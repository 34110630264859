import React from 'react';
import classNames from 'classnames';
import { Translate } from 'react-localize-redux';
import { QuoteNote } from '@brenger/api-client';

import { useTypedSelector } from '../../../../hooks';
import { Toast } from '../../../../brenger-shared-ui';
import theNetherlands from '../../../../assets/images/nl.jpg';
import brengerGo from '../../../../assets/images/brenger_go.png';
import { priceAsString } from '../../../../utils/price';
import { translate } from '../../../../utils/localization';
import { capsFirstLetter } from '../../../../utils/basics';
import { useNotification } from '../../../../hooks/useNotification';

// Util to convert current price into old price based on discount amount
const getOldPrice = (currentPrice: number, discount: number) => priceAsString(currentPrice / (1 - discount / 100));
// A list of discounts that this notification cares about
// In order of priority, so we can later <quote>look for the first match and move on</quote> :)
// IMPORTANT NOTE (🥁): Do not forget to add a translation for each note using the note as the exact trans ID.
type SpecialPriceType = 'percentage' | 'flat' | 'other';
const validDiscounts: Array<{ note: QuoteNote; type: SpecialPriceType; icon: string; data?: number }> = [
  { note: 'overijssel_20_percent_campaign', type: 'percentage', data: 30, icon: theNetherlands },
  { note: 'remote_20_percent_campaign', type: 'percentage', data: 30, icon: theNetherlands },
  { note: 'zeeland_2020_apr', type: 'percentage', data: 20, icon: theNetherlands },
  { note: 'flanders_2020_apr', type: 'percentage', data: 20, icon: theNetherlands },
  { note: 'belgium_2020_apr', type: 'percentage', data: 20, icon: theNetherlands },
  { note: 'belgium_special_pricing', type: 'percentage', data: 20, icon: theNetherlands },
  { note: 'nl_10_percent_campaign', type: 'percentage', data: 10, icon: theNetherlands },
  { note: 'randstad_eternal_weekdays', type: 'percentage', data: 10, icon: theNetherlands },
  { note: 'large_city_flat_price', type: 'flat', icon: brengerGo },
  { note: 'zeeland_tunnel_incentive', type: 'other', icon: theNetherlands },
];

export const DutchSpecialPrice: React.FC = () => {
  const notification = useNotification('dutch-special-price');
  const progressStep = useTypedSelector(state => state.generalTransport.layout.step);
  const amount = useTypedSelector(state => state.generalTransport.price?.amount || 0);
  const notes = useTypedSelector(state => state.generalTransport.quotes?.notes || []);
  // Just look for first match and move on. That is why order of validDiscounts above matters.
  const discount = validDiscounts.find(({ note }) => notes.includes(note));

  React.useEffect(() => {
    if (progressStep === 3 && amount && discount) {
      notification.add();
    } else {
      notification.remove();
    }
  }, [progressStep, amount, discount?.note, discount?.type, discount?.data]);

  // Translations for discounts of type "percentage" or "fixed" accept a data prop.
  const translationData = {} as { data?: { discount_amount: string } };

  // The translations for the percentage-based discounts are templatized,
  // so must provide some data to make it effective.
  if (discount?.type === 'percentage') {
    translationData.data = { discount_amount: `${discount?.data}%` };
  }

  return (
    <div className={classNames('toast-container', { 'toast--visible': notification.isVisible })}>
      <Toast
        prefix={<img style={{ width: 80, marginTop: 8, borderRadius: '50%' }} src={discount?.icon} />}
        handleClose={notification.removeForOneSession}
      >
        <div className="pt-0-5" style={{ paddingRight: 15 }}>
          <b>
            <Translate id={`request_flow.price_notifications.${discount?.note}.title`} {...translationData} />
          </b>
        </div>
        <Translate id={`request_flow.price_notifications.${discount?.note}.text`} {...translationData} />
        {/* Discounts of type "percentage" have an old price that can be derived and displayed to users. */}
        {discount?.type === 'percentage' && (
          <div className="discount-price-display">
            {capsFirstLetter(translate('request_flow.price.special_from'))}{' '}
            <b className={'old-price'}>{getOldPrice(amount, discount?.data || 0)}</b>{' '}
            <Translate id="request_flow.price.special_to" /> <b>{priceAsString(amount)}</b>
          </div>
        )}
      </Toast>
    </div>
  );
};

import * as React from 'react';
import classNames from 'classnames';
import '../input/input.css';
import './input-button-select.css';
import InputWrapper from '../input/InputWrapper';
import InputFeedback from '../input/InputFeedback';
import { InputProps } from '../input/Input';
import { PaymentMethod } from '../../../../modules/Payment/typings';

interface custom {
  // @TODO: options type should be general not only for payment method attrs
  options?: any[];
}

// @TODO: fix input props and use ReduxForm props
export type InputOptionsProps = custom & InputProps;

interface Props {
  // @TODO: use proper input ReduxForm props
  input: any;
  // @TODO: use proper meta ReduxForm props
  meta: any;
  successMessage: any;
  options: PaymentMethod[];
}

const InputButtonSelect: React.FC<Props> = props => {
  return (
    <InputWrapper input={...props.input} meta={...props.meta} type={'button-select'}>
      <div>
        {props.options.map((option, index) => {
          return (
            <div
              key={index}
              className={classNames([
                'trigger',
                'input-el--button-select--option',
                { 'input-el--button-select--option-selected': props.input.value === option.mollie_id },
                `delay-index-${index}`,
              ])}
              onClick={() => props.input.onChange(option.mollie_id)}
            >
              {option.icon && (
                <img className={'input-el--button-select--option-icon'} src={option.icon} alt={option.name} />
              )}
              <div className={'input-el--button-select--option-label'}>{option.name}</div>
            </div>
          );
        })}
      </div>
      <InputFeedback
        meta={...props.meta}
        error={props.meta.touched && props.meta.error}
        successMessage={props.successMessage}
      />
    </InputWrapper>
  );
};

export default InputButtonSelect;

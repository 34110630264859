import 'react-app-polyfill/ie11'; // For IE 11 support
import React from 'react';

import { render } from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { configureStore } from './state/store';
import { createBrowserHistory } from 'history';
import { LocalizeProvider } from 'react-localize-redux';
import { _BASE_URL } from './utils/global';
import { cookie } from './configs/cookie';

import App from './App';

import serviceWorker from './configs/serviceWorker';
import { sentry } from './configs/sentry';
import { getRedirectUrl } from './utils/router';
import { ErrorBoundary } from './brenger-shared-ui';

const history: any = createBrowserHistory({ basename: _BASE_URL });
export const { store, persistor } = configureStore(history);

sentry.configure();
cookie.fetchMarktplaatsUTM();

const rootContainer = document.getElementById('root-customer');

export const RootDOM = () => (
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <LocalizeProvider store={store}>
        <ErrorBoundary>
          <App history={history} />
        </ErrorBoundary>
      </LocalizeProvider>
    </PersistGate>
  </ReduxProvider>
);
const container =
  rootContainer ||
  document.getElementById('general-request-flow') ||
  document.getElementById('block-createrequestblock-2');
if (container !== null) {
  render(<RootDOM />, container);
}

// Webpack Hot Module Replacement API
if ((module as any).hot) {
  (module as any).hot.accept('./App', () => {
    render(
      <RootDOM />,
      document.getElementById('root-customer') || document.getElementById('root') || document.createElement('div')
    );
  });
}

const redirectUrl = getRedirectUrl(window.location.href);
if (redirectUrl) {
  window.location.href = redirectUrl;
}

serviceWorker();

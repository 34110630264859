import { _PERSIST_VERSION } from '../utils/global';
import _get from 'lodash/get';

export const persist = {
  runMigrations: persistor => {
    const version = persist.getCurrentVersion();
    if (_PERSIST_VERSION > version) {
      persistor.purge();
    }
  },
  getCurrentVersion: () => {
    const defaultVersion = JSON.stringify({ version: -1 });
    const parsed = _get(JSON.parse(localStorage.getItem('persist:root')), '_persist', defaultVersion);
    return JSON.parse(parsed).version;
  },
};

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import '../../assets/chat/chat.css';
import { ChatInterface } from './interface';
import { actions } from './ducks';
import _get from 'lodash/get';

import ChatHeader from './components/ChatHeader';
import ChatBody from './components/ChatBody';
import Compose from './components/Compose';

class Chat extends React.Component<ChatInterface & RouteComponentProps<{}>> {
  public render() {
    return (
      <section className={'chat-section'}>
        {_get(this.props.driver, 'driver_user', false) ? (
          <>
            <ChatHeader {...this.props} />
            <ChatBody {...this.props} />
            <Compose {...this.props} />
          </>
        ) : (
          <div />
        )}
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  driver: state.chat.driver,
  id: ownProps.transport_request.id,
  short_id: ownProps.transport_request.short_id,
  price: _get(ownProps.transport_request, 'price', { amount: 0 }),
  title: _get(ownProps.transport_request, 'item_sets[0].title', ''),
  customer: ownProps.transport_request.customer,
  confirm: ownProps.confirm,
  sending: state.chat.sending,
  reject: ownProps.reject,
  hideActionBar: _get(ownProps, 'hideActionBar', false),
  messages: state.chat.messages,
  closeChat: ownProps.closeChat,
  loading: state.chat.loading,
  transport_request: ownProps.transport_request,
});

const mapDispatchToProps = dispatch => ({
  sendMessage: (msg, format, request) => dispatch(actions.sendMessageStart(msg, format, request)),
  openChatSession: () => dispatch(actions.openChatSession()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Chat);

import React from 'react';
import { CheckMarkList, SheetLayoutSidebarContent } from '../../../brenger-shared-ui';
import { translate } from '../../../utils/localization';
import { _INSURANCE_AMOUNT } from '../../../utils/global';
import { toEuroString } from '../../../utils/payment';

export const SidebarLayout: React.FC = () => {
  return (
    <div className={'sheet-layout--sidebar-background'}>
      <SheetLayoutSidebarContent>
        <CheckMarkList
          header={String(translate('request_flow.IKEA.usp.title'))}
          checkMarkStyle={'primary'}
          list={[
            translate('request_flow.IKEA.usp.one'),
            translate('request_flow.IKEA.usp.two'),
            translate('request_flow.IKEA.usp.three', { insurance_amount: toEuroString(_INSURANCE_AMOUNT) }),
            translate('request_flow.IKEA.usp.four'),
          ]}
        />
      </SheetLayoutSidebarContent>
    </div>
  );
};

import * as React from 'react';
import InputWrapper from '../input/InputWrapper';
import InputFeedback from '../input/InputFeedback';
import { InputOptionsProps } from '../inputbuttonselect/InputButtonSelect';
import './inputswitch.css';

const InputSwitch = ({ input, meta, successMessage, options }: InputOptionsProps): any => {
  return (
    <InputWrapper input={...input} meta={...meta} type={'switch'}>
      <div className={'input-el--switch-options'}>
        {options.map((option, index) => {
          return (
            <div key={index}>
              <input
                id={input.name + '_' + index}
                type={'radio'}
                {...input}
                checked={input.value === option.value}
                value={option.value}
              />
              <label className={'input-el--switch--label'} htmlFor={input.name + '_' + index}>
                {option.label}
              </label>
            </div>
          );
        })}
      </div>
      <InputFeedback meta={...meta} error={meta.error} successMessage={successMessage} />
    </InputWrapper>
  );
};

export default InputSwitch;

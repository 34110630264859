import {
  atLeastOneLetter,
  atLeastOneNumberRegex,
  email,
  maxLength,
  minLength,
  numeric,
  numericMin,
  postalCodeRegexNL,
  required,
  testRegex,
  timeSlotEnd,
  timeSlotStart,
} from '../../utils/validation';
import { translate } from '../../utils/localization';
import _get from 'lodash/get';
import { Situations, SubStepFieldNamesDelivery, SubStepFieldNamesPickup } from './interface';
import moment from 'moment-timezone';
import _replace from 'lodash/replace';
import { CarryingHelpChoices, GenericExtraInputFileFields } from '../../typings';

export const AddressSchema = {
  line1: [
    value =>
      required(
        value,
        translate('form.fields.default.required', { field: translate('form.fields.address.line1.label') })
      ),
    (value, allValues) => {
      const countryCode = getCountryCode(allValues);
      if (countryCode === 'NL') {
        return testRegex(value, atLeastOneNumberRegex, translate('form.fields.address.line1.valid_with_number'));
      }
    },
    (value, allValues) => {
      const countryCode = getCountryCode(allValues);
      if (countryCode === 'NL') {
        return testRegex(value, atLeastOneLetter, translate('form.fields.address.line1.valid'));
      }
    },
  ],
  postal_code: [
    value =>
      required(
        value,
        translate('form.fields.default.required', { field: translate('form.fields.address.postal_code.label') })
      ),
    (value, allValues) => {
      const countryCode = getCountryCode(allValues);
      if (countryCode === 'NL') {
        return testRegex(value, postalCodeRegexNL, translate('form.fields.address.postal_code.valid'));
      }
      return undefined;
    },
  ],
  postal_code_business: [
    value =>
      required(
        value,
        translate('form.fields.default.required', { field: translate('form.fields.address.postal_code.label') })
      ),
  ],
  country: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('form.fields.address.country.label') })
    ),
  locality: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('form.fields.address.locality.label') })
    ),
  custom_delivery_date: value =>
    required(value, translate('form.fields.default.required', { field: translate('form.fields.datetime.date.label') })),
  conditions: [value => required(value, translate('request_flow.payment.agree_terms_required'))],
  relation: value => required(value),
};

export const PersonSchema = {
  first_name: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('form.fields.user.first_name.label') })
    ),
  last_name: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('form.fields.user.last_name.label') })
    ),
  phone: [
    value =>
      required(value, translate('form.fields.default.required', { field: translate('form.fields.user.phone.label') })),
    value => minLength(value, 10, translate('form.fields.user.phone.valid')),
    value => maxLength(value, 14, translate('form.fields.user.phone.valid')),
    value => numeric(value, translate('form.fields.user.phone.valid')),
  ],
  email: [
    value =>
      required(value, translate('form.fields.default.required', { field: translate('form.fields.user.email.label') })),
    value => email(value, translate('form.fields.user.email.valid')),
  ],
};

export const destinationSchema = {
  from: value =>
    required(value, translate('form.fields.default.required', { field: translate('request_flow.location.from') })),
  to: value =>
    required(value, translate('form.fields.default.required', { field: translate('request_flow.location.to') })),
};

export const itemSchema = {
  title: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('request_flow.what.fields.title.label') })
    ),
  width: value =>
    numericMin(
      value,
      1,
      translate('form.fields.default.required', { field: translate('request_flow.what.fields.width.label') })
    ),
  height: value =>
    numericMin(
      value,
      1,
      translate('form.fields.default.required', { field: translate('request_flow.what.fields.height.label') })
    ),
  length: value =>
    numericMin(
      value,
      1,
      translate('form.fields.default.required', { field: translate('request_flow.what.fields.length.label') })
    ),
  weigth: value =>
    numericMin(
      value,
      0,
      translate('form.fields.default.required', { field: translate('request_flow.what.fields.weight.label') })
    ),
  count: value =>
    numericMin(
      value,
      1,
      translate('form.fields.default.required', { field: translate('request_flow.what.fields.count.label') })
    ),
  extraCare: value => required(value, translate('request_flow.fields.extra_care.valid')),
};

export const pickupDeliverySchema = {
  time: (value, allValues) => {
    if (window.location.href.indexOf('time') < 0) {
      return undefined;
    }
    if (_get(allValues, 'pickup', false) && !_get(allValues, 'pickup.time_start_custom', false)) {
      return required(value, ' ');
    }
    if (_get(allValues, 'delivery', false) && !_get(allValues, 'delivery.time_start_custom', false)) {
      return required(value, ' ');
    }
    return undefined;
  },
  elevator: value => (value === false || value === true ? undefined : ' '),
  auction_type: value => required(value, ' '),
  situation: value => required(value, ' '),
  situation_home: value => required(value, ' '),
  marktplaats_option: value =>
    required(value, translate('request_flow.marktplaats.form.fields.marktplaats_option.valid')),
  help: value => {
    if (window.location.href.indexOf('help') > -1) {
      if (
        [
          CarryingHelpChoices.NOT_NEEDED,
          CarryingHelpChoices.NEEDED,
          CarryingHelpChoices.EXTRA_DRIVER,
          CarryingHelpChoices.EQUIPMENT_TAILGATE,
          CarryingHelpChoices.EQUIPMENT_TAILGATE_PALLET_JACK,
        ].includes(value)
      ) {
        return undefined;
      } else {
        return ' ';
      }
    }
    return undefined;
  },
  equipment: value => required(value, ' '),
  invoice: (value, allValues, props, name) => {
    const collectionKey = `${name}_${GenericExtraInputFileFields.COLLECTION}`;
    if (_get(allValues, collectionKey, []).length) {
      return undefined;
    }
    return translate('request_flow.errors.upload_required');
  },
  other: value => required(value, translate('request_flow.fields.other_note.valid')),
  floor: value => numeric(value, ' '),
  date: value => {
    if (window.location.href.indexOf('date') > -1) {
      return required(value, ' ');
    }
    return undefined;
  },
  time_start: (value, allValues, props, name) => {
    if (window.location.href.indexOf('time') < 0) {
      return undefined;
    }
    const fieldNames = name.indexOf('pickup') > -1 ? SubStepFieldNamesPickup : SubStepFieldNamesDelivery;
    if (name.indexOf('delivery') > -1 && _get(allValues, `${SubStepFieldNamesDelivery.pickup_time}.start`, false)) {
      const isSameDay = moment(new Date(allValues.delivery.date)).isSame(moment(allValues.delivery.pickupDate), 'day');
      if (isSameDay && parseInt(value, 10) < parseInt(allValues.delivery.pickupTime.start, 10)) {
        return translate('request_flow.time.fields.after_pickup_start_time', {
          time: allValues.delivery.pickupTime.start,
        });
      } else if (_get(allValues, fieldNames.time, false)) {
        return timeSlotStart(value, _get(allValues, `${fieldNames.time}.end`, null));
      }
    } else if (_get(allValues, fieldNames.time, false)) {
      return timeSlotStart(value, _get(allValues, `${fieldNames.time}.end`, null));
    }
    return required(value, ' ');
  },
  time_end: (value, allValues, props, name) => {
    if (window.location.href.indexOf('time') < 0) {
      return undefined;
    }
    const situation = _get(allValues, 'pickup.situation', '');
    const isAuction = situation === Situations.AUCTION;
    const fieldNames = name.indexOf('pickup') > -1 ? SubStepFieldNamesPickup : SubStepFieldNamesDelivery;
    if (name.indexOf('delivery') > -1 && _get(allValues, `${SubStepFieldNamesDelivery.pickup_time}.start`, false)) {
      const isSameDay = moment(new Date(allValues.delivery.date)).isSame(moment(allValues.delivery.pickupDate), 'day');
      if (isSameDay && parseInt(value, 10) < parseInt(allValues.delivery.pickupTime.end, 10)) {
        return translate('request_flow.time.fields.after_pickup_end_time', {
          time: allValues.delivery.pickupTime.end,
        });
      } else if (_get(allValues, fieldNames.time, false)) {
        return timeSlotEnd(value, _get(allValues, `${fieldNames.time}.start`, null), isAuction);
      }
    } else if (_get(allValues, fieldNames.time, false)) {
      return timeSlotEnd(value, _get(allValues, `${fieldNames.time}.start`, null), isAuction);
    }
    return required(value, ' ');
  },
};

export const userSchema = {
  first_name: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('form.fields.user.first_name.label') })
    ),
  last_name: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('form.fields.user.last_name.label') })
    ),
  phone: [
    value =>
      required(value, translate('form.fields.default.required', { field: translate('form.fields.user.phone.label') })),
    value => minLength(value, 10, translate('form.fields.user.phone.valid')),
    value => maxLength(value, 14, translate('form.fields.user.phone.valid')),
    value => numeric(value, translate('form.fields.user.phone.valid')),
  ],
  email: [
    value =>
      required(value, translate('form.fields.default.required', { field: translate('form.fields.user.email.label') })),
    value => email(value, translate('form.fields.user.email.valid')),
  ],
};

export const CompanySchema = {
  name: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('form.fields.account.account_name.label') })
    ),
  line1: [
    value =>
      required(
        value,
        translate('form.fields.default.required', { field: translate('form.fields.address.line1.label') })
      ),
    (value, allValues) => {
      const countryCode = _get(allValues, 'customer.business.address.country_code', '');
      if (countryCode === 'NL') {
        return testRegex(value, atLeastOneNumberRegex, translate('form.fields.address.line1.valid_with_number'));
      }
    },
    (value, allValues) => {
      const countryCode = _get(allValues, 'customer.business.address.country_code', '');
      if (countryCode === 'NL') {
        return testRegex(value, atLeastOneLetter, translate('form.fields.address.line1.valid'));
      }
    },
  ],
  country: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('form.fields.address.country.label') })
    ),
  postal_code: [
    value =>
      required(
        value,
        translate('form.fields.default.required', { field: translate('form.fields.address.postal_code.label') })
      ),
    (value, allValues) => {
      const countryCode = _get(allValues, 'customer.business.address.country_code', '');
      if (countryCode === 'NL') {
        return testRegex(value, postalCodeRegexNL, translate('form.fields.address.postal_code.valid'));
      }
      return undefined;
    },
  ],
  locality: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('form.fields.address.locality.label') })
    ),
  coc_number: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('form.fields.account.coc_number.label') })
    ),
  vat_number: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('form.fields.account.vat_number.label') })
    ),
};

export const getCountryCode = allValues => {
  const addresPrefix = window.location.href.indexOf('delivery') > 0 ? 'delivery.address.' : 'pickup.address.';
  return _get(allValues, addresPrefix + 'country_code', '');
};

// Normalizer functions
export const parseInteger = (input: string) => {
  if (input === '0') {
    return '';
  }
  input = input.toString().replace('.', '');
  return parseInt(input.replace(/[^0-9.]/g, ''), 10);
};

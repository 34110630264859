import createSagaMiddleware from 'redux-saga';

import { logException } from '../../utils/basics';

const sagaMiddleware = createSagaMiddleware({
  // This middleware will handle uncaught errors from Sagas.
  onError(error, errorInfo) {
    logException(error.message, errorInfo);
  },
});

export default sagaMiddleware;

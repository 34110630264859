import React, { Component } from 'react';

import './cookieconsent.css';
import { Translate } from 'react-localize-redux';
import { Button } from '../../../index';

interface Props {
  url: string;
}

interface State {
  isVisible: boolean;
}

class CookieConsent extends Component<Props, State> {
  private constructor(props) {
    super(props);
    this.state = {
      isVisible: document.cookie.indexOf('cookie-agreed=') === -1,
    };
  }

  public setCookie() {
    this.setState({ isVisible: false });
    document.cookie = 'cookie-agreed=2; expires=Fri, 31 Dec 9999 23:59:59 GMT';
  }

  public render() {
    return (
      <div className={'cookie-consent' + (this.state.isVisible ? ' cookie-consent--is-visible' : '')}>
        <div className={'cookie-consent--body'}>
          <Translate id={'cookie.consent'} />{' '}
          <a target="_blank" href={this.props.url}>
            <Translate id={'cookie.read_policy'} />
          </a>
        </div>
        <div className={'cookie-consent--action'}>
          <Button buttonStyle={'primary'} onClick={() => this.setCookie()}>
            <Translate id={'cookie.agree'} />
          </Button>
        </div>
      </div>
    );
  }
}

export default CookieConsent;

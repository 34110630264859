/*
/* BRENGER CLIENT GLOBAL CONSTANTS
/* ================================================== */

import bvaLogo from '../assets/images/bvaLogo.svg';
import vavatoLogo from '../assets/images/vavatoLogo.svg';
import ikeaLogo from '../assets/IKEA/logo.jpg';
import moment from 'moment-timezone';
import { getCustomerHost, getDriverHost } from './basics';

export const _BASE_URL = window.location.href.indexOf('/en') > -1 ? '/en/customer' : '/nl/customer';
export const _CUSTOMER_HOST_URL = getCustomerHost();
export const _DRIVER_HOST_URL = getDriverHost();
export const _ROUTING_BASE_URL = 'https://lavi84rd08.execute-api.eu-west-1.amazonaws.com/prod';
export const _WHAT_IS_MY_COUNTRY_URL =
  'https://lavi84rd08.execute-api.eu-west-1.amazonaws.com/prod/geo/what_is_my_country';
export const _INTERNATIONAL_CHECK_BASE_URL = 'https://kw7y38ca21.execute-api.eu-west-1.amazonaws.com/prod';
export const _BVA_BASE_URL = 'https://api.bva-auctions.com/api/rest/ext123';
export const _VAVATO_BASE_URL = 'https://api.vavato.com';
export const _ACCEPT_HEADER = 'application/ld+json';
export const _BASE_MAPS_URI = 'https://maps.googleapis.com/maps/api/staticmap';
export const _TRUST_PILOT_REVIEW_URL = 'https://nl.trustpilot.com/evaluate/brenger.nl';
export const _GOOGLE_REVIEW_URL = 'https://search.google.com/local/writereview?placeid=ChIJDxcpbp4JxkcRY_DecNr1mb8';
export const _GOOGLE_MAP_KEY = 'AIzaSyBwVhBOJxllD3rGUO0lLB6epMwKA6rXrDQ';
export const _BVA_LOGO = bvaLogo;
export const _IKEA_LOGO = ikeaLogo;
export const _VAVATO_LOGO = vavatoLogo;
export const _INSURANCE_AMOUNT = 100000;
export const _DIFFERENT_DELIVERY_DATE_AMOUNT = 1000; // 10 euro in cents
export const _DEFAULT_TIME_ZONE = 'Europe/Amsterdam';
export const _LONG_DISTANCE_IN_KM = 150;
export const _LONG_DISTANCE_IN_KM_EXTRA_DATE = 250;

export const _GENERAL_CONDITION_LINK = 'https://www.brenger.nl/nl/algemene-voorwaarden';
export const _PRIVACY_STATEMENT_LINK = 'https://www.brenger.nl/nl/privacybeleid';
export const _TRANSPORT_CONDITION_LINK = 'https://www.brenger.nl/nl/vervoersovereenkomst';
export const _GENERAL_COOKIES_LINK = 'https://www.brenger.nl/nl/cookies';
export const _CLIENT_SERVICES_LINK = 'https://www.brenger.nl/nl/contact-met-team-brenger';
export const _PRICE_API_URL = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'dev':
      if (process.env.REACT_APP_PRICE_API_URL) {
        return process.env.REACT_APP_PRICE_API_URL;
      }
      return 'https://kw7y38ca21.execute-api.eu-west-1.amazonaws.com/prod';
    case 'staging':
      return 'https://tolwbxzenb.execute-api.eu-west-1.amazonaws.com/staging';
    case 'prod':
    default:
      return 'https://kw7y38ca21.execute-api.eu-west-1.amazonaws.com/prod';
  }
};

export const _ASSETS_PATH = () => {
  // Returns the path where images/pdf's are stored
  switch (process.env.REACT_APP_ENV) {
    case 'dev':
      if (process.env.REACT_APP_ASSETS_PATH) {
        return process.env.REACT_APP_ASSETS_PATH + '/user_data';
      }
      return process.env.REACT_APP_API_URL_V2 + '/user_data';
    case 'staging':
      return process.env.REACT_APP_API_URL_V2 + '/user_data';
    default:
      return 'https://user-data.brenger.nl';
  }
};

export const _GEO_AUTOCOMPLETE_ENDPOINT = `${_ROUTING_BASE_URL}/geo/autocomplete`;
export const _CALCULATE_ROUTE_ENDPOINT = `${_ROUTING_BASE_URL}/geo/calculate_route`;
export const _CUSTOMER_SUPPORT_PHONE_NUMBER = '085-8881124';
export const _BUSINESS_ACCOUNT_SUPPORT_NUMBER = '085-8880490';
export const _CUSTOMER_SUPPORT_EMAIL = 'info@brenger.nl';
export const _CUSTOMER_SUPPORT_LINK = 'https://www.brenger.nl/nl/contact-met-team-brenger';
export const GENERAL_ERR_MESSAAGE = 'Oops, something went wrong. Please try again!';

export const _DEFAULT_LOCATION = { address: { lat: 52.37317, lng: 4.89066 } };

export const _UPLOAD_HEADERS = {
  contentType: 'multipart/form-data',
  accept: _ACCEPT_HEADER,
};

// time
export const _TIME_SLOT_LENGTH = 4;
export const _TIME_SLOT_LENGTH_AUCTION = 2;
// normal time
export const _TIME_START_MIN = 9;
export const _TIME_END_MAX = 22;
export const _TIME_START_MAX = _TIME_END_MAX - _TIME_SLOT_LENGTH;
export const _TIME_END_MIN = _TIME_START_MIN + _TIME_SLOT_LENGTH;
// auction times
export const _TIME_END_MIN_AUCTION = _TIME_START_MIN + _TIME_SLOT_LENGTH_AUCTION;
export const _TIME_START_MAX_AUCTION = _TIME_END_MAX - _TIME_SLOT_LENGTH_AUCTION;

export const _LIMIT_SAME_DAY_DELIVERY_MOMENT = moment().set({ hour: 0, minute: 0, second: 0 });

export const _GENERAL_DEFAULT_TIME_START = 9;
export const _GENERAL_DEFAULT_TIME_END = 18;
export const _GENERAL_DEFAULT_TIME = {
  start: _GENERAL_DEFAULT_TIME_START + ':00',
  end: _GENERAL_DEFAULT_TIME_END + ':00',
};

// Max weight
export const _MAX_NORMAL_WEIGHT = 79; // everything above is considered a high value - #723
export const _MAX_WEIGHT = 200;
export const _MAX_WEIGHT_IKEA = 500;
export const _MAX_IKEA_SERVICE_DISTANCE = 50;

// IKEA times
export const _IKEA_TIME_PICKUP_START = 9;
export const _IKEA_TIME_PICKUP_END = 18;
export const _IKEA_TIME_DELIVERY_START = 18;
export const _IKEA_TIME_DELIVERY_END = 22;
export const _IKEA_ORDER_TIME_LIMIT = moment().set({ hour: 17, minute: 0, second: 0 });

// IKEA BARGAINS (merged into normal ikea flow)
export const _IKEA_BARGAINS_TIME_PICKUP_START = 10;
export const _IKEA_BARGAINS_TIME_PICKUP_END = 11;
export const _IKEA_BARGAINS_TIME_DELIVERY_START = 11;
export const _IKEA_BARGAINS_TIME_DELIVERY_END = 15;
export const _IKEA_BARGAINS_LIMIT_SAME_DAY_DELIVERY_MOMENT = moment().set({ hour: 0, minute: 0, second: 0 });

// business price additions
export const _BUSINESS_EXTRA_DRIVER = 1500;
export const _BUSINESS_GROUND_LEVEL = 500;

// item set sizes
export const _BUSINESS_MAX_ITEM_SET_SIZE = 4;

// persist
export const _PERSIST_VERSION = 8.0004;

export const _PERSIST_BLACKLIST = [
  'localize',
  // user has its own persisted reducer.
  'user',
  'base',
  'router',
  'chat',
  'payment',
  'statusPage',
];

// payment

export const REDIRECT_URLS = {
  GENERAL_FLOW: '/partner/general/:id/thank_you',
  BVA_FLOW: '/partner/bva/:id/thank_you',
  VAVATO_FLOW: '/partner/vavato/:id/thank_you',
  IKEA_FLOW: '/partner/ikea/:id/thank_you',
};

// User assets
export const ROLES = {
  ROLE_USER: 'ROLE_USER',
  ROLE_CUSTOMER: 'ROLE_CUSTOMER',
  ROLE_DRIVER: 'ROLE_DRIVER',
  ROLE_DRIVER_PRO: 'ROLE_DRIVER_PRO',
  ROLE_ADMINISTRATOR: 'ROLE_ADMINISTRATOR',
  ROLE_CUSTOMER_CARE: 'ROLE_CUSTOMER_CARE',
  ROLE_NEEDS_SOCIAL_REGISTRATION: 'ROLE_NEEDS_SOCIAL_REGISTRATION',
};

// More info chips
export const _CHIPS = {
  options: [
    { id: 'fragile', label: 'request_flow.fields.chips.protection.fragile' },
    { id: 'on_wheels', label: 'request_flow.fields.chips.transport.on_wheels' },
    { id: 'stackable', label: 'request_flow.fields.chips.transport.stackable' },
    { id: 'separate_parts', label: 'request_flow.fields.chips.transport.separate_parts' },
  ],
};

export const TJ_STATE = {
  PUBLISHED: 'published',
  SUBMITTED: 'submitted',
  EXPIRED: 'expired',
  DRIVER_FOUND: 'driver_found',
  MAX_DRIVERS_FOUND: 'max_drivers_found',
  DRIVER_CONFIRMED: 'driver_confirmed',
  DELIVER_CONFIRMED_BY_CUSTOMER: 'delivery_confirmed_by_customer',
  DELIVER_CONFIRMED_BY_DRIVER: 'delivery_confirmed_by_driver',
  DELIVERED: 'delivered',
  DISPUTED: 'disputed',
  CANCELLED: 'cancelled',
};

export const TJAL_STATE = {
  PENDING: 'pending',
  REJECTED: 'rejected',
  ACCEPTED: 'accepted',
  TEMPORARY_ACCEPTED: 'temporary_accepted',
  REVOKED_BY_DRIVER: 'revoked_by_driver',
  REVOKED_BY_CUSTOMER_CARE: 'revoked_by_customer_care',
  DELIVERED: 'delivered',
  TRANSPORT_JOB_CANCELLED: 'transport_job_cancelled',
};

// Extra matches that don't need confirmation
export const _EXTRA_CARE_NO_CONFIRMATION = ['none', 'scooter', 'requires_equipment', 'fridge'];

import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import Destination from './containers/Destination';
import { InjectedFormProps } from 'redux-form';
import ItemSet from './containers/ItemSet';
import Pickup from './containers/Pickup';
import Delivery from './containers/Delivery';
import Contact from './containers/Contact';
import Payment from './containers/Payment';
import Terms from './containers/Terms';
import { connect } from 'react-redux';
import {
  IconInfo,
  ProgressHeader,
  SheetLayout,
  SheetLayoutContentWrapper,
  StepIndicator,
} from '../../brenger-shared-ui';
import { goBack, push } from 'connected-react-router';
import { translate } from '../../utils/localization';
import SidebarLayout from './containers/SidebarLayout';
import '../../assets/general-flow/sidebar.css';
import '../../assets/general-flow/options.css';
import '../../assets/general-flow/smallStyling.css';
import '../../assets/general-flow/widget.scss';
import { Translate } from 'react-localize-redux';
import { actions as flowActions, getReferrer } from './ducks';
import ModalLayout from './containers/ModalLayout';
import DateStep from './containers/Date';
import Packages from './containers/Packages';
import SellerForm from './components/Marktplaats/SellerForm';
import { getSearchPath } from '../../state/ducks/baseReducer';
import Weight from './containers/Weight';

export const prefix = '/transport_request';
export const _MODULE_PREFIX = '@@GeneralFlow';
export const _FLOW_TYPE = 'general';

class GeneralFlow extends React.Component<InjectedFormProps<{}> & RouteComponentProps<{}>> {
  public componentDidMount(): void {
    window.document.title = String(translate('request_flow.general.title'));
  }
  public render() {
    return (
      <>
        <ProgressHeader
          goBack={this.props.goBack}
          goBackText={String(translate('nav.previous'))}
          currentStep={this.props.generalTransport.layout.step}
          totalSteps={8}
        />
        <SheetLayout sheetOpen={this.props.generalTransport.layout.sheet} sidebar={<SidebarLayout />}>
          <SheetLayoutContentWrapper className={'sheet-layout--content-wrapper--top-header'}>
            <div className={'sheet-layout--container'}>
              <StepIndicator currentStep={this.props.generalTransport.layout.step}>
                {this.props.route.includes('invoice') ? (
                  <Translate id={'request_flow.auction.lot_add_invoice'} />
                ) : (
                  <Translate id={'request_flow.headers.step_' + this.props.generalTransport.layout.step} />
                )}
                <div className={'general-help--trigger'} onClick={() => this.props.setHelpOpen()}>
                  <IconInfo /> <Translate id={'request_flow.help.more_info'} />
                </div>
              </StepIndicator>
            </div>
          </SheetLayoutContentWrapper>
          <Switch>
            <Route path={`${prefix}/`} exact={true} component={Destination} />
            <Route path={`${prefix}/items`} component={ItemSet} />
            <Route path={`${prefix}/weight`} component={Weight} />
            <Route path={`${prefix}/date`} component={DateStep} />
            <Route path={`${prefix}/packages`} component={Packages} />
            <Route path={`${prefix}/pickup`} component={Pickup} />
            <Route path={`${prefix}/delivery`} component={Delivery} />
            <Route path={`${prefix}/contact`} component={Contact} />
            <Route path={`${prefix}/terms`} component={Terms} />
            <Route path={`${prefix}/payment`} component={Payment} />
            <Route path={`${prefix}/marktplaats_contact_requests/:id`} component={SellerForm} />
            <Redirect to={`${prefix}/`} />
          </Switch>
        </SheetLayout>
        <ModalLayout />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  generalTransport: state.generalTransport,
  exitUrl: getReferrer(state),
  route: getSearchPath(state),
});

const mapDispatchToProps = dispatch => ({
  goBack: () => dispatch(goBack()),
  createAccount: () => dispatch(push('/register')),
  setHelpOpen: () => dispatch(flowActions.setHelpOpen(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralFlow);

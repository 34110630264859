export enum StopType {
  pickup = 'pickup',
  delivery = 'delivery',
}

export enum ItemSetState {
  READY_FOR_PICKUP = 'ready_for_pickup',
  PICKUP_CONFIRMED_BY_CUSTOMER = 'pickup_confirmed_by_customer',
  PICKUP_CONFIRMED_BY_DRIVER = 'pickup_confirmed_by_driver',
  PICKED_UP = 'picked_up',
  DELIVERY_CONFIRMED_BY_CUSTOMER = 'delivery_confirmed_by_customer',
  DELIVERY_CONFIRMED_BY_DRIVER = 'delivery_confirmed_by_driver',
  DELIVERED = 'delivered',
  DISPUTED = 'disputed',
  CANCELLED = 'cancelled',
}

export enum GenericInputFileMethods {
  ADD = 'add',
  DELETE = 'delete',
  CHANGE = 'change',
}

export enum GenericExtraInputFileFields {
  COLLECTION = 'collection',
  COLLECTION_DUP = 'collection_dup',
  UPLOADING = 'uploading',
  UPLOAD_FEEDBACK = 'upload_feedback',
}

export enum Currencies {
  EUR = 'EUR',
}

export enum Countries {
  NL = 'NL',
}

export enum LocalStorageKeys {
  PAYMENT_EVENT_IDS = 'payment_event_ids',
  LANGUAGE_PREFERENCE = 'language_preference',
}

export enum NotGuaranteedReason {
  DISTANCE = 'distance',
  HEAVY = 'heavy',
}

export enum CarryingHelpChoices {
  NOT_NEEDED = 'not_needed',
  NEEDED = 'needed', // is default choice, as in: no extra help, the driver on its own is fine
  EXTRA_DRIVER = 'extra_driver',
  EQUIPMENT_TAILGATE = 'equipment_tailgate',
  EQUIPMENT_TAILGATE_PALLET_JACK = 'equipment_tailgate_pallet_jack',
  EQUIPMENT_TAILGATE_EXTRA_DRIVER = 'equipment_tailgate_extra_driver',
  EQUIPMENT_PROVIDED_BY_CUSTOMER = 'equipment_provided_by_customer',
}

import * as React from 'react';
import InputWrapper from '../input/InputWrapper';
import InputFeedback from '../input/InputFeedback';
import './inputnumber.css';
import { InputProps } from '../input/Input';

interface custom {
  minVal: boolean | number;
  classNames?: string;
}

type InputNumberProps = custom & InputProps;

const InputNumber = ({ input, meta, label, type, successMessage, minVal, inputClasses }: InputNumberProps): any => {
  if (minVal && minVal > input.value) {
    input.onChange(Number(minVal));
  }
  return (
    <div className={'input-el--number-outer'}>
      <label
        htmlFor={input.name}
        className={
          'input-el--number--modify input-el--number--modify-decrease' +
          (Number(input.value) === minVal ? ' is-disabled' : '')
        }
        onClick={Number(input.value) === minVal ? undefined : () => input.onChange(Number(input.value) - 1)}
      />

      <InputWrapper label={label} input={...input} meta={...meta} type={'number'}>
        <input className={inputClasses} id={input.name} type={type} {...input} value={input.value} />
        <label
          className={
            'input-el--label' +
            (typeof input.required !== 'undefined' && input.required === true ? ' input-el--label--required' : '')
          }
          htmlFor={input.name}
        >
          {label}
        </label>
        <InputFeedback meta={...meta} error={meta.error} successMessage={successMessage} />
      </InputWrapper>
      <label
        htmlFor={input.name}
        className={'input-el--number--modify input-el--number--modify-increase'}
        onClick={() => input.onChange(Number(input.value) + 1)}
      />
    </div>
  );
};

export default InputNumber;

import React from 'react';
import { Field } from 'redux-form';
import { IconTrash, Input, InputNumber } from '../../../../brenger-shared-ui';
import { Translate } from 'react-localize-redux';
import '../../../../assets/BVA/renderlot.css';
import '../../../../assets/auctions/auctions.css';
import { itemSchema, parseInteger } from '../../schema';
import { ItemFields } from '../../interface';

const RenderLotField = ({ fields, meta: { error } }) => {
  return (
    <>
      {fields.map((item, index) => (
        <div key={index}>
          {/* itemSet fields */}
          {index === 0 && (
            <p className={'bva-dimensions-count-title'}>
              <Translate id={'request_flow.what.item.bva_dimensions'} />
            </p>
          )}
          <div className={'bva-config--item-box'}>
            <div className={'item-wrapper--quantity'}>
              <Field
                validate={itemSchema[ItemFields.COUNT]}
                name={`${item}.count`}
                inputClasses={'data-hj-whitelist'}
                normalize={parseInteger}
                type="number"
                minVal={1}
                component={InputNumber}
                label={<Translate id={'request_flow.what.fields.quantity.label'} />}
              />
            </div>
            <div className={'item-wrapper--volume-fields'}>
              <div className={'fields'}>
                <Field
                  validate={itemSchema[ItemFields.LENGTH]}
                  name={`${item}.length`}
                  type="number"
                  inputClasses={'data-hj-whitelist'}
                  normalize={parseInteger}
                  min={1}
                  append={'cm'}
                  component={Input}
                  label={<Translate id={'request_flow.what.fields.length.label'} />}
                />
                <Field
                  validate={itemSchema[ItemFields.WIDTH]}
                  name={`${item}.width`}
                  inputClasses={'data-hj-whitelist'}
                  type="number"
                  normalize={parseInteger}
                  min={1}
                  append={'cm'}
                  component={Input}
                  label={<Translate id={'request_flow.what.fields.width.label'} />}
                />
                <Field
                  validate={itemSchema[ItemFields.HEIGHT]}
                  inputClasses={'data-hj-whitelist'}
                  name={`${item}.height`}
                  normalize={parseInteger}
                  type="number"
                  min={1}
                  append={'cm'}
                  component={Input}
                  label={<Translate id={'request_flow.what.fields.height.label'} />}
                />
              </div>
            </div>
            {index !== 0 && (
              <span
                className={'btn btn--grey render-lot--delete-card'}
                onClick={() => {
                  fields.remove(index);
                }}
              >
                <IconTrash />
              </span>
            )}
          </div>
        </div>
      ))}
      <p onClick={() => fields.push({ count: 1 })} className={'add-more-object'}>
        + <Translate id={'request_flow.BVA.integration.add_more_object'} />
      </p>
    </>
  );
};

export default RenderLotField;

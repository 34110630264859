import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { _ACTIVE_LANG, localization } from './configs/localization';
import { Route, Switch } from 'react-router'; // yes, react-router and not react-router-dom, otherwise it will not work properly with connected-react-router
import { ConnectedRouter, push } from 'connected-react-router';
import routes from './routes';
import { History } from 'history';
import './brenger-shared-ui/assets/style.css';
import { AppLoader } from './brenger-shared-ui';
import { Offline } from 'react-detect-offline';
import { actions as userActions } from './modules/User/ducks';
import NetworkDetector from './brenger-shared-ui/components/other/network-detector';
import { getActiveLanguage, getFaviconPath } from './utils/basics';
import _get from 'lodash/get';

export interface Props {
  history: History;
  initialize?: any;
  localized: boolean;
  loading: boolean;
  setNetworkStatus: any;
  push: any;
  retrieveCurrentUser(): void;
  retrieveCurrentCountry(): void;
}

interface State {
  setNetworkStatus: any;
}

class App extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    localization.configure(props);
    this.state = {
      setNetworkStatus: this.props.setNetworkStatus.bind(this),
    };
  }

  public componentDidMount(): void {
    // We make one check when the app mounts to see if the user is logged in.
    // The only other time we retrieve (and set) the user is after a successful login event.
    this.props.retrieveCurrentUser();
    this.props.retrieveCurrentCountry();
    // This is the menu interpreter, @TODO: should this be here?
    document.addEventListener('click', event => {
      const target = event.target as HTMLElement;
      const classOfTarget = target.getAttribute('class') || '';
      if (classOfTarget.indexOf('brenger-menu-link') === -1) {
        return;
      }
      const href = _get(event, 'target.href', '');
      const url = new URL(href);
      if (url.pathname.indexOf('/customer') > -1) {
        event.preventDefault();
        this.props.push(url.pathname.replace(`${getActiveLanguage()}/customer/`, ''));
        return;
      }
    });
  }

  public render() {
    const favicon = getFaviconPath();
    return (
      <div className="app-main-wrapper">
        {this.props.localized && (
          <ConnectedRouter history={this.props.history}>
            <Switch>
              {routes.map(route => (
                <Route exact={route.exact} key={route.path} {...route} />
              ))}
            </Switch>
          </ConnectedRouter>
        )}
        <Offline polling={{ url: `${favicon}` }}>
          <NetworkDetector setNetworkStatus={this.state.setNetworkStatus} />
        </Offline>
        {this.props.loading && <AppLoader locale={_ACTIVE_LANG} />}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setNetworkStatus: status =>
    dispatch({
      type: 'SET_NETWORK_STATUS',
      payload: status,
    }),
  push: path => dispatch(push(path)),
  retrieveCurrentUser: () => dispatch(userActions.retrieveCurrentUser()),
  retrieveCurrentCountry: () => dispatch(userActions.retrieveCurrentCountry()),
});

const mapStateToProps = state => ({
  localized: state.base.localized,
  loading: state.base.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(App));

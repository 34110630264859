import '../../assets/IKEA/ikea.css';

import { ProgressHeader, SheetLayout } from '../../brenger-shared-ui';
import { Redirect, Route, Switch } from 'react-router';

import DateSelection from './containers/Date';
import DeliveryDetails from './containers/DeliveryDetails';
import Notifications from './containers/Notifications';
import Payment from './containers/Payment';
import PickupDetails from './containers/PickupDetails';
import PickupOptions from './containers/PickupOptions';
import React from 'react';
import { RootState } from '../../typings';
import { SidebarLayout } from './components/SidebarLayout';
import { StepInterface } from './interface';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import { translate } from '../../utils/localization';

export const prefix = '/partner/ikea/transport_request';
export const MODULE_PREFIX = '@@IkeaFlow';
export const _FLOW_TYPE = 'ikea';

type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type Props = ReduxProps & StepInterface;

class IkeaFlow extends React.Component<Props> {
  public render() {
    window.document.title = String(translate('request_flow.IKEA.title'));
    return (
      <>
        <ProgressHeader
          goBack={this.props.goBack}
          goBackText={String(translate('nav.previous'))}
          goBackAlwaysAvailable={true}
          currentStep={this.props.step}
          totalSteps={3}
          thirdPartyText={String(translate('request_flow.IKEA.partner'))}
        />
        <SheetLayout sidebar={<SidebarLayout />} navForwards={true} isNavigating={false}>
          <Switch>
            <Route path={`${prefix}/pickup_details`} component={PickupDetails} />
            <Route path={`${prefix}/pickup_options`} component={PickupOptions} />
            <Route path={`${prefix}/date`} component={DateSelection} />
            <Route path={`${prefix}/delivery_details`} component={DeliveryDetails} />
            <Route path={`${prefix}/payment`} component={Payment} />
            <Redirect to={`${prefix}/pickup_details`} />
          </Switch>
          <Notifications />
        </SheetLayout>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  step: state.ikeaTransport.request.step,
});

const mapDispatchToProps = dispatch => ({
  goBack: () => dispatch(goBack()),
});

export default connect(mapStateToProps, mapDispatchToProps)(IkeaFlow);

import * as React from 'react';
import { Field, FieldArray } from 'redux-form';
import { Heading, InputSwitch, SheetLayoutContent, SheetLayoutHeader } from '../../../brenger-shared-ui';
import { Translate } from 'react-localize-redux';
import { customerTypeFields } from '../interface';
import ContactFields from './ContactFields';
import { AllProps as ContainerProps } from '../containers/Contact';
import { _ACTIVE_LANG } from '../../../configs/localization';

type Props = Pick<ContainerProps, 'countries' | 'isBusiness' | 'isLoggedIn'>;

const ContactPrimary: React.FC<Props> = props => {
  return (
    <>
      <SheetLayoutHeader>
        <Heading size={2}>
          <Translate id={'request_flow.headers.contact_main'} />
        </Heading>
        <p>
          <Translate id={'request_flow.headers.contact_sub'} />
          {!props.isLoggedIn && (
            <>
              <Translate id={'authentication.login.brief'} />
              <a href={`/${_ACTIVE_LANG}/customer/login?contact=true`}>
                <Translate id={'authentication.login.button'} />
              </a>
            </>
          )}
        </p>
      </SheetLayoutHeader>
      <SheetLayoutContent>
        <Field
          type={'checkbox'}
          name={customerTypeFields.type}
          options={[
            {
              name: 'individual',
              value: 'individual',
              label: <Translate id={'request_flow.contact.is_private'} />,
            },
            {
              name: 'business',
              value: 'business',
              label: <Translate id={'request_flow.contact.is_business'} />,
            },
          ]}
          component={InputSwitch}
        />
      </SheetLayoutContent>
      <FieldArray
        name={'contact.customer'}
        countries={props.countries}
        component={ContactFields}
        isBusiness={props.isBusiness}
      />
    </>
  );
};

export default ContactPrimary;

import { all, call, fork, put, PutEffect, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { actionTypes, arrayRemove, blur, change, clearFields } from 'redux-form';
import axios from 'axios';
import {
  actions as vavatoActions,
  getAuctionDeliveryDatetimePeriods,
  getAuctionForm,
  getAuctionTransportOrderDeadline,
  getContactDetails,
  getDeliveryDatetimePeriods,
  getDeliveryDetails,
  getDeliveryForm,
  getItemSets,
  getPickupDetails,
  getTransportPrice,
  types as vavatoActionTypes,
} from './ducks';
import {
  _BASE_URL,
  _CALCULATE_ROUTE_ENDPOINT,
  _DIFFERENT_DELIVERY_DATE_AMOUNT,
  _VAVATO_BASE_URL,
} from '../../utils/global';
import { duplicate } from '../../utils/helpers';
import { Price, VavatoFlowForms } from './interface';
import { http } from '../../utils/request';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { translate } from '../../utils/localization';
import _get from 'lodash/get';
import moment from 'moment-timezone';
import { getSingleSearchParam } from '../../utils/router';
import { matchPath } from 'react-router';
import { _FLOW_TYPE, prefix } from './';
import * as appUtils from '../../utils/basics';
import { getCountryIri } from '../../state/ducks/baseReducer';
import { uploadFile } from '../../state/sagas/uploadSaga';
import { searchGeoPoint } from '../../utils/geo';
import { getSessionIds } from '../../utils/eventTracking';

export function* importLots(action) {
  const path = action.payload.location.pathname;
  if (!matchPath(path, { path: `${prefix}/auction_lots_details` })) {
    return;
  }

  // Vavato lots are mainly reachable by lotPath, e.g. :
  // - /auctions/47-ironing-manikins-10-10-2014/lots/string-manikin-hs02-48365c45-fb70-4c4c-a6a3-f28b8d5840f8
  // - /nl/homepage/47-ironing-manikins-10-10-2014/string-manikin-hs02-48365c45-fb70-4c4c-a6a3-f28b8d5840f8

  const lotPaths = getLotPathsFromQueryParam(document.location.href);
  // if we don't have urls in the url
  if (lotPaths.length === 0) {
    // then try to get it from the referrer
    const lotPath = getLotPathFromVavatoUrl(document.referrer);
    if (!lotPath) {
      return;
    }
    /**
     * we got a useful referrer, push it to url,
     * this triggers a location change and we reconsider this import logic.
     * Plus we have a nice url for the user to copy to share or save.
     */
    const search = new URLSearchParams(action.payload.location.search);
    search.set('lot_paths', String(lotPath));
    yield put(push(path + '?' + search.toString()));
    return;
  }

  try {
    for (const lotPath of lotPaths) {
      const blurAction = blur(VavatoFlowForms.auctionLotForm, `lots[${lotPaths.indexOf(lotPath)}].id`, lotPath);
      yield put(blurAction);
    }
  } catch (error) {
    yield put(vavatoActions.pushNotification(translate('request_flow.Vavato.errors.fetch_error')));
  }
}

export const getLotPathsFromQueryParam = (url: string): string[] => {
  const getLotsFromQueryParams = getSingleSearchParam(url, 'lot_paths');

  const lotPaths: string[] = [];
  if (!getLotsFromQueryParams || getLotsFromQueryParams === '{lot_path}') {
    return lotPaths;
  }
  // multiple?
  if (getLotsFromQueryParams.indexOf(',') > -1) {
    const paramsLotPaths = getLotsFromQueryParams.split(',');
    paramsLotPaths.map(item => {
      if (item.length >= 20) {
        lotPaths.push(item);
      }
    });
    return lotPaths;
  }
  // single
  if (getLotsFromQueryParams.length >= 20) {
    lotPaths.push(getLotsFromQueryParams);
  }
  return lotPaths;
};

export const getLotPathFromVavatoUrl = (urlString: string): null | string => {
  if (urlString === '') {
    return null;
  }

  const url = new URL(urlString);

  // Do we have a vavato related url
  if (url.hostname.indexOf('vavato') === -1) {
    return null;
  }

  // e.g. url = https://www.vavato.com/nl/homepage/47-ironing-manikins-10-10-2014/string-manikin-hs02-48365c45-fb70-4c4c-a6a3-f28b8d5840f8
  const match = matchPath(url.pathname, { path: '/(en|nl)/homepage/:auction_slug/:lot_slug' });

  if (!_get(match, 'params.lot_slug', null)) {
    return null;
  }

  const auctionSlug = _get(match, 'params.auction_slug');
  const lotSlug = _get(match, 'params.lot_slug');
  return `/auctions/${auctionSlug}/lots/${lotSlug}`;
};

const handleLocationsResets = action => {
  const url = action.payload.location.pathname;
  if (url.indexOf(_FLOW_TYPE) === -1) {
    return;
  }
  document.body.scrollTop = document.documentElement.scrollTop = 0;
};

export function* priceHandler(action) {
  const priceFieldsArray = ['custom_delivery_date', 'custom_delivery_date_value'];
  if (matchPath(window.location.pathname, { path: `${_BASE_URL}${prefix}/delivery_details` })) {
    if (priceFieldsArray.includes(action.meta.field)) {
      yield call(calculatePrice);
    }
  }
}

export function* scanDeliveryAddress() {
  const delivery = yield select(getDeliveryDetails);
  if (matchPath(window.location.pathname, { path: `${_BASE_URL}${prefix}/delivery_details` })) {
    if (
      typeof delivery.address !== 'undefined' &&
      typeof delivery.address.postal_code !== 'undefined' &&
      typeof delivery.address.line1 !== 'undefined' &&
      typeof delivery.address.locality !== 'undefined'
    ) {
      const point = yield call(
        searchGeoPoint,
        `${delivery.address.line1}, ${delivery.address.postal_code}, ${delivery.address.locality}`
      );
      const countryIri = yield select(getCountryIri, point.data[0].address.country_code);
      if (typeof point.data[0] !== 'undefined') {
        yield all([
          put(change(VavatoFlowForms.deliveryForm, 'address.country', countryIri)),
          put(change(VavatoFlowForms.deliveryForm, 'address.lat', point.data[0].address.lat)),
          put(change(VavatoFlowForms.deliveryForm, 'address.lng', point.data[0].address.lon)),
        ]);
      } else {
        yield all([
          put(vavatoActions.pushWarning(translate('request_flow.auction.errors.invalid_address'))),
          put(clearFields(VavatoFlowForms.deliveryForm, false, true, 'address.lat')),
          put(clearFields(VavatoFlowForms.deliveryForm, false, true, 'address.lng')),
        ]);
      }
    }
  }
}

export function* removeLot(action) {
  // TODO: use variables and prefix for formnames, see general flow
  if (action.meta.form === VavatoFlowForms.auctionLotForm) {
    yield call(validateLots);
    yield call(calculatePrice);
  }
}

export function* calculatePrice() {
  const auctionForm = yield select(getAuctionForm);
  const selectedDates = yield select(getDeliveryDatetimePeriods);
  const auctionDates = yield select(getAuctionDeliveryDatetimePeriods);
  const delivery = yield select(getDeliveryForm);
  let rate: number = Price.AMOUNT;
  if (auctionForm && _get(auctionForm, 'values', null)) {
    auctionForm.values.lots.map((lot, index) => {
      if (_get(lot, 'id', null) && index !== 0) {
        rate += Price.EXTRA_RATE;
      }
    });

    if (delivery && !moment(selectedDates[0].start).isSame(moment(auctionDates[0].start), 'day')) {
      rate += _DIFFERENT_DELIVERY_DATE_AMOUNT;
    }
    yield put({ type: vavatoActionTypes.CALCULATE_PRICE, payload: rate });
  }
}

export function* fetchLotAndAuctionDetailsByLotPath(lotPath) {
  try {
    const response = yield call(fetchFromVavatoApi, lotPath);
    return yield call(parseLotAndAuctionApiResponse, response.data);
  } catch (error) {
    yield put(vavatoActions.pushNotification(translate('request_flow.Vavato.errors.fetch_error')));
  }
}

function fetchFromVavatoApi(lotPath) {
  const headers = { 'api-version': 'v1' };
  return axios.get(`${_VAVATO_BASE_URL}${lotPath}`, { headers }).then(resp => resp);
}

export function* parseLotAndAuctionApiResponse(json) {
  const country = yield select(getCountryIri, json.release_location.country.substr(0, 2));

  return {
    id: json.id,
    number: json.lot_number,
    auctionId: json.auction.id,
    title: json.title_translations.nl,
    description: json.description_translations.nl,
    thumbnail: `${_VAVATO_BASE_URL}/${json.attachments[0].urls[0].small}`,
    image: `${_VAVATO_BASE_URL}/${json.attachments[0].urls[2].large}`,
    address: {
      line1: json.release_location.address_line_1,
      line2: '',
      postalCode: json.release_location.zip,
      locality: json.release_location.city,
      country,
      latitude: json.release_loc_coordinates[0],
      longitude: json.release_loc_coordinates[1],
    },
    auction: {
      id: json.auction.id,
      slug: json.auction.slug,
      title: json.auction.name_translations.nl,
      address: {
        line1: json.release_location.address_line_1,
        line2: '',
        postalCode: json.release_location.zip,
        locality: json.release_location.city,
        country,
        latitude: json.release_loc_coordinates[0],
        longitude: json.release_loc_coordinates[1],
      },
      datetimePeriod: {
        start: json.release_from,
        end: json.release_to,
      },
    },
  };
}

export function postTransportRequest(customer, pickups, deliveries, price, items) {
  return http()
    .post('/transport_requests', {
      source_flow: 'Vavato',
      item_sets: items,
      price,
      customer,
      pickups,
      deliveries,
      session_ids: getSessionIds(),
      frontend_url: window.location.href,
    })
    .then(resp => resp);
}

export function* handleResponse(e) {
  if (typeof e.response !== 'undefined' && typeof e.response.status !== 'undefined') {
    switch (e.response.status) {
      case 400:
        yield put(vavatoActions.pushNotification(e.response.data['hydra:description']));
        break;
      default:
        yield put(vavatoActions.pushNotification(translate('request_flow.auction.errors.handle_error')));
        break;
    }
  } else {
    if (e.toString().indexOf('Network Error') > 0) {
      yield put(vavatoActions.pushNotification(translate('request_flow.auction.errors.handle_error')));
    }
  }
}

export function* submitDelivery() {
  if (matchPath(window.location.pathname, { path: `${_BASE_URL}${prefix}/delivery_details` })) {
    const auctionForm = yield select(getAuctionForm);
    if (!auctionForm) {
      yield put(push(`${prefix}/auction_lots_details`));
    }
    try {
      const customer = yield select(getContactDetails);
      const pickups = [yield select(getPickupDetails)];
      const deliveries = [yield select(getDeliveryDetails)];
      const price = yield select(getTransportPrice);
      const items = yield select(getItemSets);
      const response = yield call(postTransportRequest, customer, pickups, deliveries, price, items);
      yield all([
        put(vavatoActions.submitDeliveryStop(response)),
        put(push('/partner/vavato/transport_request/payment')),
      ]);
    } catch (e) {
      yield all([put(vavatoActions.submitDeliveryStop(e)), call(handleResponse, e)]);
    }
  }
}

export function* validateLots() {
  yield put(vavatoActions.pushNotification(null));

  const auctionForm = yield select(getAuctionForm);
  const lots = auctionForm.values.lots;
  const currentLots = auctionForm.values.lots;
  const auctionTransportOrderDeadline = yield select(getAuctionTransportOrderDeadline);

  // check different auction id
  const duplicateAuctions = currentLots.length > 1 ? duplicate(currentLots, 'auctionId') : [];

  if (Object.keys(duplicateAuctions).length > 1) {
    yield put(vavatoActions.pushNotification(translate('request_flow.auction.errors.different_auction')));
    return;
  }
  if (lots && lots.length > 0 && typeof lots[0] !== 'undefined' && typeof lots[0].number !== 'undefined') {
    for (const lot of lots) {
      if (_get(auctionForm, 'values.auction.datetimePeriod.start', null)) {
        if (!auctionForm.values.auction.datetimePeriod.start) {
          yield put(vavatoActions.pushNotification(translate('request_flow.auction.errors.no_pickup_datetime')));
          return;
        }
        if (moment().diff(auctionTransportOrderDeadline) > 0) {
          yield put(
            vavatoActions.pushNotification(translate('request_flow.auction.errors.auction_pickup_datetime_expired'))
          );
          return;
        }
      }

      // Check for list of auctions we support
      const SUPPORTED_AUCTION_SLUGS = process.env.REACT_APP_SUPPORTED_VAVATO_AUCTION_SLUGS || '0';

      if (SUPPORTED_AUCTION_SLUGS.indexOf(lot.auction.slug) < 0) {
        yield put(vavatoActions.pushNotification(translate('request_flow.auction.errors.unsupported_auction')));
        return;
      }

      const UNSUPPORTED_LOT_IDS = process.env.REACT_APP_UNSUPPORTED_VAVATO_LOT_IDS || '0';
      if (UNSUPPORTED_LOT_IDS.indexOf(lot.id) > -1) {
        yield put(vavatoActions.pushNotification(translate('request_flow.auction.errors.unsupported_lot')));
        return;
      }
    }
  } else {
    yield put(vavatoActions.pushNotification(translate('request_flow.auction.errors.at_least_one')));
  }
}

export function* submitLot(action) {
  yield put(vavatoActions.pushNotification(null));

  const redirectUrl = '/partner/vavato/transport_request/delivery_details';
  const lots = action.payload.lots;

  if (lots && lots.length > 0 && typeof lots[0] !== 'undefined' && typeof lots[0].number !== 'undefined') {
    yield put(push(redirectUrl));
  } else {
    yield put(vavatoActions.pushNotification(translate('request_flow.auction.errors.at_least_one')));
  }
}

export function* watchLotInput(action) {
  // watching lot input changes
  if (matchPath(window.location.pathname, { path: `${_BASE_URL}${prefix}/auction_lots_details` })) {
    if (
      action.meta.form === VavatoFlowForms.auctionLotForm &&
      action.meta.field.match(/lots\[[0-9]\].[id|number|url]/g) !== null
    ) {
      action.payload = getLotPathFromVavatoUrl(action.payload);
      yield call(fetchLot, action);
    }
  }
}

export function* watchDeliveryAddress(action) {
  // fetching delivery address
  if (matchPath(window.location.pathname, { path: `${_BASE_URL}${prefix}/delivery_details` })) {
    if (action.meta.form === VavatoFlowForms.deliveryForm && action.meta.field.indexOf('address') >= 0) {
      yield call(scanDeliveryAddress);
    }
  }
}

export function* fetchLot(action) {
  // TODO: refactor and write test
  yield put(vavatoActions.pushNotification(null));

  try {
    // fetch lot details
    const lotDetails = yield call(fetchLotAndAuctionDetailsByLotPath, action.payload);
    if (lotDetails) {
      if (lotDetails.image) {
        const lotImage = yield call(uploadFile, lotDetails.image, '/job_images');
        if (lotImage.status === 201) {
          lotDetails['job_image'] = lotImage.data['@id'];
        }
      }

      yield put(change(VavatoFlowForms.auctionLotForm, action.meta.field.split('.')[0], lotDetails));
      // handle auction details
      if (action.meta.field === 'lots[0].id' || action.meta.field === 'lots[0].number') {
        // yield call(fetchAuctionDetails, lotDetails.auctionId);
        if (lotDetails.auction.datetimePeriod === null) {
          yield all([
            put(arrayRemove(VavatoFlowForms.auctionLotForm, 'lots', 0)),
            put(vavatoActions.pushNotification('We could not load the auction details!')),
          ]);
          return null;
        }
        const addToFormActions = [] as PutEffect[];
        addToFormActions.push(put(change(VavatoFlowForms.auctionLotForm, 'auction', lotDetails.auction)));

        yield all(addToFormActions);
      }

      yield call(validateLots);
      // price calculation
      yield call(calculatePrice);
    }
  } catch (error) {
    // something went wrong with fetching
    const lotsForm = yield select(getAuctionForm);
    const currentLots = lotsForm.values.lots;
    // clean added failed lot from store
    yield put(arrayRemove(VavatoFlowForms.auctionLotForm, 'lots', currentLots.length - 1));
    yield put(vavatoActions.pushNotification(translate('request_flow.Vavato.errors.fetch_error')));
  }
}

function* handleProgressBar(action) {
  const url = action.payload.location.pathname;
  if (url.indexOf(_FLOW_TYPE) === -1) {
    return;
  }
  const event = {
    event: 'Flow progress',
    flow_type: _FLOW_TYPE,
  };

  if (matchPath(url, { path: `${prefix}/auction_lots_details` })) {
    event['progress_label'] = 'Step 1 - Lots';
    event['progress_value'] = 1;
    yield put(vavatoActions.setProgress(1));
  }
  if (matchPath(url, { path: `${prefix}/delivery_details` })) {
    event['progress_label'] = 'Step 2 - Delivery';
    event['progress_value'] = 2;
    yield put(vavatoActions.setProgress(2));
  }
  if (matchPath(url, { path: `${prefix}/payment` })) {
    event['progress_label'] = 'Step 3 - Payment';
    event['progress_value'] = 3;
    yield put(vavatoActions.setProgress(3));
  }

  if (!_get(event, 'progress_label', false)) {
    return;
  }

  appUtils.pushToDataLayer(event);
}

function* lotSaga() {
  yield takeLatest(actionTypes.ARRAY_REMOVE, removeLot);

  yield takeLatest(LOCATION_CHANGE, importLots);
  yield takeLatest(LOCATION_CHANGE, handleLocationsResets);
  yield takeLatest(LOCATION_CHANGE, handleProgressBar);

  yield takeEvery(actionTypes.BLUR, watchLotInput);
  yield takeEvery(actionTypes.BLUR, watchDeliveryAddress);

  yield takeLatest(actionTypes.CHANGE, priceHandler);

  yield takeLatest(vavatoActionTypes.SUBMIT_LOTS_DETAILS_START, submitLot);
  yield takeLatest(vavatoActionTypes.SUBMIT_DELIVERY_START, submitDelivery);
}

export function* VavatoSagas() {
  yield fork(lotSaga);
}

import * as React from 'react';
import './card.css';
import './card-with-image.css';
import { missingImageSrc } from '../../../global';
import { PriceDisplay } from '../../../index';

interface Props {
  extraClasses?: string;
  header: string;
  details: any;
  price?: number;
  image?: string;
  onClick?: any;
}

export const CardWithImage = ({ extraClasses = '', header, details, image, price, onClick = null }: Props) => {
  return (
    <div className={'card card-with-image ' + extraClasses + (onClick ? ' trigger' : '')} onClick={onClick}>
      <div className={'card-with-image--content'}>
        <div className={'card-with-image--header'}>{header}</div>
        <div className={'card-with-image--details'}>{details}</div>
      </div>
      <div
        className="card-with-image--media-wrap"
        style={{ backgroundImage: 'url(' + (image ? image : missingImageSrc) + ')' }}
      >
        {price && <PriceDisplay cents={price} />}
      </div>
    </div>
  );
};
export default CardWithImage;

import * as React from 'react';
import './icons.css';

const IconHome = () => {
  return (
    <svg className={'IconHome'} width="30" height="26" viewBox="0 0 30 26">
      <g fill="#0073FA" fillRule="nonzero">
        <path d="M1.334 12.939c.25.25.66.251.91.001l12.66-10.66.453-1.365a.646.646 0 0 0-.911-.001L1.332 12.028c-.25.25-.25.66.002.91z" />
        <path d="M28.48 12.939a.646.646 0 0 1-.911.001L14.909 2.28 14.456.916c.251-.25.661-.251.911-.001l13.114 11.114c.25.25.25.66-.002.91zM9.336 1.226a.646.646 0 0 0-.642-.645.646.646 0 0 0-.643.645v2.42c0 .354.289.645.643.645.353 0 .642-.29.642-.646V1.226zM29.355 24.714h-5.258V12.332a.646.646 0 0 0-1.29 0v12.382H7.225V12.332a.646.646 0 0 0-1.29 0v12.382H.71a.646.646 0 0 0-.645.643c0 .354.29.643.645.643h28.645c.355 0 .645-.29.645-.643a.646.646 0 0 0-.645-.643z" />
      </g>
    </svg>
  );
};

export default IconHome;

import { IconSmile } from '../../../brenger-shared-ui/components/basics/icons';
import placeholder from '../../../assets/images/placeholder.png';
import { Heading } from '../../../brenger-shared-ui';
import { priceAsString } from '../../../utils/price';
import React from 'react';
import { ChatInterface } from '../interface';

export class ChatHeader extends React.Component<ChatInterface, any> {
  public render() {
    return (
      <div className={'chat-section--header'}>
        <div className={'chat-section--header-person'}>
          <div className={'chat-section--close'} onClick={() => this.props.closeChat()}>
            <IconSmile />
          </div>
          <div className={'chat-section--driver-details'}>
            <img className={'chat-section--driver-details-image'} src={placeholder} />
            <div className={'driver-fullname'}>{this.props.driver.driver_user.first_name}</div>
          </div>
        </div>
        <div className={'chat-section--header-request'}>
          <Heading size={4} extraClasses={'chat-section--header-request--title no-margin'}>
            <div>{this.props.title}</div>
            <div>{this.props.price && priceAsString(this.props.price.amount)}</div>
          </Heading>
          <div className={'chat-section--header-request--id pt-0-5'}>ID: {this.props.short_id}</div>
        </div>
      </div>
    );
  }
}

export default ChatHeader;

import { CarryingHelpChoices, DatetimePeriod, GeoPoint, NotGuaranteedState } from '../../typings';
import {
  _IKEA_BARGAINS_TIME_PICKUP_END,
  _IKEA_BARGAINS_TIME_PICKUP_START,
  _IKEA_TIME_PICKUP_END,
  _IKEA_TIME_PICKUP_START,
} from '../../utils/global';
import { ItemSet } from '../GeneralFlow/interface';

// INTERFACES & PROP TYPES

export interface IKEATransport {
  request: {
    errors: null | string;
    warnings: string;
    step: number;
    submitting: boolean;
    uuid: null;
    not_guaranteed: NotGuaranteedState;
  };
}

export interface IKEAStoreData {
  active: boolean;
  storeName: string;
  storeLabel: string;
  warehouse: WarehouseOption[];
  address: {
    lat: number;
    lng: number;
    line1: string;
    line2: string;
    locality: string;
    postal_code: string;
    country_code: string;
  };
  conditions: {
    price: number;
    selectableDates?: number;
    pickup: {
      start_time: number;
      end_time: number;
      carrying_help: CarryingHelpChoices;
      floor_level: number;
    };
    delivery: {
      InNumberOfDays: number;
      start_time: number;
      end_time: number;
      order_deadline: number;
      floor_level: number;
      carrying_help: CarryingHelpChoices;
    };
  };
  config: {
    hasItemSetConfigurator: boolean;
  };
}

export enum Lot {
  id = 'id',
  number = 'number',
  invoice = 'invoice',
  title = 'title',
  image = 'image',
  description = 'description',
}

export enum User {
  first_name = 'first_name',
  last_name = 'last_name',
  email = 'email',
  phone = 'phone',
}

export enum Delivery {
  line1 = 'line1',
  line2 = 'line2',
  postal_code = 'postal_code',
  instructions = 'instructions',
  locality = 'locality',
  latitude = 'latitude',
  longitude = 'longitude',
  custom_delivery_date = 'custom_delivery_date',
  conditions = 'conditions',
}

export enum IkeaPriceDetails {
  EXTRA_RATE = 600,
  RETURN_ITEM_RATE = 6900,
  CURRENCY = 'EUR',
}

export interface UserInterface {
  first_name: string;
  last_name: string;
  email: string;
  phone: number;
}

export interface PriceInterface {
  price: number;
  currency: string;
}

export interface StepInterface {
  step: number;
  total: number;
  goBack: any;
  pickup: GeoPoint;
  delivery: GeoPoint;
}

export interface DeliveryInterface {
  contact: object;
  address: object;
  details: object;
  available_datetime_periods: DatetimePeriod[];
}

export enum WarehouseOption {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
  BOTH = 'both',
}

export enum IkeaFlowForms {
  PICKUP_DETAILS = '@@IkeaFlow/pickupDetailsForm',
  PICKUP_OPTIONS = '@@IkeaFlow/pickupOptionsForm',
  DATE = '@@IkeaFlow/DATE',
  DELIVERY = '@@IkeaFlow/deliveryForm',
  PAYMENT = '@@IkeaFlow/paymentForm',
}

export enum IkeaFlowFields {
  PICKUP_LOCATION = 'pickup_location',
  WAREHOUSE = 'pickup_warehouse',
  INVOICE = 'invoices',
  IMAGE = 'images',
  RECEIPT = 'receipt',
  ISELL = 'isell',
  WEIGHT = 'weight',
  COLLIES = 'collies',
  COUNT = 'count',
  ITEM_SETS = 'itemSets',
  DATE = 'date',
  LINE1 = 'address.line1',
  POSTAL_CODE = 'address.postal_code',
  LOCALITY = 'address.locality',
  COUNTRY_CODE = 'address.country_code',
  LAT = 'address.lat',
  LNG = 'address.lng',
  INSTRUCTIONS = 'address.instructions',
}

export interface IKEAItemSet extends ItemSet {
  attributes: IKEAItemSetAttributes;
  images?: any[];
}

export interface IKEAItemSetAttributes {
  warehouse: WarehouseOption;
  [IkeaFlowFields.COLLIES]?: number;
  [IkeaFlowFields.ISELL]?: string;
}

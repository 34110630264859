import React from 'react';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { push } from 'connected-react-router';
import { Card, Col, Heading, IconShopBlue, Label, Row, SectionWrapper } from '../../../brenger-shared-ui';
import { getNewTransportRequestLink, hasBusinessFlow } from '../ducks';
import { prefix as trackAndTracePrefix } from '../../StatusPage';
import { prefix as invoicesPrefix } from '../../User/containers/Invoices';
import { actions as userActions, getLoggedInUser, getTotalNumberInvoices } from '../../User/ducks';
import { actions, getTotalNumberTransportRequests } from '../../StatusPage/ducks';
import '../../../assets/business/dashboard.css';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export const Dashboard: React.FC<Props> = props => {
  const {
    resetFilters,
    userDetails,
    goToPath,
    hasDedicatedBusinessFlow,
    totalTrCount,
    newTrLink,
    getTransportRequests,
    fetchInvoices,
    totalInvoices,
  } = props;
  React.useEffect(() => {
    // check if logged in
    if (!userDetails.loggedIn) {
      goToPath('/login');
      return;
    }
    // reset and get total
    resetFilters();
    getTransportRequests();
    fetchInvoices();
  }, []);

  const accountName = _get(userDetails, 'userData.account.name', false);
  return (
    <Col xs={12}>
      <SectionWrapper sectionStyle={'primary'} centerContent={true} fullScreen={true}>
        <Col xs={12}>
          <div className={'pt-1 pb-1'} style={{ marginTop: '60px' }}>
            <div className={'dashboard-welcome'}>
              <div className={'icon-wrapper'}>
                <div className={'dashboard-welcome--logo'}>
                  <IconShopBlue />
                </div>
              </div>
              <Heading size={2} extraClasses={'text--center no-margin'}>
                <Translate id={`request_flow.customer_dashboard.title`} />
              </Heading>
              {accountName && <div className={'business-name text--center'}>{accountName}</div>}
            </div>
          </div>
          <Row>
            <Col xs={6} extraClasses={'pb-1'}>
              <Card onClick={() => goToPath(trackAndTracePrefix)} withBrengerSmile={true}>
                <div className={'pb-0-5 text--bold'}>
                  <Translate
                    id={`request_flow.customer_dashboard.orders${hasDedicatedBusinessFlow ? '_business' : ''}`}
                  />
                </div>
                {totalTrCount}
              </Card>
            </Col>
            <Col xs={6} extraClasses={'pb-1'}>
              <Card onClick={() => goToPath(invoicesPrefix)} withBrengerSmile={true}>
                <div className={'pb-0-5 text--bold'}>
                  <Translate id={`request_flow.customer_dashboard.invoices`} />
                </div>
                {totalInvoices}
              </Card>
            </Col>
            <Col xs={12} extraClasses={'pb-1'}>
              <Card onClick={() => goToPath(newTrLink)} withBrengerSmile={true}>
                <Label type={'green'}>
                  <Translate id={`request_flow.customer_dashboard.new_tr.greeting`} />
                </Label>
                <Heading size={5} extraClasses={'no-margin'}>
                  <Translate id={`request_flow.customer_dashboard.new_tr.title`} />
                </Heading>
                <Translate id={`request_flow.customer_dashboard.new_tr.sub_title`} />
              </Card>
            </Col>
          </Row>
        </Col>
      </SectionWrapper>
    </Col>
  );
};

const mapStateToProps = state => ({
  newTrLink: getNewTransportRequestLink(state),
  userDetails: getLoggedInUser(state),
  totalTrCount: getTotalNumberTransportRequests(state),
  totalInvoices: getTotalNumberInvoices(state),
  hasDedicatedBusinessFlow: hasBusinessFlow(state),
});

const mapDispatchToProps = dispatch => ({
  fetchInvoices: () => dispatch(userActions.fetchInvoices()),
  goToPath: path => dispatch(push(path)),
  resetFilters: () => dispatch(actions.resetFilters()),
  getTransportRequests: () => dispatch(actions.setFilters([], true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

import React from 'react';
import { getLocation } from 'connected-react-router';
import cn from 'classnames';
import { Translate } from 'react-localize-redux';

import { CirclePerson2, Toast } from '../../../../brenger-shared-ui';
import { useNotification } from '../../../../hooks/useNotification';
import { useTypedSelector } from '../../../../hooks';

export const CoronaNotification: React.FC = () => {
  const notification = useNotification('corona');
  const { pathname } = useTypedSelector(getLocation);

  React.useEffect(() => {
    if (pathname.includes('home_and_floor')) {
      notification.add();
    } else {
      notification.remove();
    }
  }, [pathname]);

  return (
    <div className={cn('toast-container', { 'toast--visible': notification.isVisible })}>
      <Toast prefix={<CirclePerson2 />} handleClose={notification.removeForOneSession}>
        <div className={'pt-0-5'} style={{ paddingRight: '15px' }}>
          <b>
            <Translate id={'request_flow.widget.corona.heading'} />
          </b>
        </div>
        <div>
          <Translate id={'request_flow.widget.corona.text'} />
        </div>
      </Toast>
    </div>
  );
};

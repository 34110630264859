import * as React from 'react';
import './icons.css';

const IconCircleClock = () => {
  return (
    <svg width="46" height="46" viewBox="0 0 46 46">
      <g fill="none" fillRule="evenodd">
        <circle cx="23" cy="23" r="23" fill="#A2CDFF" />
        <path
          fill="#005FCE"
          stroke="#005FCE"
          strokeWidth=".2"
          d="M11.128 22.187a.535.535 0 0 1-.475-.591c.712-6.24 6.02-10.946 12.347-10.946s11.635 4.706 12.347 10.946a.535.535 0 0 1-.536.595.537.537 0 0 1-.534-.475C33.627 16.017 28.78 11.72 23 11.72c-5.78 0-10.627 4.298-11.277 9.997a.537.537 0 0 1-.595.471zM23 35.35c-6.327 0-11.635-4.706-12.347-10.946a.535.535 0 0 1 .475-.591.535.535 0 0 1 .595.47c.65 5.7 5.498 9.998 11.277 9.998 5.78 0 10.627-4.298 11.277-9.997a.535.535 0 0 1 .595-.471.535.535 0 0 1 .475.591C34.635 30.644 29.327 35.35 23 35.35zm5.61-11.637h-5.687a.537.537 0 0 1-.538-.535v-7.381c0-.296.24-.535.538-.535.298 0 .539.24.539.535v6.846h5.149c.297 0 .538.24.538.535a.537.537 0 0 1-.538.535z"
        />
      </g>
    </svg>
  );
};

export default IconCircleClock;

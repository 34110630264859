import React from 'react';
import moment from 'moment';
import { Button, Card, Col, Heading, Label, LocationDisplay, Row, SectionWrapper } from '../../../brenger-shared-ui';
import { getTrTitle, getTrUuid, isBusinessReturnOrder } from '../../../utils/TransportRequest';
import { Translate } from 'react-localize-redux';
import { TJ_STATE } from '../../../utils/global';
import { translate } from '../../../utils/localization';
import { getItemSetsUniqueClientReferences } from '../../../utils/itemSet';
import { Commitment, ItemSetState } from '../../../typings';

interface Props {
  transportRequest: any;
  openSingleView: any;
}

export const TrBusinessCard: React.FC<Props> = ({ transportRequest, openSingleView }) => {
  const isReturn = isBusinessReturnOrder(transportRequest);
  const title = getTrTitle(transportRequest);
  const orderAddressDetails = isReturn ? transportRequest?.pickups[0] : transportRequest?.deliveries[0];
  const driverUser = transportRequest?.driver_user;
  const clientReferences = getItemSetsUniqueClientReferences(transportRequest.item_sets);
  const state = transportRequest?.item_sets[0]?.state || TJ_STATE.SUBMITTED;
  return (
    <Card
      extraClasses={'card--tr-business card--shade-2'}
      onClick={() => openSingleView(getTrUuid(transportRequest['@id']))}
    >
      <SectionWrapper sectionStyle={'primary-light'}>
        <Row>
          <Col xs={12} sm={8} md={9}>
            <Label type={state === ItemSetState.CANCELLED ? 'red' : 'green'}>
              {String(translate('request_flow.state.' + state)).toUpperCase()}
            </Label>
            {isReturn && (
              <Label type={'blue'}>{String(translate('request_flow.business.return_label')).toUpperCase()}</Label>
            )}
            <Heading size={5} extraClasses={'transport-request-head no-margin'}>
              <span className={'transport-request-head--label'}>
                {orderAddressDetails?.contact?.first_name} {orderAddressDetails?.contact?.last_name}
              </span>
            </Heading>
          </Col>
          <Col xs={12} sm={4} md={3} extraClasses={'card--tr-business--actions'}>
            <Button fullWidth={true}>
              <Translate id={'request_flow.status.view_tr'} />
            </Button>
          </Col>
          <Col xs={12}>{title}</Col>
        </Row>
      </SectionWrapper>
      {orderAddressDetails && (
        <Row>
          <Col xs={12} extraClasses={'pb-0-25'}>
            <Label>
              <Translate id={'request_flow.summary.carrying_help.' + orderAddressDetails.details.carrying_help} />
            </Label>
          </Col>
          <Col xs={12} md={9} lg={8}>
            <LocationDisplay isPickup={false} color={'white'}>
              <div className={'location-address text--bold'}>
                {orderAddressDetails.address.line1}{' '}
                <div className={'inline-block'}>
                  {orderAddressDetails.address.postal_code}, {orderAddressDetails.address.locality}
                </div>
              </div>
            </LocationDisplay>
            {driverUser && (
              <div className="list--open-dot text--bold">
                <Translate id={'request_flow.business.driver'} />: {driverUser.first_name} {driverUser.Last_name}
              </div>
            )}
            {/* Commitments are available when a driver made an offer (and that offer is accepted) */}
            {orderAddressDetails.commitments &&
              orderAddressDetails.commitments.map((commitment: Commitment, index) => {
                return (
                  <div key={index} className="list--open-dot text--bold">
                    {moment(commitment.committed_datetime_period.start).format('dd DD-MM: HH:mm')} -{' '}
                    {moment(commitment.committed_datetime_period.end).format('HH:mm')}
                  </div>
                );
              })}
          </Col>
          <Col xs={12} md={3} lg={4} extraClasses={'card--tr-business--short-id text--grey'}>
            <Translate id={'request_flow.business.short_id'} />: {transportRequest['short_id']}
            {clientReferences.length > 0 && (
              <div className="reference">
                <Translate id={'request_flow.business.client_reference'} />: {clientReferences.join(',')}
              </div>
            )}
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default TrBusinessCard;

import React from 'react';
import classNames from 'classnames';
import { CirclePerson2, Toast } from '../../../../brenger-shared-ui';
import { Translate } from 'react-localize-redux';
import { useNotification } from '../../../../hooks/useNotification';
import { useTypedSelector } from '../../../../hooks';

export const ContactNotification: React.FC = () => {
  const notification = useNotification('offer-limited-until-midnight');
  const progressStep = useTypedSelector(state => state.generalTransport.layout.step);
  React.useEffect(() => {
    if (progressStep === 7) {
      notification.add();
    } else {
      notification.remove();
    }
  }, [progressStep]);
  return (
    <div className={classNames('toast-container', { 'toast--visible': notification.isVisible })}>
      <Toast prefix={<CirclePerson2 />} handleClose={notification.removeForOneSession}>
        <div className={'pt-0-5'} style={{ paddingRight: '15px' }}>
          <b>
            <Translate id={'request_flow.contact.notification.header'} />
          </b>
        </div>
        <Translate id={'request_flow.contact.notification.text'} />
      </Toast>
    </div>
  );
};

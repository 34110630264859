import { Button, IconPaperPlane } from '../../../brenger-shared-ui';
import { translate } from '../../../utils/localization';
import React from 'react';
import { ChatInterface } from '../interface';

interface State {
  message: string;
  format: string;
}

export class Compose extends React.Component<ChatInterface, State> {
  public constructor(props) {
    super(props);
    this.state = {
      message: '',
      format: 'plain',
    };
  }
  public componentDidUpdate(nextProps, nextState) {
    if (this.props.sending === false && this.props.sending !== nextProps.sending && nextState.message !== '') {
      this.setState({ message: '' });
    }
  }
  public enterMessage(event, msg, transportRequest) {
    if (event.which === 13 || event.keyCode === 13 || event.key === 'Enter') {
      this.props.sendMessage(msg, 'plain', transportRequest);
    }
  }
  public render() {
    return (
      <div className={'chat-section--footer'}>
        <div className={'chat-section--actions-offer'}>
          {!this.props.hideActionBar && (
            <>
              {/*<Button
                loading={this.props.driver['loading']}
                disabled={this.props.driver['loading']}
                onClick={() => this.props.confirm(this.props.driver['@id'])}
                fullWidth={true}
                buttonStyle={'primary'}
              >
                <Translate id={'request_flow.actions.confirm_offer'} />
              </Button>
              <Button
                loading={this.props.driver['loading']}
                disabled={this.props.driver['loading']}
                onClick={() => this.props.reject(this.props.driver['@id'])}
                fullWidth={true}
                buttonStyle={'outline'}
              >
                <Translate id={'request_flow.actions.reject_offer'} />
              </Button>*/}
            </>
          )}
        </div>
        <div className={'chat-section--actions'}>
          <input
            onChange={e => this.setState({ message: e.currentTarget.value })}
            onKeyDown={e => this.enterMessage(e, this.state.message, this.props.transport_request)}
            id={'chat-body'}
            value={this.state.message}
            type={'text'}
            disabled={this.props.sending}
            placeholder={String(translate('request_flow.chat.send_message'))}
            name={'body'}
          />
          <Button
            disabled={this.props.sending || this.state.message.length === 0}
            loading={this.props.sending}
            onClick={() => this.props.sendMessage(this.state.message, 'plain', this.props.transport_request)}
          >
            <IconPaperPlane />
          </Button>
        </div>
      </div>
    );
  }
}

export default Compose;

import React from 'react';
import GoogleMap from 'google-map-react';
import { _GOOGLE_MAP_KEY } from '../../../utils/global';
import { normalizeCoords, styledMapType } from '../../../utils/maps';
import { LocationMarker } from '../../../brenger-shared-ui/components/other/locationmarker/LocationMarker';
import { fitBounds } from 'google-map-react/utils';
import { Translate } from 'react-localize-redux';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

interface Props {
  pickup?: any;
  delivery?: any;
  routeData?: any;
}

const options = {
  disableDefaultUI: true,
  styles: styledMapType,
};

export class RenderMap extends React.Component<Props, any> {
  private constructor(props) {
    super(props);
    this.state = {
      render: true,
    };
  }

  public renderPolylines(map, maps) {
    if (!_get(this.props.routeData, 'points', false)) {
      return;
    }
    const coords = normalizeCoords(this.props.routeData.points);
    const routePolyline = new maps.Polyline({
      path: coords,
      strokeColor: '#0073fa',
      strokeOpacity: 1.0,
      strokeWeight: 4,
      zIndex: 10,
    });
    routePolyline.setMap(map);
  }

  public componentDidUpdate(nextProps) {
    if (
      this.props.routeData !== nextProps.routeData ||
      this.props.pickup !== nextProps.pickup ||
      this.props.delivery !== nextProps.delivery
    ) {
      this.setState({ render: false });
      // trying to re render the map
      setTimeout(() => {
        this.setState({ render: true });
      }, 1000);
    }
  }

  public getZoomCenter(pickup, delivery) {
    if (!pickup && !delivery) {
      return { center: { lat: 52.370216, lng: 4.895168 }, zoom: 8 }; // default return, should be locale based
    }
    if (pickup && !delivery) {
      return { center: { lat: parseFloat(pickup.lat), lng: parseFloat(pickup.lng) }, zoom: 9 };
    }
    if (delivery && !pickup) {
      return { center: { lat: parseFloat(delivery.lat), lng: parseFloat(delivery.lng) }, zoom: 9 };
    }
    // Set bounds to calculate area to be shown
    const bounds = {
      ne: {
        lat: Math.max(pickup.lat, delivery.lat),
        lng: Math.max(pickup.lng, delivery.lng),
      },
      sw: {
        lat: Math.min(pickup.lat, delivery.lat),
        lng: Math.min(pickup.lng, delivery.lng),
      },
    };
    return fitBounds(bounds, { width: 220, height: 120 });
  }
  public render() {
    const pickupLat = _get(this.props.pickup, 'lat', false);
    const pickupLon = _get(this.props.pickup, 'lng', false);
    const deliveryLat = _get(this.props.delivery, 'lat', false);
    const deliveryLon = _get(this.props.delivery, 'lng', false);
    let pickup: any = false;
    if (pickupLat && pickupLon) {
      pickup = {
        lat: pickupLat,
        lng: pickupLon,
      };
    }
    let delivery: any = false;
    if (deliveryLat && deliveryLon) {
      delivery = {
        lat: deliveryLat,
        lng: deliveryLon,
      };
    }
    const isEqual = _isEqual(pickup, delivery);
    if (isEqual) {
      // clear the value of delivery, then it falls back to showing pickup only.
      delivery = false;
    }
    return (
      <div className={'google-maps-wrapper'}>
        <div className={'google-maps-sizer'}>
          <GoogleMap
            bootstrapURLKeys={{ key: _GOOGLE_MAP_KEY }}
            center={this.getZoomCenter(pickup, delivery).center}
            zoom={this.getZoomCenter(pickup, delivery).zoom}
            options={() => options}
            margin={[50, 50, 50, 50]}
            yesIWantToUseGoogleMapApiInternals={true}
            onGoogleApiLoaded={({ map, maps }) => this.renderPolylines(map, maps)}
          >
            {pickup && (
              <LocationMarker
                direction={'pickup'}
                textLabel={
                  // When equal lat/lon we only show this marker
                  <Translate id={'transport_request.pickup' + (isEqual ? '_and_delivery' : '')} />
                }
                lat={pickup.lat}
                lng={pickup.lng}
              />
            )}
            {delivery && (
              <LocationMarker
                direction={'delivery'}
                textLabel={<Translate id={'transport_request.delivery'} />}
                lat={delivery.lat}
                lng={delivery.lng}
              />
            )}
          </GoogleMap>
        </div>
      </div>
    );
  }
}

export default RenderMap;

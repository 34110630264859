import * as appUtils from '../../utils/basics';

import {
  actions as IkeaActions,
  getContactDetails,
  getDeliveryDetails,
  getItemSets,
  getPickupDetails,
  getSelectedStoreData,
  getTransportPrice,
  types as ikeaActionTypes,
} from './ducks';
import { IkeaFlowFields, IkeaFlowForms, WarehouseOption } from './interface';
import { getLocation, LOCATION_CHANGE, push } from 'connected-react-router';
import { _FLOW_TYPE, MODULE_PREFIX, prefix } from './';
import { _BASE_URL, _MAX_IKEA_SERVICE_DISTANCE, _MAX_WEIGHT_IKEA } from '../../utils/global';
import { actionTypes, change, clearFields, getFormValues, reset } from 'redux-form';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { calculateRoute, searchGeoPoint } from '../../utils/geo';

import { NotGuaranteedReason } from '../../typings';
import _get from 'lodash/get';
import { http } from '../../utils/request';
import { matchPath } from 'react-router';
import { resetUploadField } from '../../state/sagas/uploadSaga';
import { translate } from '../../utils/localization';
import { getSessionIds } from '../../utils/eventTracking';

// verify the form action types
const isIkeaFlowFormAction = (action: any) => {
  return action.meta.form.indexOf(MODULE_PREFIX) > -1;
};

function* autocompleteDeliveryAddress(action) {
  if (
    !isIkeaFlowFormAction(action) ||
    ![IkeaFlowFields.LINE1, IkeaFlowFields.POSTAL_CODE, IkeaFlowFields.LOCALITY].includes(action.meta.field)
  ) {
    return;
  }
  // Do we have complete delivery details
  const delivery = yield select(getDeliveryDetails);
  const line1 = _get(delivery, IkeaFlowFields.LINE1, false);
  const postalCode = _get(delivery, IkeaFlowFields.POSTAL_CODE, false);

  // if not bail here
  if (!postalCode || !line1) {
    return;
  }
  try {
    const deliveryResponse = yield call(searchGeoPoint, `${line1}, ${postalCode}`);
    const firstResultAddress = _get(deliveryResponse, 'data[0].address');
    if (!firstResultAddress) {
      throw new Error('We have no results');
    }
    yield all([
      put(change(IkeaFlowForms.DELIVERY, IkeaFlowFields.COUNTRY_CODE, firstResultAddress.country_code)),
      put(change(IkeaFlowForms.DELIVERY, IkeaFlowFields.LAT, firstResultAddress.latitude)),
      put(change(IkeaFlowForms.DELIVERY, IkeaFlowFields.LNG, firstResultAddress.longitude)),
      put(change(IkeaFlowForms.DELIVERY, IkeaFlowFields.LOCALITY, firstResultAddress.locality)),
    ]);
  } catch (e) {
    appUtils.logger(e);
    yield all([
      put(IkeaActions.pushWarning(translate('request_flow.auction.errors.invalid_address'))),
      put(clearFields(IkeaFlowForms.DELIVERY, false, true, IkeaFlowFields.LAT)),
      put(clearFields(IkeaFlowForms.DELIVERY, false, true, IkeaFlowFields.LNG)),
      put(clearFields(IkeaFlowForms.DELIVERY, false, true, IkeaFlowFields.COUNTRY_CODE)),
    ]);
  }
}

export function postTransportRequest(customer, pickups, deliveries, price, items) {
  return http().post('/transport_requests', {
    source_flow: 'IKEA',
    item_sets: items,
    price,
    customer,
    pickups,
    deliveries,
    session_ids: getSessionIds(),
    frontend_url: window.location.href,
  });
}

export function* handleResponse(e) {
  if (typeof e.response !== 'undefined' && typeof e.response.status !== 'undefined') {
    switch (e.response.status) {
      case 400:
        yield put(IkeaActions.pushNotification(e.response.data['hydra:description']));
        break;
      default:
        yield put(IkeaActions.pushNotification(translate('request_flow.auction.errors.handle_error')));
        break;
    }
  } else {
    if (e.toString().indexOf('Network Error') > 0) {
      yield put(IkeaActions.pushNotification(translate('request_flow.auction.errors.handle_error')));
    }
  }
}

export function* submitDelivery() {
  // We splitted up the delivery form, so when submitting from the first part, we should send the user to second part
  const location = yield select(getLocation);
  if (matchPath(location.pathname, { path: `${prefix}/delivery_details`, exact: true })) {
    yield put(push(prefix + '/delivery_details/address'));
    return;
  }
  // double check for complete values
  const pickupForm = yield select(getFormValues(IkeaFlowForms.PICKUP_DETAILS));
  if (!pickupForm) {
    yield put(push(prefix));
  }

  try {
    // gather the data for TR
    const customer = yield select(getContactDetails);
    const pickups = [yield select(getPickupDetails)];
    const deliveries = [yield select(getDeliveryDetails)];
    const price = yield select(getTransportPrice);
    const items = yield select(getItemSets);
    // create TR
    const response = yield call(postTransportRequest, customer, pickups, deliveries, price, items);
    // send user to payment
    yield all([put(IkeaActions.submitDeliveryStop(response)), put(push(`${prefix}/payment`))]);
  } catch (e) {
    appUtils.logger(e);
    yield all([put(IkeaActions.submitDeliveryStop(e)), call(handleResponse, e)]);
  }
}

export function* storeSelectionChange(action) {
  if (!isIkeaFlowFormAction(action) || action.meta.field !== IkeaFlowFields.PICKUP_LOCATION) {
    return;
  }
  // Whenever the pickup location changes, we got to make sure the warehouse option changes as well
  // To prevent unwanted sync errors
  try {
    const selectedStore = yield select(getSelectedStoreData);
    const hasNoWareHouseOptions = selectedStore?.warehouse?.length === 0;
    const defaultChoice = hasNoWareHouseOptions ? WarehouseOption.INTERNAL : '';
    yield put(change(IkeaFlowForms.PICKUP_DETAILS, IkeaFlowFields.WAREHOUSE, defaultChoice));
    if (hasNoWareHouseOptions) {
      // clear the not guaranteed flag for weight
      yield put(IkeaActions.setNotGuaranteed({ result: false, reason: NotGuaranteedReason.HEAVY }));
    }
  } catch {
    yield put(change(IkeaFlowForms.PICKUP_DETAILS, IkeaFlowFields.WAREHOUSE, ''));
  }
}

export function* warehouseSelectionChange(action) {
  if (!isIkeaFlowFormAction(action) || action.meta.field !== IkeaFlowFields.WAREHOUSE) {
    return;
  }
  yield resetUploadField(IkeaFlowForms.PICKUP_DETAILS, IkeaFlowFields.RECEIPT);
}

export function* checkWeightForGuaranteed(action) {
  if (!isIkeaFlowFormAction(action) || action.meta.field !== IkeaFlowFields.WEIGHT) {
    return;
  }
  // A simple flag for too heavy or not
  const isTooHeavy = action.payload > _MAX_WEIGHT_IKEA;
  yield put(IkeaActions.setNotGuaranteed({ result: isTooHeavy, reason: NotGuaranteedReason.HEAVY }));
}

export function* checkDistanceForGuaranteed(action) {
  // Listens to updates on pickup location, and to updates on delivery fields
  if (
    !isIkeaFlowFormAction(action) ||
    ![
      IkeaFlowFields.PICKUP_LOCATION,
      IkeaFlowFields.LAT,
      IkeaFlowFields.LNG,
      IkeaFlowFields.LOCALITY,
      IkeaFlowFields.COUNTRY_CODE,
      IkeaFlowFields.WAREHOUSE,
    ].includes(action.meta.field)
  ) {
    return;
  }
  // check if we have complete data
  const pickup = yield select(getPickupDetails);
  const delivery = yield select(getDeliveryDetails);
  if (!pickup?.address?.lat || !delivery?.address?.lat || !delivery?.address?.lng) {
    return;
  }
  // Try to fetch route details, from the calculate route endpoint
  try {
    const fetchRouteDetails = yield call(
      calculateRoute,
      { lat: pickup.address.lat, lng: pickup.address.lng },
      { lat: delivery.address.lat, lng: delivery.address.lng }
    );
    if (fetchRouteDetails.status !== 200) {
      throw new Error('Distance call did not work out');
    }
    // simple flag for distance tooFar
    const tooFar = fetchRouteDetails.data.distance > _MAX_IKEA_SERVICE_DISTANCE;
    yield put(IkeaActions.setNotGuaranteed({ result: tooFar, reason: NotGuaranteedReason.DISTANCE }));
  } catch (e) {
    appUtils.logger(e);
    // We give them the benfit of the doubt
    yield put(IkeaActions.setNotGuaranteed({ result: false, reason: NotGuaranteedReason.DISTANCE }));
  }
}

function* submitPickupOptions() {
  const selectedStore = yield select(getSelectedStoreData);
  if (selectedStore?.conditions?.selectableDates) {
    yield put(push(`${prefix}/date`));
    return;
  }
  yield put(push(`${prefix}/delivery_details`));
}

function* dateSelectionChanged(action) {
  if (!isIkeaFlowFormAction(action) || action.meta.field !== IkeaFlowFields.DATE) {
    return;
  }
  yield put(push(`${prefix}/delivery_details`));
}

function* handleProgressBar(action) {
  // This handles the visual progress of TR
  // It takes care of progress tracking as well
  if (!action.payload.location.pathname.includes(prefix)) {
    return;
  }

  // setup general tracking event
  const event = {
    event: 'Flow progress',
    flow_type: _FLOW_TYPE,
  };

  const url = action.payload.location.pathname;

  // step 1
  if (matchPath(url, { path: `${prefix}/pickup_details`, exact: true })) {
    event['progress_label'] = 'Step 1a - Pickup';
    event['progress_value'] = 1;
    yield put(IkeaActions.setProgress(1));
  }
  if (matchPath(url, { path: `${prefix}/pickup_options`, exact: true })) {
    event['progress_label'] = 'Step 1b - Pickup';
    event['progress_value'] = 1;
    yield put(IkeaActions.setProgress(1));
  }

  // step 2
  if (matchPath(url, { path: `${prefix}/delivery_details`, exact: true })) {
    event['progress_label'] = 'Step 2a - Delivery';
    event['progress_value'] = 2;
    yield put(IkeaActions.setProgress(2));
  }
  if (matchPath(url, { path: `${prefix}/delivery_details/address`, exact: true })) {
    event['progress_label'] = 'Step 2b - Delivery';
    event['progress_value'] = 2;
    yield put(IkeaActions.setProgress(2));
  }

  // step 3
  if (matchPath(url, { path: `${prefix}/payment`, exact: true })) {
    event['progress_label'] = 'Step 3 - Payment';
    event['progress_value'] = 3;
    yield put(IkeaActions.setProgress(3));
  }

  // double check on empty tracking data
  if (!_get(event, 'progress_label', false)) {
    return;
  }

  // push to analytics
  appUtils.pushToDataLayer(event);
}

function* resetForms() {
  yield all([
    put(reset(IkeaFlowForms.PICKUP_DETAILS)),
    put(reset(IkeaFlowForms.PICKUP_OPTIONS)),
    put(reset(IkeaFlowForms.DELIVERY)),
    put(reset(IkeaFlowForms.PAYMENT)),
  ]);
}

export function* IkeaTransportSagas() {
  yield takeLatest(LOCATION_CHANGE, handleProgressBar);
  yield takeLatest(actionTypes.BLUR, autocompleteDeliveryAddress);
  yield takeLatest(actionTypes.CHANGE, checkWeightForGuaranteed);
  yield takeLatest(actionTypes.CHANGE, checkDistanceForGuaranteed);
  yield takeEvery(actionTypes.CHANGE, storeSelectionChange);
  yield takeEvery(actionTypes.CHANGE, warehouseSelectionChange);
  yield takeEvery(actionTypes.CHANGE, dateSelectionChanged);
  yield takeLatest(ikeaActionTypes.SUBMIT_PICKUP_OPTIONS_START, submitPickupOptions);
  yield takeLatest(ikeaActionTypes.SUBMIT_DELIVERY_START, submitDelivery);
  yield takeLatest(ikeaActionTypes.RESET_FORMS, resetForms);
}

import * as React from 'react';
import './label.css';

export interface LabelProps {
  type?: 'basic' | 'green' | 'blue' | 'red';
  caps?: boolean;
  children: React.ReactNode;
  onClick?(): void;
}

const Label = ({ type = 'basic', caps = false, children, onClick }: LabelProps) => {
  return (
    <div className={'status-label status-label--' + type + (caps ? ' status-label--caps' : '')} onClick={onClick}>
      {children}
    </div>
  );
};

export default Label;

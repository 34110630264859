import React from 'react';
import classNames from 'classnames';
import { Field, isValid } from 'redux-form';
import { connect } from 'react-redux';
import { Col, IconArrow, IconSmile, InputButtonSelect, Row } from '../../../brenger-shared-ui';
import { LocalizeContextProps, Translate } from 'react-localize-redux';
import { getPaymentMethods } from '../ducks';
import { LocalStorageKeys, RootState } from '../../../typings';
import { GeneralFlowForms } from '../../GeneralFlow/interface';
import { PaymentFormKey } from '../typings';

import '../../../assets/payment/payment-details.css';
import { _ACTIVE_LANG } from '../../../configs/localization';

type ReduxProps = ReturnType<typeof mapStateToProps>;
// @TODO: fix when we have proper ReduxForm types.
interface OwnProps {
  resetPaymentForm(): void;
  submitPaymentForm(): void;
}

const LoadingState: React.FC = props => (
  <div className={classNames('flex', 'flex--vc')}>
    <IconSmile spinning={true} />
    <span className="pl-0-25" />
    <Translate id="default.loading" />
    <span className="pl-0-25" />
    {props.children}
  </div>
);

const LoadingPaymentMethods: React.FC = () => (
  <LoadingState>
    <Translate>
      {({ translate }: LocalizeContextProps) => (
        <span style={{ textTransform: 'lowercase' }}>{translate('request_flow.payment.methods')}</span>
      )}
    </Translate>
  </LoadingState>
);

const GoBackToPaymentMethodsButton: React.FC<{ delay: number; onClick(): void }> = props => (
  <span
    onClick={props.onClick}
    className={classNames('btn', 'btn--tertiary', 'reset-bva-payment-form', `delay-index-${props.delay}`)}
  >
    <IconArrow left={true} arrowStyle={'primary'} /> <Translate id={'request_flow.payment.back_to_method'} />
  </span>
);

type Props = ReduxProps & OwnProps;

const PaymentMethods: React.FC<Props> = props => {
  const { paymentForm, paymentMethods } = props;
  const selectedPaymentMethodID = paymentForm?.values?.method;
  const selectedPaymentIssuerID = paymentForm?.values?.issuer;
  const selectedPaymentMethodData = paymentMethods.find(m => m.mollie_id === selectedPaymentMethodID);
  const selectedPaymentMethodHasIssuers = !!selectedPaymentMethodData?.issuers?.length;

  React.useEffect(() => {
    // @TODO: make a scrollToTop component or hook.
    // coming from an status page, we need to scroll up
    window.scrollTo(0, 0);
    // Set active language
    // We do this so we know if we need to redirect the user when he/she comes back from the payment provider
    localStorage.setItem(LocalStorageKeys.LANGUAGE_PREFERENCE, _ACTIVE_LANG);
  }, []);

  React.useEffect(() => {
    if (selectedPaymentMethodID) {
      if (selectedPaymentMethodHasIssuers) {
        if (selectedPaymentIssuerID) {
          // To submit payment methods with issuers requires BOTH method ID && issuer ID to proceed.
          props.submitPaymentForm();
        }
      } else {
        // To submit payment method without issuer only requires method ID to proceed.
        props.submitPaymentForm();
      }
    }
  }, [selectedPaymentMethodID, selectedPaymentIssuerID, props.isPaymentFormValid]);

  if (!paymentMethods.length) {
    return <LoadingPaymentMethods />;
  }

  if (selectedPaymentMethodID && !selectedPaymentMethodHasIssuers) {
    return <LoadingState />;
  }

  if (selectedPaymentMethodHasIssuers && selectedPaymentIssuerID) {
    return <LoadingState />;
  }

  return (
    <div className={'order-payment pb-1'}>
      <Row extraClasses={'order-wrap-up--lot'}>
        <Col xs={12}>
          {/* First Step: make user select a payment method. */}
          {!selectedPaymentMethodID && (
            <Field name={PaymentFormKey.method} component={InputButtonSelect} options={paymentMethods} />
          )}
          {/* Second step (in some cases): make user select a payment issuer when available. */}
          {selectedPaymentMethodHasIssuers && (
            <>
              <GoBackToPaymentMethodsButton
                onClick={props.resetPaymentForm}
                delay={selectedPaymentMethodData?.issuers.length || 0}
              />
              <Field
                name={PaymentFormKey.issuer}
                component={InputButtonSelect}
                options={selectedPaymentMethodData?.issuers}
              />
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  paymentForm: state.form.paymentForm,
  paymentMethods: getPaymentMethods(state),
  // This prop is critical for hooking into validation updates.
  // Cannot submit the form until this is returns true.
  isPaymentFormValid: isValid(GeneralFlowForms.paymentForm)(state),
});

export default connect(mapStateToProps)(PaymentMethods);

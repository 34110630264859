import React from 'react';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  Button,
  FlashMessage,
  Heading,
  IconSmile,
  Input,
  InputTiles,
  SheetLayoutContent,
  SheetLayoutContentWrapper,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { SMILE_DIRECTION } from '../../../brenger-shared-ui/components/basics/icons/IconSmile';
import { RootState } from '../../../typings';
import { translate } from '../../../utils/localization';
import { Field, FieldArray, reduxForm } from 'redux-form';
import '../../../assets/user/user.css';
import ItemSets from '../../GeneralFlow/components/ItemSets';
import {
  actions,
  getDateTimePeriodIndex,
  getDateTimePeriodOptions,
  getDeliveryAddressAsString,
  getItemSetTotalSize,
} from '../ducks';
import { BusinessFields, BusinessForms } from '../interface';
import FooterLayout from './FooterLayout';

import { BusinessSchema } from '../schema';
import { _BUSINESS_ACCOUNT_SUPPORT_NUMBER, _BUSINESS_MAX_ITEM_SET_SIZE } from '../../../utils/global';

type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface FormProps {
  handleSubmit(): void;
  submit(): void;
}
type Props = ReduxProps & FormProps;

class ItemsAndServices extends React.Component<Props> {
  public render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <SheetLayoutContentWrapper>
          <SheetLayoutHeader>
            <Heading size={2}>
              <Translate id={'request_flow.business.step_2.title'} />
            </Heading>
          </SheetLayoutHeader>
          <SheetLayoutContent>
            {this.props.deliveryAddressString && (
              <>
                <Translate id={'request_flow.business.delivery_details_to'} /> {this.props.deliveryAddressString}
              </>
            )}
          </SheetLayoutContent>
          <FieldArray
            name={BusinessFields.ITEM_SETS}
            title={<Translate id={'request_flow.business.items_sub_title'} />}
            businessFlow={true}
            formName={BusinessForms.ITEMS_AND_SERVICE}
            component={ItemSets}
          />
          <SheetLayoutContent>
            {this.props.totalSize > _BUSINESS_MAX_ITEM_SET_SIZE && (
              <FlashMessage
                type={'info'}
                message={String(
                  translate('request_flow.business.errors.max_total_volume_size', {
                    phonenumber: _BUSINESS_ACCOUNT_SUPPORT_NUMBER,
                  })
                )}
              />
            )}
            {this.props.dateTimePeriods.length > 0 && (
              <div className={'business--date-selector'}>
                <div className={'pb-1 pt-1'}>
                  <b>
                    <Translate id={'request_flow.business.headings.date'} />
                  </b>
                </div>
                <Field
                  validate={BusinessSchema.date}
                  name={BusinessFields.DATE}
                  options={this.props.dateTimePeriods}
                  component={InputTiles}
                  maxDelayIndex={6}
                />
                <div>
                  <Button
                    onClick={event => {
                      event.preventDefault();
                      this.props.getMoreDates(this.props.dateTimePeriodIndex + 6);
                    }}
                    buttonStyle="outline"
                  >
                    <IconSmile smileDirection={SMILE_DIRECTION.down} />
                    <Translate id={'request_flow.business.get_more_dates'} />
                  </Button>
                </div>
              </div>
            )}
            <div className={'pb-1 pt-1'}>
              <b>
                <Translate id={'request_flow.business.headings.extra_services'} />
              </b>
            </div>
            <Field
              name={BusinessFields.SERVICE_EXTRA_DRIVER}
              label={String(translate('request_flow.business.fields.extra_driver.label'))}
              description={String(translate('request_flow.business.fields.extra_driver.description'))}
              type={'checkbox'}
              border={true}
              suffix={<span />}
              component={Input}
            />
          </SheetLayoutContent>
          <FooterLayout submitForm={this.props.submit} />
        </SheetLayoutContentWrapper>
      </form>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  deliveryAddressString: getDeliveryAddressAsString(state),
  dateTimePeriods: getDateTimePeriodOptions(state),
  dateTimePeriodIndex: getDateTimePeriodIndex(state),
  totalSize: getItemSetTotalSize(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getMoreDates: (index: number) => dispatch(actions.setDateTimePeriodIndex(index)),
});

export default reduxForm({
  form: BusinessForms.ITEMS_AND_SERVICE,
  destroyOnUnmount: false,
  forceUnregisterOnUmount: false,
  initialValues: {
    itemSets: [{ items: [{ count: 1 }] }],
  },
  onSubmit: (details, dispatch) => dispatch(actions.submitItemsServices(details)),
})(connect(mapStateToProps, mapDispatchToProps)(ItemsAndServices));

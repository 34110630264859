import { email, maxLength, minLength, numeric, required } from '../../utils/validation';
import { translate } from '../../utils/localization';
import _get from 'lodash/get';

export const auctionSchema = {
  id: value => required(value, 'required!'),
  title: value => required(value, 'required!'),
  invoice: value => required(value, translate('form.fields.default.file.required')),
  lots: (value, allValues) => {
    if (!_get(allValues, 'lots', false)) {
      return String(translate('request_flow.auction.errors.at_least_one'));
    }
    if (allValues.lots.length === 0) {
      return String(translate('request_flow.auction.errors.at_least_one'));
    }
    if (Object.keys(_get(allValues, 'lots[0]', {})).length <= 2) {
      return String(translate('request_flow.auction.errors.at_least_one'));
    }
    /* Check if the user is waiting for fetching details from BVA */
    let hasPendingLot = false;
    _get(allValues, 'lots', []).forEach(lot => {
      /* We only want to take lots in account that have some sort value in in the number field, but no additional fields */
      if (lot.number && Object.keys(lot).length < 2) {
        hasPendingLot = true;
      }
    });
    if (hasPendingLot) {
      return String(translate('request_flow.auction.errors.pending_lot'));
    }
    return undefined;
  },
};

export const userSchema = {
  first_name: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('form.fields.user.first_name.label') })
    ),
  last_name: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('form.fields.user.last_name.label') })
    ),
  phone: [
    value =>
      required(value, translate('form.fields.default.required', { field: translate('form.fields.user.phone.label') })),
    value => minLength(value, 10, translate('form.fields.user.phone.valid')),
    value => maxLength(value, 14, translate('form.fields.user.phone.valid')),
    value => numeric(value, translate('form.fields.user.phone.valid')),
  ],
  email: [
    value =>
      required(value, translate('form.fields.default.required', { field: translate('form.fields.user.email.label') })),
    value => email(value, translate('form.fields.user.email.valid')),
  ],
};

export const deliverySchema = {
  line1: value =>
    required(value, translate('form.fields.default.required', { field: translate('form.fields.address.line1.label') })),
  postal_code: [
    value =>
      required(
        value,
        translate('form.fields.default.required', { field: translate('form.fields.address.postal_code.label') })
      ),
  ],
  locality: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('form.fields.address.locality.label') })
    ),
  custom_delivery_date: value =>
    required(value, translate('form.fields.default.required', { field: translate('form.fields.datetime.date.label') })),
  conditions: [value => required(value, translate('request_flow.payment.agree_terms_required'))],
};

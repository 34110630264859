import React from 'react';
import { Col, Row } from '../../../brenger-shared-ui/components/layout/grid';
import { Translate } from 'react-localize-redux';
import { Button, CardProfile } from '../../../brenger-shared-ui';
import _get from 'lodash/get';

export class DriverCard extends React.Component<any, any> {
  public render() {
    const accountLink = this.props.tjal;
    const driverId = _get(accountLink, 'driver_user.@id', null);
    return (
      <CardProfile
        header={accountLink.driver_user.first_name}
        email={_get(accountLink, 'driver_user.email', null)}
        phone={_get(accountLink, 'driver_user.phone', null)}
        rate={_get(accountLink, 'driver_user.rating', null)}
        footer={this.renderFooter()}
        image={`https://api.brenger.nl/user_data/profile_images/${driverId.split('users/')[1]}`}
      >
        <div className={'non-prepaid-offer'}>
          <div className={'non-prepaid-offer--body pb-0-5'}>
            <Row>
              <Col xs={12} sm={6} extraClasses={'pb-0-25'}>
                <Translate id={'transport_request.pickup'} />: {_get(this.props.pickupDatetimes, 'start', '')}-
                {_get(this.props.pickupDatetimes, 'end', '')}
              </Col>
              <Col xs={12} sm={6} extraClasses={'pb-0-25'}>
                <Translate id={'transport_request.delivery'} />: {_get(this.props.deliveryDatetimes, 'start', '')}-
                {_get(this.props.deliveryDatetimes, 'end', '')}
              </Col>
            </Row>
          </div>
        </div>
      </CardProfile>
    );
  }
  public renderFooter() {
    const tjal = this.props.tjal;
    return (
      <div className={'non-prepaid-offer--actions'}>
        {this.props.setChatOpen && (
          <Button
            loading={tjal['loading']}
            disabled={tjal['loading']}
            onClick={() => this.props.setChatOpen(tjal)}
            fullWidth={true}
            buttonStyle={'outline'}
          >
            <Translate id={'request_flow.actions.start_chat'} />
          </Button>
        )}
        <Button
          loading={tjal['loading']}
          disabled={tjal['loading']}
          onClick={() => this.props.rejectAccountLink(tjal['@id'])}
          fullWidth={true}
          buttonStyle={'outline'}
        >
          <Translate id={'request_flow.actions.reject_offer'} />
        </Button>
        <Button
          loading={tjal['loading']}
          disabled={tjal['loading']}
          onClick={() => this.props.confirmAccountLink(tjal['@id'])}
          fullWidth={true}
          buttonStyle={'primary'}
        >
          <Translate id={'request_flow.actions.confirm_offer'} />
        </Button>
      </div>
    );
  }
}

export default DriverCard;

import React from 'react';
import { Col, Row } from '../../../brenger-shared-ui';
import { RouteComponentProps } from 'react-router';
import { InjectedFormProps } from 'redux-form';
import { Translate } from 'react-localize-redux';

type Props = InjectedFormProps<{}> & RouteComponentProps<{}>;

class SocialButtons extends React.Component<Props> {
  public render() {
    return (
      <Row>
        <Col xs={12} sm={6}>
          <a className="loginBtn loginBtn--facebook" onClick={() => this.props.socialAuth('facebook')}>
            <Translate id={'authentication.login.fb_button_label'} />
          </a>
        </Col>
        <Col xs={12} sm={6}>
          <a className="loginBtn loginBtn--google" onClick={() => this.props.socialAuth('google')}>
            <Translate id={'authentication.login.google_button_label'} />
          </a>
        </Col>
      </Row>
    );
  }
}

export default SocialButtons;

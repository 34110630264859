import { DatetimePeriod, GeoPoint } from '../../typings';

// INTERFACES & PROP TYPES
export enum Lot {
  id = 'id',
  number = 'number',
  invoice = 'invoice',
  title = 'title',
  image = 'image',
  description = 'description',
  path = 'path',
  url = 'url',
}

export enum User {
  first_name = 'first_name',
  last_name = 'last_name',
  email = 'email',
  phone = 'phone',
}

export enum Delivery {
  line1 = 'line1',
  line2 = 'line2',
  postal_code = 'postal_code',
  instructions = 'instructions',
  locality = 'locality',
  country_code = 'country_code',
  latitude = 'latitude',
  longitude = 'longitude',
  custom_delivery_date = 'custom_delivery_date',
  conditions = 'conditions',
}

export enum Price {
  AMOUNT = 6000,
  EXTRA_RATE = 4000,
  CURRENCY = 'EUR',
}

export interface UserInterface {
  first_name: string;
  last_name: string;
  email: string;
  phone: number;
}

export interface PriceInterface {
  price: number;
  currency: string;
}

export interface StepInterface {
  step: number;
  total: number;
  goBack: any;
  pickup: GeoPoint;
  delivery: GeoPoint;
}

export interface DeliveryInterface {
  contact: object;
  address: object;
  details: object;
  available_datetime_periods: DatetimePeriod[];
}

export enum VavatoFlowForms {
  auctionLotForm = '@@VavatoFlow/auction_lot_form',
  deliveryForm = '@@VavatoFlow/delivery_form',
}

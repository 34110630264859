import * as React from 'react';
import InputFeedback from '../input/InputFeedback';
import InputWrapper from '../input/InputWrapper';
import Pikaday from 'pikaday';
import '../input/input.css';
import './input-date.css';
import moment from 'moment-timezone';
import { getDateFormatLocale } from '../../../../utils/datetime';
import { InputProps } from '../input/Input';

export interface custom {
  disableWeekends?: boolean;
  minDate?: string;
  maxDate?: string;
  renderInline?: boolean;
  selectedPrefix?: string;
  dateFormat?: string;
}

type InputDateProps = custom & InputProps;

class InputDate extends React.Component<InputDateProps, any> {
  private needsDatePicker = React.createRef<HTMLDivElement>();
  constructor(props) {
    super(props);
    this.needsDatePicker = React.createRef();
  }

  public addPicker = datePickerComponent => {
    return new Pikaday({
      field: this.needsDatePicker.current,
      format: this.props.dateFormat || getDateFormatLocale(),
      setDefaultDate: false,
      disableWeekends: this.props.disableWeekends ? this.props.disableWeekends : false,
      defaultDate: this.props.minDate ? this.props.minDate : new Date(),
      minDate: this.props.minDate,
      maxDate: this.props.maxDate,
      bound: !this.props.renderInline,
      container: this.props.renderInline ? document.getElementById('pika-inline-' + this.props.input.name) : null,
      keyboardInput: false,
      i18n: {
        months: moment.months(),
        weekdays: moment.weekdays(),
        weekdaysShort: moment.weekdaysShort(),
      },
      onSelect: date => {
        datePickerComponent.props.input.onChange(moment(date).format('YYYY-MM-DD'));
        return date;
      },
    });
  };

  public componentDidMount() {
    const datePickerComponent = this;
    this.addPicker(datePickerComponent);
  }

  public render() {
    return (
      <InputWrapper
        input={...this.props.input}
        meta={...this.props.meta}
        type={'date' + (this.props.renderInline ? '--inline' : '')}
        label={this.props.label}
      >
        <input
          id={this.props.input.name}
          type={this.props.renderInline ? 'hidden' : 'text'}
          {...this.props.input}
          ref={this.needsDatePicker}
        />
        {this.props.renderInline && (
          <div id={'pika-inline-' + this.props.input.name} className={'pika-inline-picker'} />
        )}
        {!this.props.renderInline && (
          <label
            className={
              'input-el--label' +
              (typeof this.props.input.required !== 'undefined' && this.props.input.required === true
                ? ' input-el--label--required'
                : '')
            }
            htmlFor={this.props.input.name}
          >
            {this.props.label}
          </label>
        )}
        <InputFeedback
          meta={...this.props.meta}
          error={this.props.meta.error}
          successMessage={
            this.props.renderInline && this.props.selectedPrefix
              ? this.props.selectedPrefix + ': ' + this.props.input.value
              : this.props.successMessage
          }
        />
      </InputWrapper>
    );
  }
}

export default InputDate;

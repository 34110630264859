import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import {
  Col,
  Collapse,
  FlashMessage,
  Input,
  Row,
  SheetLayoutContent,
  SheetLayoutContentWrapper,
} from '../../../brenger-shared-ui';
import { translate } from '../../../utils/localization';
import { _GENERAL_CONDITION_LINK, _PRIVACY_STATEMENT_LINK, _TRANSPORT_CONDITION_LINK } from '../../../utils/global';
import { required } from '../../../utils/validation';
import { actions as flowActions, getAddressTextLabel, getBlockedTransportRequestReasons, isGuaranteed } from '../ducks';
import { GeneralFlowForms } from '../interface';
import FooterLayout from './FooterLayout';
import TransportRequestSummary from './TransportRequestSummary';

type Props = InjectedFormProps<{}> & RouteComponentProps<{}>;

export const Schema = {
  conditions: [value => required(value, translate('request_flow.payment.agree_terms_required'))],
};

class Terms extends React.Component<Props> {
  public componentWillMount(): void {
    this.props.setProgress(8);
  }

  public render() {
    const isBlocked = this.props.blockedTransportReasons.length > 0;
    return (
      <>
        <SheetLayoutContentWrapper>
          <form onSubmit={this.props.handleSubmit}>
            <SheetLayoutContent>
              {!this.props.isGuaranteed && !isBlocked && (
                <div className={'pb-1 pt-1'}>
                  <Translate id={'request_flow.not_guaranteed.general_message'} />
                </div>
              )}
              {isBlocked && (
                <div>
                  <div className={'ikea-not-guaranteed-messages'}>
                    <div className={'text--bold pt-1 pb-0-5'}>
                      <Translate id={'request_flow.not_guaranteed.explain.heading'} />
                    </div>
                    {this.props.blockedTransportReasons.map((reason, index) => {
                      return (
                        <div key={index} className="pb-0-5">
                          <FlashMessage
                            type={'error'}
                            message={String(translate('request_flow.not_guaranteed.explain.' + reason))}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {window.innerWidth < 992 && (
                <div className="summary">
                  <div className="summary--body">
                    <Collapse open={false} header={String(translate('request_flow.headers.mobile_summary'))}>
                      <TransportRequestSummary />
                    </Collapse>
                  </div>
                </div>
              )}
              <Row extraClasses={'order--agree-terms'}>
                <Col xs={12} extraClasses={'pt-1'}>
                  <Field
                    name={'conditions.newsletter_conditions'}
                    type={'checkbox'}
                    label={<Translate id={'request_flow.contact.newsletter'} />}
                    component={Input}
                  />
                  <Field
                    name={'conditions.general_conditions'}
                    type={'checkbox'}
                    validate={Schema['conditions']}
                    label={translate('request_flow.delivery.agree_general_terms', {
                      condition_link: (
                        <a href={_GENERAL_CONDITION_LINK} target={'_blank'}>
                          <Translate id={'request_flow.delivery.general_terms'} />
                        </a>
                      ),
                    })}
                    component={Input}
                  />
                  <Field
                    name={'conditions.privacy_statement'}
                    type={'checkbox'}
                    validate={Schema['conditions']}
                    label={
                      <>
                        <Translate
                          id={'request_flow.delivery.agree_privacy_statement'}
                          data={{
                            privacy_link: (
                              <a href={_PRIVACY_STATEMENT_LINK} target={'_blank'}>
                                <Translate id={'request_flow.delivery.privacy_statement'} />
                              </a>
                            ),
                          }}
                        />
                        <br />
                        <small style={{ paddingTop: '1em' }}>
                          <Translate
                            id={'request_flow.delivery.agree_transport_condition'}
                            data={{
                              condition_link: (
                                <a href={_TRANSPORT_CONDITION_LINK} target={'_blank'}>
                                  <Translate id={'request_flow.delivery.transport_condition'} />
                                </a>
                              ),
                            }}
                          />
                        </small>
                      </>
                    }
                    component={Input}
                  />
                </Col>
              </Row>
              <div className={'general-errors-wrapper'}>
                {this.props.generalErrors && this.props.generalErrors !== '' && (
                  <FlashMessage type={'error'} message={this.props.generalErrors} />
                )}
              </div>
            </SheetLayoutContent>
            <FooterLayout />
          </form>
        </SheetLayoutContentWrapper>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  transport: state.generalTransport,
  generalErrors: state.generalTransport.errors,
  pickupAsText: getAddressTextLabel(true, state),
  isGuaranteed: isGuaranteed(state),
  blockedTransportReasons: getBlockedTransportRequestReasons(state),
});

const mapDispatchToProps = dispatch => ({
  setProgress: step => dispatch(flowActions.setProgress(step)),
});

export default reduxForm<{}, Props>({
  form: GeneralFlowForms.terms,
  destroyOnUnmount: false,
  forceUnregisterOnUmount: true,
  onSubmit: (details, dispatch) => dispatch(flowActions.submitTerms()),
})(connect(mapStateToProps, mapDispatchToProps)(Terms));

import React from 'react';
import '../../../../assets/marktplaats/marktplaats.css';
import {
  Collapse,
  IconSmile,
  InputTiles,
  PersonBalloon,
  SectionWrapper,
  SheetLayoutContent,
  SheetLayoutContentWrapper,
} from '../../../../brenger-shared-ui';
import MarktplaatsLogo from '../../../../assets/marktplaats/marktplaats.svg';
import { Field } from 'redux-form';
import { translate } from '../../../../utils/localization';
import { SubStepFieldNamesPickup } from '../../interface';
import { Translate } from 'react-localize-redux';
import { pickupDeliverySchema } from '../../schema';
import { SMILE_DIRECTION } from '../../../../brenger-shared-ui/components/basics/icons/IconSmile';

class Onboarding extends React.Component<any, any> {
  public componentDidMount(): void {
    const wrapper = document.querySelector('.sheet-layout--main-container') as HTMLElement;
    const header = document.querySelector('.sheet-layout--content-wrapper--top-header') as HTMLElement;
    header.style.display = 'none';
    wrapper.classList.add('marktplaats-layout');
  }

  public componentWillUnmount(): void {
    const wrapper = document.querySelector('.sheet-layout--main-container') as HTMLElement;
    const header = document.querySelector('.sheet-layout--content-wrapper--top-header') as HTMLElement;
    wrapper.classList.remove('marktplaats-layout');
    header.style.display = 'block';
  }

  public render() {
    const options = [
      {
        value: 'continue',
        title: String(translate('request_flow.marktplaats.options.book')),
        desc: String(translate('request_flow.marktplaats.options.book_subtitle')),
        suffix: <IconSmile smileDirection={SMILE_DIRECTION.right} />,
      },
      {
        value: 'contact',
        title: String(translate('request_flow.marktplaats.options.ask_more_data')),
        desc: String(translate('request_flow.marktplaats.options.ask_more_data_subtitle')),
        suffix: <IconSmile smileDirection={SMILE_DIRECTION.right} />,
      },
    ];
    return (
      <SheetLayoutContentWrapper className={'marktplaats-onboarding'}>
        <SectionWrapper sectionStyle={'secondary'} centerContent={false}>
          <SheetLayoutContent>
            <img className={'marktplaats-logo'} alt={'marktplaats-logo'} src={MarktplaatsLogo} />
            <PersonBalloon
              balloonContent={
                <span
                  className={'text--primary text--bold text--center'}
                  dangerouslySetInnerHTML={{ __html: String(translate('request_flow.marktplaats.text_balloon')) }}
                />
              }
            />
          </SheetLayoutContent>
        </SectionWrapper>
        <SheetLayoutContent>
          <div className={'question-wrapper'}>
            <p className={'mp-flow-description'}>
              <Translate id={'request_flow.marktplaats.brief'} />
            </p>
            <Collapse open={false} header={String(translate('request_flow.marktplaats.faq_question'))}>
              <ul>
                <li>
                  <Translate id={'request_flow.marktplaats.answers.first'} />
                </li>
                <li>
                  <Translate id={'request_flow.marktplaats.answers.second'} />
                </li>
                <li>
                  <Translate id={'request_flow.marktplaats.answers.third'} />
                </li>
                <li>
                  <Translate id={'request_flow.marktplaats.answers.forth'} />
                </li>
                <li>
                  <Translate id={'request_flow.marktplaats.answers.fifth'} />
                </li>
              </ul>
            </Collapse>
            <Field
              validate={pickupDeliverySchema.marktplaats_option}
              name={SubStepFieldNamesPickup.marktplaats_option}
              options={options}
              component={InputTiles}
            />
          </div>
        </SheetLayoutContent>
      </SheetLayoutContentWrapper>
    );
  }
}

export default Onboarding;

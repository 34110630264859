import * as React from 'react';
import { InputOptionsProps } from '../inputbuttonselect/InputButtonSelect';
import InputWrapper from '../input/InputWrapper';
import InputFeedback from '../input/InputFeedback';
import './select.css';
import { IconSmile } from '../../basics/icons';

const GroupSelect = ({ input, meta, label, type, border, successMessage, groups }: InputOptionsProps): any => (
  <InputWrapper label={label} input={...input} meta={...meta} type={'select'} border={border}>
    {label && (
      <label
        className={
          'input-el--label' +
          (typeof input.required !== 'undefined' && input.required === true ? ' input-el--label--required' : '')
        }
        htmlFor={input.name}
      >
        {label}
      </label>
    )}
    <select {...input} id={input.name}>
      <option />
      {Object.keys(groups).map((g, i) => (
        <optgroup key={i} label={groups[g].label}>
          {groups[g].options.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
        </optgroup>
      ))}
    </select>
    <label htmlFor={input.name}>
      <IconSmile />
    </label>
    <InputFeedback meta={...meta} error={meta.error} successMessage={successMessage} />
  </InputWrapper>
);

export default GroupSelect;

import * as React from 'react';
import './icons.css';

const IconPaperPlane = () => {
  return (
    <svg width="25" height="22" viewBox="0 0 25 22">
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M25 10.585c0 .842-.507 1.57-1.288 1.914L2.917 21.001a2.071 2.071 0 0 1-.82.153c-.584 0-1.13-.23-1.52-.651-.625-.651-.742-1.532-.352-2.336l3.316-6.625a.601.601 0 0 1 .82-.268.58.58 0 0 1 .273.804l-3.316 6.625a.806.806 0 0 0 .156.957c.078.115.429.422.936.192l20.834-8.501c.508-.192.508-.651.508-.766 0-.115-.04-.575-.508-.766L2.45 1.318a.877.877 0 0 0-.936.191c-.078.115-.39.46-.156.957l3.16 6.319 11.392.919a.636.636 0 0 1 .586.651c-.04.345-.313.574-.664.574L4.088 9.972a.555.555 0 0 1-.508-.345L.264 3.003C-.126 2.237.03 1.318.615.667 1.24.016 2.137-.176 2.956.169L23.712 8.67c.82.345 1.288 1.072 1.288 1.915z"
      />
    </svg>
  );
};

export default IconPaperPlane;

import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from '../../../brenger-shared-ui/components/layout/grid';
import { Button, Heading, Input, SectionWrapper } from '../../../brenger-shared-ui';
import { RouteComponentProps } from 'react-router';
import { push } from 'connected-react-router';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { actions } from '../ducks';
import '../../../assets/user/user.css';
import { PersonSchema } from '../schema';
import { UserProperties } from '../interface';
import { translate } from '../../../utils/localization';
import { FlashMessage } from '../../../brenger-shared-ui/';
import { Translate } from 'react-localize-redux';

type Props = InjectedFormProps<{}> & RouteComponentProps<{}>;

class PasswordReset extends React.Component<Props> {
  public render() {
    return (
      <SectionWrapper extraClasses={'login-wrapper'} sectionStyle={'white'} centerContent={true}>
        <form onSubmit={this.props.handleSubmit}>
          <Heading size={2}>
            <Translate id={'authentication.reset.headline'} />
          </Heading>
          <p>
            <Translate id={'authentication.register.has_account'} />{' '}
            <a href="javascript:void(0);" onClick={() => this.props.openLogin()}>
              <Translate id={'authentication.login.button'} />
            </a>
          </p>
          <Row>
            <Col xs={12} extraClasses={'pt-1'}>
              <Field
                validate={PersonSchema[UserProperties.email]}
                name={'user.email'}
                type="text"
                component={Input}
                label={translate('form.fields.user.email.label')}
              />
            </Col>
            <Col xs={12} sm={12} extraClasses={'login-errors'}>
              {typeof this.props.error === 'string' && this.props.error !== '' && (
                <FlashMessage type={'error'} message={this.props.error} />
              )}
              {this.props.success && (
                <FlashMessage type={'success'} message={String(translate('authentication.reset.success'))} />
              )}
            </Col>
            <Col xs={12} sm={12}>
              <Button
                fullWidth={true}
                loading={this.props.submitting}
                disabled={this.props.submitting || this.props.pristine || !this.props.valid}
                buttonStyle={'secondary'}
              >
                <Translate id={'authentication.reset.button'} />
              </Button>
            </Col>
          </Row>
        </form>
      </SectionWrapper>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  submitting: state.user.password_reset.loading,
  success: state.user.password_reset.success,
  error: state.user.password_reset.error,
});

const mapDispatchToProps = dispatch => ({
  openLogin: () => dispatch(push('/login')),
});

export default reduxForm<{}, Props>({
  form: 'passwordreset',
  destroyOnUnmount: false,
  forceUnregisterOnUmount: false,
  onSubmit: (details, dispatch) => dispatch(actions.resetPass(details)),
})(connect(mapStateToProps, mapDispatchToProps)(PasswordReset));

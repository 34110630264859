import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { connect } from 'react-redux';
import { translate } from '../../utils/localization';
import TransportRequest from './containers/TransportRequest';
import '../../assets/business/business.css';
import ThankYou from './containers/ThankYou';
import Login from '../User/containers/Login';
import { isUserLoggedIn } from '../User/ducks';
import Dashboard from './containers/Dashboard';
import { hasBusinessFlow } from './ducks';
import { prefix as GeneralFlowPrefix } from '../GeneralFlow';

export const prefix = '/business/:business_name';
export const _FLOW_TYPE = 'business';

interface Props {
  isLoggedIn: boolean;
  hasBusinessFlow: boolean;
}

class Business extends React.Component<RouteComponentProps<{}> & Props> {
  public getDefaultUrl(): string {
    if (this.props.hasBusinessFlow) {
      return `${prefix}/${this.props.isLoggedIn ? 'dashboard' : 'login'}`;
    }
    return `${GeneralFlowPrefix}/${this.props.isLoggedIn ? 'dashboard' : 'login'}`;
  }
  public render() {
    window.document.title = String(translate('request_flow.business.title'));
    return (
      <>
        <Switch>
          <Route path={`${prefix}/login`} component={Login} />
          {this.props.isLoggedIn && this.props.hasBusinessFlow && (
            <>
              <Route path={`${prefix}/dashboard`} component={Dashboard} />
              <Route path={`${prefix}/transport_request`} component={TransportRequest} />
              <Route path={`${prefix}/thank_you`} component={ThankYou} />
            </>
          )}
          <Redirect to={this.getDefaultUrl()} />
        </Switch>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isLoggedIn: isUserLoggedIn(state),
  hasBusinessFlow: hasBusinessFlow(state),
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Business);

import * as React from 'react';
import './flag.css';

interface Props {
  country: string;
}

type AllProps = Props & React.AnchorHTMLAttributes<Props>;

const Button: React.FC<AllProps> = ({ country = 'nl' }) => {
  return (
    <div className={'flag-icon-wrapper'}>
      <span className={`flag-icon flag-icon-${country.toLowerCase()}`} />
    </div>
  );
};
export default Button;

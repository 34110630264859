import '../../../assets/BVA/thank-you.css';
import '../../../assets/payment/payment-details.css';

import { push } from 'connected-react-router';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import {
  Button,
  CirclePerson2,
  Col,
  Heading,
  IconCheckmarkCircle,
  Row,
  SectionWrapper,
} from '../../../brenger-shared-ui';
import { pushToDataLayer } from '../../../utils/basics';
import { translate } from '../../../utils/localization';
import { prefix as trackAndTracePath } from '../../StatusPage';
import { actions, getDashboardLink, getNewTransportRequestLink, getPrice, getTrId } from '../ducks';

interface ThankYouProps {
  uuid: string;
  price: number;
  goToPath: any;
  resetTrForm: any;
  dashboardLink: string;
  newTrLink: string;
}

type Props = ThankYouProps;

class ThankYou extends React.Component<Props> {
  public componentWillUnmount(): void {
    this.props.resetTrForm();
  }
  public componentDidMount(): void {
    if (this.props.price === 0 || this.props.uuid === '') {
      return;
    }

    pushToDataLayer({
      event: 'purchase-business',
      transactionId: this.props.uuid,
      transactionAffiliation: 'business',
      transactionTotal: (Number(this.props.price) / 100).toFixed(2),
      transactionProducts: [
        {
          sku: 'business',
          name: 'Transport request',
          price: String((Number(this.props.price) / 100).toFixed(2)),
          quantity: 1,
        },
      ],
    });
    this.props.resetTrForm();
  }

  public render() {
    return (
      <Col xs={12}>
        <SectionWrapper sectionStyle={'primary'} centerContent={true}>
          <Row>
            <Col xs={12} extraClasses={'text--center thank-you-opening'}>
              <CirclePerson2 />
              <IconCheckmarkCircle />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={8} extraClasses={'offset-md-2'}>
              <Row>
                <Col xs={12} extraClasses={'pt-1 pb-1'}>
                  <Heading size={4}>
                    <Translate id={`request_flow.business.thank_you.title`} />
                  </Heading>
                </Col>
                <Col xs={12}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: String(translate('request_flow.business.thank_you.further_steps')),
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </SectionWrapper>
        <SectionWrapper sectionStyle={'white'} extraClasses={'profile-dashboard-wrapper'} centerContent={true}>
          <Row>
            <>
              <Col xs={12} md={8} extraClasses={'offset-md-2 pb-1'}>
                <Heading size={5}>
                  <Translate id={`request_flow.business.thank_you.sub_title`} />
                </Heading>
              </Col>
              <Col xs={12} md={8} extraClasses={'offset-md-2'}>
                <Button
                  fullWidth={true}
                  onClick={() => this.props.goToPath(this.props.dashboardLink)}
                  buttonStyle={'primary'}
                >
                  <Translate id={`request_flow.business.thank_you.go_to_dashboard`} />
                </Button>
              </Col>
              <Col xs={12} md={8} extraClasses={'offset-md-2'}>
                <Button fullWidth={true} onClick={() => this.props.goToPath(trackAndTracePath)} buttonStyle={'primary'}>
                  <Translate id={`request_flow.business.thank_you.go_to_overview`} />
                </Button>
              </Col>
              <Col xs={12} md={8} extraClasses={'offset-md-2'}>
                <Button
                  fullWidth={true}
                  onClick={() => this.props.goToPath(this.props.newTrLink)}
                  buttonStyle={'secondary'}
                >
                  <Translate id={`request_flow.business.thank_you.place_request`} />
                </Button>
              </Col>
            </>
          </Row>
        </SectionWrapper>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  uuid: getTrId(state),
  price: getPrice(state),
  dashboardLink: getDashboardLink(state),
  newTrLink: getNewTransportRequestLink(state),
});

const mapDispatchToProps = dispatch => ({
  goToPath: path => dispatch(push(path)),
  resetTrForm: () => dispatch(actions.resetTrBusinessFlow()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThankYou);

import React from 'react';
import { reduxForm } from 'redux-form';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  FlashMessage,
  Heading,
  SheetLayoutContent,
  SheetLayoutContentWrapper,
  SheetLayoutFooter,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import FooterLayout from './FooterLayout';
import PaymentMethods from '../../Payment/components/PaymentMethods';
import { actions as paymentActions } from '../../Payment/ducks';
import { actions as flowActions } from '../ducks';
import { _FLOW_TYPE } from '../index';
import { Translate } from 'react-localize-redux';
import { RootState } from 'src/typings';
import { GeneralFlowForms } from '../../GeneralFlow/interface';
import { paymentSchema } from '../../Payment/schema';

type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
// @TODO: use official FormProps eventually
interface FormProps {
  handleSubmit(): void;
  reset(): void;
}
type AllProps = ReduxProps & FormProps;

const Payment: React.FC<AllProps> = props => {
  React.useEffect(() => {
    props.setProgress(8);
  }, []);

  return (
    <form onSubmit={props.handleSubmit}>
      <SheetLayoutContentWrapper>
        <SheetLayoutHeader>
          <Heading size={2}>
            <Translate id={'request_flow.headers.payment'} />
          </Heading>
        </SheetLayoutHeader>
        <SheetLayoutContent>
          <PaymentMethods resetPaymentForm={props.reset} submitPaymentForm={props.handleSubmit} />
          <div className={'add-lot-general-errors-wrapper'}>
            {props.generalErrors && <FlashMessage type={'error'} message={props.generalErrors} />}
          </div>
        </SheetLayoutContent>
        <SheetLayoutFooter>
          <FooterLayout />
        </SheetLayoutFooter>
      </SheetLayoutContentWrapper>
    </form>
  );
};

const mapStateToProps = (state: RootState) => ({
  generalErrors: state.generalTransport.errors,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setProgress: (step: number) => dispatch(flowActions.setProgress(step)),
});

export default reduxForm({
  form: GeneralFlowForms.paymentForm,
  validate: paymentSchema,
  onSubmit: (payment, dispatch: Dispatch) => dispatch(paymentActions.submitPayment({ ...payment, flow: _FLOW_TYPE })),
})(connect(mapStateToProps, mapDispatchToProps)(Payment));

import * as React from 'react';
import { Translate } from 'react-localize-redux';

import { Button, Container, SectionWrapper } from '../../../brenger-shared-ui';

interface Props {
  onClick(): void;
}

export const ConfirmDeliveryButton: React.FC<Props> = props => (
  // Make confirm delivery button sticky
  <div
    style={{
      position: 'sticky',
      bottom: 0,
      background: 'white',
      width: '100%',
    }}
  >
    <Container fluid={true}>
      {/* Override SectionWrapper's padding and margin properties with these inline CSS values */}
      <SectionWrapper sectionStyle="white" style={{ padding: 15, marginBottom: 0 }}>
        <Button buttonStyle="primary" fullWidth={true} onClick={props.onClick}>
          <Translate id={'request_flow.actions.confirm_delivery'} />
        </Button>
      </SectionWrapper>
    </Container>
  </div>
);

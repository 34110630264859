import {
  all,
  call,
  debounce,
  delay,
  fork,
  put,
  PutEffect,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  actions,
  actions as flowActions,
  datesAvailable,
  expiredHours,
  getAuctionPickupDate,
  getAuctionType,
  getChosenPackage,
  getCustomerDetails,
  getDateForm,
  getDeliveryDateOptions,
  getDeliveryFloorData,
  getDeliveryHelp,
  getDeliveryPoint,
  getDistance,
  getGeneralTransportRequest,
  getHistoricalAddresses,
  getIsDirectlyPayable,
  getIsInstantBookingAvailable,
  getIsInstantBookingSelected,
  getIsPricingIndicatingHeavyWeightTransport,
  getIsTransportHeavyWeight,
  getMarktplaatsBuyerFormValues,
  getNotGuaranteedReason,
  getPickupDateOptions,
  getPickupFloorData,
  getPickupForm,
  getPickupHelp,
  getPickupLot,
  getPickupLots,
  getPickupPoint,
  getPickupSituation,
  getPriceCallRetryAttempts,
  getPriceRequestParams,
  getProgressStep,
  getQuoteItems,
  getReferrer,
  getSelectedDateTime,
  getSellerFormData,
  getStepKey,
  getStopAvailableDate,
  getStopDestinationDetails,
  getStopSituation,
  getTransportPrice,
  getTransportRequestCreationParams,
  getValidItemSets,
  hasCustomFloor,
  isAuctionOrStore,
  isDestinationLoaded,
  isGuaranteed,
  isInternational,
  isItemValid,
  isRequestAboveMaxDistance,
  isSheetOpen,
  needsDifferentDeliveryDate,
  shouldSkipDateTimeStep,
  types as generalTypes,
} from './ducks';
import { products } from './products';
import { invalidateCache } from 'redux-cache';
import { getLocation, goBack, LOCATION_CHANGE, push } from 'connected-react-router';
import {
  actionTypes,
  arrayPush,
  arrayRemove,
  change,
  clearFields,
  focus,
  getFormSyncErrors,
  getFormValues,
  initialize,
  isPristine,
  isValid,
  reset,
  resetSection,
  touch,
  untouch,
  updateSyncErrors,
  updateSyncWarnings,
} from 'redux-form';
import { coreClient, http } from '../../utils/request';
import {
  _BVA_BASE_URL,
  _EXTRA_CARE_NO_CONFIRMATION,
  _INTERNATIONAL_CHECK_BASE_URL,
  _PRICE_API_URL,
} from '../../utils/global';
import { uploadFile } from '../../state/sagas/uploadSaga';
import _get from 'lodash/get';
import { matchPath } from 'react-router';
import moment from 'moment-timezone';
import {
  Address,
  ItemCreationParams,
  List,
  TransportRequest,
  TransportRequestCreationParams,
} from '@brenger/api-client';

import {
  AddressOption,
  AuctionTypes,
  customerTypeFields,
  customTimePreference,
  GeneralFlowForms,
  ItemFields,
  PackageFieldsValues,
  PriceNotes,
  Situations,
  SubStepFieldNamesDelivery,
  SubStepFieldNamesPickup,
  WeightFields,
} from './interface';
import { CarryingHelpChoices, GenericExtraInputFileFields, StopType } from '../../typings';

import { _FLOW_TYPE, prefix } from './index';
import { translate } from '../../utils/localization';
import { getExternalPartyIri, getSearchPath } from '../../state/ducks/baseReducer';
import * as appUtils from '../../utils/basics';
import { getLoggedInUser } from '../User/ducks';
import { _NOT_GUARANTEED_THANK_YOU } from './containers/NotGuaranteedThankYou';
import { _NOT_GUARANTEED_PAYMENT } from '../StatusPage/containers/NotPrePaidJobsPayment';
import { hotjar } from '../../configs/hotjar';
import { _DATE_BVA_COLLECTION_PATH, _DATE_DELIVERY_PATH, _DATE_PICKUP_PATH } from './containers/Date';
import { fetchLotDetails } from '../BvaFlow/sagas';
import { IEventData, trackEvent } from '../../utils/eventTracking';
import { getItemSetsVolume } from '../../utils/itemSet';
import { searchGeoPoint } from '../../utils/geo';

export function priceCalculator(request) {
  return http()
    .post(_PRICE_API_URL() + '/quotes', request)
    .then(resp => {
      if (_get(resp, 'data.logs', false) && process.env.REACT_APP_LOG) {
        appUtils.logger(resp.data['logs']);
      }
      return resp;
    });
}

export function* itemOnRemove(action) {
  if (action.meta.field.indexOf('itemSets') > -1 && action.meta.form === GeneralFlowForms.items) {
    yield put(actions.getPrice());
  }
}

export function* itemOnDebounce(action) {
  const field = action.meta.field;
  if (
    action.meta.form === GeneralFlowForms.items &&
    field.includes('itemSets') &&
    (field.includes('width') || field.includes('height') || field.includes('length') || field.includes('title')) &&
    action.meta.form === GeneralFlowForms.items
  ) {
    yield put(actions.getPrice());
  }
}

export function* itemOnChange(action) {
  const field = action.meta.field;
  if (
    action.meta.form === GeneralFlowForms.items &&
    field.includes('itemSets') &&
    (field.includes('count') || field.includes('extra_care.selection')) &&
    action.meta.form === GeneralFlowForms.items
  ) {
    yield put(actions.getPrice());
  }
}

export function* itemTitleOnChange(action) {
  const field = action.meta.field;
  // are we dealing with a title field
  if (action.meta.form !== GeneralFlowForms.items || !(field.includes('itemSets') && field.includes('title'))) {
    return;
  }

  // Wait for fresh data
  yield take(generalTypes.SET_QUOTES_DATA);

  // Get the selected extra care, so form values first
  const formValues = yield select(getFormValues(action.meta.form));
  // Figure out which item we are dealing with
  const [itemSetIndex, itemIndex] = field.match(/[0-9]{1,2}/g)!;
  // And get that value
  const selectedExtraCare = _get(formValues, `itemSets[${itemSetIndex}]items[${itemIndex}]extra_care.selection`);

  // Figure out if there is match in text
  const quoteItems: ItemCreationParams = yield select(getQuoteItems);
  const quoteItemExtraCare = quoteItems[itemIndex]?.extra_care;
  // We check if the selected value is the same as the match value from quotes. If so then there is nothing to do
  if (!quoteItemExtraCare || quoteItemExtraCare.match === selectedExtraCare) {
    return;
  }
  // So we are dealing with a new extra care value, we need to update our field values
  const itemSetItem = `itemSets[${itemSetIndex}]items[${itemIndex}]`;
  const effects: any[] = [
    put(change(action.meta.form, `${itemSetItem}${ItemFields.EXTRA_CARE_INSURED}`, quoteItemExtraCare.insured)),
    put(change(action.meta.form, `${itemSetItem}${ItemFields.EXTRA_CARE_MATCH}`, quoteItemExtraCare.match)),
  ];
  if (!_EXTRA_CARE_NO_CONFIRMATION.includes(quoteItemExtraCare.match)) {
    // there is a match, plus we do like to ask for confirmation and we didn't ask about it yet, so we reset the field
    effects.push(put(clearFields(action.meta.form, false, false, `${itemSetItem}${ItemFields.EXTRA_CARE_SELECTION}`)));
  } else {
    // There is no math (anymore) or we don't ask for confirmation, update the selected value with the default
    effects.push(put(change(action.meta.form, `${itemSetItem}${ItemFields.EXTRA_CARE_SELECTION}`, 'none')));
  }
  yield all(effects);
}

export function* retryPriceHandler() {
  // check retry attempt < 3 or not show error
  const retryAttempts = yield select(getPriceCallRetryAttempts);
  if (retryAttempts <= 3) {
    yield delay(retryAttempts * 1000);
    yield put(actions.getPrice());
  } else {
    yield put(actions.resetRetryAttempts());
    yield put(actions.setPriceLoading(false));
    yield put(
      actions.popNotification({
        type: 'error',
        message: translate('request_flow.price.failedAttempts'),
      })
    );
  }
}

export function* determineDateNavOrModal() {
  const shouldPickDifferentDeliveryDate = yield select(needsDifferentDeliveryDate);
  const situation = yield select(getPickupSituation);
  const location = yield select(getLocation);
  if (shouldPickDifferentDeliveryDate && !location.pathname.includes(_DATE_DELIVERY_PATH)) {
    yield put(change(GeneralFlowForms.date, SubStepFieldNamesDelivery.date, null));
    yield put(push(prefix + _DATE_DELIVERY_PATH));
    return;
  }
  // check if we need to ask for business hours
  if (situation === Situations.STORE || situation === Situations.AUCTION) {
    yield put(push(prefix + '/pickup/time'));
    return;
  }

  yield put(actions.setTimeModalOpen(true));
}

export function* handleHomeDeliveryDateChange(differentDeliveryDate) {
  try {
    const path = yield select(getSearchPath);
    yield put(actions.getPrice(true));

    // show time modal after delivery date selection
    if (path.includes(`${prefix}${_DATE_DELIVERY_PATH}`) && differentDeliveryDate) {
      yield put(actions.setTimeModalOpen(true));
    }

    // show time modal after pickup date selection
    if (path === `${prefix}/date` && !differentDeliveryDate) {
      yield put(actions.setTimeModalOpen(true));
    }
  } catch (err) {
    appUtils.logException('Problem with handling the home delivery date change', err);
  }
}

export function* handleAuctionStoreDeliveryDateChange(payload, differentDeliveryDate) {
  try {
    const path = yield select(getSearchPath);
    const auctionType = yield select(getAuctionType);
    const bothDatesAvailable = yield select(datesAvailable);

    // determine the next step path after chose the date
    let nextPath = '/pickup/time';
    let bvaDeliveryDateSelected = false;

    if (auctionType === AuctionTypes.BVA) {
      // select the current auction pickup collection date
      const bvaCollectionDate = yield select(getAuctionPickupDate);

      if (path.includes(_DATE_BVA_COLLECTION_PATH)) {
        // set the pickup date and time from auction pickup available date and time
        yield put(
          change(GeneralFlowForms.pickup, SubStepFieldNamesPickup.time, {
            start: moment(bvaCollectionDate.start).format('HH:mm'),
            end: moment(bvaCollectionDate.end).format('HH:mm'),
          })
        );
        yield put(change(GeneralFlowForms.date, SubStepFieldNamesPickup.date, bvaCollectionDate.start));

        // navigate to next step when dates are already set in bva flow
        if (bothDatesAvailable) {
          bvaDeliveryDateSelected = true;
        }
        nextPath = '/delivery/time';
      }
      // set the next path to delivery date when custom pickup date for auction selected
      if (!moment(bvaCollectionDate.start).isSame(payload) && !bothDatesAvailable && differentDeliveryDate) {
        nextPath = _DATE_DELIVERY_PATH;
      }
    }
    // update the available dates and price
    yield put(actions.getPrice(true));

    // navigate to next path if it's required
    if (path.includes(_DATE_DELIVERY_PATH) || bvaDeliveryDateSelected) {
      yield put(push(prefix + nextPath));
    }
  } catch (err) {
    appUtils.logException('Problem with handling the auction and store delivery date change', err);
  }
}

// handle delivery date changes from redux-form
export function* handleDeliveryDateChange(payload, isAuctionOrStoreSelected, differentDeliveryDate) {
  if (isAuctionOrStoreSelected) {
    yield call(handleAuctionStoreDeliveryDateChange, payload, differentDeliveryDate);
    return;
  }
  yield call(handleHomeDeliveryDateChange, differentDeliveryDate);
}

export function* handleDateChange(action) {
  if (action.meta.field !== SubStepFieldNamesPickup.date && action.meta.field !== SubStepFieldNamesDelivery.date) {
    return;
  }
  // set expiration date for selected pickup date time after each date changes
  yield put(actions.setSelectedPickupDateTime());

  const dateForm = yield select(getDateForm);
  const differentDeliveryDate = _get(dateForm, 'values.' + SubStepFieldNamesPickup.different_delivery_date, false);
  const isAuctionOrStoreSelected = yield select(isAuctionOrStore);

  // handle pickup date change in /date route
  if (action.meta.field === SubStepFieldNamesPickup.date) {
    const path = yield select(getSearchPath);
    if (path.includes(_DATE_BVA_COLLECTION_PATH)) {
      return;
    }
    const shouldPickDifferentDeliveryDate = yield select(needsDifferentDeliveryDate);
    const value = shouldPickDifferentDeliveryDate ? undefined : action.payload;
    // update delivery first
    yield put(change(GeneralFlowForms.date, SubStepFieldNamesDelivery.date, value));
    // then execute next step
    if (shouldPickDifferentDeliveryDate) {
      yield put(actions.getPrice());
      yield put(push(prefix + _DATE_DELIVERY_PATH));
      return;
    }

    // update the price after date selection
    yield put(actions.getPrice(true));

    // in auction and store case just go for pickup time step immidiately
    if (isAuctionOrStoreSelected) {
      yield put(push(prefix + '/pickup/time'));
      return;
    }

    const chosenPackage = yield select(getChosenPackage);
    const distance = yield select(isRequestAboveMaxDistance);
    const international = yield select(isInternational);
    if (chosenPackage === PackageFieldsValues.economic || distance || international) {
      yield put(actions.setTimeModalOpen(true));
    } else {
      yield put(push(prefix + '/pickup/time'));
    }
    return;
  }

  // handle delivery date change /date/delivery
  if (action.meta.field === SubStepFieldNamesDelivery.date) {
    yield call(handleDeliveryDateChange, action.payload, isAuctionOrStoreSelected, differentDeliveryDate);
  }
}

export function* handleGetMoreDates(action) {
  // Grab last date from set of options previously returned by pricing.
  const existingOptions =
    action.stopType === StopType.pickup ? yield select(getPickupDateOptions) : yield select(getDeliveryDateOptions);
  const lastDate = _get(existingOptions, `[${existingOptions.length - 1}].dates[0]`, null);
  // Bail if we do not have a reference date to get more dates from.
  if (!lastDate) {
    return;
  }
  // Big fat note, when dealing with dates only, use native JS (as proposed by moment-timezone itself, it is correct because we are loading as small set of history of timezone).
  const lastDateObject = new Date(lastDate);
  const nextDateOption = new Date(lastDateObject.getTime() + 86400000);
  const request = yield select(getPriceRequestParams, { [action.stopType]: nextDateOption });
  // We are now in a pricing loading state.
  yield put(actions.setPriceLoading(true));
  // Call the pricing api with updated request params.
  const resp = yield call(priceCalculator, request);
  // Check for new datetime options for this stop type
  const hasOptions = _get(resp, `data.${action.stopType}_datetime_period_options`, false);
  if (hasOptions) {
    const respOptions = _get(resp, `data.${action.stopType}_datetime_period_options.options`, false);
    if (action.stopType === StopType.pickup) {
      // Combine existing pickup dates with new ones returned by pricing
      yield put(actions.setPickupAvailableDates({ options: existingOptions.concat(respOptions) }));
    }

    if (action.stopType === StopType.delivery) {
      // Combine existing pickup dates with new ones returned by pricing
      yield put(actions.setDeliveryAvailableDates({ options: existingOptions.concat(respOptions) }));
    }
  }
  // Done fetching and combining dates
  yield put(actions.setPriceLoading(false));
}

export function* handleTimePreference(action) {
  yield put(actions.setTimeModalOpen(false));
  if (action.payload === customTimePreference.no_preference) {
    const chosenPackage = yield select(getChosenPackage);
    // Set times first
    const defaultTime = { start: '09:00', end: '18:00' };
    yield all([
      put(change(GeneralFlowForms.pickup, SubStepFieldNamesPickup.time, defaultTime)),
      put(change(GeneralFlowForms.delivery, SubStepFieldNamesDelivery.time, defaultTime)),
    ]);
    // then push path
    yield put(push(prefix + (chosenPackage === PackageFieldsValues.custom ? '/pickup/help' : '/contact')));
    return;
  }
  yield put(push(prefix + '/pickup/time'));
}

export function* closeSummaryAfterNavigation(action) {
  if (action.payload.isFirstRendering) {
    return;
  }
  const summaryOpen = yield select(isSheetOpen);
  if (summaryOpen) {
    yield put(actions.toggleSheet());
  }
}

export function* clearMarktplaatsUsersCache(action) {
  if (action.payload.location.search.indexOf('utm_source=marktplaats') > -1) {
    yield all([
      call(resetForms, false),
      put(invalidateCache('generalTransport')),
      put(actions.setProgress(1)),
      put(actions.resetPickupSelectedExpiration()),
      put(actions.setPrice(0)),
      put(actions.setPriceLoading(false)),
    ]);
  }
}

export function* termsStepChecks(action) {
  const location = action.payload.location.pathname;
  if (location.indexOf(prefix + '/terms') > -1) {
    yield put(actions.getPrice());
  }
}

export function* priceHandler(skipAvailableDatesUpdate: boolean = false, isEarlierDatesCall: boolean = false) {
  const items = yield select(getValidItemSets);
  if (items.length === 0) {
    // we can only get a price if we have at least one valid set, if not: reset price
    yield put(actions.resetPrice());
    return;
  }
  try {
    yield put(actions.setPriceLoading(true));
    yield put(actions.hideNotification());
    const request = yield select(getPriceRequestParams);
    if (request === null) {
      throw Error('Price error: Request does not have complete data');
    }

    // when we try to retrieve earlier dates, we need to check a few things
    if (isEarlierDatesCall) {
      // We should not sent the package name, since it is influencing the amount of dates returned
      if (request.package) {
        delete request['package'];
      }
      // When we are at the pickup date selection we don't want to send selected pickup datetime period
      const location = yield select(getLocation);
      if (!location.pathname.includes(_DATE_DELIVERY_PATH) && request.pickup?.available_datetime_period) {
        delete request.pickup.available_datetime_period;
      }
      // Plus we don't want to send selected delivery datetime period
      if (request.delivery?.available_datetime_period) {
        delete request.delivery.available_datetime_period;
      }
    }

    hotjar.addTag(['price_api_call', request]);
    const resp: any = yield call(priceCalculator, request);
    if (_get(resp, 'data.price.incl_vat.amount', false)) {
      const price = resp.data.price.incl_vat.amount;
      hotjar.addTag(['price_api_response', resp.data]);
      const isBelgiumOnSpecial = _get(resp.data, 'notes', []).includes(PriceNotes.belgium_special_pricing);
      const action = [
        put(actions.setPriceLoading(false)),
        put(actions.setPrice(price)),
        put(actions.setPriceStructure(resp.data.price_structure)),
        put(actions.setPriceList(resp.data.price_list)),
        put(actions.setPriceSpecial('be', isBelgiumOnSpecial)),
        put(actions.setQuotes(resp.data)),
      ];
      if (!skipAvailableDatesUpdate) {
        action.push(put(actions.setPickupAvailableDates(resp.data.pickup_datetime_period_options)));
        action.push(put(actions.setDeliveryAvailableDates(resp.data.delivery_datetime_period_options)));
      }
      yield all(action);
    }
  } catch (e) {
    appUtils.logger(e);
    const formItems = yield select(getFormValues(GeneralFlowForms.items));
    const request = yield select(getPriceRequestParams);
    const numberOfAttempts = yield select(getPriceCallRetryAttempts);
    appUtils.logException(`Price call failed`, {
      error: e,
      numberOfAttempts,
      payload: request,
      rawItemFormValues: _get(formItems, 'values', []),
    });
    yield put(actions.setPriceLoading(false));
  }
}

// if there is any lot data from auction flow, then reset the itemSets
export function* resetItemSets() {
  const route = yield select(getSearchPath);
  if (matchPath(route, { path: `${prefix}/items`, exact: true, strict: false })) {
    try {
      const pickupLots = yield select(getPickupLots);
      if (pickupLots.length > 0) {
        yield all([
          put(change(GeneralFlowForms.pickup, 'lots', [])),
          put(reset(GeneralFlowForms.items)),
          put(actions.getPrice()),
        ]);
      }
    } catch (err) {
      appUtils.logException('Problem in resetting the item set', { err });
    }
  }
}

export function* prefillExampleDimensions() {
  const location = window.location.href;
  const needsPrefill = location.indexOf(prefix + '/items') > -1 && location.indexOf('/business') === -1;
  if (!needsPrefill) {
    return;
  }
  const referrer = yield select(getReferrer);
  const pristine = yield select(isPristine(GeneralFlowForms.items));
  let targetProduct: any = null;
  Object.keys(products).map(key => {
    if (referrer.indexOf(key) > -1) {
      targetProduct = key;
    }
  });
  if (targetProduct) {
    if (pristine) {
      yield put(
        initialize(
          GeneralFlowForms.items,
          {
            itemSets: [
              {
                items: [
                  {
                    title: products[targetProduct].label || targetProduct,
                    width: products[targetProduct].width,
                    height: products[targetProduct].height,
                    length: products[targetProduct].length,
                  },
                ],
              },
            ],
          },
          { keepDirty: true }
        )
      );
    }
  }
}

export function* determineNextSubStepOnChange(action) {
  // first check pricing call
  const transport = yield select(getGeneralTransportRequest);
  if (transport.price.isLoading || transport.price.failedAttempts) {
    return;
  }

  // Are we dealing with subStepFields?
  const subStepFields = [
    SubStepFieldNamesPickup.situation,
    SubStepFieldNamesDelivery.situation,
    SubStepFieldNamesPickup.help_carrying,
    SubStepFieldNamesDelivery.help_carrying,
    SubStepFieldNamesPickup.auction_type,
    SubStepFieldNamesPickup.help_equipment,
    SubStepFieldNamesDelivery.help_equipment,
    SubStepFieldNamesPickup.time,
    SubStepFieldNamesPickup.marktplaats_option,
    SubStepFieldNamesDelivery.time,
    SubStepFieldNamesPickup.situation_home_floors_combined,
    SubStepFieldNamesDelivery.situation_home_floors_combined,
    SubStepFieldNamesPickup.instant_booking,
  ];
  if (!subStepFields.includes(action.meta.field)) {
    return;
  }

  // So we do need to handle this field and value
  // Show the user that he selected the field (wait for animation)
  yield delay(250);

  // Handle instant booking selection
  // When the user has this as an option and clicks it, he only needs to add contact details
  if (action.meta.field === SubStepFieldNamesPickup.instant_booking) {
    yield put(push(`${prefix}/contact`));
    return;
  }

  // proceed determining next step
  const isPickup = action.meta.field.indexOf('pickup') > -1;
  const formName = isPickup ? GeneralFlowForms.pickup : GeneralFlowForms.delivery;
  const pathPrefix = prefix + (isPickup ? '/pickup' : '/delivery');
  const fieldNames = isPickup ? SubStepFieldNamesPickup : SubStepFieldNamesDelivery;
  const formValues = yield select(getFormValues(formName));
  const pickupAtStoreOrAuction = yield select(isAuctionOrStore);

  // if is pickup situation && situation is home
  if (action.meta.field === fieldNames.situation && action.payload === Situations.HOME) {
    yield put(push(`${prefix}/items`));
    return;
  }

  if (action.meta.field === fieldNames.marktplaats_option) {
    if (action.payload === 'continue') {
      yield all([
        put(change(GeneralFlowForms.pickup, 'pickup.situation', Situations.HOME)),
        put(push(`${prefix}/items`)),
      ]);
      return;
    } else {
      yield put(push(`${pathPrefix}/marktplaats/form`));
      return;
    }
  }

  // Situation = marktplaats then ask for more information
  if (action.meta.field === fieldNames.situation && action.payload === Situations.MARKTPLAATS) {
    // We used to send the user to 'pathPrefix/marktplaats', but conversion was low, so we sent to 'items', and show a notification
    yield put(push(`${prefix}/items`));
    return;
  }

  // Situation = auction|store
  if (action.meta.field === fieldNames.situation) {
    if (action.payload === Situations.STORE) {
      yield put(push(`${prefix}/items`));
    }
    if (action.payload === Situations.AUCTION) {
      yield put(push(`${pathPrefix}/auctions`));
    }
    return;
  }

  // auction type selection step
  if (action.meta.field === fieldNames.auction_type) {
    if (action.payload === AuctionTypes.BVA) {
      yield put(push(`${pathPrefix}/auctions/bva`));
    } else {
      yield put(push(`${prefix}/items`));
    }
    return;
  }

  // Help needed with equipment
  if (action.meta.field === fieldNames.help_carrying && action.payload === CarryingHelpChoices.NOT_NEEDED) {
    yield put(push(`${pathPrefix}/help/equipment`));
    return;
  }

  // Help with equipment chosen, goto date or skip
  if (
    action.meta.field === fieldNames.help_carrying &&
    (action.payload === CarryingHelpChoices.EQUIPMENT_TAILGATE_PALLET_JACK ||
      action.payload === CarryingHelpChoices.EQUIPMENT_TAILGATE)
  ) {
    yield put(push(`${prefix}/contact`));
    return;
  }

  // Help, plus situation is home
  if (action.meta.field === fieldNames.help_carrying && !pickupAtStoreOrAuction) {
    yield put(push(`${pathPrefix}/home_and_floor`));
    return;
  }

  if (
    isPickup &&
    (action.meta.field === fieldNames.situation_home_floors_combined ||
      (pickupAtStoreOrAuction &&
        action.meta.field === fieldNames.help_carrying &&
        action.payload !== CarryingHelpChoices.EQUIPMENT_TAILGATE))
  ) {
    if (_get(formValues, 'pickup.help_carrying', '') !== CarryingHelpChoices.EXTRA_DRIVER) {
      yield put(push(`${prefix}/delivery/help`));
    } else {
      yield put(push(`${prefix}/delivery/home_and_floor`));
    }
    return;
  }
  if (!isPickup && action.meta.field === fieldNames.situation_home_floors_combined) {
    const pickupHelp = yield select(getPickupHelp);
    const deliveryHelp = yield select(getDeliveryHelp);
    const pickupSituation = yield select(getPickupSituation);
    // when extra driver is selected, we need to ask for weight
    if (
      pickupSituation !== Situations.AUCTION &&
      [pickupHelp, deliveryHelp].includes(CarryingHelpChoices.EXTRA_DRIVER)
    ) {
      yield put(push(`${prefix}/weight`));
      return;
    }
    // if not proceed to contact
    yield put(push(`${prefix}/contact`));
    return;
  }

  // Situation, Floors, Elevator, Date, Time
  yield determineNextSubmitStep();
}

export function* setPickupDateTime() {
  // put pickup date time in the delivery values
  const pickupForm = yield select(getPickupForm);
  const dateForm = yield select(getDateForm);

  if (
    _get(pickupForm, `values.${SubStepFieldNamesPickup.time}`, false) &&
    _get(dateForm, `values.${SubStepFieldNamesPickup.date}`, false)
  ) {
    yield put(change(GeneralFlowForms.delivery, SubStepFieldNamesDelivery.pickup_time, pickupForm.values.pickup.time));
    yield put(change(GeneralFlowForms.delivery, SubStepFieldNamesDelivery.pickup_date, dateForm.values.pickup.date));
  }
}

export function* updateFormSyncErrors(action) {
  if (Object.keys(action.payload.syncErrors).length === 0) {
    yield put(flowActions.pushNotification(null));
  }
}

export function* handleSyncErrors(action) {
  const step = yield select(getProgressStep);
  const route = yield select(getSearchPath);
  if (action.error) {
    if (
      matchPath(route, { path: `${prefix}/pickup/auctions/bva`, exact: true, strict: false }) ||
      matchPath(route, { path: `${prefix}/pickup/invoice`, exact: true, strict: false })
    ) {
      return;
    } else if (matchPath(route, { path: `${prefix}/pickup/marktplaats/form`, exact: true, strict: false })) {
      yield put(flowActions.pushNotification(translate('request_flow.errors.fill_in_inputs')));
    } else if (step >= 7) {
      yield put(flowActions.pushNotification(null));
    } else {
      yield put(flowActions.pushNotification(translate('request_flow.errors.select_required')));
    }
  }
}

export function* scrollAfterSyncError(action) {
  try {
    if (
      action.error &&
      (action.meta.form === GeneralFlowForms.contact ||
        action.meta.form === GeneralFlowForms.items ||
        action.meta.form === GeneralFlowForms.terms)
    ) {
      const messageElement = document.querySelector('.input-el--feedback--error') as HTMLElement;
      if (messageElement) {
        messageElement.scrollIntoView();
        setTimeout(() => window.scroll(0, window.scrollY - 100), 100);
      }
    }
  } catch (err) {
    appUtils.logger(err);
  }
}

export function* afterPushNotification(action) {
  const messageElement = document.querySelector('.message') as HTMLElement;
  if (messageElement) {
    messageElement.scrollIntoView();
    // first wait for scroll to the view and wait for update window scroll Y then scroll a bit to the top
    setTimeout(() => window.scroll(0, window.scrollY - 50), 100);
  }
}

export function getCurrentPickupDeliveryFields(path, stepKey) {
  const stopType = path.indexOf('pickup') > -1 ? 'pickup' : 'delivery';
  if (stepKey === 'help') {
    return `${stopType}.help_carrying`;
  }
  if (stepKey === `${stopType}_home`) {
    return `${stopType}.home`;
  }
  if (stepKey === `${stopType}_date`) {
    return `${stopType}.date`;
  }
  if (stepKey === 'time') {
    return `${stopType}.time`;
  }
  if (stepKey === 'floors') {
    return `${stopType}.floor_count`;
  }
  if (stepKey === 'elevator') {
    return `${stopType}.floor_elevator`;
  }
  if (stepKey === 'equipment') {
    return `${stopType}.help_equipment`;
  }
  return `${stopType}.situation`;
}

export function* fetchMarktplaatsRequest(action) {
  const url = action.payload.location.pathname;
  const path = matchPath(url, { path: `${prefix}/marktplaats_contact_requests/:id`, exact: true, strict: false });
  const uuid = _get(path, 'params.id', null);
  if (path) {
    try {
      // reset messages
      yield put(flowActions.pushNotification(null));
      yield put(change(GeneralFlowForms.mp_seller, 'pickup.marktplaats.result', ''));
      yield put(flowActions.setProgress(0));
      const form = GeneralFlowForms.mp_seller;
      const response = yield call(fetchMarktplaatsContactRequests, uuid);
      // prefill buyer and seller emails as hidden fields
      yield all([
        put(change(form, 'pickup.marktplaats.buyer_email', _get(response, 'data.buyer_email', ''))),
        put(change(form, 'pickup.marktplaats.seller_email', _get(response, 'data.seller_email', ''))),
        call(hideProgressElements),
      ]);
    } catch (err) {
      appUtils.logException('fetching marktplaats contact request failed', action);
      yield put(flowActions.pushNotification(translate('request_flow.errors.handle_request')));
    }
  }
}

export function hideProgressElements() {
  const sidebar = document.querySelector('.sheet-layout--sidebar') as HTMLElement;
  const stepsIndicator = document.querySelector('.progress-steps') as HTMLElement;
  // hide unnecessary elements
  sidebar.style.display = 'none';
  stepsIndicator.style.display = 'none';
}

export function fetchMarktplaatsContactRequests(uuid) {
  return http()
    .get(`/marktplaats_contact_requests/${uuid}`)
    .then(resp => resp);
}

export function marktplaatsContactRequests(body) {
  return http()
    .post('/marktplaats_contact_requests', body)
    .then(resp => resp);
}

export function* submitMartkplaatsSellerForm(action) {
  try {
    yield put(flowActions.pushNotification(null));
    const data = yield select(getSellerFormData);
    yield call(marktplaatsContactRequests, data);
    yield put(change(GeneralFlowForms.mp_seller, 'pickup.marktplaats.result', 'succeed'));
  } catch (err) {
    const errMessage = String(translate('request_flow.errors.handle_request'));
    appUtils.logException(errMessage, err);
    yield all([
      put(flowActions.pushNotification(errMessage)),
      put(change(GeneralFlowForms.mp_seller, 'pickup.marktplaats.result', 'failed')),
    ]);
  }
}

export function* submitMarktplaatsBuyerForm(data) {
  try {
    yield put(flowActions.pushNotification(null));
    yield call(marktplaatsContactRequests, data);
    yield put(push(`${prefix}/pickup/marktplaats/form?success=true`));
  } catch (err) {
    const msg = String(translate('request_flow.errors.handle_request'));
    yield put(flowActions.pushNotification(msg));
    appUtils.logException('Email to marktplaats seller failed', err);
  }
}

export function* determineNextSubmitStep() {
  // check missed validations for sub steps (pickup & delivery)
  const path = yield select(getSearchPath);
  const isContact = path.indexOf('contact') > -1;
  const isPickup = path.indexOf('pickup') > -1;
  const isDelivery = path.indexOf('delivery') > -1;
  const pathArray = path.split('/');
  const pathEnd = pathArray[pathArray.length - 1];
  const situation = yield select(getPickupSituation);
  // marktplaats contact form submission
  if (path.indexOf('marktplaats/form') > -1) {
    const marktplaatsBuyerFormValues = yield select(getMarktplaatsBuyerFormValues);
    yield call(submitMarktplaatsBuyerForm, marktplaatsBuyerFormValues);
    return;
  }

  // marktplaats options form submission
  if (path.indexOf('marktplaats') > -1) {
    const pickupForm = yield select(getPickupForm);
    if (_get(pickupForm, `values.${SubStepFieldNamesPickup.marktplaats_option}`, '') === 'continue') {
      yield all([
        put(change(GeneralFlowForms.pickup, 'pickup.situation', Situations.HOME)),
        put(push(`${prefix}/items`)),
      ]);
      return;
    }

    yield put(push(`${prefix}/pickup/marktplaats/form`));
    return;
  }

  if (pathEnd === 'bva') {
    const isLotDetailsValid = yield select(isValid(GeneralFlowForms.pickup));
    if (isLotDetailsValid) {
      yield put(push(`${prefix}/weight`));
    }
    return;
  }

  if (!isContact && (isPickup || isDelivery)) {
    const stepKey = yield select(getStepKey);
    const currentField = getCurrentPickupDeliveryFields(path, stepKey);
    const values = yield select(getFormValues(isPickup ? GeneralFlowForms.pickup : GeneralFlowForms.delivery));
    if (
      !_get(values, currentField, false) &&
      currentField !== 'pickup.floor_elevator' &&
      currentField !== SubStepFieldNamesPickup.invoice &&
      currentField !== 'delivery.floor_elevator' &&
      !path.includes('pickup/invoice') // Invoice upload validation is handled now trough normal validation
    ) {
      yield delay(500);
      yield put(flowActions.pushNotification(translate('request_flow.errors.select_required')));
      return;
    }
  }

  // to prevent default behaviour, stop here
  if (!isPickup && !isDelivery && !isContact) {
    return;
  }

  if (isContact) {
    switch (pathEnd) {
      case 'contact':
        yield put(push(`${prefix}/contact/pickup`));
        break;
      case 'pickup':
        yield put(push(`${prefix}/contact/delivery`));
        break;
      case 'delivery':
        yield put(push(`${prefix}${situation === Situations.HOME ? '/terms' : '/pickup/invoice'}`));
        break;
    }
    return;
  }

  // We are not contact, so some more work is involved
  const pathPrefix = prefix + (isPickup ? '/pickup' : '/delivery');
  const fieldNames = isPickup ? SubStepFieldNamesPickup : SubStepFieldNamesDelivery;

  const auctionType = yield select(getAuctionType);
  const formValuesPickup = yield select(getFormValues(GeneralFlowForms.pickup));
  const formValuesDelivery = yield select(getFormValues(GeneralFlowForms.delivery));
  const pickupHelp = _get(formValuesPickup, SubStepFieldNamesPickup.help_carrying, '');
  const hasPickupHelp = pickupHelp !== CarryingHelpChoices.NOT_NEEDED || pickupHelp !== CarryingHelpChoices.NEEDED;

  const formValues = isPickup ? formValuesPickup : formValuesDelivery;
  const floorCount = _get(formValues, fieldNames.floor_count, false);
  const needsElevatorQuestion = Number(floorCount) > 1;

  let nextPath = '';
  const pickupSituation = yield select(getPickupSituation);

  switch (pathEnd) {
    case 'invoice':
      nextPath = `${prefix}/terms`;
      break;
    case 'pickup':
      nextPath = `${prefix}/items`;
      break;
    case 'BvaAuctions':
    case 'auctions':
      if (auctionType === AuctionTypes.BVA) {
        nextPath = `${pathPrefix}/auctions/bva`;
      } else {
        nextPath = `${prefix}/items`;
      }
      break;
    case 'delivery':
      nextPath = hasPickupHelp ? `${prefix}/contact` : `${pathPrefix}/help`;
      break;
    case 'floors':
      nextPath = needsElevatorQuestion ? `${pathPrefix}/home/elevator` : `${prefix}/contact`;
      break;
    case 'equipment':
      // when equipment changed, we proceed to ask for weight
      // but we don't when the pickup situation is Auction, cause then we ask this earlier
      nextPath = pickupSituation !== Situations.AUCTION ? `${prefix}/weight` : `${prefix}/contact`;
      break;
    case 'help':
      const chosenValue = _get(formValues, fieldNames.help_carrying, '');
      if (chosenValue === CarryingHelpChoices.EQUIPMENT_PROVIDED_BY_CUSTOMER) {
        nextPath = isPickup ? `${prefix}/delivery/help` : `${prefix}/contact`;
        break;
      }
      const equipmentOptions = [
        CarryingHelpChoices.EQUIPMENT_TAILGATE,
        CarryingHelpChoices.EQUIPMENT_TAILGATE_PALLET_JACK,
        CarryingHelpChoices.EQUIPMENT_TAILGATE_EXTRA_DRIVER,
      ];
      const isWithEquipment = equipmentOptions.includes(chosenValue);
      // when equipment is selected and we are not dealing with auction, ask the kind, else proceed to ask for home and floor
      nextPath =
        isWithEquipment && pickupSituation !== Situations.AUCTION
          ? `${pathPrefix}/help/equipment`
          : `${pathPrefix}/home_and_floor`;
      break;
    case 'time':
      yield put(updateSyncErrors(isPickup ? GeneralFlowForms.pickup : GeneralFlowForms.delivery, {}));
      if (isPickup && hasPickupHelp) {
        nextPath = `${prefix}/delivery/time`;
      } else if (isPickup && !hasPickupHelp) {
        nextPath = `${prefix}/delivery/time`;
      } else {
        const chosenPackage = yield select(getChosenPackage);
        if (chosenPackage === PackageFieldsValues.custom) {
          nextPath = `${prefix}/pickup/help`;
        } else {
          nextPath = `${prefix}/contact`;
        }
      }
      break;
  }
  yield put(push(nextPath));
}

export function* determineSkipDateTimeStep(action) {
  // TODO: refactor next and back sagas
  const path = action.payload.location.pathname;
  const skipDateTime = yield select(shouldSkipDateTimeStep);
  if (skipDateTime) {
    if (action.payload.action === 'PUSH') {
      if (path.indexOf('/pickup/time') > -1) {
        yield put(push(`${prefix}/delivery/help`));
      }
      if (path.indexOf('/delivery/time') > -1) {
        yield put(push(`${prefix}/contact`));
      }
    }
    if (action.payload.action === 'POP' && path.indexOf('/date') > -1) {
      yield put(goBack());
    }
    if (action.payload.action === 'POP' && path.indexOf('/time') > -1) {
      yield put(goBack());
    }
  }
}

export function* replaceCountryCode(action) {
  if (action.meta.field === 'customer.business.address.country') {
    const dropdown = document.getElementById('customer.business.address.country') as HTMLSelectElement;
    if (dropdown !== null) {
      yield put(
        change(
          action.meta.form,
          'customer.business.address.country_code',
          dropdown.options[dropdown.selectedIndex].text
        )
      );
    }
  }
}

export function fetchBillingAddress(IRI) {
  return http()
    .get(IRI, { withCredentials: true })
    .then(resp => resp);
}

export function* changeCustomerType(action) {
  if (action.meta.field === 'customer.type' && action.payload === 'business') {
    yield call(fetchAndFillBillingAddress);
  }
}

export function* fetchAndFillBillingAddress() {
  const user = yield select(getLoggedInUser);
  const userBillingAddress = _get(user, 'userData.account.address', null);
  if (userBillingAddress) {
    const billingAddress = yield call(fetchBillingAddress, userBillingAddress);
    yield all([
      put(change(GeneralFlowForms.contact, 'customer.business.address.line1', _get(billingAddress, 'data.line1', ''))),
      put(
        change(
          GeneralFlowForms.contact,
          'customer.business.address.locality',
          _get(billingAddress, 'data.locality', '')
        )
      ),
      put(
        change(
          GeneralFlowForms.contact,
          'customer.business.address.postal_code',
          _get(billingAddress, 'data.postal_code', '')
        )
      ),
      put(
        change(GeneralFlowForms.contact, 'customer.business.address.country', _get(billingAddress, 'data.country', ''))
      ),
    ]);
  }
}

// fetch historical addresses in contact step initalization
export function* fetchHistoricalAddresses(action) {
  if (matchPath(action.payload.location.pathname, { path: `${prefix}/contact`, exact: true, strict: false })) {
    const historicalAddresses = yield select(getHistoricalAddresses);
    if (historicalAddresses?.length) {
      const res: List<Address> = yield call(coreClient.users.listStopAddressesForCurrentUser);
      yield put(actions.setHistoricalAddresses(res['hydra:member']));
    }
  }
}

// prefill current address fields in pickup and delivery based on selected historical address
export function* handleHistoricalAddressChange(action) {
  try {
    if (action.meta.form === GeneralFlowForms.contact && action.meta.field.indexOf('historical_addresses') > -1) {
      const historicalAddress: AddressOption[] = yield select(getHistoricalAddresses);
      const selectedAddress = historicalAddress.find(address => address['@id'] === action.payload);
      const stopType = action.meta.field.indexOf('pickup') > -1 ? 'pickup' : 'delivery';
      // replace in pickup address fields
      yield all([
        put(change(GeneralFlowForms.contact, `${stopType}.address.line1`, _get(selectedAddress, 'line1', ''))),
        put(change(GeneralFlowForms.contact, `${stopType}.address.locality`, _get(selectedAddress, 'locality'))),
        put(change(GeneralFlowForms.contact, `${stopType}.address.postal_code`, _get(selectedAddress, 'postal_code'))),
      ]);
    }
  } catch (e) {
    appUtils.logException('Oops, fetching the historical addresses failed!', e);
  }
}

export function* prefillBusinessAddress(action) {
  if (matchPath(action.payload.location.pathname, { path: `${prefix}/contact`, exact: true, strict: false })) {
    const contactForm = yield select(getFormValues(GeneralFlowForms.contact));
    if (_get(contactForm, 'values.customer.type', '') !== 'individual') {
      yield call(fetchAndFillBillingAddress);
    }
  }
}

export function* addMyDetails(action) {
  // check for field name and value
  if (!action.payload || !action.meta.field.includes('add_my_details')) {
    return;
  }

  const isPickup = action.meta.field.includes('pickup');
  const label = `${isPickup ? 'pickup' : 'delivery'}.contact`;
  const formValues = yield select(getFormValues(GeneralFlowForms.contact));
  yield all([
    put(change(GeneralFlowForms.contact, `${label}.first_name`, _get(formValues, 'customer.contact.first_name'))),
    put(change(GeneralFlowForms.contact, `${label}.last_name`, _get(formValues, 'customer.contact.last_name'))),
    put(change(GeneralFlowForms.contact, `${label}.email`, _get(formValues, 'customer.contact.email'))),
    put(change(GeneralFlowForms.contact, `${label}.phone`, _get(formValues, 'customer.contact.phone'))),
  ]);
}

export function* removeMyDetails(action) {
  if (action.meta.field.includes('add_my_details') && !action.payload) {
    const isPickup = action.meta.field.includes('pickup');
    const label = `${isPickup ? 'pickup' : 'delivery'}.contact`;
    const fields = ['first_name', 'last_name', 'email', 'phone'].map(field => `${label}.${field}`);
    yield put(clearFields(GeneralFlowForms.contact, false, false, ...fields));
  }
}

export function* triggerSyncValidation(action) {
  // check for field name and value
  if (action.meta.field.indexOf('customer.type') === -1 || action.payload === 'business') {
    return;
  }
  // hack, when unregistering the business fields, sync validation kicks in and marks as unvalid.
  // to prevents this, we retrigger the sync validation by updating the first_name field twice
  const label = `customer.contact.first_name`;
  const formValues = yield select(getFormValues(GeneralFlowForms.contact));
  const valueFirstName = _get(formValues, label, '');
  yield all([
    put(change(GeneralFlowForms.contact, label, '')),
    put(change(GeneralFlowForms.contact, label, valueFirstName)),
  ]);
}

export function* addLoggedInDetails(action) {
  // check if we are in the contact section
  if (window.location.href.indexOf(prefix + '/contact') === -1) {
    return;
  }
  // set data
  const label = `customer.contact`;
  const formValues = yield select(getFormValues(GeneralFlowForms.contact));
  const loggedInUser = yield select(getLoggedInUser);
  // if the values are null or the user is not logged in, then don't do anything
  if (_get(formValues, label, null) !== null || !loggedInUser.loggedIn) {
    return;
  }
  // customer fields
  const fieldsCustomer = ['first_name', 'last_name', 'phone', 'email'];
  yield all(
    fieldsCustomer.map(field =>
      put(change(GeneralFlowForms.contact, `${label}.${field}`, _get(loggedInUser, `userData.${field}`)))
    )
  );

  // is the logged in user a business user?
  if (_get(loggedInUser, 'userData.account.account_type') !== 'business') {
    return;
  }
  // set customer type field to business
  yield put(change(GeneralFlowForms.contact, customerTypeFields.type, 'business'));
  // Registering fields need to happen
  yield delay(100);
  // fillup the business fields
  const labelBusiness = `customer.business`;
  const fieldsBusiness = ['name', 'coc_number', 'vat_number'];
  yield all(
    fieldsBusiness.map(field =>
      put(
        change(GeneralFlowForms.contact, `${labelBusiness}.${field}`, _get(loggedInUser, `userData.account.${field}`))
      )
    )
  );
}

// prefill default dummy contact details for pickup in auction situation
export function* prefillDefaultPickupContactDetails(action) {
  const acts: any = [];
  const contactFields = ['first_name', 'last_name', 'email', 'phone'];
  const defaultValues = ['veiling', 'veiling', 'noreply@brenger.nl', '0000000000'];
  try {
    const url = action.payload.location.pathname;
    const stopType = yield select(getStopSituation, StopType.pickup);
    if (
      matchPath(url, { path: `${prefix}/contact/pickup`, exact: true, strict: false }) &&
      stopType === Situations.AUCTION
    ) {
      // prefill the fields by default values actions
      contactFields.map((field, i) => {
        acts.push(put(change(GeneralFlowForms.contact, `pickup.contact.${field}`, defaultValues[i])));
      });
      // touch all contact fields actions
      acts.push(
        put(
          touch(
            GeneralFlowForms.contact,
            contactFields.map(field => `pickup.contact.${field}`)
          )
        )
      );
      yield all(acts);
    }
  } catch (err) {
    appUtils.logger(err);
  }
}

export function* resetAddressForm(action) {
  const url = action.payload.location.pathname;
  const addressFields = ['line1', 'postal_code', 'locality', 'instructions'];
  const contactFields = ['first_name', 'last_name', 'phone', 'email'];
  if (matchPath(url, { path: `${prefix}/contact/delivery`, exact: true, strict: false })) {
    yield all(addressFields.map(field => put(untouch(GeneralFlowForms.contact, `delivery.address.${field}`))));
    yield all(contactFields.map(field => put(untouch(GeneralFlowForms.contact, `delivery.contact.${field}`))));
  }
  if (matchPath(url, { path: `${prefix}/contact/pickup`, exact: true, strict: false })) {
    yield all(addressFields.map(field => put(untouch(GeneralFlowForms.contact, `pickup.address.${field}`))));
    yield all(contactFields.map(field => put(untouch(GeneralFlowForms.contact, `pickup.contact.${field}`))));
  }
}

export function* resetForms(removeDestination: boolean = true) {
  const resetActions: PutEffect[] = [
    put(reset(GeneralFlowForms.items)),
    put(reset(GeneralFlowForms.pickup)),
    put(reset(GeneralFlowForms.delivery)),
    put(reset(GeneralFlowForms.packages)),
    put(reset(GeneralFlowForms.date)),
    put(reset(GeneralFlowForms.contact)),
    put(actions.resetPrice()),
    put(actions.extendCacheExpiration()),
  ];
  if (removeDestination) {
    resetActions.push(put(actions.resetDestinationDetails()));
  }
  yield all(resetActions);
}

export function* resetDestinationWidget() {
  /*
   * BRENGER_WIDGET_RESET
   * Custom event that takes care of widget reset
   * See event listener here: stand-alone/ts/widget.ts:22
   * */
  document.dispatchEvent(new CustomEvent('BRENGER_WIDGET_RESET'));
}

export function* resetSyncErrors(action) {
  yield put(updateSyncErrors(action.meta.form, {}));
}

export function* prefillPostalCode(action) {
  const stopType = action.meta.field.indexOf('pickup') > -1 ? StopType.pickup : StopType.delivery;
  try {
    if (
      action.meta.field === `${stopType}.address.line1` &&
      action.meta.form === GeneralFlowForms.contact &&
      action.payload.length > 3
    ) {
      const results = yield call(getGeoCodeResultsFilteredOnLocality, stopType, action.payload);
      if (results.length === 0) {
        yield all([
          put(clearFields(GeneralFlowForms.contact, false, true, `${stopType}.address.lat`)),
          put(clearFields(GeneralFlowForms.contact, false, true, `${stopType}.address.lng`)),
        ]);
        return;
      }
      const postalCode = _get(results[0], 'address.postal_code', null);
      if (postalCode && postalCode.indexOf(',') < 0) {
        yield all([
          put(change(GeneralFlowForms.contact, `${stopType}.address.postal_code`, postalCode)),
          put(change(GeneralFlowForms.contact, `${stopType}.address.lat`, _get(results[0], 'address.latitude'))),
          put(change(GeneralFlowForms.contact, `${stopType}.address.lng`, _get(results[0], 'address.longitude'))),
          put(updateSyncWarnings(GeneralFlowForms.contact, {})),
        ]);
      } else {
        yield all([
          put(clearFields(GeneralFlowForms.contact, false, true, `${stopType}.address.lat`)),
          put(clearFields(GeneralFlowForms.contact, false, true, `${stopType}.address.lng`)),
        ]);
      }
    }
  } catch (Err) {
    appUtils.logger(Err);
  }
}

export function* validatePostalCode(action) {
  const stopType = action.meta.field.indexOf('pickup') > -1 ? 'pickup' : 'delivery';
  if (action.meta.field !== `${stopType}.address.postal_code` || action.meta.form !== GeneralFlowForms.contact) {
    return;
  }
  const postalCode = action.payload.trim();
  if (postalCode === '') {
    return;
  }
  const formErrors = yield select(getFormSyncErrors(GeneralFlowForms.contact));
  if (_get(formErrors, `${stopType}.address.postal_code`) !== undefined) {
    // postal code is not valid
    return;
  }
  try {
    const results = yield call(getGeoCodeResultsFilteredOnLocality, stopType, postalCode);
    if (results.length === 0) {
      // show warning
      const formValues = yield select(getFormValues(GeneralFlowForms.contact));
      const locality = _get(formValues, `${stopType}.address.locality`, translate('form.fields.locality.label'));
      yield put(
        updateSyncWarnings(GeneralFlowForms.contact, {
          [stopType]: { address: { postal_code: translate('form.fields.address.postal_code.warning', { locality }) } },
        })
      );
    } else {
      // clear warnings
      yield put(updateSyncWarnings(GeneralFlowForms.contact, {}));
    }
  } catch (Err) {
    appUtils.logger(Err);
  }
}

function* getGeoCodeResultsFilteredOnLocality(stopType, query) {
  const formValues = yield select(getFormValues(GeneralFlowForms.contact));
  const countryCode = _get(formValues, `${stopType}.address.country_code`);
  const locality = _get(formValues, `${stopType}.address.locality`);
  if (!countryCode || !locality) {
    // something is missing
    return;
  }
  // get locality options
  const search = yield call(searchGeoPoint, `${query},${countryCode}`);
  // filter them on filled out locality
  return search.data.filter(item => item.address.locality === locality);
}

export function* prefillLocalityInput(action) {
  if (
    action.meta.form !== GeneralFlowForms.contact ||
    (action.payload.name.indexOf('pickup') === -1 && action.payload.name.indexOf('delivery') === -1)
  ) {
    return;
  }
  const stopType = action.payload.name.indexOf('pickup') > -1 ? StopType.pickup : StopType.delivery;
  const fieldNamePrefix = stopType + '.address.';
  const widgetDetails = yield select(getStopDestinationDetails, stopType);
  if (widgetDetails === null) {
    return;
  }
  yield all([
    put(change(GeneralFlowForms.contact, fieldNamePrefix + 'administrative_area', widgetDetails.administrative_area)),
    put(change(GeneralFlowForms.contact, fieldNamePrefix + 'locality', widgetDetails.locality)),
    put(change(GeneralFlowForms.contact, fieldNamePrefix + 'country_name', widgetDetails.country_name)),
    put(change(GeneralFlowForms.contact, fieldNamePrefix + 'country_code', widgetDetails.country_code)),
  ]);
}

export function* handleLoadingStatus() {
  // reset loadings from price
  yield put(actions.setPriceLoading(false));
  // reset general errors
  yield put(actions.pushNotification(null));
}

export function* goToFirstStepGeneralFlow() {
  yield put(push(prefix + '?expired=true'));
}

export function* checkPersistExpiration(action) {
  // for expiration, check if we already handle the reset
  if (window.location.search.indexOf('reset_flow') > -1) {
    return;
  }

  // We should redirect the user only when we are dealing with a location in the general flow.
  // In other cases we are rendering a different part of the app, the cache should still expire according to the rules, but there is no redirect needed
  const shouldRedirect = window.location.href.indexOf(`customer${prefix}`) > -1;

  const pickupDate = yield select(getStopAvailableDate, StopType.pickup);
  if (pickupDate.length > 10) {
    const firstPickupDate = pickupDate[0];
    const pickupSelectedExpirationDateTime = yield select(getSelectedDateTime);
    const isPickupOnToday = moment(firstPickupDate).isSame(moment(), 'day');
    const untilMidnightMinutes: number = moment(pickupSelectedExpirationDateTime).diff(moment(), 'minutes');
    if (untilMidnightMinutes <= 0 || (new Date().getHours() >= 12 && isPickupOnToday)) {
      yield all([call(resetForms), put(actions.resetPickupSelectedExpiration())]);
      if (shouldRedirect) {
        yield call(goToFirstStepGeneralFlow);
      }
    }
  }

  // we are generating the expired date for 24hr as default w redux-cache in reducer
  const expiredDiffHours = yield select(expiredHours);
  if (expiredDiffHours >= 0) {
    // more than 24hr
    yield all([
      put(invalidateCache('generalTransport')),
      put(actions.resetPickupSelectedExpiration()),
      call(resetForms),
    ]);
    if (shouldRedirect) {
      yield call(goToFirstStepGeneralFlow);
    }
    return;
  }
}

export function* checkInternational() {
  const destinationLoaded = yield select(isDestinationLoaded);
  if (!destinationLoaded) {
    return;
  }
  const pickup = yield select(getPickupPoint);
  const delivery = yield select(getDeliveryPoint);
  if (pickup.latitude && pickup.longitude && delivery.latitude && delivery.longitude) {
    const endpoint =
      _INTERNATIONAL_CHECK_BASE_URL +
      `/is_route_international?from=${pickup.latitude},${pickup.longitude}&to=${delivery.latitude},${delivery.longitude}`;
    const request = url =>
      http()
        .get(url)
        .then(resp => resp);
    const response = yield call(request, endpoint);
    yield put(actions.setInternational(response.data.international));
  } else {
    appUtils.logException('Passed the first step without selected destination');
  }
}

export function* clearFormErrors(action) {
  const location = action.payload.location.pathname;
  // when moving backwards and not is first rendering
  if (!(action.payload.action === 'POP' && !action.payload.isFirstRendering)) {
    return;
  }
  // And location is pickup or delivery
  if (location.indexOf(prefix + '/contact') > -1) {
    yield put(updateSyncErrors(GeneralFlowForms.contact, {}));
    return;
  }
}

export function* determinePriceCall(action) {
  const location = action.payload.location.pathname;
  const paths = [
    `${prefix}/items`,
    `${prefix}/pickup`,
    `${prefix}/pickup/help`,
    `${prefix}/pickup/help/equipment`,
    `${prefix}/pickup/home`,
    `${prefix}/pickup/home_and_floor`,
    `${prefix}/pickup/auctions/bva`,
    `${prefix}/pickup/home/floors`,
    `${prefix}/pickup/home/elevator`,
    `${prefix}/pickup/help/equipment`,
    `${prefix}/pickup/time`,
    `${prefix}/delivery/help`,
    `${prefix}/packages`,
    `${prefix}/delivery/help/equipment`,
    `${prefix}/delivery/home`,
    `${prefix}/delivery/home_and_floor`,
    `${prefix}/delivery/home/floors`,
    `${prefix}/delivery/home/elevator`,
    `${prefix}/delivery/help/equipment`,
    `${prefix}/delivery/time`,
    `${prefix}/contact`,
    `${prefix}/weight`,
  ];

  if (paths.includes(location)) {
    yield put(actions.getPrice());
  }
}

export function* resetFormValuesPerStep(action) {
  const location = action.payload.location.pathname;
  yield put(actions.toggleSubmitting(false));
  const allActions = [] as any[];
  switch (location) {
    case prefix + '/pickup':
      allActions.push(put(resetSection(GeneralFlowForms.pickup, 'pickup')));
      allActions.push(put(clearFields(GeneralFlowForms.date, false, true, SubStepFieldNamesPickup.date)));
      allActions.push(put(clearFields(GeneralFlowForms.date, false, true, SubStepFieldNamesDelivery.date)));
      break;
    case prefix + '/pickup/help':
      // TODO: wait for the clearFields bug to be fixed
      //  see also: https://github.com/erikras/redux-form/issues/4101
      allActions.push(put(clearFields(GeneralFlowForms.pickup, false, true, SubStepFieldNamesPickup.help_carrying)));
      break;
    case prefix + '/pickup/home':
      allActions.push(put(clearFields(GeneralFlowForms.pickup, false, true, SubStepFieldNamesPickup.situation_home)));
      break;
    case prefix + '/pickup/home_and_floor':
      allActions.push(
        put(clearFields(GeneralFlowForms.pickup, false, true, SubStepFieldNamesPickup.situation_home_floors_combined))
      );
      break;
    case prefix + '/pickup/home/floors':
      allActions.push(
        put(
          clearFields(
            GeneralFlowForms.pickup,
            false,
            true,
            SubStepFieldNamesPickup.floor_count,
            SubStepFieldNamesPickup.floor_custom,
            SubStepFieldNamesPickup.floor_elevator
          )
        )
      );
      break;
    case prefix + '/pickup/home/elevator':
      allActions.push(put(clearFields(GeneralFlowForms.pickup, false, true, SubStepFieldNamesPickup.floor_elevator)));
      break;
    case prefix + '/pickup/help/equipment':
      allActions.push(put(clearFields(GeneralFlowForms.pickup, false, true, SubStepFieldNamesPickup.help_equipment)));
      break;
    case prefix + '/packages':
      allActions.push(put(clearFields(GeneralFlowForms.packages, false, true, 'chosen_package')));
      break;
    case prefix + '/date':
    case prefix + '/date/bva_collection':
      /*
       * Was reseting per field, changed it to reset whole date form (since dates are isolated in one form)
       * Clear fields doesn't work when there initial values, because of a bug in reduc-form
       * Plus having one action to clear all is more efficient.
       */
      allActions.push(put(reset(GeneralFlowForms.date)));
      // Reset available pick up dates each time we land here to avoid rendering incorrect cached dates
      allActions.push(put(actions.setPickupAvailableDates([])));
      // grab fresh dates to render for user
      allActions.push(put(actions.getPrice()));
      if (action.payload.isFirstRendering) {
        allActions.push(put(actions.setTimeModalOpen(false)));
      }
      break;
    case prefix + '/date/delivery':
      allActions.push(put(clearFields(GeneralFlowForms.date, false, true, SubStepFieldNamesDelivery.date)));
      // Reset available pick up dates each time we land here to avoid rendering incorrect cached dates
      allActions.push(put(actions.setDeliveryAvailableDates([])));
      // we need fresh dates
      allActions.push(put(actions.getPrice()));
      if (action.payload.isFirstRendering) {
        allActions.push(put(actions.setTimeModalOpen(false)));
      }
      break;
    case prefix + '/pickup/time':
      allActions.push(put(clearFields(GeneralFlowForms.pickup, false, true, SubStepFieldNamesPickup.time)));
      allActions.push(put(resetSection(GeneralFlowForms.delivery, 'delivery')));
      break;
    case prefix + '/delivery/help':
      // TODO: wait for the clearFields bug to be fixed
      //  see also: https://github.com/erikras/redux-form/issues/4101
      allActions.push(
        put(clearFields(GeneralFlowForms.delivery, false, true, SubStepFieldNamesDelivery.help_carrying))
      );
      break;
    case prefix + '/delivery/home':
      allActions.push(
        put(clearFields(GeneralFlowForms.delivery, false, true, SubStepFieldNamesDelivery.situation_home))
      );
    case prefix + '/delivery/home_and_floor':
      allActions.push(
        put(
          clearFields(GeneralFlowForms.delivery, false, true, SubStepFieldNamesDelivery.situation_home_floors_combined)
        )
      );
      break;
    case prefix + '/delivery/home/floors':
      allActions.push(
        put(
          clearFields(
            GeneralFlowForms.delivery,
            false,
            true,
            SubStepFieldNamesDelivery.floor_count,
            SubStepFieldNamesDelivery.floor_custom,
            SubStepFieldNamesDelivery.floor_elevator
          )
        )
      );
    case prefix + '/delivery/home/elevator':
      allActions.push(
        put(clearFields(GeneralFlowForms.delivery, false, true, SubStepFieldNamesDelivery.floor_elevator))
      );
    case prefix + '/delivery/help/equipment':
      allActions.push(
        put(clearFields(GeneralFlowForms.delivery, false, true, SubStepFieldNamesDelivery.help_equipment))
      );
      break;
    case prefix + '/delivery/time':
      allActions.push(put(change(GeneralFlowForms.delivery, 'setPickupToDeliveryTime', false)));
      allActions.push(put(clearFields(GeneralFlowForms.delivery, false, true, SubStepFieldNamesDelivery.time)));
      break;
      break;
    case prefix + '/weight':
      // clear the field value
      allActions.push(put(clearFields(GeneralFlowForms.weight, false, true, WeightFields.WEIGHT)));
      // clear the item field value where it is also saved
      allActions.push(put(clearFields(GeneralFlowForms.items, false, true, `itemSets[0].items[0].weight`)));
      break;
  }
  yield all(allActions);
}

export function* setHelpTypeBasedOnLocation(action) {
  const location = action.payload.location.pathname;
  const helpMap: any = {
    [`${prefix}`]: 'destination',
    [`${prefix}/`]: 'destination',
    [`${prefix}/items`]: 'items',
    [`${prefix}/pickup`]: 'pickup',
    [`${prefix}/pickup/home`]: 'pickup_home',
    [`${prefix}/pickup/home_and_floor`]: 'pickup_home_and_floor',
    [`${prefix}/pickup/invoice`]: 'pickup_invoice',
    [`${prefix}/delivery/help`]: 'help',
    [`${prefix}/pickup/help`]: 'help',
    [`${prefix}/delivery/help/equipment`]: 'equipment',
    [`${prefix}/pickup/help/equipment`]: 'equipment',
    [`${prefix}/delivery/home/floors`]: 'floors',
    [`${prefix}/pickup/home/floors`]: 'floors',
    [`${prefix}/delivery/home/elevator`]: 'elevator',
    [`${prefix}/pickup/home/elevator`]: 'elevator',
    [`${prefix}/delivery/time`]: 'time',
    [`${prefix}/pickup/time`]: 'time',
    [`${prefix}/delivery`]: 'delivery',
    [`${prefix}/delivery/home`]: 'delivery_home',
    [`${prefix}/date`]: 'pickup_date',
    [`${prefix}/date/delivery`]: 'delivery_date',
    [`${prefix}/contact`]: 'contact',
    [`${prefix}/contact/pickup`]: 'contact_pickup',
    [`${prefix}/contact/delivery`]: 'contact_delivery',
    [`${prefix}/terms`]: 'terms',
    [`${prefix}/payment`]: 'payment',
    [`${prefix}/packages`]: 'packages',
    [`${prefix}/pickup/home_and_floor`]: 'pickup_home_and_floor',
    [`${prefix}/delivery/home_and_floor`]: 'delivery_home_and_floor',
  };

  yield put(actions.setHelpType(_get(helpMap, location, 'default')));
}

// If delivery < 13:00 and pickup selected time frame is flexible then set pickup to delivery timeframe
export function* handleCustomDeliveryTimeSelection(action) {
  if (action.meta.field === 'delivery.time' && action.meta.form === GeneralFlowForms.delivery) {
    try {
      const deliveryFormValues = yield select(getFormValues(GeneralFlowForms.delivery));
      const deliveryStartTime = parseInt(action.payload.start, 10);
      const deliveryEndTime = parseInt(action.payload.end, 10);
      const pickupStartTime = parseInt(_get(deliveryFormValues, 'delivery.pickupTime.start', '0'), 10);
      const pickupEndTime = parseInt(_get(deliveryFormValues, 'delivery.pickupTime.end', 0), 10);

      // Pickup selected time-frame should be flexible and delivery start time < 13
      if (deliveryStartTime < 13 && deliveryEndTime < 17 && pickupStartTime === 9 && pickupEndTime === 18) {
        yield all([
          put(change(GeneralFlowForms.pickup, 'pickup.time', action.payload)),
          put(change(GeneralFlowForms.delivery, 'setPickupToDeliveryTime', true)),
          put(actions.submitDelivery(deliveryFormValues)),
        ]);
      } else {
        yield all([
          put(change(GeneralFlowForms.delivery, 'setPickupToDeliveryTime', false)),
          put(actions.submitDelivery(deliveryFormValues)),
        ]);
      }
    } catch (err) {}
  }
}

export function* handleCustomFloorLevel(action) {
  if (
    (action.meta.field === SubStepFieldNamesPickup.floor_count ||
      action.meta.field === SubStepFieldNamesDelivery.floor_count) &&
    (action.meta.form === GeneralFlowForms.pickup || action.meta.form === GeneralFlowForms.delivery)
  ) {
    const isPickup = action.meta.form === GeneralFlowForms.pickup;
    const subStepKey = isPickup ? SubStepFieldNamesPickup : SubStepFieldNamesDelivery;
    const formKey = isPickup ? GeneralFlowForms.pickup : GeneralFlowForms.delivery;
    const hasCustom = yield select(hasCustomFloor, isPickup);
    if (hasCustom && parseInt(action.payload, 10) < 3) {
      yield all([put(change(formKey, subStepKey.floor_custom, false)), put(focus(formKey, subStepKey.floor_count))]);
    }
  }
}

export function* getEarlierDateOptions(action) {
  yield put(actions.getPrice(false, true));
}

export function* handlePackageSubmit(action) {
  const packageFormValues = yield select(getFormValues(GeneralFlowForms.packages));
  if (!packageFormValues) {
    yield delay(500);
    yield put(flowActions.pushNotification(translate('request_flow.errors.select_required')));
    return;
  }
  const international = yield select(isInternational);
  // In some cases, a job may not be guaranteed, but still have dates available.
  const areDatesAvailable = yield select(state => state.generalTransport.quotes?.guaranteed?.dates_available);
  const chosenPackage = yield select(getChosenPackage);

  if (!areDatesAvailable && international && chosenPackage === PackageFieldsValues.custom) {
    yield put(push(`${prefix}/pickup/help`));
    return;
  }
  if (!areDatesAvailable && international && chosenPackage === PackageFieldsValues.economic) {
    yield put(push(`${prefix}/contact`));
    return;
  }
  const auctionType = yield select(getAuctionType);
  const auctionDate = yield select(getAuctionPickupDate);
  if (auctionType === AuctionTypes.BVA && auctionDate.start !== '') {
    yield put(push(`${prefix}/date/bva_collection`));
  } else {
    yield put(push(`${prefix}/date`));
  }
}

export function* handleTermsSubmission(action) {
  yield call(createTransportRequest);
}

export function* submitItems() {
  // We get to ITEMS after selecting a situation
  // When auction is selected, we like to know the weight first
  // In all other situations we can proceed to packages
  const pickupSituation = yield select(getPickupSituation);
  if (pickupSituation === Situations.AUCTION) {
    yield put(push(`${prefix}/weight`));
  }
  const isPricingIndicatingHeavyWeightTransport = yield select(getIsPricingIndicatingHeavyWeightTransport);
  const path = isPricingIndicatingHeavyWeightTransport ? `${prefix}/date` : `${prefix}/packages`;
  yield put(push(path));
}

export function* createTransportRequest() {
  /*
   * First we need to know if we are dealing with an instant booking.
   * If so we got take care of a few things
   * - Fetch price for most up to date info about: availability & timeslots
   * - Check if instant booking is still available
   * - if not, then redirect the user to the date step
   * - if all passes, continue creating transportrequest
   */
  const isInstantBookingSelected = yield select(getIsInstantBookingSelected);
  if (isInstantBookingSelected) {
    yield put(actions.getPrice());
    // wait till fresh data has arrived
    yield take(generalTypes.SET_PICKUP_AVAILABLE_DATES);
    // check availability
    const isInstantBookingAvailable = yield select(getIsInstantBookingAvailable);
    if (!isInstantBookingAvailable) {
      // redirect user, he chose for instant but it is not available anymore
      yield put(push(`${prefix}/date`));
      return;
    }
    // All good, keep rollin` like a normal TR creation
  }

  const transportRequestCreationParams: TransportRequestCreationParams = yield select(
    getTransportRequestCreationParams
  );

  // First post addresses
  yield put(actions.toggleSubmitting(true));
  try {
    // remove the temp request flag for non-prepaid job payments before make a new payment request
    localStorage.removeItem('temp_request');
    hotjar.addTag(['transport_request_call', transportRequestCreationParams]);
    const transportRequest: TransportRequest = yield call(
      coreClient.transportRequests.create,
      transportRequestCreationParams
    );
    // save uuid and navigate user to payment
    if (transportRequest) {
      hotjar.addTag(['transport_request_success']);
      const uuid = transportRequest['@id'].split('transport_requests/')[1];
      const effects: any[] = [];
      effects.push(put(flowActions.setTransportUUID(uuid)));
      effects.push(put(flowActions.toggleSheet()));
      effects.push(call(subscribeNewsletter));

      const isDirectlyPayable = yield select(getIsDirectlyPayable);
      // To go to the payment page, the quote must either be guaranteed or directly payable
      if (isDirectlyPayable) {
        effects.push(put(push(`${prefix}/payment`)));
      } else {
        effects.push(put(push(`${prefix}/thank_you_not_guaranteed`)));
      }
      yield all(effects);
    } else {
      yield put(actions.toggleSubmitting(false));
    }
  } catch (err) {
    appUtils.logger(err);
    hotjar.addTag(['transport_request_failure']);
    yield put(flowActions.toggleSheet());
    if (_get(err, 'response', false)) {
      if (_get(err.response, 'data["hydra:description"]', false)) {
        const msg = err.response.data['hydra:description'].replace(/[\r\n]/g, '<br />');
        yield all([put(actions.toggleSubmitting(false)), put(actions.failedTransportRequest(msg))]);
        appUtils.logException(`CREATE TR FAILED`, {
          error: err.response.data['hydra:description'],
          payload: transportRequestCreationParams,
        });
      }
    } else {
      appUtils.logException(`CREATE TR FAILED`, { error: err, payload: transportRequestCreationParams });
      const msg = translate('request_flow.errors.handle_request');
      yield all([put(actions.toggleSubmitting(false)), put(actions.failedTransportRequest(msg))]);
    }

    openChatCustomerCare();
  }
}

function* subscribeNewsletter() {
  const termsForm = yield select(getFormValues(GeneralFlowForms.terms));
  if (!termsForm?.conditions.newsletter_conditions) {
    return;
  }
  const customerDetails = yield select(getCustomerDetails);
  const subscribe = () =>
    http().post('/subscribe_newsletter', {
      first_name: customerDetails.first_name,
      last_name: customerDetails.last_name,
      email: customerDetails.email,
    });
  try {
    yield call(subscribe);
  } catch (e) {
    /* Let this fail silent, it is bad luck, but not a show stopper  */
    appUtils.logger(e);
  }
}
export function openChatCustomerCare() {
  const body = document.querySelector('body');
  if (body) {
    const timing = body.classList.contains('chat--is-visible') ? 0 : 350;
    if (!body.classList.contains('chat--is-visible')) {
      body.classList.add('chat--is-visible');
      if (window.fcWidget && typeof window.fcWidget.open !== 'undefined') {
        setTimeout(() => {
          window.fcWidget.open();
        }, timing);
      }
    } else {
      body.classList.remove('chat--is-visible');
    }
  }
}

export function* addTransportRequestFlowClass(action) {
  // add class when we are in the new flow
  const body = document.querySelector('body');
  if (!body) {
    return;
  }
  if (window.location.pathname.indexOf(`/customer${prefix}`) > -1) {
    body.classList.add('transport-request-flow');
  } else {
    body.classList.remove('transport-request-flow');
  }
}

export function* resetGeneralErrors(action) {
  if (action.payload.location.pathname.indexOf(prefix) > -1) {
    yield put(actions.resetRetryAttempts());
  }
}

export function* resetModalTimePicker(action) {
  if (action.payload.location.pathname.indexOf('/date') < 0) {
    yield put(actions.setTimeModalOpen(false));
  }
}

export function* resetFormsFromWidget(action) {
  if (!action.payload.isFirstRendering) {
    return;
  }
  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.get('reset_flow') === 'dates_only') {
    yield put(reset(GeneralFlowForms.date));
    return;
  }
  if (searchParams.get('reset_flow') === 'full') {
    yield call(resetForms, false);
  }
}

export function* addFeedbackForm(action) {
  if (action.payload.isFirstRendering) {
    if (window.location.pathname.indexOf(`/customer${prefix}`) === -1) {
      return;
    }
  }
  const utmSource = _get(appUtils.parseCookies(), 'utm_source', '');
  if (utmSource) {
    hotjar.addForm(utmSource);
  }
}

function* getPrice(action) {
  yield call(priceHandler, action.skipAvailableDatesUpdate, action.isEarlierDatesCall);
}

function* readWidgetParameters(action) {
  if (!action.payload.isFirstRendering || window.location.search.length === 0) {
    return;
  }
  const searchParams = new URLSearchParams(window.location.search);
  const whitelistedValues = [
    'administrative_area',
    'locality',
    'country_code',
    'country_name',
    'latitude',
    'longitude',
  ];
  const pickup: object = {};
  whitelistedValues.map(key => {
    const keyName = `pickup[${key}]`;
    if (!searchParams.has(keyName)) {
      return;
    }
    pickup[key] = searchParams.get(keyName);
  });
  const delivery = {};
  whitelistedValues.map(key => {
    const keyName = `delivery[${key}]`;
    if (!searchParams.has(keyName)) {
      return;
    }
    delivery[key] = searchParams.get(keyName);
  });
  const readActions: any[] = [];
  if (Object.keys(pickup).length === whitelistedValues.length) {
    readActions.push(put(actions.setDestinationDetails(pickup, StopType.pickup)));
  }
  if (Object.keys(delivery).length === whitelistedValues.length) {
    readActions.push(put(actions.setDestinationDetails(delivery, StopType.delivery)));
  }
  const referrer = searchParams.get(`meta[referrer]`);
  if (referrer !== null) {
    const decodedReferrer = decodeURI(referrer);
    const searchParamsReferrer = new URL(decodedReferrer).searchParams;
    if (searchParamsReferrer.get(`utm_source`)) {
      document.cookie = 'utm_source=' + searchParamsReferrer.get(`utm_source`);
    }
    readActions.push(put(actions.setReferrer(decodedReferrer)));
  }
  yield all(readActions);
}

function* progressTracking(action) {
  const event = {
    event: 'Flow progress',
    flow_type: _FLOW_TYPE,
  };
  let progressLabel = 'undefined';
  let progressValue = action.payload;
  switch (action.payload) {
    case 1:
      progressLabel = 'Step 1 - Destination';
      break;
    case 2:
      progressLabel = 'Step 2 - Type';
      break;
    case 3:
      progressLabel = 'Step 3 - Items';
      break;
    case 4:
      progressLabel = 'Step 4 - Packages';
      break;
    case 5:
      progressLabel = 'Step 5 - Date & Time';
      break;
    case 6:
      progressLabel = 'Step 6 - Services';
      break;
    case 7:
      progressLabel = 'Step 7 - Contact';
      break;
    case 8:
      progressLabel = 'Step 8 - Terms & Payment';
      break;
    case _NOT_GUARANTEED_PAYMENT:
      progressLabel = 'Step 8 - Payment non guaranteed';
      progressValue = 8;
      break;
    case _NOT_GUARANTEED_THANK_YOU:
      progressLabel = 'Step 8 - Thank you non guaranteed';
      progressValue = 8;
      break;
  }

  event['progress_label'] = progressLabel;
  event['progress_value'] = progressValue;
  appUtils.pushToDataLayer(event);
}

function* helpTracking(action) {
  if (!action.payload) {
    return;
  }
  appUtils.pushToDataLayer({
    event: 'Help popup opened',
    flow_type: _FLOW_TYPE,
    help_popup_location: window.location.href,
  });
}

function* distanceTracking() {
  const distance = yield select(getDistance);
  const ranges = [10, 30, 50, 75, 100, 150, 200, 250, 300, 400];
  let action = '> 400 km';
  for (let i = 0; i < ranges.length; i++) {
    if (distance < ranges[i]) {
      action = ranges[i] === 10 ? '< ' : '';
      action += `${ranges[i]} km`;
      break;
    }
  }
  trackEvent({
    eventCategory: 'General TR - Distance',
    eventAction: action,
    eventLabel: String(distance),
    eventValue: distance,
  });
}

function* metaDataTracking() {
  const [
    guaranteed,
    notGuaranteedReason,
    items,
    pickupDetails,
    deliveryDetails,
    chosenPackage,
    pickupSituation,
    pickupHelp,
    deliveryHelp,
    pickupFloorData,
    deliveryFloorData,
    transportPrice,
    auctionType,
  ] = yield all([
    select(isGuaranteed),
    select(getNotGuaranteedReason),
    select(getValidItemSets),
    select(getStopDestinationDetails, StopType.pickup),
    select(getStopDestinationDetails, StopType.delivery),
    select(getChosenPackage),
    select(getStopSituation, StopType.pickup, false),
    select(getPickupHelp),
    select(getDeliveryHelp),
    select(getPickupFloorData),
    select(getDeliveryFloorData),
    select(getTransportPrice),
    select(getAuctionType),
  ]);

  // collect events
  const events: IEventData[] = [];

  // Guaranteed - Check if the price === 0, if not we have a valid guaranteed/not guaranteed answer
  if (transportPrice !== 0) {
    events.push({
      eventCategory: 'General TR - Guaranteed',
      eventAction: String(guaranteed),
      eventLabel: notGuaranteedReason,
    });
  }

  // Auction type
  if (auctionType) {
    events.push({
      eventCategory: 'General TR - Auction type',
      eventAction: auctionType,
    });
  }

  // Items
  if (items.length > 0) {
    events.push({
      eventCategory: 'General TR - volume m3',
      eventAction: Number(getItemSetsVolume(items) / 1000000).toFixed(2),
    });
    events.push({
      eventCategory: 'General TR - number of items',
      eventAction: String(items.length),
    });
  }

  // Region & country
  const pickupCountry = _get(pickupDetails, 'country', 'none');
  const deliveryCountry = _get(deliveryDetails, 'country', 'none');
  const pickupAdministrative = _get(pickupDetails, 'administrative_area', 'none');
  const deliveryAdministrative = _get(deliveryDetails, 'administrative_area', 'none');
  if (pickupDetails !== null) {
    events.push({
      eventCategory: 'General TR - Pickup region',
      eventAction: pickupAdministrative,
    });
    events.push({
      eventCategory: 'General TR - Pickup country',
      eventAction: pickupCountry,
    });
  }

  if (deliveryDetails !== null) {
    events.push({
      eventCategory: 'General TR - Delivery region',
      eventAction: deliveryAdministrative,
    });
    events.push({
      eventCategory: 'General TR - Delivery country',
      eventAction: deliveryCountry,
    });
  }

  if (pickupDetails !== null && deliveryDetails !== null) {
    events.push({
      eventCategory: 'General TR - Pickup => Delivery | region',
      eventAction: `From ${pickupAdministrative} to ${deliveryAdministrative}`,
      eventLabel: pickupAdministrative === deliveryAdministrative ? 'same' : 'different',
    });
    events.push({
      eventCategory: 'General TR - Pickup => Delivery | country',
      eventAction: `From ${pickupCountry} to ${deliveryCountry}`,
      eventLabel: pickupCountry === deliveryCountry ? 'same' : 'different',
    });
  }

  // Package
  if (chosenPackage) {
    events.push({
      eventCategory: 'General TR - Chosen package',
      eventAction: chosenPackage,
    });
  }

  // Situation / type
  if (pickupSituation) {
    events.push({
      eventCategory: 'General TR - Type of transport (pickup situation)',
      eventAction: pickupSituation,
    });
  }

  // Help
  if (pickupHelp !== CarryingHelpChoices.NOT_NEEDED) {
    events.push({
      eventCategory: 'General TR - Pickup help',
      eventAction: pickupHelp,
    });
  }

  if (deliveryHelp !== CarryingHelpChoices.NOT_NEEDED) {
    events.push({
      eventCategory: 'General TR - Delivery help',
      eventAction: deliveryHelp,
    });
  }

  // Floor / Elevator
  if (pickupFloorData.floor !== null) {
    events.push({
      eventCategory: 'General TR - Pickup floor',
      eventAction: pickupFloorData.hasElevator ? 'Elevator' : pickupFloorData.floor,
    });
  }

  if (deliveryFloorData.floor !== null) {
    events.push({
      eventCategory: 'General TR - Delivery floor',
      eventAction: deliveryFloorData.hasElevator ? 'Elevator' : deliveryFloorData.floor,
    });
  }

  // Fire events
  events.map(event => trackEvent(event));
}

export function* destinationSaga() {
  yield takeLatest(LOCATION_CHANGE, readWidgetParameters);
}

export function* escapeBvaFlow() {
  yield put(push(`${prefix}/items`));
}

// BVA auction date selection step initialization
export function* bvaAuctionDateStepInit(action) {
  const path = action.payload.location.pathname;
  if (
    matchPath(action.payload.location.pathname, {
      path: `${prefix}${_DATE_BVA_COLLECTION_PATH}`,
      exact: true,
      strict: false,
    })
  ) {
    if (action.payload.action === 'POP' && path.includes('bva_collection')) {
      yield put(goBack());
    }
    // Always switch off the different delivery checkbox
    yield put(change(GeneralFlowForms.date, SubStepFieldNamesPickup.different_delivery_date, false));

    const lots = yield select(getPickupLots);
    const collectionDate = yield select(getAuctionPickupDate);

    // if auction pickup available date is not available then just go to normal pickup date flow otherwise prefill
    if (lots.length > 0 && !moment(collectionDate.start).isValid()) {
      yield put(change(GeneralFlowForms.pickup, 'pickup.errorCode', 'no_available_auction_pickup_date'));
      yield put(push(prefix + _DATE_PICKUP_PATH));
    } else {
      yield all([
        put(change(GeneralFlowForms.date, SubStepFieldNamesPickup.date, collectionDate.start)),
        put(actions.getPrice(false)),
      ]);
    }
  }
}

// Bva auction initialization in general flow
export function* bvaAuctionLotStepInit(action) {
  if (
    matchPath(action.payload.location.pathname, { path: `${prefix}/pickup/auctions/bva`, exact: true, strict: false })
  ) {
    const lots = yield select(getPickupLots);
    // when people switch to bva flow and already have items in the state then reset the item sets
    const items = yield select(getValidItemSets);

    // when people switch stop type and already have items in state then reset the item sets
    if (items.length > 0 && lots.length === 0) {
      yield put(reset(GeneralFlowForms.items));
    }
  }
}

// BVA-INTEGRATION: fetch auction details
export function fetchAuctionDetails(auctionId) {
  try {
    return fetch(`${_BVA_BASE_URL}/auction/${auctionId}/summary`);
  } catch (e) {
    put(actions.pushNotification(translate('request_flow.auction.errors.fetch_error')));
  }
}

// watching lot input changes
export function* watchLotInput(action) {
  if (!(action.meta.form === GeneralFlowForms.pickup && action.meta.field.match(/lots\[[0-9]\].number/g))) {
    return;
  }
  const lotDetails = yield call(fetchLotDetails, action);
  if (!lotDetails) {
    // bail here, error message handling is in fetchLotDetails details of BVA
    return;
  }
  const currentLots = yield select(getPickupLots);

  // different auction is not allowed
  if (currentLots.length > 0 && currentLots[0].auctionId && currentLots[0].auctionId !== lotDetails.auctionId) {
    yield all([
      put(arrayRemove(GeneralFlowForms.pickup, `lots`, currentLots.length - 1)),
      put(actions.pushNotification(translate('request_flow.auction.errors.different_auction'))),
    ]);
    return;
  }
  // fetch auction details and collection dates
  const resp = yield call(fetchAuctionDetails, lotDetails.auctionId);
  if (!resp) {
    // we need the auction to be able to continue, error message is handled in fetchAuctionDetails
    return;
  }

  const auctionDetails = yield resp.json();
  const collectionDates = auctionDetails.collectionDaySummaries;

  // don't allow customers to add the auction with expired collection dates
  if (_get(collectionDates[0], 'endDate', false) && moment(collectionDates[0].endDate).isBefore(new Date())) {
    yield all([
      put(arrayRemove(GeneralFlowForms.pickup, `lots`, currentLots.length - 1)),
      put(actions.pushNotification(translate('request_flow.auction.errors.expired_auction'))),
    ]);
    return;
  }
  const lotIndex = action.meta.field.match(/\d/g)[0];
  const externalPartyId = yield select(getExternalPartyIri, 'BVA');

  // remove existing errors
  yield put(actions.pushNotification(null));

  // set the auction collection dates to related lot in de state
  lotDetails['collectionDaySummaries'] = collectionDates;

  // store the lot details in state form.pickup.lots
  yield put(change(GeneralFlowForms.pickup, action.meta.field.split('.')[0], lotDetails));

  const BVAitem = {
    count: 1,
  };
  try {
    // upload the image
    const lotImage = yield call(uploadFile, lotDetails.image, '/job_images');
    if (lotImage.status === 201) {
      // We follow same structure as the generic upload component
      BVAitem[`image_${GenericExtraInputFileFields.COLLECTION}`] = [{ '@id': lotImage.data['@id'] }];
    }
  } catch (e) {
    appUtils.logger(e);
  }

  // add an itemSet with the lot details
  yield all([
    put(
      change(GeneralFlowForms.items, `itemSets[${lotIndex}]`, {
        title: lotDetails.title,
        description: lotDetails.description,
        items: [BVAitem],
        external_party: externalPartyId,
        attributes: {
          external_lot_id: lotDetails.id,
          external_lot_no: lotDetails.number,
        },
      })
    ),
    put(arrayPush(GeneralFlowForms.pickup, `lots[${lotIndex}].items`, { count: 1 })),
  ]);
}

// BVA-INTEGRATION: remove lot from general pickup form
export function* removeLot(action) {
  if (action.meta.field === 'lots' && action.meta.form === GeneralFlowForms.pickup) {
    try {
      yield all([
        put(arrayRemove(GeneralFlowForms.items, `itemSets[${action.meta.index}].items`, 0)),
        put(arrayRemove(GeneralFlowForms.items, `itemSets[${action.meta.index}].external_invoices`, 0)),
        put(actions.getPrice()),
      ]);
    } catch (err) {
      appUtils.logException('remove lot failed!', err);
    }
  }
}

// BVA-INTEGRATION: remove item from lot
export function* removeLotItem(action) {
  if (action.meta.field.match(/lots\[[0-9]\].items/g) !== null && action.meta.form === GeneralFlowForms.pickup) {
    try {
      yield all([
        put(
          arrayRemove(GeneralFlowForms.items, `itemSets[${action.meta.field.match(/\d/g)[0]}].items`, action.meta.index)
        ),
        put(actions.getPrice()),
      ]);
    } catch (err) {
      appUtils.logException('remove lot failed!', err);
    }
  }
}

// BVA-INTEGRATION:  set lots dimensions to itemSet
export function* addBvaDimensionsToItemSet(action) {
  if (
    action.meta.form === GeneralFlowForms.pickup &&
    action.meta.field.match(/lots\[[0-9]\].[width|height|length|count]/g) !== null
  ) {
    try {
      // if the dimensions are all set then add them to itemsetForm
      const lotIndex = action.meta.field.match(/\d/g)[0];
      const itemIndex = action.meta.field.match(/\d/g)[1];
      const lot = yield select(getPickupLot, lotIndex);
      const item = { ...lot.items[itemIndex], title: lot.title };
      if (isItemValid(item)) {
        yield all([
          put(change(GeneralFlowForms.items, `itemSets[${lotIndex}].items[${itemIndex}].title`, item.title)),
          put(change(GeneralFlowForms.items, `itemSets[${lotIndex}].items[${itemIndex}].width`, item.width)),
          put(change(GeneralFlowForms.items, `itemSets[${lotIndex}].items[${itemIndex}].length`, item.length)),
          put(change(GeneralFlowForms.items, `itemSets[${lotIndex}].items[${itemIndex}].height`, item.height)),
          put(change(GeneralFlowForms.items, `itemSets[${lotIndex}].items[${itemIndex}].count`, item.count)),
          put(actions.getPrice()),
        ]);
      } else {
        yield put(actions.getPrice());
      }
    } catch (err) {}
  }
}

function* handleWeightChange(action) {
  // Reset when situation changes
  if ([SubStepFieldNamesPickup.situation].includes(action.meta.field)) {
    yield all([
      clearFields(GeneralFlowForms.items, false, true, `itemSets[0].items[0].weight`),
      clearFields(GeneralFlowForms.weight, false, true, WeightFields.WEIGHT),
    ]);
    return;
  }
  if (action.meta.form === GeneralFlowForms.weight && action.meta.field === WeightFields.WEIGHT) {
    /* Because we don't like to bother all customers with weight questions, we ask it separately.
     * We decided to ask it for the whole transport, and save it on the first item.
     */
    try {
      yield put(change(GeneralFlowForms.items, `itemSets[0].items[0].weight`, action.payload));
      yield put(actions.getPrice());
    } catch (err) {
      yield put(push(`${prefix}/items`));
    }
  }
}

function* handleWeightSubmit(action) {
  // We either get to the WEIGHT page after selecting help [extra_driver|equipment]
  // Or when auction is selected, we get here after items.
  // So depending on the situation we need to redirect the user to the next step.
  const pickupSituation = yield select(getPickupSituation);
  if (pickupSituation !== Situations.AUCTION) {
    yield put(push(`${prefix}/contact`));
    return;
  }
  // so we are auction, then we need to know if the user his entered an high value weight.
  const isHeavyWeight = yield select(getIsTransportHeavyWeight);
  const path = isHeavyWeight ? `${prefix}/date` : `${prefix}/packages`;
  yield put(push(path));
}

export function* itemSetsSaga() {
  // prefill the example product dimensions when customers coming from ads or marktplaats with UTM
  yield takeLatest(LOCATION_CHANGE, prefillExampleDimensions);

  // reset the item set when people change their flow from bva flow with filled-in lots (items)
  yield takeLatest(LOCATION_CHANGE, resetItemSets);

  // call the price api per each count input value change

  // call the price api per each item remove
  yield takeLatest(actionTypes.ARRAY_REMOVE, itemOnRemove);

  // call the price api per each dimension input value change
  yield takeLatest(actionTypes.CHANGE, itemTitleOnChange);
  yield takeLatest(actionTypes.CHANGE, itemOnChange);
  yield debounce(1000, actionTypes.CHANGE, itemOnDebounce);

  yield takeLatest(generalTypes.SUBMIT_ITEMS, submitItems);
}

export function* pickupSaga() {
  yield takeLatest(actionTypes.CHANGE, handleCustomFloorLevel);
  yield takeLatest(actionTypes.CHANGE, handleCustomDeliveryTimeSelection);
}

export function* dateSaga() {
  yield takeLatest(generalTypes.SET_CUSTOM_TIME_PREFERENCE, handleTimePreference);
  yield takeLatest(generalTypes.SUBMIT_DATE, determineDateNavOrModal);
  yield takeLatest(generalTypes.GET_MORE_DATES, handleGetMoreDates);
  yield takeEvery(actionTypes.CHANGE, handleDateChange);
}

export function* packageSaga() {
  yield takeEvery(generalTypes.GET_EARLIER_DATE_OPTIONS, getEarlierDateOptions);
  yield takeLatest(generalTypes.SUBMIT_PACKAGES, handlePackageSubmit);
}

export function* contactSaga() {
  yield takeLatest(actionTypes.CHANGE, changeCustomerType);
  yield takeLatest(actionTypes.CHANGE, addMyDetails);
  yield takeLatest(actionTypes.CHANGE, removeMyDetails);
  yield takeLatest(actionTypes.CHANGE, replaceCountryCode);
  yield takeLatest(generalTypes.SUBMIT_CONTACT, determineNextSubmitStep);
  yield debounce(500, actionTypes.CHANGE, prefillPostalCode);
  yield debounce(250, actionTypes.REGISTER_FIELD, prefillLocalityInput);
  yield debounce(100, actionTypes.CHANGE, triggerSyncValidation);
  yield debounce(250, actionTypes.REGISTER_FIELD, addLoggedInDetails);
  yield debounce(500, actionTypes.SET_SUBMIT_FAILED, scrollAfterSyncError);
  yield debounce(1000, LOCATION_CHANGE, prefillDefaultPickupContactDetails);
  yield takeLatest(actionTypes.BLUR, validatePostalCode);
  yield takeLatest(LOCATION_CHANGE, resetAddressForm);
  yield takeLatest(LOCATION_CHANGE, prefillBusinessAddress);
  yield takeLatest(LOCATION_CHANGE, fetchHistoricalAddresses);
  yield takeLatest(actionTypes.CHANGE, handleHistoricalAddressChange);
}

// connection between bva order flow and general auction flow
export function* bvaAuctionSaga() {
  yield takeLatest(LOCATION_CHANGE, bvaAuctionLotStepInit);
  yield takeLatest(LOCATION_CHANGE, bvaAuctionDateStepInit);
  yield takeLatest(generalTypes.ESCAPE_BVA_FLOW, escapeBvaFlow);
  yield takeEvery(actionTypes.BLUR, watchLotInput); // add lot watcher
  yield takeLatest(actionTypes.ARRAY_REMOVE, removeLot); // remove lot
  yield takeEvery(actionTypes.ARRAY_REMOVE, removeLotItem); // remote item from lot
  yield debounce(1000, actionTypes.CHANGE, addBvaDimensionsToItemSet); // replace lot details in pickup form
}

export function* subStepsSaga() {
  yield takeLatest(actionTypes.CHANGE, determineNextSubStepOnChange);
  yield takeLatest(generalTypes.SUBMIT_PICKUP, determineNextSubmitStep);
  yield takeLatest(generalTypes.SUBMIT_DELIVERY, determineNextSubmitStep);
  yield takeLatest(generalTypes.SET_DELIVERY_AVAILABLE_DATES, setPickupDateTime);
}

export function* generalSaga() {
  yield takeLatest(LOCATION_CHANGE, resetFormsFromWidget);
  yield takeLatest(LOCATION_CHANGE, addFeedbackForm);
  yield takeLatest(LOCATION_CHANGE, addTransportRequestFlowClass);
  yield takeLatest(LOCATION_CHANGE, checkPersistExpiration);
  yield takeLatest(LOCATION_CHANGE, handleLoadingStatus);
  yield takeLatest(LOCATION_CHANGE, clearFormErrors);
  yield takeLatest(LOCATION_CHANGE, setHelpTypeBasedOnLocation);
  yield takeLatest(LOCATION_CHANGE, resetFormValuesPerStep);
  yield takeLatest(LOCATION_CHANGE, resetGeneralErrors);
  yield takeLatest(LOCATION_CHANGE, resetModalTimePicker);
  yield takeLatest(LOCATION_CHANGE, determinePriceCall);
  yield takeLatest(LOCATION_CHANGE, determineSkipDateTimeStep);
  yield takeLatest(LOCATION_CHANGE, termsStepChecks);
  yield takeLatest(LOCATION_CHANGE, clearMarktplaatsUsersCache);
  yield takeLatest(LOCATION_CHANGE, closeSummaryAfterNavigation);

  yield takeLatest(actionTypes.SET_SUBMIT_FAILED, handleSyncErrors);
  yield takeLatest(actionTypes.UPDATE_SYNC_ERRORS, updateFormSyncErrors);
  yield takeLatest(generalTypes.SUBMIT_TERMS, handleTermsSubmission);
  yield takeLatest(generalTypes.RESET_FORMS, resetForms);
  yield takeLatest(actionTypes.SET_SUBMIT_SUCCEEDED, resetSyncErrors);

  yield takeLatest(generalTypes.PRICE_API_RETRY_ATTEMPT_INCREMENT, retryPriceHandler);
  yield debounce(500, generalTypes.PUSH_NOTIFICATION, afterPushNotification);

  yield takeLatest(generalTypes.GET_PRICE, getPrice);
  yield takeLatest(generalTypes.SET_DESTINATION_DETAILS, getPrice);
  yield takeLatest(generalTypes.SET_DESTINATION_DETAILS, checkInternational);
  yield takeLatest(generalTypes.RESET_DESTINATION_DETAILS, resetDestinationWidget);

  // marktplaats seller request
  yield takeLatest(LOCATION_CHANGE, fetchMarktplaatsRequest);
  yield takeLatest(generalTypes.SUBMIT_MP_SELLER_FORM, submitMartkplaatsSellerForm);
}

function* trackingSaga() {
  yield takeLatest(generalTypes.SET_PROGRESS_STEP, progressTracking);
  yield takeLatest(generalTypes.SET_PROGRESS_STEP, metaDataTracking);
  yield takeLatest(generalTypes.SET_PROGRESS_STEP, distanceTracking);
  yield takeLatest(generalTypes.SET_HELP_OPEN, helpTracking);
}

function* weightSaga() {
  yield takeLatest(actionTypes.CHANGE, handleWeightChange);
  yield takeLatest(generalTypes.SUBMIT_WEIGHT, handleWeightSubmit);
}

export function* GeneralTransportSagas() {
  yield fork(contactSaga);
  yield fork(generalSaga);
  yield fork(itemSetsSaga);
  yield fork(bvaAuctionSaga);
  yield fork(pickupSaga);
  yield fork(dateSaga);
  yield fork(packageSaga);
  yield fork(subStepsSaga);
  yield fork(destinationSaga);
  yield fork(trackingSaga);
  yield fork(weightSaga);
}

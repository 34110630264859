import React from 'react';
import moment from 'moment-timezone';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Heading, SheetLayoutContent, SheetLayoutContentWrapper, SheetLayoutHeader } from '../../../brenger-shared-ui';
import { CarryingHelpChoices, RootState, StopType } from '../../../typings';
import '../../../assets/user/user.css';
import {
  getAddressDetails,
  getClientReference,
  getContactDelivery,
  getInstructions,
  getIsReturnTransport,
  getSelectedDatetimePeriod,
  getValidItemSets,
} from '../ducks';
import FooterLayout from './FooterLayout';
import _get from 'lodash/get';
import { BusinessFields, BusinessForms } from '../interface';
import { getUploadedFileDataByFormAndFieldName } from '../../../state/ducks/baseReducer';
import { getDateFormatLocale } from '../../../utils/datetime';

type ReduxProps = ReturnType<typeof mapStateToProps>;

type Props = ReduxProps;

const TransportPreview: React.FC<Props> = ({
  businessAddress,
  customerAddress,
  customerContact,
  date,
  itemSets,
  clientReference,
  invoices,
  isReturn,
  instructions,
}) => {
  return (
    <SheetLayoutContentWrapper>
      <SheetLayoutHeader>
        <Heading size={2}>
          <Translate id={'request_flow.business.' + (isReturn ? 'return_label' : 'order_header_normal')} />
        </Heading>
      </SheetLayoutHeader>
      <SheetLayoutContent className={'business-preview'}>
        <div>
          <Heading size={5}>
            <Translate id="request_flow.business.headings.preview_customer" />
          </Heading>
        </div>
        <div>
          <b>
            <Translate id={'form.fields.user.name.label'} />:{' '}
          </b>
          {customerContact.first_name} {customerContact.last_name}
        </div>
        <div>
          <b>
            <Translate id={`request_flow.contact.address`} />:{' '}
          </b>
          {customerAddress.address.line1}, {customerAddress.address.postal_code}, {customerAddress.address.locality}
        </div>
        <div>
          <b>
            <Translate id={'communication.phone_short'} />:{' '}
          </b>
          {customerContact.phone}
        </div>
        <div>
          <b>
            <Translate id={'communication.email'} />:{' '}
          </b>
          {customerContact.email}
        </div>
        {instructions && (
          <div>
            <b>
              <Translate id={'request_flow.summary.instructions'} />:{' '}
            </b>
            {instructions}
          </div>
        )}
        <div className={'pt-1'}>
          {date?.length && (
            <div>
              <b>
                <Translate id={'datetime.date'} />:{' '}
              </b>
              {moment(date[0].start).format(getDateFormatLocale())} ({moment(date[0].start).format('dddd')})
            </div>
          )}
          <div className={'flex'}>
            <b>
              <Translate id={'request_flow.business.step_2.title_small'} />:{' '}
            </b>
            <div>
              {itemSets.map((itemSet, itemSetIndex) => {
                return itemSet.items.map((item, index) => {
                  return (
                    <div key={itemSetIndex + index}>
                      &nbsp;
                      {item.title} - {item.width}x{item.height}x{item.length} ({item.count}x)
                    </div>
                  );
                });
              })}
            </div>
          </div>
          {clientReference && (
            <div>
              <b>
                <Translate id={'request_flow.business.client_reference'} />:{' '}
              </b>
              {clientReference}
            </div>
          )}
          {invoices.length > 0 && (
            <div>
              <b>
                <Translate id={'request_flow.business.invoice_header'} />{' '}
              </b>
              {invoices.join(',')}
            </div>
          )}
          {customerAddress.details.carrying_help === CarryingHelpChoices.EXTRA_DRIVER && (
            <div>
              <b>
                <Translate id={'request_flow.business.headings.extra_services'} />:{' '}
              </b>
              <Translate id={'request_flow.business.fields.extra_driver.label'} />
            </div>
          )}
        </div>
        <div className={'pt-1'}>
          <Heading size={5}>
            <Translate id="request_flow.business.headings.preview_company" />
          </Heading>
        </div>
        <div className={'pb-1'}>
          {businessAddress.address.line1}, {businessAddress.address.postal_code}, {businessAddress.address.locality}
        </div>
      </SheetLayoutContent>
      <FooterLayout />
    </SheetLayoutContentWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  businessAddress: getAddressDetails(state, StopType.pickup),
  customerAddress: getAddressDetails(state, StopType.delivery),
  customerContact: getContactDelivery(state),
  isReturn: getIsReturnTransport(state),
  itemSets: getValidItemSets(state),
  date: getSelectedDatetimePeriod(state, StopType.pickup),
  clientReference: getClientReference(state),
  invoices: getUploadedFileDataByFormAndFieldName(
    state,
    BusinessForms.DESTINATION,
    BusinessFields.INVOICE,
    'original_name'
  ),
  instructions: getInstructions(state),
});

export default connect(mapStateToProps)(TransportPreview);

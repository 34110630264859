import React from 'react';
import { Translate } from 'react-localize-redux';
import { Addition } from '../interface';
import IconCheckmark from '../../../brenger-shared-ui/components/basics/icons/IconCheckmark';
import { priceAsString } from '../../../utils/price';

interface Props {
  additions: Addition[];
  renderCheckMark: boolean;
}

const AdditionList = ({ additions, renderCheckMark }: Props) => {
  return (
    <>
      {additions.map((item, index) => {
        return (
          <div className={'summary-line'} key={index}>
            <div>
              {renderCheckMark && <IconCheckmark />} {item.beforeText && item.beforeText}{' '}
              {item.transKey && <Translate id={item.transKey} />}
              {item.text && item.text} {item.afterText && item.afterText}
            </div>
            {item.price !== null && (
              <div className={'adds-price'}>
                {item.price >= 0 ? '+' : '-'} {priceAsString(item.price)}
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default AdditionList;

import * as React from 'react';
import './checkmarklist.css';
import { IconCheckmark } from '../../basics/icons';

interface Props {
  list: any[];
  checkMarkStyle?: string;
  header?: string;
}

export const CheckMarkList = ({ header, list, checkMarkStyle = '' }: Props) => {
  return (
    <div className={'check-mark-list'}>
      {header && <div className={'check-mark-list--header'}>{header}</div>}
      {list.map((item, key) => {
        return (
          <div key={key} className={'check-mark-list--item'}>
            <div className={'check-mark-list--icon'}>
              <IconCheckmark checkMarkStyle={checkMarkStyle} />
            </div>
            <div className={'check-mark-list--text'}>{item}</div>
          </div>
        );
      })}
    </div>
  );
};
export default CheckMarkList;

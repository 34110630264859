import React from 'react';
import './apploader.css';
import { IconSmile } from '../../basics/icons';

interface Props {
  locale?: string;
}

export const AppLoader = ({ locale = 'en' }: Props) => {
  return (
    <section className={'loading-app-placeholder'}>
      <div>
        {/* @TODO: Use Translate component */}
        <IconSmile spinning={true} /> {locale === 'en' ? 'Busy loading...' : 'Bezig met laden...'}
      </div>
    </section>
  );
};

export default AppLoader;

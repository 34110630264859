import { DatetimePeriod } from '../../typings';

// interfaces & types
export interface Address {
  line1: string;
  line2: string;
  postal_code: string | any;
  locality: string;
  municipality?: string;
  administrative_area?: string;
  instructions?: string;
  country: string;
  lat?: number;
  lng?: number;
}

export interface stopTypeDetails {
  address: Address;
  details: {
    situation: string;
    carrying_help: string;
    instructions: string;
  };
  available_datetime_periods: DatetimePeriod[];
}

export enum StatusPageForms {
  FILTERS = '@@StatusPage/filters',
}

import { MessageInterface } from './interface';

// init state
export const defaultState = {
  loading: true,
  messages: [],
  sending: false,
  payload: '',
  format: 'plain',
  uuid: null,
  errors: null,
  item_sets: null,
  driver: {
    first_name: '',
    last_name: '',
    phone: '',
  },
};

// types
export const types = {
  START_CHAT_SESSION: 'START_CHAT_SESSION',
  CLOSE_CHAT_SESSION: 'CLOSE_CHAT_SESSION',
  SEND_NEW_MESSAGE_START: 'SEND_NEW_MESSAGE_START',
  SEND_NEW_MESSAGE_SUCCESS: 'SEND_NEW_MESSAGE_SUCCESS',
  SEND_NEW_MESSAGE_FAILED: 'SEND_NEW_MESSAGE_FAILED',
  FETCH_ALL_MESSAGES: 'FETCH_ALL_MESSAGES',
  FETCH_ALL_MESSAGES_SUCCESS: 'FETCH_ALL_MESSAGES_SUCCESS',
  FETCH_ALL_MESSAGES_FAILED: 'FETCH_ALL_MESSAGES_FAILED',
  SET_CHAT_OPEN: 'SET_CHAT_OPEN',
  SET_DRIVER_DATA: 'SET_DRIVER_DATA',
  REJECT_DRIVER: 'REJECT_DRIVER',
  CONFIRM_DRIVER: 'CONFIRM_DRIVER',
};

// actions
export const actions = {
  setDriver: driver => ({ type: types.SET_DRIVER_DATA, payload: driver }),
  rejectDriver: (job, driver) => ({ type: types.REJECT_DRIVER }),
  confirmDriver: (job, driver) => ({ type: types.CONFIRM_DRIVER }),
  openChatSession: () => ({ type: types.START_CHAT_SESSION }),
  closeChatSession: () => ({ type: types.CLOSE_CHAT_SESSION }),
  sendMessageStart: (msg, format, request) => ({
    type: types.SEND_NEW_MESSAGE_START,
    payload: { msg, format, request },
  }),
  sendMessageFailed: err => ({ type: types.SEND_NEW_MESSAGE_FAILED, payload: err }),
  sendMessageSuccess: resp => ({ type: types.SEND_NEW_MESSAGE_SUCCESS, payload: resp }),
  successFetchAllMessages: messages => ({ type: types.FETCH_ALL_MESSAGES_SUCCESS, payload: messages }),
  failedFetchAllMessages: err => ({ type: types.FETCH_ALL_MESSAGES_FAILED }),
};

// selectors
export const getMessages = state => {
  return state.chat.messages;
};

export const getTransportAccountLink = state => {
  return state.chat.driver;
};

export const getItemSetId = state => {
  return state.chat.item_sets;
};

export const getPayload = state => {
  return {
    transport_job_account_link: '/transport_job_account_links/0881697a-dc38-461c-9fcd-205913ddac5e',
    item_set: '/item_sets/6473436a-75a8-4531-9779-81ac6459e41b',
    author: '/users/d64d2ad3-e585-46f6-b678-afbbe278d206',
    body: state.chat.payload,
    body_format: state.chat.format,
    client_ip_address: '0.0.0.0',
    created_at: new Date(),
    updated_at: new Date(),
  };
};

// reducers
export const chatReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.SEND_NEW_MESSAGE_START:
      return {
        ...state,
        payload: action.payload.msg,
        format: action.payload.format,
        sending: true,
      };
      break;
    case types.SET_DRIVER_DATA:
      return {
        ...state,
        driver: action.payload,
        // loading: false, // should be remove #testcase
      };
      break;
    case types.SEND_NEW_MESSAGE_SUCCESS:
      const _messages: MessageInterface | any = state.messages;
      _messages.push(action.payload);
      return {
        ...state,
        messages: _messages,
        sending: false,
      };
    case types.SEND_NEW_MESSAGE_FAILED:
      return {
        ...state,
        errors: action.payload,
        sending: false,
      };
      break;
    case types.FETCH_ALL_MESSAGES_FAILED:
      return {
        ...state,
        errors: action.payload,
      };
    case types.FETCH_ALL_MESSAGES_SUCCESS:
      const sortedByDate = action.payload.sort(
        (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      );
      return {
        ...state,
        messages: sortedByDate,
        loading: false,
      };
    default:
      return state;
  }
};

export default chatReducer;

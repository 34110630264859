import React from 'react';
import { CirclePerson2, Toast } from '../../../../brenger-shared-ui';
import { Translate } from 'react-localize-redux';

interface Props {
  isOpen: boolean;
  close(): void;
}

const SamePickupAsDeliveryToast = ({ isOpen, close }: Props) => {
  return (
    <div className={`toast-container ${isOpen ? 'toast--visible' : ''}`}>
      <Toast prefix={<CirclePerson2 />} handleClose={() => close()}>
        <div className={'pt-0-5'} style={{ paddingRight: '15px' }}>
          <b>
            <Translate id={'request_flow.same_pickup_time_as_delivery.heading'} />
          </b>
        </div>
        <Translate id={'request_flow.same_pickup_time_as_delivery.desc'} />
      </Toast>
    </div>
  );
};

export default SamePickupAsDeliveryToast;

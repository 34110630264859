import { applyMiddleware, compose, createStore } from 'redux';
import createRootReducer from './ducks';
import { rootSaga } from './sagas';
import { sagaMiddleware } from './middlewares';
import { routerMiddleware } from 'connected-react-router';
import { _PERSIST_BLACKLIST, _PERSIST_VERSION } from '../utils/global';
import { cacheEnhancer } from 'redux-cache';

import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { persist } from '../configs/persist';

export const configureStore = history => {
  const rootReducer = createRootReducer(history);
  const persistedReducer = persistReducer(
    {
      key: 'root',
      version: _PERSIST_VERSION,
      blacklist: _PERSIST_BLACKLIST,
      storage,
    },
    rootReducer
  );
  const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 100 })) ||
    compose;
  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history)), cacheEnhancer())
  );
  const persistor = persistStore(store);
  persist.runMigrations(persistor);
  sagaMiddleware.run(rootSaga);
  return { store, persistor };
};

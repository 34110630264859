import * as React from 'react';
import './person-balloon.css';
import { Col, Container, Row } from '../../layout/grid';

interface Props {
  balloonContent: any;
}

export const PersonBalloon = ({ balloonContent }: Props) => {
  return (
    <div className={'person-balloon--wrapper'}>
      <Container fluid={true}>
        <Row>
          <Col xs={6} extraClasses={'text--right'}>
            <svg
              className={'person-balloon--svg'}
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100"
              viewBox="0 0 100 100"
            >
              <g fill="none" fillRule="evenodd">
                <g transform="translate(0 .151)">
                  <mask id="b" fill="#fff">
                    <path id="a" d="M0 .39H99.61v92.765H0z" />
                  </mask>
                  <path
                    fill="#00EBA0"
                    d="M0 49.924C0 22.567 22.298.39 49.804.39 77.31.39 99.61 22.567 99.61 49.924c0 17.132-8.746 32.23-22.038 41.126-4.25-6.027-10.488-10.567-17.787-12.633v6.848c0 4.358-3.552 7.89-7.934 7.89-4.381 0-7.933-3.532-7.933-7.89 0-.135.013-.265.02-.398V82.93c0-1.7-.454-3.314-1.234-4.746-8.024 2.044-14.85 7.048-19.253 13.767C9.372 83.199 0 67.654 0 49.924"
                    mask="url(#b)"
                  />
                </g>
                <g transform="translate(23.37 77.99)">
                  <mask id="d" fill="#fff">
                    <path id="c" d="M.08.347h54.122v21.275H.08z" />
                  </mask>
                  <path
                    fill="#00383B"
                    d="M19.334.347c.78 1.432 1.234 3.045 1.234 4.746v1.936c-.008.133-.021.264-.021.398 0 4.358 3.552 7.89 7.933 7.89 4.382 0 7.934-3.532 7.934-7.89V.58c7.3 2.066 13.537 6.606 17.788 12.633-7.933 5.31-17.487 8.41-27.768 8.41A49.758 49.758 0 0 1 .081 14.113C4.483 7.394 11.309 2.39 19.334.347"
                    mask="url(#d)"
                  />
                </g>
                <path
                  fill="#00EB9D"
                  d="M38.786 32.632a15.887 15.887 0 0 1 5.668-.865c6.554.208 11.993 4.501 14.23 10.315a12.298 12.298 0 0 1-7.586 2.61c-6.739 0-12.206-5.382-12.312-12.06M51.85 93.307c-4.382 0-7.933-3.533-7.933-7.89 0-.135.013-.266.02-.399v-1.936c0-1.7-.454-3.314-1.234-4.746a32.91 32.91 0 0 1 17.08.232v6.848c0 4.358-3.551 7.89-7.933 7.89"
                />
                <path
                  fill="#00EB9C"
                  d="M58.284 28.017H36.41c-4.536 0-8.247 3.69-8.247 8.203v13.746c0 4.512 3.711 8.204 8.247 8.204h21.874c4.537 0 8.248-3.692 8.248-8.204V36.22c0-4.512-3.711-8.203-8.248-8.203"
                />
                <path
                  fill="#0072FF"
                  d="M58.284 29.607H36.41c-4.536 0-8.247 3.69-8.247 8.203v13.746c0 4.512 3.711 8.204 8.247 8.204h21.874c4.537 0 8.248-3.692 8.248-8.204V37.81c0-4.512-3.711-8.203-8.248-8.203"
                />
                <path
                  fill="#00EB9D"
                  d="M59.131 47.003c0-.689.652-1.181 1.327-1.026 2.612.598 4.559 2.925 4.559 5.704s-1.947 5.106-4.56 5.703c-.674.155-1.326-.337-1.326-1.026v-9.355z"
                />
                <path
                  fill="#FFF"
                  d="M59.131 47.003c0-.689.652-1.181 1.327-1.026 2.612.598 4.559 2.925 4.559 5.704s-1.947 5.106-4.56 5.703c-.674.155-1.326-.337-1.326-1.026v-9.355z"
                  opacity=".8"
                />
                <path
                  fill="#00A9E2"
                  d="M44.349 51.115a2.301 2.301 0 0 0 2.307 2.296 2.3 2.3 0 0 0 2.307-2.296 2.3 2.3 0 0 0-2.307-2.295 2.301 2.301 0 0 0-2.307 2.295M30.842 51.115a2.301 2.301 0 0 0 2.308 2.296 2.301 2.301 0 0 0 2.308-2.296 2.301 2.301 0 0 0-2.308-2.295 2.301 2.301 0 0 0-2.308 2.295"
                />
                <path
                  fill="#FC0"
                  d="M40.938 59.614V53.79c0-.559-.679-.839-1.076-.444L38.1 55.1c-.89.885-.89 2.32 0 3.204l1.763 1.754c.397.395 1.076.115 1.076-.444"
                />
                <path
                  fill="#FFF"
                  d="M28.126 58.227l-.015-9.885c-.37-7.262 4.211-13.497 10.675-15.71.106 6.678 5.573 12.06 12.312 12.06 2.864 0 5.493-.98 7.586-2.61a16.348 16.348 0 0 1 1.1 5.873V78.57a32.887 32.887 0 0 0-17.08-.232 10.215 10.215 0 0 0-4.984-4.534c-5.971-2.535-10.058-8.612-9.594-15.576"
                />
                <path
                  fill="#00A9E2"
                  d="M44.913 51.115a2.301 2.301 0 0 0 2.308 2.296 2.301 2.301 0 0 0 2.307-2.296 2.301 2.301 0 0 0-2.307-2.295 2.301 2.301 0 0 0-2.308 2.295M31.407 51.115a2.301 2.301 0 0 0 2.307 2.296 2.301 2.301 0 0 0 2.308-2.296 2.301 2.301 0 0 0-2.308-2.295 2.301 2.301 0 0 0-2.307 2.295M34.669 63.218H37.3c.156 0 .297.105.327.258a3.47 3.47 0 0 0 3.404 2.786 3.47 3.47 0 0 0 3.403-2.786.329.329 0 0 1 .326-.258h2.634c.197 0 .357.169.334.363-.386 3.336-3.241 5.936-6.697 5.936s-6.311-2.6-6.698-5.936a.332.332 0 0 1 .335-.363"
                />
                <path
                  fill="#0072FF"
                  d="M58.683 42.082s-3.227-10.01-14.266-10.01c0 0-1.989-.24-5.63.56 0 0 .2 8.003 7.396 11.078 0 0 6.058 3.156 12.5-1.628"
                />
              </g>
            </svg>
          </Col>
          <Col xs={6} extraClasses={'text--left'}>
            <div className={'person-balloon--balloon'}>
              <div className={'person-balloon--balloon--content'}>{balloonContent}</div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default PersonBalloon;

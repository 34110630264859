import _get from 'lodash/get';
import { getTrTitle, isBusinessReturnOrder } from '../../utils/TransportRequest';
import { _FLOW_TYPE as BusinessFlowType } from '../Business/containers/TransportRequest';
import { stopTypeDetails } from './interface';
import { ItemSetState, RootState, StopType } from '../../typings';
import { StatusPage } from './typings';
import { getItemSetsUniqueClientReferences } from '../../utils/itemSet';

// init state
export const defaultState: StatusPage = {
  id: null,
  short_id: null,
  pickup: null,
  delivery: null,
  customer: null,
  item_sets: [],
  price: null,
  routeData: null,
  // transport job id
  transport_job: null,
  failedLoadingTransportRequest: false,
  directly_claimable: null,
  transport_job_bundled: false,
  // accepted driver for the job
  confirmedAccountLink: null,
  // list of drivers who made an offer
  accountLinks: [],
  source_flow: '',
  // list of transport jobs
  overview_list: {
    loading: false,
    results: [],
    total: 0,
  },
  total_number_trs: 0,
  currentPage: 1,
  // https://gitlab.com/brenger/frontend/transport-requests/issues/590
  cancelable: false,
  layout: {
    // FIXME: should not saved be here
    isLoggedIn: false,
    chatOpen: false,
    confirmOpen: false,
    isCancelLoading: false,
    cancelErrorText: '',
  },
};

export const _MODULE_PREFIX = '@@StatusPage';

// types
export const types = {
  FAILED_LOADING_TRANSPORT_REQUEST: `${_MODULE_PREFIX}/FAILED_LOADING_TRANSPORT_REQUEST`,
  SET_ACCOUNT_TOTAL_REQUESTS: `${_MODULE_PREFIX}/SET_ACCOUNT_TOTAL_REQUESTS`,
  SET_STATUS_PAGE_ID: `${_MODULE_PREFIX}/SET_STATUS_PAGE_ID`,
  SET_CHAT_OPEN: `${_MODULE_PREFIX}/SET_CHAT_OPEN`,
  SET_CONFIRM_OPEN: `${_MODULE_PREFIX}/SET_CONFIRM_OPEN`,
  SET_TRANSPORT_REQUEST: `${_MODULE_PREFIX}/SET_TRANSPORT_REQUEST`,
  SET_TRANSPORT_REQUEST_STOP_CONTACT: `${_MODULE_PREFIX}/SET_TRANSPORT_REQUEST_STOP_CONTACT`,
  SET_TRANSPORT_ROUTE: `${_MODULE_PREFIX}/SET_TRANSPORT_ROUTE`,
  SET_ACCOUNT_LINKS: `${_MODULE_PREFIX}/SET_ACCOUNT_LINKS`,
  SET_TRANSPORT_JOB_ID: `${_MODULE_PREFIX}/SET_TRANSPORT_JOB_ID`,
  SET_ACCOUNT_DETAILS: `${_MODULE_PREFIX}/SET_ACCOUNT_DETAILS`,
  SET_ACCEPTED_ACCOUNT_LINK: `${_MODULE_PREFIX}/SET_ACCEPTED_ACCOUNT_LINK`,
  CONFIRM_ACCOUNT_LINK: 'CONFIRM_ACCOUNT_LINK',
  REJECT_ACCOUNT_LINK: `${_MODULE_PREFIX}/REJECT_ACCOUNT_LINK`,
  REJECT_ACCOUNT_LINK_FAILED: `${_MODULE_PREFIX}/REJECT_ACCOUNT_LINK_FAILED`,
  REMOVE_ACCOUNT_LINK: `${_MODULE_PREFIX}/REMOVE_ACCOUNT_LINK`,
  CONFIRMED_ACCOUNT_LINK: `${_MODULE_PREFIX}/CONFIRMED_ACCOUNT_LINK`,
  CONFIRM_ACCOUNT_LINK_FAILED: `${_MODULE_PREFIX}/CONFIRM_ACCOUNT_LINK_FAILED`,
  SET_CONFIRMED_DRIVER_ACCOUNT: `${_MODULE_PREFIX}/SET_CONFIRMED_DRIVER_ACCOUNT`,
  CLEAR_TRANSPORT_REQUEST: `${_MODULE_PREFIX}/CLEAR_TRANSPORT_REQUEST`,
  CONFIRM_DELIVERY_AS_CUSTOMER_START: `${_MODULE_PREFIX}/CONFIRM_DELIVERY_AS_CUSTOMER_START`,
  CANCEL_TRANSPORT_REQUEST_START: `${_MODULE_PREFIX}/CANCEL_TRANSPORT_REQUEST_START`,
  CANCEL_TRANSPORT_REQUEST_SUCCESS: `${_MODULE_PREFIX}/CANCEL_TRANSPORT_REQUEST_SUCCESS`,
  CANCEL_TRANSPORT_REQUEST_FAILED: `${_MODULE_PREFIX}/CANCEL_TRANSPORT_REQUEST_FAILED`,
  SUBMIT_FILTER_FORM: `${_MODULE_PREFIX}/SUBMIT_FILTER_FORM`,
  SET_FILTERS: `${_MODULE_PREFIX}/SET_FILTERS`,
  RESET_FILTERS: `${_MODULE_PREFIX}/RESET_FILTERS`,
  FETCH_FILTERED_RESULTS_START: `${_MODULE_PREFIX}/FETCH_FILTERED_RESULTS_START`,
  FETCH_FILTERED_RESULTS_SUCCESS: `${_MODULE_PREFIX}/FETCH_FILTERED_RESULTS_SUCCESS`,
  FETCH_FILTERED_RESULTS_FAILED: `${_MODULE_PREFIX}/FETCH_FILTERED_RESULTS_FAILED`,
  FETCH_FILTERED_RESULTS_PAGE: `${_MODULE_PREFIX}/FETCH_FILTERED_RESULTS_PAGE`,
  FETCH_FILTERED_RESULTS_TOTAL_REQUESTS: `${_MODULE_PREFIX}/FETCH_FILTERED_RESULTS_TOTAL_REQUESTS`,
};

// actions
export const actions = {
  setAccountTotalRequests: (total: number) => ({ type: types.SET_ACCOUNT_TOTAL_REQUESTS, total }),
  failedLoadingTransportRequest: () => ({ type: types.FAILED_LOADING_TRANSPORT_REQUEST }),
  setTransportRequestId: id => ({ type: types.SET_STATUS_PAGE_ID, payload: id }),
  setTransportJobId: id => ({ type: types.SET_TRANSPORT_JOB_ID, payload: id }),
  setChatOpen: (isOpen, tjal) => ({ type: types.SET_CHAT_OPEN, payload: { isOpen, tjal } }),
  setConfirmOpen: isOpen => ({ type: types.SET_CONFIRM_OPEN, payload: isOpen }),
  cancelTransportRequestStart: id => ({ type: types.CANCEL_TRANSPORT_REQUEST_START, payload: id }),
  cancelTransportRequestSuccess: id => ({ type: types.CANCEL_TRANSPORT_REQUEST_SUCCESS, payload: id }),
  cancelTransportRequestFailed: (id, error) => ({ type: types.CANCEL_TRANSPORT_REQUEST_FAILED, payload: id, error }),
  setTransportRequest: request => ({ type: types.SET_TRANSPORT_REQUEST, payload: request }),
  setTransportRequestStopContact: request => ({ type: types.SET_TRANSPORT_REQUEST_STOP_CONTACT, payload: request }),
  setRoutePolylines: route => ({ type: types.SET_TRANSPORT_ROUTE, payload: route }),
  setAccountLinks: tjals => ({ type: types.SET_ACCOUNT_LINKS, payload: tjals }),
  setAccountDetails: details => ({ type: types.SET_ACCOUNT_DETAILS, payload: details }),
  setAcceptedAccountLink: tjal => ({ type: types.SET_ACCEPTED_ACCOUNT_LINK, payload: tjal }),
  confirmAccountLink: id => ({ type: types.CONFIRM_ACCOUNT_LINK, payload: id }),
  rejectAccountLink: id => ({ type: types.REJECT_ACCOUNT_LINK, payload: id }),
  removeAccountLink: id => ({ type: types.REMOVE_ACCOUNT_LINK, payload: id }),
  confirmedAccountLink: id => ({ type: types.CONFIRMED_ACCOUNT_LINK, payload: id }),
  setAcceptedDriverAccount: account => ({ type: types.SET_CONFIRMED_DRIVER_ACCOUNT, payload: account }),
  rejectAccountLinkFailed: err => ({ type: types.REJECT_ACCOUNT_LINK_FAILED, payload: err }),
  confirmAccountLinkFailed: err => ({ type: types.CONFIRM_ACCOUNT_LINK_FAILED, payload: err }),
  clearTransportRequest: () => ({ type: types.CLEAR_TRANSPORT_REQUEST }),
  confirmDeliveryAsCustomer: (offer, review) => ({
    type: types.CONFIRM_DELIVERY_AS_CUSTOMER_START,
    payload: { offer, review },
  }),
  submitFilterForm: details => ({ type: types.SUBMIT_FILTER_FORM }),
  setFilters: (filters: string[], updateAccountTotalRequests: boolean = false) => ({
    type: types.SET_FILTERS,
    payload: { filters, updateAccountTotalRequests },
  }),
  resetFilters: () => ({ type: types.RESET_FILTERS }),
  fetchFilteredResultsStart: () => ({ type: types.FETCH_FILTERED_RESULTS_START }),
  fetchFilteredResultsSuccess: requests => ({ type: types.FETCH_FILTERED_RESULTS_SUCCESS, payload: requests }),
  fetchFilteredResultsFailed: () => ({ type: types.FETCH_FILTERED_RESULTS_FAILED }),
  fetchFilteredResultPage: (page: number) => ({ type: types.FETCH_FILTERED_RESULTS_PAGE, page }),
  fetchFilteredResultTotalRequests: (total: number) => ({ type: types.FETCH_FILTERED_RESULTS_TOTAL_REQUESTS, total }),
};

export const getIsBusinessReturnOrder = state => {
  return isBusinessReturnOrder({
    item_sets: state.statusPage.item_sets,
  });
};

export const getChatOpenState = state => {
  return state.statusPage.layout.chatOpen;
};

export const getAccountLinks = state => {
  return state.statusPage.accountLinks;
};

export const getConfirmedAccountLink = state => {
  return state.statusPage.confirmedAccountLink;
};

export const getDeliveryId = (state, fullIri: boolean = false): null | string => {
  const delivery = getStopTypeDetails(state, StopType.delivery);
  if (delivery === null) {
    return null;
  }
  const deliveryIri = _get(delivery, '@id', null);
  if (deliveryIri === null) {
    return null;
  }
  if (fullIri) {
    return deliveryIri;
  }
  return deliveryIri.split('deliveries/')[1];
};

export const getTransportRequestId = state => {
  return state.statusPage.id;
};

export const getTransportJobId = state => {
  return state.statusPage.transport_job;
};

export const getTransportRequestTitle = state => {
  return getTrTitle(_get(state, 'statusPage', null));
};

export const getItemSets = (state: RootState) => {
  return state.statusPage.item_sets;
};

export const getUniqueClientReferences = (state: RootState): string[] => {
  const itemSets = getItemSets(state);
  return getItemSetsUniqueClientReferences(itemSets);
};

export const getTransportRequestState = (state): string => {
  const itemSets = getItemSets(state);
  return _get(itemSets, '[0].state', ItemSetState.READY_FOR_PICKUP);
};

export const getTransportRequestStateLabel = (state): string => {
  const driverFound = getIsDriverFound(state);
  const transportRequestState = getTransportRequestState(state);
  if (transportRequestState === ItemSetState.READY_FOR_PICKUP && driverFound) {
    return 'driver_found';
  }
  return transportRequestState;
};

export const getIsDriverFound = (state): boolean => {
  const confirmedAccountLink = getConfirmedAccountLink(state);
  return confirmedAccountLink !== null;
};

export const getTransportRequestIsDeliveredForCustomer = (state: RootState): boolean => {
  const TransportRequestState = getTransportRequestState(state);
  return (
    TransportRequestState === ItemSetState.DELIVERED ||
    TransportRequestState === ItemSetState.DELIVERY_CONFIRMED_BY_CUSTOMER
  );
};

export const getTransportRequestIsDirectlyClaimable = state => {
  if (state.statusPage.source_flow === BusinessFlowType) {
    return true;
  }
  return _get(state, 'statusPage.directly_claimable', true);
};

export const getTotalNumberTransportRequests = (state: any): number => {
  return state.statusPage.total_number_trs;
};

export const getStopTypeDetails = (state: any, stopType: StopType): stopTypeDetails | null => {
  return _get(state, `statusPage.${stopType}`, null);
};

// menu items
export const navItems = [
  {
    title: 'Brenger voor bedrijven',
    url: '/bedrijven',
    extra_classes: 'menu-index-1',
    is_normal_link: true,
  },
  {
    title: 'Hoe het werkt',
    url: '/nl/hoe-het-werkt',
    extra_classes: 'menu-index-2',
    is_normal_link: true,
  },
];

export const navItemsMobile = [
  {
    title: 'Uitloggen',
    url: 'https://api.brenger.nl/logout',
    extra_classes: 'bottom-position mobile-only menu-index-3',
    is_normal_link: true,
  },
];

export const navItemsProfile = [
  {
    title: '',
    url: '/profile',
    image: '',
    extra_classes: 'menu-index-3',
    children: [
      {
        title: 'Profiel',
        url: '/profile',
        extra_classes: 'menu-index-4',
      },
      {
        title: 'Uitloggen',
        url: 'https://api.brenger.nl/logout',
        extra_classes: 'hide-mobile menu-index-5 nav-item--offset color-grey',
        is_normal_link: true,
      },
    ],
  },
];

// reducers
export const statusPage = (state = defaultState, action: any): StatusPage => {
  switch (action.type) {
    case types.FAILED_LOADING_TRANSPORT_REQUEST:
      return {
        ...state,
        failedLoadingTransportRequest: true,
      };
    case types.CLEAR_TRANSPORT_REQUEST:
      return {
        ...state,
        id: defaultState.id,
        transport_job: defaultState.transport_job,
        price: defaultState.price,
        routeData: defaultState.routeData,
        item_sets: defaultState.item_sets,
        delivery: defaultState.delivery,
        pickup: defaultState.pickup,
        failedLoadingTransportRequest: defaultState.failedLoadingTransportRequest,
        confirmedAccountLink: defaultState.confirmedAccountLink,
        accountLinks: defaultState.accountLinks,
      };
    case types.SET_TRANSPORT_ROUTE:
      return {
        ...state,
        routeData: action.payload,
      };
    case types.SET_ACCOUNT_LINKS:
      return {
        ...state,
        accountLinks: action.payload['hydra:member'],
      };
    case types.SET_STATUS_PAGE_ID:
      return {
        ...state,
        id: action.payload,
        failedLoadingTransportRequest: false,
      };
    case types.SET_TRANSPORT_JOB_ID:
      return {
        ...state,
        transport_job: action.payload,
      };
    case types.SET_CHAT_OPEN:
      return {
        ...state,
        layout: {
          ...defaultState.layout,
          chatOpen: action.payload.isOpen,
          // tjal: action.payload.tjal,
        },
      };
    case types.SET_ACCEPTED_ACCOUNT_LINK:
      return {
        ...state,
        confirmedAccountLink: action.payload,
      };
    case types.SET_CONFIRM_OPEN:
      return {
        ...state,
        layout: {
          ...defaultState.layout,
          confirmOpen: action.payload,
        },
      };
    case types.SET_ACCOUNT_DETAILS:
      const _accountLinks = state.accountLinks.map((driver: any, key) => {
        if (driver.driver_account === action.payload['@id']) {
          return {
            ...driver,
            account: action.payload,
          };
        } else {
          return driver;
        }
      });
      return {
        ...state,
        accountLinks: _accountLinks,
      };
    case types.CONFIRM_ACCOUNT_LINK:
      const _confirmAccountLinks: any = state.accountLinks;
      _confirmAccountLinks.map(tjal => {
        if (tjal['@id'] === action.payload) {
          tjal.loading = true;
        } else {
          tjal.loading = false;
        }
      });
      return {
        ...state,
        accountLinks: _confirmAccountLinks,
      };
    case types.REJECT_ACCOUNT_LINK:
      const _rejectAccountLinks: any = state.accountLinks;
      _rejectAccountLinks.map(tjal => {
        if (tjal['@id'] === action.payload) {
          tjal.loading = true;
        } else {
          tjal.loading = false;
        }
      });
      return {
        ...state,
        accountLinks: _rejectAccountLinks,
      };
    case types.CONFIRM_ACCOUNT_LINK_FAILED:
    case types.REJECT_ACCOUNT_LINK_FAILED:
      const pendingAccountLinks: any = state.accountLinks;
      pendingAccountLinks.map(tjal => {
        tjal.loading = false;
      });
      return {
        ...state,
        accountLinks: pendingAccountLinks,
      };
    case types.SET_TRANSPORT_REQUEST:
      return {
        ...state,
        short_id: action.payload.short_id,
        customer: action.payload.customer,
        price: action.payload.price,
        pickup: _get(action, 'payload.pickups[0]', {}),
        delivery: _get(action, 'payload.deliveries[0]', {}),
        item_sets: action.payload.item_sets,
        directly_claimable: action.payload.directly_claimable,
        source_flow: action.payload.source_flow,
        transport_job_bundled: action.payload.transport_job_bundled,
        cancelable: action.payload.cancelable,
      };
    case types.SET_TRANSPORT_REQUEST_STOP_CONTACT:
      return {
        ...state,
        short_id: defaultState.short_id,
        customer: defaultState.customer,
        price: defaultState.price,
        pickup: action.payload['@type'].indexOf('Pickup') > -1 ? action.payload.stopTypeDetails : defaultState.pickup,
        delivery:
          action.payload['@type'].indexOf('Delivery') > -1 ? action.payload.stopTypeDetails : defaultState.delivery,
        item_sets: action.payload.item_sets,
        directly_claimable: null,
        source_flow: defaultState.source_flow,
        transport_job_bundled: action.payload.transport_job_bundled,
      };
    case types.SET_ACCOUNT_TOTAL_REQUESTS:
      return {
        ...state,
        total_number_trs: action.total,
      };
    case types.REMOVE_ACCOUNT_LINK:
      const _accountLinkShouldRemove: any = [];
      state.accountLinks.map((tjal: any) => {
        if (tjal['@id'] !== action.payload) {
          _accountLinkShouldRemove.push(tjal);
        }
      });
      return {
        ...state,
        accountLinks: _accountLinkShouldRemove,
        layout: {
          ...state.layout,
          chatOpen: false,
        },
      };
    case types.SET_CONFIRMED_DRIVER_ACCOUNT:
      const _confirmedAcountLink: any = state.confirmedAccountLink;
      _confirmedAcountLink['account'] = action.payload;
      return {
        ...state,
        confirmedAccountLink: _confirmedAcountLink,
      };
    case types.CONFIRMED_ACCOUNT_LINK:
      const filteredAccountLinks: any = [];
      let confirmedAccountLink = state.confirmedAccountLink;
      state.accountLinks.map((tjal: any) => {
        tjal.loading = false;
        if (tjal['@id'] !== action.payload) {
          filteredAccountLinks.push(tjal);
        } else {
          confirmedAccountLink = tjal;
        }
      });
      return {
        ...state,
        accountLinks: filteredAccountLinks,
        confirmedAccountLink,
        layout: {
          ...state.layout,
          chatOpen: false,
        },
      };
    case types.CANCEL_TRANSPORT_REQUEST_START:
      return {
        ...state,
        layout: {
          ...state.layout,
          isCancelLoading: true,
          cancelErrorText: '',
        },
      };
    case types.CANCEL_TRANSPORT_REQUEST_SUCCESS:
      const filteredTransportRequests = state.overview_list.results.filter(
        transportRequest => transportRequest['@id'] !== `/transport_requests/${action.payload}`
      );
      return {
        ...state,
        overview_list: {
          ...state.overview_list,
          results: filteredTransportRequests,
        },
        layout: {
          ...state.layout,
          isCancelLoading: false,
          cancelErrorText: '',
        },
      };
    case types.CANCEL_TRANSPORT_REQUEST_FAILED:
      return {
        ...state,
        layout: {
          ...state.layout,
          isCancelLoading: false,
          cancelErrorText: action.error.message,
        },
      };
    case types.FETCH_FILTERED_RESULTS_START:
      return {
        ...state,
        overview_list: {
          ...state.overview_list,
          loading: true,
        },
      };
    case types.FETCH_FILTERED_RESULTS_SUCCESS:
      return {
        ...state,
        overview_list: {
          ...state.overview_list,
          results: action.payload,
          loading: false,
        },
      };
    case types.FETCH_FILTERED_RESULTS_FAILED:
      return {
        ...state,
        overview_list: {
          ...state.overview_list,
          total: 0,
          loading: false,
        },
      };
    case types.FETCH_FILTERED_RESULTS_TOTAL_REQUESTS:
      return {
        ...state,
        overview_list: {
          ...state.overview_list,
          total: action.total,
        },
      };
    case types.FETCH_FILTERED_RESULTS_PAGE:
      return {
        ...state,
        currentPage: action.page,
      };
    default:
      return state;
  }
};

export default statusPage;

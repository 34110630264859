/*
/* BRENGER MAP UTILS
/* ================================================== */

import { _BASE_MAPS_URI } from './global';

/**
 *
 * @param coords {array}
 * @returns {array}
 */
const normalizeCoords = coords => {
  let normalized = [];
  if (coords === null) {
    return normalized;
  }
  coords.map(point => {
    normalized.push({ lat: point.latitude, lng: point.longitude });
    return normalized;
  });
  return normalized;
};

/**
 *
 * @param apiKey {string}
 * @param route {array}
 * @param width {number}
 * @param height {number}
 * @returns {string}
 */
const generateStaticMapsWithRouteSrc = (apiKey, route, width = 600, height = 500) => {
  if (!apiKey) {
    throw new Error('API key not set');
  }
  if (!route) {
    throw new Error('Route is not set, cannot print route');
  }
  if (Array.isArray(route) === false || route.length === 0) {
    throw new Error('Route has no coörds, cannot print route');
  }
  const paths = route
    .filter(item => {
      return typeof item === 'object';
    })
    .map(item => {
      return `${item.lat},${item.lon}`;
    });
  return encodeURI(`${_BASE_MAPS_URI}?size=${width}x${height}&maptype=roadmap&path=${paths.join('|')}&key=${apiKey}`);
};

const styledMapType = [
  {
    featureType: 'administrative',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.locality',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'landscape',
    stylers: [
      {
        hue: '#0066ff',
      },
      {
        saturation: 74,
      },
      {
        lightness: 100,
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#fcfcfc',
      },
    ],
  },
  {
    featureType: 'poi',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#bbfb95',
      },
    ],
  },
  {
    featureType: 'road',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.highway',
    stylers: [
      {
        saturation: -85,
      },
      {
        lightness: 61,
      },
      {
        visibility: 'off',
      },
      {
        weight: 0.6,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road.local',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eaeaea',
      },
    ],
  },
  {
    featureType: 'transit',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'water',
    stylers: [
      {
        color: '#5f94ff',
      },
      {
        lightness: 26,
      },
      {
        gamma: 5.86,
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#dff2ff',
      },
    ],
  },
];

export { generateStaticMapsWithRouteSrc, styledMapType, normalizeCoords };

import { Nullable } from '../../typings';
import { User as IUser } from '@brenger/api-client';

export enum UserProperties {
  first_name = 'first_name',
  last_name = 'last_name',
  password = 'password',
  email = 'email',
  phone = 'phone',
  termsofuse = 'termsofuse',
}

export enum Company {
  name = 'name',
  line1 = 'line1',
  country = 'country',
  postal_code = 'postal_code',
  locality = 'locality',
  coc_number = 'coc_number',
  vat_number = 'vat_number',
}

export interface PasswordReset {
  loading: boolean;
  error: string;
  success: boolean;
}

export interface PasswordNew {
  token: string;
  loading: boolean;
  error: string;
  success: boolean;
}

export interface Invoice {
  '@id': string;
  '@type': string;
  invoice_number: string;
  refunds: any;
  invoice_url: string;
  transport_jobs: string[];
  transport_requests: string[];
  transport?: any;
}

export interface User {
  id: Nullable<string>;
  fetching: boolean;
  invoices: Invoice[];
  loggedIn: boolean;
  loggedInMethod: Nullable<any>;
  userType: Nullable<string>;
  userData: Nullable<IUser>;
  errors: string;
  details: Nullable<any>;
  loading: boolean;
  registered: boolean;
  isBusiness: boolean;
  what_is_my_country: string;
  password_reset: PasswordReset;
  password_new: PasswordNew;
}

import React from 'react';
import './locationmarker.css';

interface Props {
  lat: number;
  lng: number;
  textLabel: any;
  direction?: string;
}

export class LocationMarker extends React.Component<Props> {
  public render() {
    return (
      <div className={'location-marker location-marker--' + this.props.direction}>
        <div className={'location-marker--label'}>{this.props.textLabel}</div>
        {this.props.direction === 'pickup' && (
          <svg className={'location-marker--icon'} viewBox="695.5 422.5 25 25" width="17" height="17">
            <circle
              cx="708"
              cy="435"
              r="10"
              fill="#FFF"
              strokeWidth="5"
              stroke="#0073fa"
              strokeLinejoin="miter"
              strokeLinecap="square"
              strokeMiterlimit="3"
            />
          </svg>
        )}
        {this.props.direction === 'delivery' && (
          <svg className={'location-marker--icon'} viewBox="698 425 20 20" width="17" height="17">
            <circle vectorEffect="non-scaling-stroke" cx="708" cy="435" r="10" fill="#0073fa" />
          </svg>
        )}
      </div>
    );
  }
}

export default LocationMarker;

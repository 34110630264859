import { ItemSet } from '../modules/GeneralFlow/interface';

export const getItemSetsVolumeAsString = (itemSets: any[]): string => {
  if (!itemSets) {
    return '';
  }
  const objectsSizes: string[] = [];
  itemSets.map(itemSet => {
    if (itemSet.items) {
      itemSet.items.map(item => {
        const quantity = Number(item.count);
        const volume: string[] = [];
        if (item.length) {
          volume.push(item.length);
        }
        if (item.width) {
          volume.push(item.width);
        }
        if (item.height) {
          volume.push(item.height);
        }
        if (volume.length > 0) {
          objectsSizes.push(volume.join('x') + ' cm' + (quantity > 1 ? ' (' + quantity + 'x)' : ''));
        }
      });
    }
  });

  return objectsSizes.join(', ');
};

export const getItemSetsVolume = (itemSets: any[]): number => {
  const objectsSizes: number[] = [];
  itemSets.map(itemSet => {
    itemSet.items.map(item => {
      const volume: number[] = [];
      if (item.count) {
        volume.push(Number(item.count));
      }
      if (item.length) {
        volume.push(Number(item.length));
      }
      if (item.width) {
        volume.push(Number(item.width));
      }
      if (item.height) {
        volume.push(Number(item.height));
      }
      if (volume.length === 4) {
        objectsSizes.push(volume.reduce((a, b) => a * b));
      }
    });
  });
  if (objectsSizes.length === 0) {
    return 0;
  }
  return objectsSizes.reduce((a, b) => a + b);
};

export const getItemSetsUniqueClientReferences = (itemSets: ItemSet[]): string[] => {
  const references: string[] = [];
  if (itemSets.length === 0) {
    return references;
  }
  let index = 0;
  do {
    const clientReference = itemSets[index].client_reference;
    if (clientReference && !references.includes(clientReference)) {
      references.push(clientReference);
    }
    index++;
  } while (itemSets.length > index);
  return references;
};

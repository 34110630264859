import React from 'react';
import { Translate } from 'react-localize-redux';
import './network-detector.css';

const TRY_AGAIN_DURATION = 30;

interface Props {
  setNetworkStatus: any;
}

interface State {
  seconds: number;
  interval: any;
}

export class NetworkDetector extends React.Component<Props, State> {
  private constructor(props) {
    super(props);
    this.state = {
      seconds: TRY_AGAIN_DURATION,
      interval: null,
    };
  }
  public componentWillMount() {
    this.props.setNetworkStatus('OFFLINE');
    this.setState({
      interval: window.setInterval(() => {
        const seconds = this.state.seconds - 1;
        this.setState({ seconds });
      }, 1000),
    });
  }
  public componentWillUnmount() {
    this.props.setNetworkStatus('ONLINE');
    window.clearInterval(this.state.interval);
    this.setState({ interval: null });
  }
  public componentDidUpdate(props, state) {
    if (state.seconds === 0) {
      this.setState({ seconds: TRY_AGAIN_DURATION });
    }
  }
  public render() {
    return (
      <div className={'offline-bar'}>
        <Translate data={{ seconds: this.state.seconds }} id={'transport_request.offline_bar'} />
      </div>
    );
  }
}

export default NetworkDetector;

import { all, call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  actions as userActions,
  getLoggedInUser,
  getResetToken,
  isRegistered,
  isSocialRegistration,
  types as actionTypes,
} from './ducks';
import { BASE_URL_API, coreClient, http } from '../../utils/request';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { actionTypes as formActionTypes, change } from 'redux-form';
import { matchPath } from 'react-router';
import { _WHAT_IS_MY_COUNTRY_URL, GENERAL_ERR_MESSAAGE, ROLES } from '../../utils/global';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import * as appUtils from '../../utils/basics';
import { getDashboardLink } from '../Business/ducks';
import { User } from '@brenger/api-client';
import { Invoice } from './interface';
import { getSearchParamString } from '../../state/ducks/baseReducer';

export function* startSocialAuth(action) {
  const URL = appUtils.urlParser();
  const uuid = URL.get('transport_request');
  const referer = uuid && uuid !== '' ? `&transport_request=${uuid}` : '';
  window.location.assign(`${BASE_URL_API}/user/login?social=${action.service}${referer}`);
}

export function* verifyLoggedInUser(action) {
  if (matchPath(action.payload.location.pathname, { path: '/login/check' })) {
    try {
      const registered = yield select(isRegistered);
      const redirectUrl = registered ? '/track_and_trace' : '/register';
      const user: User = yield call(coreClient.users.retrieveCurrentUser);
      yield all([put(userActions.socialAuthSuccess(user)), put(push(redirectUrl))]);
    } catch (e) {
      appUtils.logger(e);
      yield put(userActions.socialAuthFailed(GENERAL_ERR_MESSAAGE));
    }
  }
}

export function* failedSocialAuth(action) {
  if (matchPath(action.payload.location.pathname, { path: '/login/error', exact: true })) {
    yield delay(5000);
    yield put(push('/register'));
  }
}

export function completeRegistration(details, id) {
  details['username'] = details.email;
  details['roles'] = [ROLES.ROLE_CUSTOMER, ROLES.ROLE_USER];
  return http()
    .put(`${id}/complete_social_registration`, details, { withCredentials: true })
    .then(resp => resp);
}

export function createIndividualAccount(details) {
  const URL = appUtils.urlParser();
  const uuid = URL.get('transport_request');
  const url = uuid && uuid !== '' ? `users?transport_request=${uuid}` : `users`;
  details.account = {
    account_type: 'individual',
    name: details.first_name + ' ' + details.last_name,
    phone: details.phone,
  };
  details['username'] = details.email;
  details['roles'] = [ROLES.ROLE_CUSTOMER, ROLES.ROLE_USER];
  return http()
    .post(url, details, { withCredentials: true })
    .then(resp => resp);
}

export function createBusinessAccount(details) {
  const URL = appUtils.urlParser();
  const uuid = URL.get('transport_request');
  const url = uuid && uuid !== '' ? `users?transport_request=${uuid}` : `users`;
  details.account.account_type = 'business';
  details.account.phone = details.phone;
  details['username'] = details.email;
  details['roles'] = [ROLES.ROLE_CUSTOMER, ROLES.ROLE_USER];
  return http()
    .post(url, details, { withCredentials: true })
    .then(resp => resp);
}

export function* registerUser(action) {
  let formPayload = action.payload.user;
  if (_get(action.payload.user, 'account', false)) {
    formPayload = _omit(action.payload.user, 'account');
  }
  try {
    const user = yield select(getLoggedInUser);
    const isSocialReg = yield select(isSocialRegistration);
    if (isSocialReg) {
      // social logged in user registration scenario
      if (user.isBusiness) {
        const account = action.payload.user.account;
        yield all([
          call(updateAccount, user.details.account['@id'], account),
          call(createAddress, account),
          call(completeRegistration, formPayload, user.id),
        ]);
        yield put(userActions.registerSuccess(action.payload.user));
      } else {
        yield call(completeRegistration, formPayload, user.id);
        yield put(userActions.registerSuccess(action.payload.user));
      }
    } else {
      // without social account registration scenario
      if (user.isBusiness) {
        yield call(createBusinessAccount, action.payload.user);
        yield put(userActions.registerSuccess(action.payload.user));
      } else {
        // test failure
        yield call(createIndividualAccount, action.payload.user);
        yield put(userActions.registerSuccess(action.payload.user));
      }
    }
  } catch (err) {
    appUtils.logger(err);
    yield put(userActions.registerFailed(GENERAL_ERR_MESSAAGE));
  }
}

export function* prefillLoggedInUserDetails(action) {
  if (matchPath(action.payload.location.pathname, { path: '/register', exact: true })) {
    // check if we should preset business user
    if (window.location.search.indexOf('business=true') > -1) {
      yield put(change('register', 'user.type', 'business'));
    }

    const user = yield select(getLoggedInUser);
    if (user.id) {
      yield all([
        put(change('register', 'user.first_name', user.details.first_name)),
        put(change('register', 'user.last_name', user.details.last_name)),
        put(change('register', 'user.email', user.details.email)),
        put(change('register', 'user.phone', user.details.phone)),
      ]);
    }
  }
}

export function* loginUser(action) {
  const URL = appUtils.urlParser();
  const uuid = URL.get('transport_request');
  const url = uuid && uuid !== '' ? `/login?transport_request=${uuid}` : `/login`;
  try {
    const request = user => http().post(url, { email: user.email, password: user.password }, { withCredentials: true });
    const response = yield call(request, action.payload.user);
    if (response.status === 200) {
      // retrieve the currently logged in user!
      yield put(userActions.retrieveCurrentUser());
      // successful login!
      yield put(userActions.loginSuccess());
    }
  } catch (e) {
    appUtils.logger(e);
    yield put(userActions.loginFailed('Oops, wrong credentials!! please try again'));
  }
}

export function* checkUserType(action) {
  if (action.meta.field === 'user.type') {
    yield put(userActions.setAccountType(action.payload));
  }
}

export function* createAddress(addr) {
  return http()
    .post('addresses', addr, { withCredentials: true })
    .then(resp => resp);
}

export function* updateAccount(id, account) {
  return http()
    .put(id, account, { withCredentials: true })
    .then(resp => resp);
}

export function* init(action) {
  if (
    matchPath(action.payload.location.pathname, { path: '/register', exact: true }) ||
    matchPath(action.payload.location.pathname, { path: '/login', exact: true })
  ) {
    yield put(userActions.initAuthentication());
  }
}

export function* getTransportPerInvoice(invoice) {
  try {
    const request = url =>
      http()
        .get(url)
        .then(resp => resp);
    const response = yield call(request, invoice.transport_requests[0]);
    return response.data;
  } catch (err) {
    appUtils.logException('cannot fetch the transport request for the invoice', { invoice, err });
  }
}

export function* fetchInvoices(action) {
  try {
    const request = () =>
      http()
        .get('/invoices', { withCredentials: true })
        .then(resp => resp);
    const response = yield call(request);
    const invoices = response.data['hydra:member'];

    // fetch transport job data per each invoice
    const actions: any = [];
    invoices.map((invoice: Invoice) => {
      actions.push(call(getTransportPerInvoice, invoice));
    });
    const [...responses] = yield all(actions);
    invoices.map((invoice, i) => (invoice.transport = responses[i]));
    // sort invoices by date
    invoices.sort((a, b) => {
      const date1: number = new Date(b.transport.deliveries[0].available_datetime_periods[0].start).getTime();
      const date2: number = new Date(a.transport.deliveries[0].available_datetime_periods[0].start).getTime();
      return date1 - date2;
    });
    yield put(userActions.setInvoices(invoices));
  } catch (err) {
    appUtils.logException('cannot fetch the invoices', err);
  }
}

export function* checkWhatIsMyCountry() {
  try {
    const whatIsMyCountry = () => http().get(_WHAT_IS_MY_COUNTRY_URL);
    const request = yield call(whatIsMyCountry);
    yield put(userActions.setWhatIsMyCountry(_get(request, 'data', 'NL')));
  } catch (err) {
    appUtils.logException('cannot fetch the geo location based on IP', err);
  }
}

export function* retrieveCurrentUser() {
  try {
    const amILoggedIn = () => http().get('/am_i_logged_in', { withCredentials: true });
    const response = yield call(amILoggedIn);
    if (response) {
      const loggedInUser: User = yield call(coreClient.users.retrieveCurrentUser);
      yield put(userActions.setUser(loggedInUser));
      /*
       * BRENGER_USER_UPDATED
       * Custom event that takes care updating user details across scripts
       * See event listener here: stand-alone/ts/menu.ts:12
       * */
      const event = new CustomEvent('BRENGER_USER_UPDATED', {
        detail: {
          result: loggedInUser,
        },
      });
      document.dispatchEvent(event);
    }
  } catch (err) {
    yield put(userActions.loginFailed(err));
  }
}

export function* afterRegister(action) {
  const URL = appUtils.urlParser();
  const uuid = URL.get('transport_request');
  const url = uuid && uuid !== '' ? `/login?transport_request=${uuid}` : `/login`;
  if (_get(action, 'payload.email', false) && _get(action, 'payload.new_password', false)) {
    try {
      const request = (email, password) =>
        http()
          .post(url, { email, password }, { withCredentials: true })
          .then(resp => resp);
      const response = yield call(request, action.payload.email, action.payload.new_password);
      if (response.status === 200) {
        yield put(userActions.loginSuccess());
      }
    } catch (Err) {
      appUtils.logger(Err);
    }
  }
}

export function* afterLogin() {
  const dashboardLink = yield select(getDashboardLink);
  const router = yield select(getSearchParamString);
  if (router.includes('contact=true')) {
    yield put(push('/transport_request/contact'));
  } else {
    yield put(push(dashboardLink));
  }
}

export function* sendPasswordReset(action) {
  try {
    const passwordResetCall = email =>
      http()
        .post('/users/reset_password/request', { email })
        .then(resp => resp);
    const response = yield call(passwordResetCall, action.payload.user.email);
    if (response.status === 200) {
      yield put(userActions.resetPassSuccess());
    }
  } catch (e) {
    appUtils.logger(e);
    yield put(userActions.resetPassFailed('Request failed'));
  }
}

export function* setPasswordResetToken(action) {
  const url = action.payload.location.pathname;
  const match = matchPath(url, { path: `/password_reset/:token`, exact: true, strict: false });
  if (match) {
    const token = _get(match, 'params.token', '');
    yield put(userActions.setNewPassToken(token));
    return;
  }
  // redirect, should be deleted when mailer is updated
  const redirectMatch = matchPath(url, { path: `/password-reset/:token`, exact: true, strict: false });
  if (redirectMatch) {
    const token = _get(redirectMatch, 'params.token', '');
    yield put(push('/password_reset/' + token));
  }
}

export function* sendPasswordNew(action) {
  const resetToken = yield select(getResetToken);
  try {
    const newPasswordCall = (token, password) =>
      http()
        .post('/users/reset_password', { token, password })
        .then(resp => resp);
    const response = yield call(newPasswordCall, resetToken, action.payload.user.password);
    if (response.status === 200) {
      yield put(userActions.newPassSuccess());
    }
  } catch (e) {
    appUtils.logger(e);
    yield put(userActions.newPassFailed('Request failed'));
  }
}

export function* trackUserId() {
  const user = yield select(getLoggedInUser);
  const id = _get(user, 'userData[@id]', null);
  if (!id) {
    return;
  }
  appUtils.pushToDataLayer({ userId: id.replace('/users/', '') });
}

function* userAccountSaga() {
  yield takeLatest(actionTypes.RETRIEVE_CURRENT_COUNTRY, checkWhatIsMyCountry);
  yield takeLatest(actionTypes.FETCH_INVOICES, fetchInvoices);
  yield takeLatest(actionTypes.LOGIN_USER_START, loginUser);
  yield takeLatest(actionTypes.LOGIN_USER_SUCCESS, afterLogin);
  yield takeLatest(actionTypes.RETRIEVE_CURRENT_USER, retrieveCurrentUser);
  yield takeLatest(actionTypes.SET_USER_DETAILS, trackUserId);
  yield takeLatest(formActionTypes.CHANGE, checkUserType);
}

function* socialAuthSaga() {
  yield takeLatest(LOCATION_CHANGE, init);
  yield takeLatest(LOCATION_CHANGE, verifyLoggedInUser);
  yield takeLatest(LOCATION_CHANGE, failedSocialAuth);
  yield takeLatest(LOCATION_CHANGE, prefillLoggedInUserDetails);
  yield takeLatest(actionTypes.SOCIAL_AUTH_START, startSocialAuth);
  yield takeLatest(actionTypes.REGISTER_USER_START, registerUser);
  yield takeLatest(actionTypes.REGISTER_USER_SUCCESS, afterRegister);
}

function* passWordResetSaga() {
  yield takeLatest(actionTypes.RESET_PASS_START, sendPasswordReset);
  yield takeLatest(actionTypes.NEW_PASS_START, sendPasswordNew);
  yield takeLatest(LOCATION_CHANGE, setPasswordResetToken);
}

export function* UserSagas() {
  yield fork(userAccountSaga);
  yield fork(socialAuthSaga);
  yield fork(passWordResetSaga);
}

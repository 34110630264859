import * as React from 'react';
import './progressbar.css';

interface Props {
  currentStep: number;
  totalSteps: number;
}

export const ProgressBar = ({ currentStep, totalSteps }: Props) => {
  const style = { '--progress-position': 100 - (currentStep / totalSteps) * 100 + '%' } as React.CSSProperties;
  return (
    <div className={'progress-bar'}>
      <div style={style} className={'progress-bar-indicator progress-current-' + currentStep} />
    </div>
  );
};
export default ProgressBar;

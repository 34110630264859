import React from 'react';
import { AxiosError } from 'axios';
import { createMatchSelector, push } from 'connected-react-router';
import { Translate } from 'react-localize-redux';
import Rating from 'react-rating';
import { useForm, useMutation, useQuery } from 'react-sage';
import { useDispatch } from 'react-redux';

import { coreClient } from '../../../utils/request';
import { _GOOGLE_REVIEW_URL, _TRUST_PILOT_REVIEW_URL } from '../../../utils/global';
import { logException } from '../../../utils/basics';
import { prefix } from '..';
import { useTypedSelector } from '../../../hooks';
import { SingleViewWrapper } from './SingleViewWrapper';
import { Button, Container, FlashMessage, Heading, IconStar, SectionWrapper } from '../../../brenger-shared-ui';

enum Field {
  PUNCTUALITY = 'punctuation',
  COMMUNICATION = 'communication',
  FRIENDLINESS = 'friendliness',
  ARRIVAL_MESSAGE = 'arrivalMessage',
  BRANDED_CLOTHING = 'brandedClothing',
  IDENTIFIED = 'identified',
  MOVING_SUPPLIES = 'movingSupplies',
  PUBLIC_COMMENT = 'comment_public',
  ALL_SERVICES_DELIVERED = 'all_services_delivered',
  PRIVATE_NOTE = 'note',
}

// These are the only three required fields.
// Therefore, the form gets validators for these fields.
const validators = {
  [Field.PUNCTUALITY]: (val: number) => !val,
  [Field.COMMUNICATION]: (val: number) => !val,
  [Field.FRIENDLINESS]: (val: number) => !val,
};

const requiredInitialState = {
  [Field.PUNCTUALITY]: 0,
  [Field.COMMUNICATION]: 0,
  [Field.FRIENDLINESS]: 0,
  [Field.PUBLIC_COMMENT]: '',
};

const optionalInitialState = {
  [Field.ARRIVAL_MESSAGE]: true,
  [Field.BRANDED_CLOTHING]: true,
  [Field.IDENTIFIED]: true,
  [Field.MOVING_SUPPLIES]: true,
  [Field.ALL_SERVICES_DELIVERED]: false,
  [Field.PRIVATE_NOTE]: '',
};

export const Review: React.FC = () => {
  const [reviewId, setReviewId] = React.useState('');
  const dispatch = useDispatch();
  const requiredForm = useForm({ initialState: requiredInitialState, validators });
  const optionalForm = useForm({ initialState: optionalInitialState });
  const match = useTypedSelector(createMatchSelector<any, { id: string }>(`${prefix}/:id`));

  const transportRequest = useQuery(coreClient.transportRequests.retrieve, {
    args: { id: match?.params.id },
  });

  const stopContactDetails = useQuery(coreClient.stops.listDetailsForContact, {
    args: { id: match?.params.id },
  });

  const createReview = useMutation(coreClient.reviews.create, createdReview => {
    // Save the newly created review id in local state so we can...
    // ... use it to update the review in the second step.
    setReviewId(createdReview.id);
  });

  const updateReview = useMutation(coreClient.reviews.update, updatedReview => {
    if (updatedReview.communication > 4 && updatedReview.punctuality > 4 && updatedReview.friendliness > 4) {
      const reviewUrl = Math.random() < 0.5 ? _GOOGLE_REVIEW_URL : _TRUST_PILOT_REVIEW_URL;
      window.open(reviewUrl, '_blank', 'menubar=1,resizable=1,width=600,height=728');
    }
    // Go back to status page
    dispatch(push(`${prefix}/${match?.params.id}`));
  });

  React.useEffect(() => {
    if (createReview.result.error) {
      logException('Error creating a review', (updateReview.result.error as AxiosError)?.response);
    }
    if (updateReview.result.error) {
      logException('Error updating a review', (updateReview.result.error as AxiosError)?.response);
    }
  }, [Boolean(createReview.result.error), Boolean(updateReview.result.error)]);

  return (
    <SingleViewWrapper>
      <Container fluid={true}>
        <SectionWrapper sectionStyle={'white'}>
          <Heading size={2} extraClasses="flex flex--c pt-0-5">
            <Translate id="request_flow.review.title_review" />
          </Heading>
          <p>
            <Translate id="request_flow.review.body_review" />
          </p>

          {/* FIRST PART */}
          {/* No review has been created yet, so only show the first form. */}
          {!reviewId && (
            <div className="review-form">
              <div>
                <b style={{ marginRight: 6 }}>
                  <Translate id={`request_flow.review.${Field.PUNCTUALITY}`} />:
                </b>
                <Rating
                  emptySymbol={<IconStar full={false} />}
                  fullSymbol={<IconStar full={true} />}
                  onChange={rating => requiredForm.set({ [Field.PUNCTUALITY]: rating })}
                  initialRating={requiredForm.data[Field.PUNCTUALITY].value}
                />
              </div>
              <div>
                <b style={{ marginRight: 6 }}>
                  <Translate id={`request_flow.review.${Field.COMMUNICATION}`} />:
                </b>
                <Rating
                  emptySymbol={<IconStar full={false} />}
                  fullSymbol={<IconStar full={true} />}
                  onChange={rating => requiredForm.set({ [Field.COMMUNICATION]: rating })}
                  initialRating={requiredForm.data[Field.COMMUNICATION].value}
                />
              </div>
              <div>
                <b style={{ marginRight: 6 }}>
                  {/* This translation key is under kindness instead of friendliness! */}
                  <Translate id={`request_flow.review.kindness`} />:
                </b>
                <Rating
                  emptySymbol={<IconStar full={false} />}
                  fullSymbol={<IconStar full={true} />}
                  onChange={rating => requiredForm.set({ [Field.FRIENDLINESS]: rating })}
                  initialRating={requiredForm.data[Field.FRIENDLINESS].value}
                />
              </div>
              <div className="pb-0-5">
                <b>
                  <Translate id={`request_flow.review.${Field.PUBLIC_COMMENT}`} />:
                </b>
                <br />
                <textarea onChange={e => requiredForm.set({ [Field.PUBLIC_COMMENT]: e.target.value })} />
              </div>
              <div className="btn-wrapper btn-wrapper--centered">
                {createReview.result.error && (
                  <FlashMessage type="error">
                    <Translate id="request_flow.errors.fill_in_inputs" />
                  </FlashMessage>
                )}
                <Button
                  buttonStyle="secondary"
                  loading={createReview.result.loading}
                  disabled={
                    requiredForm.hasErrors ||
                    createReview.result.loading ||
                    transportRequest.loading ||
                    stopContactDetails.loading
                  }
                  onClick={() => {
                    createReview.reset();
                    createReview.invoke({
                      // The delivery IRI can be derived from one of two network requests...
                      // ...depending on the type of user viewing the page (customer or stop contact).
                      delivery:
                        transportRequest.result?.deliveries?.[0]?.['@id'] ||
                        stopContactDetails.result?.['hydra:member'][0]?.['@id'],
                      communication: requiredForm.data[Field.COMMUNICATION].value,
                      friendliness: requiredForm.data[Field.FRIENDLINESS].value,
                      punctuality: requiredForm.data[Field.PUNCTUALITY].value,
                      body: requiredForm.data[Field.PUBLIC_COMMENT].value,
                    });
                  }}
                >
                  <Translate id="request_flow.review.send" />
                </Button>
              </div>
            </div>
          )}

          {/* SECOND PART */}
          {/* Here, a review has already been created (hence we have an ID in state) and some
          optional fields are shown, which will be used to update the existing review. */}
          {reviewId && (
            <div className="review-form">
              <div className="pb-0-5 private-review">
                <p>
                  <b>
                    <Translate id="profile_page.review.private_notes.title" />
                  </b>
                  <br />
                  <Translate id="profile_page.review.private_notes.description" />
                </p>
                <ul>
                  {/* ARRIVAL MESSAGE CHECKBOX */}
                  <li>
                    <input
                      type="checkbox"
                      name={Field.ARRIVAL_MESSAGE}
                      // The question is posed as a negative, so checked means false.
                      checked={!optionalForm.data.arrivalMessage.value}
                      onChange={e => {
                        optionalForm.set({
                          arrivalMessage: !e.target.checked,
                          all_services_delivered: false,
                        });
                      }}
                    />
                    <label htmlFor={Field.ARRIVAL_MESSAGE}>
                      <Translate id="request_flow.review.private.arrival_message" />
                    </label>
                  </li>
                  {/* BRANDED CLOTHING CHECKBOX */}
                  <li>
                    <input
                      type="checkbox"
                      name={Field.BRANDED_CLOTHING}
                      // The question is posed as a negative, so checked means false.
                      checked={!optionalForm.data.brandedClothing.value}
                      onChange={e => {
                        optionalForm.set({
                          brandedClothing: !e.target.checked,
                          all_services_delivered: false,
                        });
                      }}
                    />
                    <label htmlFor={Field.BRANDED_CLOTHING}>
                      <Translate id="request_flow.review.private.branded_clothing" />
                    </label>
                  </li>
                  {/* IDENTIFIED CHECKBOX */}
                  <li>
                    <input
                      type="checkbox"
                      name={Field.IDENTIFIED}
                      // The question is posed as a negative, so checked means false.
                      checked={!optionalForm.data.identified.value}
                      onChange={e => {
                        optionalForm.set({
                          identified: !e.target.checked,
                          all_services_delivered: false,
                        });
                      }}
                    />
                    <label htmlFor={Field.IDENTIFIED}>
                      <Translate id="request_flow.review.private.identified" />
                    </label>
                  </li>
                  {/* MOVING SUPPLIES CHECKBOX */}
                  <li>
                    <input
                      type="checkbox"
                      name={Field.MOVING_SUPPLIES}
                      // The question is posed as a negative, so checked means false.
                      checked={!optionalForm.data.movingSupplies.value}
                      onChange={e => {
                        optionalForm.set({
                          movingSupplies: !e.target.checked,
                          all_services_delivered: false,
                        });
                      }}
                    />
                    <label htmlFor={Field.MOVING_SUPPLIES}>
                      <Translate id="request_flow.review.private.moving_supplies" />
                    </label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      name={Field.ALL_SERVICES_DELIVERED}
                      checked={optionalForm.data.all_services_delivered.value}
                      onChange={e => {
                        // This unchecks any of the negative statements and makes them all true.
                        optionalForm.set({
                          arrivalMessage: true,
                          brandedClothing: true,
                          identified: true,
                          movingSupplies: true,
                          all_services_delivered: e.target.checked,
                        });
                      }}
                    />
                    <label htmlFor={Field.ALL_SERVICES_DELIVERED}>
                      <Translate id={`request_flow.review.private.all_services_delivered`} />
                    </label>
                  </li>
                </ul>
                <b>
                  <Translate id={`profile_page.review.private_notes.${Field.PRIVATE_NOTE}`} />:
                </b>
                <br />
                <textarea onChange={e => optionalForm.set({ note: e.target.value })} />
              </div>
              <div className="btn-wrapper btn-wrapper--centered">
                {updateReview.result.error && (
                  <FlashMessage type="error">
                    <Translate id="request_flow.errors.handle_request" />
                  </FlashMessage>
                )}
                <Button
                  buttonStyle="secondary"
                  loading={updateReview.result.loading}
                  disabled={
                    // Disable the submit button if:
                    // 1) the update review mutation is in flight, or
                    updateReview.result.loading ||
                    // 2) nothing from the initial state of the form has changed.
                    (optionalForm.data.arrivalMessage.value === optionalInitialState.arrivalMessage &&
                      optionalForm.data.brandedClothing.value === optionalInitialState.brandedClothing &&
                      optionalForm.data.identified.value === optionalInitialState.identified &&
                      optionalForm.data.movingSupplies.value === optionalInitialState.movingSupplies &&
                      optionalForm.data.all_services_delivered.value === optionalInitialState.all_services_delivered)
                  }
                  onClick={() => {
                    updateReview.reset();
                    updateReview.invoke({
                      id: reviewId,
                      private_note_to_brenger: optionalForm.data.note.value,
                      arrival_message: optionalForm.data.arrivalMessage.value,
                      branded_clothing: optionalForm.data.brandedClothing.value,
                      identified: optionalForm.data.identified.value,
                      moving_supplies: optionalForm.data.movingSupplies.value,
                    });
                  }}
                >
                  <Translate id="request_flow.review.send" />
                </Button>
              </div>
            </div>
          )}
        </SectionWrapper>
      </Container>
    </SingleViewWrapper>
  );
};

import { DatetimePeriod, StopType } from '../typings';
import _get from 'lodash/get';

export const getCommitmentByStopId = (
  transportJobAccountLinkResponse,
  transportRequestResponse,
  type: StopType
): DatetimePeriod[] => {
  const commitments = _get(transportJobAccountLinkResponse, 'data["hydra:member"][0].' + type + '_commitments', []);
  const firstStop = type === StopType.pickup ? 'pickups[0][@id]' : 'deliveries[0][@id]';
  const commitmentsByStopId = commitments.filter(
    commitment => commitment[type] === _get(transportRequestResponse, firstStop)
  );
  if (commitmentsByStopId.length > 0) {
    return [commitmentsByStopId[0].committed_datetime_period];
  }
  return [];
};

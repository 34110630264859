import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { change } from 'redux-form';

import {
  actions as flowActions,
  getIsTransportToOrFromSpecificCountry,
  getIsTransportWithinSameCountry,
  getToPaymentButtonTranslation,
  isLoading,
  isSubmitDisabled,
} from '../ducks';
import {
  Button,
  Col,
  Container,
  IconArrow,
  IconSmile,
  PriceDisplay,
  Row,
  SheetLayoutFooter,
} from '../../../brenger-shared-ui';
import '../../../assets/general-flow/footer.css';
import { NotGuaranteedNotification } from '../components/notifications/NotGuaranteedNotification';
import { ContactNotification } from '../components/notifications/ContactNotification';
import { GeneralFlowForms } from '../interface';
import SamePickupAsDeliveryToast from '../components/notifications/SamePickupAsDelivery';
import ExampleProductPrice from '../components/notifications/ExampleProductPrice';
import { BelgiumNotification } from '../components/notifications/BelgiumNotification';
import { RootState } from '../../../typings';
import { MarktplaatsNotification } from '../components/notifications/MarktplaatsNotification';
import _get from 'lodash/get';
import { CoronaNotification } from '../components/notifications/CoronaNotification';
import { DutchSpecialPrice } from '../components/notifications/DutchSpecialPrice';
import { WeightNotification } from '../components/notifications/WeightNotification';
import { ExtraCareNotification } from '../components/notifications/ExtraCare';

interface OwnProps {
  customSubmitFunction?: (event: any) => void;
}

type Props = OwnProps & ReduxType;

const FooterLayout: React.FC<Props> = props => {
  const hasSeenBelgiumBlock = sessionStorage.getItem('to-belgium-block-message');
  const [showBelgianBlock, setShowBelgianBlock] = React.useState(true);
  const hasSeenBelgiumNotguaranteed = sessionStorage.getItem('belgium-not-guaranteed-message');
  const [showBelgianNotguaranteed, setShowBelgianNotguaranteed] = React.useState(true);
  // set up visibility states
  const isSamePickupTimeAsDelivery = props.transport.layout.step === 5 && props.form?.values?.setPickupToDeliveryTime;
  const isExamplePriceToastOpen = props.transport.layout.step === 1 && props.transport.examples.isOpen;
  const isBelgiumNotGuaranteedVisible =
    props.isBelgiumTransport && showBelgianNotguaranteed && !hasSeenBelgiumNotguaranteed;
  const isBelgiumBlockVisible = props.isTransportToBelgium && showBelgianBlock && !hasSeenBelgiumBlock;
  /* AB TEST: change when people see price */
  const showPrice = document.body.classList.contains('show-price-later') ? props.transport.layout.step > 3 : true;

  return (
    <SheetLayoutFooter>
      <Container>
        {props.transport.notifications.visible && (
          <div className={`footer-notification-bar ${props.transport.notifications.type}-notification`}>
            <p dangerouslySetInnerHTML={{ __html: props.transport.notifications.message }} />
            <span onClick={props.hideNotification}>x</span>
          </div>
        )}
        <div className={'relative'}>
          {/* show notification when the transport job is not guaranteed */}
          <NotGuaranteedNotification />
          {/* show notification when we set pickup time to delivery time */}
          <SamePickupAsDeliveryToast isOpen={isSamePickupTimeAsDelivery} close={props.setPickupToDeliveryTime} />
          {/* show notification on contact page to tell the user that the price he sees is limited to this day - ABTested */}
          <ContactNotification />
          {/* Show notification to marktplaat users at items step, we ask if likes to send a mail to the marktplaats user, to ask for more info */}
          <MarktplaatsNotification />
          {/* Show a notification about potentially needing extra help or a tailgate for certain weight ranges */}
          <WeightNotification />
          <CoronaNotification />
          <BelgiumNotification
            visible={isBelgiumNotGuaranteedVisible}
            handleClose={() => {
              sessionStorage.setItem('belgium-not-guaranteed-message', 'true');
              setShowBelgianNotguaranteed(false);
            }}
          />
          <BelgiumNotification
            toBelgium={true}
            visible={isBelgiumBlockVisible}
            handleClose={() => {
              sessionStorage.setItem('to-belgium-block-message', 'true');
              setShowBelgianBlock(false);
            }}
          />
          <DutchSpecialPrice />
          {/* Example product price toast notification in items step */}
          <ExampleProductPrice
            isOpen={isExamplePriceToastOpen}
            examples={props.transport.examples}
            close={props.toggleExamples}
          />
          <ExtraCareNotification />
        </div>
        <Row>
          {/* error notification when we have failure price api call */}
          <Col xs={5} sm={6} extraClasses={'footer-action-bar hidden--lg-up'}>
            {/* AB TEST: change when people see price */}
            {showPrice && (
              <div onClick={props.toggleSheet}>
                <PriceDisplay
                  failedAttempts={props.transport.price.failedAttempts}
                  loading={props.transport.price.isLoading}
                  header={
                    <div>
                      <IconSmile />
                      <Translate id={'request_flow.price.heading'} />{' '}
                    </div>
                  }
                  cents={props.transport.price.amount}
                />
              </div>
            )}
          </Col>
          <Col xs={7} sm={6} extraClasses={'offset-lg-6'}>
            {props.customSubmitFunction ? (
              <div
                className={'btn btn--secondary btn--icon-right btn--full-width'}
                onClick={props.customSubmitFunction}
              >
                <Translate id={props.nextButtonTrans} />
                <IconArrow />
              </div>
            ) : (
              <Button
                loading={props.isLoading}
                disabled={props.isSubmitDisabled}
                buttonStyle={'secondary'}
                iconPos={'right'}
                fullWidth={true}
              >
                <Translate id={props.nextButtonTrans} />
                <IconArrow />
              </Button>
            )}
          </Col>
        </Row>
      </Container>
    </SheetLayoutFooter>
  );
};

const mapStateToProps = (state: RootState) => ({
  transport: state.generalTransport,
  form: state.form[GeneralFlowForms.delivery],
  isLoading: isLoading(state),
  nextButtonTrans: getToPaymentButtonTranslation(state),
  isSubmitDisabled: isSubmitDisabled(state),
  isTransportToBelgium: getIsTransportToOrFromSpecificCountry(state, 'BE'),
  isBelgiumTransport: getIsTransportWithinSameCountry(state, 'BE'),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleSheet: () => dispatch(flowActions.toggleSheet()),
  hideNotification: () => dispatch(flowActions.hideNotification()),
  toggleExamples: () => dispatch(flowActions.setExampleOpen(false)),
  setPickupToDeliveryTime: () => dispatch(change(GeneralFlowForms.delivery, 'setPickupToDeliveryTime', false)),
});

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(FooterLayout);

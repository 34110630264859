import _get from 'lodash/get';
import { IDEAL_ISSUERS_LIST, sortIssuers } from '../../utils/payment';
import { getWhatIsMyCountry } from '../User/ducks';
import * as appUtils from '../../utils/basics';
import { RootState } from '../../typings/interfaces';
import { Payment, PaymentMethod } from './typings';

// init state
export const defaultState: Payment = {
  methods: [],
  paymentInfo: {
    id: null,
    transport_request: null,
    amount: null,
    state: null,
    polling: false,
    error: null,
    isSubmitting: false,
  },
};

// types
export const types = {
  SET_PAYMENT_METHODS: 'SET_PAYMENT_METHODS',
  PUSH_NOTIFICATION: 'PUSH_NOTIFICATION',
  SUBMIT_PAYMENT_START: 'SUBMIT_PAYMENT_START',
  SUBMIT_PAYMENT_RETRY_START: 'SUBMIT_PAYMENT_RETRY_START',
  SUBMIT_PAYMENT_STOP: 'SUBMIT_PAYMENT_STOP',
  SET_PAYMENT_INFO_ID: 'SET_PAYMENT_INFO_ID',
  POLL_PAYMENT_START: 'POLL_PAYMENT_START',
  POLL_PAYMENT_ERROR: 'POLL_PAYMENT_ERROR',
  POLL_PAYMENT_SUCCESS: 'POLL_PAYMENT_SUCCESS',
};

// actions
export const actions = {
  pushNotification: message => ({ type: types.PUSH_NOTIFICATION, payload: message }),
  submitPayment: payment => ({ type: types.SUBMIT_PAYMENT_START, payload: payment }),
  submitPaymentRetry: payment => ({ type: types.SUBMIT_PAYMENT_RETRY_START, payload: payment }),
  setPaymentMethods: methods => ({ type: types.SET_PAYMENT_METHODS, payload: methods }),
  stopPaymentRequest: () => ({ type: types.SUBMIT_PAYMENT_STOP }),
  setPaymentInfoId: paymentId => ({ type: types.SET_PAYMENT_INFO_ID, payload: paymentId }),
  pollPaymentStatusStart: () => ({ type: types.POLL_PAYMENT_START }),
  pollPaymentStatusError: error => ({ type: types.POLL_PAYMENT_ERROR, payload: error }),
  pollPaymentStatusSuccess: paymentInfo => ({ type: types.POLL_PAYMENT_SUCCESS, payload: paymentInfo }),
};

// selectors
export const getPaymentMethods = (state: RootState): PaymentMethod[] => {
  let methods = state.payment?.methods || [];
  // specify payment methods order for belgium customers
  if (getWhatIsMyCountry(state) === 'BE') {
    methods.forEach((m, i) => {
      const mollieId = _get(m, 'mollie_id', '');
      if (mollieId === 'bancontact') {
        methods = appUtils.swapArray(methods, i, 0);
      }
      if (mollieId === 'kbc') {
        methods = appUtils.swapArray(methods, i, 1);
      }
      if (mollieId === 'belfius') {
        methods = appUtils.swapArray(methods, i, 2);
      }
      if (mollieId === 'creditcard') {
        methods = appUtils.swapArray(methods, i, 3);
      }
      if (mollieId === 'sofort') {
        methods = appUtils.swapArray(methods, i, 4);
      }
    });
    // shift ideal to end of the list
    const idealIdx = methods.findIndex(method => method.mollie_id === 'ideal');
    methods = appUtils.swapArray(methods, idealIdx, methods.length - 1);
  }
  methods = methods.filter(option => option);
  return methods;
};

export const getPaymentInfo = (state: RootState) => {
  return state.payment.paymentInfo;
};

// reducers
export const paymentReducer = (state: Payment = defaultState, action): Payment => {
  switch (action.type) {
    case types.PUSH_NOTIFICATION:
      return {
        ...state,
        paymentInfo: {
          ...state.paymentInfo,
          error: action.payload,
        },
      };
    case types.SET_PAYMENT_INFO_ID:
      return {
        ...state,
        paymentInfo: {
          ...state.paymentInfo,
          id: action.payload,
        },
      };
    case types.POLL_PAYMENT_START:
      return {
        ...state,
        paymentInfo: {
          ...state.paymentInfo,
          polling: true,
        },
      };
    case types.POLL_PAYMENT_ERROR:
      return {
        ...state,
        paymentInfo: {
          ...state.paymentInfo,
          polling: false,
          error: action.payload,
        },
      };
    case types.POLL_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentInfo: {
          ...state.paymentInfo,
          amount: action.payload.amount,
          transport_request: action.payload.transport_request,
          state: action.payload.state,
          polling: action.payload.polling,
          error: null,
        },
      };
    case types.SUBMIT_PAYMENT_RETRY_START:
      return {
        ...state,
        paymentInfo: {
          ...state.paymentInfo,
          isSubmitting: true,
        },
      };
    case types.SET_PAYMENT_METHODS:
      let methods = action.payload;
      // sorting methods & issuers
      const idealIndex = methods.findIndex(x => x.mollie_id === 'ideal');
      methods.splice(0, 0, methods.splice(idealIndex, 1)[0]);

      const creditIndex = methods.findIndex(x => x.mollie_id === 'creditcard');
      methods.splice(1, 0, methods.splice(creditIndex, 1)[0]);

      // sort ideal issuers
      methods = sortIssuers(methods, 'ideal', IDEAL_ISSUERS_LIST);

      return {
        ...state,
        methods,
      };
    case types.SUBMIT_PAYMENT_START:
      return {
        ...state,
        paymentInfo: {
          ...state.paymentInfo,
          isSubmitting: true,
        },
      };
    case types.SUBMIT_PAYMENT_STOP:
      return {
        ...state,
        paymentInfo: {
          ...state.paymentInfo,
          isSubmitting: false,
        },
      };
    default:
      return state;
  }
};

export default paymentReducer;

import React from 'react';
import { connect } from 'react-redux';
import { Container, Heading, Pagination, SectionWrapper } from '../../../brenger-shared-ui';
import { push } from 'connected-react-router';
import { prefix } from '../index';
import { Translate } from 'react-localize-redux';
import { getNewTransportRequestLink, hasBusinessFlow } from '../../Business/ducks';
import { actions } from '../ducks';
import { RootState } from '../../../typings';
import { Dispatch } from 'redux';
import { TransportRequestList } from './TransportRequestList';
import Filters from './Filters';
import classnames from 'classnames';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const Overview: React.FC<Props> = props => {
  const {
    overviewState,
    currentPage,
    newTrLink,
    hasDedicatedBusinessFlow,
    fetchPage,
    goToPath,
    openSingleView,
    accountTotalTrs,
    getTransportRequests,
    resetFilters,
  } = props;
  React.useEffect(() => {
    if (accountTotalTrs > 0) {
      return;
    }
    resetFilters();
    getTransportRequests();
  }, []);
  return (
    <Container fluid={true}>
      <SectionWrapper sectionStyle={'white'} extraClasses={classnames({ 'tr-overview-loaded': accountTotalTrs > 0 })}>
        <Heading extraClasses={'text--center'} size={2}>
          <Translate id={'request_flow.status.nav.title' + (hasDedicatedBusinessFlow ? '_business' : '')} />
        </Heading>
        <div className="results-filters--wrapper">
          {accountTotalTrs > 0 && <Filters />}

          <div className="results">
            <TransportRequestList
              list={overviewState}
              openSingleView={openSingleView}
              startNewTr={() => goToPath(newTrLink)}
              showButton={accountTotalTrs > 0}
            />
            <Pagination onClick={fetchPage} limit={30} total={overviewState.total} currentPage={currentPage} />
          </div>
        </div>
      </SectionWrapper>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  overviewState: state.statusPage.overview_list,
  accountTotalTrs: state.statusPage.total_number_trs,
  currentPage: state.statusPage.currentPage,
  newTrLink: getNewTransportRequestLink(state),
  hasDedicatedBusinessFlow: hasBusinessFlow(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchPage: (page: number) => dispatch(actions.fetchFilteredResultPage(page)),
  resetFilters: () => dispatch(actions.resetFilters()),
  goToPath: (path: string) => dispatch(push(path)),
  openSingleView: (id: string) => dispatch(push(`${prefix}/${id}`)),
  getTransportRequests: () => dispatch(actions.setFilters([], true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);

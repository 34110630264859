import * as React from 'react';
import '../input/input.css';
import './textarea.css';
import InputWrapper from '../input/InputWrapper';
import InputFeedback from '../input/InputFeedback';
import { InputProps } from '../input/Input';

const Textarea = ({ input, meta, label, type, successMessage }: InputProps): any => (
  <InputWrapper input={...input} meta={...meta} type={'textarea'}>
    <textarea
      id={input.name}
      defaultValue={input.value}
      onBlur={input.onBlur}
      onChange={input.onChange}
      onFocus={input.onFocus}
    />
    <label
      className={
        'input-el--label' +
        (typeof input.required !== 'undefined' && input.required === true ? ' input-el--label--required' : '')
      }
      htmlFor={input.name}
    >
      {label}
    </label>
    <InputFeedback meta={...meta} error={meta.error} successMessage={successMessage} />
  </InputWrapper>
);

export default Textarea;

import * as React from 'react';
import './icons.css';

const IconCirclePerson = () => {
  return (
    <svg width="46" height="46" viewBox="0 0 46 46">
      <g fill="none" fillRule="evenodd" transform="translate(0 -2)">
        <circle cx="23" cy="25" r="23" fill="#A2CDFF" />
        <g fill="#005FCE">
          <path d="M14.105 35.078h16.592v-2.97c0-3.794-3.004-6.881-6.695-6.881H20.8c-3.692 0-6.695 3.087-6.695 6.881v2.97zm17.144 1.136H13.553a.56.56 0 0 1-.553-.568v-3.538c0-4.42 3.5-8.017 7.8-8.017h3.202c4.3 0 7.8 3.597 7.8 8.017v3.538a.56.56 0 0 1-.553.568z" />
          <path d="M17.685 36.214a.56.56 0 0 1-.553-.568v-5.493a.56.56 0 0 1 .553-.568.56.56 0 0 1 .552.568v5.493a.56.56 0 0 1-.552.568M27.7 36.214a.56.56 0 0 1-.552-.568v-5.493a.56.56 0 0 1 .553-.568.56.56 0 0 1 .552.568v5.493a.56.56 0 0 1-.552.568M24.493 24.778L23.31 27.21a.663.663 0 0 1-1.199 0l-1.183-2.432h3.565zM29.208 17.035a.556.556 0 0 1-.542-.465c-.54-3.014-3.1-5.202-6.085-5.202-2.986 0-5.545 2.188-6.085 5.202a.555.555 0 0 1-.643.456.567.567 0 0 1-.444-.661c.637-3.553 3.653-6.133 7.172-6.133 3.519 0 6.535 2.58 7.171 6.133a.567.567 0 0 1-.544.67" />
          <path d="M22.58 25.227c-3.518 0-6.534-2.58-7.17-6.132a.567.567 0 0 1 .443-.662.554.554 0 0 1 .643.456c.54 3.014 3.099 5.202 6.085 5.202 2.986 0 5.545-2.188 6.085-5.202a.554.554 0 0 1 .643-.456c.3.057.499.353.443.662-.636 3.553-3.652 6.132-7.171 6.132" />
        </g>
      </g>
    </svg>
  );
};

export default IconCirclePerson;

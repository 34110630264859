import { Currencies, NotGuaranteedReason, Nullable, PriceDataPart, RootState } from '../../typings';
import { DatetimePeriod, NotGuaranteedUpdate } from '../../typings/interfaces';
import {
  IkeaFlowFields,
  IkeaFlowForms,
  IKEAItemSet,
  IKEAItemSetAttributes,
  IKEAStoreData,
  IKEATransport,
  WarehouseOption,
} from './interface';
import { _IKEA_TIME_PICKUP_END, _IKEA_TIME_PICKUP_START } from '../../utils/global';
import {
  getCountryIri,
  getExternalPartyIri,
  getShouldThisFieldUploadTwice,
  getUploadedFileDataByFormAndFieldName,
} from '../../state/ducks/baseReducer';
import moment, { Moment } from 'moment-timezone';

import { StoreLocations } from './global';
import { MODULE_PREFIX } from './index';
import _get from 'lodash/get';
import { getFormValues } from 'redux-form';
import { isItemValid } from '../GeneralFlow/ducks';
import { translate } from '../../utils/localization';

// init state
export const defaultState: IKEATransport = {
  request: {
    errors: null,
    warnings: '',
    step: 1,
    submitting: false,
    uuid: null,
    not_guaranteed: {
      [NotGuaranteedReason.DISTANCE]: false,
      [NotGuaranteedReason.HEAVY]: false,
      latestReason: '',
    },
  },
};

// types
export const types = {
  SET_PROGRESS_STEP: `${MODULE_PREFIX}/SET_PROGRESS_STEP`,
  SUBMIT_PICKUP_START: `${MODULE_PREFIX}/SUBMIT_PICKUP_START`,
  SUBMIT_PICKUP_OPTIONS_START: `${MODULE_PREFIX}/SUBMIT_PICKUP_OPTIONS_START`,
  SUBMIT_DELIVERY_START: `${MODULE_PREFIX}/SUBMIT_DELIVERY_START`,
  SUBMIT_DELIVERY_STOP: `${MODULE_PREFIX}/SUBMIT_DELIVERY_STOP`,
  PUSH_NOTIFICATION: `${MODULE_PREFIX}/PUSH_NOTIFICATION`,
  PUSH_WARNING: `${MODULE_PREFIX}/PUSH_WARNING`,
  SET_NOT_GUARANTEED: `${MODULE_PREFIX}/SET_NOT_GUARANTEED`,
  RESET_FORMS: `${MODULE_PREFIX}/RESET_FORMS`,
};

// actions
export const actions = {
  setProgress: (step: number) => ({ type: types.SET_PROGRESS_STEP, payload: step }),
  submitPickupDetails: pickup => ({ type: types.SUBMIT_PICKUP_START, payload: pickup }),
  submitPickupOptions: () => ({ type: types.SUBMIT_PICKUP_OPTIONS_START }),
  submitDeliveryDetails: delivery => ({ type: types.SUBMIT_DELIVERY_START, payload: delivery }),
  submitDeliveryStop: delivery => ({ type: types.SUBMIT_DELIVERY_STOP, payload: delivery }),
  pushNotification: (message: string) => ({ type: types.PUSH_NOTIFICATION, payload: message }),
  pushWarning: (message: string) => ({ type: types.PUSH_WARNING, payload: message }),
  setNotGuaranteed: (notGuaranteedUpdate: NotGuaranteedUpdate) => ({
    type: types.SET_NOT_GUARANTEED,
    payload: notGuaranteedUpdate,
  }),
  resetForms: () => ({ type: types.RESET_FORMS }),
};

export const getIkeaTransportRequest = (state: RootState): IKEATransport => {
  return state.ikeaTransport;
};

export const getSelectedStoreData = (state: RootState): Nullable<IKEAStoreData> => {
  const formValues = getFormValues(IkeaFlowForms.PICKUP_DETAILS)(state);
  if (!formValues) {
    return null;
  }
  const selectedStore: string = _get(formValues, IkeaFlowFields.PICKUP_LOCATION, '');
  const selectedStoreData = StoreLocations.find((store: IKEAStoreData) => {
    return selectedStore === store.storeName;
  });
  if (!selectedStoreData) {
    return null;
  }
  return selectedStoreData;
};

export const getSelectedStoreAddress = (state: RootState): string => {
  const selectedStoreData = getSelectedStoreData(state);
  if (!selectedStoreData) {
    return '';
  }

  return `${selectedStoreData.address.line2}, ${selectedStoreData.address.line1}, ${selectedStoreData.address.postal_code}, ${selectedStoreData.address.locality}`;
};

export const getTransportPrice = (state: RootState): PriceDataPart => {
  const selectedStoreData = getSelectedStoreData(state);
  const amount: number = selectedStoreData ? selectedStoreData.conditions.price : 0;
  return {
    amount,
    currency: Currencies.EUR,
  };
};

export const getContactDetails = (state: RootState) => {
  const delivery = getFormValues(IkeaFlowForms.DELIVERY)(state);
  const address = delivery.address;
  return {
    ...delivery.contact,
    address,
  };
};

export const getStoreSpecificDate = (state: RootState): Moment => {
  const selectedStoreData = getSelectedStoreData(state);
  if (!selectedStoreData) {
    return moment();
  }
  const startToCountFromThisDay: Moment = moment();
  if (
    !startToCountFromThisDay.isBefore(
      moment().set({ hour: selectedStoreData.conditions.delivery.order_deadline, minute: 0, second: 0 })
    )
  ) {
    startToCountFromThisDay.add(1, 'day');
  }
  return startToCountFromThisDay.add(selectedStoreData.conditions.delivery.InNumberOfDays, 'day');
};

export const getStoreSpecificOrSelectedDate = (state: RootState): Moment => {
  const selectedStoreData = getSelectedStoreData(state);
  let dateTime = getStoreSpecificDate(state);
  // are we dealing with a selectable date?
  if (selectedStoreData?.conditions?.selectableDates) {
    const formValues = getFormValues(IkeaFlowForms.DATE)(state);
    // When date is available
    if (formValues && formValues[IkeaFlowFields.DATE]) {
      dateTime = moment(formValues[IkeaFlowFields.DATE]);
    }
  }
  return dateTime;
};

export const getPickupDatetimePeriods = (state: RootState): DatetimePeriod[] => {
  const selectedStoreData = getSelectedStoreData(state);
  const datetime = getStoreSpecificOrSelectedDate(state);

  const startTime = !selectedStoreData ? _IKEA_TIME_PICKUP_START : selectedStoreData.conditions.pickup.start_time;
  const endTime = !selectedStoreData ? _IKEA_TIME_PICKUP_END : selectedStoreData.conditions.pickup.end_time;
  return [
    {
      start: datetime.set({ hour: startTime, minute: 0, second: 0 }).toDate(),
      end: datetime.set({ hour: endTime, minute: 0, second: 0 }).toDate(),
    },
  ];
};

export const getDeliveryDatetimePeriods = (state: RootState): DatetimePeriod[] => {
  const datetime = getStoreSpecificOrSelectedDate(state);
  const selectedStoreData = getSelectedStoreData(state);
  return [
    {
      start: !selectedStoreData
        ? moment().toDate()
        : datetime
            .set({ hour: selectedStoreData.conditions.delivery.start_time, minute: 0, second: 0, millisecond: 0 })
            .toDate(),
      end: !selectedStoreData
        ? moment().toDate()
        : datetime
            .set({ hour: selectedStoreData.conditions.delivery.end_time, minute: 0, second: 0, millisecond: 0 })
            .toDate(),
    },
  ];
};

export const getDeliveryDetails = (state: RootState) => {
  const delivery = getFormValues(IkeaFlowForms.DELIVERY)(state);
  if (!delivery) {
    return null;
  }
  const selectedStoreData = getSelectedStoreData(state);
  if (!selectedStoreData) {
    return null;
  }
  const address = {
    ...delivery.address,
    country: getCountryIri(state, delivery.address.country_code),
  };
  delete address.country_code;
  return {
    address,
    contact: delivery.contact,
    available_datetime_periods: getDeliveryDatetimePeriods(state),
    details: {
      situation: 'home',
      extras: [],
      instructions: _get(delivery, 'address.instructions', null),
      carrying_help: selectedStoreData.conditions.delivery.carrying_help,
      floor_level: selectedStoreData.conditions.delivery.floor_level,
    },
  };
};

export const getPickupDetails = (state: RootState) => {
  const selectedStoreData = getSelectedStoreData(state);
  if (!selectedStoreData) {
    return null;
  }

  const datetime = getPickupDatetimePeriods(state);
  return {
    address: {
      ...selectedStoreData.address,
      country: getCountryIri(state, selectedStoreData.address.country_code),
      country_code: undefined,
    },
    contact: {
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
    },
    available_datetime_periods: datetime,
    details: {
      situation: 'store',
      extras: [],
      instructions: '',
      carrying_help: selectedStoreData.conditions.pickup.carrying_help,
      floor_level: selectedStoreData.conditions.pickup.floor_level,
    },
  };
};

export const getItemSets = (state: RootState): IKEAItemSet[] => {
  const pickupOptionsValues = getFormValues(IkeaFlowForms.PICKUP_OPTIONS)(state);
  if (pickupOptionsValues === null) {
    return [];
  }

  // Shared itemSet setup for internal/external
  const itemSetCommon = {
    external_party: getExternalPartyIri(state, 'ikea'),
    title: '',
    description: '',
  };
  // Shared item setup for  internal/external
  const itemCommon = {
    length: 1,
    height: 1,
    width: 1,
  };

  const [isInternalOrBoth, isExternalOrBoth] = getIsInternalExternalOrBoth(state);
  const itemSets: IKEAItemSet[] = [];

  const store = getSelectedStoreData(state);

  let items = [
    {
      ...itemCommon,
      title: translate('request_flow.IKEA.item_set_title.internal'),
      count: parseInt(pickupOptionsValues[IkeaFlowFields.COUNT], 10),
      weight: 0,
    },
  ];
  if (store?.config.hasItemSetConfigurator) {
    items = pickupOptionsValues[IkeaFlowFields.ITEM_SETS][0].items.filter(isItemValid).map(item => {
      return { ...item, weight: 0 };
    });
  }

  if (isInternalOrBoth) {
    itemSets.push({
      ...itemSetCommon,
      attributes: getItemSetAttributes(state, WarehouseOption.INTERNAL),
      images: getUploadedFileDataByFormAndFieldName(state, IkeaFlowForms.PICKUP_OPTIONS, IkeaFlowFields.IMAGE, '@id'),
      external_invoices: getUploadedFileDataByFormAndFieldName(
        state,
        IkeaFlowForms.PICKUP_DETAILS,
        IkeaFlowFields.RECEIPT,
        '@id'
      ),
      items,
    });
  }
  const isUploadedTwice = getShouldThisFieldUploadTwice(state, IkeaFlowForms.PICKUP_DETAILS, IkeaFlowFields.RECEIPT);
  if (isExternalOrBoth) {
    const invoices = getUploadedFileDataByFormAndFieldName(
      state,
      IkeaFlowForms.PICKUP_OPTIONS,
      IkeaFlowFields.INVOICE,
      '@id'
    );
    const receiptDup = getUploadedFileDataByFormAndFieldName(
      state,
      IkeaFlowForms.PICKUP_DETAILS,
      IkeaFlowFields.RECEIPT,
      '@id',
      isUploadedTwice
    );
    itemSets.push({
      ...itemSetCommon,
      attributes: getItemSetAttributes(state, WarehouseOption.EXTERNAL),
      external_invoices: [...receiptDup, ...invoices],
      items: [
        {
          ...itemCommon,
          title: translate('request_flow.IKEA.item_set_title.external'),
          weight: parseInt(pickupOptionsValues[IkeaFlowFields.WEIGHT], 10),
          count: parseInt(pickupOptionsValues[IkeaFlowFields.COLLIES], 10),
        },
      ],
    });
  }

  return itemSets;
};

export const getItemSetAttributes = (state: RootState, Warehouse: WarehouseOption): IKEAItemSetAttributes => {
  // get form values
  const pickupOptionsValues = getFormValues(IkeaFlowForms.PICKUP_OPTIONS)(state);
  // Not all IKEA Stores are asking for the same attributes
  // Weight and count should be stored at items (of this itemSet),
  // but we dont work with items at IKEA TR's, so we store in attributes
  if (Warehouse === WarehouseOption.INTERNAL) {
    return {
      warehouse: WarehouseOption.INTERNAL,
    };
  }

  return {
    warehouse: WarehouseOption.EXTERNAL,
    [IkeaFlowFields.COLLIES]: parseInt(pickupOptionsValues[IkeaFlowFields.COLLIES], 10),
    [IkeaFlowFields.ISELL]: pickupOptionsValues[IkeaFlowFields.ISELL],
  };
};

export const getWarehouseChoice = (state: RootState): WarehouseOption => {
  const pickupDetailsForm = getFormValues(IkeaFlowForms.PICKUP_DETAILS)(state);
  return _get(pickupDetailsForm, IkeaFlowFields.WAREHOUSE);
};

export const getIsInternalExternalOrBoth = (state: RootState): boolean[] => {
  const warehouseChoice = getWarehouseChoice(state);
  const isExternalOrBoth = [WarehouseOption.EXTERNAL, WarehouseOption.BOTH].includes(warehouseChoice);
  const isInternalOrBoth = [WarehouseOption.INTERNAL, WarehouseOption.BOTH].includes(warehouseChoice);
  return [isInternalOrBoth, isExternalOrBoth];
};

export // reducers
const ikeaTransport = (state: IKEATransport = defaultState, action) => {
  switch (action.type) {
    case types.PUSH_NOTIFICATION:
      return {
        ...state,
        request: {
          ...state.request,
          errors: action.payload,
        },
      };
    case types.PUSH_WARNING:
      return {
        ...state,
        request: {
          ...state.request,
          warnings: action.payload,
        },
      };
    case types.SET_PROGRESS_STEP:
      return {
        ...state,
        request: {
          ...state.request,
          step: action.payload,
        },
      };
    case types.SUBMIT_DELIVERY_START:
      return {
        ...state,
        request: {
          ...state.request,
          submitting: true,
          errors: null,
        },
      };
    case types.SUBMIT_DELIVERY_STOP:
      const status = action.payload.status;
      const resp = action.payload.data;
      return {
        ...state,
        request: {
          ...state.request,
          uuid: status === 201 ? resp['@id'].split('transport_requests/')[1] : null,
          submitting: false,
        },
      };
    case types.SET_NOT_GUARANTEED:
      const latestReason = action.payload.result ? action.payload.reason : '';
      return {
        ...state,
        request: {
          ...state.request,
          not_guaranteed: {
            ...state.request.not_guaranteed,
            [action.payload.reason]: action.payload.result,
            latestReason,
          },
        },
      };
    default:
      return state;
  }
};

export default ikeaTransport;

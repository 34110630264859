import * as React from 'react';
import './card.css';
import { Heading } from '../../basics/heading';
import { IconSmile } from '../../basics/icons';

interface Props {
  extraClasses?: string;
  header?: any;
  footer?: any;
  onClick?: any;
  disabled?: boolean;
  withBrengerSmile?: boolean;
  title?: string;
  children: React.ReactNode;
}

export const Card = ({
  extraClasses = '',
  header,
  footer,
  onClick,
  children,
  disabled = false,
  withBrengerSmile = false,
  title,
}: Props) => {
  return (
    <div
      className={'card col-12 ' + extraClasses + (onClick ? ' trigger' : '') + (disabled ? ' card--disabled' : '')}
      onClick={onClick}
      title={title}
    >
      {withBrengerSmile && <IconSmile />}
      {header && (
        <div className="card-header">
          <Heading size={4}>{header}</Heading>
        </div>
      )}
      <div className="card-body">{children}</div>
      {footer && <div className="card-footer">{footer}</div>}
    </div>
  );
};
export default Card;

import React from 'react';
import { Translate } from 'react-localize-redux';

import {
  CirclePerson2,
  Col,
  Heading,
  IconCheckmarkCircle,
  IconSmile,
  Row,
  SectionWrapper,
} from '../../../brenger-shared-ui';
import { translate } from '../../../utils/localization';

const BvaPaymentSuccess: React.FC = () => {
  return (
    <>
      <SectionWrapper sectionStyle={'primary'} centerContent={true}>
        <Row>
          <Col xs={12} extraClasses={'text--center thank-you-opening'}>
            <CirclePerson2 />
            <IconCheckmarkCircle />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8} extraClasses={'offset-md-2'}>
            <Row>
              <Col xs={12} extraClasses={'pt-1 pb-1'}>
                <Heading size={4}>
                  <Translate id={'request_flow.BVA.thank_you.title'} />
                </Heading>
              </Col>
              <Col xs={12}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: String(translate('request_flow.BVA.thank_you.further_steps')),
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </SectionWrapper>
      <SectionWrapper sectionStyle={'white'} extraClasses={'profile-dashboard-wrapper'} centerContent={true}>
        <Row>
          <Col xs={12} md={8} extraClasses={'offset-md-2 pb-1'}>
            <Heading size={5}>
              <Translate id={'request_flow.BVA.thank_you.sub_title'} />
            </Heading>
          </Col>
          <Col xs={12} md={8} extraClasses={'offset-md-2'}>
            <ul className={'line-list'}>
              <li className={'line-list-item'}>
                <a
                  className={'trigger line-list-item-label with-icon with-icon--right'}
                  href={'/nl/customer/partner/bva/transport_request/auction_lots_details'}
                >
                  <Translate id={'request_flow.BVA.thank_you.another_request'} />
                  <IconSmile />
                </a>
              </li>
              <li className={'line-list-item'}>
                <a className={'line-list-item-label with-icon with-icon--right'} href={'https://brenger.nl'}>
                  <Translate id={'request_flow.thank_you.back_to'} data={{ linkname: 'Brenger' }} />
                  <IconSmile />
                </a>
              </li>
              <li className={'line-list-item'}>
                <a className={'line-list-item-label with-icon with-icon--right'} href={'https://www.bva-auctions.com'}>
                  <Translate id={'request_flow.thank_you.back_to'} data={{ linkname: 'BVA Auctions' }} />
                  <IconSmile />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </SectionWrapper>
    </>
  );
};

export default BvaPaymentSuccess;

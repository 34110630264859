import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-sage';
import { getLocation, push } from 'connected-react-router';
import { Translate } from 'react-localize-redux';

import { coreClient } from '../../../utils/request';
import { useModalState, useTypedSelector } from '../../../hooks';
import { actions } from '../ducks';
import { Button, FlashMessage, Modal } from '../../../brenger-shared-ui';
import { _CUSTOMER_SUPPORT_EMAIL, _CUSTOMER_SUPPORT_LINK, _CUSTOMER_SUPPORT_PHONE_NUMBER } from '../../../utils/global';

import '../../../assets/status-page/opening.css';

const ConfirmDeliveryModal: React.FC<{ deliveryId: string; openCustomerCareModal(): void }> = props => {
  const dispatch = useDispatch();
  const { pathname } = useTypedSelector(getLocation);
  const confirmDelivery = useMutation(coreClient.deliveries.confirmDeliveryByCustomer, () => {
    // On success of this mutation: 1) redirect to review form and 2) close the modal
    dispatch(push(`${pathname}/review`));
    dispatch(actions.setConfirmOpen(false));
  });

  return (
    <Modal
      header={<Translate id="request_flow.review.title_confirm" />}
      open={true}
      onClick={() => dispatch(actions.setConfirmOpen(false))}
    >
      <p>
        <Translate id="request_flow.review.body_confirm" />
      </p>
      <div className="btn-wrapper">
        {confirmDelivery.result.error && (
          <FlashMessage type="error">
            <Translate id="request_flow.errors.confirm_delivery_failed" />
          </FlashMessage>
        )}
        <Button buttonStyle="tertiary" onClick={props.openCustomerCareModal}>
          <Translate id="default.no" />
        </Button>
        <Button
          loading={confirmDelivery.result.loading}
          disabled={confirmDelivery.result.loading}
          buttonStyle="secondary"
          onClick={() => confirmDelivery.invoke(props.deliveryId)}
        >
          <Translate id="default.yes" />
        </Button>
      </div>
    </Modal>
  );
};

export const CustomerCareModal: React.FC<{ deliveryId: string }> = props => {
  const dispatch = useDispatch();
  const confirmDelivery = useMutation(coreClient.deliveries.confirmDeliveryByCustomer);
  // If the customer clicks through to the custer care modal, we still want to confirm delivery.
  // Theere, invoke the confirm mutation on mount.
  React.useEffect(() => {
    confirmDelivery.invoke(props.deliveryId);
  }, []);

  return (
    <Modal
      header={<Translate id="request_flow.review.title_delivery_no_success" />}
      open={true}
      onClick={() => dispatch(actions.setConfirmOpen(false))}
    >
      <p>
        <Translate
          id="request_flow.review.body_delivery_no_success"
          data={{
            phone: <a href={'tel:' + _CUSTOMER_SUPPORT_PHONE_NUMBER}>{_CUSTOMER_SUPPORT_PHONE_NUMBER}</a>,
            email: <a href={'mailto:' + _CUSTOMER_SUPPORT_EMAIL}>{_CUSTOMER_SUPPORT_EMAIL}</a>,
          }}
        />
      </p>
      <div className="btn-wrapper btn-wrapper--centered">
        <Button buttonStyle="tertiary" onClick={() => actions.setConfirmOpen(false)}>
          <Translate id="default.close" />
        </Button>
        <Button target="_blank" href={_CUSTOMER_SUPPORT_LINK} buttonStyle="primary">
          <Translate id="request_flow.actions.contact" />
        </Button>
      </div>
    </Modal>
  );
};

export const ConfirmDelivery: React.FC = () => {
  const deliveryId = useTypedSelector(state => state.statusPage.delivery?.['@id']?.split('deliveries/')[1] || '');
  const customerCareModal = useModalState(false);

  if (customerCareModal.isOpen) {
    return <CustomerCareModal deliveryId={deliveryId} />;
  }

  return <ConfirmDeliveryModal openCustomerCareModal={customerCareModal.open} deliveryId={deliveryId} />;
};

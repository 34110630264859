import { History } from 'history';
import { GeneralFlowForms, GeneralTransport } from '../modules/GeneralFlow/interface';
import { Payment, PaymentForm } from '../modules/Payment/typings';
import { User } from '../modules/User/interface';
import { BvaFlowForms, BvaTransport } from '../modules/BvaFlow/interface';
import { IkeaFlowForms, IKEATransport } from '../modules/IkeaFlow/interface';
import { Currencies, NotGuaranteedReason } from './enums';
import { BusinessState } from '../modules/Business/interface';
import { StatusPage } from '../modules/StatusPage/typings';
import { NotificationState } from '../state/ducks/notifications';
export interface CoreIRI {
  '@id': string;
  '@type': string;
}

// @NOTE: BIG FATE NOTE: this is a placeholder until we add
// official types for redux-form library. Its purpose is to
// provide some additional structure to RootState.
interface ReduxFormPlaceholder {
  registeredFields?: any;
  fields?: any;
  anyTouched?: boolean;
  // name of the active field
  active?: string;
  values?: any;
  initial?: any;
  submitSucceeded?: boolean;
  syncErrors?: any;
}

interface Form {
  [GeneralFlowForms.terms]?: ReduxFormPlaceholder;
  [GeneralFlowForms.contact]: ReduxFormPlaceholder;
  [GeneralFlowForms.date]: ReduxFormPlaceholder;
  [GeneralFlowForms.delivery]: ReduxFormPlaceholder;
  [GeneralFlowForms.items]: ReduxFormPlaceholder;
  [GeneralFlowForms.mp_seller]?: ReduxFormPlaceholder;
  [GeneralFlowForms.packages]: ReduxFormPlaceholder;
  [GeneralFlowForms.pickup]: ReduxFormPlaceholder;
  [GeneralFlowForms.weight]: ReduxFormPlaceholder;
  [GeneralFlowForms.paymentForm]?: PaymentForm;
  [BvaFlowForms.auctionLotForm]?: ReduxFormPlaceholder;
  [BvaFlowForms.deliveryForm]?: ReduxFormPlaceholder;
  [IkeaFlowForms.PICKUP_DETAILS]?: ReduxFormPlaceholder;
  [IkeaFlowForms.DELIVERY]?: ReduxFormPlaceholder;
  [IkeaFlowForms.PAYMENT]?: ReduxFormPlaceholder;
}

interface Country extends CoreIRI {
  id: string;
  code: string;
  created_at: string;
  updated_at: string;
  label: string;
  value: string;
}

interface Base {
  localized: boolean;
  loading: boolean;
  autocomplete: any;
  externalParties: any[];
  countries: Country[];
}

export interface RootState {
  router: History;
  localize: any;
  form: Form;
  generalTransport: GeneralTransport;
  statusPage: StatusPage;
  ikeaTransport: IKEATransport;
  bvaTransport: BvaTransport;
  vavatoTransport: any;
  payment: Payment;
  user: User;
  chat: any;
  base: Base;
  business: BusinessState;
  notifications: NotificationState;
  _persist: any;
}

export interface FileData extends CoreIRI {
  '@context': string;
  name: string;
  content_url: string;
  created_at: number;
  file_size: number;
  height: number;
  id: string;
  item_set: string;
  mime_type: string;
  updated_at: string;
  width: number;
}

export interface ProcessedFileData extends FileData {
  original_name: string;
}

export interface PriceData {
  vat: PriceDataPart;
  incl_vat: PriceDataPart;
  excl_vat: PriceDataPart;
}

export interface PriceDataPart {
  amount: number;
  currency: Currencies;
}

export interface DatetimePeriod {
  start: string | Date;
  end: string | Date;
}

export interface NotGuaranteedState {
  [NotGuaranteedReason.DISTANCE]: boolean;
  [NotGuaranteedReason.HEAVY]: boolean;
  latestReason: string;
}

export interface NotGuaranteedUpdate {
  reason: NotGuaranteedReason;
  result: boolean;
}

export interface GeoPoint {
  lat: number;
  lng: number;
}

export interface Commitment {
  '@id': string;
  '@type': string;
  committed_datetime_period: DatetimePeriod;
}

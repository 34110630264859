import React from 'react';
import { FieldArray } from 'redux-form';
import ContactFields from './ContactFields';
import { AllProps as ContainerProps } from '../containers/Contact';
import { Heading, SheetLayoutHeader } from '../../../brenger-shared-ui';
import { Translate } from 'react-localize-redux';
import { StopType } from '../../../typings';

type Props = Pick<ContainerProps, 'groupHistoricalAddresses' | 'title'>;

const ContactDelivery: React.FC<Props> = props => (
  <>
    <SheetLayoutHeader>
      <Heading size={2}>
        <Translate id={props.title('contactDelivery')} />
      </Heading>
    </SheetLayoutHeader>
    <FieldArray
      groupHistoricalAddresses={props.groupHistoricalAddresses(StopType.delivery)}
      name={'contact.delivery'}
      component={ContactFields}
      isPickup={false}
      isBusiness={false}
    />
  </>
);

export default ContactDelivery;

import _get from 'lodash/get';
import { AddressOption } from '../modules/GeneralFlow/interface';

export const getAddressLabel = (address: AddressOption): string => {
  const line1 = _get(address, 'line1', null);
  const locality = _get(address, 'locality', null);
  const postalCode = _get(address, 'postal_code', null);
  const fullAddress = `${line1 || ''} ${locality || ''} ${postalCode || ''}`;
  return fullAddress.trim() === '' ? 'Invalid Address' : fullAddress;
};

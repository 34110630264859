import * as React from 'react';
import './statuslist.css';
import { IconCheckmark } from '../icons';

interface Props {
  items: any[];
}

export const StatusList = ({ items }: Props) => {
  return (
    <ul className={'line-list'}>
      {items.map((item, index) => {
        return (
          <li className={'line-list-item delay-index-' + index} key={index}>
            <div className={'line-list-item-label'}>{item.label}</div>
            {item.value && (
              <div className={'line-list-item-value'}>
                <IconCheckmark />
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default StatusList;

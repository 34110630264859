import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { FieldArray, InjectedFormProps, reduxForm } from 'redux-form';
import { actions as vavatoActions, getAuctionPickupDatetimePeriod, getAuctionTransportOrderDeadline } from '../ducks';
import RenderLotField from '../components/RenderLotField';
import RenderAuctionField from '../components/RenderAuctionField';
import { connect } from 'react-redux';
import {
  Button,
  Col,
  Container,
  Heading,
  IconArrow,
  PriceDisplay,
  Row,
  SheetLayoutContent,
  SheetLayoutContentWrapper,
  SheetLayoutFooter,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { Translate } from 'react-localize-redux';
import _get from 'lodash/get';
import { VavatoFlowForms } from '../interface';

type Props = InjectedFormProps<{}> & RouteComponentProps<{}>;

class AuctionForm extends React.Component<Props> {
  public render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <SheetLayoutContentWrapper>
          <SheetLayoutHeader>
            <Heading size={2}>
              <Translate id={'request_flow.headers.auction_lots'} />
            </Heading>
          </SheetLayoutHeader>
          <SheetLayoutContent>
            <React.Fragment>
              <p className={'pb-1'}>
                <Translate id={'request_flow.Vavato.introduction'} />
              </p>
              {typeof this.props.auctionForm !== 'undefined' && (
                <RenderAuctionField form={this.props.auctionForm} auctionPickupDate={this.props.auctionPickupDate} />
              )}
              <FieldArray
                name={'lots'}
                form={this.props.auctionForm}
                component={RenderLotField}
                generalErrors={this.props.transport.request.errors}
              />

              <ul>
                <li>
                  <Translate
                    id={'request_flow.auction.pickup_deadline_information'}
                    data={{
                      date: this.props.auctionPickupDeadline.format('dddd DD MMMM YYYY'),
                      time: this.props.auctionPickupDeadline.format('H:mm'),
                    }}
                  />
                </li>
                <li>
                  <Translate id={'request_flow.auction.max_dimensions'} />
                </li>
                <li>
                  <Translate id={'request_flow.Vavato.invoice_warning'} />
                </li>
              </ul>
            </React.Fragment>
          </SheetLayoutContent>
        </SheetLayoutContentWrapper>
        <SheetLayoutFooter>
          <Container>
            <Row>
              <Col xs={5}>
                <PriceDisplay header={<Translate id={'request_flow.price.total'} />} cents={this.props.price} />
              </Col>
              <Col xs={7}>
                <Button
                  loading={this.props.submitting}
                  disabled={this.props.submitting || this.props.transport.request.errors !== null}
                  buttonStyle={'secondary'}
                  iconPos={'right'}
                  fullWidth={true}
                >
                  <Translate id={'onboarding.step.next'} />
                  <IconArrow />
                </Button>
              </Col>
            </Row>
          </Container>
        </SheetLayoutFooter>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  auctionForm: state.form[VavatoFlowForms.auctionLotForm],
  auctionPickupDeadline: getAuctionTransportOrderDeadline(state),
  auctionPickupDate: getAuctionPickupDatetimePeriod(state).start,
  transport: state.vavatoTransport,
  price: state.vavatoTransport.request.price,
});

export default reduxForm<{}, Props>({
  form: VavatoFlowForms.auctionLotForm,
  destroyOnUnmount: false,
  forceUnregisterOnUmount: false,
  onSubmit: (lots, dispatch) => dispatch(vavatoActions.submitLotsDetails(lots)),
})(connect(mapStateToProps)(AuctionForm));

import { IKEAStoreData, WarehouseOption } from './interface';
import {
  _IKEA_BARGAINS_TIME_PICKUP_END,
  _IKEA_BARGAINS_TIME_PICKUP_START,
  _IKEA_TIME_PICKUP_END,
  _IKEA_TIME_PICKUP_START,
} from '../../utils/global';
import { CarryingHelpChoices } from '../../typings';

export const StoreLocations: IKEAStoreData[] = [
  {
    active: true,
    storeName: 'amsterdam',
    storeLabel: 'IKEA Amsterdam',
    warehouse: [],
    address: {
      lat: 52.3028027,
      lng: 4.9476359,
      line1: 'Hullenbergweg 2',
      line2: 'IKEA Amsterdam',
      locality: 'Amsterdam',
      postal_code: '1101 BL',
      country_code: 'NL',
    },
    conditions: {
      price: 4900,
      pickup: {
        start_time: _IKEA_BARGAINS_TIME_PICKUP_START,
        end_time: _IKEA_BARGAINS_TIME_PICKUP_END,
        carrying_help: CarryingHelpChoices.NEEDED,
        floor_level: 0,
      },
      delivery: {
        InNumberOfDays: 1,
        start_time: 11,
        end_time: 15,
        order_deadline: 21,
        carrying_help: CarryingHelpChoices.NEEDED,
        floor_level: 4,
      },
    },
    config: {
      hasItemSetConfigurator: true,
    },
  },
  {
    active: false,
    storeName: 'breda',
    storeLabel: 'IKEA Breda',
    warehouse: [],
    address: {
      lat: 51.580497,
      lng: 4.728579,
      line1: 'Kruisweide 1',
      line2: 'IKEA Breda',
      locality: 'Breda',
      postal_code: '4814 RW',
      country_code: 'NL',
    },
    conditions: {
      price: 4900,
      pickup: {
        start_time: _IKEA_TIME_PICKUP_START,
        end_time: _IKEA_TIME_PICKUP_END,
        carrying_help: CarryingHelpChoices.NEEDED,
        floor_level: 0,
      },
      delivery: {
        InNumberOfDays: 1,
        start_time: 11,
        end_time: 15,
        order_deadline: 21,
        carrying_help: CarryingHelpChoices.NEEDED,
        floor_level: 4,
      },
    },
    config: {
      hasItemSetConfigurator: false,
    },
  },
  {
    active: true,
    storeName: 'eindhoven',
    storeLabel: 'IKEA Eindhoven',
    warehouse: [WarehouseOption.INTERNAL, WarehouseOption.EXTERNAL, WarehouseOption.BOTH],
    address: {
      lat: 51.498254,
      lng: 5.470716,
      line1: 'Ekkersrijt 7001',
      line2: 'IKEA Eindhoven',
      locality: 'Son',
      postal_code: '5692 DB',
      country_code: 'NL',
    },
    conditions: {
      price: 3900,
      selectableDates: 2,
      pickup: {
        start_time: _IKEA_TIME_PICKUP_START,
        end_time: _IKEA_TIME_PICKUP_END,
        carrying_help: CarryingHelpChoices.EXTRA_DRIVER,
        floor_level: 0,
      },
      delivery: {
        InNumberOfDays: 1,
        start_time: 11,
        end_time: 15,
        order_deadline: 21,
        carrying_help: CarryingHelpChoices.EXTRA_DRIVER,
        floor_level: 4,
      },
    },
    config: {
      hasItemSetConfigurator: false,
    },
  },
  {
    active: true,
    storeName: 'haarlem',
    storeLabel: 'IKEA Haarlem',
    warehouse: [],
    address: {
      lat: 52.384811,
      lng: 4.66912,
      line1: 'Laan van Decima 1',
      line2: 'IKEA Haarlem',
      locality: 'Haarlem',
      postal_code: '2031 CX',
      country_code: 'NL',
    },
    conditions: {
      price: 4900,
      pickup: {
        start_time: _IKEA_BARGAINS_TIME_PICKUP_START,
        end_time: _IKEA_BARGAINS_TIME_PICKUP_END,
        carrying_help: CarryingHelpChoices.NEEDED,
        floor_level: 0,
      },
      delivery: {
        InNumberOfDays: 1,
        start_time: 11,
        end_time: 15,
        order_deadline: 21,
        carrying_help: CarryingHelpChoices.NEEDED,
        floor_level: 4,
      },
    },
    config: {
      hasItemSetConfigurator: true,
    },
  },
  {
    active: false,
    storeName: 'utrecht',
    storeLabel: 'IKEA Utrecht',
    warehouse: [],
    address: {
      lat: 52.062137,
      lng: 5.09991,
      line1: 'Van Rensselaerlaan 7',
      line2: 'IKEA Utrecht',
      locality: 'Utrecht',
      postal_code: '3526 AP',
      country_code: 'NL',
    },
    conditions: {
      pickup: {
        start_time: _IKEA_TIME_PICKUP_START,
        end_time: _IKEA_TIME_PICKUP_END,
        carrying_help: CarryingHelpChoices.NEEDED,
        floor_level: 0,
      },
      price: 4900,
      delivery: {
        InNumberOfDays: 1,
        start_time: 11,
        end_time: 15,
        order_deadline: 21,
        carrying_help: CarryingHelpChoices.NEEDED,
        floor_level: 4,
      },
    },
    config: {
      hasItemSetConfigurator: false,
    },
  },
];

// init state
import _get from 'lodash/get';
import {
  _CHIPS,
  _DEFAULT_LOCATION,
  _DEFAULT_TIME_ZONE,
  _GENERAL_DEFAULT_TIME_END,
  _GENERAL_DEFAULT_TIME_START,
  _LONG_DISTANCE_IN_KM,
  _LONG_DISTANCE_IN_KM_EXTRA_DATE,
  _MAX_NORMAL_WEIGHT,
  _MAX_WEIGHT,
  _TIME_SLOT_LENGTH,
} from '../../utils/global';
import {
  Addition,
  AddressOption,
  AuctionOpeningHoursTimePeriod,
  AuctionTypes,
  customerTypeFields,
  customTimePreference,
  DateOption,
  DestinationStop,
  FloorData,
  GeneralFlowForms,
  GeneralTransport,
  GroupAddress,
  HomeSituations,
  Item,
  ItemFields,
  ItemSet,
  PackageFields,
  PackageFieldsValues,
  PricedTimePeriodOption,
  priceListTypes,
  Situations,
  StoreOpeningHoursTimePeriod,
  SubStepFieldNamesDelivery,
  SubStepFieldNamesPickup,
  TitleStepName,
  WeightFields,
} from './interface';
import { CarryingHelpChoices, GenericExtraInputFileFields, Nullable, RootState, StopType } from '../../typings';
import moment from 'moment-timezone';
import { _MODULE_PREFIX } from './index';
import { DEFAULT_KEY, generateCacheTTL } from 'redux-cache';
import { getFormValues } from 'redux-form';
import { translate } from '../../utils/localization';
import { floorAsString } from '../../utils/floors';
import { logException } from '../../utils/basics';
import { prefix } from './';
import { getItemSetsVolumeAsString } from '../../utils/itemSet';
import { getAddressLabel } from '../../utils/address';
import { getCountryIri, getUploadedFileDataByFormAndFieldName } from '../../state/ducks/baseReducer';
import { getLocation } from 'connected-react-router';
import {
  getDateFormatLocale,
  getDayFromNow,
  getHoursFromTime,
  getMinutesFromTime,
  printDatesAsString,
} from '../../utils/datetime';
import { matchPath } from 'react-router';
import { priceAsString } from '../../utils/price';
import { getSessionIds } from '../../utils/eventTracking';
import {
  DateTimePeriod,
  DatetimePeriodOption,
  ItemCreationParams,
  ItemSetCreationParams,
  Quote,
  QuoteNotGuaranteedReason,
  SourceFlow,
  StopDetails,
  TransportRequestCreationParams,
} from '@brenger/api-client';
import { _DATE_BVA_COLLECTION_PATH } from './containers/Date';
import { getIsAllowedToPayByInvoice } from '../User/ducks';

export const defaultState: GeneralTransport = {
  [DEFAULT_KEY]: null,
  selectedPickupDateTime: null,
  request: {
    uuid: null,
    international: false,
    customTime: null,
    customTimePreference: customTimePreference.no_preference,
  },
  destination: {
    pickup: null,
    delivery: null,
  },
  errors: null,
  price: {
    amount: 0,
    isLoading: false,
    retryAttempt: 0,
    pickupAvailableDates: null,
    deliveryAvailableDates: null,
    failedAttempts: false,
    special: {
      be: false,
    },
  },
  priceStructure: {
    base: null,
    global: [],
    pickup: [],
    delivery: [],
  },
  priceList: null,
  examples: {
    isOpen: false,
    prices: [],
  },
  notifications: {
    message: '',
    type: '',
    visible: false,
  },
  layout: {
    sheet: false,
    step: 1,
    modal_time: false,
    modal_help: false,
    modal_help_type: '',
    more_info: false,
    uploaded_invoice_names: [],
  },
  referrer: '',
  isSubmitting: false,
  quotes: null,
  historicalAddresses: [],
};

// types
export const types = {
  SET_DESTINATION_DETAILS: `${_MODULE_PREFIX}/SET_DESTINATION_DETAILS`,
  RESET_DESTINATION_DETAILS: `${_MODULE_PREFIX}/RESET_DESTINATION_DETAILS`,
  POP_FOOTER_NOTIFICATION: `${_MODULE_PREFIX}/POP_FOOTER_NOTIFICATION`,
  HIDE_FOOTER_NOTIFICATION: `${_MODULE_PREFIX}/HIDE_FOOTER_NOTIFICATION`,
  PICKUP_SELECT_DATE_TIME_EXPIRED: `${_MODULE_PREFIX}/PICKUP_SELECT_DATE_TIME_EXPIRED`,
  SET_SUBMITTING_STATUS: `${_MODULE_PREFIX}/SET_SUBMITTING_STATUS`,
  SET_PRICE: `${_MODULE_PREFIX}/SET_PRICE`,
  ESCAPE_BVA_FLOW: `${_MODULE_PREFIX}/ESCAPE_BVA_FLOW`,
  SET_PRICE_STRUCTURE: `${_MODULE_PREFIX}/SET_PRICE_STRUCTURE`,
  SET_PRICE_LOADING: `${_MODULE_PREFIX}/SET_PRICE_LOADING`,
  SET_EXAMPLE_OPEN: `${_MODULE_PREFIX}/SET_EXAMPLE_OPEN`,
  SET_EXAMPLE_PRICES: `${_MODULE_PREFIX}/SET_EXAMPLE_PRICES`,
  SET_PRICE_SPECIAL: `${_MODULE_PREFIX}/SET_PRICE_SPECIAL`,
  RESET_PRICE: `${_MODULE_PREFIX}/RESET_PRICE`,
  EXTEND_CACHE_EXPIRATIONS: `${_MODULE_PREFIX}/EXTEND_CACHE_EXPIRATIONS`,
  PUSH_NOTIFICATION: `${_MODULE_PREFIX}/PUSH_NOTIFICATION`,
  SET_PROGRESS_STEP: `${_MODULE_PREFIX}/SET_PROGRESS_STEP`,
  TOGGLE_SHEET: `${_MODULE_PREFIX}/TOGGLE_SHEET`,
  SET_TIME_MODAL_OPEN: `${_MODULE_PREFIX}/SET_TIME_MODAL_OPEN`,
  SET_CUSTOM_TIME_PREFERENCE: `${_MODULE_PREFIX}/SET_CUSTOM_TIME_PREFERENCE`,
  SET_HELP_OPEN: `${_MODULE_PREFIX}/SET_HELP_OPEN`,
  SET_HELP_TYPE: `${_MODULE_PREFIX}/SET_HELP_TYPE`,
  TOGGLE_MORE_INFO: `${_MODULE_PREFIX}/TOGGLE_MORE_INFO`,
  CREATE_TRANSPORT_REQUEST: `${_MODULE_PREFIX}/CREATE_TRANSPORT_REQUEST`,
  SUBMIT_PICKUP: `${_MODULE_PREFIX}/SUBMIT_PICKUP`,
  SUBMIT_DELIVERY: `${_MODULE_PREFIX}/SUBMIT_DELIVERY`,
  SUBMIT_CONTACT: `${_MODULE_PREFIX}/SUBMIT_CONTACT`,
  SUBMIT_TERMS: `${_MODULE_PREFIX}/SUBMIT_TERMS`,
  SUBMIT_DATE: `${_MODULE_PREFIX}/SUBMIT_DATE`,
  SUBMIT_PACKAGES: `${_MODULE_PREFIX}/SUBMIT_PACKAGES`,
  SUBMIT_WEIGHT: `${_MODULE_PREFIX}/SUBMIT_WEIGHT`,
  CREATE_TRANSPORT_REQUEST_START: `${_MODULE_PREFIX}/CREATE_TRANSPORT_REQUEST_START`,
  CREATE_TRANSPORT_REQUEST_SUCCESS: `${_MODULE_PREFIX}/CREATE_TRANSPORT_REQUEST_SUCCESS`,
  CREATE_TRANSPORT_REQUEST_FAILURE: `${_MODULE_PREFIX}/CREATE_TRANSPORT_REQUEST_FAILURE`,
  RESET_FORMS: `${_MODULE_PREFIX}/RESET_FORMS`,
  SET_TRANSPORT_UUID: `${_MODULE_PREFIX}/SET_TRANSPORT_UUID`,
  SET_REFERRER: `${_MODULE_PREFIX}/SET_REFERRER`,
  SUBMIT_WIDGET: `${_MODULE_PREFIX}/SUBMIT_WIDGET`,
  SUBMIT_ITEMS: `${_MODULE_PREFIX}/SUBMIT_ITEMS`,
  SET_PICKUP_AVAILABLE_DATES: `${_MODULE_PREFIX}/SET_PICKUP_AVAILABLE_DATES`,
  SET_DELIVERY_AVAILABLE_DATES: `${_MODULE_PREFIX}/SET_DELIVERY_AVAILABLE_DATES`,
  SET_QUOTES_DATA: `${_MODULE_PREFIX}/SET_QUOTES_DATA`,
  ITEM_SETS_CHANGE: `${_MODULE_PREFIX}/ITEM_SETS_CHANGE`,
  SET_SELECTED_PICKUP_DATETIME: `${_MODULE_PREFIX}/SET_SELECTED_PICKUP_DATETIME`,
  PRICE_API_RETRY_ATTEMPT_INCREMENT: `${_MODULE_PREFIX}/PRICE_API_RETRY_ATTEMPT_INCREMENT`,
  RESET_RETRY_ATTEMPTS: `${_MODULE_PREFIX}/RESET_RETRY_ATTEMPTS`,
  SET_UPLOADED_INVOICE_NAMES: `${_MODULE_PREFIX}/SET_UPLOADED_INVOICE_NAMES`,
  REMOVE_UPLOADED_INVOICE: `${_MODULE_PREFIX}/REMOVE_UPLOADED_INVOICE`,
  SET_PRICE_LIST: `${_MODULE_PREFIX}/SET_PRICE_LIST`,
  GET_MORE_DATES: `${_MODULE_PREFIX}/GET_MORE_DATES`,
  GET_EARLIER_DATE_OPTIONS: `${_MODULE_PREFIX}/GET_EARLIER_DATE_OPTIONS`,
  SET_INTERNATIONAL: `${_MODULE_PREFIX}/SET_INTERNATIONAL`,
  GET_PRICE: `${_MODULE_PREFIX}/GET_PRICE`,
  SET_HISTORICAL_ADDRESSES: `${_MODULE_PREFIX}/SET_HISTORICAL_ADDRESSES`,
  SUBMIT_MP_SELLER_FORM: `${_MODULE_PREFIX}/SUBMIT_MP_SELLER_FORM`,
};

// actions
export const actions = {
  submitSellerForm: () => ({ type: types.SUBMIT_MP_SELLER_FORM }),
  getEarlierDates: (stopType: StopType) => ({ type: types.GET_EARLIER_DATE_OPTIONS, stopType }),
  setHistoricalAddresses: payload => ({ type: types.SET_HISTORICAL_ADDRESSES, payload }),
  setDestinationDetails: (payload, stopType: StopType) => ({ type: types.SET_DESTINATION_DETAILS, payload, stopType }),
  resetDestinationDetails: () => ({ type: types.RESET_DESTINATION_DETAILS }),
  submitItems: () => ({ type: types.SUBMIT_ITEMS }),
  setInternational: (payload: boolean) => ({ type: types.SET_INTERNATIONAL, payload }),
  removeInvoice: (index: number) => ({ type: types.REMOVE_UPLOADED_INVOICE, payload: index }),
  setUploadedInvoice: payload => ({ type: types.SET_UPLOADED_INVOICE_NAMES, payload }),
  popNotification: payload => ({ type: types.POP_FOOTER_NOTIFICATION, payload }),
  hideNotification: () => ({ type: types.HIDE_FOOTER_NOTIFICATION }),
  resetRetryAttempts: () => ({ type: types.RESET_RETRY_ATTEMPTS }),
  setSelectedPickupDateTime: () => ({ type: types.SET_SELECTED_PICKUP_DATETIME }),
  extendCacheExpiration: () => ({ type: types.EXTEND_CACHE_EXPIRATIONS }),
  purgeForms: () => ({ type: types.RESET_FORMS }),
  createTransportRequest: details => ({ type: types.CREATE_TRANSPORT_REQUEST_START, payload: details }),
  failedTransportRequest: err => ({ type: types.CREATE_TRANSPORT_REQUEST_FAILURE, payload: err }),
  resetPickupSelectedExpiration: () => ({ type: types.PICKUP_SELECT_DATE_TIME_EXPIRED }),
  pushNotification: message => ({ type: types.PUSH_NOTIFICATION, payload: message }),
  setQuotes: data => ({ type: types.SET_QUOTES_DATA, payload: data }),
  setProgress: step => ({ type: types.SET_PROGRESS_STEP, payload: step }),
  setPrice: price => ({ type: types.SET_PRICE, payload: price }),
  setPriceStructure: priceStructure => ({ type: types.SET_PRICE_STRUCTURE, payload: priceStructure }),
  setPriceLoading: loading => ({ type: types.SET_PRICE_LOADING, payload: loading }),
  escapeBvaFlow: () => ({ type: types.ESCAPE_BVA_FLOW }),
  setPriceSpecial: (type: string, isOnSpecial: boolean) => ({
    type: types.SET_PRICE_SPECIAL,
    payload: type,
    isOnSpecial,
  }),
  resetPrice: () => ({ type: types.RESET_PRICE }),
  setExampleOpen: isOpen => ({ type: types.SET_EXAMPLE_OPEN, payload: isOpen }),
  setExamplePrices: examples => ({ type: types.SET_EXAMPLE_PRICES, payload: examples }),
  toggleSheet: () => ({ type: types.TOGGLE_SHEET }),
  toggleMoreInfo: () => ({ type: types.TOGGLE_MORE_INFO }),
  setCustomTimePreference: (preference: string) => ({ type: types.SET_CUSTOM_TIME_PREFERENCE, payload: preference }),
  setTimeModalOpen: isOpen => ({ type: types.SET_TIME_MODAL_OPEN, payload: isOpen }),
  setHelpOpen: isOpen => ({ type: types.SET_HELP_OPEN, payload: isOpen }),
  setHelpType: type => ({ type: types.SET_HELP_TYPE, payload: type }),
  submitPickup: formResults => ({ type: types.SUBMIT_PICKUP, formResults }),
  submitDelivery: formResults => ({ type: types.SUBMIT_DELIVERY, formResults }),
  submitContact: () => ({ type: types.SUBMIT_CONTACT }),
  submitTerms: () => ({ type: types.SUBMIT_TERMS }),
  submitDate: formResults => ({ type: types.SUBMIT_DATE, formResults }),
  submitPackages: formResults => ({ type: types.SUBMIT_PACKAGES, formResults }),
  submitWeight: formResults => ({ type: types.SUBMIT_WEIGHT, formResults }),
  resetForms: () => ({ type: types.RESET_FORMS }),
  setTransportUUID: uuid => ({ type: types.SET_TRANSPORT_UUID, payload: uuid }),
  setReferrer: referrer => ({ type: types.SET_REFERRER, payload: referrer }),
  setPickupAvailableDates: options => ({ type: types.SET_PICKUP_AVAILABLE_DATES, payload: options }),
  setDeliveryAvailableDates: options => ({ type: types.SET_DELIVERY_AVAILABLE_DATES, payload: options }),
  toggleSubmitting: isSubmitting => ({ type: types.SET_SUBMITTING_STATUS, payload: isSubmitting }),
  setPriceList: priceList => ({ type: types.SET_PRICE_LIST, payload: priceList }),
  getMoreDates: (stopType: StopType) => ({ type: types.GET_MORE_DATES, stopType }),
  getPrice: (skipAvailableDatesUpdate: boolean = false, isEarlierDatesCall: boolean = false) => ({
    type: types.GET_PRICE,
    skipAvailableDatesUpdate,
    isEarlierDatesCall,
  }),
};

// destination selectors
export const getPickupDestinationDetails = state => getStopDestinationDetails(state, StopType.pickup);
export const getDeliveryDestinationDetails = state => getStopDestinationDetails(state, StopType.delivery);
export const getStopDestinationDetails = (state, type: StopType): null | DestinationStop => {
  return state.generalTransport.destination[type];
};

export const isDestinationLoaded = (state): boolean => {
  return (
    getPickupDestinationDetails(state) !== defaultState.destination.pickup &&
    getDeliveryDestinationDetails(state) !== defaultState.destination.delivery
  );
};

export const getIsTransportWithinSameCountry = (state: RootState, countryCode: string): boolean => {
  const pickup = getPickupDestinationDetails(state);
  const delivery = getDeliveryDestinationDetails(state);
  if (!pickup || !delivery) {
    return false;
  }
  return pickup.country_code === countryCode && delivery.country_code === countryCode;
};

export const getIsTransportToOrFromSpecificCountry = (state: RootState, countryCode: string): boolean => {
  const pickup = getPickupDestinationDetails(state);
  const delivery = getDeliveryDestinationDetails(state);
  // return false when not defined
  if (!pickup || !delivery) {
    return false;
  }
  // return false when transport is within same country
  if (pickup.country_code === countryCode && delivery.country_code === countryCode) {
    return false;
  }
  // return if either pickup or delivery is true
  return pickup.country_code === countryCode || delivery.country_code === countryCode;
};

// selectors
export const getPriceCallRetryAttempts = state => {
  return state.generalTransport.price.retryAttempt;
};

export const getOpeningHours = (state: any, stopType: StopType): PricedTimePeriodOption[] => {
  const situation = getStopSituation(state, stopType);
  return situation === Situations.AUCTION ? AuctionOpeningHoursTimePeriod : StoreOpeningHoursTimePeriod;
};

export const isInternational = (state: RootState): boolean => {
  return !!state.generalTransport?.request?.international;
};

export const getPriceAmount = state => {
  return state.generalTransport.price.amount;
};

export const getMarktplaatsBuyerFormValues = state => {
  const data = _get(state.form[GeneralFlowForms.pickup], 'values.pickup.marktplaats', []);
  return {
    buyer_email: _get(data, 'buyer_email', null),
    seller_email: _get(data, 'seller_email', null),
    form_data: JSON.stringify({
      form: 'buyer_request',
      values: {
        product_description: _get(data, 'product_description', ''),
      },
    }),
  };
};

export const getSellerFormData = state => {
  const formData = _get(state.form[GeneralFlowForms.mp_seller], 'values.pickup.marktplaats', []);
  const questions = {};
  questions[String(translate('request_flow.marktplaats.answers.first'))] = _get(formData, 'product_description', '');
  questions[String(translate('request_flow.marktplaats.answers.second'))] = _get(formData, 'pickup_situation', '');
  questions[String(translate('request_flow.marktplaats.answers.third'))] = _get(formData, 'collection_date', '');
  questions[String(translate('request_flow.marktplaats.answers.forth'))] = _get(formData, 'extra_help', '');
  questions[String(translate('request_flow.marktplaats.answers.fifth'))] = _get(formData, 'address', '');
  return {
    buyer_email: formData.buyer_email,
    seller_email: formData.seller_email,
    form_data: JSON.stringify({
      form: 'seller_answers',
      values: questions,
    }),
  };
};

export const getPriceStructure = state => {
  return state.generalTransport.priceStructure;
};

export const getAuctionType = (state: any): string | undefined => {
  const pickupSituation = getPickupSituation(state);
  if (pickupSituation !== Situations.AUCTION) {
    return undefined;
  }
  return _get(
    state.form[GeneralFlowForms.pickup],
    `values.${SubStepFieldNamesPickup.auction_type}`,
    AuctionTypes.OTHERS
  );
};

export const getBasePrice = (state): number => {
  const structure = getPriceStructure(state);
  const itemSets = getValidItemSets(state);
  return itemSets.length > 0 ? _get(structure, 'base', 0) : 0;
};

export const isSpecialPriceActive = (state, type: string): boolean => {
  const isSpecialPrice: boolean | null = _get(state.generalTransport, 'price.special.' + type, null);
  if (isSpecialPrice === null) {
    throw new Error('Requested special price type does not exist');
  }
  return isSpecialPrice;
};

export const getFeeHome = state => getExtraFeeByType(state, priceListTypes.stop_situation_home);
export const getFeeAuction = state => getExtraFeeByType(state, priceListTypes.stop_situation_auction);
export const getFeeStore = state => getExtraFeeByType(state, priceListTypes.stop_situation_store);
export const getFeeExtraPerson = state => getExtraFeeByType(state, priceListTypes.carrying_help_extra_driver);
export const getFeeTailgate = state => getExtraFeeByType(state, priceListTypes.carrying_help_equipment_tailgate);

export const getFeeTailgatePalletJack = state => {
  return getExtraFeeByType(state, priceListTypes.carrying_help_equipment_tailgate_pallet_jack);
};

export const getFeeTailgateExtraDriver = (state: RootState) => {
  return getExtraFeeByType(state, priceListTypes.carrying_help_equipment_tailgate_extra_driver);
};

export const getFeeFloor = state => {
  const withExtraHelpPickup =
    _get(state.form[GeneralFlowForms.pickup], 'values.' + SubStepFieldNamesPickup.help_carrying, '') ===
    CarryingHelpChoices.EXTRA_DRIVER;
  const withExtraHelpDelivery =
    _get(state.form[GeneralFlowForms.delivery], 'values.' + SubStepFieldNamesDelivery.help_carrying, '') ===
    CarryingHelpChoices.EXTRA_DRIVER;
  const hasHelp = withExtraHelpPickup || withExtraHelpDelivery;
  if (hasHelp) {
    return getExtraFeeByType(state, priceListTypes.per_floor_with_extra_help);
  }
  return getExtraFeeByType(state, priceListTypes.per_floor_without_extra_help);
};

export const getCustomTimeFirstExtraFee = state => getExtraFeeByType(state, priceListTypes.custom_timeslot_first);
export const getCustomTimeSecondExtraFee = state => getExtraFeeByType(state, priceListTypes.custom_timeslot_second);

export const getCustomTimeExtraFee = (
  state: any,
  stopType: StopType,
  startHour: number,
  endHour: number
): number | null => {
  const firstFee = getCustomTimeFirstExtraFee(state) || 0;
  const secondFee = getCustomTimeSecondExtraFee(state) || 0;
  const pickupForm = state.form[GeneralFlowForms.pickup];
  const pickupSituation = getStopSituation(state, StopType.pickup);

  // no fees for pickup, when situation is auction/shop
  if (stopType === StopType.pickup && pickupSituation !== Situations.HOME) {
    return null;
  }

  const sameDayDelivery = isSameDayDelivery(state);
  if (stopType === StopType.pickup) {
    // Pickup after 13.00 on the same day makes delivery NOT flexible
    if (startHour >= 13 && sameDayDelivery) {
      return firstFee + secondFee;
    }
    return firstFee;
  }

  // when pickup situation is auction/shop, delivery custom timeslot is always the first fee
  if (stopType === StopType.delivery && pickupSituation !== Situations.HOME) {
    return firstFee;
  }

  // For handling the rest of delivery, we need selected pickup time
  const pickupTimeStart = _get(pickupForm, 'values.pickup.time.start', 0);
  const pickupTimeEnd = _get(pickupForm, 'values.pickup.time.end', 0);

  // If pickup start is 13:00 or later, we already included the fee at pickup
  if (parseInt(pickupTimeStart, 10) >= 13 && sameDayDelivery) {
    return 0;
  }

  // When pickup is flexible
  if (parseInt(pickupTimeStart, 10) === 9 && parseInt(pickupTimeEnd, 10) === 18) {
    // Then delivery before 17.00 on the same day makes pickup time NOT flexible
    if (endHour <= 16 && sameDayDelivery) {
      return firstFee + secondFee;
    }
    // else they pay for a first custom slot
    return firstFee;
  }

  // pickup is custom too, so this is the second fee
  return secondFee;
};

export const getExtraFeeByType = (state: any, type: string): number | null => {
  const priceList = state.generalTransport.priceList;
  if (priceList === null) {
    return null;
  }
  const fee = _get(priceList, type, null);
  if (fee === null) {
    logException(`"${type}" is a non existing fee in the price list.`);
  }
  return fee;
};

export const getCarryHelpExtraCharge = (state): number => {
  if (_get(state.generalTransport, 'priceStructure.global', null) === null) {
    return 0;
  }
  let carryHelpCharges = state.generalTransport.priceStructure.global.filter(
    item => _get(item, 'charge', '') === 'carrying_help'
  );
  carryHelpCharges = carryHelpCharges.filter(item => _get(item, 'amount', '') > 0);
  if (carryHelpCharges.length === 0) {
    return 0;
  }
  return carryHelpCharges[0].amount;
};

export const hasCustomFloor = (state, isPickup) => {
  const formKey = isPickup ? GeneralFlowForms.pickup : GeneralFlowForms.delivery;
  return _get(
    state.form[formKey],
    `values.${isPickup ? SubStepFieldNamesPickup.floor_custom : SubStepFieldNamesDelivery.floor_custom}`,
    false
  );
};

export const getUploadedInvoiceNames = (state): string[] => {
  return state.generalTransport.layout.uploaded_invoice_names;
};

export const getGeneralTransportRequest = state => {
  return state.generalTransport;
};

export const getProgressStep = state => {
  return state.generalTransport.layout.step;
};

export const getSelectedDateTime = state => {
  return state.generalTransport.selectedPickupDateTime;
};

export const isSheetOpen = state => {
  return state.generalTransport.layout.sheet;
};

export const isTimeModalOpen = (state): boolean => {
  return state.generalTransport.layout.modal_time;
};

export const getDistance = (state: any): number => {
  return _get(state, 'generalTransport.quotes.distance', 0);
};

export const isRequestAboveMaxDistance = (state: RootState, extraDateDistance: boolean = false): boolean => {
  /*

    The difference between a long distance & extra date distance:
    - _LONG_DISTANCE_IN_KM < _LONG_DISTANCE_IN_KM_EXTRA_DATE
    - _LONG_DISTANCE_IN_KM: is the distance where we stop asking for specific timeslots (at this point)
    - _LONG_DISTANCE_IN_KM_EXTRA_DATE: is the distance where people are forced to select two dates.
  */
  const distance = extraDateDistance ? _LONG_DISTANCE_IN_KM_EXTRA_DATE : _LONG_DISTANCE_IN_KM;
  return getDistance(state) > distance;
};

export const getAuctionPickupDate = (state): DateTimePeriod => {
  const lot = getPickupLot(state, 0);
  return {
    start: _get(lot, 'collectionDaySummaries[0].startDate', ''),
    end: _get(lot, 'collectionDaySummaries[0].endDate', ''),
  };
};

export const getAuctionPickupDateString = (state): string => {
  const dateTime = getAuctionPickupDate(state);
  if (dateTime.start === '' || dateTime.end === '') {
    return '';
  }
  const date = moment(dateTime.start).format(getDateFormatLocale());
  const from = moment(dateTime.start).format('HH:mm');
  const to = moment(dateTime.end).format('HH:mm');
  return `${date} ${String(translate('datetime.from'))} ${from} ${String(translate('datetime.until'))} ${to}`;
};

export const isAuctionOrStore = (state): boolean => {
  const situation = _get(state.form[GeneralFlowForms.pickup], 'values.' + SubStepFieldNamesPickup.situation, '');
  return situation === Situations.AUCTION || situation === Situations.STORE;
};

export const isNoPreferenceTimeOptionVisible = (state): boolean => {
  const deliveryForm = state.form[GeneralFlowForms.delivery];
  const pickupStartTime = parseInt(_get(deliveryForm, 'values.delivery.pickupTime.start', '00:00'), 10);
  return isRequestAboveMaxDistance(state) && isAuctionOrStore(state) ? pickupStartTime < 15 : pickupStartTime < 13;
};

export const isExpired = state => {
  return moment(new Date(state.generalTransport.cacheUntil)).isBefore(moment());
};

export const expiredDays = state => {
  return moment().diff(moment(new Date(state.generalTransport.cacheUntil)), 'days');
};

export const expiredHours = state => {
  if (state.generalTransport.cacheUntil === null) {
    return -1;
  }
  return moment().diff(moment(new Date(state.generalTransport.cacheUntil)), 'hours');
};

export const isGuaranteed = (state: RootState): boolean => {
  return _get(state.generalTransport.quotes, 'guaranteed.result', false);
};

export const getQuote = (state: RootState): Quote | null => {
  return state.generalTransport.quotes;
};

export const getQuoteItems = (state: RootState): ItemCreationParams[] => {
  return _get(getQuote(state), 'item_sets[0].items', []);
};

export const getBlockedTransportRequestReasons = (state: RootState) => {
  const itemSets = getValidItemSets(state);
  const reasons = [] as QuoteNotGuaranteedReason[];
  const isAboveMaxWeight =
    itemSets.filter(itemSet => itemSet.items.filter(item => (item.weight || 0) > _MAX_WEIGHT).length > 0).length > 0;
  if (isAboveMaxWeight) {
    reasons.push('item.over_200kg');
  }
  return reasons;
};

export const getNotGuaranteedReason = (state: RootState) => {
  return state.generalTransport.quotes?.guaranteed?.reason;
};

export const getTransportPrice = state => {
  return state.generalTransport.price.amount;
};

export const getItemSetTotalSizeAsString = state => {
  const itemFormValues = getFormValues(GeneralFlowForms.items)(state);
  return getItemSetsVolumeAsString(_get(itemFormValues, 'itemSets', false));
};

export const getToPaymentButtonTranslation = state => {
  const pathname = state.router.location.pathname;
  /* AB TEST: change when people see price */
  if (pathname === `${prefix}/items` && document.body.classList.contains('show-price-later')) {
    return 'request_flow.steps.next_price';
  }
  switch (pathname) {
    case `${prefix}/terms`:
      if (getIsDirectlyPayable(state)) {
        return 'request_flow.payment.button';
      } else {
        return 'request_flow.actions.create_first_request';
      }
    case `${prefix}/pickup/marktplaats/form`:
      return 'request_flow.marktplaats.form.submit';
    case `${prefix}/payment`:
      return 'request_flow.payment.button';
    default:
      return 'request_flow.steps.next';
  }
};

export const getPickupPoint = state => {
  return getPickupDestinationDetails(state);
};

export const getDeliveryPoint = state => {
  return getDeliveryDestinationDetails(state);
};

export const getPickupDateOptions = state => getStopDateOptions(state, StopType.pickup);
export const getDeliveryDateOptions = state => getStopDateOptions(state, StopType.delivery);

export const getStopDateOptions = (state, stopType: StopType): DatetimePeriodOption[] => {
  return stopType === StopType.pickup
    ? _get(state, 'generalTransport.price.pickupAvailableDates.options', [])
    : _get(state, 'generalTransport.price.deliveryAvailableDates.options', []);
};

export const getInstantBookingOption = (state: RootState): DatetimePeriodOption | null => {
  const dateOptions = getStopDateOptions(state, StopType.pickup);
  const instantDateOptions = dateOptions.filter(dateOption => {
    return dateOption.time_options?.length && dateOption.time_options[0].description === 'instant';
  });
  if (!instantDateOptions.length) {
    return null;
  }
  return instantDateOptions[0];
};

export const getIsInstantBookingAvailable = (state: RootState): boolean => {
  return getInstantBookingOption(state) !== null;
};

export const getIsInstantBookingSelected = (state: RootState): boolean => {
  const formValues = getFormValues(GeneralFlowForms.date)(state);
  if (!formValues) {
    return false;
  }
  return formValues[SubStepFieldNamesPickup.instant_booking];
};

export const getStopDateSelectableOptions = (state: RootState, stopType: StopType): DateOption[] => {
  let dateOptions = getStopDateOptions(state, stopType);

  if (dateOptions.length === 0) {
    return [];
  }

  dateOptions = dateOptions.filter(dateOption => {
    return dateOption.time_options?.length && dateOption.time_options[0].description !== 'instant';
  });

  const isAboveMaxDistance = isRequestAboveMaxDistance(state, true);

  const options = dateOptions.map(dateOption => {
    let titleOption = '';
    for (const dateString of dateOption.dates) {
      if (!isAboveMaxDistance) {
        titleOption += `${getDayFromNow(dateString)} ${moment(dateString).format('DD MMMM')}`;
      } else {
        titleOption += `${moment(dateString).format('DD MMMM')} + ${moment(dateString)
          .add(1, 'day')
          .format('DD MMMM')}`;
      }
    }
    const priceAmount = _get(dateOption, 'price_change.incl_vat.amount', 0);
    const priceLabel = priceAsString(priceAmount);
    return {
      value: dateOption.dates[0],
      title: titleOption,
      suffix: `<span class='text--${_get(dateOption, 'label', 'primary')}'>${
        priceAmount !== 0 ? `+ ${priceLabel}` : priceLabel
      }</span>`,
    };
  });
  return options.filter(option => option);
};

export const needsDifferentDeliveryDate = state => {
  if (isRequestAboveMaxDistance(state, true)) {
    return false;
  }
  const dateForm = getDateForm(state);
  return _get(dateForm, 'values.' + SubStepFieldNamesPickup.different_delivery_date, false);
};

export const shouldSkipDateTimeStep = state =>
  !_get(state, 'generalTransport.price.pickupAvailableDates.available', true);

export const getFlowTitleByStepAndSituation = (state, stepName: TitleStepName): string => {
  const situation = getPickupSituation(state); // we don't ask for delivery situation, so it is always pickup situation
  // no suffix for home situation
  const situationSuffix: string = situation === Situations.HOME ? '' : '_' + situation.toLowerCase();
  switch (stepName) {
    case 'itemSet':
      return 'request_flow.headers.what' + situationSuffix;
    case 'weight':
      return 'request_flow.headers.weight';
    case 'datePickup':
      return 'request_flow.date.title_question_pickup' + situationSuffix;
    case 'dateDelivery':
      return 'request_flow.date.title_question_delivery';
    case 'datePickupLongDistance':
      return 'request_flow.date.title_question_pickup_long_distance' + situationSuffix;
    case 'dateDeliveryLongDistance':
      return 'request_flow.date.title_question_delivery_long_distance';
    case 'timePickup':
      return 'request_flow.time.title_question_pickup' + situationSuffix;
    case 'timeDelivery':
      return 'request_flow.time.title_question_delivery';
    case 'timeCustomPickup':
      return 'request_flow.time.second_option.label' + situationSuffix;
    case 'timeCustomDelivery':
      return 'request_flow.time.second_option.label';
    case 'helpPickup':
      return 'request_flow.pickup.help_carrying_question' + situationSuffix;
    case 'helpDelivery':
      return 'request_flow.delivery.help_carrying_question';
    case 'contactPickup':
      return 'request_flow.headers.contact_pickup' + situationSuffix;
    case 'contactDelivery':
      return 'request_flow.headers.contact_delivery';
    default:
      // Worst case, fall back to 'Your transport' as title
      return 'request_flow.headers.transport_request_title';
  }
};

export const getRequestTitle = (state, withDefault: boolean = true): string => {
  const itemForm = state.form[GeneralFlowForms.items];
  const itemSets: ItemSet[] = _get(itemForm, 'values.itemSets', []);
  return getTitleOfItemsAsString(itemSets, withDefault);
};

export const getTitleOfItemsAsString = (itemSets: ItemSet[], withDefault: boolean = true): string => {
  const defaultTitle = withDefault ? String(translate('request_flow.headers.transport_request_title')) : '';
  // no items
  if (!itemSets) {
    return defaultTitle;
  }

  if (!_get(itemSets[0], 'items[0].title', false)) {
    return defaultTitle;
  }

  let titles: any[] = [];
  itemSets.map(itemSet => itemSet.items.map(item => titles.push(item.title)));

  titles = titles.filter((a, b) => titles.indexOf(a) === b);
  if (titles.length === 0) {
    return defaultTitle;
  }
  return `${titles.join(', ')}`;
};

export const getRequestMoreInfo = state => {
  const options = [..._CHIPS.options];
  const selectedChips = _get(state.form[GeneralFlowForms.items], 'values.' + ItemFields.MORE_INFO, []);
  const moreInfoStrings: string[] = [];
  options.map(option => {
    if (selectedChips.includes(option.id)) {
      moreInfoStrings.push(String(translate(option.label)));
    }
  });
  return moreInfoStrings.join(', ');
};

export const getAddressTextLabel = (isPickup, state) => {
  const widgetDetails = getStopDestinationDetails(state, isPickup ? StopType.pickup : StopType.delivery);
  if (widgetDetails === null) {
    return null;
  }
  const contactForm = getContactsForm(state);
  const fieldName = isPickup ? StopType.pickup : StopType.delivery;
  const address = _get(contactForm, 'values.' + fieldName + '.address.line1', false);
  const locality = _get(contactForm, 'values.' + fieldName + '.address.locality', false);
  if (locality && address) {
    return `${address}, ${locality}`;
  }
  return widgetDetails.locality;
};

export const customerIsBusiness = (state: RootState): boolean => {
  return _get(getContactsForm(state), 'values.' + customerTypeFields.type, '') === 'business';
};

export const getContactAddress = (isPickup, state) => {
  const key = isPickup ? StopType.pickup : StopType.delivery;
  const address = getContactsForm(state).values[key].address;
  return {
    ...address,
    country: getCountryIri(state, address.country_code),
  };
};

export const getContactPerson = (isPickup, state) => {
  const key = isPickup ? StopType.pickup : StopType.delivery;
  return getContactsForm(state).values[key].contact;
};

export const getCustomerDetails = state => {
  const customer = _get(getContactsForm(state), 'values.customer.contact', {});
  const business = _get(getContactsForm(state), 'values.customer.business', false);
  const isBusiness = customerIsBusiness(state);
  if (isBusiness) {
    return {
      ...customer,
      company_details: {
        coc_number: '',
        vat_number: '',
        company_name: business.name,
        address: business.address,
      },
    };
  } else {
    return customer;
  }
};

export const getPickupForm = state => {
  return state.form[GeneralFlowForms.pickup];
};

export const getPickupLots = (state: any) => {
  return _get(state.form[GeneralFlowForms.pickup], `values.lots`, []);
};

export const getPickupLot = (state: any, index: number) => {
  return _get(state.form[GeneralFlowForms.pickup], `values.lots[${index}]`, null);
};

export const getDateForm = state => {
  return state.form[GeneralFlowForms.date];
};

export const getDeliveryForm = state => {
  return state.form[GeneralFlowForms.delivery];
};

export const getContactsForm = state => {
  return state.form[GeneralFlowForms.contact];
};

export const getChosenPackage = state => {
  if (getIsTransportHeavyWeight(state)) {
    return PackageFieldsValues.custom;
  }
  const packageFormValues = getFormValues(GeneralFlowForms.packages)(state);
  return _get(packageFormValues, PackageFields.chosen);
};

export const getPickupFloorData = (state): FloorData => getFloorData(state, StopType.pickup);
export const getDeliveryFloorData = (state): FloorData => getFloorData(state, StopType.delivery);

export const getFloorData = (state, stopType: StopType): FloorData => {
  const formValues = _get(stopType === StopType.pickup ? getPickupForm(state) : getDeliveryForm(state), 'values');
  const fieldNames = stopType === StopType.pickup ? SubStepFieldNamesPickup : SubStepFieldNamesDelivery;
  const chosenPackage = getChosenPackage(state);
  if (!formValues) {
    return {
      floor: chosenPackage === PackageFieldsValues.economic ? 0 : undefined,
      isCustom: false,
      floorString: '',
      hasElevator: false,
    };
  }
  // in case of equipment (at pickup or delivery) then the floor level is set to 0
  const equipmentOptions = [CarryingHelpChoices.EQUIPMENT_TAILGATE, CarryingHelpChoices.EQUIPMENT_TAILGATE_PALLET_JACK];
  if (
    equipmentOptions.includes(getStopHelp(state, StopType.pickup)) ||
    equipmentOptions.includes(getStopHelp(state, StopType.delivery)) ||
    chosenPackage === PackageFieldsValues.economic
  ) {
    return {
      floor: 0,
      isCustom: false,
      floorString: '',
      hasElevator: false,
    };
  }
  const situationAndFloor = _get(formValues, fieldNames.situation_home_floors_combined, HomeSituations.outside);
  const situation = situationAndFloor.replace(/\[[0-9]{1,2}\]/g, '');
  switch (situation) {
    case String(HomeSituations.ground_floor_and_ready):
    case String(HomeSituations.ground_floor_not_ready):
      return {
        floor: 0,
        isCustom: false,
        floorString: '',
        hasElevator: false,
      };
    case String(HomeSituations.floor_without_elevator):
      const floor: number = Number(situationAndFloor.match(/[0-9]{1,2}/g)![0]);
      return {
        floor,
        isCustom: false,
        floorString: floorAsString(floor),
        hasElevator: false,
      };
    case String(HomeSituations.floor_with_elevator): {
      return {
        floor: 5,
        isCustom: false,
        floorString: floorAsString(5),
        hasElevator: true,
      };
    }
  }
  return {
    isCustom: false,
    floorString: '',
    hasElevator: false,
  };
};

export const isMarktplaatsFormSentSucessfully = (state: any): boolean => {
  return _get(state.form[GeneralFlowForms.mp_seller], 'values.pickup.marktplaats.result', 'failed');
};

export const isItemSetsInitialized = (state: any): boolean => {
  const item = _get(state.form[GeneralFlowForms.items], 'values.itemSets[0].items[0].count', null);
  return item !== null;
};

export const normalizeItems = (itemSets: ItemSet[]): ItemSetCreationParams[] => {
  return itemSets.map(itemSet => {
    const normalizedItemSet = {
      title: itemSet.title,
      description: itemSet.description,
      items: itemSet.items.map(item => {
        // Because the upload component creates more fields then we need, we need to take care of two things:
        // 1. get the job image
        // 2. return explicitly the fields that we need for an item, to filter out the unnescary stuff
        const jobImage = _get(item, `image_${GenericExtraInputFileFields.COLLECTION}[0]['@id']`);
        const nomalizedItem = {
          title: item.title || '',
          length: item.length || 0,
          height: item.height || 0,
          width: item.width || 0,
          weight: item.weight || 0,
          count: item.count || 0,
          extra_care: item.extra_care,
        };
        if (jobImage) {
          // job_image can only be a single IRI reference
          // So it can't be an empty string or array
          nomalizedItem['job_image'] = jobImage;
        }
        return nomalizedItem;
      }),
    };
    // Some optional keys should be checked on existance, and added to the normalized itemSet.
    const optionalKeys = ['external_party', 'external_invoices', 'attributes', 'client_reference'];
    optionalKeys.forEach(key => {
      if (itemSet[key]) {
        normalizedItemSet[key] = itemSet[key];
      }
    });

    return normalizedItemSet;
  });
};

// return all valid items with extra details
export const getItemSets = state => {
  const itemSets = getValidItemSets(state);
  const title = getRequestTitle(state);
  const description = getRequestMoreInfo(state);
  return itemSets.map((itemSet, idx) => {
    const externalInvoices = getUploadedFileDataByFormAndFieldName(
      state,
      GeneralFlowForms.pickup,
      SubStepFieldNamesPickup.invoice,
      '@id'
    );
    const externalParty = _get(itemSet, 'external_party', false);
    const attributes = _get(itemSet, 'attributes', false);
    itemSet['title'] = _get(itemSet, 'title', title);
    itemSet['description'] = _get(itemSet, 'description', description);
    if (externalInvoices.length > 0) {
      itemSet['external_invoices'] = externalInvoices;
    }
    if (externalParty) {
      itemSet['external_party'] = externalParty;
    }
    if (attributes) {
      itemSet['attributes'] = attributes;
    }
    return itemSet;
  });
};

export const getTransportRequestCreationParams = (state: RootState): TransportRequestCreationParams => {
  const contacts = getContactsForm(state).values;
  const itemSets = normalizeItems(getItemSets(state));
  const pickupDetails = getPickupDetails(state);
  pickupDetails.instructions = _get(contacts, 'pickup.address.instructions', '');
  const deliveryDetails = getDeliveryDetails(state);
  deliveryDetails.instructions = _get(contacts, 'delivery.address.instructions', '');
  const PickupAvailableDatetimePeriods = getPickupAvailableDateTime(state);
  const DeliveryAvailableDatetimePeriods = getDeliveryAvailableDateTime(state);

  /* Gather attributes, and add them to the itemset */
  const attributes = {};
  if (getIsInstantBookingSelected(state)) {
    attributes['instant_transport'] = true;
  }
  const auctionType = getAuctionType(state);
  if (auctionType) {
    attributes['auction_type'] = auctionType;
  }
  if (itemSets.length && Object.keys(attributes).length) {
    itemSets[0]['attributes'] = attributes;
  }
  return {
    international: state.generalTransport.request.international,
    source_flow: SourceFlow.GENERAL,
    guaranteed: isGuaranteed(state),
    item_sets: itemSets,
    price: {
      amount: getTransportPrice(state),
      currency: 'EUR',
    },
    customer: getCustomerDetails(state),
    session_ids: getSessionIds(),
    frontend_url: window.location.href,
    pickups: [
      {
        address: getContactAddress(true, state),
        contact: getContactPerson(true, state),
        available_datetime_periods: PickupAvailableDatetimePeriods ? PickupAvailableDatetimePeriods : [],
        details: pickupDetails,
      },
    ],
    deliveries: [
      {
        address: getContactAddress(false, state),
        contact: getContactPerson(false, state),
        available_datetime_periods: DeliveryAvailableDatetimePeriods ? DeliveryAvailableDatetimePeriods : [],
        details: deliveryDetails,
      },
    ],
  };
};

export const getIsDirectlyPayable = (state: RootState): boolean => {
  const isAllowedToPayByInvoice = getIsAllowedToPayByInvoice(state);
  const quote = getQuote(state);
  if (isAllowedToPayByInvoice) {
    /*
     * We return an inverted boolean here.
     * - When a customer is allowed to pay by invoice, direct payable means: paying on invoice
     * - When direct payable is false, then we deal with a difficult TR,
     * to prevent any trouble we send this 'pay on invoice customer' to direct payment
     */
    return !quote?.guaranteed.directly_payable;
  }
  return Boolean(quote?.guaranteed.directly_payable);
};

export const getStopHomeSituation = (state: RootState, stopType: StopType) => {
  const stopDetails = _get(state.form[GeneralFlowForms[stopType]], 'values', null);
  if (stopDetails === null) {
    return null;
  }
  const subStepFieldNames = stopType === StopType.pickup ? SubStepFieldNamesPickup : SubStepFieldNamesDelivery;
  return _get(stopDetails, subStepFieldNames.situation_home, null);
};

export const getPickupHelp = state => getStopHelp(state, StopType.pickup);
export const getDeliveryHelp = state => getStopHelp(state, StopType.delivery);

export const getStopHelp = (state, stopType: StopType) => {
  const isEconomicPackage = getChosenPackage(state) === PackageFieldsValues.economic;
  if (isEconomicPackage) {
    return CarryingHelpChoices.NEEDED;
  }
  const stopDetails = _get(state.form[GeneralFlowForms[stopType]], 'values', null);
  if (stopDetails === null) {
    return CarryingHelpChoices.NOT_NEEDED;
  }
  const subStepFieldNames = stopType === StopType.pickup ? SubStepFieldNamesPickup : SubStepFieldNamesDelivery;
  let carryingHelp = _get(stopDetails, subStepFieldNames.help_carrying, null);
  carryingHelp = _get(stopDetails, subStepFieldNames.help_equipment, carryingHelp);

  // if delivery has no value, then fallback to pickup
  if (stopType === StopType.delivery && _get(state.form[GeneralFlowForms[StopType.pickup]], 'values', false)) {
    // We don't have value so fall back to pickup
    if (carryingHelp === null) {
      carryingHelp = getPickupHelp(state);
    }
  }

  return carryingHelp || CarryingHelpChoices.NOT_NEEDED;
};

export const transportRequestHasHelp = (state): boolean => {
  const pickupHelp: string = getPickupHelp(state);
  const deliveryHelp: string = getPickupHelp(state);
  const noExtraHelpValues: string[] = [CarryingHelpChoices.NOT_NEEDED, CarryingHelpChoices.NEEDED];
  return !noExtraHelpValues.includes(pickupHelp) && !noExtraHelpValues.includes(deliveryHelp);
};

export const getPickupSituation = (state, withDefault = true) => getStopSituation(state, StopType.pickup, withDefault);
export const getDeliverySituation = (state, withDefault = true) =>
  getStopSituation(state, StopType.delivery, withDefault);

export const getStopSituation = (state, stopType: StopType, withDefault: boolean = true) => {
  const stopDetails = _get(state.form[GeneralFlowForms[stopType]], 'values');
  const defaultValue = withDefault ? Situations.HOME : null;
  let situation = _get(stopDetails, `${stopType}.situation`, defaultValue);
  // temperory (let's wait for price API to support)
  if (situation === Situations.MARKTPLAATS) {
    situation = Situations.HOME;
  }
  return situation;
};

export const isSubmitDisabled = (state: RootState): boolean => {
  const transport = state.generalTransport;
  const location = getLocation(state);
  // Hard block for customers
  if (location.pathname.includes('terms')) {
    return getBlockedTransportRequestReasons(state).length > 0;
  }
  const isPriceFailed = transport.price.failedAttempts;
  const isPriceLoading = transport.price.isLoading;
  const isPaymentLoading = state.payment.paymentInfo.isSubmitting;
  const isDisabled = isPriceFailed || isPriceLoading || transport.isSubmitting || isPaymentLoading;
  return transport.layout.step === 1 ? false : isDisabled;
};

export const getStopDetails = (state: RootState, stopType: StopType): StopDetails => {
  const floorData = getFloorData(state, stopType);
  return {
    situation: getStopSituation(state, stopType),
    floor_level: floorData.floor,
    elevator: floorData.hasElevator,
    carrying_help: getStopHelp(state, stopType),
    extras: [],
  };
};

export const getPickupDetails = (state: RootState) => getStopDetails(state, StopType.pickup);
export const getDeliveryDetails = (state: RootState) => getStopDetails(state, StopType.delivery);

export const getStopAvailableDate = (state, stopType: StopType): string[] => {
  const fieldNames = stopType === StopType.pickup ? SubStepFieldNamesPickup : SubStepFieldNamesDelivery;
  let date = _get(state.form[GeneralFlowForms.date], 'values.' + fieldNames.date, null);
  const isAuctionSituation = getStopSituation(state, stopType) === Situations.AUCTION;

  // if we are dealing with an instant booking, get the instant booking option and retrieve date from it
  if (getIsInstantBookingSelected(state)) {
    const dateTimeOption = getInstantBookingOption(state);
    date = dateTimeOption?.dates[0] || date;
  }

  if (date === null) {
    return [];
  }

  if (isRequestAboveMaxDistance(state, true) && !isAuctionSituation) {
    return [
      date,
      moment(date)
        .add(1, 'day')
        .format('YYYY-MM-DD'),
    ];
  }
  return [date];
};

export const getPickupAvailableDate = state => getStopAvailableDate(state, StopType.pickup);
export const getDeliveryAvailableDate = state => getStopAvailableDate(state, StopType.delivery);

export const getStopAvailableTime = (state, stopType: StopType) => {
  // if we are dealing with an instant booking, get the instant booking option and retrieve times from it
  if (getIsInstantBookingSelected(state)) {
    const instantBookingOption = getInstantBookingOption(state);
    const instantBookingOptionTimes = instantBookingOption?.time_options[0];
    if (instantBookingOptionTimes?.start && instantBookingOptionTimes.end) {
      let startHour = Number(getHoursFromTime(instantBookingOptionTimes.start));
      const startMinutes = getMinutesFromTime(instantBookingOptionTimes.start);
      // when it is delivery and is the endhour we increase the instant booking timeslot with 1 hour
      let endHour = Number(getHoursFromTime(instantBookingOptionTimes.end)) + (stopType === StopType.delivery ? 1 : 0);
      const endMinutes = getMinutesFromTime(instantBookingOptionTimes.end);
      /* TRANSFORM FROM UTC to LOCAL
       * For now this is required because of following reasons:
       * - When we are dealing with an instant booking, we make use of raw times that come from pricing, pricing talks in UTC.
       * - Selected time is presented to the customer in the sidebar, so it needs to be in transformed to local time
       * - Last but not least, we assume in this TR repo that times are in Amsterdam timezone, so upon TR creation
       *  we transform times from Amsterdam to UTC again because Core talks in UTC as well.
       */
      startHour = moment()
        .utc()
        .hour(startHour)
        .local()
        .hour();
      endHour = moment()
        .utc()
        .hour(endHour)
        .local()
        .hour();

      return {
        start: `${startHour}:${startMinutes}`,
        end: `${endHour}:${endMinutes}`,
      };
    }
  }
  const fieldNames = stopType === StopType.pickup ? SubStepFieldNamesPickup : SubStepFieldNamesDelivery;
  return _get(state.form[GeneralFlowForms[stopType]], 'values.' + fieldNames.time, { start: null, end: null });
};

export const getHistoricalAddresses = (state): AddressOption[] => {
  return state.generalTransport.historicalAddresses;
};

// return historical addresses grouped by stop type (pickup/delivery)
export const getGroupHistoricalAddresses = (state: RootState, stopType: StopType): GroupAddress => {
  const addresses = getHistoricalAddresses(state);

  // only show historical addresses with same locality as the destination
  const filteredAddresses = addresses.filter(address => {
    return address.locality === getStopDestinationDetails(state, stopType)?.locality;
  });

  const groupAddresses = {
    [StopType.pickup]: {
      label: String(translate('request_flow.contact.pickup_address')),
      options: [],
    },
    [StopType.delivery]: {
      label: String(translate('request_flow.contact.delivery_address')),
      options: [],
    },
  };

  for (const address of filteredAddresses) {
    if (address.stop_type) {
      groupAddresses[address.stop_type].options.push(address);
    }
  }

  return groupAddresses;
};

export const getStopAvailableDateTime = (state, stopType: StopType): DateTimePeriod[] => {
  const auctionType = getAuctionType(state);
  if (auctionType === AuctionTypes.BVA && stopType === StopType.pickup) {
    const pickupDatetimePeriod = getAuctionPickupDate(state);
    if (pickupDatetimePeriod.start !== '' && pickupDatetimePeriod.end !== '') {
      return [pickupDatetimePeriod];
    }
  }

  const dates = getStopAvailableDate(state, stopType);
  const international = isInternational(state);
  if (international || dates.length === 0) {
    return [];
  }

  const time = getStopAvailableTime(state, stopType);
  let startHour = time?.start ? getHoursFromTime(time.start) : _GENERAL_DEFAULT_TIME_START;
  if (!time.start && stopType === StopType.delivery) {
    // If a pickup time has already been selected, then we are dealing with delivery time.
    // In this case, it's important to utilize the pickup start time as a clamp on the delivery start time.
    // Doing this ensures that the pricing API is able to calculate the correct price structure.
    const pickupTime = getStopAvailableTime(state, StopType.pickup);
    startHour = pickupTime?.start ? getHoursFromTime(pickupTime.start) : _GENERAL_DEFAULT_TIME_START;
  }
  const startMinutes = time?.start ? getMinutesFromTime(time.start) : 0;
  const endHour = time?.end ? getHoursFromTime(time.end) : _GENERAL_DEFAULT_TIME_END;
  const endMinutes = time?.end ? getMinutesFromTime(time.end) : 0;

  return dates.map(date => {
    return {
      start: moment
        .tz(date, _DEFAULT_TIME_ZONE)
        .hour(startHour)
        .minutes(startMinutes)
        .toISOString(),
      end: moment
        .tz(date, _DEFAULT_TIME_ZONE)
        .hour(endHour)
        .minutes(endMinutes)
        .toISOString(),
    };
  });
};

export const getStepKey = state => {
  return state.generalTransport.layout.modal_help_type;
};

export const getPickupAvailableDateTime = state => getStopAvailableDateTime(state, StopType.pickup);
export const getDeliveryAvailableDateTime = state => getStopAvailableDateTime(state, StopType.delivery);

export const isSameDayDelivery = (state): boolean => {
  const pickupDateTime = getPickupAvailableDateTime(state);
  const deliveryDateTime = getDeliveryAvailableDateTime(state);

  if (pickupDateTime.length === 0 || deliveryDateTime.length === 0) {
    // we default to true (in 95% of the cases it is)
    return true;
  }
  return moment(pickupDateTime[0].start).isSame(moment(deliveryDateTime[0].start), 'day');
};

export const datesAvailable = state => {
  const dateForm = getDateForm(state);
  const pickupDate = _get(dateForm, 'values.pickup.date', false);
  const deliveryDate = _get(dateForm, 'values.delivery.date', false);
  return pickupDate && deliveryDate ? true : false;
};

export const getPriceRequestParams = (state, dateOptions = {}): Nullable<{ [key: string]: any }> => {
  const itemSets = normalizeItems(getValidItemSets(state));
  if (itemSets.length === 0) {
    return null;
  }

  const pickupAddress = getPickupPoint(state);
  const deliveryAddress = getDeliveryPoint(state);
  const pickupDetails = getPickupDetails(state);
  const deliveryDetails = getDeliveryDetails(state);
  const pickupDateTime = getPickupAvailableDateTime(state);
  const deliveryDateTime = getDeliveryAvailableDateTime(state);
  const chosenPackage = getChosenPackage(state);

  // set default title for itemSets
  // @TODO: this should not be done with map, as we are mutating data instead of returning new values.
  itemSets.map(itemSet => itemSet.items.map(item => (item.title = _get(item, 'title', ''))));

  const pickup = {
    address: pickupAddress ? { lat: pickupAddress.latitude, lng: pickupAddress.longitude } : _DEFAULT_LOCATION.address,
    details: pickupDetails,
  };

  // Check on dateOption key is required, because when fetching for more dates this is set, and then we don't want to send the selected date
  if (
    pickupDateTime.length !== 0 &&
    _get(pickupDateTime, '[0]start', false) !== null &&
    !dateOptions.hasOwnProperty(StopType.pickup)
  ) {
    // Pricing API can only handle one date time period, so we pick the first
    pickup['available_datetime_period'] = pickupDateTime[0];
  }
  const delivery = {
    address: deliveryAddress
      ? { lat: deliveryAddress.latitude, lng: deliveryAddress.longitude }
      : _DEFAULT_LOCATION.address,
    details: deliveryDetails,
  };

  // Check on dateOption key is required, because when fetching for more dates this is set, and then we don't want to send the selected date
  if (
    deliveryDateTime.length !== 0 &&
    _get(deliveryDateTime, '[0]start', false) !== null &&
    Object.keys(dateOptions).length === 0
  ) {
    // Pricing API can only handle one date time period, so we pick the first
    delivery['available_datetime_period'] = deliveryDateTime[0];
  }

  const selectedPickupDate = getPickupAvailableDate(state);

  // Big fat note, when dealing with dates only, use native JS (as proposed by moment-timezone itself, it is correct because we are loading as small set of history of timezone).
  let pickupDatesStartFrom = dateOptions?.[StopType.pickup];

  const auctionType = getAuctionType(state);
  // In bva auction situation if there's collection date set the pickup date start from that date
  if (auctionType === AuctionTypes.BVA && Object.keys(dateOptions).length === 0) {
    const collectionDate = _get(getAuctionPickupDate(state), 'start');
    if (collectionDate) {
      pickupDatesStartFrom = moment(collectionDate);
    }
  }

  const payload = {
    item_sets: itemSets,
    pickup,
    delivery,
    pickup_dates_start_from: moment(pickupDatesStartFrom).format('YYYY-MM-DD'),
    session_ids: getSessionIds(),
    frontend_url: window.location.href,
  };
  // If we have a pickup date, then we would like to send that as start date for delivery
  if (selectedPickupDate.length) {
    payload['delivery_dates_start_from'] = moment(dateOptions?.[StopType.delivery] || selectedPickupDate[0]).format(
      'YYYY-MM-DD'
    );
  }
  const location = getLocation(state);
  if (chosenPackage && !location?.pathname.includes(_DATE_BVA_COLLECTION_PATH)) {
    payload['package'] = chosenPackage;
  }

  return payload;
};

// return true when when we need to wait for something in the flow
export const isLoading = (state: RootState) => {
  return (
    state.generalTransport.price.isLoading ||
    state.payment.paymentInfo?.isSubmitting ||
    state.generalTransport.isSubmitting
  );
};

export const getIsPricingIndicatingHeavyWeightTransport = (state: RootState): boolean => {
  const quoteItems = getQuoteItems(state);
  return quoteItems.some(quoteItem => {
    const match = quoteItem.extra_care?.match;
    return match && ['requires_equipment', 'scooter'].includes(match);
  });
};

export const getIsTransportHeavyWeight = (state: RootState): boolean => {
  /*
   * There are two reasons a transport can be indicated as heavy weight
   * - By user entering a high value weight
   * - By indication of pricing
   */
  const weightFormValues = getFormValues(GeneralFlowForms.weight)(state);
  const isPricingIndicating = getIsPricingIndicatingHeavyWeightTransport(state);
  return weightFormValues?.[WeightFields.WEIGHT] > _MAX_NORMAL_WEIGHT || isPricingIndicating;
};

export const getValidItemSets = (state: RootState): ItemSet[] => {
  const itemForm = state.form[GeneralFlowForms.items];
  let itemSets: ItemSet[] = _get(itemForm, 'values.itemSets', []);
  if (!itemSets) {
    return [];
  }
  // filter out wrong items, and add weight
  itemSets = itemSets.map((itemSet: ItemSet) => {
    return {
      ...itemSet,
      items: itemSet.items.filter((item: Item) => isItemValid(item)),
    };
  });
  // return itemSets with more then 0 items
  return itemSets.filter(itemSet => itemSet.items.length > 0);
};

export const getItem = (state, index) => {
  const itemForm = state.form[GeneralFlowForms.items];
  if (_get(itemForm, `values.itemSets[0].items[${index}]`, false)) {
    return itemForm.values.itemSets[0].items[index];
  } else {
    return null;
  }
};

export const isItemValid = (item: Item): boolean => {
  const width = _get(item, 'width');
  const height = _get(item, 'height');
  const len = _get(item, 'length');
  const count = _get(item, 'count');
  return (
    typeof width === 'number' &&
    !isNaN(width) &&
    typeof height === 'number' &&
    !isNaN(height) &&
    typeof len === 'number' &&
    !isNaN(len) &&
    typeof count === 'number' &&
    !isNaN(count)
  );
};

export const getAddressFields = (state, isPickup) => {
  const form = getContactsForm(state);
  const key = isPickup ? StopType.pickup : StopType.delivery;
  if (
    _get(form, `values.${key}.address.line1`, false) &&
    _get(form, `values.${key}.address.postal_code`, false) &&
    _get(form, `values.${key}.address.locality`, false)
  ) {
    return {
      line1: form.values[key].address.line1,
      postal_code: form.values[key].address.postal_code,
      locality: form.values[key].address.locality,
    };
  } else {
    return null;
  }
};
export const getAddressGeoPoint = (state, isPickup: boolean) => {
  const form = _get(getContactsForm(state), 'values', null);
  const stopType = isPickup ? StopType.pickup : StopType.delivery;
  const widgetDetails: DestinationStop | null = getStopDestinationDetails(state, stopType);
  // return updated geopoints, with fallback to autocomplete
  const lat = _get(widgetDetails, 'latitude', null);
  const lng = _get(widgetDetails, 'longitude', null);
  if (!form) {
    return {
      lat,
      lng,
    };
  }
  return {
    lat: _get(form, stopType + '.address.lat', lat),
    lng: _get(form, stopType + '.address.lng', lng),
  };
};

export const getCustomTimePeriodOptions = (state: any, stopType: StopType): PricedTimePeriodOption[] => {
  const result: PricedTimePeriodOption[] = [];
  let startTime = 9;
  if (isSameDayDelivery(state) && stopType === StopType.delivery) {
    const pickupForm = state.form[GeneralFlowForms.pickup];
    const pickupTimeStart = _get(pickupForm, 'values.pickup.time.start', 0);
    startTime = parseInt(pickupTimeStart, 10);
  }
  for (let i = startTime; i <= 18; i++) {
    const endTime = i + _TIME_SLOT_LENGTH;
    result.push(
      new PricedTimePeriodOption(`${i}:00`, `${endTime}:00`, getCustomTimeExtraFee(state, stopType, i, endTime))
    );
  }
  return result;
};

export const getReferrer = state => {
  return state.generalTransport.referrer || 'https://www.brenger.nl';
};

export const getPickupAdditions = (state, includeHelp): Addition[] =>
  getStopAdditions(state, StopType.pickup, includeHelp);
export const getDeliveryAdditions = (state, includeHelp): Addition[] =>
  getStopAdditions(state, StopType.delivery, includeHelp);
export const getStopAdditions = (state, stopType: StopType, includeHelp: boolean = false): Addition[] => {
  const additions: Addition[] = [];

  // Situation
  const stopSituation = getStopSituation(state, stopType, false);
  if (stopSituation !== null && stopSituation !== Situations.AUCTION) {
    additions.push({
      transKey: 'request_flow.summary.situation.' + stopSituation,
      price: 0,
    });
  }
  // Help
  if (includeHelp) {
    const stopHelp = getStopHelp(state, stopType);
    if (stopHelp !== null || stopHelp !== CarryingHelpChoices.NOT_NEEDED) {
      const amount = stopHelp === CarryingHelpChoices.NEEDED ? 0 : getCarryHelpExtraCharge(state);
      additions.push({
        transKey: 'request_flow.summary.carrying_help.' + stopHelp,
        price: amount,
      });
    }
  }
  // Home situation
  const stopHomeSituation = getStopHomeSituation(state, stopType);
  if (stopHomeSituation !== null && stopHomeSituation !== HomeSituations.inside) {
    additions.push({
      transKey: 'request_flow.summary.situation.home_ready',
      price: 0,
    });
  }

  // Other additions
  let priceAdditions = _get(getPriceStructure(state), stopType, null);
  if (priceAdditions !== null) {
    priceAdditions = priceAdditions.filter(item => _get(item, 'kind', '') !== CarryingHelpChoices.NOT_NEEDED);
    priceAdditions.map(addition => {
      let transKey =
        'request_flow.summary.' +
        (addition.charge === 'floor' && addition.level === 0 ? 'ground_level' : addition.charge);
      if (addition.hasOwnProperty('kind')) {
        transKey += '.' + addition.kind;
      }
      additions.push({
        beforeText: `${
          _get(addition, 'level', null) && addition.charge !== 'elevator' ? floorAsString(addition.level) : ''
        }`,
        transKey,
        price: addition.amount,
      });
    });
  }

  // Date
  const dates = getStopAvailableDate(state, stopType);
  const time = getStopAvailableTime(state, stopType);
  if (dates.length === 1) {
    additions.push({
      text: String(
        `${moment(dates[0]).format('D MMM')} ${
          time.start ? String(time.start) + '-' + (time.end ? String(time.end) : '') : ''
        }`
      ),
      price: null,
    });
  }

  if (dates.length > 1) {
    additions.push({
      text: `${printDatesAsString(dates)} ${
        time.start ? String(time.start) + '-' + (time.end ? String(time.end) : '') : ''
      }`,
      price: null,
    });
  }

  return additions;
};

export const getGlobalAdditions = (state): Addition[] => {
  let priceAdditions = _get(getPriceStructure(state), 'global', null);
  if (priceAdditions === null) {
    return [];
  }

  // check if we need to print help
  const pickupHelp = getPickupHelp(state);
  const deliveryHelp = getDeliveryHelp(state);
  if (pickupHelp === CarryingHelpChoices.NOT_NEEDED && deliveryHelp === CarryingHelpChoices.NOT_NEEDED) {
    // remove help not_needed, since there is no answer of user yet
    priceAdditions = priceAdditions.filter(item => _get(item, 'kind', '') !== 'not_needed');
  }

  // Other price additions
  priceAdditions = priceAdditions.filter(item => _get(item, 'kind', '') !== 'needed');
  // filter out 'needed' help situation
  priceAdditions = priceAdditions.filter(item => _get(item, 'kind', '') !== CarryingHelpChoices.NOT_NEEDED);
  return priceAdditions.map((addition, index) => {
    let transKey = 'request_flow.summary.' + addition.charge;
    if (addition.hasOwnProperty('kind')) {
      transKey += '.' + addition.kind;
    }
    return {
      transKey,
      price: addition.amount,
    };
  });
};

// return the current pickup step in the flow based on route path
export const getPickupStep = (state: any): number => {
  const pathname = state.router.location.pathname;
  if (matchPath(pathname, { path: `${prefix}/pickup/invoice`, exact: true, strict: false })) {
    return 8;
  } else if (
    matchPath(pathname, { path: `${prefix}/pickup/time`, exact: true, strict: false }) ||
    matchPath(pathname, { path: `${prefix}/delivery/time`, exact: true, strict: false })
  ) {
    return 5;
  } else if (
    matchPath(pathname, { path: `${prefix}/pickup/help`, exact: true, strict: false }) ||
    matchPath(pathname, { path: `${prefix}/pickup/home_and_floor`, exact: true, strict: false }) ||
    matchPath(pathname, { path: `${prefix}/pickup/home/floors`, exact: true, strict: false }) ||
    matchPath(pathname, { path: `${prefix}/pickup/home/elevator`, exact: true, strict: false }) ||
    matchPath(pathname, { path: `${prefix}/pickup/help/equipment`, exact: true, strict: false })
  ) {
    return 6;
  } else if (matchPath(pathname, { path: `${prefix}/pickup/auctions/bva`, exact: true, strict: false })) {
    return 3;
  } else {
    return 2;
  }
};

// return the current delivery step in the flow based on route path
export const getDeliveryStep = (state: any): number => {
  const pathname = state.router.location.pathname;
  if (
    matchPath(pathname, { path: `${prefix}/pickup/time`, exact: true, strict: false }) ||
    matchPath(pathname, { path: `${prefix}/delivery/time`, exact: true, strict: false })
  ) {
    return 5;
  } else if (
    matchPath(pathname, { path: `${prefix}/delivery/help`, exact: true, strict: false }) ||
    matchPath(pathname, { path: `${prefix}/delivery/home_and_floor`, exact: true, strict: false }) ||
    matchPath(pathname, { path: `${prefix}/delivery/home/floors`, exact: true, strict: false }) ||
    matchPath(pathname, { path: `${prefix}/delivery/home/elevator`, exact: true, strict: false }) ||
    matchPath(pathname, { path: `${prefix}/delivery/help/equipment`, exact: true, strict: false })
  ) {
    return 6;
  } else {
    return 4;
  }
};

// reducers
export const generalTransport = (state: GeneralTransport = defaultState, action): GeneralTransport => {
  switch (action.type) {
    case types.PUSH_NOTIFICATION:
      return {
        ...state,
        errors: action.payload,
      };
    case types.SET_SELECTED_PICKUP_DATETIME:
      const expirationDate = moment.utc().add('days', 1);
      expirationDate.set('hours', 0);
      expirationDate.set('minutes', 0);
      expirationDate.set('seconds', 0);
      return {
        ...state,
        selectedPickupDateTime: expirationDate,
      };
    case types.PICKUP_SELECT_DATE_TIME_EXPIRED:
      return {
        ...state,
        selectedPickupDateTime: null,
      };
    case types.SET_PICKUP_AVAILABLE_DATES:
      return {
        ...state,
        price: {
          ...state.price,
          pickupAvailableDates: action.payload,
        },
      };
    case types.SET_INTERNATIONAL:
      return {
        ...state,
        request: {
          ...state.request,
          international: action.payload,
        },
      };
    case types.SET_DELIVERY_AVAILABLE_DATES:
      return {
        ...state,
        price: {
          ...state.price,
          deliveryAvailableDates: action.payload,
        },
      };
    case types.SET_SUBMITTING_STATUS:
      return {
        ...state,
        isSubmitting: action.payload,
      };
    case types.SET_TRANSPORT_UUID:
      return {
        ...state,
        request: {
          ...state.request,
          uuid: action.payload,
        },
      };
    case types.CREATE_TRANSPORT_REQUEST_START:
      return {
        ...state,
        price: {
          ...state.price,
          isLoading: true,
        },
      };
    case types.RESET_RETRY_ATTEMPTS:
      return {
        ...state,
        price: {
          ...state.price,
          retryAttempt: 0,
        },
      };
    case types.PRICE_API_RETRY_ATTEMPT_INCREMENT:
      let retryAttempts = state.price.retryAttempt;
      if (retryAttempts > 3) {
        retryAttempts = 0;
      }
      retryAttempts++;
      return {
        ...state,
        price: {
          ...state.price,
          retryAttempt: retryAttempts,
          failedAttempts: true,
        },
      };
    case types.CREATE_TRANSPORT_REQUEST_FAILURE:
      return {
        ...state,
        price: {
          ...state.price,
          isLoading: false,
        },
        errors: action.payload,
      };
    case types.SET_PRICE:
      return {
        ...state,
        price: {
          ...state.price,
          amount: action.payload,
          isLoading: false,
        },
      };
    case types.SET_PRICE_STRUCTURE:
      return {
        ...state,
        priceStructure: action.payload,
      };
    case types.SET_PRICE_LOADING:
      return {
        ...state,
        price: {
          ...state.price,
          isLoading: action.payload,
        },
      };
    case types.SET_PRICE_SPECIAL:
      return {
        ...state,
        price: {
          ...state.price,
          special: {
            ...state.price.special,
            [action.payload]: action.isOnSpecial,
          },
        },
      };
    case types.SET_EXAMPLE_PRICES:
      return {
        ...state,
        examples: {
          isOpen: true,
          prices: action.payload,
        },
      };
    case types.SET_EXAMPLE_OPEN:
      return {
        ...state,
        examples: {
          ...state.examples,
          isOpen: action.payload,
        },
      };
    case types.EXTEND_CACHE_EXPIRATIONS:
      return {
        ...state,
        [DEFAULT_KEY]: generateCacheTTL(60000 * 60 * 24), // 24hr expiration
      };
    case types.SET_PROGRESS_STEP:
      return {
        ...state,
        layout: {
          ...state.layout,
          step: action.payload,
        },
      };
    case types.POP_FOOTER_NOTIFICATION:
      return {
        ...state,
        notifications: {
          type: action.payload.type,
          message: action.payload.message,
          visible: true,
        },
      };
    case types.SET_UPLOADED_INVOICE_NAMES:
      if (action.payload) {
        return {
          ...state,
          layout: {
            ...state.layout,
            uploaded_invoice_names: action.payload,
          },
        };
      } else {
        return state;
      }
    case types.HIDE_FOOTER_NOTIFICATION:
      return {
        ...state,
        notifications: {
          type: '',
          message: '',
          visible: false,
        },
      };
    case types.TOGGLE_SHEET:
      return {
        ...state,
        layout: {
          ...state.layout,
          sheet: !state.layout.sheet,
        },
      };
      break;
    case types.TOGGLE_MORE_INFO:
      return {
        ...state,
        layout: {
          ...state.layout,
          more_info: !state.layout.more_info,
        },
      };
      break;
    case types.SET_HELP_OPEN:
      return {
        ...state,
        layout: {
          ...state.layout,
          modal_help: action.payload,
        },
      };
      break;
    case types.SET_CUSTOM_TIME_PREFERENCE:
      return {
        ...state,
        request: {
          ...state.request,
          customTime: action.payload,
        },
      };
      break;
    case types.SET_HISTORICAL_ADDRESSES:
      const addresses = action.payload;
      addresses.map(address => {
        address['value'] = address['@id'];
        address['label'] = getAddressLabel(address);
        return address;
      });
      return {
        ...state,
        historicalAddresses: addresses,
      };
      break;
    case types.SET_TIME_MODAL_OPEN:
      return {
        ...state,
        layout: {
          ...state.layout,
          modal_time: action.payload,
        },
      };
      break;
    case types.SET_HELP_TYPE:
      return {
        ...state,
        layout: {
          ...state.layout,
          modal_help_type: action.payload,
        },
      };
    case types.SET_REFERRER:
      return {
        ...state,
        referrer: action.payload,
      };
    case types.SET_QUOTES_DATA:
      return {
        ...state,
        price: {
          ...state.price,
          failedAttempts: false,
        },
        quotes: action.payload,
      };
    case types.SET_PRICE_LIST:
      return {
        ...state,
        priceList: action.payload,
      };
    case types.SET_DESTINATION_DETAILS:
      return {
        ...state,
        destination: {
          ...state.destination,
          [action.stopType]: action.payload,
        },
      };

    case types.RESET_PRICE:
      return {
        ...state,
        price: defaultState.price,
        priceList: defaultState.priceList,
        priceStructure: defaultState.priceStructure,
        quotes: defaultState.quotes,
      };

    case types.RESET_DESTINATION_DETAILS:
      return {
        ...state,
        destination: defaultState.destination,
      };
    case types.RESET_FORMS:
      return defaultState;
    default:
      return state;
  }
};

export default generalTransport;

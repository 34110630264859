import React from 'react';
import { Translate } from 'react-localize-redux';

import {
  CirclePerson2,
  Col,
  Heading,
  IconCheckmarkCircle,
  IconSmile,
  Row,
  SectionWrapper,
} from '../../../brenger-shared-ui';
import { actions } from '../ducks';
import { translate } from '../../../utils/localization';
import { connect } from 'react-redux';

interface Props {
  resetIkeaForms: any;
}

const IkeaPaymentSuccess: React.FC<Props> = props => {
  React.useEffect(() => {
    props.resetIkeaForms();
  }, []);
  return (
    <>
      <SectionWrapper sectionStyle={'primary'} centerContent={true}>
        <Row>
          <Col xs={12} extraClasses={'text--center thank-you-opening'}>
            <CirclePerson2 />
            <IconCheckmarkCircle />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8} extraClasses={'offset-md-2'}>
            <Row>
              <Col xs={12} extraClasses={'pt-1 pb-1'}>
                <Heading size={4}>
                  <Translate id={'request_flow.IKEA.thank_you.title'} />
                </Heading>
              </Col>
              <Col xs={12}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: String(translate('request_flow.IKEA.thank_you.further_steps')),
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </SectionWrapper>
      <SectionWrapper sectionStyle={'white'} extraClasses={'profile-dashboard-wrapper'} centerContent={true}>
        <Row>
          <Col xs={12} md={8} extraClasses={'offset-md-2 pb-1'}>
            <Heading size={5}>
              <Translate id={'request_flow.IKEA.thank_you.sub_title'} />
            </Heading>
          </Col>
          <Col xs={12} md={8} extraClasses={'offset-md-2'}>
            <ul className={'line-list'}>
              <li className={'line-list-item'}>
                <a
                  className={'trigger line-list-item-label with-icon with-icon--right'}
                  href={'/nl/customer/partner/ikea/transport_request/pickup_details'}
                >
                  <Translate id={'request_flow.IKEA.thank_you.another_request'} />
                  <IconSmile />
                </a>
              </li>
              <li className={'line-list-item'}>
                <a className={'line-list-item-label with-icon with-icon--right'} href={'https://brenger.nl'}>
                  <Translate id={'request_flow.thank_you.back_to'} data={{ linkname: 'Brenger' }} />
                  <IconSmile />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </SectionWrapper>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  resetIkeaForms: () => dispatch(actions.resetForms()),
});

export default connect(null, mapDispatchToProps)(IkeaPaymentSuccess);

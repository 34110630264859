import * as React from 'react';
import { Translate } from 'react-localize-redux';
import {
  Button,
  CirclePerson2,
  Col,
  Heading,
  IconCheckmarkCircle,
  Row,
  SectionWrapper,
} from '../../../brenger-shared-ui';
import { translate } from '../../../utils/localization';
import { AllProps as PaymentContainerProps } from '../../Payment/components/ThankYou';
import { Props as NotGuaranteedThankYouContainerProps } from '../containers/NotGuaranteedThankYou';

interface OwnProps {
  customTransKey?: string;
}

type Props = Partial<PaymentContainerProps> & Partial<NotGuaranteedThankYouContainerProps> & OwnProps;

const PaymentSuccess: React.FC<Props> = props => {
  const isGuaranteedTranslationKey = props.isGuaranteed ? 'guaranteed' : 'not_guaranteed';

  return (
    <>
      <SectionWrapper sectionStyle={'primary'} centerContent={true}>
        <Row>
          <Col xs={12} extraClasses={'text--center thank-you-opening'}>
            <CirclePerson2 />
            <IconCheckmarkCircle />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8} extraClasses={'offset-md-2'}>
            <Row>
              <Col xs={12} extraClasses={'pt-1 pb-1'}>
                <Heading size={4}>
                  {props.payByInvoice ? (
                    <Translate
                      id={`request_flow.general.thank_you.${isGuaranteedTranslationKey}.pay_by_invoice.title`}
                    />
                  ) : (
                    <Translate id={`request_flow.general.${props.customTransKey || 'thank_you'}.title`} />
                  )}
                </Heading>
              </Col>
              <Col xs={12}>
                {props.payByInvoice ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: String(
                        translate(
                          `request_flow.general.thank_you.${isGuaranteedTranslationKey}.pay_by_invoice.further_steps`
                        )
                      ),
                    }}
                  />
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: String(
                        translate(`request_flow.general.${props.customTransKey || 'thank_you'}.further_steps`)
                      ),
                    }}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </SectionWrapper>
      <SectionWrapper sectionStyle={'white'} extraClasses={'profile-dashboard-wrapper'} centerContent={true}>
        <Row>
          {!props.user?.loggedIn && (
            <Col xs={12} md={8} extraClasses={'offset-md-2'}>
              <p>
                <Translate id={'request_flow.general.thank_you.make_account_explain'} />
              </p>
              <Button
                fullWidth={true}
                onClick={() => props.uuid && props.createAccount && props.createAccount(props.uuid)}
                buttonStyle={'outline'}
              >
                <Translate id={`request_flow.general.thank_you.make_account`} />
              </Button>
            </Col>
          )}
          {props.uuid && (
            <>
              <Col xs={12} md={8} extraClasses={'offset-md-2'}>
                <b>
                  <Translate id={`request_flow.general.${props.customTransKey || 'thank_you'}.sub_title`} />
                </b>
                <Button
                  fullWidth={true}
                  onClick={() => props.uuid && props.trackPage && props.trackPage(props.uuid)}
                  buttonStyle={'primary'}
                >
                  <Translate id={`request_flow.general.thank_you.track_and_trace_request`} />
                </Button>
              </Col>
            </>
          )}
        </Row>
      </SectionWrapper>
    </>
  );
};

export default PaymentSuccess;

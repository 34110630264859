import React from 'react';
import { connect } from 'react-redux';
import '../../../assets/status-page/opening.css';
import { actions, getChatOpenState } from '../ducks';
import { Modal } from '../../../brenger-shared-ui';
import Chat from '../../Chat';

interface Props {
  messages?: any[];
  activeUser?: object;
  setChatOpen?: any;
  chatIsOpen: boolean;
  transport?: any;
  driver: any;
  reject(): object;
  confirm(): object;
}

class ChatModal extends React.Component<Props> {
  public render() {
    return (
      <Modal modalStyle={'chat'} open={this.props.chatIsOpen} onClick={this.props.setChatOpen}>
        <Chat transport_request={this.props.transport} closeChat={this.props.setChatOpen} />
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  chatIsOpen: getChatOpenState(state),
  transport: state.statusPage,
});

const mapDispatchToProps = dispatch => ({
  setChatOpen: () => dispatch(actions.setChatOpen(false, null)),
  confirm: id => dispatch(actions.confirmAccountLink(id)),
  reject: id => dispatch(actions.rejectAccountLink(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatModal);

import React from 'react';
import { Card, Col, Heading, LocationDisplay, Row, SectionWrapper } from '../../../brenger-shared-ui';
import { priceAsString } from '../../../utils/price';
import { getItemSetsVolumeAsString } from '../../../utils/itemSet';
import _get from 'lodash/get';
import moment from 'moment-timezone';
import { getTrTitle, getTrUuid } from '../../../utils/TransportRequest';

export class TrCard extends React.Component<any, any> {
  public render() {
    let items: any = null;
    const title = getTrTitle(this.props.tr);
    if (this.props.tr.source_flow !== 'BVA' && this.props.tr.source_flow !== 'IKEA') {
      items = this.props.tr.item_sets[0].items.map(itemSet => {
        return {
          width: itemSet.width,
          height: itemSet.height,
          count: itemSet.count,
          length: itemSet.length,
        };
      });
    }
    return (
      <Card
        onClick={() => this.props.openSingleView(getTrUuid(this.props.tr['@id']))}
        extraClasses={'card--transport-request card--shade-2'}
      >
        <SectionWrapper sectionStyle={'primary-light'}>
          <Heading size={5} extraClasses={'transport-request-head no-margin'}>
            <span className={'transport-request-head--label'}>
              {title} (#{this.props.tr['short_id']})
            </span>
            <span className={'transport-request-head--price'}>{priceAsString(this.props.tr.price.amount)}</span>
          </Heading>
          <div className={'pt-0-25'}>{items && getItemSetsVolumeAsString(items)}</div>
        </SectionWrapper>
        <div className={'pt-1'}>
          {this.props.tr.pickups &&
            this.props.tr.pickups.map((pickup, index) => {
              return (
                <Row key={`pickup-${index}`}>
                  <Col xs={8}>
                    <LocationDisplay locationName={pickup.address.locality} isPickup={true} color={'black'}>
                      <div className={'loaction-address text--grey'}>
                        {pickup.address.line1} <div className={'inline-block'}>{pickup.address.postal_code}</div>
                      </div>
                    </LocationDisplay>
                  </Col>
                  <Col xs={4}>
                    {_get(pickup, 'available_datetime_periods[0].start', false) && (
                      <div className={'location-display--datetime'}>
                        <div className={'location-date'}>
                          {moment(pickup.available_datetime_periods[0].start).format('L')}
                        </div>
                        <div className={'location-time text--grey'}>
                          {moment(pickup.available_datetime_periods[0].start).format('LT')} -{' '}
                          {moment(pickup.available_datetime_periods[0].end).format('LT')}
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              );
            })}
          {this.props.tr.deliveries &&
            this.props.tr.deliveries.map((delivery, index) => {
              return (
                <Row key={`delivery-${index}`}>
                  <Col xs={8}>
                    <LocationDisplay locationName={delivery.address.locality} isPickup={false} color={'black'}>
                      <div className={'loaction-address text--grey'}>
                        {delivery.address.line1} <div className={'inline-block'}>{delivery.address.postal_code}</div>
                      </div>
                    </LocationDisplay>
                  </Col>
                  <Col xs={4}>
                    {_get(delivery, 'available_datetime_periods[0].start', false) && (
                      <div className={'location-display--datetime'}>
                        <div className={'location-date'}>
                          {moment(delivery.available_datetime_periods[0].start).format('L')}
                        </div>
                        <div className={'location-time text--grey'}>
                          {moment(delivery.available_datetime_periods[0].start).format('LT')} -{' '}
                          {moment(delivery.available_datetime_periods[0].end).format('LT')}
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              );
            })}
        </div>
      </Card>
    );
  }
}

export default TrCard;

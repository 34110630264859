import { debounce, put } from 'redux-saga/effects';
import { actions as baseActions } from '../ducks/baseReducer';

export function* setTranslationLoaded() {
  yield put(baseActions.setTranslationsLoaded());
}

export function* LocalizationSaga() {
  yield debounce(100, '@@localize/ADD_TRANSLATION_FOR_LANGUAGE', setTranslationLoaded);
}

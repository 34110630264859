import { _CALCULATE_ROUTE_ENDPOINT, _GEO_AUTOCOMPLETE_ENDPOINT } from './global';
import axios from 'axios';
import { GeoPoint } from '../typings';

export function calculateRoute(pickup: GeoPoint, delivery: GeoPoint) {
  return axios.get(_CALCULATE_ROUTE_ENDPOINT + `?from=${pickup.lat},${pickup.lng}&to=${delivery.lat},${delivery.lng}`);
}

export function searchGeoPoint(query: string) {
  return axios.get(_GEO_AUTOCOMPLETE_ENDPOINT + `?q=${query}`);
}

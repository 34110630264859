import React from 'react';

import { Field } from 'redux-form';
import { Translate } from 'react-localize-redux';
import { pickupDeliverySchema } from '../schema';
import { translate } from '../../../utils/localization';
import { priceAsString } from '../../../utils/price';
import { getTimeSlotSelectOptions } from '../../../utils/time';

import {
  _GENERAL_DEFAULT_TIME,
  _TIME_END_MAX,
  _TIME_END_MIN,
  _TIME_END_MIN_AUCTION,
  _TIME_SLOT_LENGTH,
  _TIME_START_MAX,
  _TIME_START_MAX_AUCTION,
  _TIME_START_MIN,
} from '../../../utils/global';

import {
  Situations,
  SubStepFieldNamesDelivery,
  SubStepFieldNamesPickup,
  TimeElementType,
  TimeStepProps,
} from '../interface';

import {
  FlashMessage,
  Heading,
  IconSmile,
  InputHidden,
  InputTiles,
  Select,
  SheetLayoutContent,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { printDatesAsString } from '../../../utils/datetime';

export interface SubStepTimeState {
  visibleTimeOptions: TimeElementType[];
}

/**
 * SubStepTime: render the available time options
 *
 * @param  {boolean} isPickup
 * @param  {date} date
 * @param  {object} timeOptions
 * @param  {boolean} isNoPreferenceTimeOptionVisible
 * @param  {object} transport
 * @param  {string} situation
 * @return {object} return the rendered time step layout with options
 */

class SubStepTime extends React.Component<TimeStepProps, SubStepTimeState> {
  constructor(props: TimeStepProps) {
    super(props);
    const { isPickup, timeOptions } = this.props;
    const visibleTimeOptions = !isPickup ? timeOptions : timeOptions.slice(0, 4);
    this.state = {
      visibleTimeOptions,
    };
  }
  public loadMoreOptions() {
    this.setState({ visibleTimeOptions: this.props.timeOptions });
  }
  public render() {
    const { isPickup, date, isNoPreferenceTimeOptionVisible, transport, situation, timeOptions } = this.props;
    const fieldNames = isPickup ? SubStepFieldNamesPickup : SubStepFieldNamesDelivery;
    const isAuction = situation === Situations.AUCTION;
    const isAuctionOrStore = isAuction || situation === Situations.STORE;
    const dateValue = date === null ? '' : <span className={'text--primary'}>{printDatesAsString(date)}</span>;
    const availableTimeOptions: any =
      isAuctionOrStore && isPickup ? this.props.openingHours : this.state.visibleTimeOptions;
    const fixedTimeStoreAuction = {
      start: '09:00',
      end: '17:00',
    };
    const fixedTimeRegular = {
      // Clamp the start of the fixed time option to the first available time option.
      // This prevents the fixed time frame from being unrealistic in the UI.
      start: timeOptions[0].start,
      end: _GENERAL_DEFAULT_TIME.end,
    };
    // render the fixed time option
    const fixedTimeOption: TimeElementType[] = [
      {
        value: isAuctionOrStore ? fixedTimeStoreAuction : fixedTimeRegular,
        title: String(
          translate(
            'request_flow.time.first_option.label_' + situation,
            isAuctionOrStore ? fixedTimeStoreAuction : fixedTimeRegular
          )
        ),
        suffix: <span className="text--primary"> + {priceAsString(0)}</span>,
      },
    ];

    // render the custom time-frames for all situations
    const customTimePeriodTiles: TimeElementType[] = availableTimeOptions.map(timePeriodOption => {
      return {
        value: { start: timePeriodOption.start, end: timePeriodOption.end },
        title: `${timePeriodOption.start} - ${timePeriodOption.end}`,
        suffix: <span className="text--primary"> + {priceAsString(timePeriodOption.priceChange)}</span>,
      };
    });
    return (
      <>
        <SheetLayoutHeader>
          <Heading size={2}>
            <Translate id={this.props.title} data={{ date: dateValue }} />
          </Heading>
        </SheetLayoutHeader>

        <SheetLayoutContent>
          <div className={'question-wrapper'}>
            {isNoPreferenceTimeOptionVisible && (
              <div className={'pb-1'}>
                <div className="text--bold pb-0-5">
                  <Translate id={'request_flow.time.first_option.introduction_' + situation} />
                </div>
                <Field
                  compact={!isAuctionOrStore}
                  name={fieldNames.time}
                  validate={pickupDeliverySchema.time}
                  options={fixedTimeOption}
                  component={InputTiles}
                />
              </div>
            )}
            {/* Only show the title when 'no preference option' is visible */}
            {isNoPreferenceTimeOptionVisible && (
              <div className="pb-0-5">
                <div className="text--bold">
                  <Translate id={this.props.subTitle} />
                </div>
                {isAuctionOrStore && (
                  <div>
                    <Translate id={'request_flow.time.custom_time_explain'} data={{ time_slot: _TIME_SLOT_LENGTH }} />
                  </div>
                )}
              </div>
            )}
            {/* When situation is home, we let the user select a time slot trough tiles */}
            {!isAuctionOrStore && (
              <div className={'fixed-time-option'}>
                <Field
                  compact={true}
                  maxDelayIndex={7}
                  name={fieldNames.time}
                  validate={pickupDeliverySchema.time}
                  options={customTimePeriodTiles}
                  component={InputTiles}
                />
                {customTimePeriodTiles.length <= 5 && !isAuctionOrStore && (
                  <span
                    onClick={() => this.loadMoreOptions()}
                    className={'btn btn--outline btn--full-width btn--icon-left text--left more-days'}
                  >
                    <span className={'icon-wrapper'}>
                      <IconSmile />
                    </span>
                    <Translate id={'request_flow.time.show_more_times'} />
                  </span>
                )}
              </div>
            )}
            {/* When situation is auction or store, we let the user select a time slot trough selects */}
            {isAuctionOrStore && (
              <div className="trigger input-el--tile--option delay-index-0 input-el--tile--option--align-top input-el--tile--option--time">
                <div className={'input-el--tile--option--time-fields pt-1'}>
                  <Field
                    validate={pickupDeliverySchema.time_start}
                    name={`${fieldNames.time}.start`}
                    label={<Translate id={'request_flow.time.fields.time_start.label'} />}
                    options={getTimeSlotSelectOptions(
                      _TIME_START_MIN,
                      isAuction ? _TIME_START_MAX_AUCTION : _TIME_START_MAX,
                      30
                    )}
                    component={Select}
                  />
                  <Field
                    validate={pickupDeliverySchema.time_end}
                    name={`${fieldNames.time}.end`}
                    label={<Translate id={'request_flow.time.fields.time_end.label'} />}
                    options={getTimeSlotSelectOptions(
                      isAuction ? _TIME_END_MIN_AUCTION : _TIME_END_MIN,
                      _TIME_END_MAX,
                      30
                    )}
                    component={Select}
                  />
                </div>
              </div>
            )}

            <Field name={`setPickupToDeliveryTime`} component={InputHidden} />
            <div className={'general-errors-wrapper'}>
              {transport.errors && transport.errors !== '' && (
                <FlashMessage type={'error'} message={transport.errors} />
              )}
            </div>
          </div>
        </SheetLayoutContent>
      </>
    );
  }
}

export default SubStepTime;

import React from 'react';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import {
  Heading,
  InputFileUploads,
  Select,
  SheetLayoutContent,
  SheetLayoutContentWrapper,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { Translate } from 'react-localize-redux';
import { getSelectedStoreAddress, getSelectedStoreData, getWarehouseChoice } from '../ducks';
import { pickupSchema } from '../schema';
import { IkeaFlowFields, IkeaFlowForms, IKEAStoreData, WarehouseOption } from '../interface';
import { StoreLocations } from '../global';
import { translate } from '../../../utils/localization';
import { push } from 'connected-react-router';
import { RootState } from '../../../typings';
import _get from 'lodash/get';
import { prefix } from '../';
import FooterLayout from './FooterLayout';

type ReduxProps = ReturnType<typeof mapStateToProps>;

interface FormProps {
  submitting: boolean;
  handleSubmit(): void;
  reset(): void;
}

type Props = ReduxProps & FormProps;

class PickupDetails extends React.Component<Props> {
  public render() {
    const storeOptions = StoreLocations.filter((store: IKEAStoreData) => store.active).map((store: IKEAStoreData) => {
      return {
        value: store.storeName,
        label: store.storeLabel,
      };
    });
    const warehouseOptions = !this.props.selectedStoreData
      ? []
      : this.props.selectedStoreData.warehouse.map(option => {
          return {
            value: option,
            label: String(translate('request_flow.IKEA.fields.warehouse.options.' + option)),
          };
        });
    const selectedStore = _get(this.props.pickupFormValues, [IkeaFlowFields.PICKUP_LOCATION]);
    const selectedWarehouse = _get(this.props.pickupFormValues, [IkeaFlowFields.WAREHOUSE]);
    const uploadVisible = (selectedStore && selectedStore !== 'eindhoven') || selectedWarehouse;
    const uploadTwice = selectedStore === 'eindhoven' && WarehouseOption.BOTH === selectedWarehouse;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <SheetLayoutContentWrapper>
          <SheetLayoutHeader>
            <Heading size={2}>
              <Translate id={'request_flow.IKEA.pickup_header'} />
            </Heading>
          </SheetLayoutHeader>
          <SheetLayoutContent>
            <div className={'pb-0-5 text--bold'}>
              <Translate id={'request_flow.IKEA.which'} />
            </div>
            <Field
              name={IkeaFlowFields.PICKUP_LOCATION}
              validate={pickupSchema.location}
              component={Select}
              options={storeOptions}
              label={translate('request_flow.fields.select_store.label')}
            />
            {this.props.selectedStoreAddress && (
              <small className={'text--primary pb-0-5'}>{this.props.selectedStoreAddress}</small>
            )}
            <small className={'pb-1'}>
              <Translate id={'request_flow.IKEA.warning'} />
            </small>

            {warehouseOptions.length > 0 && (
              <>
                <div className={'pb-0-5 text--bold'}>
                  <Translate id={'request_flow.IKEA.fields.warehouse.question'} />
                </div>
                <Field
                  name={IkeaFlowFields.WAREHOUSE}
                  validate={pickupSchema.warehouse}
                  component={Select}
                  options={warehouseOptions}
                  label={translate('request_flow.IKEA.fields.warehouse.label')}
                />
              </>
            )}
            {uploadVisible && (
              <>
                <InputFileUploads
                  fieldName={IkeaFlowFields.RECEIPT}
                  formName={IkeaFlowForms.PICKUP_DETAILS}
                  accept={'image/*'}
                  uploadTwice={uploadTwice}
                  validate={pickupSchema.image}
                  headerBold={true}
                  texts={{
                    button: {
                      default: String(translate('request_flow.IKEA.fields.upload_receipt.button.default')),
                      add: String(translate('request_flow.IKEA.fields.upload_receipt.button.add')),
                      delete: String(translate('request_flow.IKEA.fields.upload_receipt.button.delete')),
                      change: String(translate('request_flow.IKEA.fields.upload_receipt.button.change')),
                    },
                    header: String(translate('request_flow.IKEA.fields.upload_receipt.header')),
                  }}
                />
              </>
            )}
          </SheetLayoutContent>
          <FooterLayout submitting={this.props.submitting} />
        </SheetLayoutContentWrapper>
      </form>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  selectedStoreAddress: getSelectedStoreAddress(state),
  selectedStoreData: getSelectedStoreData(state),
  pickupFormValues: getFormValues(IkeaFlowForms.PICKUP_DETAILS)(state),
  warehouseChoice: getWarehouseChoice(state),
});

export default reduxForm({
  form: IkeaFlowForms.PICKUP_DETAILS,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmit: (pickup, dispatch) => dispatch(push(`${prefix}/pickup_options`)),
})(connect(mapStateToProps)(PickupDetails));

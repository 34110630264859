import React from 'react';
import { Translate } from 'react-localize-redux';
import {
  FlashMessage,
  Heading,
  InputFileUploads,
  SheetLayoutContent,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { pickupDeliverySchema } from '../schema';
import { GeneralFlowForms, Situations, SubStepFieldNamesPickup } from '../interface';
import { translate } from '../../../utils/localization';

interface Props {
  errors: string;
  situation: string;
}

const SubStepInvoice = ({ situation, errors }: Props) => {
  const required = situation === Situations.AUCTION;
  return (
    <>
      <SheetLayoutHeader>
        <Heading size={2}>
          <Translate id={`request_flow.pickup.${situation}.invoice_question`} />
        </Heading>
      </SheetLayoutHeader>
      <SheetLayoutContent>
        <div className={'question-wrapper'}>
          <div>
            <div className={'text--bold pb-0-5'}>
              <Translate id={`request_flow.pickup.${situation}.invoice_label`} />
            </div>
            <div className={'pb-1'}>
              <Translate id={`request_flow.pickup.${situation}.invoice_description`} />
            </div>
            <InputFileUploads
              fieldName={SubStepFieldNamesPickup.invoice}
              formName={GeneralFlowForms.pickup}
              accept={'application/pdf,image/*'}
              validate={required ? pickupDeliverySchema.invoice : undefined}
              texts={{
                button: {
                  default: String(translate('request_flow.pickup.invoice.button.default')),
                  add: String(translate('request_flow.pickup.invoice.button.add')),
                  delete: String(translate('request_flow.pickup.invoice.button.delete')),
                  change: String(translate('request_flow.pickup.invoice.button.change')),
                },
                header: String(translate('request_flow.pickup.invoice.header')),
              }}
            />
          </div>
        </div>
        <div className={'general-errors-wrapper'}>
          {errors && errors !== '' && <FlashMessage type={'error'} message={errors} />}
        </div>
      </SheetLayoutContent>
    </>
  );
};

export default SubStepInvoice;

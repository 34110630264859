import { translate } from '../../utils/localization';
import { PaymentFormKey } from './typings';

type PaymentSchemaValidation = { [key in PaymentFormKey]: string | undefined };

export const paymentSchema = (values: PaymentSchemaValidation) => {
  const errors = {
    method: undefined,
    issuer: undefined,
  } as PaymentSchemaValidation;

  if (!values.method) {
    errors.method = translate('form.fields.default.required', { field: translate('form.fields.payment.method.label') });
  }

  if (!values.issuer) {
    errors.issuer = translate('form.fields.default.required', { field: translate('form.fields.payment.issuer.label') });
  }

  return errors;
};

import * as React from 'react';
import './icons.css';

const IconShopBlue = () => {
  return (
    <svg width="75" height="53" viewBox="0 0 75 53">
      <g fill="#0073FA" fillRule="nonzero">
        <path d="M36.25 23.535A1.26 1.26 0 0 0 35 24.796v18.109a1.26 1.26 0 0 0 1.25 1.262h25.188a1.26 1.26 0 0 0 1.25-1.262V24.796a1.26 1.26 0 0 0-1.25-1.261H36.25zm23.938 18.108H37.5V26.058h22.688v15.585zM27.438 37.352H24a1.26 1.26 0 0 0-1.25 1.262A1.26 1.26 0 0 0 24 39.876h3.438a1.26 1.26 0 0 0 1.25-1.262 1.26 1.26 0 0 0-1.25-1.262z" />
        <path d="M.625 9.401c0 3.786 2.625 7.004 6.125 7.887v9.843A1.26 1.26 0 0 0 8 28.393a1.26 1.26 0 0 0 1.25-1.262v-9.59c1.938-.127 3.813-.947 5.188-2.335 1.5 1.451 3.5 2.334 5.687 2.334h.125c2.125 0 4.188-.883 5.688-2.334 1.5 1.451 3.5 2.334 5.687 2.334h.125c2.125 0 4.188-.883 5.688-2.334 1.5 1.451 3.5 2.334 5.687 2.334h.125c2.125 0 4.188-.883 5.688-2.334 1.5 1.451 3.5 2.334 5.687 2.334h.125c2.125 0 4.188-.883 5.688-2.334a8.368 8.368 0 0 0 5.25 2.334v9.591a1.26 1.26 0 0 0 1.25 1.262 1.26 1.26 0 0 0 1.25-1.262v-9.843a8.213 8.213 0 0 0 6-7.319c.312-.379.312-.946.062-1.451L69.187.568A1.225 1.225 0 0 0 68.126 0H7c-.375 0-.813.19-1 .568L.812 8.644s0 .063-.062.063c0 .063-.063.063-.063.126s0 .063-.062.127c0 .063 0 .063-.063.126v.126c.063.063.063.126.063.19 0-.064 0-.064 0 0zm7.063-6.877h59.5L71.5 9.275v.126c0 3.155-2.5 5.679-5.625 5.679-1.813 0-3.5-.884-4.563-2.398-.25-.378-.687-.568-1.062-.505-.438 0-.813.19-1.063.505-1.062 1.514-2.75 2.398-4.562 2.398h-.063-.062c-1.813 0-3.5-.884-4.563-2.398-.25-.378-.687-.568-1.062-.505-.438 0-.813.19-1.063.505-1.062 1.514-2.75 2.398-4.562 2.398h-.063-.062c-1.813 0-3.5-.884-4.563-2.398-.25-.378-.687-.568-1.062-.505-.438 0-.813.19-1.063.505-1.062 1.514-2.75 2.398-4.562 2.398h-.063-.062c-1.813 0-3.5-.884-4.563-2.398-.25-.378-.687-.568-1.062-.505-.438 0-.813.19-1.063.505C24 14.196 22.313 15.08 20.5 15.08h-.063-.062c-1.813 0-3.5-.884-4.563-2.398-.25-.378-.687-.568-1.062-.505-.438 0-.813.19-1.063.505-1.062 1.514-2.75 2.398-4.562 2.398-3 0-5.438-2.335-5.625-5.3l4.188-7.256z" />
        <path d="M71.313 50.476h-3.376v-17.54a1.26 1.26 0 0 0-1.25-1.262 1.26 1.26 0 0 0-1.25 1.262v17.54H32.126v-25.68a1.26 1.26 0 0 0-1.25-1.261H13.312a1.26 1.26 0 0 0-1.25 1.261v25.68H9.25v-17.54A1.26 1.26 0 0 0 8 31.674a1.26 1.26 0 0 0-1.25 1.262v17.54H3.375a1.26 1.26 0 0 0-1.25 1.262A1.26 1.26 0 0 0 3.375 53h67.938a1.26 1.26 0 0 0 1.25-1.262 1.26 1.26 0 0 0-1.25-1.262zm-56.75 0V26.058h15.062v24.418H14.562z" />
      </g>
    </svg>
  );
};

export default IconShopBlue;

export const priceAsString = (cents: number | null, withCurrency: boolean = true): string => {
  if (cents === 0 || cents === null) {
    return (withCurrency ? '€ ' : '') + 0;
  }
  const priceArray = centsToPriceArray(cents);
  return (withCurrency ? '€ ' : '') + (priceArray[1] === '00' ? priceArray[0] : priceArray[0] + ',' + priceArray[1]);
};

export const centsToPriceArray = (cents: number): string[] => {
  if (cents === 0) {
    return ['0', ''];
  }
  return (cents / 100).toFixed(2).split('.');
};

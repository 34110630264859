import { all, put } from 'redux-saga/effects';
import { actions as baseActions } from '../ducks/baseReducer';
import { logException, logger } from '../../utils/basics';
import { actions as paymentActions } from '../../modules/Payment/ducks';
import countries from '../../baseData/countries.json';
import external_parties from '../../baseData/external_parties.json';
import payment_methods from '../../baseData/payment_methods.json';

export function* onInitSaga() {
  try {
    yield all([
      put(baseActions.setCountries(countries)),
      put(baseActions.setExternalParties(external_parties)),
      put(paymentActions.setPaymentMethods(payment_methods)),
    ]);
  } catch (err) {
    // Good lord have mercy
    logger(err);
    logException('Problem loading base data', err);
  }
}

import { GeoPoint, Nullable, StopType } from '../../typings';
import { IKEAItemSetAttributes } from '../IkeaFlow/interface';

import { Address as IAddress, ItemCreationParams, Quote } from '@brenger/api-client';

// INTERFACES & PROP TYPES
export interface Path {
  from: string | GeoPoint;
  to: string | GeoPoint;
}

export interface ItemSet {
  title: string;
  description: string;
  items: ItemCreationParams[];
  attributes?: { [key: string]: string } | IKEAItemSetAttributes;
  external_invoices?: string[];
  external_party?: null | string;
  client_reference?: string;
}

export interface Item {
  title: string;
  width: number;
  height: number;
  length: number;
  count: number;
  weight?: number;
  job_image?: string;
}

export interface driversListInterface {
  list: any;
  setChatOpen: any;
}

export interface ToggleSheetInterface {
  toggleSheet: any;
}

export interface FloorData {
  floor?: number;
  isCustom: boolean;
  floorString: string;
  hasElevator: boolean;
}

export interface GeoPointData extends GeoPoint {
  isDone: boolean;
}

export interface Addition {
  beforeText?: string;
  afterText?: string;
  text?: string;
  transKey?: string;
  price: number | null;
}

// enums
export enum Situations {
  HOME = 'home',
  STORE = 'store',
  AUCTION = 'auction',
  MARKTPLAATS = 'marktplaats',
  OTHER = 'other',
}

export enum HomeSituations {
  outside = 'outside',
  inside = 'inside',
  ground_floor_and_ready = 'ground_floor_and_ready',
  ground_floor_not_ready = 'ground_floor_not_ready',
  floor_with_elevator = 'floor_with_elevator',
  floor_without_elevator = 'floor_without_elevator',
}

// ENUMS
export enum ItemFields {
  TITLE = 'title',
  WIDTH = 'width',
  HEIGHT = 'height',
  LENGTH = 'length',
  COUNT = 'count',
  WEIGHT = 'weight',
  MORE_INFO = 'more_info',
  EXTRA_CARE_MATCH = 'extra_care.match',
  EXTRA_CARE_SELECTION = 'extra_care.selection',
  EXTRA_CARE_INSURED = 'extra_care.insure',
}

export enum User {
  first_name = 'first_name',
  last_name = 'last_name',
  email = 'email',
  phone = 'phone',
}

export interface GroupAddressOption {
  label: string;
  options: AddressOption[];
}

export interface GroupAddress {
  [StopType.pickup]: GroupAddressOption;
  [StopType.delivery]: GroupAddressOption;
}

export interface AddressOption {
  line1: string | null;
  locality: string | null;
  postal_code: string | null;
  lat?: number | null;
  lng?: number | null;
  value?: string;
  label?: string;
  stop_type?: string;
}

export enum Address {
  line1 = 'line1',
  line2 = 'line2',
  country = 'country',
  postal_code = 'postal_code',
  postal_code_company = 'postal_code_company',
  postal_code_business = 'postal_code_business',
  locality = 'locality',
  latitude = 'latitude',
  longitude = 'longitude',
  custom_delivery_date = 'custom_delivery_date',
}

export enum SubStepFieldNamesPickup {
  marktplaats_option = 'pickup.marktplaats_option',
  help_carrying = 'pickup.help_carrying',
  help_equipment = 'pickup.help_equipment',
  situation = 'pickup.situation',
  invoice = 'pickup.invoice',
  other_situation_note = 'pickup.other_situation_note',
  weight_tailgate = 'pickup.weight_tailgate',
  weight_elevator = 'pickup.weight_elevator',
  situation_home = 'pickup.home_situation',
  auction_type = 'pickup.auction_type',
  situation_home_floors_combined = 'pickup.home_floor_situation_combined',
  floor_count = 'pickup.floor_count',
  floor_custom = 'pickup.floor_count_custom',
  floor_elevator = 'pickup.floor_elevator',
  date = 'pickup.date',
  date_custom = 'pickup.date_custom',
  instant_booking = 'instant_booking',
  different_delivery_date = 'pickup.different_delivery_date',
  time = 'pickup.time',
  time_start_custom = 'pickup.time_start_custom',
  time_end_custom = 'pickup.time_end_custom',
  pickup_time = 'delivery.pickupTime',
  pickup_date = 'delivery.pickupDate',
}

export enum WeightFields {
  WEIGHT = 'weight',
}

export enum PackageFields {
  chosen = 'chosen_package',
}

export enum PackageFieldsValues {
  economic = 'economic',
  custom = 'custom',
}

export enum AuctionTypes {
  BVA = 'bva',
  BELGA = 'belgaveilingen',
  TROOSTWIJK = 'troostwijk',
  ONLINEVEILINGMEESTERS = 'onlineveilingmeester',
  VAVATO = 'vavato',
  OTHERS = 'others',
}

export enum Company {
  name = 'name',
  line1 = 'line1',
  country = 'country',
  postal_code = 'postal_code',
  locality = 'locality',
  coc_number = 'coc_number',
  vat_number = 'vat_number',
}

export enum SubStepFieldNamesDelivery {
  marktplaats_option = 'pickup.marktplaats_option',
  help_carrying = 'delivery.help_carrying',
  auction_type = 'delivery.auction_type',
  help_equipment = 'delivery.help_equipment',
  situation = 'delivery.situation',
  other_situation_note = 'delivery.other_situation_note',
  weight_tailgate = 'delivery.weight_tailgate',
  weight_elevator = 'delivery.weight_elevator',
  situation_home = 'delivery.home_situation',
  situation_home_floors_combined = 'delivery.home_floor_situation_combined',
  floor_count = 'delivery.floor_count',
  floor_custom = 'delivery.floor_count_custom',
  floor_elevator = 'delivery.floor_elevator',
  date = 'delivery.date',
  date_custom = 'delivery.date_custom',
  time = 'delivery.time',
  pickup_time = 'delivery.pickupTime',
  pickup_date = 'delivery.pickupDate',
}

export enum SubStepFieldValues {
  date_custom = 'date_custom',
  time_custom = 'time_custom',
  floor_custom = 'floor_custom',
}

export enum PriceLabels {
  rush = 'rush',
  budget = 'budget',
  sustainable = 'sustainable',
  standard = 'budget',
}

export enum PriceNotes {
  belgium_special_pricing = 'belgium_special_pricing',
}

export enum GeneralFlowForms {
  pickup = '@@GeneralFlow/pickup_order_form',
  packages = '@@GeneralFlow/packages_form',
  items = '@@GeneralFlow/items_order_form',
  delivery = '@@GeneralFlow/delivery_order_form',
  date = '@@GeneralFlow/date_form',
  contact = '@@GeneralFlow/contact_order_form',
  terms = '@@GeneralFlow/conditions_order_form',
  mp_seller = '@@GeneralFlow/mp_seller',
  weight = '@@GeneralFlow/weight_form',
  paymentForm = 'paymentForm',
}

export enum customerTypeFields {
  type = 'customer.type',
}

export enum priceListTypes {
  stop_situation_auction = 'stop_situation.auction',
  stop_situation_home = 'stop_situation.home',
  stop_situation_store = 'stop_situation.store',
  custom_timeslot_first = 'custom_timeslot.first',
  custom_timeslot_second = 'custom_timeslot.second',
  carrying_help_equipment_tailgate = 'carrying_help.equipment_tailgate',
  carrying_help_equipment_tailgate_extra_driver = 'carrying_help.tailgate_extra_driver',
  carrying_help_equipment_tailgate_pallet_jack = 'carrying_help.equipment_tailgate_pallet_jack',
  carrying_help_extra_driver = 'carrying_help.extra_driver',
  per_floor_without_extra_help = 'per_floor.without_extra_help',
  per_floor_with_extra_help = 'per_floor.with_extra_help',
}

export enum customTimePreference {
  no_preference = 'no_preference',
  custom_time = 'custom_time',
}

export interface DestinationStop {
  locality: string;
  country_code: string;
  country_name: string;
  latitude: number;
  longitude: number;
}

export interface StopDetailsFee {
  date?: string;
  floorData: FloorData;
  feeExtraPerson: number | null;
  feeTailgate: number | null;
  feeTailgatePalletJack: number | null;
  feeFloor: number | null;
}

export interface DeliveryProps extends StopDetailsFee {
  hasHelp: boolean;
}

export interface PickupProps extends StopDetailsFee {
  openingHours: PricedTimePeriodOption[];
  hasHelp: boolean;
  feeHome?: number | null;
  feeAuction?: number | null;
  feeStore?: number | null;
}

export interface TimeStepProps {
  isPickup: boolean;
  openingHours?: PricedTimePeriodOption[];
  date: string[];
  timeOptions: any;
  isNoPreferenceTimeOptionVisible?: boolean;
  transport: any;
  situation?: string;
  title: string;
  subTitle: string;
}

export interface TimeElementType {
  value: PricedTimePeriodOption;
  title: string;
  suffix: object;
  desc?: string;
}

export interface TimePeriodInterface {
  start: string;
  end: string;
}

export class PricedTimePeriodOption implements TimePeriodInterface {
  public start: string;
  public end: string;
  public priceChange?: number | null; // todo; introduce a price interface

  constructor(start: string, end: string, priceChange: number | null) {
    this.start = start;
    this.end = end;
    this.priceChange = priceChange;
  }
}

export const StoreOpeningHoursTimePeriod: PricedTimePeriodOption[] = [new PricedTimePeriodOption('09:00', '17:00', 0)];
export const AuctionOpeningHoursTimePeriod: PricedTimePeriodOption[] = [
  new PricedTimePeriodOption('09:00', '16:00', 0),
];

export type TitleStepName =
  | 'timeCustomPickup'
  | 'itemSet'
  | 'datePickup'
  | 'dateDelivery'
  | 'datePickupLongDistance'
  | 'dateDeliveryLongDistance'
  | 'timePickup'
  | 'timeDelivery'
  | 'timeCustomPickup'
  | 'timeCustomDelivery'
  | 'helpPickup'
  | 'helpDelivery'
  | 'contactPickup'
  | 'contactDelivery'
  | 'weight';
interface Notifications {
  message: string;
  type: string;
  visible: boolean;
}
interface Layout {
  sheet: boolean;
  step: number;
  modal_time: boolean;
  modal_help: boolean;
  modal_help_type: string;
  more_info: boolean;
  uploaded_invoice_names: any[];
}

interface Examples {
  isOpen: boolean;
  prices: any[];
}

interface PriceSpecial {
  be: boolean;
}

interface AvailableDates {
  available: boolean;
  options: any[];
}
interface Price {
  amount: number;
  isLoading: boolean;
  retryAttempt: number;
  pickupAvailableDates: Nullable<AvailableDates>;
  deliveryAvailableDates: Nullable<AvailableDates>;
  failedAttempts: boolean;
  special: PriceSpecial;
}

export interface GeneralTransport {
  cacheUntil?: number;
  selectedPickupDateTime: Nullable<any>;
  request: {
    uuid: string | null;
    customTime: any;
    customTimePreference: any;
    international: boolean;
  };
  destination: {
    [StopType.pickup]: any;
    [StopType.delivery]: any;
  };
  errors: any;
  price: Price;
  priceStructure: any;
  priceList: any;
  examples: Examples;
  notifications: Notifications;
  layout: Layout;
  referrer: any;
  isSubmitting: boolean;
  quotes: Nullable<Quote>;
  historicalAddresses: IAddress[];
}

export interface DateOption {
  value: string;
  title: string;
  suffix: any;
}

// list of products

export const products = {
  kasten: {
    label: 'Kast',
    length: 190,
    height: 95,
    width: 50,
  },
  bankstellen: {
    label: 'Bank',
    length: 200,
    height: 75,
    width: 100,
  },
  tafels: {
    label: 'Tafel',
    length: 200,
    height: 80,
    width: 95,
  },
  bedden: {
    label: 'Bed',
    length: 200,
    height: 80,
    width: 140,
  },
  stoelen: {
    label: 'Stoel',
    length: 80,
    height: 130,
    width: 70,
  },
  witgoed: {
    label: 'Wasmachine',
    length: 80,
    height: 130,
    width: 70,
  },
  fiets: {
    label: 'Fiets',
    length: 200,
    height: 140,
    width: 70,
  },
  scooter: {
    label: 'Scooter',
    length: 200,
    height: 150,
    width: 80,
  },
  matras: {
    label: 'Matras',
    length: 200,
    height: 50,
    width: 140,
  },
  fauteuil: {
    label: 'Fauteuil',
    length: 90,
    height: 120,
    width: 80,
  },
  bureau: {
    label: 'Bureau',
    length: 160,
    height: 80,
    width: 75,
  },
  dressoir: {
    label: 'Dressoir',
    length: 200,
    height: 100,
    width: 50,
  },
  zetels: {
    label: 'Zetels',
    length: 200,
    height: 75,
    width: 100,
  },
};

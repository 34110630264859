/*
/* BRENGER DATE UTILS
/* ================================================== */

import moment, { Moment } from 'moment-timezone';
import 'moment/locale/nl'; // needed for day and month names etc
import _get from 'lodash/get';
import _uniq from 'lodash/uniq';
import { translate } from './localization';
import { _ACTIVE_LANG } from '../configs/localization';

export const getDayFromNow = (datetime: string | Moment): string => {
  let momentInput = datetime;
  if (!moment.isMoment(datetime)) {
    momentInput = moment(datetime);
  }

  // HISTORY: More than 2 days back, format as simple date
  if (momentInput.isBefore(moment().subtract(2, 'days'))) {
    return momentInput.format(getDateFormatLocale());
  }
  // FUTURE: display a day name
  if (momentInput.isAfter(moment().add(2, 'days'))) {
    return momentInput.format('dddd');
  }
  // TODAY, TOMORROW, YESTERDAY
  return momentInput.calendar().split(' ')[0];
};

export const printDatesAsString = (dates: string[], conjunctionTrans: string = 'default.and'): string => {
  const dateStrings = _uniq(
    dates
      .filter(dateString => dateString !== null)
      .map(dateString => {
        return moment(dateString).format('DD MMM');
      })
  );
  if (dateStrings.length === 0) {
    return '';
  }
  const lastDateString = dateStrings.pop();
  if (dateStrings.length === 0) {
    return lastDateString;
  }

  return `${dateStrings.join(',')} ${translate(conjunctionTrans)} ${lastDateString}`;
};

// return date format based on current locale
export const getDateFormatLocale = (): string => {
  switch (_ACTIVE_LANG) {
    case 'nl':
    case 'be':
      return 'DD-MM-YYYY';
    case 'en':
      return 'MM-DD-YYYY';
    default:
      return 'DD-MM-YYYY';
  }
};

export const getHoursFromTime = (time: string): string => {
  return time.split(':')[0];
};

export const getMinutesFromTime = (time: string): string => {
  return time.split(':')[1];
};

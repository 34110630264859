// contact form between marktplaats buyer and seller
import React from 'react';
import {
  Button,
  Col,
  FlashMessage,
  Heading,
  IconArrow,
  InputHidden,
  PersonBalloon,
  Row,
  SheetLayoutContent,
  SheetLayoutHeader,
  Textarea,
} from '../../../../brenger-shared-ui';
import '../../../../assets/marktplaats/marktplaats.css';
import { actions as flowActions, isMarktplaatsFormSentSucessfully } from '../../ducks';
import { GeneralFlowForms } from '../../interface';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { RouteComponentProps } from 'react-router';
import _get from 'lodash/get';
import { Translate } from 'react-localize-redux';
import { translate } from '../../../../utils/localization';
type Props = InjectedFormProps<{}> & RouteComponentProps<{}>;

class SellerForm extends React.Component<any, any> {
  public componentDidMount(): void {
    const header = document.querySelector('.sheet-layout--content-wrapper--top-header') as HTMLElement;
    header.style.display = 'none';
  }

  public componentWillUnmount(): void {
    const header = document.querySelector('.sheet-layout--content-wrapper--top-header') as HTMLElement;
    header.style.display = 'block';
  }

  public render() {
    const errors = this.props.errors;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div className={'marktplaats-onboarding-form'}>
          <SheetLayoutHeader>
            <Heading size={2}>
              <Translate id={'request_flow.marktplaats.seller_form.title'} />
            </Heading>
          </SheetLayoutHeader>
          <SheetLayoutContent>
            <div className={'question-wrapper'}>
              <p>
                <Translate id={'request_flow.marktplaats.seller_form.subtitle'} />
              </p>
              <Row>
                <Field name={`pickup.marktplaats.result`} type="text" component={InputHidden} />
                <Field
                  name={`pickup.marktplaats.buyer_email`}
                  type="text"
                  component={InputHidden}
                  label={'Jouw e-mailadres'}
                />
                <Field
                  name={`pickup.marktplaats.seller_email`}
                  type="text"
                  component={InputHidden}
                  label={'e-mailadres verkoper marktplaats'}
                />
                <Col xs={12}>
                  <label>
                    <b>
                      <Translate id={'request_flow.marktplaats.answers.first'} />:
                    </b>
                  </label>
                  <div className={'hint'}>
                    <Translate id={'request_flow.marktplaats.placeholders.first'} />
                  </div>
                  <Field name={`pickup.marktplaats.product_description`} type="text" component={Textarea} />
                </Col>
                <Col xs={12}>
                  <label>
                    <b>
                      <Translate id={'request_flow.marktplaats.answers.second'} />:
                    </b>
                  </label>
                  <div className={'hint'}>
                    <Translate id={'request_flow.marktplaats.placeholders.second'} />
                  </div>
                  <Field name={`pickup.marktplaats.pickup_situation`} type="text" component={Textarea} />
                </Col>
                <Col xs={12}>
                  <label>
                    <b>
                      <Translate id={'request_flow.marktplaats.answers.third'} />:
                    </b>
                  </label>
                  <div className={'hint'}>
                    <Translate id={'request_flow.marktplaats.placeholders.third'} />
                  </div>
                  <Field name={`pickup.marktplaats.collection_date`} type="text" component={Textarea} />
                </Col>
                <Col xs={12}>
                  <label>
                    <b>
                      <Translate id={'request_flow.marktplaats.answers.forth'} />:
                    </b>
                  </label>
                  <div className={'hint'}>
                    <Translate id={'request_flow.marktplaats.placeholders.forth'} />
                  </div>
                  <Field name={`pickup.marktplaats.extra_help`} type="text" component={Textarea} />
                </Col>
                <Col xs={12}>
                  <label>
                    <b>
                      <Translate id={'request_flow.marktplaats.answers.fifth'} />:
                    </b>
                  </label>
                  <div className={'hint'}>
                    <Translate id={'request_flow.marktplaats.placeholders.fifth'} />
                  </div>
                  <Field name={`pickup.marktplaats.address`} type="text" component={Textarea} />
                </Col>
              </Row>
            </div>
            <div className={'general-errors-wrapper'}>
              {errors && errors !== '' && <FlashMessage type={'error'} message={errors} />}
            </div>
            <Button
              loading={_get(this.props, 'isLoading', false)}
              disabled={this.props.submitDisabled}
              buttonStyle={'secondary'}
              iconPos={'right'}
              fullWidth={true}
            >
              <Translate id={'request_flow.marktplaats.form.submit'} />
              <IconArrow />
            </Button>
          </SheetLayoutContent>
          {this.props.isSucceed === 'succeed' && (
            <div className={'after-submit-message'}>
              <PersonBalloon
                balloonContent={
                  <span
                    className={'text--primary text--bold text--center'}
                    dangerouslySetInnerHTML={{
                      __html: String(translate('request_flow.marktplaats.seller_form.success_message')),
                    }}
                  />
                }
              />
              <p
                className={'success-message'}
                dangerouslySetInnerHTML={{
                  __html: String(translate('request_flow.marktplaats.seller_form.hint')),
                }}
              />
              <Button onClick={() => window.location.assign('/')}>
                <Translate id={'request_flow.marktplaats.form.call_to_action'} />
              </Button>
            </div>
          )}
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  errors: state.generalTransport.errors,
  isSucceed: isMarktplaatsFormSentSucessfully(state),
});

export default reduxForm<{}, Props>({
  form: GeneralFlowForms.mp_seller,
  destroyOnUnmount: false,
  forceUnregisterOnUmount: true,
  initialValues: {
    pickup: {
      buyer_email: '',
      seller_email: '',
    },
  },
  onSubmit: (formResults, dispatch) => dispatch(flowActions.submitSellerForm()),
})(connect(mapStateToProps)(SellerForm));

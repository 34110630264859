import React from 'react';
import { Field } from 'redux-form';
import {
  Button,
  CardWithImage,
  Col,
  FlashMessage,
  Input,
  InputFileUploads,
  InputHidden,
  Row,
} from '../../../brenger-shared-ui';
import _get from 'lodash/get';
import { auctionSchema } from '../schema';
import { BvaFlowForms, Lot } from '../interface';
import { Translate } from 'react-localize-redux';
import { translate } from '../../../utils/localization';
import '../../../assets/BVA/renderlot.css';

const RenderLotField: React.FC<any> = ({ generalErrors, form, fields, meta: { error, submitFailed } }) => {
  return (
    <>
      {fields.map((lot, index: number) => (
        <div key={index} className={'render-lot'}>
          {_get(form, `values.lots[${index}]`, false) && form.values.lots[index].title && (
            <>
              <CardWithImage
                header={form.values.lots[index].title}
                details={
                  <div>
                    <div>
                      <Translate id="request_flow.auction.lot_number" />: {form.values.lots[index].number}
                    </div>
                    <InputFileUploads
                      fieldName={`${lot}.external_invoice`}
                      formName={BvaFlowForms.auctionLotForm}
                      accept={'application/pdf'}
                      validate={auctionSchema[Lot.invoice]}
                      multiple={false}
                      texts={{
                        button: {
                          default: String(translate('request_flow.BVA.fields.upload_invoice.button.default')),
                          add: String(translate('request_flow.BVA.fields.upload_invoice.button.add')),
                          delete: String(translate('request_flow.BVA.fields.upload_invoice.button.delete')),
                          change: String(translate('request_flow.BVA.fields.upload_invoice.button.change')),
                        },
                        header: String(translate('request_flow.BVA.fields.upload_invoice.header')),
                      }}
                    />
                  </div>
                }
                image={form.values.lots[index].image}
              />
              <span
                className={'btn btn--grey btn--size-sm render-lot--delete-card'}
                onClick={() => {
                  fields.remove(index);
                }}
              >
                <Translate id={'request_flow.auction.lot_remove'} />
              </span>
              <Field
                validate={auctionSchema[Lot.number]}
                name={`${lot}.number`}
                type="text"
                component={InputHidden}
                label={`Lot #${index + 1}`}
              />
              <Field name={`${lot}.id`} type="text" component={InputHidden} />
              <Field
                validate={auctionSchema[Lot.id]}
                name={`${lot}.image`}
                type="text"
                component={InputHidden}
                label={`Lot #${index + 1}`}
              />
              <Field name={`${lot}.job_image`} type="text" component={InputHidden} />
            </>
          )}
          <div className={'add-lot-actions'}>
            {!form.values?.lots[index]?.title && (
              <Row>
                <Col xs={12} md={5}>
                  <Field
                    validate={auctionSchema[Lot.number]}
                    name={`${lot}.number`}
                    type="text"
                    component={Input}
                    label={`${translate('request_flow.auction.lot_number')} #${index + 1}`}
                  />
                  <Field name={`${lot}.id`} type="text" component={InputHidden} />
                </Col>
                <Col xs={12} md={7} extraClasses={'add-lot-action'}>
                  <Button buttonStyle="primary" type="button">
                    <Translate id={'request_flow.auction.add'} />
                  </Button>
                  <Button
                    type="button"
                    buttonStyle="grey"
                    onClick={() => {
                      fields.remove(index);
                    }}
                  >
                    <Translate id={'request_flow.auction.lot_remove'} />
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        </div>
      ))}
      <div className={'add-lot-general-errors-wrapper'}>
        {submitFailed && error && <FlashMessage type={'error'} message={error} />}
        {generalErrors && generalErrors !== '' && <FlashMessage type={'error'} message={generalErrors} />}
      </div>

      <span className={'btn btn--outline'} onClick={() => fields.push({ number: '' })}>
        + <Translate id={'request_flow.auction.lot_add'} />
      </span>
    </>
  );
};

export default RenderLotField;

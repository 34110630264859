import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Route } from 'react-router';
import { InjectedFormProps, reduxForm } from 'redux-form';
import {
  actions as flowActions,
  getCustomTimePeriodOptions,
  getDeliveryAvailableDate,
  getDeliveryDestinationDetails,
  getDeliveryFloorData,
  getDeliveryStep,
  getFeeExtraPerson,
  getFeeFloor,
  getFlowTitleByStepAndSituation,
  getIsTransportHeavyWeight,
  isNoPreferenceTimeOptionVisible,
  transportRequestHasHelp,
} from '../ducks';
import { connect } from 'react-redux';
import { prefix } from '../index';
import { SubStepHelp } from '../components/SubStepHelp';
import SubStepTime from '../components/SubStepTime';
import { SheetLayoutContentWrapper } from '../../../brenger-shared-ui';
import FooterLayout from './FooterLayout';
import SubStepEquipment from '../components/SubStepEquipment';
import { DeliveryProps, GeneralFlowForms, Situations } from '../interface';
import { StopType } from '../../../typings';
import _get from 'lodash/get';
import SubStepHomeFloorCombined from '../components/SubStepHomeFloorCombined';
import { SubStepHelpHeavy } from '../components/SubStepHelpHeavy';

type Props = DeliveryProps & InjectedFormProps<{}> & RouteComponentProps<{}>;

class Delivery extends React.Component<Props, any> {
  public componentWillMount(): void {
    this.props.setProgress(this.props.step);
  }
  public render() {
    const locality = _get(this.props.autocompleteState, 'locality', '');
    return (
      <form onSubmit={this.props.handleSubmit}>
        <SheetLayoutContentWrapper>
          <Route
            exact={true}
            path={`${prefix}/delivery/help`}
            render={() => {
              if (!this.props.isHeavyTransport) {
                return (
                  <SubStepHelp
                    errors={this.props.transport.errors}
                    isPickup={false}
                    locality={locality}
                    title={this.props.title('helpDelivery')}
                  />
                );
              }
              return (
                <SubStepHelpHeavy
                  errors={this.props.transport.errors}
                  isPickup={false}
                  locality={locality}
                  title={this.props.title('helpDelivery')}
                />
              );
            }}
          />
          <Route
            exact={true}
            path={`${prefix}/delivery/help/equipment`}
            render={() => <SubStepEquipment transport={this.props.transport} isPickup={false} locality={locality} />}
          />
          <Route
            exact={true}
            path={`${prefix}/delivery/home_and_floor`}
            render={() => (
              <SubStepHomeFloorCombined
                hasHelp={this.props.hasHelp}
                transport={this.props.transport}
                feeFloor={this.props.feeFloor}
                isPickup={false}
                locality={locality}
              />
            )}
          />
          <Route
            exact={true}
            path={`${prefix}/delivery/time`}
            render={() => (
              <SubStepTime
                transport={this.props.transport}
                isPickup={false}
                isNoPreferenceTimeOptionVisible={this.props.isNoPreferenceTimeOptionVisible}
                date={this.props.date}
                situation={Situations.HOME}
                timeOptions={this.props.customTimePeriodOptions}
                title={this.props.title('timeDelivery')}
                subTitle={this.props.title('timeCustomDelivery')}
              />
            )}
          />
          <FooterLayout />
        </SheetLayoutContentWrapper>
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  transport: state.generalTransport,
  form: state.form[GeneralFlowForms.delivery],
  date: getDeliveryAvailableDate(state),
  autocompleteState: getDeliveryDestinationDetails(state),
  floorData: getDeliveryFloorData(state),
  hasHelp: transportRequestHasHelp(state),
  feeExtraPerson: getFeeExtraPerson(state),
  feeFloor: getFeeFloor(state),
  isNoPreferenceTimeOptionVisible: isNoPreferenceTimeOptionVisible(state),
  customTimePeriodOptions: getCustomTimePeriodOptions(state, StopType.delivery),
  step: getDeliveryStep(state),
  title: stepName => getFlowTitleByStepAndSituation(state, stepName),
  isHeavyTransport: getIsTransportHeavyWeight(state),
});

const mapDispatchToProps = dispatch => ({
  setProgress: step => dispatch(flowActions.setProgress(step)),
  toggleSheetLayout: () => dispatch(flowActions.toggleSheet()),
});

export default reduxForm<{}, Props>({
  form: GeneralFlowForms.delivery,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  initialValues: {
    setPickupToDeliveryTime: false,
  },
  onSubmit: (formResults, dispatch) => dispatch(flowActions.submitDelivery(formResults)),
})(connect(mapStateToProps, mapDispatchToProps)(Delivery));

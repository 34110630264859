import React from 'react';
import {
  Button,
  Col,
  Container,
  FlashMessage,
  IconArrow,
  PriceDisplay,
  Row,
  SheetLayoutContent,
  SheetLayoutFooter,
} from '../../../brenger-shared-ui';
import { connect } from 'react-redux';
import { getTransportPrice } from '../ducks';
import { Translate } from 'react-localize-redux';
import '../../../assets/BVA/delivery-details.css';
import _get from 'lodash/get';
import { RootState } from '../../../typings/interfaces';
import { getLocation } from 'connected-react-router';
import { prefix } from '..';
import { translate } from '../../../utils/localization';
import { _MAX_WEIGHT_IKEA } from '../../../utils/global';

type ReduxProps = ReturnType<typeof mapStateToProps>;

interface ComponentProps {
  submitting: boolean;
}

type Props = ReduxProps & ComponentProps;

const FooterLayout: React.FC<Props> = ({ ...props }) => {
  const isNotGuaranteed = props.not_guaranteed.distance || props.not_guaranteed.heavy;
  const islastDeliveryPath = props.location.pathname === `${prefix}/delivery_details/address`;
  return (
    <>
      <SheetLayoutContent>
        <div>{props.errors && <FlashMessage type={'error'} message={props.errors} />}</div>
        <div>{props.warnings && <FlashMessage type={'info'} message={props.warnings} />}</div>
        {islastDeliveryPath && isNotGuaranteed && (
          <div className={'ikea-not-guaranteed-messages'}>
            <div className={'text--bold mb-1'}>
              <Translate id={'request_flow.IKEA.not_guaranteed.explain.heading'} />
            </div>
            {props.not_guaranteed.distance && (
              <FlashMessage
                type={'error'}
                message={String(translate('request_flow.IKEA.not_guaranteed.explain.distance'))}
              />
            )}
            {props.not_guaranteed.heavy && (
              <FlashMessage
                type={'error'}
                message={String(
                  translate('request_flow.IKEA.not_guaranteed.explain.heavy', { amountkg: _MAX_WEIGHT_IKEA })
                )}
              />
            )}
          </div>
        )}
      </SheetLayoutContent>

      <SheetLayoutFooter>
        <Container>
          <Row>
            <Col xs={5}>
              <PriceDisplay header={<Translate id={'request_flow.price.total'} />} cents={props.price.amount} />
            </Col>
            <Col xs={7}>
              <Button
                loading={props.submitting}
                disabled={(isNotGuaranteed && islastDeliveryPath) || props.submitting}
                buttonStyle={'secondary'}
                iconPos={'right'}
                fullWidth={true}
              >
                <Translate id={'onboarding.step.next'} />
                <IconArrow />
              </Button>
            </Col>
          </Row>
        </Container>
      </SheetLayoutFooter>
    </>
  );
};

const mapStateToProps = (state: RootState, ownProps) => ({
  submitting: ownProps.submitting,
  not_guaranteed: state.ikeaTransport.request.not_guaranteed,
  errors: state.ikeaTransport.request.errors,
  warnings: state.ikeaTransport.request.warnings,
  price: getTransportPrice(state),
  location: getLocation(state),
});

export default connect(mapStateToProps)(FooterLayout);

import React from 'react';
import _get from 'lodash/get';
import moment from 'moment-timezone';
import { DatetimePeriod } from '../../../../typings';

const PrintDatetimePeriods = (datetimePeriods: DatetimePeriod[]): JSX.Element[] => {
  return datetimePeriods.map((datetimePeriod, index) => {
    return (
      <div key={index} className={'datetime-period-string'}>
        {printDatetimeStartAsStringLongFormat(datetimePeriod)}
      </div>
    );
  });
};

export default PrintDatetimePeriods;

const printDatetimeStartAsStringLongFormat = (datetimePeriod: DatetimePeriod): string => {
  const start = _get(datetimePeriod, 'start', false);
  const end = _get(datetimePeriod, 'end', false);
  if (!start || !end) {
    return '';
  }
  return moment(start).format('dddd DD MMMM YYYY');
};

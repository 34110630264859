import * as React from 'react';
import './icons.css';
import classNames from 'classnames';

interface Props {
  spinning?: boolean;
  centered?: boolean;
  smileDirection?: SMILE_DIRECTION;
}

export enum SMILE_DIRECTION {
  up = 'up',
  right = 'right',
  down = 'down',
  left = 'left',
}

const IconSmile = ({ spinning = false, centered = false, smileDirection = SMILE_DIRECTION.right }: Props) => {
  return (
    <svg
      className={classNames([
        'IconSmile',
        { 'spinning-smile': spinning },
        { centered },
        `IconSmile--${smileDirection}`,
      ])}
      width="10.66"
      height="20"
      viewBox="0 0 8 15"
      version="1.1"
    >
      <g>
        <g transform="translate(-722.000000, -31.000000)" className="smile">
          <g transform="translate(30.000000, 20.000000)">
            <path d="M692,11.3767916 L692,14.3242916 C692,14.4982916 692.1328,14.6572916 692.328,14.6902916 C694.3408,15.0397916 695.8672,16.6132916 695.8672,18.5002916 C695.8672,20.3857916 694.3408,21.9607916 692.328,22.3102916 C692.1328,22.3447916 692,22.5007916 692,22.6747916 L692,25.6237916 C692,25.8457916 692.2144,26.0242916 692.4624,25.9972916 C696.6992,25.5652916 700,22.3687916 700,18.5002916 C700,14.6317916 696.6992,11.4352916 692.4624,11.0032916 C692.2144,10.9777916 692,11.1577916 692,11.3767916" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconSmile;

import React from 'react';
import { reduxForm } from 'redux-form';
import { getDeliveryDetails, getSelectedStoreAddress, getTransportPrice } from '../ducks';
import { actions as paymentActions } from '../../Payment/ducks';
import { connect } from 'react-redux';
import '../../../assets/payment/payment-details.css';
import {
  Col,
  Collapse,
  Heading,
  PrintDatetimePeriods,
  Row,
  SheetLayoutContent,
  SheetLayoutContentWrapper,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { Translate } from 'react-localize-redux';
import _get from 'lodash/get';
import { push } from 'connected-react-router';
import PaymentMethods from '../../Payment/components/PaymentMethods';
import { translate } from '../../../utils/localization';
import { _FLOW_TYPE, prefix } from '../index';
import { DatetimePeriod, RootState } from '../../../typings';
import FooterLayout from './FooterLayout';
import moment from 'moment-timezone';

type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface FormProps {
  submitting: boolean;
  handleSubmit(): void;
  reset(): void;
}

type Props = ReduxProps & FormProps;

class Payment extends React.Component<Props> {
  public componentDidMount(): void {
    if (!this.props.uuid) {
      this.props.startAgain();
    }
  }
  public render() {
    const datetimePeriods: DatetimePeriod[] = _get(this.props.deliveryDetails, 'available_datetime_periods', []);
    const addressData = _get(this.props.deliveryDetails, 'address', false);
    return (
      <form onSubmit={this.props.handleSubmit}>
        <SheetLayoutContentWrapper>
          <SheetLayoutHeader>
            <Heading size={2}>
              <Translate id={'request_flow.headers.payment'} />
            </Heading>
          </SheetLayoutHeader>
          <SheetLayoutContent>
            <Collapse open={false} header={String(translate('request_flow.payment.order_wrap_up'))}>
              <div className={'order-wrap-up pb-1'}>
                <Row extraClasses={'order-wrap-up--lot'}>
                  <Col xs={12} extraClasses={'pt-1'}>
                    <Heading size={5}>
                      <Translate id={'request_flow.IKEA.pickup'} />
                    </Heading>
                    <p>{this.props.selectedStoreAddress}</p>
                  </Col>
                </Row>
                <Row extraClasses={'order-wrap-up--date'}>
                  <Col xs={12} extraClasses={'pt-1'}>
                    <Heading size={5}>
                      <Translate id={'request_flow.delivery.date'} />
                    </Heading>
                  </Col>
                  <Col xs={12}>
                    <div className={'order-wrap-up--details'}>
                      <div className="flex">
                        {PrintDatetimePeriods(datetimePeriods)}
                        {datetimePeriods.map((dateTimePeriod: DatetimePeriod, index) => {
                          return (
                            <div key={index} className={'text--primary'}>
                              &nbsp;
                              <Translate id={'request_flow.time.between'} />{' '}
                              {moment(dateTimePeriod.start).format('HH:mm')} -{' '}
                              {moment(dateTimePeriod.end).format('HH:mm')}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row extraClasses={'order-wrap-up--address'}>
                  <Col xs={12} extraClasses={'pt-1'}>
                    <Heading size={5}>
                      <Translate id={'location.address'} />
                    </Heading>
                  </Col>
                  <Col xs={12}>
                    <div className={'order-wrap-up--details'}>
                      {addressData && `${addressData.line1}, ${addressData.postal_code}, ${addressData.locality}`}
                    </div>
                  </Col>
                </Row>
                <Row extraClasses={'order-wrap-up--price'}>
                  <Col xs={12} extraClasses={'pt-1'}>
                    <Heading size={5}>
                      <Translate id={'request_flow.price.total'} />
                    </Heading>
                  </Col>
                  <Col xs={12}>
                    <div className={'order-wrap-up--details'}>€ {(this.props.price.amount / 100).toFixed(2)} </div>
                  </Col>
                </Row>
              </div>
            </Collapse>
            <PaymentMethods resetPaymentForm={this.props.reset} submitPaymentForm={this.props.handleSubmit} />
          </SheetLayoutContent>
          <FooterLayout submitting={this.props.submitting} />
        </SheetLayoutContentWrapper>
      </form>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  deliveryDetails: getDeliveryDetails(state),
  transport: state.ikeaTransport,
  uuid: state.ikeaTransport.request.uuid,
  payment: state.payment,
  isSubmitting: state.payment.paymentInfo.isSubmitting,
  selectedStoreAddress: getSelectedStoreAddress(state),
  price: getTransportPrice(state),
});

const mapDispatchToProps = dispatch => ({
  startAgain: (): void => dispatch(push(`${prefix}/pickup_details`)),
});

export default reduxForm<Props>({
  form: 'paymentForm',
  initialValues: {},
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmit: (payment, dispatch) => dispatch(paymentActions.submitPayment({ ...payment, flow: _FLOW_TYPE })),
})(connect(mapStateToProps, mapDispatchToProps)(Payment));

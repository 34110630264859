import React from 'react';
import { Field } from 'redux-form';
import { Translate } from 'react-localize-redux';
import { translate } from '../../../utils/localization';
import { priceAsString } from '../../../utils/price';
import { FlashMessage, Heading, InputTiles, SheetLayoutContent, SheetLayoutHeader } from '../../../brenger-shared-ui';
import { SubStepFieldNamesDelivery, SubStepFieldNamesPickup } from '../interface';
import { pickupDeliverySchema } from '../schema';
import { CarryingHelpChoices } from '../../../typings';
import { useTypedSelector } from '../../../hooks';
import { getFeeTailgate, getFeeTailgateExtraDriver, getFeeTailgatePalletJack } from '../ducks';

interface Props {
  isPickup: boolean;
  locality: string;
  errors: any;
  title: string;
}

export const SubStepHelpHeavy: React.FC<Props> = ({ errors, isPickup, locality, title }) => {
  const fieldName = isPickup ? SubStepFieldNamesPickup.help_carrying : SubStepFieldNamesDelivery.help_carrying;
  const feeTailgate = useTypedSelector(getFeeTailgate);
  const feeTailgatePalletJack = useTypedSelector(getFeeTailgatePalletJack);
  const feeTailgateExtraDriver = useTypedSelector(getFeeTailgateExtraDriver);
  const heavyOptions = [
    {
      value: CarryingHelpChoices.EQUIPMENT_PROVIDED_BY_CUSTOMER,
      title: String(translate('request_flow.weight_equipment_heavy.equipment_provided')),
      desc: <Translate id={'request_flow.weight_equipment_heavy.equipment_provided_explain'} />,
      suffix: <span className={'text--primary'}>+ {priceAsString(0)}</span>,
    },
    {
      value: CarryingHelpChoices.EQUIPMENT_TAILGATE,
      title: String(translate('request_flow.weight_equipment_heavy.equipment_tailgate')),
      desc: <Translate id={'request_flow.weight_equipment_heavy.equipment_tailgate_explain'} />,
      suffix: <span className={'text--primary'}>+ {priceAsString(feeTailgate)}</span>,
    },
    {
      value: CarryingHelpChoices.EQUIPMENT_TAILGATE_EXTRA_DRIVER,
      title: String(translate('request_flow.weight_equipment_heavy.equipment_tailgate_extra_driver')),
      desc: <Translate id={'request_flow.weight_equipment_heavy.equipment_tailgate_extra_driver'} />,
      suffix: <span className={'text--primary'}>+ {priceAsString(feeTailgateExtraDriver)}</span>,
    },
    {
      value: CarryingHelpChoices.EQUIPMENT_TAILGATE_PALLET_JACK,
      title: String(translate('request_flow.weight_equipment_heavy.equipment_tailgate_with_pallet_jack')),
      desc: <Translate id={'request_flow.weight_equipment_heavy.equipment_tailgate_with_pallet_jack_explain'} />,
      suffix: <span className={'text--primary'}>+ {priceAsString(feeTailgatePalletJack)}</span>,
    },
  ];
  return (
    <>
      <SheetLayoutHeader>
        <Heading size={2}>
          <Translate id={title} data={{ locality: <span className={'text--primary'}>{locality}</span> }} />
        </Heading>
      </SheetLayoutHeader>
      <SheetLayoutContent>
        <div className={'question-wrapper'}>
          <Field validate={pickupDeliverySchema.help} name={fieldName} options={heavyOptions} component={InputTiles} />
        </div>
        <div className={'general-errors-wrapper'}>{errors && <FlashMessage type={'error'} message={errors} />}</div>
      </SheetLayoutContent>
    </>
  );
};

import React from 'react';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import {
  Button,
  Col,
  Container,
  IconArrow,
  IconSmile,
  PriceDisplay,
  Row,
  SheetLayoutFooter,
} from '../../../brenger-shared-ui';
import { actions, getBusinessFlowError, getPrice, getPriceLoading, getProgressStep, getSheetState } from '../ducks';
import { RootState } from '../../../typings';

type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export class FooterLayout extends React.Component<ReduxProps> {
  public render() {
    const isDisabled =
      (this.props.step === 2 && !this.props.price) ||
      this.props.businessFlowError !== null ||
      this.props.priceIsLoading ||
      !this.props.depotAddresses.length;
    return (
      <SheetLayoutFooter>
        <Container>
          <Row>
            <Col xs={6} extraClasses={'footer-action-bar hidden--lg-up business--footer'}>
              <div onClick={() => this.props.setSheetState(!this.props.sheetState)}>
                <PriceDisplay
                  header={
                    <div>
                      <IconSmile />
                      <Translate id={'request_flow.price.total_ex_vat'} />
                    </div>
                  }
                  cents={this.props.price}
                  loading={this.props.priceIsLoading}
                />
              </div>
            </Col>
            <Col xs={6} extraClasses={'offset-lg-6'}>
              <Button
                loading={this.props.priceIsLoading}
                disabled={isDisabled}
                buttonStyle={'secondary'}
                iconPos={'right'}
                fullWidth={true}
                type="button"
                onClick={this.props.step !== 3 ? () => this.props.submitForm() : () => this.props.createTr()}
              >
                <Translate
                  id={this.props.step === 3 ? 'request_flow.business.submit_request' : 'onboarding.step.next'}
                />
                <IconArrow />
              </Button>
            </Col>
          </Row>
        </Container>
      </SheetLayoutFooter>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps) => ({
  price: getPrice(state, false),
  priceIsLoading: getPriceLoading(state),
  sheetState: getSheetState(state),
  businessFlowError: getBusinessFlowError(state),
  step: getProgressStep(state),
  depotAddresses: state.business.depots.addresses,
  submitForm: ownProps.submitForm,
});

const mapDispatchToProps = dispatch => ({
  setSheetState: state => dispatch(actions.setSheetState(state)),
  createTr: () => dispatch(actions.createNewTr()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FooterLayout);

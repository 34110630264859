import * as React from 'react';
import '../input/input.css';
import './input-chips.css';
import InputWrapper from '../input/InputWrapper';
import InputFeedback from '../input/InputFeedback';
import { InputProps } from '../input/Input';
import { Translate } from 'react-localize-redux';

interface chip {
  id: string;
  label: string;
}

interface custom {
  chips: {
    title?: string;
    options: chip[];
  };
  buttonStyle: string;
  close: any;
}

type InputFileProps = InputProps & custom;

interface ChipsState {
  modalOpen: boolean;
  selectedChips: string[];
  customDescription: string;
}

export class InputChips extends React.Component<InputFileProps, ChipsState> {
  public toggleChip(id) {
    const inputValues = this.props.input.value || [];
    let newChips: string[] = [...inputValues];

    if (newChips.indexOf(id) === -1) {
      newChips.push(id);
    } else {
      newChips = newChips.filter(i => i !== id);
    }

    this.props.input.onChange(newChips);
  }

  public render() {
    const selectedChips = this.props.input.value === '' ? [] : this.props.input.value;
    return (
      <div className={'input-chips input-chips--wrapper'}>
        <input type="hidden" {...this.props.input} />
        <div className={'input-chips--body'}>
          <div className={'input-chips--section  pb-1'}>
            {this.props.chips.title && (
              <div className={'text--bold pb-0-5'}>
                <Translate id={this.props.chips.title} />
              </div>
            )}
            <div className={'input-chips--options'}>
              {this.props.chips.options.map((option, key) => {
                return (
                  <span
                    className={
                      'input-chips--single-option ' +
                      (selectedChips.includes(option.id) ? 'input-chips--single-option--is-selected' : '')
                    }
                    key={key}
                    onClick={() => this.toggleChip(option.id)}
                  >
                    <Translate id={option.label} />
                  </span>
                );
              })}
            </div>
          </div>
        </div>
        <InputWrapper input={...this.props.input} meta={...this.props.meta} type={'chips'}>
          <InputFeedback
            meta={...this.props.meta}
            error={this.props.error}
            successMessage={this.props.successMessage}
          />
        </InputWrapper>
      </div>
    );
  }
}

export default InputChips;

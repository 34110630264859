import axios, { AxiosError, AxiosResponse } from 'axios';
import _get from 'lodash/get';
import { store } from '../';
import { types } from '../modules/GeneralFlow/ducks';
import { logException, logger } from './basics';
import { CoreClient, PriceClient } from '@brenger/api-client';
import { _PRICE_API_URL } from './global';

export const BASE_URL_DRUPAL = process.env.REACT_APP_API_URL;
export const BASE_URL_API = process.env.REACT_APP_API_URL_V2;

export enum _CONFIGS {
  ACCEPT_HEADER = 'application/ld+json',
  CONTENT_TYPE_HEADER = 'application/ld+json',
}

interface httpType {
  version?: number;
  headers?: any;
}

export const httpCallErrorHandling = (instance, error) => {
  if (error?.response?.config?.url.match(/am_i_logged_in|login/g)) {
    // the user is not logged in, or the user is not found. No need to log anything
    return Promise.reject(error);
  }
  const status = error.response ? error.response.status : null;
  if (status === 404) {
    // We decided that 404's are less meaningful.
    // If something terrible happens there will be an exception at backend side, since we are talking about rest responses here
    return Promise.reject(error);
  }
  const state = store.getState();
  const retryAttempt = state.generalTransport.price.retryAttempt;

  // handle retry attemps if we have failed request from quotes.
  // This also prevents double logging errors, sentry will be fired in the saga
  if (error?.response?.config?.url.indexOf('quotes') > -1) {
    if (status === 400 || status === 500) {
      if (retryAttempt <= 3) {
        store.dispatch({ type: types.PRICE_API_RETRY_ATTEMPT_INCREMENT });
      } else {
        store.dispatch({ type: types.RESET_RETRY_ATTEMPTS });
      }
    }
    return Promise.reject(error);
  }

  // in cases of >400 or 5XX
  if (status >= 400 && status <= 500) {
    logException(`${status} FAILED REQUEST`, {
      config: error.response.config,
      data: error.response.data,
    });
  }
  return Promise.reject(error);
};

// Note: over time, we should aim to stop using this axios instance in favor of the
// the api-clients (see below).
export const http = (options?: httpType) => {
  const version = _get(options, 'version', 2);
  const headers = _get(options, 'headers', false);
  // apply interceptor on response
  const instance = axios.create({
    baseURL: version !== 1 ? BASE_URL_API : BASE_URL_DRUPAL,
    headers: {
      Accept: headers ? headers.accept : _CONFIGS.ACCEPT_HEADER,
      'Content-Type': headers ? headers.contentType : _CONFIGS.CONTENT_TYPE_HEADER,
    },
  });
  instance.interceptors.response.use(
    response => response,
    error => httpCallErrorHandling(instance, error)
  );
  return instance;
};

// Check for specific error codes we care about and log them in Sentry.
const errorResponseInterceptor = (error: AxiosError) => {
  const status = error.response?.status;
  if (status && status > 400 && status !== 404 && status <= 500) {
    logException(`${status} FAILED REQUEST`, {
      config: error.response?.config,
      data: error.response?.data,
    });
  }
  // After doing the above check, we have to throw the error again so that
  // it can be handled by any enclosing try/catch statements in the app.
  throw error;
};

// In certain environments, it is helpful to surface internal logs that may
// shed light on the decisions made behind certain quotes.
const priceResponseInterceptor = (response: AxiosResponse) => {
  const logs = response?.data?.logs;
  if (logs && process.env.REACT_APP_LOG) {
    logger(logs);
  }
  return response;
};

const priceAxiosInstance = axios.create({ baseURL: _PRICE_API_URL() });
priceAxiosInstance.interceptors.response.use(priceResponseInterceptor, errorResponseInterceptor);
export const priceClient = new PriceClient(priceAxiosInstance);

const coreAxiosInstance = axios.create({ baseURL: BASE_URL_API, withCredentials: true });
coreAxiosInstance.interceptors.response.use(undefined, errorResponseInterceptor);
export const coreClient = new CoreClient(coreAxiosInstance);

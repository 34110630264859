import React from 'react';
import { translate } from '../../../utils/localization';
import { deliverySchema } from '../schema';
import { Delivery, IkeaFlowFields } from '../interface';
import { Field } from 'redux-form';
import { Col, Heading, Input, InputHidden, Row, SheetLayoutContent } from '../../../brenger-shared-ui';
import { Translate } from 'react-localize-redux';
import { _GENERAL_CONDITION_LINK, _PRIVACY_STATEMENT_LINK, _TRANSPORT_CONDITION_LINK } from '../../../utils/global';

export const DeliveryFields: React.FC = () => {
  return (
    <SheetLayoutContent>
      <Row extraClasses={'pt-1'}>
        <Col xs={12} extraClasses={'pt-1 pb-0-5'}>
          <Heading size={5}>
            <Translate id={'request_flow.IKEA.delivery.address_details'} />
          </Heading>
        </Col>
        <Col xs={12}>
          <Field
            validate={deliverySchema[Delivery.line1]}
            name={IkeaFlowFields.LINE1}
            type="text"
            component={Input}
            label={translate('form.fields.address.line1.label')}
          />
        </Col>
        <Col xs={12} sm={4}>
          <Field
            validate={deliverySchema[Delivery.postal_code]}
            name={IkeaFlowFields.POSTAL_CODE}
            type="text"
            component={Input}
            label={translate('form.fields.address.postal_code.label')}
          />
        </Col>
        <Col xs={12} sm={8}>
          <Field
            validate={deliverySchema[Delivery.locality]}
            name={IkeaFlowFields.LOCALITY}
            type="text"
            component={Input}
            label={translate('form.fields.address.locality.label')}
          />
          <Field name={IkeaFlowFields.COUNTRY_CODE} component={InputHidden} />
          <Field name={IkeaFlowFields.LAT} component={InputHidden} />
          <Field name={IkeaFlowFields.LNG} component={InputHidden} />
        </Col>
        <Col xs={12}>
          <Field
            name={IkeaFlowFields.INSTRUCTIONS}
            type="text"
            component={Input}
            label={translate('form.fields.address.instructions.label')}
          />
        </Col>
      </Row>
      <Row extraClasses={'order--agree-terms'}>
        <Col xs={12} extraClasses={'pt-1'}>
          <Field
            name={'general_conditions'}
            type={'checkbox'}
            validate={deliverySchema[Delivery.conditions]}
            label={
              <Translate
                id={'request_flow.delivery.agree_general_terms'}
                data={{
                  condition_link: (
                    <a href={_GENERAL_CONDITION_LINK} target={'_blank'}>
                      <Translate id={'request_flow.delivery.general_terms'} />
                    </a>
                  ),
                }}
              />
            }
            component={Input}
          />
          <Field
            name={'privacy_statement'}
            type={'checkbox'}
            validate={deliverySchema[Delivery.conditions]}
            label={
              <>
                <Translate
                  id={'request_flow.delivery.agree_privacy_statement'}
                  data={{
                    privacy_link: (
                      <a href={_PRIVACY_STATEMENT_LINK} target={'_blank'}>
                        <Translate id={'request_flow.delivery.privacy_statement'} />
                      </a>
                    ),
                  }}
                />
                <br />
                <small style={{ paddingTop: '1em' }}>
                  <Translate
                    id={'request_flow.delivery.agree_transport_condition'}
                    data={{
                      condition_link: (
                        <a href={_TRANSPORT_CONDITION_LINK} target={'_blank'}>
                          <Translate id={'request_flow.delivery.transport_condition'} />
                        </a>
                      ),
                    }}
                  />
                </small>
              </>
            }
            component={Input}
          />
        </Col>
      </Row>
    </SheetLayoutContent>
  );
};

import React from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import _get from 'lodash/get';
import { Container, Heading, Label, SectionWrapper } from '../../../brenger-shared-ui';
import '../../../assets/status-page/transport-request.css';
import { priceAsString } from '../../../utils/price';
import { getItemSetsVolumeAsString } from '../../../utils/itemSet';
import { getItemSets, getTransportRequestTitle, getUniqueClientReferences } from '../ducks';
import { _FLOW_TYPE as BusinessFlowType } from '../../Business/containers/TransportRequest';

type ReduxProps = ReturnType<typeof mapStateToProps>;

const TransportRequestHead: React.FC<ReduxProps> = props => {
  /**
   * @TODO HACK ALERT BELOW
   * This is a HACK and should go away eventually.
   * We are hiding the item sets volume string when it is 1x1x1,
   * as that indicates that it's an Ikea order.
   */
  const itemSetsVolume = getItemSetsVolumeAsString(props.items);

  return (
    <Container fluid={true}>
      <SectionWrapper sectionStyle={'primary-light'}>
        {props.distance !== 0 && (
          <div className={'pb-0-25'}>
            <Label type={'blue'}>{parseInt(props.distance.toString(), 10)} km.</Label>
          </div>
        )}
        <Heading size={1} extraClasses={'transport-request-head no-margin'}>
          <span className={'transport-request-head--label'}>{props.title}</span>
          {props.price && (
            <span className={'transport-request-head--price'}>
              {priceAsString(parseInt(props.price.toString(), 10))}
              {props.source_flow === BusinessFlowType && (
                <small>
                  <Translate id={'request_flow.price.inc_vat'} />
                </small>
              )}
            </span>
          )}
        </Heading>
        {/* HACK ALERT - see note above. */}
        {!itemSetsVolume.includes('1x1x1') && <div className={'pt-0-5'}>{itemSetsVolume}</div>}
        {props.short_id && (
          <small>
            <Translate
              id={`request_flow.${props.source_flow === BusinessFlowType ? 'business.short_id' : 'status.short_id'}`}
            />
            : {props.short_id}
          </small>
        )}
        {props.clientReferences.length > 0 && (
          <small>
            <Translate id={'request_flow.business.client_reference'} />: {props.clientReferences.join(',')}
          </small>
        )}
      </SectionWrapper>
    </Container>
  );
};

const mapStateToProps = state => ({
  short_id: state.statusPage.short_id,
  distance: _get(state.statusPage.routeData, 'distance', 0),
  price: _get(state.statusPage.price, 'amount'),
  title: getTransportRequestTitle(state),
  items: getItemSets(state),
  clientReferences: getUniqueClientReferences(state),
  source_flow: state.statusPage.source_flow,
});

export default connect(mapStateToProps)(TransportRequestHead);

import _get from 'lodash/get';
import { matchPath } from 'react-router';
import { Nullable } from '../typings';

export const getSingleSearchParam = (fullUrl: string, parameter: string, defaultValue: any = null): any => {
  if (!fullUrl) {
    return defaultValue;
  }
  return new URL(fullUrl).searchParams.get(parameter) || defaultValue;
};

export const getLotIdFromBVAUrl = (urlString: string): null | number => {
  if (urlString === '') {
    return null;
  }

  const url = new URL(urlString);

  // Do we have a bva related url
  if (!url.hostname.includes('bva')) {
    return null;
  }

  const match: Nullable<{ params: { locale: string; lotid: string; auctionid: string } }> = matchPath(url.pathname, {
    path: '/:locale/auction/lot/:auctionid/:lotid',
  });
  if (!match) {
    return null;
  }
  return Number(match.params.lotid);
};

export const getRedirectUrl = (url: string): string | null => {
  if (url.indexOf('www.brenger.nl/nl/driver') > -1) {
    return url.replace('www.brenger.nl/nl/driver', 'driver.brenger.nl/nl/driver');
  }
  return null;
};

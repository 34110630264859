import React from 'react';
import './pricedisplay.css';
import { IconSmile } from '../../basics/icons';
import { centsToPriceArray } from '../../../../utils/price';

interface Props {
  currency?: string;
  header?: any;
  cents: number;
  loading?: boolean;
  failedAttempts?: boolean;
}

interface State {
  blink: boolean;
}

export class PriceDisplay extends React.Component<Props, State> {
  private constructor(props) {
    super(props);
    this.state = {
      blink: false,
    };
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevProps.cents === this.props.cents) {
      return;
    }
    this.setState({ blink: true });
    setTimeout(() => {
      this.setState({ blink: false });
    }, 1000);
  }

  public render() {
    const priceArray = centsToPriceArray(this.props.cents);
    if (this.props.cents === 0) {
      return null;
    }
    return (
      <div
        className={
          'price-display' +
          (this.props.failedAttempts ? ' price-display-failure' : '') +
          (this.props.loading ? ' price-display--is-loading' : '') +
          (this.state.blink ? ' price-display--is-visible' : '')
        }
      >
        {this.props.header && <div className={'price-display--head'}>{this.props.header}</div>}
        <div className={'price-display--layout'}>
          <span className={'price-display--layout-prefix'}>{this.props.currency}</span>
          <span className={'price-display--layout-big'}>&euro; {priceArray[0]},</span>
          <span className={'price-display--layout-small'}>{priceArray[1]}</span>
        </div>
        <div className={'price-display--loading-element'}>
          <IconSmile spinning={true} />
        </div>
      </div>
    );
  }
}

export default PriceDisplay;

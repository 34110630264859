import * as React from 'react';
import './icons.css';

const IconEye = () => {
  return (
    <svg className={'IconEye'} width="24px" height="14px" viewBox="0 0 24 14">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-556.000000, -18.000000)">
          <g>
            <path
              className={'cls-1'}
              d="M556.172045,25.4531183 C555.938393,25.2008172 555.943352,24.8052043 556.183129,24.5586237 C557.678092,23.0222366 562.952611,18 568.017689,18 C573.082768,18 578.331034,23.0225376 579.818121,24.5589247 C580.056732,24.8052043 580.061107,25.199914 579.828039,25.4516129 C578.392875,27.0003441 573.345882,32 567.999312,32 C562.654493,32 557.608667,27.0030538 556.172045,25.4531183 Z M572.267788,25.0000301 C572.267788,22.5679441 570.357444,20.5962022 568.001092,20.5962022 C565.644448,20.5962022 563.734396,22.5679441 563.734396,25.0000301 C563.734396,27.4321161 565.644448,29.4038581 568.001092,29.4038581 C570.357444,29.4038581 572.267788,27.4321161 572.267788,25.0000301 Z"
              id="Toon"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconEye;

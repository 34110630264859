import * as React from 'react';
import InputWrapper from './InputWrapper';
import InputFeedback from './InputFeedback';

export interface custom {
  type: string;
  successMessage: string;
  label?: string;
  description?: string;
  suffix?: string;
  disabled?: boolean;
  border?: boolean;
  classNames?: string;
  prepend?: string;
  append?: string;
  blockEnterSubmit?: boolean;
}

export type InputProps = custom & any;

const Input = ({
  input,
  meta,
  border = false,
  disabled = false,
  label,
  description,
  suffix,
  type,
  successMessage,
  inputClasses,
  prepend,
  append,
  blockEnterSubmit = false,
}: InputProps): any => (
  <InputWrapper label={label} input={...input} meta={...meta} type={type} border={border}>
    <div className={'input-el--inner'}>
      {prepend && <span className={'input-el--prepend'}>{prepend}</span>}
      <div className={'input-el--tag'}>
        <input
          className={inputClasses}
          disabled={disabled}
          id={input.name}
          type={type}
          {...input}
          onKeyPress={
            !blockEnterSubmit
              ? undefined
              : e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }
          }
        />
        {label && (
          <label
            className={
              'input-el--label' +
              (typeof input.required !== 'undefined' && input.required === true ? ' input-el--label--required' : '') +
              (suffix ? ' input-el--label--with-suffix' : '')
            }
            htmlFor={input.name}
          >
            <span className={'input-el--label-text'}>{label}</span>
            {suffix && <div className={'input-el--suffix'}>{suffix}</div>}
            {description && <div className={'input-el--description'}>{description}</div>}
          </label>
        )}
      </div>
      {append && <span className={'input-el--append'}>{append}</span>}
    </div>

    <InputFeedback meta={...meta} error={meta.error} successMessage={successMessage} />
  </InputWrapper>
);

export default Input;

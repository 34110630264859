import React from 'react';
import { Button, Col, Heading, Row, SectionWrapper } from '../../../brenger-shared-ui';
import { Translate } from 'react-localize-redux';

export const PaymentError = ({ toggleRetryPayment }) => {
  return (
    <SectionWrapper sectionStyle={'white'} extraClasses={'profile-dashboard-wrapper'} centerContent={true}>
      <Row>
        <Col xs={12} md={8} extraClasses={'offset-md-2 pb-1'}>
          <Heading size={5}>
            <Translate id={'request_flow.payment.verification_failed.title'} />
          </Heading>
        </Col>
        <Col xs={12} md={8} extraClasses={'offset-md-2'}>
          <p>
            <Translate id={'request_flow.payment.verification_failed.text'} />
          </p>
        </Col>
        <Col xs={12} md={8} extraClasses={'offset-md-2'}>
          <Button buttonStyle={'primary'} fullWidth={true} onClick={toggleRetryPayment}>
            <Translate id={'request_flow.payment.verification_failed.button'} />
          </Button>
        </Col>
      </Row>
    </SectionWrapper>
  );
};

export default PaymentError;

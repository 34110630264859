import React from 'react';
import { Field, FieldArray } from 'redux-form';
import {
  FlashMessage,
  IconTrash,
  Input,
  InputFileUploads,
  InputHidden,
  InputNumber,
  InputRadio,
  SectionWrapper,
  SheetLayoutContent,
} from '../../../brenger-shared-ui';
import { itemSchema, parseInteger } from '../schema';
import { ItemFields } from '../interface';
import { Translate } from 'react-localize-redux';
import { translate } from '../../../utils/localization';
import { FormNames } from '../../../typings';
import { ItemCreationParams } from '@brenger/api-client';
import { _EXTRA_CARE_NO_CONFIRMATION } from '../../../utils/global';

interface Props {
  title: any;
  fields: any;
  showSizeWarning: boolean;
  businessFlow: boolean;
  formName: FormNames;
  quoteItems?: ItemCreationParams[];
}

const ItemSets: React.FC<Props> = ({ title, fields, showSizeWarning, businessFlow = false, formName, quoteItems }) => {
  return (
    <>
      {fields.map((itemSet, index) => (
        <FieldArray
          name={`${itemSet}.items`}
          title={title}
          component={Items}
          showSizeWarning={showSizeWarning}
          businessFlow={businessFlow}
          formName={formName}
          key={index}
          quoteItems={quoteItems}
        />
      ))}
    </>
  );
};

export default ItemSets;

const Items: React.FC<Props> = ({ title, fields, showSizeWarning, businessFlow, formName, quoteItems }) => {
  return (
    <React.Fragment>
      <SectionWrapper centerContent={false} sectionStyle={'primary-light'}>
        <SheetLayoutContent>
          {title && <div className={'item-config-sub-title'}>{title}</div>}
          <div className={'item-config-content'}>
            <div className="item-config-list">
              {fields.map((item, index) => (
                <Item
                  key={index}
                  item={item}
                  index={index}
                  handleRemove={() => fields.remove(index)}
                  businessFlow={businessFlow}
                  formName={formName}
                  quoteItem={quoteItems?.[index]}
                />
              ))}
            </div>
          </div>
          {showSizeWarning && (
            <FlashMessage type={'info'} message={String(translate('request_flow.what.item.size_warning'))} />
          )}
        </SheetLayoutContent>
      </SectionWrapper>
      <SheetLayoutContent>
        <div className={'item-config--actions'}>
          <span className={'btn btn--outline btn--full-width'} onClick={() => fields.push({ count: 1 })}>
            <Translate id={'request_flow.what.item.add' + (businessFlow ? '_business' : '')} />
          </span>
        </div>
      </SheetLayoutContent>
    </React.Fragment>
  );
};

interface ItemProps {
  item: any;
  index: number;
  handleRemove: any;
  businessFlow: boolean;
  formName: FormNames;
  quoteItem?: ItemCreationParams;
}

const Item: React.FC<ItemProps> = ({ item, index, handleRemove, businessFlow, formName, quoteItem }) => {
  const extraCareMatch = quoteItem?.extra_care?.match;
  return (
    <>
      {index === 1 && !businessFlow && (
        <div className={'text--explain'}>
          <Translate id={'request_flow.what.item.items_price_explain'} />
        </div>
      )}
      <div className={'item-wrapper'}>
        <div className={'item-wrapper--title'}>
          <Field
            validate={itemSchema[ItemFields.TITLE]}
            name={`${item}.${ItemFields.TITLE}`}
            type="text"
            inputClasses={'data-hj-whitelist'}
            component={Input}
            label={String(translate('request_flow.what.fields.title.label'))}
          />
          {/* When we have a match, the user needs to confirm if the match is valid for certain type of matches */}
          {extraCareMatch && !_EXTRA_CARE_NO_CONFIRMATION.includes(extraCareMatch) && (
            <>
              <Translate id={'request_flow.fields.extra_care.label.' + extraCareMatch} />
              <Field
                validate={itemSchema.extraCare}
                name={`${item}.${ItemFields.EXTRA_CARE_SELECTION}`}
                component={InputRadio}
                optionsInline={!extraCareMatch.includes('fridge')}
                options={
                  /*
                   * Fridge is currently ignored via _EXTRA_CARE_NO_CONFIRMATION
                   */
                  extraCareMatch.includes('fridge')
                    ? [
                        {
                          value: 'fridge-must_stand',
                          label: translate('request_flow.fields.extra_care.options.fridge-must_stand'),
                        },
                        {
                          value: 'fridge',
                          label: translate('request_flow.fields.extra_care.options.fridge'),
                        },
                        {
                          value: 'unknown',
                          label: translate('default.no'),
                        },
                      ]
                    : [
                        {
                          value: extraCareMatch,
                          label: translate('default.yes'),
                        },
                        {
                          value: 'unknown',
                          label: translate('default.no'),
                        },
                      ]
                }
              />
              {/* Besides the confirmation, we save the other values as a copy from pricing */}
              <Field name={`${item}.${ItemFields.EXTRA_CARE_MATCH}`} component={InputHidden} />
              <Field name={`${item}.${ItemFields.EXTRA_CARE_INSURED}`} component={InputHidden} />
            </>
          )}
          {/* When we don't have to asked for confirmation, we store in these hidden fields the values that arrive from pricing */}
          {extraCareMatch && _EXTRA_CARE_NO_CONFIRMATION.includes(extraCareMatch) && (
            <>
              <Field name={`${item}.${ItemFields.EXTRA_CARE_SELECTION}`} component={InputHidden} />
              <Field name={`${item}.${ItemFields.EXTRA_CARE_MATCH}`} component={InputHidden} />
              <Field name={`${item}.${ItemFields.EXTRA_CARE_INSURED}`} component={InputHidden} />
            </>
          )}
          <div className={'item-wrapper--desc pb-0-5'}>
            <Translate id={'request_flow.what.item.volume_title'} />
          </div>
        </div>
        <div className={'item-wrapper--volume-fields'}>
          <div className={'fields'}>
            <Field
              validate={itemSchema[ItemFields.LENGTH]}
              name={`${item}.${ItemFields.LENGTH}`}
              type="number"
              inputClasses={'data-hj-whitelist'}
              normalize={parseInteger}
              min={1}
              append={'cm'}
              component={Input}
              label={String(translate('request_flow.what.fields.length.label'))}
            />
            <Field
              validate={itemSchema[ItemFields.WIDTH]}
              name={`${item}.${ItemFields.WIDTH}`}
              inputClasses={'data-hj-whitelist'}
              type="number"
              normalize={parseInteger}
              min={1}
              append={'cm'}
              component={Input}
              label={String(translate('request_flow.what.fields.width.label'))}
            />
            <Field
              validate={itemSchema[ItemFields.HEIGHT]}
              inputClasses={'data-hj-whitelist'}
              name={`${item}.${ItemFields.HEIGHT}`}
              normalize={parseInteger}
              type="number"
              min={1}
              append={'cm'}
              component={Input}
              label={String(translate('request_flow.what.fields.height.label'))}
            />
            <Field name={`${item}.weight`} type="hidden" component={InputHidden} />
          </div>
        </div>
        <div className={'item-wrapper--quantity'}>
          <Field
            validate={itemSchema[ItemFields.COUNT]}
            name={`${item}.${ItemFields.COUNT}`}
            inputClasses={'data-hj-whitelist'}
            normalize={parseInteger}
            type="number"
            minVal={1}
            component={InputNumber}
            label={String(translate('request_flow.what.fields.quantity.label'))}
          />
        </div>
        <div className={'item-wrapper--image'}>
          <InputFileUploads fieldName={`${item}.image`} formName={formName} accept={'image/*'} renderPreview={true} />
        </div>
        <div className={'item-wrapper--remove-container'}>
          <span
            className={'item-wrapper--remove'}
            title={String(translate('request_flow.what.item.remove'))}
            onClick={index > 0 ? handleRemove : undefined}
          >
            <IconTrash />
          </span>
        </div>
      </div>
    </>
  );
};

import _get from 'lodash/get';
import moment from 'moment-timezone';
import { getFormValues } from 'redux-form';

import { AddressCreationParams, DateTimePeriod } from '@brenger/api-client';

import { getUploadedFileDataByFormAndFieldName } from '../../state/ducks/baseReducer';
import { CarryingHelpChoices, Currencies, Nullable, StopType } from '../../typings';
import { DatetimePeriod, PriceData, RootState } from '../../typings/interfaces';
import { _DEFAULT_TIME_ZONE, _GENERAL_DEFAULT_TIME_END, _GENERAL_DEFAULT_TIME_START } from '../../utils/global';
// init state
import { prefix as generalFlowPrefix } from '../GeneralFlow';
import { isItemValid, normalizeItems } from '../GeneralFlow/ducks';
import { Item, ItemSet } from '../GeneralFlow/interface';
import { getLoggedInUser } from '../User/ducks';
import { _FLOW_TYPE as BusinessFlowType } from './containers/TransportRequest';
import { prefix } from './index';
import {
  BusinessAttributes,
  BusinessAttributesValues,
  BusinessFields,
  BusinessForms,
  BusinessState,
  DateSelectOption,
} from './interface';
import { getSessionIds } from '../../utils/eventTracking';
import { getDateFormatLocale } from '../../utils/datetime';

export const defaultState: BusinessState = {
  business_name: null,
  has_business_flow: false,
  depots: {
    loading: false,
    addresses: [],
  },
  transport_request: {
    uuid: '',
    price: {
      loading: false,
      vat: {
        amount: 0,
        currency: Currencies.EUR,
      },
      incl_vat: {
        amount: 0,
        currency: Currencies.EUR,
      },
      excl_vat: {
        amount: 0,
        currency: Currencies.EUR,
      },
    },
    date_time_periods: {
      options: [],
      index: 5,
    },
    layout: {
      sheet: false,
      step: 1,
    },
    error: null,
  },
};

export const _MODULE_PREFIX = '@@BusinessFlow';

// types
export const types = {
  SUBMIT_DESTINATION: `${_MODULE_PREFIX}/SUBMIT_DESTINATION`,
  SUBMIT_ITEMS: `${_MODULE_PREFIX}/SUBMIT_ITEMS`,
  SET_BUSINESS_DOMAIN_NAME: `${_MODULE_PREFIX}/SET_BUSINESS_DOMAIN_NAME`,
  SET_SHEET_STATE: `${_MODULE_PREFIX}/SET_SHEET_OPEN`,
  SET_PROGRESS_STEP: `${_MODULE_PREFIX}/SET_PROGRESS_STEP`,
  SET_TR_ID: `${_MODULE_PREFIX}/SET_TR_ID`,
  RESET_TR_BUSINESS_FLOW: `${_MODULE_PREFIX}/RESET_TR_BUSINESS_FLOW`,
  START_NEW_TR: `${_MODULE_PREFIX}/START_NEW_TR`,
  CREATE_TR: `${_MODULE_PREFIX}/CREATE_TR`,
  SET_HAS_BUSINESS_FLOW: `${_MODULE_PREFIX}/SET_HAS_BUSINESS_FLOW`,
  SET_BUSINESS_FLOW_ERROR: `${_MODULE_PREFIX}/SET_BUSINESS_FLOW_ERROR`,
  GET_PRICE: `${_MODULE_PREFIX}/GET_PRICE`,
  SET_PRICE: `${_MODULE_PREFIX}/SET_PRICE`,
  SET_PRICE_LOADING: `${_MODULE_PREFIX}/SET_PRICE_LOADING`,
  GET_DATE_TIME_PERIODS_OPTIONS: `${_MODULE_PREFIX}/GET_DATE_TIME_PERIODS_OPTIONS`,
  SET_DATE_TIME_PERIODS_OPTIONS: `${_MODULE_PREFIX}/SET_DATE_TIME_PERIODS_OPTIONS`,
  SET_DATE_TIME_PERIOD_INDEX: `${_MODULE_PREFIX}/SET_DATE_TIME_PERIOD_INDEX`,
  FETCH_ADDRESSES: `${_MODULE_PREFIX}/FETCH_BUSINESS_ADDRESS_START`,
  SET_BUSINESS_DEPOT_ADDRESSES: `${_MODULE_PREFIX}/FETCH_BUSINESS_ADDRESS_SUCCESS`,
};

// actions
export const actions = {
  setBusinessDomainName: (name: string | null) => ({ type: types.SET_BUSINESS_DOMAIN_NAME, name }),
  submitDestination: (details: any) => ({ type: types.SUBMIT_DESTINATION, details }),
  submitItemsServices: (details: any) => ({ type: types.SUBMIT_ITEMS, details }),
  setSheetState: (openBoolean: boolean) => ({ type: types.SET_SHEET_STATE, openBoolean }),
  setProgressStep: (step: number) => ({ type: types.SET_PROGRESS_STEP, step }),
  setTransportRequestId: (id: string) => ({ type: types.SET_TR_ID, id }),
  resetTrBusinessFlow: () => ({ type: types.RESET_TR_BUSINESS_FLOW }),
  setHasBusinessFlow: (hasFlow: boolean) => ({ type: types.SET_HAS_BUSINESS_FLOW, hasFlow }),
  setBusinessFlowError: (error: string | null) => ({ type: types.SET_BUSINESS_FLOW_ERROR, error }),
  getPrice: () => ({ type: types.GET_PRICE }),
  setPrice: (priceData: PriceData) => ({ type: types.SET_PRICE, priceData }),
  setPriceLoading: (loading: boolean) => ({ type: types.SET_PRICE_LOADING, loading }),
  getDateTimePeriodOptions: () => ({ type: types.GET_DATE_TIME_PERIODS_OPTIONS }),
  setDateTimePeriodOptions: (options: DatetimePeriod[]) => ({ type: types.SET_DATE_TIME_PERIODS_OPTIONS, options }),
  setDateTimePeriodIndex: (index: number) => ({ type: types.SET_DATE_TIME_PERIOD_INDEX, payload: index }),
  fetchAddressesStart: () => ({ type: types.FETCH_ADDRESSES }),
  setDepotAddresses: (addresses: AddressCreationParams[]) => ({
    type: types.SET_BUSINESS_DEPOT_ADDRESSES,
    payload: addresses,
  }),
  createNewTr: () => ({ type: types.CREATE_TR }),
};

// selectors
export const getSheetState = (state: RootState): boolean => {
  return state.business.transport_request.layout.sheet;
};

export const getProgressStep = (state: RootState): number => {
  return state.business.transport_request.layout.step;
};

export const getPrice = (state: RootState, inclVat: boolean = true): number => {
  try {
    return inclVat
      ? state.business.transport_request.price.incl_vat.amount
      : state.business.transport_request.price.excl_vat.amount;
  } catch (e) {
    return 0;
  }
};

export const getPriceLoading = (state: RootState): boolean => {
  return state.business.transport_request.price.loading;
};

export const getDateTimePeriodOptions = (state: RootState): DateSelectOption[] => {
  const options = state.business.transport_request.date_time_periods.options;
  if (options.length === 0) {
    return [];
  }
  const index = Math.min(getDateTimePeriodIndex(state), options.length - 1);
  const dateOptions: DateSelectOption[] = [];
  let i = 0;
  do {
    dateOptions.push({
      value: String(i),
      title: `<div>${moment(options[i].start).format('dddd')}<div class="date-short">${moment(options[i].start).format(
        getDateFormatLocale()
      )}</div></div>`,
    });
    i++;
  } while (i <= index);
  return dateOptions;
};

export const getDateTimePeriodIndex = (state: RootState): number => {
  return state.business.transport_request.date_time_periods.index;
};

export const getBusinessName = (state: RootState): Nullable<string> => {
  return state.business.business_name;
};

export const hasBusinessFlow = (state: RootState): boolean => {
  return state.business.has_business_flow;
};

export const getBusinessSlug = (state: RootState): string => {
  const businessName = getBusinessName(state);
  if (businessName === defaultState.business_name) {
    return '';
  }
  const businessSlug: string = _get(getLoggedInUser(state), 'userData.account.business_name_slug', 'unknown');

  return prefix.replace(':business_name', businessSlug);
};

export const getNewTransportRequestLink = (state: RootState): string => {
  const businessName = getBusinessName(state);
  if (businessName !== defaultState.business_name) {
    return `${getBusinessSlug(state)}/transport_request`;
  }
  return generalFlowPrefix;
};

export const getDashboardLink = (state: RootState): Nullable<string> => {
  const businessName = getBusinessName(state);
  if (businessName !== defaultState.business_name) {
    return `${getBusinessSlug(state)}/dashboard`;
  }
  return '/dashboard';
};

export const getTrId = (state: RootState): string => {
  return state.business.transport_request.uuid;
};

export const getBusinessFlowError = (state: RootState): Nullable<string> => {
  return state.business.transport_request.error;
};

export const getItemSetTotalSize = (state: RootState): number => {
  const form = getFormValues(BusinessForms.ITEMS_AND_SERVICE)(state);
  const items = _get(form, 'itemSets[0].items', []);
  const reducer = (accumulator, item) =>
    accumulator + item.count * (item.width / 100) * (item.height / 100) * (item.length / 100);
  return items.reduce(reducer, 0);
};

export const getDeliveryAddressAsString = (state: RootState): Nullable<string> => {
  const destinationForm = getFormValues(BusinessForms.DESTINATION)(state);
  if (!destinationForm) {
    return null;
  }
  return `${_get(destinationForm, BusinessFields.DELIVERY_FIRST_NAME, '')} ${_get(
    destinationForm,
    BusinessFields.DELIVERY_LAST_NAME,
    ''
  )}. ${_get(destinationForm, BusinessFields.DELIVERY_LINE_1, '')} ${_get(
    destinationForm,
    BusinessFields.DELIVERY_POSTAL_CODE,
    ''
  )}, ${_get(destinationForm, BusinessFields.DELIVERY_LOCALITY, '')}`;
};

export const getAllItems = (state: RootState): Item[] => {
  const itemValues = getFormValues(BusinessForms.ITEMS_AND_SERVICE)(state);
  return _get(itemValues, BusinessFields.ITEM_SETS + '[0].items', []);
};

export const getValidItemSets = (state: RootState): ItemSet[] => {
  const itemForm = getFormValues(BusinessForms.ITEMS_AND_SERVICE)(state);
  let itemSets: any = _get(itemForm, BusinessFields.ITEM_SETS, []);
  if (!itemSets.length) {
    return [];
  }
  itemSets = itemSets.filter(itemSet => {
    return itemSet.items.filter(item => isItemValid(item)).length !== 0;
  });
  // check for client reference and external invoices
  const clientReference = getClientReference(state);
  const externalInvoices = getUploadedFileDataByFormAndFieldName(
    state,
    BusinessForms.DESTINATION,
    BusinessFields.INVOICE,
    '@id'
  );
  // if both client ref and invoices don't exist, we are done
  if (!clientReference && !externalInvoices.length) {
    return itemSets;
  }
  // else map them into the itemSets and
  return itemSets.map(itemSet => {
    if (externalInvoices.length) {
      itemSet['external_invoices'] = externalInvoices;
    }
    if (clientReference) {
      itemSet['client_reference'] = clientReference;
    }
    return itemSet;
  });
};

export const getClientReference = (state: RootState): string | undefined => {
  const destinationForm = getFormValues(BusinessForms.DESTINATION)(state);
  if (!destinationForm) {
    return undefined;
  }
  return destinationForm[BusinessFields.CLIENT_REFERENCE];
};

export const getContactPickup = (state: RootState) => {
  const contact = getLoggedInUser(state);
  const customer = {
    first_name: contact.userData.first_name,
    last_name: contact.userData.last_name,
    email: contact.userData.email,
    phone: contact.userData.phone,
  };
  if (_get(contact, 'userData.account.account_type', '') === 'business') {
    return {
      ...customer,
      company_details: {
        company_name: _get(contact, 'userData.account.name', ''),
        coc_number: _get(contact, 'userData.account.coc_number', ''),
        vat_number: _get(contact, 'userData.account.vat_number', ''),
        address: getAddress(state, StopType.pickup),
      },
    };
  } else {
    return customer;
  }
};

export const getContactDelivery = (state: RootState) => {
  const destinationForm = getFormValues(BusinessForms.DESTINATION)(state);
  return {
    first_name: _get(destinationForm, BusinessFields.DELIVERY_FIRST_NAME, ''),
    last_name: _get(destinationForm, BusinessFields.DELIVERY_LAST_NAME, ''),
    email: _get(destinationForm, BusinessFields.DELIVERY_EMAIL, ''),
    phone: _get(destinationForm, BusinessFields.DELIVERY_PHONE, ''),
  };
};

export const getAddress = (state: RootState, stopType: StopType) => {
  const destinationFormValues = getFormValues(BusinessForms.DESTINATION)(state);
  if (stopType === StopType.delivery) {
    return destinationFormValues[stopType].address;
  }
  const depotAddresses = state.business.depots.addresses;
  if (!depotAddresses.length) {
    return {};
  }
  if (depotAddresses.length === 1) {
    return depotAddresses[0];
  }
  const index = _get(destinationFormValues, BusinessFields.DEPOT_SELECT, 0);
  return depotAddresses[index];
};

export const getInstructions = (state: RootState) => {
  const addressFormValues = getFormValues(BusinessForms.DESTINATION)(state);
  return _get(addressFormValues, BusinessFields.DELIVERY_INSTRUCTIONS, '');
};

export const getAddressDetails = (state: RootState, stopType: StopType) => {
  const isPickup = stopType === StopType.pickup;
  const serviceValues = getFormValues(BusinessForms.ITEMS_AND_SERVICE)(state);
  const addressFormValues = getFormValues(BusinessForms.DESTINATION)(state);
  const instructions = isPickup ? '' : _get(addressFormValues, BusinessFields.DELIVERY_INSTRUCTIONS, '');
  const commonDetails = {
    carrying_help:
      _get(serviceValues, BusinessFields.SERVICE_EXTRA_DRIVER, false) === true
        ? CarryingHelpChoices.EXTRA_DRIVER
        : CarryingHelpChoices.NOT_NEEDED,
    floor_level: 0,
    elevator: false,
    extras: [],
    instructions,
  };
  return {
    address: getAddress(state, stopType),
    details: {
      ...commonDetails,
      situation: isPickup ? 'store' : 'home',
    },
  };
};

export const getSelectedDatetimePeriod = (state: RootState, stopType: StopType): undefined | DateTimePeriod[] => {
  const formValues = getFormValues(BusinessForms.ITEMS_AND_SERVICE)(state);
  const dateIndex = _get(formValues, BusinessFields.DATE, false);
  if (!dateIndex) {
    return undefined;
  }
  const selectedDateOption = state.business.transport_request.date_time_periods.options[
    parseInt(dateIndex, 10)
  ] as DateTimePeriod;
  if (stopType === StopType.pickup) {
    return [selectedDateOption];
  }
  return [
    {
      start: moment
        .tz(selectedDateOption.start, _DEFAULT_TIME_ZONE)
        .hour(_GENERAL_DEFAULT_TIME_START)
        .toISOString(),
      end: moment
        .tz(selectedDateOption.start, _DEFAULT_TIME_ZONE)
        .hour(_GENERAL_DEFAULT_TIME_END)
        .toISOString(),
    },
  ];
};

export const getPriceRequestParams = (state: any): object | null => {
  const itemSets = normalizeItems(getValidItemSets(state));
  if (itemSets.length === 0) {
    return null;
  }
  const pickup = getAddressDetails(state, StopType.pickup);
  const delivery = getAddressDetails(state, StopType.delivery);
  return {
    account_id: _get(getLoggedInUser(state), 'userData.account.id', ''),
    item_sets: itemSets,
    pickup,
    delivery,
  };
};

export const getIsReturnTransport = (state: RootState): boolean => {
  return _get(getFormValues(BusinessForms.DESTINATION)(state), BusinessFields.RETURN_ORDER, false);
};

export const getTransportRequestPayload = (state: RootState): object | null => {
  const items = normalizeItems(getValidItemSets(state));
  const price = getPrice(state);
  if (items.length === 0 || price === 0) {
    return null;
  }
  // Is it a return transport?
  const isReturnOrder = getIsReturnTransport(state);

  // Store the type of orderinstructions
  items[0]['attributes'] = {
    [BusinessAttributes.ORDER_TYPE]: !isReturnOrder
      ? BusinessAttributesValues.ORDER_TYPE_NORMAL
      : BusinessAttributesValues.ORDER_TYPE_RETURN,
  };

  // Prepare pickup data
  const pickupAddress = getAddressDetails(state, StopType.pickup);
  const pickups = [
    {
      ...pickupAddress,
      contact: getContactPickup(state),
      available_datetime_periods: getSelectedDatetimePeriod(state, StopType.pickup),
    },
  ];

  // Prepare delivery data
  const deliveryAddress = getAddressDetails(state, StopType.delivery);
  const deliveries = [
    {
      ...deliveryAddress,
      contact: getContactDelivery(state),
      available_datetime_periods: getSelectedDatetimePeriod(state, StopType.delivery),
    },
  ];

  return {
    source_flow: BusinessFlowType,
    guaranteed: true,
    item_sets: items,
    price: {
      amount: price,
      currency: Currencies.EUR,
    },
    customer: getContactPickup(state),
    pickups: !isReturnOrder ? pickups : deliveries,
    deliveries: !isReturnOrder ? deliveries : pickups,
    frontend_url: window.location.href,
    session_ids: getSessionIds(),
  };
};

// reducers
export const businessReducer = (state: BusinessState = defaultState, action) => {
  switch (action.type) {
    case types.SET_BUSINESS_DOMAIN_NAME:
      return {
        ...state,
        business_name: action.name,
      };
      break;
    case types.SET_SHEET_STATE:
      return {
        ...state,
        transport_request: {
          ...state.transport_request,
          layout: {
            ...state.transport_request.layout,
            sheet: action.step,
          },
        },
      };
      break;
    case types.SET_PROGRESS_STEP:
      return {
        ...state,
        transport_request: {
          ...state.transport_request,
          layout: {
            ...state.transport_request.layout,
            step: action.step,
          },
        },
      };
      break;
    case types.SET_TR_ID:
      return {
        ...state,
        transport_request: {
          ...state.transport_request,
          uuid: action.id,
        },
      };
      break;
    case types.SET_BUSINESS_FLOW_ERROR:
      return {
        ...state,
        transport_request: {
          ...state.transport_request,
          error: action.error,
        },
      };
      break;
    case types.SET_HAS_BUSINESS_FLOW:
      return {
        ...state,
        has_business_flow: action.hasFlow,
      };
      break;
    case types.SET_PRICE:
      return {
        ...state,
        transport_request: {
          ...state.transport_request,
          price: {
            ...action.priceData,
            loading: false,
          },
        },
      };
      break;
    case types.SET_PRICE_LOADING:
      return {
        ...state,
        transport_request: {
          ...state.transport_request,
          price: {
            ...state.transport_request.price,
            loading: action.loading,
          },
        },
      };
      break;
    case types.SET_DATE_TIME_PERIODS_OPTIONS:
      return {
        ...state,
        transport_request: {
          ...state.transport_request,
          date_time_periods: {
            options: action.options,
            index: 5,
          },
        },
      };
      break;
    case types.SET_DATE_TIME_PERIOD_INDEX:
      return {
        ...state,
        transport_request: {
          ...state.transport_request,
          date_time_periods: {
            ...state.transport_request.date_time_periods,
            index: action.payload,
          },
        },
      };
      break;
    case types.FETCH_ADDRESSES:
      return {
        ...state,
        depots: {
          loading: true,
          addresses: [],
        },
      };
    case types.SET_BUSINESS_DEPOT_ADDRESSES:
      return {
        ...state,
        depots: {
          loading: false,
          addresses: action.payload,
        },
      };
    default:
      return state;
  }
};

export default businessReducer;

import React from 'react';
import { CheckMarkList, PriceDisplay, SheetLayoutSidebarContent } from '../../../brenger-shared-ui';
import { translate } from '../../../utils/localization';
import { _INSURANCE_AMOUNT } from '../../../utils/global';
import { toEuroString } from '../../../utils/payment';
import { Translate } from 'react-localize-redux';

interface Props {
  price: number;
}

export class SidebarLayout extends React.Component<Props, any> {
  public render() {
    return (
      <div className={'sheet-layout--sidebar-background'}>
        <div className="summary">
          <div className="summary--body">
            <div className={'summary-heading'}>
              <Translate id={'request_flow.headers.transport_request_title'} />
            </div>
          </div>
        </div>

        <SheetLayoutSidebarContent>
          <div className={'business--price-wrapper'}>
            <PriceDisplay
              header={
                <>
                  <Translate id={'request_flow.price.total_ex_vat'} />
                </>
              }
              cents={this.props.price}
            />
          </div>
          <CheckMarkList
            header={String(translate('request_flow.business.usp.title'))}
            checkMarkStyle={'primary'}
            list={[
              translate('request_flow.business.usp.one', { insurance_amount: toEuroString(_INSURANCE_AMOUNT) }),
              translate('request_flow.business.usp.two', { insurance_amount: toEuroString(_INSURANCE_AMOUNT) }),
              translate('request_flow.business.usp.three', { insurance_amount: toEuroString(_INSURANCE_AMOUNT) }),
              translate('request_flow.business.usp.four', { insurance_amount: toEuroString(_INSURANCE_AMOUNT) }),
            ]}
          />
        </SheetLayoutSidebarContent>
      </div>
    );
  }
}

export default SidebarLayout;

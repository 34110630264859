// contact form between marktplaats buyer and seller

import React from 'react';
import {
  Col,
  FlashMessage,
  Heading,
  Input,
  Row,
  SheetLayoutContent,
  SheetLayoutHeader,
} from '../../../../brenger-shared-ui';
import '../../../../assets/marktplaats/marktplaats.css';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import Button from '../../../../brenger-shared-ui/components/basics/button/Button';
import { GeneralFlowForms, User } from '../../interface';
import { actions as flowActions } from '../../ducks';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { PersonSchema } from '../../schema';
import { Translate } from 'react-localize-redux';
import { translate } from '../../../../utils/localization';

type Props = InjectedFormProps<{}> & RouteComponentProps<{}>;

class BuyerForm extends React.Component<any, any> {
  public componentDidMount(): void {
    const header = document.querySelector('.sheet-layout--content-wrapper--top-header') as HTMLElement;
    header.style.display = 'none';
  }

  public componentWillUnmount(): void {
    const header = document.querySelector('.sheet-layout--content-wrapper--top-header') as HTMLElement;
    header.style.display = 'block';
  }

  public render() {
    const qs = window.location.search;
    const errors = this.props.errors;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div className={'marktplaats-onboarding-form'}>
          <SheetLayoutHeader>
            <Heading size={2}>
              <Translate id={'request_flow.marktplaats.form.title'} />
            </Heading>
          </SheetLayoutHeader>
          <SheetLayoutContent>
            <div className={'question-wrapper'}>
              <p>
                <Translate id={'request_flow.marktplaats.form.subtitle'} />
              </p>
              <Row>
                <Col xs={12}>
                  <label>
                    <b>
                      <Translate id={'request_flow.marktplaats.form.buyer_email_title'} />:
                    </b>
                  </label>
                  <Field
                    name={`pickup.marktplaats.buyer_email`}
                    type="text"
                    validate={PersonSchema[User.email]}
                    component={Input}
                    label={String(translate('request_flow.marktplaats.form.buyer_email_label'))}
                  />
                </Col>
                <Col xs={12}>
                  <label>
                    <b>
                      <Translate id={'request_flow.marktplaats.form.seller_email_title'} />:
                    </b>
                  </label>
                  <Field
                    validate={PersonSchema[User.email]}
                    name={`pickup.marktplaats.seller_email`}
                    type="text"
                    component={Input}
                    label={String(translate('request_flow.marktplaats.form.seller_email_label'))}
                  />
                </Col>
                <Col xs={12}>
                  <label>
                    <b>
                      <Translate id={'request_flow.marktplaats.form.product_desc_title'} />:
                    </b>
                  </label>
                  <Field
                    name={`pickup.marktplaats.product_description`}
                    type="text"
                    component={Input}
                    label={String(translate('request_flow.marktplaats.form.product_desc_label'))}
                  />
                </Col>
              </Row>
              <p>
                <Translate id={'request_flow.marktplaats.form.hint'} />
              </p>
              <ul>
                <li>
                  <Translate id={'request_flow.marktplaats.answers.first'} />
                </li>
                <li>
                  <Translate id={'request_flow.marktplaats.answers.second'} />
                </li>
                <li>
                  <Translate id={'request_flow.marktplaats.answers.third'} />
                </li>
                <li>
                  <Translate id={'request_flow.marktplaats.answers.forth'} />
                </li>
                <li>
                  <Translate id={'request_flow.marktplaats.answers.fifth'} />
                </li>
              </ul>
            </div>
            {errors && errors !== '' && <FlashMessage type={'error'} message={errors} />}
          </SheetLayoutContent>
          {qs.indexOf('success=true') > -1 && (
            <div className={'after-submit-message'}>
              <h4>
                <Translate id={'request_flow.marktplaats.form.success_message'} />
              </h4>
              <Button onClick={() => window.location.assign('/')}>
                <Translate id={'request_flow.marktplaats.form.call_to_action'} />
              </Button>
            </div>
          )}
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  errors: state.generalTransport.errors,
});

export default reduxForm<{}, Props>({
  form: GeneralFlowForms.pickup,
  destroyOnUnmount: false,
  forceUnregisterOnUmount: true,
  initialValues: {
    pickup: {
      buyer_email: '',
      seller_email: '',
    },
  },
  onSubmit: (formResults, dispatch) => dispatch(flowActions.submitSellerForm()),
})(connect(mapStateToProps)(BuyerForm));

import * as React from 'react';
import { Route } from 'react-router';
import { reduxForm } from 'redux-form';
import {
  actions as flowActions,
  customerIsBusiness,
  getFlowTitleByStepAndSituation,
  getGroupHistoricalAddresses,
  getStopSituation,
} from '../ducks';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { prefix } from '../index';
import { SheetLayoutContentWrapper } from '../../../brenger-shared-ui';
import { RootState, StopType } from '../../../typings';
import FooterLayout from './FooterLayout';
import ContactPickup from '../components/ContactPickup';
import ContactDelivery from '../components/ContactDelivery';
import ContactPrimary from '../components/ContactPrimary';
import { getCountries } from '../../../state/ducks/baseReducer';
import { GeneralFlowForms, TitleStepName } from '../interface';
import { isUserLoggedIn } from '../../User/ducks';

// TODO: Use types from @types/redux-form when correct package has been installed.
interface FormProps {
  handleSubmit(): void;
}

type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export type AllProps = ReduxProps & FormProps;

const Contact: React.FC<AllProps> = props => {
  React.useEffect(() => {
    props.setProgress(7);
  }, []);

  return (
    <form onSubmit={props.handleSubmit}>
      <SheetLayoutContentWrapper>
        <Route
          exact={true}
          path={`${prefix}/contact`}
          render={() => (
            <ContactPrimary isLoggedIn={props.isLoggedIn} countries={props.countries} isBusiness={props.isBusiness} />
          )}
        />
        <Route
          exact={true}
          path={`${prefix}/contact/pickup`}
          render={() => (
            <ContactPickup
              title={props.title}
              groupHistoricalAddresses={props.groupHistoricalAddresses}
              pickupSituation={props.pickupSituation}
            />
          )}
        />
        <Route
          exact={true}
          path={`${prefix}/contact/delivery`}
          render={() => (
            <ContactDelivery title={props.title} groupHistoricalAddresses={props.groupHistoricalAddresses} />
          )}
        />
        <FooterLayout />
      </SheetLayoutContentWrapper>
    </form>
  );
};

const mapStateToProps = (state: RootState) => ({
  transport: state.generalTransport,
  pickupSituation: getStopSituation(state, StopType.pickup),
  isBusiness: customerIsBusiness(state),
  countries: getCountries(state),
  isLoggedIn: isUserLoggedIn(state),
  groupHistoricalAddresses: (stopType: StopType) => getGroupHistoricalAddresses(state, stopType),
  title: (stepName: TitleStepName) => getFlowTitleByStepAndSituation(state, stepName),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setProgress: (step: number) => dispatch(flowActions.setProgress(step)),
});

const form = reduxForm({
  form: GeneralFlowForms.contact,
  destroyOnUnmount: false,
  initialValues: {
    customer: {
      type: 'individual',
    },
  },
  updateUnregisteredFields: true,
  forceUnregisterOnUnmount: true,
  onSubmit: (_, dispatch: Dispatch) => dispatch(flowActions.submitContact()),
});

export default connect(mapStateToProps, mapDispatchToProps)(form(Contact));

import * as React from 'react';
import './inputtexttoggle.css';

export interface Props {
  input: any;
  label: string;
}

const InputTextToggle: React.FC<Props> = ({ input, label }) => (
  <div className={'input-el--text-toggle'}>
    <input id={input.name} type={'checkbox'} {...input} />
    {label && (
      <label className={'input-el--text-toggle--label'} htmlFor={input.name}>
        <span className={'input-el--text-toggle--label-text'}>{label}</span>
      </label>
    )}
  </div>
);

export default InputTextToggle;

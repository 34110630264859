import { all, call, debounce, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { matchPath } from 'react-router';
import _get from 'lodash/get';
import { actions, getTransportRequestId, getTransportRequestState, types } from './ducks';
import { actions as flowActions } from '../../modules/GeneralFlow/ducks';
import { http } from '../../utils/request';
import { _GOOGLE_REVIEW_URL, _TRUST_PILOT_REVIEW_URL, TJAL_STATE } from '../../utils/global';
import * as appUtils from '../../utils/basics';
import { getIsAllowedToPayByInvoice, isUserLoggedIn } from '../User/ducks';
import { prefix as statusPagePath } from './index';
import { fetchEndpoint } from '../../state/sagas/baseSagas';
import { getCommitmentByStopId } from '../../utils/commitments';
import { ItemSetState, Nullable, StopType } from '../../typings';
import { calculateRoute, searchGeoPoint } from '../../utils/geo';
import { StatusPageForms } from './interface';
import { actionTypes as formActions, getFormValues, reset } from 'redux-form';
import { FilterFields } from './typings';

function* setTransportRequestId(action) {
  const url = action.payload.location.pathname;
  const match = matchPath(url, { path: `${statusPagePath}/:id`, exact: true, strict: false });
  const matchOverview = matchPath(url, { path: `${statusPagePath}`, exact: true, strict: false });
  if (match || matchOverview) {
    yield put(actions.clearTransportRequest());
    const header = document.querySelector('header.region-header');
    if (header) {
      header.remove();
    }
  }
  if (match) {
    const isLoggedIn = yield select(isUserLoggedIn);
    if (isLoggedIn) {
      document.body.style.overflow = 'hidden';
    }
  } else {
    document.body.style.overflow = '';
    return;
  }
  yield put(actions.setTransportRequestId(_get(match, 'params.id', null)));
}

export function* determineTypeTransportPage(action) {
  const trackAndTraceData = yield call(getTrackAndTraceData, action.payload);
  const type = trackAndTraceData?.['@type'];

  if (!type) {
    yield put(actions.failedLoadingTransportRequest());
    return;
  }

  if (type === 'TransportRequest') {
    yield call(loadNormalTransportRequestPage, trackAndTraceData, action.payload);
  } else {
    yield call(loadStopContactTransportRequestPage, trackAndTraceData);
  }
}

function* loadNormalTransportRequestPage(transportRequestResponse: any, transportRequestId: string) {
  try {
    const transportJobAccountLinksResponse = yield call(fetchTransportJobAccountLinks, transportRequestId);
    if (transportRequestResponse.pickups.length === 0 || transportRequestResponse.deliveries.length === 0) {
      return;
    }
    // handle Transport job ID
    const parsedId = _get(transportJobAccountLinksResponse, 'data["hydra:member"][0]["transport_job"]', false);
    if (parsedId) {
      yield put(actions.setTransportJobId(parsedId.split('transport_jobs/')[1]));
    } else {
      yield put(actions.setTransportJobId(null));
    }
    // if driver available, set it
    if (_get(transportRequestResponse, 'driver_user', false)) {
      yield put(
        actions.setAcceptedAccountLink({
          driver_user: _get(transportRequestResponse, 'driver_user', false),
          state: TJAL_STATE.ACCEPTED,
        })
      );
    }
    // check if there are commitments
    const pickupCommitment = getCommitmentByStopId(
      transportJobAccountLinksResponse,
      transportRequestResponse,
      StopType.pickup
    );
    if (pickupCommitment.length > 0) {
      transportRequestResponse.pickups[0].available_datetime_periods = pickupCommitment;
    }
    const deliveryCommitment = getCommitmentByStopId(
      transportJobAccountLinksResponse,
      transportRequestResponse,
      StopType.delivery
    );
    if (deliveryCommitment.length > 0) {
      transportRequestResponse.deliveries[0].available_datetime_periods = deliveryCommitment;
    }
    // check we have a geo point first
    if (_get(transportRequestResponse, 'route.points', false)) {
      yield all([
        put(actions.setTransportRequest(transportRequestResponse)),
        put(actions.setRoutePolylines(transportRequestResponse.route)),
      ]);
    } else {
      // We don't have geo points, so lets get them
      const pickup = transportRequestResponse.pickups[0].address;
      const delivery = transportRequestResponse.deliveries[0].address;

      // trying to fetch the geo point based on the full address
      const [pickupAddress, deliveryAddress] = yield all([
        call(getFullAddress, pickup),
        call(getFullAddress, delivery),
      ]);
      const [pickupGeoPoint, deliveryGeoPoint] = yield all([
        call(searchGeoPoint, pickupAddress),
        call(searchGeoPoint, deliveryAddress),
      ]);

      if (pickupGeoPoint.data.length === 0 || deliveryGeoPoint.data.length === 0) {
        // route data failed
        yield put(actions.setTransportRequest(transportRequestResponse));
      } else {
        // set the geo points to the current pickup and delivery in store
        const [trPickup, trDelivery] = yield all([
          call(mapAddressGeoPoints, transportRequestResponse, pickupGeoPoint.data[0].address),
          call(mapAddressGeoPoints, transportRequestResponse, deliveryGeoPoint.data[0].address, true),
        ]);
        const route = yield call(
          calculateRoute,
          {
            lat: trPickup.pickups[0].address.lat,
            lng: trPickup.pickups[0].address.lng,
          },
          {
            lat: trDelivery.pickups[0].address.lat,
            lng: trDelivery.pickups[0].address.lng,
          }
        );
        yield all([
          put(actions.setTransportRequest(transportRequestResponse)),
          put(actions.setRoutePolylines(route.data)),
        ]);
      }
    }
  } catch (err) {
    /*if (_get(transportRequestResponse.data, '@id', false)) {
      yield put(actions.setTransportRequest(transportRequest));
    }*/
    yield put(actions.failedLoadingTransportRequest());
  }
}

export function* loadStopContactTransportRequestPage(transportRequestResponse) {
  // handle items sets
  const itemsEndpoint: string = transportRequestResponse.item_sets[0]['@id'];
  const itemSetsResponse = yield call(fetchEndpoint, itemsEndpoint);
  const itemResponses = yield all(
    _get(itemSetsResponse, 'data.items', []).map(itemIri => {
      return call(fetchEndpoint, itemIri);
    })
  );
  // map item set data
  const itemSets = [
    {
      ...itemSetsResponse,
      items: itemResponses.map(itemResponse => {
        return itemResponse.data;
      }),
      state: _get(itemSetsResponse, 'data.state', ItemSetState.READY_FOR_PICKUP),
    },
  ];

  // check if there are commitments, with a fall back to available datetime periods
  const commitments = transportRequestResponse['@type'] === 'Pickup' ? 'pickup_commitments' : 'delivery_commitments';
  const datetimePeriods = _get(
    transportRequestResponse,
    commitments + '[0].committed_datetime_period',
    transportRequestResponse.available_datetime_periods[0]
  );

  // is address complete?
  const address = transportRequestResponse.address;
  if (!address.hasOwnProperty('lat')) {
    const addressLine = yield call(getFullAddress, address);
    const geoPoint = yield call(searchGeoPoint, addressLine);
    if (_get(geoPoint, 'data[0]address')) {
      address.lat = geoPoint.data[0].address.latitude;
      address.lng = geoPoint.data[0].address.longitude;
    }
  }
  // map total response
  const transportRequest = {
    ...transportRequestResponse,
    item_sets: itemSets,
    stopTypeDetails: {
      ['@id']: transportRequestResponse['@id'],
      details: {
        ...transportRequestResponse.details,
      },
      available_datetime_periods: [datetimePeriods],
      address,
    },
  };

  yield put(actions.setTransportRequestStopContact(transportRequest));

  // if driver available, set it
  if (_get(transportRequestResponse, 'driver_user', false)) {
    yield put(
      actions.setAcceptedAccountLink({
        driver_user: _get(transportRequestResponse, 'driver_user', false),
        state: TJAL_STATE.ACCEPTED,
      })
    );
  }
}

function* getDriverList() {
  const transportRequestId = yield select(getTransportRequestId);
  const transportRequestState = yield select(getTransportRequestState);
  const driverOffer = yield call(getDriversOfferList, transportRequestId);
  yield all([put(actions.setAccountLinks(driverOffer.data))]);

  const acceptedDriver: any = [];
  driverOffer.data['hydra:member'].map(offer => {
    if (offer.state === 'accepted' || transportRequestState === TJAL_STATE.DELIVERED) {
      acceptedDriver.push(put(actions.setAcceptedAccountLink(offer)));
    }
  });
  yield all(acceptedDriver);
}

export function* rejectAccountLink(action) {
  try {
    const id = action.payload.split('transport_job_account_links/')[1];
    const reject = yield call(rejectDriver, id);
    if (reject) {
      yield put(actions.removeAccountLink(action.payload));
    }
  } catch (err) {
    appUtils.logger(err);
    yield put(actions.rejectAccountLinkFailed(err));
  }
}

export async function fetchTransportRequestById(id: string): Promise<Nullable<any>> {
  try {
    const { data } = await http().get(`/transport_requests/${id}`);
    return data;
  } catch (err) {
    return null;
  }
}

export function confirmTemporaryDriver(jobId, payByInvoice = false) {
  const endpoint = payByInvoice ? 'accept' : 'temporary_accept';
  return http()
    .post(`/transport_job_account_links/${jobId}/${endpoint}`, {}, { withCredentials: true })
    .then(resp => resp);
}

export function* confirmAccountLink(action) {
  if (window.location.href.indexOf('trace') < 0) {
    return;
  }
  try {
    const transportRequestId = window.location.href.split('trace/')[1];
    const transportRequest = yield call(fetchTransportRequestById, transportRequestId);
    const payByInvoice = yield select(getIsAllowedToPayByInvoice);
    const isPaid = transportRequest?.eligible_for_delivery;
    const id = action.payload.split('transport_job_account_links/')[1];
    if (isPaid) {
      yield all([call(confirmDriver, id), put(actions.confirmedAccountLink(action.payload))]);
    } else {
      if (transportRequestId) {
        localStorage.setItem('temp_request', transportRequestId);
        yield all([
          call(confirmTemporaryDriver, id, payByInvoice),
          put(flowActions.setTransportUUID(transportRequestId)),
          put(push('/not_prepaid_jobs/payment')),
        ]);
      }
    }
  } catch (err) {
    appUtils.logger(err);
    yield put(actions.confirmAccountLinkFailed(err));
  }
}

export function openGoogleReviews() {
  const reviewUrl = Math.random() < 0.5 ? _GOOGLE_REVIEW_URL : _TRUST_PILOT_REVIEW_URL;
  window.open(reviewUrl, '_blank', 'menubar=1,resizable=1,width=600,height=728');
}

export function getDriversOfferList(uuid) {
  return http()
    .get(
      `transport_requests/${uuid}/transport_job_account_links?state=${TJAL_STATE.PENDING},${TJAL_STATE.TEMPORARY_ACCEPTED}`,
      { withCredentials: true }
    )
    .then(resp => resp);
}

export function getFullAddress(addr) {
  return `${addr.line1}, ${addr.postal_code}, ${addr.locality}`;
}

export function fetchTransportJobAccountLinks(id) {
  return http()
    .get(`/transport_requests/${id}/transport_job_account_links`, { withCredentials: true })
    .then(resp => resp);
}

export function mapAddressGeoPoints(transport, addr, delivery = false) {
  if (delivery) {
    transport.deliveries[0].address['lat'] = addr.latitude;
    transport.deliveries[0].address['lng'] = addr.longitude;
  } else {
    transport.pickups[0].address['lat'] = addr.latitude;
    transport.pickups[0].address['lng'] = addr.longitude;
  }
  return transport;
}

export function* acceptTransportJob(action) {
  const match = matchPath(action.payload.location.pathname, {
    path: `${statusPagePath}/:id`,
    exact: true,
    strict: false,
  });
  if (match && window.location.href.indexOf('accept=true') > -1) {
    const jobId = localStorage.getItem('temp_job');
    yield all([call(confirmDriver, jobId), put(actions.confirmedAccountLink('/transport_job_account_links/' + jobId))]);
    localStorage.removeItem('temp_request');
    localStorage.removeItem('temp_job');
  }
}

function* getTrackAndTraceData(transportRequestId: string) {
  const [transportRequest, stopContact] = yield all([
    call(fetchTransportRequestById, transportRequestId),
    call(fetchStopContact, transportRequestId),
  ]);

  const validStopContactRequest = _get(stopContact, 'hydra:member', []).length > 0;

  if (transportRequest && validStopContactRequest) {
    appUtils.logException('Duplicate UUID for TR and stopContact', { action_required: 'Tams is buying drinks' });
    // return the one with less sensitive information
    return stopContact;
  }

  if (transportRequest) {
    return transportRequest;
  }

  if (validStopContactRequest) {
    return stopContact['hydra:member'][0];
  }

  return null;
}

async function fetchStopContact(id: string) {
  try {
    const { data } = await http().get(`/stop_contacts/${id}/stops`);
    return data;
  } catch (err) {
    return null;
  }
}

export function confirmDriver(jobId) {
  return http()
    .post(`/transport_job_account_links/${jobId}/accept`, {}, { withCredentials: true })
    .then(resp => resp);
}

export function rejectDriver(jobId) {
  return http()
    .post(`/transport_job_account_links/${jobId}/reject`, {}, { withCredentials: true })
    .then(resp => resp);
}

export function cancelTransportRequest(id: string) {
  return http()
    .post(`/transport_requests/${id}/cancel`, { withCredentials: true })
    .catch(err => {
      appUtils.logger(err);
      return err;
    });
}

export function* handleCancelTransportRequest(action) {
  const response = yield call(cancelTransportRequest, action.payload);
  if (response.status >= 200 && response.status <= 204) {
    yield put(actions.cancelTransportRequestSuccess(action.payload));
    window.location.reload();
  } else {
    yield put(actions.cancelTransportRequestFailed(action.payload, response));
  }
}
export function* resetPagination(action) {
  // check if we are dealing with the right form
  if (action.meta.form !== StatusPageForms.FILTERS) {
    return;
  }
  // trigger fetch first page, tot reset pagination and fetch new results automagically
  yield put(actions.fetchFilteredResultPage(1));
}

/*
SETUP FILTERS
Action can either be pagination or redux-form
*/
function* setupFilters(action) {
  // kickoff building query params with page number
  const queryParams: string[] = ['page=' + action.page];
  // scroll to top
  window.scrollTo(0, 0);

  // get filters
  const formValues = yield select(getFormValues(StatusPageForms.FILTERS));
  if (!formValues) {
    yield put(actions.setFilters(queryParams));
    return;
  }

  if (formValues[FilterFields.PRESENTATION]) {
    queryParams.push(`presentation=${formValues[FilterFields.PRESENTATION]}`);
  }

  if (formValues[FilterFields.QUERY]) {
    queryParams.push('search=' + encodeURIComponent(formValues[FilterFields.QUERY]));
  }
  if (formValues[FilterFields.DATE]) {
    queryParams.push('delivery_day=' + encodeURIComponent(formValues[FilterFields.DATE]));
  }

  if (
    (formValues[FilterFields.ORDER_TYPE_NORMAL] && formValues[FilterFields.ORDER_TYPE_RETURN]) ||
    (!formValues[FilterFields.ORDER_TYPE_NORMAL] && !formValues[FilterFields.ORDER_TYPE_RETURN])
  ) {
    queryParams.push('business_order_type=all');
  }

  if (formValues[FilterFields.ORDER_TYPE_NORMAL] && !formValues[FilterFields.ORDER_TYPE_RETURN]) {
    queryParams.push('business_order_type=normal');
  }

  if (!formValues[FilterFields.ORDER_TYPE_NORMAL] && formValues[FilterFields.ORDER_TYPE_RETURN]) {
    queryParams.push('business_order_type=return');
  }

  yield put(actions.setFilters(queryParams));
}

function* getFilteredResults(action) {
  yield put(actions.fetchFilteredResultsStart());
  try {
    const query = action.payload.filters.length ? '?' + action.payload.filters.join('&') : '';
    const getTransportRequest = () => http().get(`/transport_requests${query}`, { withCredentials: true });
    const response = yield call(getTransportRequest);
    const effects = [
      put(actions.fetchFilteredResultTotalRequests(response.data['hydra:totalItems'])),
      put(actions.fetchFilteredResultsSuccess(response.data['hydra:member'])),
    ];
    if (action.payload.updateAccountTotalRequests) {
      effects.push(put(actions.setAccountTotalRequests(response.data['hydra:totalItems'])));
    }
    yield all(effects);
  } catch (e) {
    appUtils.logger(e);
    yield put(actions.fetchFilteredResultsFailed());
  }
}

function* resetFilters() {
  yield put(reset(StatusPageForms.FILTERS));
}

export function* StatusPageSagas() {
  yield takeLatest(LOCATION_CHANGE, acceptTransportJob);
  yield takeLatest(LOCATION_CHANGE, setTransportRequestId);

  yield takeLatest(types.SET_STATUS_PAGE_ID, determineTypeTransportPage);
  yield takeLatest(types.SET_TRANSPORT_REQUEST, getDriverList);

  // confirm, reject account link
  yield takeLatest(types.CONFIRM_ACCOUNT_LINK, confirmAccountLink);
  yield takeLatest(types.REJECT_ACCOUNT_LINK, rejectAccountLink);

  // delete transport request
  yield takeEvery(types.CANCEL_TRANSPORT_REQUEST_START, handleCancelTransportRequest);

  // Handle filter changes
  yield debounce(500, formActions.CHANGE, resetPagination);
  yield takeLatest(types.SET_FILTERS, getFilteredResults);
  yield takeLatest(types.FETCH_FILTERED_RESULTS_PAGE, setupFilters);
  yield takeLatest(types.RESET_FILTERS, resetFilters);
}

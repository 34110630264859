import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { localizeReducer } from 'react-localize-redux';
import { History } from 'history';
import { reducer as formReducer } from 'redux-form';
import { bvaTransport } from '../../modules/BvaFlow/ducks';
import { vavatoTransport } from '../../modules/VavatoFlow/ducks';
import { generalTransport } from '../../modules/GeneralFlow/ducks';
import { ikeaTransport } from '../../modules/IkeaFlow/ducks';
import { base } from './baseReducer';
import { paymentReducer } from '../../modules/Payment/ducks';
import userReducer from '../../modules/User/ducks';
import { chatReducer } from '../../modules/Chat/ducks';
import { statusPage } from '../../modules/StatusPage/ducks';
import businessReducer from '../../modules/Business/ducks';
import { notificationsReducer } from './notifications';

export default (history: History) =>
  combineReducers({
    router: connectRouter(history),
    localize: localizeReducer,
    form: formReducer,
    generalTransport,
    statusPage,
    ikeaTransport,
    bvaTransport,
    vavatoTransport,
    payment: paymentReducer,
    user: userReducer,
    chat: chatReducer,
    base,
    business: businessReducer,
    notifications: notificationsReducer,
  });

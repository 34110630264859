import { getTitleOfItemsAsString } from '../modules/GeneralFlow/ducks';
import _get from 'lodash/get';
import { BusinessAttributes, BusinessAttributesValues } from '../modules/Business/interface';

export const getTrUuid = (iri: string): string => {
  return iri.split('transport_requests/')[1];
};

export const getTrTitle = (tr: any): string => {
  return getTitleOfItemsAsString(_get(tr, 'item_sets', []));
};

export const isBusinessReturnOrder = (transportRequest: any): boolean => {
  return (
    _get(
      transportRequest,
      'item_sets[0].attributes.' + BusinessAttributes.ORDER_TYPE,
      BusinessAttributesValues.ORDER_TYPE_NORMAL
    ) === BusinessAttributesValues.ORDER_TYPE_RETURN
  );
};

import moment from 'moment-timezone';

export const getTimeSlotSelectOptions = (
  timeFromHours: number,
  timeLimitHours: number,
  stepMinutes: number
): object[] => {
  const options: object[] = [];
  const time = moment().set({ hours: timeFromHours, minutes: 0, seconds: 0 });
  const timeLimit = moment().set({ hours: timeLimitHours, minutes: 0, seconds: 0 });
  while (time.isSameOrBefore(timeLimit)) {
    options.push({ label: time.format('HH:mm'), value: time.format('HH:mm') });
    time.add({ minutes: stepMinutes });
  }
  return options;
};

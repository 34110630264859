import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import {
  FlashMessage,
  Heading,
  IconCircleClock,
  IconCirclePerson,
  IconCircleThumbsUp,
  IconCircleVan,
  InputHidden,
  Row,
  SheetLayoutContent,
  SheetLayoutContentWrapper,
} from '../../../brenger-shared-ui';
import { actions as flowActions, getDeliveryAvailableDate, getPickupAvailableDate, getPriceAmount } from '../ducks';
import { GeneralFlowForms, PackageFields, PackageFieldsValues } from '../interface';
import FooterLayout from './FooterLayout';
import PackageOption, { PackageOptionType } from '../components/PackageOption';
import { priceAsString } from '../../../utils/price';
import { Translate } from 'react-localize-redux';
import PackageModal, { PackageModalProps } from '../components/PackageModal';
import { translate } from '../../../utils/localization';
import { _INSURANCE_AMOUNT } from '../../../utils/global';
import { printDatesAsString } from '../../../utils/datetime';

interface PackageOverviewProps {
  errors: string;
  price: number;
  pickupDate: string[];
  deliveryDate: string[];
}

interface State {
  modalPackage: string;
}

type Props = PackageOverviewProps & InjectedFormProps<{}> & RouteComponentProps<{}>;

type SinglePackage = PackageOptionType & PackageModalProps;

class PackageOverview extends React.Component<Props, State> {
  public constructor(props) {
    super(props);
    this.state = {
      modalPackage: '',
    };
  }

  public componentWillMount(): void {
    this.props.setProgress(4);
  }

  public setModalPackage(event: Event, type: string): void {
    event.preventDefault();
    this.setState({ modalPackage: type });
    this.props.change(PackageFields.chosen, type);
  }

  public render() {
    const dateString = printDatesAsString([...this.props.pickupDate, ...this.props.deliveryDate]);
    const packages: SinglePackage[] = [
      {
        title: String(translate('request_flow.packages.economic.title')),
        subTitle: String(translate('request_flow.packages.economic.sub_title_box')),
        subTitleModal: String(translate('request_flow.packages.economic.sub_title')),
        price: (
          <div>
            <span className="price-value">{priceAsString(this.props.price)}</span>
          </div>
        ),
        labelValue: String(translate('request_flow.packages.economic.label')),
        type: 'green',
        usps: [
          String(translate('request_flow.packages.economic.usp_1')),
          String(translate('request_flow.packages.economic.usp_2')),
          String(translate('request_flow.packages.economic.usp_3', { amount: priceAsString(_INSURANCE_AMOUNT) })),
        ],
        openModal: event => this.setModalPackage(event, PackageFieldsValues.economic),
        isSelected: this.state.modalPackage === PackageFieldsValues.economic,
        modalIsOpen: this.state.modalPackage === PackageFieldsValues.economic,
        services: [
          {
            icon: <IconCircleVan />,
            text: String(translate('request_flow.packages.economic.service_1')),
          },
          {
            icon: <IconCirclePerson />,
            text: String(translate('request_flow.packages.economic.service_2')),
          },
          {
            icon: <IconCircleClock />,
            text: String(translate('request_flow.packages.economic.service_3', { dates: dateString })),
          },
          {
            icon: <IconCircleThumbsUp />,
            text: String(
              translate('request_flow.packages.economic.service_4', { amount: priceAsString(_INSURANCE_AMOUNT) })
            ),
          },
        ],
        cancel: event => this.setModalPackage(event, ''),
      },
      {
        title: String(translate('request_flow.packages.custom.title')),
        subTitle: String(translate('request_flow.packages.custom.sub_title_box')),
        subTitleModal: String(translate('request_flow.packages.custom.sub_title')),
        price: (
          <div>
            <div className={'price-from'}>
              <Translate id={'request_flow.price.from'} />
            </div>
            <span className="price-value">{priceAsString(this.props.price + 500)}</span>
          </div>
        ),
        labelValue: String(translate('request_flow.packages.custom.label')),
        type: 'blue',
        usps: [
          String(translate('request_flow.packages.custom.usp_1')),
          String(translate('request_flow.packages.custom.usp_2')),
          String(translate('request_flow.packages.custom.usp_3')),
        ],
        openModal: event => this.setModalPackage(event, PackageFieldsValues.custom),
        isSelected: this.state.modalPackage === PackageFieldsValues.custom,
        modalIsOpen: this.state.modalPackage === PackageFieldsValues.custom,
        services: [
          {
            icon: <IconCircleVan />,
            text: String(translate('request_flow.packages.custom.service_1')),
          },
          {
            icon: <IconCirclePerson />,
            text: String(translate('request_flow.packages.custom.service_2')),
          },
          {
            icon: <IconCircleClock />,
            text: String(translate('request_flow.packages.custom.service_3', { dates: dateString })),
          },
          {
            icon: <IconCircleThumbsUp />,
            text: String(
              translate('request_flow.packages.custom.service_4', { amount: priceAsString(_INSURANCE_AMOUNT) })
            ),
          },
        ],
        cancel: event => this.setModalPackage(event, ''),
      },
    ];
    return (
      <SheetLayoutContentWrapper>
        <form onSubmit={this.props.handleSubmit}>
          <SheetLayoutContent>
            <Heading extraClasses={'no-margin'} size={4}>
              <Translate id={'request_flow.packages.title'} />
            </Heading>
            <Field name={PackageFields.chosen} component={InputHidden} />
            <Row extraClasses={'package--overview'}>
              {packages.map((item, index) => {
                return (
                  <PackageOption
                    key={index}
                    title={item.title}
                    subTitleModal={item.subTitle}
                    price={item.price}
                    labelValue={item.labelValue}
                    type={item.type}
                    usps={item.usps}
                    openModal={item.openModal}
                    isSelected={item.isSelected}
                  />
                );
              })}
            </Row>
            <div className={'general-errors-wrapper'}>
              {this.props.errors && this.props.errors !== '' && (
                <FlashMessage type={'error'} message={this.props.errors} />
              )}
            </div>
          </SheetLayoutContent>
          {packages.map((item, index) => {
            return (
              <PackageModal
                key={index}
                title={item.title}
                subTitle={item.subTitleModal}
                services={item.services}
                modalIsOpen={item.modalIsOpen}
                cancel={item.cancel}
              />
            );
          })}
          <FooterLayout />
        </form>
      </SheetLayoutContentWrapper>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  errors: state.generalTransport.errors,
  price: getPriceAmount(state),
  pickupDate: getPickupAvailableDate(state),
  deliveryDate: getDeliveryAvailableDate(state),
});

const mapDispatchToProps = dispatch => ({
  setProgress: step => dispatch(flowActions.setProgress(step)),
});

export default reduxForm<{}, Props>({
  form: GeneralFlowForms.packages,
  destroyOnUnmount: false,
  forceUnregisterOnUmount: true,
  onSubmit: (formResults, dispatch) => dispatch(flowActions.submitPackages(formResults)),
})(connect(mapStateToProps, mapDispatchToProps)(PackageOverview));

import React from 'react';
import { IconCross, IconInfo, IconMarker, IconSmile } from '../../basics/icons';
import './locationautocomplete.css';
import '../input/input.css';
import InputWrapper from '../input/InputWrapper';
import InputFeedback from '../input/InputFeedback';
import { InputProps } from '../input/Input';
import _get from 'lodash/get';

interface custom {
  autocompleteState: {
    error: string;
    info: string;
    isFetching: boolean;
    shouldFetch: boolean;
    results: any[];
    selected: boolean;
  };
}

type LocationAutocompleteProps = custom & InputProps;

class LocationAutocomplete extends React.Component<LocationAutocompleteProps, any> {
  public componentDidMount(): void {
    const inputEl = document.querySelector('.' + this.props.input.name + ' input');

    if (!inputEl) {
      return;
    }

    inputEl.addEventListener('keydown', (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowUp':
          this.selectNextOption(false);
          e.preventDefault();
          break;

        case 'ArrowDown':
          this.selectNextOption();
          e.preventDefault();
          break;

        case 'Enter':
          this.selectHighlightedOption();
          e.preventDefault();
          break;

        case 'Tab':
          this.selectHighlightedOption();
          break;

        default:
          return;
      }
    });
  }

  public selectNextOption(down: boolean = true): void {
    const highlightedClass = 'highlighted';
    const inputSelector = document.querySelector('.' + this.props.input.name);
    if (!inputSelector) {
      return;
    }
    const highlightedOption = inputSelector.querySelector('.autocomplete-list--item.' + highlightedClass);
    let newHighlight = down
      ? inputSelector.querySelector('.autocomplete-list--item:first-child')
      : inputSelector.querySelector('.autocomplete-list--item:last-child');
    if (highlightedOption) {
      highlightedOption.classList.remove(highlightedClass);
      newHighlight = down ? highlightedOption.nextElementSibling : highlightedOption.previousElementSibling;
    }
    if (!newHighlight) {
      newHighlight = down
        ? inputSelector.querySelector('.autocomplete-list--item:first-child')
        : inputSelector.querySelector('.autocomplete-list--item:last-child');
    }
    if (newHighlight) {
      newHighlight.classList.add(highlightedClass);
      this.onSelect(newHighlight.getAttribute('data-key'), true);
    }
  }

  public selectHighlightedOption() {
    const highlightedOption =
      document.querySelector('.' + this.props.input.name + ' .autocomplete-list--item.highlighted') ||
      document.querySelector('.' + this.props.input.name + ' .autocomplete-list--item');
    if (highlightedOption) {
      this.onSelect(highlightedOption.getAttribute('data-key'), false);
    }
  }

  public onSelect(index, shouldStayOpen) {
    this.props.locationAutocompleteSelect({
      meta: {
        form: this.props.meta.form,
        field: this.props.input.name,
        isOpen: shouldStayOpen,
      },
      payload: this.props.autocompleteState.results[index],
    });
  }

  public render() {
    const isOpen = this.props.autocompleteState.isOpen && _get(this.props.autocompleteState, 'results', []).length > 0;
    return (
      <InputWrapper
        label={this.props.label}
        input={...this.props.input}
        meta={...this.props.meta}
        type={
          'autocomplete ' +
          this.props.input.name +
          (this.props.autocompleteState.isFetching ? ' input-el--autocomplete--is-loading' : '')
        }
        customIsValid={this.props.autocompleteState.selected !== false}
      >
        <label htmlFor={this.props.input.name}>
          <div className={'input-el--autocomplete--icon-wrapper'}>
            <IconSmile spinning={true} />
            <IconMarker />
          </div>
        </label>
        <label
          htmlFor={this.props.input.name}
          className={'input-el--autocomplete--clear'}
          onClick={() => this.props.input.onChange('')}
        >
          <IconCross />
        </label>
        <input
          className={'data-hj-whitelist'}
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          id={this.props.input.name}
          {...this.props.input}
        />
        <label
          className={
            'input-el--label' +
            (typeof this.props.input.required !== 'undefined' && this.props.input.required === true
              ? ' input-el--label--required'
              : '')
          }
          htmlFor={this.props.input.name}
        >
          {this.props.label}
        </label>
        {isOpen && (
          <div className={'autocomplete-list'}>
            {this.props.autocompleteState.results.map((item, index) => {
              return (
                <div
                  className={'autocomplete-list--item'}
                  onClick={event => this.onSelect(index, false)}
                  data-key={index}
                  key={index}
                >
                  {item.label}
                </div>
              );
            })}
          </div>
        )}
        {this.props.meta.active && this.props.autocompleteState.info && (
          <div className={'input-el--autocomplete--hints'}>
            <IconInfo /> {this.props.autocompleteState.info}
          </div>
        )}
        <InputFeedback
          meta={...this.props.meta}
          error={this.props.meta.error || this.props.autocompleteState.error}
          successMessage={this.props.successMessage}
        />
      </InputWrapper>
    );
  }
}

export default LocationAutocomplete;

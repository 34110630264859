/*
/* BRENGER PRICING UTILS
/* ================================================== */

import _get from 'lodash/get';

export const IDEAL_ISSUERS_LIST = [
  'ideal_ABNANL2A',
  'ideal_RABONL2U',
  'ideal_INGBNL2A',
  'ideal_SNSBNL2A',
  'ideal_KNABNL2H',
  'ideal_BUNQNL2A',
  'ideal_TRIONL2U',
  'ideal_ASNBNL21',
];

export const toEuro = (cent: number) => {
  return cent / 100;
};

export const toEuroString = (cent: number) => {
  return `\u20ac ${cent / 100}`;
};

export const sortIssuers = (methods, mollieId, orders) => {
  const index = methods.findIndex(x => x.mollie_id === mollieId);
  orders.map((item, i) => {
    if (_get(methods[index], 'issuers', false)) {
      const issuerIndex = methods[index].issuers.findIndex(y => y.mollie_id === item);
      if (issuerIndex > -1) {
        methods[index].issuers.splice(i, 0, methods[index].issuers.splice(issuerIndex, 1)[0]);
      }
    }
  });
  return methods;
};

import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { Delivery, User, VavatoFlowForms } from '../interface';
import {
  Button,
  Col,
  Container,
  FlashMessage,
  Heading,
  IconArrow,
  Input,
  InputHidden,
  PriceDisplay,
  PrintDatetimePeriods,
  Row,
  SheetLayoutContent,
  SheetLayoutContentWrapper,
  SheetLayoutFooter,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { translate } from '../../../utils/localization';
import { connect } from 'react-redux';
import { actions as vavatoActions, getAuctionDeliveryDatetimePeriods, getDeliveryDatetimePeriods } from '../ducks';
import { deliverySchema, userSchema } from '../schema';
import { Translate } from 'react-localize-redux';
import '../../../assets/BVA/delivery-details.css';
import _get from 'lodash/get';
import { _GENERAL_CONDITION_LINK, _PRIVACY_STATEMENT_LINK, _TRANSPORT_CONDITION_LINK } from '../../../utils/global';

type Props = InjectedFormProps<{}> & RouteComponentProps<{}>;

class DeliveryForm extends React.Component<InjectedFormProps<{}> & Props> {
  public render() {
    const customDeliveryDate = _get(this.props.deliveryForm, 'values.custom_delivery_date', false);
    const customDeliveryDateValue = _get(this.props.deliveryForm, 'values.custom_delivery_date_value', false);
    return (
      <form onSubmit={this.props.handleSubmit}>
        <SheetLayoutContentWrapper>
          <SheetLayoutHeader>
            <Heading size={2}>
              <Translate id={'request_flow.headers.delivery'} />
            </Heading>
          </SheetLayoutHeader>
          <SheetLayoutContent>
            <Row>
              <Col xs={12}>
                <Heading size={5}>
                  <Translate
                    id={
                      'request_flow.delivery.' +
                      (customDeliveryDate && !customDeliveryDateValue ? 'choose_delivery_date' : 'date')
                    }
                  />
                </Heading>
                {(!customDeliveryDate || (customDeliveryDate && customDeliveryDateValue)) && (
                  <>
                    {PrintDatetimePeriods(this.props.availableDatetimePeriods)}
                    <div className={'time-slot-explain'}>
                      <Translate id={'request_flow.delivery.explain'} />
                    </div>
                  </>
                )}
                <>
                  <Translate
                    id={'request_flow.delivery.alternative_day_with_link'}
                    data={{ link: <a href="https://www.brenger.nl">www.brenger.nl</a> }}
                  />
                </>
              </Col>
              <Field name={'custom_delivery_date'} component={InputHidden} />
            </Row>
            <Row extraClasses={'pt-1'}>
              <Col xs={12} extraClasses={'pt-1 pb-0-5'}>
                <Heading size={5}>
                  <Translate id={'request_flow.delivery.details'} />
                </Heading>
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  validate={userSchema[User.first_name]}
                  name={'contact.first_name'}
                  type="text"
                  component={Input}
                  label={translate('form.fields.user.first_name.label')}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  validate={userSchema[User.last_name]}
                  name={'contact.last_name'}
                  type="text"
                  component={Input}
                  label={translate('form.fields.user.last_name.label')}
                />
              </Col>
              <Col xs={12}>
                <Field
                  validate={deliverySchema[Delivery.line1]}
                  name={'address.line1'}
                  type="text"
                  component={Input}
                  label={translate('form.fields.address.line1.label')}
                />
              </Col>
              <Col xs={12} sm={4}>
                <Field
                  validate={deliverySchema[Delivery.postal_code]}
                  name={'address.postal_code'}
                  type="text"
                  component={Input}
                  label={translate('form.fields.address.postal_code.label')}
                />
              </Col>
              <Col xs={12} sm={8}>
                <Field
                  validate={deliverySchema[Delivery.locality]}
                  name={'address.locality'}
                  type="text"
                  component={Input}
                  label={translate('form.fields.address.locality.label')}
                />
                <Field name={'address.country'} component={InputHidden} />
                <Field name={'address.lat'} component={InputHidden} />
                <Field name={'address.lng'} component={InputHidden} />
              </Col>
              <Col xs={12}>
                <Field
                  name={'address.instructions'}
                  type="text"
                  component={Input}
                  label={translate('form.fields.address.instructions.label')}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  validate={userSchema[User.phone]}
                  name={'contact.phone'}
                  type="tel"
                  component={Input}
                  label={translate('form.fields.user.phone.label')}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  validate={userSchema[User.email]}
                  name={'contact.email'}
                  type="text"
                  component={Input}
                  label={translate('form.fields.user.email.label')}
                />
              </Col>
            </Row>
            <Row extraClasses={'order--agree-terms'}>
              <Col xs={12} extraClasses={'pt-1'}>
                <Field
                  name={'general_conditions'}
                  type={'checkbox'}
                  validate={deliverySchema[Delivery.conditions]}
                  label={translate('request_flow.delivery.agree_general_terms', {
                    condition_link: (
                      <a href={_GENERAL_CONDITION_LINK} target={'_blank'}>
                        <Translate id={'request_flow.delivery.general_terms'} />
                      </a>
                    ),
                  })}
                  component={Input}
                />
                <Field
                  name={'privacy_statement'}
                  type={'checkbox'}
                  validate={deliverySchema[Delivery.conditions]}
                  label={
                    <>
                      <Translate
                        id={'request_flow.delivery.agree_privacy_statement'}
                        data={{
                          privacy_link: (
                            <a href={_PRIVACY_STATEMENT_LINK} target={'_blank'}>
                              <Translate id={'request_flow.delivery.privacy_statement'} />
                            </a>
                          ),
                        }}
                      />
                      <br />
                      <small style={{ paddingTop: '1em' }}>
                        <Translate
                          id={'request_flow.delivery.agree_transport_condition'}
                          data={{
                            condition_link: (
                              <a href={_TRANSPORT_CONDITION_LINK} target={'_blank'}>
                                <Translate id={'request_flow.delivery.transport_condition'} />
                              </a>
                            ),
                          }}
                        />
                      </small>
                    </>
                  }
                  component={Input}
                />
              </Col>
            </Row>
            <div className={'add-lot-general-errors-wrapper'}>
              {this.props.generalErrors && this.props.generalErrors !== '' && (
                <FlashMessage type={'error'} message={this.props.generalErrors} />
              )}
            </div>

            <div className={'add-lot-general-warnings-wrapper'}>
              {this.props.warnings && this.props.warnings !== '' && (
                <FlashMessage type={'info'} message={this.props.warnings} />
              )}
            </div>
          </SheetLayoutContent>
        </SheetLayoutContentWrapper>
        <SheetLayoutFooter>
          <Container>
            <Row>
              <Col xs={5}>
                <PriceDisplay header={<Translate id={'request_flow.price.total'} />} cents={this.props.price} />
              </Col>
              <Col xs={7}>
                <Button
                  loading={this.props.transport.request.submitting}
                  disabled={this.props.transport.request.submitting}
                  buttonStyle={'secondary'}
                  iconPos={'right'}
                  fullWidth={true}
                >
                  <Translate id={'onboarding.step.next'} />
                  <IconArrow />
                </Button>
              </Col>
            </Row>
          </Container>
        </SheetLayoutFooter>
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  auctionForm: state.form[VavatoFlowForms.auctionLotForm],
  deliveryForm: state.form[VavatoFlowForms.deliveryForm],
  transport: state.vavatoTransport,
  availableDatetimePeriods: getDeliveryDatetimePeriods(state),
  auctionDatetimePeriods: getAuctionDeliveryDatetimePeriods(state),
  generalErrors: state.vavatoTransport.request.errors,
  warnings: state.vavatoTransport.request.warnings,
  price: state.vavatoTransport.request.price,
});

const mapDispatchToProps = dispatch => ({});

export default reduxForm<{}, Props>({
  form: VavatoFlowForms.deliveryForm,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  initialValues: {
    custom_delivery_date: false,
  },
  onSubmit: (delivery, dispatch) => dispatch(vavatoActions.submitDeliveryDetails(delivery)),
})(connect(mapStateToProps, mapDispatchToProps)(DeliveryForm));

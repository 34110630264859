import * as React from 'react';
import './stepindicator.css';

interface Props {
  currentStep: number;
  children: any;
}

export const StepIndicator = ({ currentStep, children }: Props) => {
  return (
    <div className={'step-indicator step-indicator--step-' + currentStep}>
      <div className={'step-indicator--circle'}>{currentStep}</div>
      <div className={'step-indicator--title'}>{children}</div>
    </div>
  );
};
export default StepIndicator;

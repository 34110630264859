import * as React from 'react';
import './icons.css';

const IconCross = () => {
  return (
    <svg className="IconCross" width="20" height="20" viewBox="0 0 27.85 27.83" version="1.1">
      <path d="M16.53,13.93l10.8-10.8a1.87,1.87,0,0,0,0-2.6,1.87,1.87,0,0,0-2.6,0l-10.8,10.8L3.13.52a1.87,1.87,0,0,0-2.6,0,1.87,1.87,0,0,0,0,2.6l10.8,10.8L.53,24.73a1.87,1.87,0,0,0,0,2.6,1.66,1.66,0,0,0,1.3.5,1.9,1.9,0,0,0,1.3-.5l10.8-10.8,10.8,10.8a1.66,1.66,0,0,0,1.3.5,1.9,1.9,0,0,0,1.3-.5,1.87,1.87,0,0,0,0-2.6Z" />
    </svg>
  );
};

export default IconCross;

import React from 'react';
import { translate } from '../../../utils/localization';
import { userSchema } from '../schema';
import { User } from '../interface';
import { Field } from 'redux-form';
import { Col, Heading, Input, Row, SheetLayoutContent } from '../../../brenger-shared-ui';
import { Translate } from 'react-localize-redux';

export const ContactFields: React.FC = () => {
  return (
    <SheetLayoutContent>
      <Row extraClasses={'pt-1'}>
        <Col xs={12} extraClasses={'pt-1 pb-0-5'}>
          <Heading size={5}>
            <Translate id={'request_flow.IKEA.delivery.your_details'} />
          </Heading>
        </Col>
        <Col xs={12} sm={6}>
          <Field
            validate={userSchema[User.first_name]}
            name={'contact.first_name'}
            type="text"
            component={Input}
            label={translate('form.fields.user.first_name.label')}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Field
            validate={userSchema[User.last_name]}
            name={'contact.last_name'}
            type="text"
            component={Input}
            label={translate('form.fields.user.last_name.label')}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Field
            validate={userSchema[User.phone]}
            name={'contact.phone'}
            type="tel"
            component={Input}
            label={translate('form.fields.user.phone.label')}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Field
            validate={userSchema[User.email]}
            name={'contact.email'}
            type="text"
            component={Input}
            label={translate('form.fields.user.email.label')}
          />
        </Col>
      </Row>
    </SheetLayoutContent>
  );
};

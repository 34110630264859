import React from 'react';
import { connect } from 'react-redux';
import '../../../assets/payment/payment-details.css';
import '../../../assets/BVA/thank-you.css';
import { Col } from '../../../brenger-shared-ui';
import { push } from 'connected-react-router';
import PaymentSuccess from '../components/PaymentSuccess';
import { actions as flowActions, isGuaranteed } from '../ducks';
import { getIsAllowedToPayByInvoice } from '../../User/ducks';
import { RootState } from '../../../typings';

type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export type Props = ReduxProps;

export const _NOT_GUARANTEED_THANK_YOU = 'thank_you_not_guaranteed';

const NotGuaranteedThankYou: React.FC<Props> = props => {
  React.useEffect(() => {
    props.setProgress(_NOT_GUARANTEED_THANK_YOU);
  }, []);

  return (
    <Col xs={12}>
      <PaymentSuccess
        user={props.user}
        trackPage={props.trackPage}
        isGuaranteed={props.isGuaranteed}
        uuid={props.uuid}
        payByInvoice={props.payByInvoice}
        createAccount={props.createAccount}
        customTransKey={'thank_you_not_guaranteed'}
      />
    </Col>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.user,
  uuid: state.generalTransport.request.uuid,
  isGuaranteed: isGuaranteed(state),
  payByInvoice: getIsAllowedToPayByInvoice(state),
});

const mapDispatchToProps = dispatch => ({
  setProgress: (step: string) => dispatch(flowActions.setProgress(step)),
  createAccount: (uuid: string) => dispatch(push(`/register?transport_request=${uuid}`)),
  trackPage: (uuid: string) => dispatch(push('/track_and_trace/' + uuid)),
  goToTransportStatus: (uuid: string) => dispatch(push('/transport_request/track/' + uuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotGuaranteedThankYou);

export const hotjar = {
  addTag: key => {
    if (typeof window.hj === 'function') {
      window.hj('tagRecording', key);
    }
  },
  addForm: key => {
    if (typeof window.hj === 'function') {
      window.hj('trigger', key);
    }
  },
};

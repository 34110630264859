import { pushToDataLayer } from './basics';

const UUID_SESSION_KEY = 'uuid';

export interface IEventData {
  eventCategory: string;
  eventAction: string;
  eventLabel?: string;
  eventValue?: number;
}

export const trackEvent = (eventData: IEventData): void => {
  pushToDataLayer({
    event: 'trackEvent',
    ...eventData,
  });
};

// https://stackoverflow.com/questions/105034/how-to-create-guid-uuid
/* tslint:disable */
const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
/* tslint:enable */

// Retrieves a client-generated uuid from session storage and if it does not
// exist, creates one for the current session.
const retrieveSessionId = () => {
  return window.sessionStorage.getItem(UUID_SESSION_KEY);
};

// Returns a list of the GA clientIds. Wrapped in a try/catch for graceful error handling in
// cases where getAll may not be a function.
const retrieveClientIdFromGa = (): string | undefined => {
  try {
    const [tracker] = window.ga?.getAll();
    return tracker?.get('clientId');
  } catch (err) {
    return undefined;
  }
};

const createSessionId = () => {
  const sessionId = uuidv4();
  window.sessionStorage.setItem(UUID_SESSION_KEY, sessionId);
  return sessionId;
};

// Finds and returns all session ids for this client.
export const getSessionIds = () => {
  const sessionIds: string[] = [];
  // Add any GA session IDs on the client
  const gaClientId = retrieveClientIdFromGa();
  if (gaClientId) {
    sessionIds.push(gaClientId);
  }
  // Add any existing session IDs (the ones we created)
  const existingSessionId = retrieveSessionId();
  if (existingSessionId) {
    sessionIds.push(existingSessionId);
  }
  // If cannot find any session IDs laying around for this client, create one.
  if (!sessionIds.length) {
    const newSessionId = createSessionId();
    sessionIds.push(newSessionId);
  }
  return sessionIds;
};

import { DatetimePeriod, GeoPoint } from '../../typings';

// INTERFACES & PROP TYPES
export interface BvaTransport {
  request: {
    errors: string | null;
    warnings: string;
    price: Price;
    step: 1;
    submitting: false;
    uuid: string | null;
  };
}

export interface Polyline {
  distance: number | null;
  duration: number | null;
  points: GeoPoint[] | null;
}

export enum Lot {
  id = 'id',
  number = 'number',
  invoice = 'invoice',
  title = 'title',
  image = 'image',
  description = 'description',
}

export enum User {
  first_name = 'first_name',
  last_name = 'last_name',
  email = 'email',
  phone = 'phone',
}

export enum Delivery {
  line1 = 'line1',
  line2 = 'line2',
  postal_code = 'postal_code',
  instructions = 'instructions',
  locality = 'locality',
  country_code = 'country_code',
  latitude = 'latitude',
  longitude = 'longitude',
  custom_delivery_date = 'custom_delivery_date',
  conditions = 'conditions',
}

export enum Price {
  AMOUNT = 4900,
  EXTRA_RATE = 4000,
  CURRENCY = 'EUR',
}

export interface UserInterface {
  first_name: string;
  last_name: string;
  email: string;
  phone: number;
}

export interface StepInterface {
  step: number;
  total: number;
  goBack: any;
  pickup: GeoPoint;
  delivery: GeoPoint;
}

export interface DeliveryInterface {
  contact: object;
  address: object;
  details: object;
  available_datetime_periods: DatetimePeriod[];
}

export enum BvaFlowForms {
  auctionLotForm = '@@BvaFlow/auction_lot_form',
  deliveryForm = '@@BvaFlow/delivery_form',
}

import React from 'react';
import { Translate } from 'react-localize-redux';
import classNames from 'classnames';

import { useTypedSelector } from '../../../../hooks';
import { useNotification } from '../../../../hooks/useNotification';
import { CirclePerson2, Toast } from '../../../../brenger-shared-ui';
import { getQuoteItems } from '../../ducks';

export const ExtraCareNotification: React.FC = () => {
  const notification = useNotification('extra-care--scooter');
  const progressStep = useTypedSelector(state => state.generalTransport.layout.step);
  const quoteItems = useTypedSelector(getQuoteItems);
  const hasExtraCareScooter = quoteItems.some(quoteItem => {
    return quoteItem.extra_care?.match === 'scooter';
  });

  React.useEffect(() => {
    if (progressStep === 3 && hasExtraCareScooter) {
      notification.add();
    } else {
      notification.remove();
    }
  }, [progressStep, hasExtraCareScooter]);

  return (
    <div className={classNames('toast-container', { 'toast--visible': notification.isVisible })}>
      <Toast prefix={<CirclePerson2 />} handleClose={notification.removeForOneSession}>
        <div className="pt-0-5" style={{ paddingRight: '15px' }}>
          <b>
            <Translate id="request_flow.extra_care.scooter.notification.title" />
          </b>
        </div>
        <div>
          <Translate id="request_flow.extra_care.scooter.notification.text" />
        </div>
      </Toast>
    </div>
  );
};

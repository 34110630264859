import React from 'react';
import { Field } from 'redux-form';
import { PersonSchema } from '../schema';
import { UserProperties } from '../interface';
import { Input } from '../../../brenger-shared-ui';
import { translate } from '../../../utils/localization';
import { _GENERAL_CONDITION_LINK } from '../../../utils/global';
import { Translate } from 'react-localize-redux';

export const PersonalDetails = (): any => {
  return (
    <>
      <Field
        validate={PersonSchema[UserProperties.first_name]}
        component={Input}
        type="text"
        name={'user.first_name'}
        label={translate('form.fields.user.first_name.label')}
      />
      <Field
        validate={PersonSchema[UserProperties.last_name]}
        component={Input}
        type="text"
        name={'user.last_name'}
        label={translate('form.fields.user.last_name.label')}
      />
      <Field
        validate={PersonSchema[UserProperties.email]}
        component={Input}
        type="email"
        name={'user.email'}
        label={translate('form.fields.user.email.label')}
      />
      <Field
        validate={PersonSchema[UserProperties.password]}
        component={Input}
        type="password"
        name={'user.new_password'}
        label={translate('form.fields.user.password.label')}
      />
      <Field
        validate={PersonSchema[UserProperties.phone]}
        component={Input}
        type="number"
        name={'user.phone'}
        label={translate('form.fields.user.phone.label')}
      />
      <Field
        name={'user.termsofuse'}
        type={'checkbox'}
        validate={PersonSchema[UserProperties.termsofuse]}
        label={translate('request_flow.delivery.agree_general_terms', {
          condition_link: (
            <a href={_GENERAL_CONDITION_LINK} target={'_blank'}>
              <Translate id={'request_flow.delivery.general_terms'} />
            </a>
          ),
        })}
        component={Input}
      />
    </>
  );
};

export default PersonalDetails;

import React from 'react';
import { Input, InputHidden, Select } from '../../../brenger-shared-ui';
import { translate } from '../../../utils/localization';
import { CompanySchema } from '../schema';
import { Company } from '../interface';
import { Field } from 'redux-form';
import { normalizePostalCode } from '../../../utils/normalisation';

export const CompanyDetails = (props): any => {
  const countries: any = [];
  if (props.countries) {
    props.countries.map(country => {
      if (country.code === 'NL' || country.code === 'BE') {
        country.label = country.code;
        country.value = country['@id'];
        countries.push(country);
      }
    });
  }
  return (
    <>
      <Field
        component={Input}
        type="text"
        name={'user.account.name'}
        label={translate('form.fields.account.account_name.label')}
        validate={CompanySchema[Company.name]}
      />
      <Field
        validate={CompanySchema[Company.line1]}
        name={`user.account.address.line1`}
        type="text"
        component={Input}
        label={translate('form.fields.address.line1.label')}
      />
      <Field
        validate={CompanySchema[Company.postal_code]}
        name={`user.account.address.postal_code`}
        type="text"
        component={Input}
        normalize={normalizePostalCode}
        label={translate('form.fields.address.postal_code.label')}
      />
      <Field
        validate={CompanySchema[Company.locality]}
        name={`user.account.address.locality`}
        type="text"
        component={Input}
        label={translate('form.fields.address.locality.label')}
      />
      <Field
        validate={CompanySchema[Company.country]}
        name={`user.account.address.country`}
        type="text"
        component={Select}
        options={countries}
        label={translate('form.fields.address.country.label')}
      />
      <Field name={`user.account.address.country_code`} type="text" component={InputHidden} />
    </>
  );
};

export default CompanyDetails;

import * as React from 'react';
import classNames from 'classnames';
import { InputProps } from '../../../brenger-shared-ui/components/form/input/Input';
import { useTypedSelector } from '../../../hooks';
import { getInstantBookingOption } from '../ducks';
import { priceAsString } from '../../../utils/price';
import { Translate } from 'react-localize-redux';

export const InstantBookingOption: React.FC<InputProps> = ({ input }) => {
  const option = useTypedSelector(getInstantBookingOption);
  const priceChange = option?.price_change?.incl_vat.amount || 0;
  return (
    <div className="input-el input-el--tile input-el--no-label input-el--tile-compact">
      <div
        className={classNames('trigger', 'input-el--tile--option', 'delay-index-0', 'brenger-instant', {
          'brenger-isntant--selected': input.value,
        })}
        onClick={() => input.onChange(true)}
      >
        <div className="input-el--tile--copy">
          <div className="input-el--tile--title">
            <Translate id={'request_flow.date.instant_booking.title'} />
          </div>
          <div className="input-el--tile--description">
            <Translate id={'request_flow.date.instant_booking.description'} />
          </div>
        </div>
        <div className="input-el--tile--suffix">
          <span className={'text--' + option?.label}>+ {priceAsString(priceChange)}</span>
        </div>
        <input {...input} type={'hidden'} />
      </div>
    </div>
  );
};

import React from 'react';
import { Field, FieldArray, getFormValues } from 'redux-form';
import _get from 'lodash/get';
import { Translate } from 'react-localize-redux';

import { auctionSchema } from '../../../BvaFlow/schema';
import { Lot } from '../../../BvaFlow/interface';
import { translate } from '../../../../utils/localization';
import RenderLotItemsField from './RenderLotItemsField';
import { Button, CardWithImage, Col, FlashMessage, Input, InputHidden, Row } from '../../../../brenger-shared-ui';
import { useTypedSelector } from '../../../../hooks';
import { GeneralFlowForms } from '../../interface';

import '../../../../assets/BVA/renderlot.css';
import '../../../../assets/auctions/auctions.css';

// @TODO - make this interface complete when we have @types/redux-form
interface Props {
  generalErrors: any;
  form: any;
  fields: any;
  meta: any;
}

const RenderLotField: React.FC<Props> = ({ generalErrors, form, fields, meta: { error, submitFailed } }) => {
  const pickupForm: any = useTypedSelector(getFormValues(GeneralFlowForms.pickup));
  // Check if the pickup form already includes a valid lot. This is deduced by looking for an id.
  const hasValidLot = pickupForm?.lots?.some((lot: { id: string }) => lot.id);

  React.useEffect(() => {
    // If there are no valid lots in the form yet, add an empty one on mount so that
    // the user can immediately begin filling it out.
    if (!pickupForm?.lots?.length) {
      fields.push({ number: '' });
    }
  }, [hasValidLot]);

  return (
    <div className="pb-0-5">
      {/* Show label for how to add lot when none has been added yet. */}
      {/* After one has been added, the "add more lots" button will be sufficient. */}
      {!hasValidLot && (
        <div className="pb-0-5">
          <Translate id="request_flow.auction.lot_add" />
        </div>
      )}
      {fields.map((lot: any, index: number) => (
        <div key={index} className={'render-lot'}>
          {form.values?.lots[index]?.title && (
            <>
              <CardWithImage
                header={form.values.lots[index].title}
                details={
                  <div>
                    <div>
                      <Translate id="request_flow.auction.lot_number" />: {form.values.lots[index].number}
                    </div>
                  </div>
                }
                image={form.values.lots[index].image}
              />
              <span
                className={'btn btn--grey btn--size-sm render-lot--delete-card'}
                onClick={() => {
                  fields.remove(index);
                }}
              >
                <Translate id={'request_flow.auction.lot_remove'} />
              </span>
              <Field
                validate={auctionSchema[Lot.number]}
                name={`${lot}.number`}
                type="text"
                component={InputHidden}
                label={`Lot #${index + 1}`}
              />
              <Field name={`${lot}.id`} type="text" component={InputHidden} />
              <Field
                validate={auctionSchema[Lot.image]}
                name={`${lot}.image`}
                type="text"
                component={InputHidden}
                label={`Lot #${index + 1}`}
              />
              {/* itemSet fields */}
              <FieldArray name={`lots[${index}].items`} component={RenderLotItemsField} />
            </>
          )}
          <div className={'add-lot-actions'}>
            {!form.values?.lots[index]?.title && (
              <Row>
                <Col xs={12} md={5}>
                  <Field
                    validate={auctionSchema[Lot.number]}
                    name={`${lot}.number`}
                    type="text"
                    normalize={(value: string) => value.replace(/\s/g, '')}
                    component={Input}
                    label={`${translate('request_flow.auction.lot_number')} #${index + 1}`}
                  />
                  <Field name={`${lot}.id`} type="text" component={InputHidden} />
                </Col>
                <Col xs={12} md={7} extraClasses={'add-lot-action'}>
                  <Button type={'button'} buttonStyle="primary">
                    <Translate id={'request_flow.auction.add'} />
                  </Button>
                  <Button
                    buttonStyle="grey"
                    onClick={() => {
                      fields.remove(index);
                    }}
                  >
                    <Translate id={'request_flow.auction.lot_remove'} />
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        </div>
      ))}
      <div key="general-errors" className={'add-lot-general-errors-wrapper'}>
        {submitFailed && error && <FlashMessage type={'error'} message={error} />}
        {generalErrors && generalErrors !== '' && <FlashMessage type={'error'} message={generalErrors} />}
      </div>
      {/* Show the "add another lot" button ONLY IF there is already one valid lot in the form. */}
      {hasValidLot && (
        <span key="add-another-lot" className={'btn btn--outline'} onClick={() => fields.push({ number: '' })}>
          + <Translate id={'request_flow.auction.lot_add'} />
        </span>
      )}
    </div>
  );
};

export default RenderLotField;

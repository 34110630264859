import {
  atLeastOneLetter,
  atLeastOneNumberRegex,
  email,
  exactLength,
  maxLength,
  minLength,
  numeric,
  numericMin,
  required,
  testRegex,
} from '../../utils/validation';
import { translate } from '../../utils/localization';
import _get from 'lodash/get';

export const pickupSchema = {
  location: value => required(value, translate('request_flow.fields.select_store.valid')),
  warehouse: value => required(value, translate('request_flow.IKEA.fields.warehouse.valid')),
  receipt: value => required(value, translate('request_flow.IKEA.fields.upload_receipt.valid')),
  invoice: value => required(value, translate('request_flow.IKEA.fields.upload_invoice.valid')),
  image: value => required(value, translate('request_flow.IKEA.fields.upload_image.valid')),
  date: value => required(value, translate('request_flow.IKEA.fields.upload_image.valid')),
  isell: value => exactLength(value, 10),
  count: value =>
    numericMin(
      value,
      1,
      translate('form.fields.default.required', { field: translate('request_flow.IKEA.fields.count.label') })
    ),
  weight: value =>
    numericMin(
      value,
      1,
      translate('form.fields.default.required', { field: translate('request_flow.IKEA.fields.weight.label') })
    ),
  collies: value =>
    numericMin(
      value,
      1,
      translate('form.fields.default.required', { field: translate('request_flow.IKEA.fields.collies.label') })
    ),
};

export const userSchema = {
  first_name: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('form.fields.user.first_name.label') })
    ),
  last_name: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('form.fields.user.last_name.label') })
    ),
  phone: [
    value =>
      required(value, translate('form.fields.default.required', { field: translate('form.fields.user.phone.label') })),
    value => minLength(value, 10, translate('form.fields.user.phone.valid')),
    value => maxLength(value, 14, translate('form.fields.user.phone.valid')),
    value => numeric(value, translate('form.fields.user.phone.valid')),
  ],
  email: [
    value =>
      required(value, translate('form.fields.default.required', { field: translate('form.fields.user.email.label') })),
    value => email(value, translate('form.fields.user.email.valid')),
  ],
};

export const deliverySchema = {
  line1: [
    value =>
      required(
        value,
        translate('form.fields.default.required', { field: translate('form.fields.address.line1.label') })
      ),
    (value, allValues) => {
      const countryCode = _get(allValues, 'address.country_code', '');
      if (countryCode === 'NL') {
        return testRegex(value, atLeastOneNumberRegex, translate('form.fields.address.line1.valid_with_number'));
      }
    },
    (value, allValues) => {
      const countryCode = _get(allValues, 'address.country_code', '');
      if (countryCode === 'NL') {
        return testRegex(value, atLeastOneLetter, translate('form.fields.address.line1.valid'));
      }
    },
  ],
  postal_code: [
    value =>
      required(
        value,
        translate('form.fields.default.required', { field: translate('form.fields.address.postal_code.label') })
      ),
  ],
  locality: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('form.fields.address.locality.label') })
    ),
  custom_delivery_date: value =>
    required(value, translate('form.fields.default.required', { field: translate('form.fields.datetime.date.label') })),
  conditions: [value => required(value, translate('request_flow.payment.agree_terms_required'))],
};

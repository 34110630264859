import * as React from 'react';
import './icons.css';

const IconShop = () => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 117.7 84">
      <g>
        <path d="M57,37.3c-1.1,0-2,0.9-2,2V68c0,1.1,0.9,2,2,2h40.3c1.1,0,2-0.9,2-2V39.3c0-1.1-0.9-2-2-2H57z M95.3,66H59V41.3h36.3V66z" />
        <path d="M42.9,59.2h-5.5c-1.1,0-2,0.9-2,2s0.9,2,2,2h5.5c1.1,0,2-0.9,2-2S44,59.2,42.9,59.2z" />
        <path
          d="M0,14.9c0,6,4.2,11.1,9.8,12.5V43c0,1.1,0.9,2,2,2s2-0.9,2-2V27.8c3.1-0.2,6.1-1.5,8.3-3.7c2.4,2.3,5.6,3.7,9.1,3.7
		c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c3.4,0,6.7-1.4,9.1-3.7c2.4,2.3,5.6,3.7,9.1,3.7c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
		c3.4,0,6.7-1.4,9.1-3.7c2.4,2.3,5.6,3.7,9.1,3.7c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c3.4,0,6.7-1.4,9.1-3.7c2.4,2.3,5.6,3.7,9.1,3.7
		c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c3.4,0,6.7-1.4,9.1-3.7c2.3,2.2,5.3,3.5,8.4,3.7V43c0,1.1,0.9,2,2,2s2-0.9,2-2V27.4
		c5.2-1.4,9.2-6,9.6-11.6c0.5-0.6,0.5-1.5,0.1-2.3l-8.1-12.6c-0.4-0.6-1-0.9-1.7-0.9H10.2C9.6,0,8.9,0.3,8.6,0.9L0.3,13.7
		c0,0,0,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1-0.1,0.2c0,0.1,0,0.1-0.1,0.2c0,0.1,0,0.2,0,0.2C0,14.7,0,14.8,0,14.9
		C0,14.8,0,14.8,0,14.9C0,14.9,0,14.9,0,14.9z M11.3,4h95.2l6.9,10.7c0,0.1,0,0.1,0,0.2c0,5-4,9-9,9c-2.9,0-5.6-1.4-7.3-3.8
		c-0.4-0.6-1.1-0.9-1.7-0.8c-0.7,0-1.3,0.3-1.7,0.8c-1.7,2.4-4.4,3.8-7.3,3.8c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
		c-2.9,0-5.6-1.4-7.3-3.8c-0.4-0.6-1.1-0.9-1.7-0.8c-0.7,0-1.3,0.3-1.7,0.8c-1.7,2.4-4.4,3.8-7.3,3.8c0,0-0.1,0-0.1,0
		c0,0-0.1,0-0.1,0c-2.9,0-5.6-1.4-7.3-3.8c-0.4-0.6-1.1-0.9-1.7-0.8c-0.7,0-1.3,0.3-1.7,0.8c-1.7,2.4-4.4,3.8-7.3,3.8
		c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-2.9,0-5.6-1.4-7.3-3.8c-0.4-0.6-1.1-0.9-1.7-0.8c-0.7,0-1.3,0.3-1.7,0.8
		c-1.7,2.4-4.4,3.8-7.3,3.8c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-2.9,0-5.6-1.4-7.3-3.8c-0.4-0.6-1.1-0.9-1.7-0.8
		c-0.7,0-1.3,0.3-1.7,0.8c-1.7,2.4-4.4,3.8-7.3,3.8c-4.8,0-8.7-3.7-9-8.4L11.3,4z"
        />
        <path
          d="M113.1,80h-5.4V52.2c0-1.1-0.9-2-2-2s-2,0.9-2,2V80H50.4V39.3c0-1.1-0.9-2-2-2H20.3c-1.1,0-2,0.9-2,2V80h-4.5V52.2
		c0-1.1-0.9-2-2-2s-2,0.9-2,2V80H4.4c-1.1,0-2,0.9-2,2s0.9,2,2,2h15.9h28.1h64.7c1.1,0,2-0.9,2-2S114.2,80,113.1,80z M22.3,80V41.3
		h24.1V80H22.3z"
        />
      </g>
    </svg>
  );
};

export default IconShop;

// init state
import { getTranslate } from 'react-localize-redux';
import _get from 'lodash/get';
import { RootState } from 'src/typings';
import { getFormValues } from 'redux-form';
import { ProcessedFileData } from 'src/typings/interfaces';
import { GenericExtraInputFileFields } from '../../typings/enums';
import { FormNames } from 'src/typings/types';

const defaultState = {
  localized: false,
  loading: true,
  autocomplete: {},
  externalParties: [],
  countries: [],
};

const prefix = '@@base';

// types
export const types = {
  SET_TRANSLATIONS_LOADED: `${prefix}/SET_TRANSLATIONS_LOADED`,
  CLEAN_LOCAL_STORAGE: `${prefix}/CLEAN_LOCAL_STORAGE`,
  START_APP_LOADING: `${prefix}/START_APP_LOADING`,
  STOP_APP_LOADING: `${prefix}/STOP_APP_LOADING`,
  SET_COUNTRIES: `${prefix}/SET_COUNTRIES`,
  SET_EXTERNAL_PARTIES: `${prefix}/SET_EXTERNAL_PARTIES`,
  REMOVE_UPLOADED_FILE: `${prefix}/REMOVE_UPLOADED_FILE`,
};

// actions
export const actions = {
  setExternalParties: parties => ({ type: types.SET_EXTERNAL_PARTIES, payload: parties }),
  setTranslationsLoaded: () => ({ type: types.SET_TRANSLATIONS_LOADED }),
  cleanLocalStorage: () => ({ type: types.CLEAN_LOCAL_STORAGE }),
  startLoading: () => ({ type: types.START_APP_LOADING }),
  stopLoading: () => ({ type: types.STOP_APP_LOADING }),
  setCountries: countries => ({ type: types.SET_COUNTRIES, payload: countries }),
  removeUploadedFile: payload => ({ type: types.REMOVE_UPLOADED_FILE, payload }),
};

// selectors
export const getLocalizeState = state => {
  return state.localize;
};

export const getExternalParties = state => {
  return state.base.externalParties;
};

export const getExternalPartyIri = (state: any, shortCode: string): string => {
  if (shortCode) {
    const externalPartyIRI = state.base.externalParties.filter(
      externalParty => externalParty.short_code.toUpperCase() === shortCode.toUpperCase()
    );
    return externalPartyIRI[0]['@id'];
  }
  return '';
};

export const getTranslateFromStore = state => {
  return getTranslate(state.localize);
};

export const getSearchParamString = state => {
  return state.router.location.search;
};

export const getSearchPath = state => {
  return state.router.location.pathname;
};

export const getCountries = (state: RootState, countryCodes?: string[]): any => {
  if (countryCodes) {
    return state.base.countries.filter(country => countryCodes.includes(country.code)).reverse();
  }
  return state.base.countries;
};

export const getCountryIri = (state: any, countryCode: string): string => {
  if (countryCode) {
    const countryIRI = state.base.countries.filter(country => country.code.toUpperCase() === countryCode.toUpperCase());
    return _get(countryIRI, '[0]["@id"]', null);
  }
  return '';
};

// generic uploads selectors
export const getUploadsByFormAndFieldName = (
  state: RootState,
  form: FormNames,
  fieldName: string,
  fromDuplicateCollection: boolean = false
): ProcessedFileData[] => {
  const formValues = getFormValues(form)(state);
  const collection = fromDuplicateCollection
    ? GenericExtraInputFileFields.COLLECTION_DUP
    : GenericExtraInputFileFields.COLLECTION;
  return _get(formValues, `${fieldName}_${collection}`, []);
};

export const getIsUploadingByFormAndFieldName = (state: RootState, form: FormNames, fieldName: string): boolean => {
  const formValues = getFormValues(form)(state);
  return _get(formValues, `${fieldName}_${GenericExtraInputFileFields.UPLOADING}`, false);
};

export const getUploadFeedbackByFormAndFieldName = (state: RootState, form: FormNames, fieldName: string): string => {
  const formValues = getFormValues(form)(state);
  return _get(formValues, `${fieldName}_${GenericExtraInputFileFields.UPLOAD_FEEDBACK}`, '');
};

export const getShouldThisFieldUploadTwice = (state: RootState, form: FormNames, fieldName: string): boolean => {
  const formState = state.form[form];
  return (
    typeof _get(formState, `registeredFields.${fieldName}_${GenericExtraInputFileFields.COLLECTION_DUP}`) !==
    'undefined'
  );
};

export const getUploadedFileDataByFormAndFieldName = (
  state: RootState,
  form: FormNames,
  fieldName: string,
  dataType: string,
  fromDuplicateCollection: boolean = false
): string[] => {
  const collection: ProcessedFileData[] = getUploadsByFormAndFieldName(state, form, fieldName, fromDuplicateCollection);
  return collection.map((file: ProcessedFileData) => {
    return file[dataType];
  });
};

// reducers
export const base = (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_TRANSLATIONS_LOADED:
      return {
        ...state,
        localized: true,
        loading: false,
      };
    case types.SET_EXTERNAL_PARTIES:
      return {
        ...state,
        externalParties: action.payload,
      };
    case types.START_APP_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.STOP_APP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case types.SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    default:
      return state;
  }
};

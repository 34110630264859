import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { getDeliveryDetails } from '../ducks';
import { actions as paymentActions } from '../../Payment/ducks';
import { connect } from 'react-redux';
import '../../../assets/payment/payment-details.css';
import {
  Button,
  Col,
  Collapse,
  Container,
  FlashMessage,
  Heading,
  IconArrow,
  PriceDisplay,
  PrintDatetimePeriods,
  Row,
  SheetLayoutContent,
  SheetLayoutContentWrapper,
  SheetLayoutFooter,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { Translate } from 'react-localize-redux';
import _get from 'lodash/get';
import { _INSURANCE_AMOUNT } from '../../../utils/global';
import { toEuroString } from '../../../utils/payment';
import { push } from 'connected-react-router';
import PaymentMethods from '../../Payment/components/PaymentMethods';
import { translate } from '../../../utils/localization';
import { _FLOW_TYPE, prefix } from '../index';
import { VavatoFlowForms } from '../interface';

type Props = InjectedFormProps<{}> & RouteComponentProps<{}>;

class Payment extends React.Component<InjectedFormProps<{}> & Props> {
  public componentDidMount(): void {
    if (!this.props.uuid) {
      this.props.startAgain();
    }
  }
  public render() {
    const datetimePeriods = _get(this.props.deliveryDetails, 'available_datetime_periods', []);
    const addressData = _get(this.props.deliveryDetails, 'address', false);
    const lotsData = _get(this.props.auctionForm, 'values.lots', []);
    return (
      <form onSubmit={this.props.handleSubmit}>
        <SheetLayoutContentWrapper>
          <SheetLayoutHeader>
            <Heading size={2}>
              <Translate id={'request_flow.headers.payment'} />
            </Heading>
          </SheetLayoutHeader>
          <SheetLayoutContent>
            <Collapse open={false} header={String(translate('request_flow.payment.order_wrap_up'))}>
              <div className={'order-wrap-up pb-1'}>
                <Row extraClasses={'order-wrap-up--lot'}>
                  <Col xs={12} extraClasses={'pt-1'}>
                    <Heading size={5}>
                      <Translate id={'request_flow.auction.lot'} />
                    </Heading>
                  </Col>
                  <Col xs={12}>
                    <div className={'order-wrap-up--details'}>
                      {lotsData.map((item, index) => {
                        return (
                          <div key={index}>
                            {item.number} - {item.title}
                          </div>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
                <Row extraClasses={'order-wrap-up--date'}>
                  <Col xs={12} extraClasses={'pt-1'}>
                    <Heading size={5}>
                      <Translate id={'request_flow.delivery.date'} />
                    </Heading>
                  </Col>
                  <Col xs={12}>
                    <div className={'order-wrap-up--details'}>
                      {PrintDatetimePeriods(datetimePeriods)}
                      <div className={'time-slot-explain'}>
                        <Translate id={'request_flow.delivery.explain'} />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row extraClasses={'order-wrap-up--address'}>
                  <Col xs={12} extraClasses={'pt-1'}>
                    <Heading size={5}>
                      <Translate id={'location.address'} />
                    </Heading>
                  </Col>
                  <Col xs={12}>
                    <div className={'order-wrap-up--details'}>
                      {addressData && `${addressData.line1}, ${addressData.postal_code}, ${addressData.locality}`}
                    </div>
                  </Col>
                </Row>
                <Row extraClasses={'order-wrap-up--price'}>
                  <Col xs={12} extraClasses={'pt-1'}>
                    <Heading size={5}>
                      <Translate id={'request_flow.price.total'} />
                    </Heading>
                  </Col>
                  <Col xs={12}>
                    <div className={'order-wrap-up--details'}>
                      € {(this.props.price / 100).toFixed(2)}{' '}
                      <Translate
                        id={'request_flow.price.inc_vat_insurance'}
                        data={{ insurance_amount: toEuroString(_INSURANCE_AMOUNT) }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Collapse>
            <PaymentMethods resetPaymentForm={this.props.reset} submitPaymentForm={this.props.handleSubmit} />
            <div className={'add-lot-general-errors-wrapper'}>
              {this.props.generalErrors && this.props.generalErrors !== '' && (
                <FlashMessage type={'error'} message={this.props.generalErrors} />
              )}
            </div>
          </SheetLayoutContent>
        </SheetLayoutContentWrapper>
        <SheetLayoutFooter>
          <Container>
            <Row>
              <Col xs={5}>
                <PriceDisplay header={<Translate id={'request_flow.price.total'} />} cents={this.props.price} />
              </Col>
              <Col xs={7}>
                <Button
                  loading={this.props.transport.request.submitting || this.props.isSubmitting}
                  disabled={this.props.transport.request.submitting || this.props.isSubmitting}
                  buttonStyle={'secondary'}
                  iconPos={'right'}
                  fullWidth={true}
                >
                  <Translate id={'onboarding.step.next'} />
                  <IconArrow />
                </Button>
              </Col>
            </Row>
          </Container>
        </SheetLayoutFooter>
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  auctionForm: state.form[VavatoFlowForms.auctionLotForm],
  deliveryDetails: getDeliveryDetails(state),
  transport: state.vavatoTransport,
  price: state.vavatoTransport.request.price,
  generalErrors: state.vavatoTransport.request.errors,
  uuid: state.vavatoTransport.request.uuid,
  payment: state.payment,
  isSubmitting: state.payment.paymentInfo.isSubmitting,
});

const mapDispatchToProps = dispatch => ({
  startAgain: () => dispatch(push(`${prefix}/auction_lots_details`)),
});

export default reduxForm<{}, Props>({
  form: 'paymentForm',
  initialValues: {},
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmit: (payment, dispatch) => dispatch(paymentActions.submitPayment({ ...payment, flow: _FLOW_TYPE })),
})(connect(mapStateToProps, mapDispatchToProps)(Payment));

import * as React from 'react';

interface Props {
  fluid?: boolean;
  children: React.ReactNode;
}

export const Container = ({ fluid = false, children }: Props) => {
  return <div className={fluid ? 'container-fluid' : 'container'}>{children}</div>;
};
export default Container;

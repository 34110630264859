import React from 'react';
import { Field } from 'redux-form';
import { Translate } from 'react-localize-redux';
import { FlashMessage, Heading, InputTiles, SheetLayoutContent, SheetLayoutHeader } from '../../../brenger-shared-ui';
import { SubStepFieldNamesDelivery, SubStepFieldNamesPickup } from '../interface';
import { priceAsString } from '../../../utils/price';
import { translate } from '../../../utils/localization';
import { pickupDeliverySchema } from '../schema';
import { CarryingHelpChoices } from '../../../typings';
import { useTypedSelector } from '../../../hooks';
import { getFeeTailgate, getFeeTailgateExtraDriver, getFeeTailgatePalletJack } from '../ducks';

interface Props {
  isPickup: boolean;
  locality: string;
  transport: any;
}

const SubStepEquipment = ({ transport, isPickup, locality }: Props) => {
  const fieldNames = isPickup ? SubStepFieldNamesPickup : SubStepFieldNamesDelivery;
  const stopType = isPickup ? 'pickup' : 'delivery';
  const feeTailgate = useTypedSelector(getFeeTailgate);
  const feeTailgatePalletJack = useTypedSelector(getFeeTailgatePalletJack);
  const feeTailgateExtraDriver = useTypedSelector(getFeeTailgateExtraDriver);
  const options = [
    {
      value: CarryingHelpChoices.EQUIPMENT_TAILGATE,
      title: String(translate('request_flow.weight_equipment.equipment_tailgate')),
      desc: <Translate id={'request_flow.weight_equipment.equipment_tailgate_explain'} />,
      suffix: <span className={'text--primary'}>+ {priceAsString(feeTailgate)}</span>,
    },
    {
      value: CarryingHelpChoices.EQUIPMENT_TAILGATE_PALLET_JACK,
      title: String(translate('request_flow.weight_equipment.equipment_tailgate_with_pallet_jack')),
      desc: <Translate id={'request_flow.weight_equipment.equipment_tailgate_with_pallet_jack_explain'} />,
      suffix: <span className={'text--primary'}>+ {priceAsString(feeTailgatePalletJack)}</span>,
    },
    {
      value: CarryingHelpChoices.EQUIPMENT_TAILGATE_EXTRA_DRIVER,
      title: String(translate('request_flow.weight_equipment.equipment_tailgate_extra_driver')),
      desc: <Translate id={'request_flow.weight_equipment.equipment_tailgate_extra_driver'} />,
      suffix: <span className={'text--primary'}>+ {priceAsString(feeTailgateExtraDriver)}</span>,
    },
  ];
  return (
    <>
      <SheetLayoutHeader>
        <Heading size={2}>
          {
            <Translate
              id={`request_flow.${stopType}.weight_equipment_question`}
              data={{ locality: <span className={'text--primary'}>{locality}</span> }}
            />
          }
        </Heading>
      </SheetLayoutHeader>
      <SheetLayoutContent>
        <div className={'question-wrapper'}>
          <Field
            validate={pickupDeliverySchema.equipment}
            name={fieldNames.help_equipment}
            options={options}
            component={InputTiles}
          />
        </div>
        <div className={'general-errors-wrapper'}>
          {transport.errors && transport.errors !== '' && <FlashMessage type={'error'} message={transport.errors} />}
        </div>
      </SheetLayoutContent>
    </>
  );
};

export default SubStepEquipment;

import { debounce, delay, fork, takeLatest } from 'redux-saga/effects';
import { persistor } from '../../';
import { types } from '../ducks/baseReducer';
import { LOCATION_CHANGE } from 'connected-react-router';
import { actionTypes as formTypes } from 'redux-form';
import { prefix } from '../../modules/GeneralFlow';
import { http } from '../../utils/request';
import { uploadSagas } from './uploadSaga';

export function* cleanLocalStorage() {
  persistor.purge();
}

export function* scrollToTop(action) {
  if (action.payload.location.pathname.indexOf(prefix) > -1) {
    window.scrollTo(0, 0);
  }
}

export function* focusOnFieldsInView(action) {
  const isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1;
  const inputEl = document.getElementById(action.meta.field);
  if (!isAndroid || !inputEl) {
    return;
  }
  // let's go!
  const yCoordinate = inputEl.getBoundingClientRect().top + window.pageYOffset;
  window.scrollTo({
    top: yCoordinate - 70,
    behavior: 'smooth',
  });
}

export function* uponSubmitFailedScrollToFirstError(action) {
  if (!action.error) {
    return;
  }
  const error = document.querySelector('.input-el--feedback--error');
  yield delay(100);
  if (error) {
    error.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
  }
}

export function* foldInMenuAndFlowClass() {
  const navItems = document.querySelectorAll('.main-nav--items .nav-item');
  for (let i = 0; i < navItems.length; ++i) {
    navItems[i].classList.remove('sub-nav--is-open');
  }
  // add class when we are in the new flow
  const body = document.querySelector('body');
  if (!body) {
    return;
  }
  if (window.location.pathname.indexOf(`/customer`) > -1) {
    body.classList.add('render-app');
  } else {
    body.classList.remove('render-app');
  }
}

export function fetchEndpoint(endpoint: string, options: object = {}) {
  return http(options).get(endpoint);
}

export function* basicSideEffects() {
  yield takeLatest(types.CLEAN_LOCAL_STORAGE, cleanLocalStorage);
  yield takeLatest(LOCATION_CHANGE, foldInMenuAndFlowClass);
  yield debounce(100, LOCATION_CHANGE, scrollToTop);
  yield takeLatest(formTypes.FOCUS, focusOnFieldsInView);
  yield takeLatest(formTypes.SET_SUBMIT_FAILED, uponSubmitFailedScrollToFirstError);
}

export function* baseSagas() {
  yield fork(basicSideEffects);
  yield fork(uploadSagas);
}

import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { actionTypes as formTypes, change, clearFields, untouch } from 'redux-form';
import { http } from '../../utils/request';
import _get from 'lodash/get';
import { _UPLOAD_HEADERS } from '../../utils/global';
import { ProcessedFileData } from '../../typings/interfaces';
import { GenericExtraInputFileFields, GenericInputFileMethods } from '../../typings/enums';
import { getShouldThisFieldUploadTwice, getUploadsByFormAndFieldName, types as baseTypes } from '../ducks/baseReducer';
import { logger } from '../../utils/basics';
import { translate } from '../../utils/localization';

function* handleGenericUploads(action) {
  if (!_get(action, 'payload.input_file_method')) {
    return;
  }
  // flag uploading
  yield put(change(action.meta.form, `${action.meta.field}_${GenericExtraInputFileFields.UPLOADING}`, true));
  // Block refresh
  window.addEventListener('beforeunload', blockRefresh, true);

  // reset feedback
  yield put(change(action.meta.form, `${action.meta.field}_${GenericExtraInputFileFields.UPLOAD_FEEDBACK}`, ''));
  yield put(untouch(action.meta.form, `${action.meta.field}`));
  try {
    const fileList: FileList = action.payload.files;

    // compress files
    const compressActions: any[] = [];
    for (let i = 0; i < fileList.length; i++) {
      compressActions.push(call(processFile, fileList[i]));
    }
    const compressAll = yield all(compressActions);

    // prepare uploads
    const endPoint: string = action.meta.field.includes('image') ? '/job_images' : '/external_documents';
    const uploadActions: any[] = [];
    for (let i = 0; i < compressAll.length; i++) {
      uploadActions.push(call(uploadFile, compressAll[i], endPoint));
    }

    // upload at once
    const uploadAll = yield all(uploadActions);
    yield handleUploadResponsesAndSaveValues(
      action,
      uploadAll,
      fileList,
      `${action.meta.field}_${GenericExtraInputFileFields.COLLECTION}`
    );

    const shouldUploadTwice = yield select(getShouldThisFieldUploadTwice, action.meta.form, action.meta.field);
    if (shouldUploadTwice) {
      // Upload second time
      const uploadAllDup = yield all(uploadActions);
      yield handleUploadResponsesAndSaveValues(
        action,
        uploadAllDup,
        fileList,
        `${action.meta.field}_${GenericExtraInputFileFields.COLLECTION_DUP}`
      );
    }
  } catch (e) {
    logger(e);
    // Show feedback
    yield put(
      change(
        action.meta.form,
        `${action.meta.field}_${GenericExtraInputFileFields.UPLOAD_FEEDBACK}`,
        translate('request_flow.fields.generic_uploads.warnings.all_failed')
      )
    );
  }

  // clear native input
  const fileInputs = yield document.querySelectorAll(`input[type='file']`) as NodeList;
  for (const fileInput of fileInputs) {
    yield (fileInput.value = '');
  }
  // unflag
  yield put(change(action.meta.form, `${action.meta.field}_${GenericExtraInputFileFields.UPLOADING}`, false));
  // unblock refresh
  window.removeEventListener('beforeunload', blockRefresh, true);
}

function* handleUploadResponsesAndSaveValues(action, uploadAll, fileList, collectionFieldName) {
  // check responses
  let fileDataArray: ProcessedFileData[] = uploadAll.map((upload, index) => {
    if (upload.status === 201) {
      return {
        ...upload.data,
        original_name: fileList[index].name,
      };
    }
  });

  // Check if everything went silky smooth
  if (uploadAll.length !== fileDataArray.length) {
    yield put(
      change(
        action.meta.form,
        `${action.meta.field}_${GenericExtraInputFileFields.UPLOAD_FEEDBACK}`,
        translate('request_flow.fields.generic_uploads.warnings.some_uploads_failed')
      )
    );
  }

  // Check if we need to overwrite, or add the files to existing files
  if (action.payload.input_file_method === GenericInputFileMethods.ADD) {
    const collection = yield select(getUploadsByFormAndFieldName, action.meta.form, action.meta.field);
    fileDataArray = [...collection, ...fileDataArray];
  }

  // save all responses to collection field
  yield put(change(action.meta.form, collectionFieldName, fileDataArray));
}

function* removeUploadedFile(action) {
  if (_get(action, 'payload.input_file_method') !== GenericInputFileMethods.DELETE) {
    return;
  }
  const actions: any[] = [];
  const collection = yield select(getUploadsByFormAndFieldName, action.payload.formName, action.payload.fieldName);
  collection.splice(action.payload.index, 1);
  actions.push(
    put(
      change(
        action.payload.formName,
        `${action.payload.fieldName}_${GenericExtraInputFileFields.COLLECTION}`,
        collection
      )
    )
  );
  const isUploadedTwice = yield select(
    getShouldThisFieldUploadTwice,
    action.payload.formName,
    action.payload.fieldName
  );
  if (isUploadedTwice) {
    actions.push(
      put(
        change(
          action.payload.formName,
          `${action.payload.fieldName}_${GenericExtraInputFileFields.COLLECTION_DUP}`,
          collection
        )
      )
    );
  }
  if (collection.length === 0) {
    actions.push(put(clearFields(action.payload.formName, false, true, `${action.payload.fieldName}`)));
    actions.push(
      put(
        change(
          action.payload.formName,
          `${action.payload.fieldName}_${GenericExtraInputFileFields.UPLOAD_FEEDBACK}`,
          ''
        )
      )
    );
  }
  yield all(actions);
}

export function* resetUploadField(formName, fieldName) {
  yield all([
    put(change(formName, `${fieldName}_${GenericExtraInputFileFields.COLLECTION}`, [])),
    put(change(formName, `${fieldName}_${GenericExtraInputFileFields.COLLECTION_DUP}`, [])),
    put(change(formName, `${fieldName}_${GenericExtraInputFileFields.UPLOAD_FEEDBACK}`, '')),
    put(clearFields(formName, false, true, `${fieldName}`)),
  ]);
}

export function uploadFile(file, endPoint) {
  const formData = new FormData();

  // Figure out if we pass a hotlink to the image, or a binary
  if (typeof file === 'string' && file.indexOf('http') > -1) {
    formData.append('source_file_url', file);
  } else {
    formData.append('file', file);
  }

  return http({ headers: _UPLOAD_HEADERS })
    .post(endPoint, formData)
    .then(resp => resp);
}

function* processFile(file) {
  const mimeType = file.type;
  if (!mimeType.match(/.(jpg|jpeg|png)$/i)) {
    return file;
  }
  const loadedFile = yield loadFile(file);
  const canvasData = yield getResizedImageData(loadedFile, mimeType);

  // some magic to convert the canvas data to file formatting
  const blobBin = atob(canvasData.split(',')[1]);
  const array: any = [];
  for (let i = 0; i < blobBin.length; i++) {
    array.push(blobBin.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: mimeType });
}

function loadFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onloadend = loadedFile => resolve(loadedFile?.target?.result);

    reader.onerror = () => reject(new Error('There was an error loading the file'));
  });
}

function getResizedImageData(imageSource, mime = 'image/jpeg', maxSize = 1920, quality = 0.7) {
  return new Promise(resolve => {
    const img = new Image();
    img.src = imageSource as string;
    img.onload = () => {
      let width = img.width;
      let height = img.height;
      const maxDimension = Math.max(width, height);
      if (maxDimension > maxSize) {
        const scale = maxSize / maxDimension;
        width = scale * img.width;
        height = scale * img.height;
      }
      const elem = document.createElement('canvas');
      elem.width = width;
      elem.height = height;
      const ctx = elem.getContext('2d');
      if (!ctx) {
        return;
      }
      ctx.drawImage(img, 0, 0, width, height);
      const data = ctx.canvas.toDataURL(mime, quality);
      resolve(data);
    };
  });
}

function blockRefresh(e) {
  e.preventDefault();
  // Chrome requires returnValue to be set
  // Custom messages are not supported, makes panda sad
  e.returnValue = '';
}

export function* uploadSagas() {
  yield takeEvery(formTypes.CHANGE, handleGenericUploads);
  yield takeEvery(baseTypes.REMOVE_UPLOADED_FILE, removeUploadedFile);
}

/*
/* BRENGER BASICS UTILS (DEPRECATED!)
/* ================================================== */

import _get from 'lodash/get';
const COOKIE = document.cookie;

export const randomIntFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

// Lodash in training
export const valueOrElseWithSize = (key: any, dictionary: any, defaultVal: any) => {
  return key in dictionary && dictionary[key].length > 0 ? dictionary[key] : defaultVal;
};

export const valueOrElse = (key: any, dictionary: any, defaultVal: any) => {
  return key in dictionary ? dictionary[key] : defaultVal;
};
/**
 *
 * @param {string} title
 * @param {string} suffix
 * @param {string} separator
 * @returns {null}
 */
export const setDocumentTitle = (title: string, suffix: string = 'Brenger', separator: string = '|') => {
  try {
    document.title = `${title} ${separator} ${suffix}`;
  } catch (err) {
    document.title = `${separator} ${suffix}`;
  }
};

export const getActiveLanguage = () => {
  if (window.location.pathname.indexOf('/nl') > -1) {
    return 'nl';
  }
  if (window.location.pathname.indexOf('/en') > -1) {
    return 'en';
  }
  return _get(parseCookies(), 'driver_dashboard_lang', 'nl');
};

export const getFaviconPath = () => {
  const host = window.location.hostname;
  if (host.includes('www.brenger.nl')) {
    return '/themes/brenger_rebranding_theme/favicon.ico';
  } else {
    return '/favicon.ico';
  }
};

export const swapArray = (arr: any[], index1: number, index2: number) =>
  arr.map((val, idx) => {
    if (idx === index1) {
      return arr[index2];
    }
    if (idx === index2) {
      return arr[index1];
    }
    return val;
  });

export const isElementInViewport = (el: Element) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const hyphensToCamelCase = (s: string) => {
  return s.replace(/_([a-z])/g, g => {
    return g[1].toUpperCase();
  });
};
// Crossbrowser events
export const addEvent = (el: any, type: any, handler: any) => {
  if (el.attachEvent) {
    el.attachEvent('on' + type, handler);
  } else {
    el.addEventListener(type, handler);
  }
};
export const removeEvent = (el: any, type: any, handler: any) => {
  if (el.detachEvent) {
    el.detachEvent('on' + type, handler);
  } else {
    el.removeEventListener(type, handler);
  }
};

export const capsFirstLetter = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const stripLastCharacter = (s: string) => {
  return s.slice(0, -1);
};

/**
 *
 * @param {object} event
 * @returns {null}
 */
export const pushToDataLayer = (event: any) => {
  if (process.env.REACT_APP_ENV === 'dev') {
    if (process.env.REACT_APP_LOG) {
      logger(event);
    }
    return;
  }

  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  window.dataLayer.push(event);
};

export const parseCookies = () => {
  return COOKIE.split(';').reduce((params, hash) => {
    const [key, val] = hash.trim().split('=');
    return Object.assign(params, { [key]: decodeURIComponent(val) });
  }, {});
};

export const getSpecialIconsArray = (transportJob: any) => {
  const iconsArr = [] as any[];

  // check for pickup/delivery situation
  const extraCarryHelp = 'extra_carry_help';
  const pickupSituation = _get(transportJob, 'pickups[0].situation.system_name', '');
  const deliverySituation = _get(transportJob, 'deliveries[0].situation.system_name', '');
  if (pickupSituation === extraCarryHelp || deliverySituation === extraCarryHelp) {
    iconsArr.push(extraCarryHelp);
  }

  // check for special requirements
  _get(transportJob, 'special_requirements', []).map((requirement: any) => {
    return iconsArr.push(requirement.system_name);
  });

  return iconsArr;
};

export const logException = (message: string, data: any = {}) => {
  // @ts-ignore
  if (typeof window.Sentry === 'undefined') {
    return;
  }
  // @ts-ignore
  window.Sentry.withScope((scope: any) => {
    scope.setTags('order-flow');
    scope.setExtras(data);
    // @ts-ignore
    window.Sentry.captureException(message);
    // @ts-ignore
    window.Sentry.captureMessage(message);
  });
};

export const urlParser = () => new URLSearchParams(document.location.search);

export const setCookie = (key: string, value: string, days: number) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = key + '=' + (value || '') + expires + ';domain=.brenger.nl;path=/';
};

export function removeCookie(key: string) {
  document.cookie = key + '=; Max-Age=-99999999;';
}

export function getCustomerHost() {
  if (process.env.REACT_APP_ENV === 'staging') {
    return process.env.REACT_APP_CUSTOMER_HOST_STAGING || 'https://staging.customer.brenger.nl';
  } else {
    return process.env.REACT_APP_CUSTOMER_HOST || 'https://customer.brenger.nl';
  }
}

export function getDriverHost() {
  if (process.env.REACT_APP_ENV === 'staging') {
    return process.env.REACT_APP_DRIVER_HOST_STAGING || 'https://staging.driver.brenger.nl';
  } else {
    return process.env.REACT_APP_DRIVER_HOST || 'https://driver.brenger.nl';
  }
}

export function logger(message: any) {
  if (process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'staging') {
    // tslint:disable-next-line
    console.log(message);
  }
}

import * as React from 'react';
import './modal.css';
import { IconCross } from '../../basics/icons';

interface Props {
  open?: boolean;
  header?: any;
  onlyToggleByButton?: boolean;
  onClick?: any;
  modalStyle?: string;
  noShade?: boolean;
  innerId?: string;
}

export enum modalStyleOptions {
  basic = 'basic',
  blue = 'blue',
  chat = 'chat',
  statusPage = 'status-page',
  white = 'white',
  whiteSmall = 'white modal--style-small',
}

export const Modal: React.FC<Props> = ({
  open = false,
  header,
  onlyToggleByButton = false,
  onClick,
  modalStyle = 'basic',
  noShade = false,
  innerId,
  children,
}) => {
  return (
    <div
      className={
        'modal' + (open ? ' modal--isOpen' : ' modal--isClosed') + ' modal--style-' + modalStyleOptions[modalStyle]
      }
    >
      <div
        className={'modal-backdrop' + (noShade ? ' modal-backdrop--no-shade' : '')}
        onClick={onlyToggleByButton ? undefined : onClick}
      >
        <div id={innerId} className={'modal-inner'} onClick={event => blockClick(event)}>
          <div className={'modal-header'}>
            {header && header}
            {!onlyToggleByButton && (
              <div className={'modal-close trigger'} onClick={onClick}>
                <IconCross />
              </div>
            )}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};
export default Modal;

const blockClick = (event: any) => {
  event.stopPropagation();
};

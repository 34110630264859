import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { arraySplice, FieldArray, InjectedFormProps, reduxForm } from 'redux-form';
import { actions as flowActions, getFlowTitleByStepAndSituation, getPickupSituation, getQuoteItems } from '../ducks';
import { connect } from 'react-redux';
import {
  Button,
  CheckMarkList,
  Col,
  FlashMessage,
  Heading,
  Modal,
  Row,
  SheetLayoutContent,
  SheetLayoutContentWrapper,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import ItemSets from '../components/ItemSets';
import { Translate } from 'react-localize-redux';
import '../../../assets/general-flow/items.css';
import { GeneralFlowForms, Item, Situations, ToggleSheetInterface } from '../interface';
import { push } from 'connected-react-router';
import FooterLayout from './FooterLayout';
import { products } from '../products';
import _get from 'lodash/get';
import { translate } from '../../../utils/localization';
import { toEuroString } from '../../../utils/payment';
import { _INSURANCE_AMOUNT } from '../../../utils/global';

interface customProps {
  title: string;
  situation: string;
}

type Props = InjectedFormProps<{}> & RouteComponentProps<{}> & ToggleSheetInterface & customProps;

interface State {
  modalOpen: boolean;
}

class ItemSet extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
  }

  public componentWillMount(): void {
    this.props.setProgress(3);
  }

  // return true when the item values have prefilled from example product dimensions
  public isInitializedValue(values): boolean {
    let isPristine = false;
    Object.keys(products).map((key: string) => {
      const product = products[key];
      if (
        product.label === values.title &&
        product.width === values.width &&
        product.height === values.height &&
        product.length === values.length
      ) {
        isPristine = true;
      }
    });
    return isPristine;
  }

  public closeModal(e) {
    e.preventDefault();
    this.setState({ modalOpen: false });
    this.forceUpdate();
  }

  public isValidItem(item) {
    return (
      !_get(item, 'width', false) &&
      !_get(item, 'height', false) &&
      !_get(item, 'length', false) &&
      !_get(item, 'title', false)
    );
  }

  public discardEmptyItems() {
    const items = _get(this.props.form, 'values.itemSets[0].items', []);
    const tmp: number[] = [];
    // store empty item's index to remove
    items.map((item: Item, i: number) => {
      if (this.isValidItem(item) && i > 0) {
        tmp.push(i);
      }
    });
    // delete items recursively
    tmp.reverse().map(index => this.props.arrayItemSplice(index));
    // wait for redux form unregister fields actions
    setTimeout(() => this.props.handleSubmit(), 500);
  }

  public beforeSubmit(e, props) {
    e.preventDefault();
    const formValues = this.props.form.values;
    const initialItemValues = formValues.itemSets[0].items[0];
    // We confirm with the customer when we have prefilled the item set values based on example product from url before submission
    if (this.isInitializedValue(initialItemValues)) {
      this.setState({ modalOpen: true });
      return;
    }
    // We do remove those items which are not valid (empty or not fully filled-in)
    this.discardEmptyItems();
    this.props.handleSubmit();
  }

  public shouldSizeWarningBeShown() {
    const itemSets = _get(this.props.form, 'values.itemSets[0].items', null);
    if (itemSets === null || itemSets.length === 0) {
      return false;
    }
    const itemSetsTooSmall = itemSets.filter(
      item => _get(item, 'width') < 11 && _get(item, 'height') < 11 && _get(item, 'length') < 11
    );
    return itemSetsTooSmall.length > 0;
  }

  public render() {
    return (
      <form onSubmit={e => this.beforeSubmit(e, this.props)}>
        <SheetLayoutContentWrapper>
          <SheetLayoutHeader>
            <Heading size={2}>
              <Translate id={this.props.title} />
            </Heading>
            {this.props.situation === Situations.STORE && (
              <p
                dangerouslySetInnerHTML={{
                  __html: String(translate('request_flow.what.item.business_price')),
                }}
              />
            )}
          </SheetLayoutHeader>
          <FieldArray
            name={'itemSets'}
            title={<Translate id={'request_flow.what.item.sub_title'} />}
            component={ItemSets}
            formName={GeneralFlowForms.items}
            quoteItems={this.props.quoteItems}
            showSizeWarning={this.shouldSizeWarningBeShown()}
          />
          <SheetLayoutContent>
            <div className={'general-errors-wrapper'}>
              {this.props.transport.request.errors && this.props.transport.request.errors !== '' && (
                <FlashMessage type={'error'} message={this.props.transport.request.errors} />
              )}
            </div>
            {/* AB test */}
            <div className={'hide--lg-up'}>
              <div className={'brenger-usp-display'}>
                <CheckMarkList
                  checkMarkStyle={'primary'}
                  list={[
                    translate('request_flow.summary.usp.one', { insurance_amount: toEuroString(_INSURANCE_AMOUNT) }),
                    translate('request_flow.summary.usp.two', { insurance_amount: toEuroString(_INSURANCE_AMOUNT) }),
                    translate('request_flow.summary.usp.three', { insurance_amount: toEuroString(_INSURANCE_AMOUNT) }),
                    translate('request_flow.summary.usp.four', { insurance_amount: toEuroString(_INSURANCE_AMOUNT) }),
                  ]}
                />
              </div>
            </div>
          </SheetLayoutContent>
          <FooterLayout />
        </SheetLayoutContentWrapper>
        <Modal
          header={
            <b>
              <Translate id={'request_flow.what.example_dimensions.title'} />
            </b>
          }
          onlyToggleByButton={true}
          modalStyle={'whiteSmall'}
          open={this.state.modalOpen}
        >
          <div className={'help-content text--center'}>
            <p>
              <Translate id={'request_flow.what.example_dimensions.description'} />
            </p>
            <Row>
              <Col md={6}>
                <Button onClick={e => this.closeModal(e)} buttonStyle={'tertiary'}>
                  <Translate id={'request_flow.what.example_dimensions.cancel'} />
                </Button>
              </Col>
              <Col md={6}>
                <Button onClick={() => this.props.handleSubmit()} buttonStyle={'secondary'}>
                  <Translate id={'request_flow.what.example_dimensions.submit'} />
                </Button>
              </Col>
            </Row>
          </div>
        </Modal>
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  transport: state.generalTransport,
  form: state.form[GeneralFlowForms.items],
  situation: getPickupSituation(state),
  price: state.generalTransport.request.price,
  title: getFlowTitleByStepAndSituation(state, 'itemSet'),
  quoteItems: getQuoteItems(state),
});

const mapDispatchToProps = dispatch => ({
  setProgress: step => dispatch(flowActions.setProgress(step)),
  toggleSheetLayout: () => dispatch(flowActions.toggleSheet()),
  navigate: (url: string) => dispatch(push(url)),
  arrayItemSplice: (index: number) => dispatch(arraySplice(GeneralFlowForms.items, 'itemSets[0].items', index, 1)),
});

export default reduxForm<{}, Props>({
  form: GeneralFlowForms.items,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  initialValues: {
    itemSets: [
      {
        items: [
          {
            count: 1,
          },
        ],
      },
    ],
  },
  onSubmit: (formData, dispatch) => dispatch(flowActions.submitItems()),
})(connect(mapStateToProps, mapDispatchToProps)(ItemSet));

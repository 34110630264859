import React from 'react';
import { ChatInterface } from '../interface';
import moment from 'moment-timezone';
import { getDayFromNow } from '../../../utils/datetime';
import { IconSmile, Label } from '../../../brenger-shared-ui';
import { translate } from '../../../utils/localization';
import _get from 'lodash/get';

export class ChatBody extends React.Component<ChatInterface, any> {
  public render() {
    return (
      <div className={'chat-section--body'}>
        <div className={'chat-section--body-wrapper'}>
          {this.props.loading && (
            <div className={'loading-messages'}>
              <IconSmile spinning={true} />
            </div>
          )}
          {!this.props.loading &&
            this.props.messages.map((message, index) => {
              const mine = message['@type'] === 'CustomerMessage' ? true : false;
              const printDay = index === 0;
              /*const prevMessageIndex = index - 1;
                  const timeStampThisMessage = 'message time';
                  if (prevMessageIndex > 0) {
                      const timeStampPrevMessage = 'prev message time'; // messages[prevMessageIndex].timestamp;
                      if (moment(timeStampThisMessage).isAfter(timeStampPrevMessage, 'days')) {
                        printDay = true;
                      }
                }*/
              return (
                <div key={index}>
                  {printDay && (
                    <div className={'chat-section--message-day'}>
                      <Label>{getDayFromNow(message.created_at)}</Label>
                    </div>
                  )}
                  <div
                    key={index}
                    className={
                      'chat-section--message ' +
                      (mine ? 'chat-section--message--my-side' : 'chat-section--message--other-side')
                    }
                  >
                    <div className={'chat-section--message-header'}>
                      <span className={'chat-section--message-header--user'}>
                        {mine
                          ? translate('request_flow.chat.you')
                          : _get(message, 'author.first_name', '') + _get(message, 'author.last_name', false)}
                      </span>
                      <span className={'chat-section--message-header--time'}>
                        {moment(message.created_at).format('HH:mm')}
                      </span>
                    </div>
                    <div className={'chat-section--message-body'} dangerouslySetInnerHTML={{ __html: message.body }} />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

export default ChatBody;

import { all, call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';
import { actionTypes as formTypes, reset as resetForm } from 'redux-form';
import { matchPath } from 'react-router';
import _get from 'lodash/get';

import { actions as paymentActions, getPaymentInfo, types as actionTypes } from './ducks';
import { _CUSTOMER_SUPPORT_PHONE_NUMBER, REDIRECT_URLS } from '../../utils/global';
import { BASE_URL_API, http } from '../../utils/request';
import { LOCATION_CHANGE } from 'connected-react-router';
import { translate } from '../../utils/localization';
import { getBvaTransportRequest } from '../BvaFlow/ducks';
import { defaultState, getGeneralTransportRequest } from '../GeneralFlow/ducks';
import { getIkeaTransportRequest } from '../IkeaFlow/ducks';
import { getVavatoTransportRequest } from '../VavatoFlow/ducks';
import { _FLOW_TYPE as IkeaFlowType } from '../IkeaFlow';
import { _FLOW_TYPE as VavatoFlowType } from '../VavatoFlow';
import { _FLOW_TYPE as BvaFlowType } from '../BvaFlow';
import { GeneralFlowForms } from '../GeneralFlow/interface';

export function getPaymentAPI(uuid: string) {
  return `${BASE_URL_API}/transport_requests/${uuid}/payments`;
}

export function makePaymentRequest(uuid: string, method: string) {
  return http()
    .post(getPaymentAPI(uuid), method)
    .then(resp => resp);
}

export function* submitPaymentRetry(action) {
  const paymentInfo = yield select(getPaymentInfo);
  const payload = action.payload;
  if (paymentInfo.transport_request === null) {
    return;
  }
  const transportRequest = paymentInfo.transport_request.split('transport_requests/');
  yield call(executePayment, transportRequest[1], payload);
}

export function* submitPayment(action) {
  let transport = defaultState;
  // handle the temporary driver accept [non-prepaid-flow]
  const tmpPaymentRequest = localStorage.getItem('temp_request');
  if (tmpPaymentRequest) {
    yield call(executePayment, tmpPaymentRequest, action.payload);
    return;
  }
  if (action.payload.flow === BvaFlowType) {
    transport = yield select(getBvaTransportRequest);
  } else if (action.payload.flow === VavatoFlowType) {
    transport = yield select(getVavatoTransportRequest);
  } else if (action.payload.flow === IkeaFlowType) {
    transport = yield select(getIkeaTransportRequest);
  } else {
    transport = yield select(getGeneralTransportRequest);
  }
  yield call(executePayment, transport.request.uuid, action.payload);
}

export function* executePayment(transportUuid, method) {
  try {
    const paymentRequest = yield call(makePaymentRequest, transportUuid, method);
    if (paymentRequest.status === 201) {
      // Fire off reduxForm's own action creator for resetting JUST THE payment form.
      // We do not want to persist the payment form.
      // Not persisting this form means that users must re-input their payment method (and issuer)
      // each time they visit the payment module. This is desired because:
      // a) the payment module/flow is a simple, two-click process and,
      // b) when users click back from Mollie, we do want them stuck in the issuer stage.
      yield put(resetForm(GeneralFlowForms.paymentForm));
      // Redirect to Mollie payment page.
      window.location.assign(paymentRequest.data.redirect_url);
    }
  } catch (err) {
    yield put(paymentActions.stopPaymentRequest());
    yield put(paymentActions.pushNotification(translate('request_flow.payment.failed_payment_method')));
  }
}

function* pollPaymentStart() {
  let attempts = 1;
  const maxAttempts = 10;
  while (attempts <= maxAttempts) {
    const paymentInfo = yield select(getPaymentInfo);
    if (paymentInfo.polling === false) {
      return false;
    }
    yield call(loadPaymentInfo);
    attempts++;
    yield delay(2000);
  }
  if (attempts > maxAttempts) {
    yield put(paymentActions.pollPaymentStatusError(translate('request_flow.auction.errors.fetch_error')));
  }
}

function* loadPaymentInfo() {
  const paymentInfo = yield select(getPaymentInfo);
  const tmpTransportRequestId = localStorage.getItem('temp_request');
  try {
    const paymentResp = yield call(() =>
      http()
        .get(`/payments/${paymentInfo.id}`)
        .then(resp => resp)
    );
    const paymentRespData = _get(paymentResp, 'data', false);
    if (!paymentRespData) {
      return;
    }
    // mock
    // paymentRespData = {"@context":"\/contexts\/Payment","@id":"\/payments\/205d5bd3-2899-4448-9119-47f528146125","@type":"Payment","id":"205d5bd3-2899-4448-9119-47f528146125","payment_method":"\/payment_methods\/8ad5ae37-3a49-4fe3-aaca-ec0c00668a44","payment_method_issuer":"\/payment_method_issuers\/0ab7021b-e645-4c90-bb55-6173b3fcfe23","transport_request":"\/transport_requests\/e5d83cb0-079e-4a3c-a6cf-eaad998687ce","amount":4500,"currency":"EUR","status":"paid","created_at":"2019-04-25T12:49:02+00:00","updated_at":"2019-04-25T12:50:58+00:00"};
    yield put(
      paymentActions.pollPaymentStatusSuccess({
        amount: paymentRespData.amount,
        state: paymentRespData.status,
        transport_request: paymentRespData.transport_request,
        polling: !(paymentRespData.status !== 'open' && paymentRespData.status !== 'pending'),
      })
    );
    if (tmpTransportRequestId) {
      yield delay(2000);
      localStorage.removeItem('temp_request');
    }
  } catch (error) {
    // Don't do anything cause we keep polling
  }
}

export function* handlePaymentResponse(referenceId, source) {
  if (referenceId && referenceId !== 'undefined' && referenceId !== 'null') {
    yield all([put(paymentActions.setPaymentInfoId(referenceId)), put(paymentActions.pollPaymentStatusStart())]);
  } else {
    const error = translate('request_flow.thank_you.no_payment_found', {
      phonenumber: _CUSTOMER_SUPPORT_PHONE_NUMBER,
    });
    yield put(paymentActions.pollPaymentStatusError(error));
  }
}

export function* afterPayment(action) {
  const path = action.payload.location.pathname;
  const matchURLs = {
    BVA: matchPath(path, { path: REDIRECT_URLS.BVA_FLOW, exact: true }),
    VAVATO: matchPath(path, { path: REDIRECT_URLS.VAVATO_FLOW, exact: true }),
    IKEA: matchPath(path, { path: REDIRECT_URLS.IKEA_FLOW, exact: true }),
    GENERAL: matchPath(path, { path: REDIRECT_URLS.GENERAL_FLOW, exact: true }),
  };
  if (matchURLs.BVA) {
    const referenceId = _get(matchURLs.BVA.params, 'id', null);
    yield call(handlePaymentResponse, referenceId, 'BVA');
  }
  if (matchURLs.VAVATO) {
    const referenceId = _get(matchURLs.VAVATO.params, 'id', null);
    yield call(handlePaymentResponse, referenceId, 'VAVATO');
  }
  if (matchURLs.GENERAL) {
    const referenceId = _get(matchURLs.GENERAL.params, 'id', null);
    yield call(handlePaymentResponse, referenceId, 'GENERAL');
  }
  if (matchURLs.IKEA) {
    const referenceId = _get(matchURLs.IKEA.params, 'id', null);
    yield call(handlePaymentResponse, referenceId, 'IKEA');
  }
}

export function* handleSelectIssuer(action) {
  if (action.meta.field === 'method' && (action.payload === 'ideal' || action.payload === '')) {
    window.scrollTo(0, 0);
  }
}

function* eventsListener() {
  yield takeLatest(LOCATION_CHANGE, afterPayment);
  yield takeLatest(actionTypes.SUBMIT_PAYMENT_START, submitPayment);
  yield takeLatest(actionTypes.SUBMIT_PAYMENT_RETRY_START, submitPaymentRetry);
  yield takeLatest(actionTypes.POLL_PAYMENT_START, pollPaymentStart);
  yield takeLatest(formTypes.CHANGE, handleSelectIssuer);
}

export function* PaymentSagas() {
  yield fork(eventsListener);
}

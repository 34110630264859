import * as React from 'react';
import './icons.css';

const IconCircleThumbsUp = () => {
  return (
    <svg width="46" height="46" viewBox="0 0 46 46">
      <g fill="none" fillRule="evenodd">
        <circle cx="23" cy="23" r="23" fill="#A2CDFF" />
        <g fill="#005FCE">
          <path
            stroke="#005FCE"
            strokeWidth=".2"
            d="M32.147 25.928H27.57a.427.427 0 0 0-.417.44c0 .24.185.44.417.44h3.328c.691 0 1.25.588 1.25 1.315 0 .724-.559 1.315-1.25 1.315h-4.165a.427.427 0 0 0-.414.438c0 .243.186.439.414.439h2.916c.694 0 1.25.591 1.25 1.315 0 .727-.556 1.317-1.25 1.317H17.99V19.788h.404c.037.008 1.01.022 1.951-.943 1.202-1.225 1.809-3.418 1.809-6.513 0-.82.121-2.197.947-2.197h.098c.93 0 1.92 1.375 2.08 2.497.083.61.334 2.746-.044 4.597l-.515 2.559h7.011c.688 0 1.252.59 1.252 1.317 0 .723-.564 1.316-1.252 1.316H27.57a.425.425 0 0 0-.417.436c0 .245.185.44.417.44h2.916c.364 0 1.176.018 1.602.084 1.09.172 1.31.617 1.31 1.231 0 .727-.563 1.316-1.25 1.316zm-19.986 7.898h4.998V18.908h-4.998v14.918zm22.069-9.214c0-1.095-.57-1.606-1.13-1.855.433-.404.711-.994.711-1.652 0-1.21-.93-2.197-2.079-2.197h-5.98l.303-1.492c.4-1.998.138-4.263.05-4.91-.22-1.534-1.504-3.247-2.905-3.247h-.098c-.662 0-1.782.4-1.782 3.073 0 3.518-.831 5.129-1.531 5.858-.684.716-1.346.71-1.381.718h-.417v-.875H11.33v16.669h6.662v-.876h11.657c1.146 0 2.084-.985 2.084-2.196 0-.51-.166-.98-.443-1.353.961-.19 1.695-1.084 1.695-2.154 0-.51-.172-.98-.449-1.354.965-.193 1.695-1.087 1.695-2.157z"
          />
          <path d="M14.66 29c.688 0 1.252.59 1.252 1.315 0 .727-.564 1.315-1.252 1.315-.689 0-1.25-.588-1.25-1.315 0-.726.561-1.315 1.25-1.315m0 3.509c1.15 0 2.08-.983 2.08-2.194 0-1.21-.93-2.192-2.08-2.192-1.148 0-2.084.982-2.084 2.192 0 1.21.936 2.194 2.084 2.194" />
        </g>
      </g>
    </svg>
  );
};

export default IconCircleThumbsUp;

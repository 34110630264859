// init state
import _get from 'lodash/get';
import { _PERSIST_VERSION, ROLES } from '../../utils/global';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { missingImageSrc } from '../../brenger-shared-ui/global';
import { Invoice, User } from './interface';
import { RootState } from '../../typings/interfaces';

export const defaultState: User = {
  id: null,
  fetching: false,
  loggedIn: false,
  loggedInMethod: null,
  userType: null,
  userData: null,
  errors: '',
  details: null,
  loading: false,
  registered: false,
  isBusiness: false,
  what_is_my_country: 'NL',
  invoices: [],
  password_reset: {
    loading: false,
    error: '',
    success: false,
  },
  password_new: {
    token: '',
    loading: false,
    error: '',
    success: false,
  },
};

// types
export const types = {
  CREATE_ACCOUNT_INIT: 'USER/CREATE_ACCOUNT_INIT',
  SOCIAL_AUTH_START: 'USER/SOCIAL_AUTH_START',
  SOCIAL_AUTH_SUCCESS: 'USER/SOCIAL_AUTH_SUCCESS',
  SOCIAL_AUTH_FAILED: 'USER/SOCIAL_AUTH_FAILED',
  REGISTER_USER_START: 'USER/REGISTER_USER_START',
  REGISTER_USER_SUCCESS: 'USER/REGISTER_USER_SUCCESS',
  REGISTER_USER_FAILED: 'USER/REGISTER_USER_FAILED',
  LOGIN_USER_START: 'USER/LOGIN_USER_START',
  LOGIN_USER_SUCCESS: 'USER/LOGIN_USER_SUCCESS',
  LOGIN_USER_FAILED: 'USER/LOGIN_USER_FAILED',
  RESET_PASS_START: 'USER/RESET_PASS_START',
  RESET_PASS_SUCCESS: 'USER/RESET_PASS_SUCCESS',
  SET_INVOICES: 'USER/SET_INVOICES',
  RESET_PASS_FAILED: 'USER/RESET_PASS_FAILED',
  NEW_PASS_START: 'USER/NEW_PASS_START',
  NEW_PASS_SUCCESS: 'USER/NEW_PASS_SUCCESS',
  NEW_PASS_FAILED: 'USER/NEW_PASS_FAILED',
  SET_NEW_PASS_TOKEN: 'USER/SET_NEW_PASS_TOKEN',
  SET_USER_DETAILS: 'USER/SET_USER_DETAILS',
  SET_ACCOUNT_TYPE: 'USER/SET_ACCOUNT_TYPE',
  SET_WHAT_IS_MY_COUNTRY: 'USER/SET_WHAT_IS_MY_COUNTRY',
  FETCH_INVOICES: 'USER/FETCH_INVOICES',
  RETRIEVE_CURRENT_USER: 'USER/RETRIEVE_CURRENT_USER',
  RETRIEVE_CURRENT_COUNTRY: 'USER/RETRIEVE_CURRENT_COUNTRY',
};

// actions
export const actions = {
  setWhatIsMyCountry: (country: string) => ({ type: types.SET_WHAT_IS_MY_COUNTRY, payload: country }),
  socialAuthStart: (service, registered) => ({ type: types.SOCIAL_AUTH_START, service, registered }),
  register: user => ({ type: types.REGISTER_USER_START, payload: user }),
  registerFailed: err => ({ type: types.REGISTER_USER_FAILED, payload: err }),
  registerSuccess: user => ({ type: types.REGISTER_USER_SUCCESS, payload: user }),
  socialAuthSuccess: user => ({ type: types.SOCIAL_AUTH_SUCCESS, payload: user }),
  socialAuthFailed: user => ({ type: types.SOCIAL_AUTH_FAILED, payload: user }),
  initAuthentication: () => ({ type: types.CREATE_ACCOUNT_INIT }),
  login: user => ({ type: types.LOGIN_USER_START, payload: user }),
  loginSuccess: () => ({ type: types.LOGIN_USER_SUCCESS }),
  loginFailed: err => ({ type: types.LOGIN_USER_FAILED, payload: err }),
  resetPass: details => ({ type: types.RESET_PASS_START, payload: details }),
  resetPassSuccess: () => ({ type: types.RESET_PASS_SUCCESS }),
  resetPassFailed: message => ({ type: types.RESET_PASS_FAILED, payload: message }),
  newPass: details => ({ type: types.NEW_PASS_START, payload: details }),
  newPassSuccess: () => ({ type: types.NEW_PASS_SUCCESS }),
  newPassFailed: message => ({ type: types.NEW_PASS_FAILED, payload: message }),
  setNewPassToken: token => ({ type: types.SET_NEW_PASS_TOKEN, payload: token }),
  setUser: type => ({ type: types.SET_USER_DETAILS, payload: type }),
  setAccountType: isBusiness => ({ type: types.SET_ACCOUNT_TYPE, payload: isBusiness }),
  setInvoices: (invoices: Invoice[]) => ({ type: types.SET_INVOICES, payload: invoices }),
  fetchInvoices: () => ({ type: types.FETCH_INVOICES }),
  retrieveCurrentUser: () => ({ type: types.RETRIEVE_CURRENT_USER }),
  retrieveCurrentCountry: () => ({ type: types.RETRIEVE_CURRENT_COUNTRY }),
};

// selectors
export const getLoggedInUser = state => {
  return state.user;
};

export const getProfileImageSrc = state => {
  return _get(state.user, 'userData.profile_image') || missingImageSrc;
};

export const getIsAllowedToPayByInvoice = (state): boolean => {
  return _get(state.user, 'userData.account.paying_by_invoice', false);
};

export const getUserRoles = state => {
  return _get(state.user, 'userData.roles', []);
};

export const hasDriverRole = state => {
  const roles = getUserRoles(state);
  return roles.includes('ROLE_DRIVER');
};

export const getWhatIsMyCountry = (state: RootState): string => {
  return state.user.what_is_my_country;
};

export const isUserLoggedIn = state => {
  return state.user.loggedIn;
};

export const isRegistered = state => {
  return state.user.registered;
};

export const isSocialRegistration = state => {
  const role = ROLES.ROLE_NEEDS_SOCIAL_REGISTRATION;
  return _get(state.user.details, 'roles', false) && state.user.details.roles.indexOf(role) > -1;
};

export const getTotalNumberInvoices = (state: RootState): number => {
  return state.user.invoices.length;
};

// selectors
export const getResetToken = state => {
  return state.user.password_new.token;
};

// reducers
export const userReducer = (state = defaultState, action): User => {
  switch (action.type) {
    case types.CREATE_ACCOUNT_INIT:
      return {
        ...state,
        errors: '',
        loading: false,
        fetching: false,
        registered: false,
        password_reset: {
          ...state.password_reset,
          loading: false,
          error: '',
          success: false,
        },
        password_new: {
          ...state.password_new,
          loading: false,
          error: '',
          success: false,
        },
      };
    case types.SET_INVOICES:
      return {
        ...state,
        invoices: action.payload,
      };
    case types.SET_ACCOUNT_TYPE:
      return {
        ...state,
        isBusiness: action.payload === 'business',
      };
    case types.SET_WHAT_IS_MY_COUNTRY:
      return {
        ...state,
        what_is_my_country: action.payload,
      };
    case types.SET_USER_DETAILS:
      return {
        ...state,
        userType: action.payload['@type'],
        userData: action.payload,
        loading: false,
        loggedIn: true,
        errors: '',
      };
      break;
    case types.LOGIN_USER_START:
      return {
        ...state,
        loading: true,
        errors: '',
      };
    case types.LOGIN_USER_FAILED:
      return {
        ...state,
        errors: action.payload,
        loading: false,
        loggedIn: false,
        fetching: false,
        loggedInMethod: null,
        userType: null,
        userData: null,
        details: null,
      };
    case types.LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedIn: true,
        loggedInMethod: 'manual',
        errors: '',
      };
    case types.REGISTER_USER_START:
      return {
        ...state,
        loading: true,
        errors: '',
      };
    case types.REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.REGISTER_USER_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case types.SOCIAL_AUTH_START:
      return {
        ...state,
        fetching: true,
        errors: '',
        loggedInMethod: action.service,
        registered: action.registered,
      };
    case types.SOCIAL_AUTH_FAILED:
      return {
        ...state,
        fetching: false,
        errors: action.payload,
      };
    case types.SOCIAL_AUTH_SUCCESS:
      return {
        ...state,
        id: action.payload['@id'],
        fetching: false,
        errors: '',
        loggedIn: true,
        details: action.payload,
      };
    case types.RESET_PASS_START:
      return {
        ...state,
        password_reset: {
          ...state.password_reset,
          success: false,
          loading: true,
          error: '',
        },
      };
    case types.RESET_PASS_SUCCESS:
      return {
        ...state,
        password_reset: {
          ...state.password_reset,
          success: true,
          loading: false,
          error: '',
        },
      };
    case types.RESET_PASS_FAILED:
      return {
        ...state,
        password_reset: {
          ...state.password_reset,
          success: false,
          loading: false,
          error: action.payload,
        },
      };
    case types.NEW_PASS_START:
      return {
        ...state,
        password_new: {
          ...state.password_new,
          success: false,
          loading: true,
          error: '',
        },
      };
    case types.NEW_PASS_SUCCESS:
      return {
        ...state,
        password_new: {
          ...state.password_new,
          success: true,
          loading: false,
          error: '',
        },
      };
    case types.NEW_PASS_FAILED:
      return {
        ...state,
        password_new: {
          ...state.password_new,
          success: false,
          loading: false,
          error: action.payload,
        },
      };
    case types.SET_NEW_PASS_TOKEN:
      return {
        ...state,
        password_new: {
          ...state.password_new,
          token: action.payload,
        },
      };
    default:
      return state;
  }
};

const persistConfig = {
  key: 'user',
  blacklist: ['password_new', 'password_reset'],
  version: _PERSIST_VERSION,
  storage,
};

export default persistReducer(persistConfig, userReducer);

import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { Dispatch } from 'redux';
import { actions as bvaActions, getPaymentInfo, getPaymentMethods } from '../../Payment/ducks';
import { connect } from 'react-redux';
import '../../../assets/payment/payment-details.css';
import '../../../assets/BVA/thank-you.css';
import { Col, IconSmile, Row, SectionWrapper } from '../../../brenger-shared-ui';
import { Translate } from 'react-localize-redux';
import { PaymentEventNames, RETRY_PAYMENT_STATUS } from '../typings';
import { REDIRECT_URLS } from '../../../utils/global';
import PaymentError from '../../Payment/components/PaymentError';
import BvaPaymentSuccess from '../../BvaFlow/components/PaymentSuccess';
import IkeaPaymentSuccess from '../../IkeaFlow/components/PaymentSuccess';
import GeneralPaymentSuccess from '../../GeneralFlow/components/PaymentSuccess';
import { PaymentRetry } from '../../Payment/components/PaymentRetry';
import { push } from 'connected-react-router';
import { _FLOW_TYPE as flowTypeIKEA } from '../../IkeaFlow';
import { _FLOW_TYPE as flowTypeGeneral } from '../../GeneralFlow';
import { _FLOW_TYPE as flowTypeBVA } from '../../BvaFlow';
import { _FLOW_TYPE as flowTypeVavato } from '../../VavatoFlow';
import { pushToDataLayer } from '../../../utils/basics';
import { LocalStorageKeys, RootState } from '../../../typings';
import { _ACTIVE_LANG } from '../../../configs/localization';

interface State {
  retryPayment: boolean;
}

// @TODO: use proper redux form props here eventually.
interface MockedFormProps {
  valid: boolean;
  submitting: boolean;
  reset(): void;
  handleSubmit(): void;
}

type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
type OwnProps = RouteComponentProps<{}>;

export type AllProps = ReduxProps & OwnProps & MockedFormProps;

class ThankYou extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      retryPayment: false,
    };
  }

  public toggleRetryPayment() {
    this.setState({ retryPayment: !this.state.retryPayment });
  }

  public componentWillMount() {
    // before we do anything, lets check if we are loading the prefered language
    // We do this for the thank you page only
    const preferredLanguage = localStorage.getItem(LocalStorageKeys.LANGUAGE_PREFERENCE);

    if (!preferredLanguage || preferredLanguage === _ACTIVE_LANG) {
      return;
    }
    // We are not, so we redirect the user, strip pathname, and replace with prefered language.
    const pathName: string[] = window.location.pathname.split('/');
    pathName.splice(0, 2);
    // remove the item, because we handled it now and we dont want any side effects
    localStorage.removeItem(LocalStorageKeys.LANGUAGE_PREFERENCE);
    window.location.href = ['/' + preferredLanguage, ...pathName].join('/');
  }
  public componentDidUpdate(prevProps) {
    if (this.props.paymentInfo.state === null || this.props.paymentInfo.polling === true) {
      return;
    }
    if (this.props.paymentInfo.state === prevProps.paymentInfo.state) {
      return;
    }
    // Track payment state whenever payment info is resolved by Mollie,
    // The payment state could still be in RETRY | FAILED state, or COMPLETE if successful
    this.trackPaymentState();
  }

  public renderPaymentStatus() {
    if (this.props.paymentInfo.error && !this.state.retryPayment) {
      return <PaymentError toggleRetryPayment={() => this.toggleRetryPayment()} />;
    }

    if (this.props.paymentInfo.state === 'paid') {
      if (this.props.match.path === REDIRECT_URLS.IKEA_FLOW) {
        return <IkeaPaymentSuccess />;
      }
      if (this.props.match.path === REDIRECT_URLS.GENERAL_FLOW) {
        return <GeneralPaymentSuccess {...this.props} />;
      }
      if (this.props.match.path === REDIRECT_URLS.BVA_FLOW) {
        return <BvaPaymentSuccess />;
      }
    }

    if (
      (this.props.paymentInfo.state && RETRY_PAYMENT_STATUS.includes(this.props.paymentInfo.state)) ||
      this.state.retryPayment
    ) {
      return (
        <PaymentRetry
          submitting={this.props.submitting}
          valid={this.props.valid}
          reset={this.props.reset}
          handleSubmit={this.props.handleSubmit}
          paymentInfo={this.props.paymentInfo}
          paymentMethods={this.props.paymentMethods}
        />
      );
    }

    return (
      <SectionWrapper sectionStyle={'white'} extraClasses={'profile-dashboard-wrapper'} centerContent={true}>
        <Row>
          <Col xs={12} md={8} extraClasses={'offset-md-2 pb-1'}>
            <IconSmile spinning={true} /> <Translate id={'request_flow.thank_you.verifying_payment'} />
          </Col>
        </Row>
      </SectionWrapper>
    );
  }
  public render() {
    return <Col xs={12}>{this.renderPaymentStatus()}</Col>;
  }

  public trackPaymentState() {
    if (!this.props.paymentInfo.id) {
      return;
    }
    // get saved id's, to be checked later
    const localpaymentEventIds = localStorage.getItem(LocalStorageKeys.PAYMENT_EVENT_IDS);
    const paymentEventIds: string[] = localpaymentEventIds ? JSON.parse(localpaymentEventIds) : [];

    // get event name
    const eventName = this.getEventName();
    const event = {
      event: eventName,
    };
    // set the type
    let flowType = '';
    if (this.props.match.path === REDIRECT_URLS.GENERAL_FLOW) {
      flowType = flowTypeGeneral;
    }
    if (this.props.match.path === REDIRECT_URLS.BVA_FLOW) {
      flowType = flowTypeBVA;
    }
    if (this.props.match.path === REDIRECT_URLS.VAVATO_FLOW) {
      flowType = flowTypeVavato;
    }
    if (this.props.match.path === REDIRECT_URLS.IKEA_FLOW) {
      flowType = flowTypeIKEA;
    }
    if (this.props.paymentInfo.error) {
      event['payment_error'] = this.props.paymentInfo.error;
    }
    event['flow_type'] = flowType;

    // Fire GA event every time we did not track it as purchase
    if (!paymentEventIds.includes(this.props.paymentInfo.id)) {
      pushToDataLayer(event);
    }

    // Decide if we need to track it as e-comm transaction event
    if (eventName !== PaymentEventNames.COMPLETE || paymentEventIds.includes(this.props.paymentInfo.id)) {
      return;
    }

    // e-comm transaction event
    pushToDataLayer({
      event: 'purchase',
      transactionId: this.props.paymentInfo.id,
      transactionAffiliation: flowType,
      transactionTotal: (Number(this.props.paymentInfo.amount) / 100).toFixed(2),
      transactionProducts: [
        {
          sku: flowType,
          name: 'Transport request',
          price: String((Number(this.props.paymentInfo.amount) / 100).toFixed(2)),
          quantity: 1,
        },
      ],
    });

    // save the the ID to storage
    localStorage.setItem(
      LocalStorageKeys.PAYMENT_EVENT_IDS,
      JSON.stringify([...paymentEventIds, this.props.paymentInfo.id])
    );
  }

  public getEventName(): PaymentEventNames {
    if (this.props.paymentInfo.state === 'paid') {
      return PaymentEventNames.COMPLETE;
    }
    if (this.props.paymentInfo.error) {
      return PaymentEventNames.FAILED;
    }
    return PaymentEventNames.RETRY;
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
  uuid: state.generalTransport.request.uuid,
  paymentForm: state.form.paymentForm,
  paymentInfo: getPaymentInfo(state),
  paymentMethods: getPaymentMethods(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createAccount: (uuid: string) => dispatch(push(`/register?transport_request=${uuid}`)),
  goToTransportStatus: (uuid: string) => dispatch(push(`/transport_request/track/${uuid}`)),
  trackPage: (uuid: string) => dispatch(push(`/track_and_trace/${uuid}`)),
});

const form = reduxForm({
  form: 'paymentForm', // same as normal payment form
  initialValues: {},
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmit: (payment, dispatch: Dispatch) => dispatch(bvaActions.submitPaymentRetry(payment)),
});

export default connect(mapStateToProps, mapDispatchToProps)(form(ThankYou));

import React from 'react';
import { Field } from 'redux-form';
import { Translate } from 'react-localize-redux';
import {
  FlashMessage,
  Heading,
  IconAuction,
  IconSmile,
  InputTiles,
  SheetLayoutContent,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { pickupDeliverySchema } from '../schema';
import '../../../assets/auctions/auctions.css';
import vavatoLogo from '../../../assets/auctions/vavato.png';
import bvaLogo from '../../../assets/auctions/bva.png';
import belgaLogo from '../../../assets/auctions/belga.jpg';
import onlineveilingmeesterLogo from '../../../assets/auctions/onlineveiling.png';
import troostwijkLogo from '../../../assets/auctions/troostwijk.png';
import { AuctionTypes, SubStepFieldNamesPickup } from '../interface';
import { translate } from '../../../utils/localization';

interface Props {
  transport: any;
}

const SubStepAuctionTypes = ({ transport }: Props) => {
  const options = [
    {
      value: AuctionTypes.BVA,
      title: 'BVA Auctions',
      suffix: <IconSmile />,
      prefix: <img src={bvaLogo} alt={'bva'} />,
    },
    {
      value: AuctionTypes.TROOSTWIJK,
      title: 'Troostwijk',
      suffix: <IconSmile />,
      prefix: <img src={troostwijkLogo} alt={'troostwijk'} />,
    },
    {
      value: AuctionTypes.ONLINEVEILINGMEESTERS,
      title: 'Online Veilingmeester',
      suffix: <IconSmile />,
      prefix: <img src={onlineveilingmeesterLogo} alt={'onlineveilingmeester'} />,
    },
    {
      value: AuctionTypes.VAVATO,
      title: 'Vavato',
      suffix: <IconSmile />,
      prefix: <img src={vavatoLogo} alt={'vavato'} />,
    },
    {
      value: AuctionTypes.BELGA,
      title: 'Belga',
      suffix: <IconSmile />,
      prefix: <img src={belgaLogo} alt={'belga'} />,
    },
    {
      value: AuctionTypes.OTHERS,
      title: String(translate('request_flow.situation.other')),
      suffix: <IconSmile />,
      prefix: <IconAuction />,
    },
  ];
  return (
    <>
      <SheetLayoutHeader>
        <Heading size={2}>
          <Translate id={'request_flow.auction.select_auction'} />
        </Heading>
      </SheetLayoutHeader>
      <SheetLayoutContent>
        <div className={'auction-types question-wrapper'}>
          <Field
            name={SubStepFieldNamesPickup.auction_type}
            type="select"
            validate={pickupDeliverySchema.auction_type}
            options={options}
            component={InputTiles}
          />
        </div>
        <div className={'general-errors-wrapper'}>
          {transport.errors && transport.errors !== '' && <FlashMessage type={'error'} message={transport.errors} />}
        </div>
      </SheetLayoutContent>
    </>
  );
};

export default SubStepAuctionTypes;

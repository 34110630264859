import * as React from 'react';
import './progresssteps.css';
import { IconCross } from '../../basics/icons';

interface Props {
  currentStep: number;
  totalSteps: number;
  exitUrl?: any;
}

export const ProgressSteps = ({ currentStep, totalSteps, exitUrl }: Props) => {
  return (
    <div className={'progress-steps' + (exitUrl ? ' progress-steps--with-exit' : '')}>
      {Array(totalSteps)
        .fill('')
        .map((el, index) => (
          <div
            className={
              'progress-step' +
              ' progress-step--' +
              (index + 1 < currentStep ? 'done' : '') +
              (index + 1 === currentStep ? 'active' : '')
            }
            key={'progress_step_' + index}
          >
            {index + 1}
          </div>
        ))}
      / {totalSteps}
      {exitUrl && (
        <span className={'progress-steps--exit'} onClick={exitUrl}>
          <IconCross />
        </span>
      )}
    </div>
  );
};
export default ProgressSteps;

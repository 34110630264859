import * as React from 'react';
import './toast.css';
import { IconCross } from '../../basics/icons';

interface Props {
  prefix?: any;
  suffix?: any;
  handleClose?: any;
  children: any;
}

export const Toast = ({ prefix, suffix, handleClose, children }: Props) => {
  return (
    <div className={'toast'}>
      <div className={'toast--wrapper'}>
        {prefix && <div className={'toast--prefix'}>{prefix}</div>}
        <div className={'toast--content'}>{children}</div>
        {suffix && <div className={'toast--suffix'}>{suffix}</div>}
        {handleClose && (
          <div className={'toast--close trigger'} onClick={handleClose}>
            <IconCross />
          </div>
        )}
      </div>
    </div>
  );
};
export default Toast;

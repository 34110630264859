import React from 'react';
import { Card, IconCheckmark, Label } from '../../../brenger-shared-ui';
import '../../../assets/general-flow/packages.scss';
import { Translate } from 'react-localize-redux';
import { LabelProps } from '../../../brenger-shared-ui/components/basics/label/Label';

export interface PackageOptionProps {
  title: string;
  price: any;
  labelValue: string;
  usps: string[];
  subTitleModal: string;
  openModal: any;
  isSelected: boolean;
}

export type PackageOptionType = PackageOptionProps & Pick<LabelProps, 'type'>;

const PackageOption = ({
  title,
  subTitleModal,
  price,
  labelValue,
  type,
  usps,
  openModal,
  isSelected,
}: PackageOptionType) => {
  return (
    <Card extraClasses={'package' + (isSelected ? ' isSelected' : '')} onClick={openModal}>
      <div>
        <Label type={type}>{labelValue}</Label>
      </div>
      <div className={'package--header'}>
        <div className={'package--header--text'}>
          <div className={'package--header--text--title'}>{title}</div>
        </div>
      </div>
      <div className={'package--header--text--subtitle'}>{subTitleModal}</div>
      <div className={'package--bottom'}>
        <div className={'package--usps'}>
          <>
            {usps.map((item, index) => {
              return (
                <div className={'package--usps--item'} key={index}>
                  <div className={'package--usps--item--icon'}>
                    <IconCheckmark />
                  </div>
                  <div className={'package--usps--item--text'}>{item}</div>
                </div>
              );
            })}
          </>
        </div>
        <div className={'package--select'}>
          <Translate id={'request_flow.packages.view_package'} />
        </div>
      </div>
    </Card>
  );
};

export default PackageOption;

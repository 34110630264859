/*
/* BRENGER LOCALIZATION UTILS
/* ================================================== */

import { getTranslate } from 'react-localize-redux';
import { store } from '../';

export const translate = (translateKey: string, data = {}): string => {
  return getTranslate(store.getState().localize)(translateKey, data);
};

import * as React from 'react';
import InputWrapper from '../input/InputWrapper';
import InputFeedback from '../input/InputFeedback';
import { InputOptionsProps } from '../inputbuttonselect/InputButtonSelect';
import classNames from 'classnames';
interface CustomProps {
  optionsInline: boolean;
}

type Props = InputOptionsProps & CustomProps;

const InputRadio: React.FC<Props> = ({ input, extraClasses, meta, successMessage, optionsInline, options }) => {
  return (
    <InputWrapper
      input={...input}
      meta={...meta}
      extraClasses={classNames(extraClasses, { 'input-el--radio--inline': optionsInline })}
      type={'radio'}
    >
      <div className="input-el--option--list">
        {options.map((option, index) => {
          return (
            <div key={index} className="input-el--option--item">
              <input
                id={input.name + '_' + index}
                type={'radio'}
                name={input.name}
                onChange={() => input.onChange(option.value)}
                checked={input.value === option.value}
                value={option.value}
              />
              <label className={'input-el--label'} htmlFor={input.name + '_' + index}>
                {option.label}
              </label>
            </div>
          );
        })}
      </div>
      <InputFeedback meta={...meta} error={meta.error} successMessage={successMessage} />
    </InputWrapper>
  );
};

export default InputRadio;

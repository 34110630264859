import {
  Delivery,
  // ItemSet
} from '@brenger/api-client';
// import {ItemSet} from '..'
import { Nullable, PriceDataPart } from '../../typings';
/**
 * INTERFACES
 */

export interface StatusPage {
  id: Nullable<string>;
  short_id: Nullable<string>;
  pickup: Nullable<any>;
  delivery: Delivery | null;
  customer: Nullable<any>;
  item_sets: any;
  price: Nullable<PriceDataPart>;
  routeData: Nullable<any>;
  transport_job: Nullable<any>;
  failedLoadingTransportRequest: boolean;
  directly_claimable: Nullable<any>;
  transport_job_bundled: boolean;
  // accepted driver for the job
  confirmedAccountLink: Nullable<any>;
  // list of drivers who made an offer
  accountLinks: any[];
  // @TODO: make this an ENUM!
  source_flow: string;
  // list of transport jobs
  overview_list: {
    loading: boolean;
    results: any[];
    total: number;
  };
  total_number_trs: number;
  currentPage: number;
  cancelable: boolean;
  layout: {
    // FIXME: should not saved be here
    isLoggedIn: boolean;
    chatOpen: boolean;
    confirmOpen: boolean;
    isCancelLoading: boolean;
    cancelErrorText: string;
  };
}

export enum PresentationFilter {
  PUBLISHED = 'published_for_customer',
  DRIVER_CONFIRMED = 'driver_confirmed',
  DELIVERED = 'delivered',
}

export enum FilterFields {
  QUERY = 'query',
  ORDER_TYPE_NORMAL = 'order_type_normal',
  ORDER_TYPE_RETURN = 'order_type_return',
  DATE = 'date',
  PRESENTATION = 'presentation',
}

import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { push } from 'connected-react-router';
import { FieldArray, InjectedFormProps, reduxForm } from 'redux-form';
import { actions as bvaActions, getAuctionPickupDatetimePeriod, getAuctionTransportOrderDeadline } from '../ducks';
import RenderLotField from '../components/RenderLotField';
import RenderAuctionField from '../components/RenderAuctionField';
import { connect } from 'react-redux';
import {
  Button,
  Col,
  Container,
  Heading,
  IconArrow,
  PriceDisplay,
  Row,
  SheetLayoutContent,
  SheetLayoutContentWrapper,
  SheetLayoutFooter,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { Translate } from 'react-localize-redux';
import { BvaFlowForms } from '../interface';
import _get from 'lodash/get';
import { RootState } from '../../../typings';
import { auctionSchema } from '../schema';

type Props = InjectedFormProps<{}> & RouteComponentProps<{}>;

class AuctionForm extends React.Component<Props> {
  public render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <SheetLayoutContentWrapper>
          <SheetLayoutHeader>
            <Heading size={2}>
              <Translate id={'request_flow.headers.auction_lots'} />
            </Heading>
          </SheetLayoutHeader>
          <SheetLayoutContent>
            <>
              <div className={'pb-1'}>
                <Translate id={'request_flow.BVA.introduction'} />
              </div>
              {typeof this.props.auctionForm !== 'undefined' && (
                <RenderAuctionField form={this.props.auctionForm} auctionPickupDate={this.props.auctionPickupDate} />
              )}
              <FieldArray
                validate={auctionSchema.lots}
                name={'lots'}
                form={this.props.auctionForm}
                component={RenderLotField}
                generalErrors={this.props.transport.request.errors}
              />
              {_get(this.props.auctionForm, `values.lots[0].title`) && (
                <>
                  <div style={{ color: '#0073fa' }} className={'text--primary pt-1'}>
                    {' '}
                    <Translate
                      id={'request_flow.auction.pickup_deadline_information'}
                      data={{
                        date: this.props.auctionPickupDeadline.format('dddd DD MMMM YYYY'),
                        time: this.props.auctionPickupDeadline.format('H:mm'),
                      }}
                    />
                  </div>
                  <div style={{ color: '#0073fa' }} className={'text--primary pt-1'}>
                    {' '}
                    <Translate id={'request_flow.auction.max_dimensions'} />
                  </div>
                  <p>
                    <Translate id={'request_flow.BVA.invoice_warning'} />
                  </p>
                </>
              )}
              {this.props.transport.request.errors && (
                <p className={'escape-bva-link'} onClick={() => this.props.navigate('/transport_request')}>
                  <Translate id={'request_flow.auction.escape_to_normal_flow'} />
                </p>
              )}
            </>
          </SheetLayoutContent>
        </SheetLayoutContentWrapper>
        <SheetLayoutFooter>
          <Container>
            <Row>
              <Col xs={5}>
                <PriceDisplay header={<Translate id={'request_flow.price.total'} />} cents={this.props.price} />
              </Col>
              <Col xs={7}>
                <Button
                  loading={this.props.submitting}
                  disabled={this.props.submitting || this.props.transport.request.errors !== null}
                  buttonStyle={'secondary'}
                  iconPos={'right'}
                  fullWidth={true}
                >
                  <Translate id={'onboarding.step.next'} />
                  <IconArrow />
                </Button>
              </Col>
            </Row>
          </Container>
        </SheetLayoutFooter>
      </form>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  auctionForm: state.form[BvaFlowForms.auctionLotForm],
  auctionPickupDeadline: getAuctionTransportOrderDeadline(state),
  auctionPickupDate: getAuctionPickupDatetimePeriod(state).start,
  transport: state.bvaTransport,
  price: state.bvaTransport.request.price,
});

const mapDispatchToProps = dispatch => ({
  navigate: path => dispatch(push(path)),
});

export default reduxForm<{}, Props>({
  form: BvaFlowForms.auctionLotForm,
  destroyOnUnmount: false,
  forceUnregisterOnUmount: false,
  onSubmit: (lots, dispatch) => dispatch(bvaActions.submitLotsDetails(lots)),
})(connect(mapStateToProps, mapDispatchToProps)(AuctionForm));

/*
/* BRENGER VALIDATION UTILS
/* ================================================== */

import moment from 'moment-timezone';
import {
  _TIME_END_MAX,
  _TIME_END_MIN,
  _TIME_END_MIN_AUCTION,
  _TIME_SLOT_LENGTH,
  _TIME_SLOT_LENGTH_AUCTION,
  _TIME_START_MAX,
  _TIME_START_MIN,
} from './global';
import { translate } from './localization';

const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,24}$/;
export const atLeastOneNumberRegex = /^(.*[0-9].*)$/;
export const atLeastOneLetter = /[A-Z]/i;
export const addressRegexNL = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/;
export const postalCodeRegexNL = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;

// validations core methods
export const required = (value, msg) => (value ? undefined : msg || 'Required');

export const exactLength = (value, charLength, msg) =>
  value && value.toString().length !== charLength
    ? msg || translate('request_flow.fields.exact_length.valid', { charLength })
    : undefined;

export const maxLength = (value, max, msg) =>
  value && value.toString().length > max ? msg || `Must be ${max} characters or less` : undefined;

export const numeric = (value, msg) =>
  !isNaN(Number(value)) || Number(value) <= 0 ? undefined : msg || 'Must be a number';

export const numericMin = (value, minimum, msg) =>
  parseInt(value, 10) >= minimum ? undefined : msg || 'Must be a number';

export const minLength = (value, min, msg) =>
  value && value.toString().length < min ? msg || `Must be at least ${min}` : undefined;

export const email = (value, msg) =>
  value && !emailRegex.test(value.toLowerCase()) ? msg || 'Invalid email address' : undefined;

export const testRegex = (value, regexString, msg) => (value && !regexString.test(value) ? msg : undefined);

export const timeSlotStart = (value, endTime) => {
  if (!value) {
    return translate('form.fields.default.required', {
      field: translate('request_flow.time.fields.time_start.label'),
    });
  }
  if (value) {
    const momentStart = moment(value, 'HH:mm');
    const momentStartMin = moment().set({ hour: _TIME_START_MIN, minute: 0 });
    const momentStartMax = moment().set({ hour: _TIME_START_MAX, minute: 0 });
    if (momentStart.isBefore(momentStartMin, 'minutes')) {
      return translate('request_flow.time.fields.to_early', {
        field: translate('request_flow.time.fields.time_start.label'),
        time: _TIME_START_MIN + ':00',
      });
    }
    if (momentStart.isAfter(momentStartMax, 'minutes')) {
      return translate('request_flow.time.fields.to_late', {
        field: translate('request_flow.time.fields.time_start.label'),
        time: _TIME_START_MAX + ':00',
      });
    }
  }
  return undefined;
};

export const timeSlotEnd = (value, startTime, isAuction) => {
  if (!value && startTime) {
    return translate('form.fields.default.required', { field: translate('request_flow.time.fields.time_end.label') });
  }
  if (value) {
    const momentEnd = moment(value, 'HH:mm');
    if (startTime) {
      const momentStart = moment(startTime, 'HH:mm');
      const hourDiff = momentEnd.diff(momentStart, 'hours');
      const hourDiffLimit = isAuction ? _TIME_SLOT_LENGTH_AUCTION - 1 : _TIME_SLOT_LENGTH - 1;
      if (hourDiff <= hourDiffLimit) {
        return translate('request_flow.time.fields.time_slot', {
          hours: isAuction ? _TIME_SLOT_LENGTH_AUCTION : _TIME_SLOT_LENGTH,
        });
      }
    }
    const momentEndMin = moment().set({ hour: isAuction ? _TIME_END_MIN_AUCTION : _TIME_END_MIN, minute: 0 });
    const momentEndMax = moment().set({ hour: _TIME_END_MAX, minute: 0 });
    if (momentEnd.isBefore(momentEndMin, 'minutes')) {
      return translate('request_flow.time.fields.to_early', {
        field: translate('request_flow.time.fields.time_end.label'),
        time: (isAuction ? _TIME_END_MIN_AUCTION : _TIME_END_MIN) + ':00',
      });
    }
    if (momentEnd.isAfter(momentEndMax, 'minutes')) {
      return translate('request_flow.time.fields.to_late', {
        field: translate('request_flow.time.fields.time_end.label'),
        time: _TIME_END_MAX + ':00',
      });
    }
  }

  return undefined;
};

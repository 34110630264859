/*
/* BRENGER NORMAILISATION UTILS
/* ================================================== */

import { getCountryCode } from '../modules/GeneralFlow/schema';
import { capsFirstLetter } from './basics';

export const normalizePostalCode = (value, previousValue, allValues, previousAllValues) => {
  value = value.trim();
  if (!value) {
    return value;
  }
  const countryCode = getCountryCode(allValues);
  if (countryCode !== 'NL') {
    return value;
  }
  const lastTwoCharacters = value
    .slice(-2)
    .toUpperCase()
    .replace(' ', '');
  const firstPart = value.substr(0, value.length - 2).replace(' ', '');
  if (!lastTwoCharacters.match(/[a-z]{2}/i) || !firstPart.match(/[0-9]{4}/i)) {
    return value;
  }
  return `${firstPart} ${lastTwoCharacters}`;
};

export const normalizeFirstLetterCaps = value => {
  return capsFirstLetter(value);
};

import * as React from 'react';
import classNames from 'classnames';

import { IconSmile } from '../icons';
import './button.css';

interface Props {
  buttonStyle?: ButtonStyleOption;
  extraClasses?: string;
  centered?: boolean;
  size?: string;
  loading?: boolean;
  fullWidth?: boolean;
  iconPos?: 'left' | 'right';
  onClick?: any;
}

export type ButtonStyleOption = 'primary' | 'secondary' | 'tertiary' | 'outline' | 'delete' | 'grey' | 'is-disabled';

type AllProps = Props & React.ButtonHTMLAttributes<Props> & React.AnchorHTMLAttributes<Props>;

const Button: React.FC<AllProps> = ({
  buttonStyle = 'primary',
  extraClasses = '',
  href,
  size,
  loading = false,
  target,
  disabled = false,
  fullWidth = false,
  onClick,
  iconPos,
  children,
  type = 'submit',
}) => {
  const className = classNames(
    'btn',
    `btn--${buttonStyle}`,
    {
      ['btn--loading']: loading,
      [`btn--size-${size}`]: size,
      [`btn--icon-${iconPos}`]: iconPos,
      ['btn--is-disabled']: disabled,
      ['btn--full-width']: fullWidth,
    },
    extraClasses
  );

  if (href) {
    return (
      <a className={className} href={href} target={target} style={disabled ? { pointerEvents: 'none' } : {}}>
        {children}
        {loading && <IconSmile spinning={true} />}
      </a>
    );
  }

  return (
    <button className={className} onClick={onClick} disabled={disabled} type={type}>
      {children}
      {loading && <IconSmile spinning={true} />}
    </button>
  );
};
export default Button;

import * as React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { getChatOpenState } from '../ducks';
import { Modal } from '../../../brenger-shared-ui';
import { useTypedSelector } from '../../../hooks';

import { prefix } from '..';

export const SingleViewWrapper: React.FC = props => {
  const isLoggedIn = useTypedSelector(state => state.user.loggedIn);
  const isChatOpen = useTypedSelector(getChatOpenState);
  const dispatch = useDispatch();
  const onClick = () => dispatch(push(prefix));

  if (isLoggedIn) {
    return (
      <Modal modalStyle="statusPage" open={true} noShade={isChatOpen} onClick={onClick}>
        {props.children}
      </Modal>
    );
  }
  return <>{props.children}</>;
};

import React from 'react';
import { Dispatch } from 'redux';
import { Route } from 'react-router';
import {
  actions as flowActions,
  getAuctionPickupDateString,
  getChosenPackage,
  getFlowTitleByStepAndSituation,
  getStopDateSelectableOptions,
  isRequestAboveMaxDistance,
} from '../ducks';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Heading, SheetLayoutContentWrapper, SheetLayoutHeader } from '../../../brenger-shared-ui';
import { prefix } from '../index';
import SubStepDate from '../components/SubStepDate';
import FooterLayout from './FooterLayout';
import { GeneralFlowForms, PackageFieldsValues, SubStepFieldNamesPickup, TitleStepName } from '../interface';
import { push } from 'connected-react-router';
import { Translate } from 'react-localize-redux';
import { RootState, StopType } from '../../../typings';

interface OwnProps {
  // @TODO: replace this with injected form props when we have correct type.
  handleSubmit(): void;
}

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
type AllProps = OwnProps & ReduxType;

export const _DATE_PICKUP_PATH = `/date`;
export const _DATE_DELIVERY_PATH = `/date/delivery`;
export const _DATE_BVA_COLLECTION_PATH = '/date/bva_collection';

const DateContainer: React.FC<AllProps> = props => {
  React.useEffect(() => {
    props.setProgress(5);
  }, []);

  const isEconomic = props.chosenPackage === PackageFieldsValues.economic;

  return (
    <form onSubmit={props.handleSubmit}>
      <SheetLayoutContentWrapper>
        <Route
          exact={true}
          path={`${prefix}${_DATE_PICKUP_PATH}`}
          render={() => (
            <SubStepDate
              errors={props.errors}
              errorCode={props.errorCode}
              dateOptions={props.dateOptionsPickup}
              isPriceLoading={props.isPriceLoading}
              getEarlierDates={() => props.getEarlierDates(StopType.pickup)}
              showGetEarlierDatesButton={isEconomic}
              getMoreDates={() => props.getMoreDates(StopType.pickup)}
              isPickup={true}
              isAboveMaxDistance={props.isAboveMaxDistance}
              title={props.title(props.isAboveMaxDistance ? 'datePickupLongDistance' : 'datePickup')}
            />
          )}
        />
        <Route
          exact={true}
          path={`${prefix}${_DATE_DELIVERY_PATH}`}
          render={() => (
            <SubStepDate
              isPriceLoading={props.isPriceLoading}
              errors={props.errors}
              errorCode={props.errorCode}
              dateOptions={props.dateOptionsDelivery}
              showGetEarlierDatesButton={isEconomic}
              getEarlierDates={() => props.getEarlierDates(StopType.delivery)}
              getMoreDates={() => props.getMoreDates(StopType.delivery)}
              isPickup={false}
              title={props.title(props.isAboveMaxDistance ? 'dateDeliveryLongDistance' : 'dateDelivery')}
            />
          )}
        />
        <Route
          exact={true}
          path={`${prefix}${_DATE_BVA_COLLECTION_PATH}`}
          render={() => (
            <>
              <SheetLayoutHeader>
                <Heading size={2}>
                  <Translate id={'request_flow.BVA.integration.pickup_date_auction'} />
                </Heading>
                <p>{props.auctionPickupDate}</p>
                <p className={'another-collection-date'} onClick={() => props.navigateTo(`${prefix}/date`)}>
                  <Translate id={'request_flow.BVA.integration.other_pickup_date'} />
                </p>
              </SheetLayoutHeader>
              <SubStepDate
                errors={props.errors}
                errorCode={props.errorCode}
                dateOptions={props.dateOptionsDelivery}
                showGetEarlierDatesButton={false}
                getEarlierDates={() => props.getEarlierDates(StopType.delivery)}
                getMoreDates={() => props.getMoreDates(StopType.delivery)}
                isPriceLoading={props.isPriceLoading}
                isPickup={false}
                hasDifferentDeliveryOption={false}
                title={props.title(props.isAboveMaxDistance ? 'dateDeliveryLongDistance' : 'dateDelivery')}
              />
            </>
          )}
        />
        <FooterLayout />
      </SheetLayoutContentWrapper>
    </form>
  );
};

const mapStateToProps = (state: RootState) => ({
  errors: state.generalTransport.errors,
  isPriceLoading: state.generalTransport?.price?.isLoading,
  auctionPickupDate: getAuctionPickupDateString(state),
  errorCode: _get(state.form[GeneralFlowForms.pickup], 'values.pickup.errorCode', ''),
  chosenPackage: getChosenPackage(state),
  dateOptionsPickup: getStopDateSelectableOptions(state, StopType.pickup),
  dateOptionsDelivery: getStopDateSelectableOptions(state, StopType.delivery),
  title: (stepName: TitleStepName) => getFlowTitleByStepAndSituation(state, stepName),
  isAboveMaxDistance: isRequestAboveMaxDistance(state, true),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setProgress: (step: number) => dispatch(flowActions.setProgress(step)),
  getMoreDates: (stopType: StopType) => dispatch(flowActions.getMoreDates(stopType)),
  getEarlierDates: (stopType: StopType) => dispatch(flowActions.getEarlierDates(stopType)),
  navigateTo: (path: string) => dispatch(push(path)),
});

export default reduxForm({
  form: GeneralFlowForms.date,
  destroyOnUnmount: false,
  initialValues: {
    [SubStepFieldNamesPickup.instant_booking]: false,
  },
  onSubmit: (formResults: any, dispatch: Dispatch) => dispatch(flowActions.submitDate(formResults)),
})(connect(mapStateToProps, mapDispatchToProps)(DateContainer));

import * as React from 'react';
import './card.css';
import './card-profile.css';
import { missingImageSrc } from '../../../global';
import { getBooleansForRenderingStars } from '../../../../utils/reviews';
import { IconStar } from '../../basics/icons';
import { Translate } from 'react-localize-redux';

interface Props {
  extraClasses?: string;
  header: string;
  phone?: string;
  image?: string;
  email?: string;
  reviewScore?: number;
  footer?: any;
  children?: any;
  onClick?: any;
  rate?: number;
  vehicle?: string;
}

export const CardProfile = ({
  extraClasses = '',
  header,
  phone,
  image,
  vehicle,
  rate,
  email,
  reviewScore,
  footer,
  children,
  onClick,
}: Props) => {
  return (
    <div className={'card card-profile card--shade-2 ' + extraClasses + (onClick ? ' trigger' : '')} onClick={onClick}>
      <div className={'card-profile--content-wrapper'}>
        <div className={'card-profile--header'}>
          <div className={'card-profile--header-text'}>{header}</div>
          {reviewScore && (
            <div className={'card-profile--header-stars'}>
              {getBooleansForRenderingStars(reviewScore).map((starFull, index) => (
                <IconStar key={index} full={starFull} />
              ))}
            </div>
          )}
        </div>
        <div className={'card-profile--content-text'}>
          <>
            {children && children}
            {rate && (
              <p>
                <Translate id={'profile_page.rating'} />: {rate}/5
              </p>
            )}
            {vehicle && <p>{vehicle}</p>}
            {email && (
              <>
                <Translate id={'form.fields.user.email.label'} />: &nbsp;
                <a href={'email:' + email}>{email}</a>
              </>
            )}
            {phone && (
              <div>
                <Translate id={'form.fields.user.phone.label'} />: &nbsp;
                <a href={'tel:' + phone}>{phone}</a>
              </div>
            )}
          </>
        </div>
      </div>
      <div
        className="card-profile--image-wrap"
        style={{ backgroundImage: `url(${image ? image : missingImageSrc})` }}
      />
      {footer && <div className={'card-profile--footer'}>{footer}</div>}
    </div>
  );
};
export default CardProfile;

import React from 'react';
import { Button, Modal } from '../../../brenger-shared-ui';
import { modalStyleOptions } from '../../../brenger-shared-ui/components/notice/modal/Modal';
import '../../../assets/general-flow/packages.scss';
import { Translate } from 'react-localize-redux';

interface service {
  icon: any;
  text: string;
}

export interface PackageModalProps {
  title: string;
  subTitle: string;
  services: service[];
  modalIsOpen: boolean;
  cancel: any;
}

const PackageModal = ({ title, subTitle, services, modalIsOpen, cancel }: PackageModalProps) => {
  return (
    <Modal modalStyle={modalStyleOptions.white} open={modalIsOpen} onClick={cancel}>
      <div className={'package-modal'}>
        <div className={'package-modal--text-title'}>{title}</div>
        <div className={'package-modal--text-subtitle'}>{subTitle}</div>
        <div className={'package-modal--specs'}>
          {services.map((item, index) => {
            return (
              <ul className={'package-modal--specs--item'} key={index}>
                <li className={'package-modal--specs--item-text'}>{item.text}</li>
              </ul>
            );
          })}
        </div>
        <div className={'package-modal--actions'}>
          <Button buttonStyle={'secondary'} fullWidth={true}>
            <Translate id={'request_flow.packages.modal_select'} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PackageModal;

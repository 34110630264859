import React from 'react';
import { connect } from 'react-redux';
import { Heading, Label } from '../../../brenger-shared-ui';
import { Translate } from 'react-localize-redux';
import RenderMap from '../components/RenderMap';
import '../../../assets/status-page/opening.css';
import _get from 'lodash/get';
import { translate } from '../../../utils/localization';
import { getIsBusinessReturnOrder, getStopTypeDetails, getTransportRequestStateLabel } from '../ducks';
import { stopTypeDetails } from '../interface';
import { ItemSetState, RootState, StopType } from '../../../typings';

interface Props {
  pickup: stopTypeDetails;
  delivery: stopTypeDetails;
  routeData?: any;
  stateLabel: string;
  isBusinessReturnOrder: boolean;
}

const TransportRequestHeadMap: React.FC<Props> = ({
  pickup,
  delivery,
  routeData,
  stateLabel,
  isBusinessReturnOrder,
}) => {
  return (
    <div className={'status-page-opening'}>
      <div className={'status-page-opening--header'}>
        <Heading size={2}>
          <Translate id={'request_flow.headers.transport_request_title'} />
        </Heading>
      </div>
      <div className={'status-page-opening--status'}>
        <Label type={stateLabel === ItemSetState.CANCELLED ? 'red' : 'green'} caps={true}>
          {String(translate('request_flow.state.' + stateLabel)).toUpperCase()}
        </Label>
        {isBusinessReturnOrder && (
          <Label type={'blue'}>{String(translate('request_flow.business.return_label')).toUpperCase()}</Label>
        )}
      </div>
      <RenderMap routeData={routeData} pickup={_get(pickup, 'address', {})} delivery={_get(delivery, 'address', {})} />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  pickup: getStopTypeDetails(state, StopType.pickup),
  delivery: getStopTypeDetails(state, StopType.delivery),
  routeData: state.statusPage.routeData,
  stateLabel: getTransportRequestStateLabel(state),
  isBusinessReturnOrder: getIsBusinessReturnOrder(state),
});

export default connect(mapStateToProps)(TransportRequestHeadMap);

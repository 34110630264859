import * as React from 'react';
import './heading.css';

interface Props {
  size: number;
  children: React.ReactNode;
  extraClasses?: string;
  onClick?(): void;
}

export const Heading = ({ size, children, extraClasses = '', onClick }: Props) => {
  switch (size) {
    case 2: {
      return (
        <h2 className={extraClasses} onClick={onClick}>
          {children}
        </h2>
      );
    }
    case 3: {
      return (
        <h3 className={extraClasses} onClick={onClick}>
          {children}
        </h3>
      );
    }
    case 4: {
      return (
        <h4 className={extraClasses} onClick={onClick}>
          {children}
        </h4>
      );
    }
    case 5: {
      return (
        <h5 className={extraClasses} onClick={onClick}>
          {children}
        </h5>
      );
    }
    case 1:
    default: {
      return (
        <h1 className={extraClasses} onClick={onClick}>
          {children}
        </h1>
      );
    }
  }
};
export default Heading;

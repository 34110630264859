import * as React from 'react';
import './flashmessage.css';

type messageType = 'success' | 'info' | 'info-light' | 'error';
interface Props {
  type: messageType;
  message?: string;
}

export const FlashMessage: React.FC<Props> = ({ type, message, children }) => {
  if (message && (message.indexOf('</') > 0 || message.indexOf('/>') > 0)) {
    return <p className={'message message--' + type} dangerouslySetInnerHTML={{ __html: message }} />;
  } else if (children) {
    return <p className={'message message--' + type}>{children}</p>;
  } else {
    return <p className={'message message--' + type}>{message}</p>;
  }
};
export default FlashMessage;

import React from 'react';
import { connect } from 'react-redux';

import { IconSmile } from '../../../brenger-shared-ui';
import TransportRequestHeadMap from './TransportRequestHeadMap';
import TransportRequestHead from './TransportRequestHead';
import TransportRequestBody from './TransportRequestBody';
import { ConfirmDeliveryButton } from '../components/ConfirmDeliveryButton';
import {
  actions,
  getIsDriverFound,
  getStopTypeDetails,
  getTransportRequestIsDeliveredForCustomer,
  getTransportRequestState,
} from '../ducks';
import { RootState, StopType } from '../../../typings';
import { SingleViewWrapper } from './SingleViewWrapper';

import '../../../assets/status-page/opening.css';

type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
type AllProps = ReduxProps;

const Item: React.FC<AllProps> = props => {
  const isConfirmDeliveryButtonVisible = props.delivery && props.isDriverFound && !props.isDeliveredCustomer;
  return (
    <SingleViewWrapper>
      {!props.isJobLoaded ? (
        <div className="loading-overview-transport-job">
          {props.failedJob ? (
            <>
              {/* @TODO: requires translation strings */}
              <h3>Transport request fetching failed!</h3>
              <p>It seems your transport data has some issues. Please try again...</p>
            </>
          ) : (
            <IconSmile spinning={true} />
          )}
        </div>
      ) : (
        <>
          <TransportRequestHeadMap />
          <TransportRequestHead />
          <TransportRequestBody />
          {/* Render sticky button when customer needs to confirm delivery */}
          {isConfirmDeliveryButtonVisible && (
            <>
              {/* Drop in a div to offset space needed by the fixed confirm delivery button */}
              <ConfirmDeliveryButton onClick={props.setConfirmOpen} />
            </>
          )}
        </>
      )}
    </SingleViewWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  delivery: getStopTypeDetails(state, StopType.delivery),
  transportRequestState: getTransportRequestState(state),
  isDriverFound: getIsDriverFound(state),
  isDeliveredCustomer: getTransportRequestIsDeliveredForCustomer(state),
  isJobLoaded: !!state.statusPage.id && !!state.statusPage.item_sets.length,
  transport_request: state.statusPage,
  failedJob: state.statusPage.failedLoadingTransportRequest,
});

const mapDispatchToProps = dispatch => ({
  setConfirmOpen: () => dispatch(actions.setConfirmOpen(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Item);

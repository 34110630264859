import React from 'react';
import { connect } from 'react-redux';
import { ProgressHeader, SheetLayout, SheetLayoutContentWrapper, StepIndicator } from '../../../brenger-shared-ui';
import { Redirect, Route, Switch } from 'react-router';
import { translate } from '../../../utils/localization';
import { Translate } from 'react-localize-redux';
import ModalLayout from '../../GeneralFlow/containers/ModalLayout';
import { prefix } from '../';
import ItemsAndServices from './ItemsAndServices';
import TransportPreview from './TransportPreview';
import Destination from './Destination';
import { actions, getDashboardLink, getPrice, getProgressStep, getSheetState } from '../ducks';
import { goBack, push } from 'connected-react-router';
import { SidebarLayout } from '../components/SidebarLayout';

interface TransportRequestProps {
  isSheetOpen: boolean;
  step: number;
  price: number;
}
type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type Props = TransportRequestProps & ReduxProps;

export const _FLOW_TYPE = 'Business';

class TransportRequest extends React.Component<Props> {
  public componentDidMount() {
    this.props.getDateTimePeriodOptions();
    this.props.fetchAddresses();
  }

  public render() {
    return (
      <>
        <ProgressHeader
          goBack={this.props.goBack}
          goBackText={String(translate('nav.previous'))}
          goBackAlwaysAvailable={true}
          currentStep={this.props.step}
          totalSteps={4}
          exitUrl={() => this.props.goToPath(this.props.dashboardLink)}
          logoUrl={event => {
            event.preventDefault();
            this.props.goToPath(this.props.dashboardLink);
          }}
        />
        <SheetLayout sheetOpen={this.props.isSheetOpen} sidebar={<SidebarLayout price={this.props.price} />}>
          <SheetLayoutContentWrapper className={'sheet-layout--content-wrapper--top-header'}>
            <div className={'sheet-layout--container'}>
              <StepIndicator currentStep={this.props.step}>
                <Translate id={'request_flow.business.step_' + this.props.step + '.title_small'} />
              </StepIndicator>
            </div>
          </SheetLayoutContentWrapper>
          <Switch>
            <Route path={`${prefix}/transport_request/destination`} exact={true} component={Destination} />
            <Route path={`${prefix}/transport_request/items`} exact={true} component={ItemsAndServices} />
            <Route path={`${prefix}/transport_request/preview`} exact={true} component={TransportPreview} />
            <Redirect to={`${prefix}/transport_request/destination`} />
          </Switch>
        </SheetLayout>
        <ModalLayout />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isSheetOpen: getSheetState(state),
  step: getProgressStep(state),
  price: getPrice(state, false),
  dashboardLink: getDashboardLink(state),
});

const mapDispatchToProps = dispatch => ({
  goBack: () => dispatch(goBack()),
  goToPath: path => dispatch(push(path)),
  getDateTimePeriodOptions: () => dispatch(actions.getDateTimePeriodOptions()),
  fetchAddresses: () => dispatch(actions.fetchAddressesStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransportRequest);

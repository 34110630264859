import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import {
  Heading,
  InputTiles,
  SheetLayoutContent,
  SheetLayoutContentWrapper,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { Translate } from 'react-localize-redux';
import { getSelectedStoreData, getStoreSpecificDate } from '../ducks';
import { IkeaFlowFields, IkeaFlowForms } from '../interface';
import { push } from 'connected-react-router';
import { prefix } from '..';
import FooterLayout from './FooterLayout';
import { useTypedSelector } from '../../../hooks';
import { pickupSchema } from '../schema';
import { getDayFromNow } from '../../../utils/datetime';

interface Props {
  submitting: boolean;
  handleSubmit(): void;
  reset(): void;
}

interface dateOption {
  value: string;
  title: string;
}

const DateSelection: React.FC<Props> = props => {
  const startDate = useTypedSelector(getStoreSpecificDate);
  const storeData = useTypedSelector(getSelectedStoreData);
  const selectableDatesAhead = storeData?.conditions?.selectableDates || 1;

  const dateOptions: dateOption[] = [];

  let index = 0;
  do {
    const date = startDate.add(index, 'day');
    dateOptions.push({
      value: date.format(),
      title: `${getDayFromNow(date)} ${date.format('DD MMMM')}`,
    });
    index++;
  } while (index < selectableDatesAhead);

  return (
    <form onSubmit={props.handleSubmit}>
      <SheetLayoutContentWrapper>
        <SheetLayoutHeader>
          <Heading size={2}>
            <Translate id={'request_flow.IKEA.date.header'} />
          </Heading>
        </SheetLayoutHeader>
        <SheetLayoutContent>
          <p>
            <Translate
              id={'request_flow.IKEA.date.text'}
              data={{
                start_time: storeData?.conditions?.delivery?.start_time + ':00',
                end_time: storeData?.conditions?.delivery?.end_time + ':00',
              }}
            />
          </p>
          <Field
            validate={pickupSchema.date}
            name={IkeaFlowFields.DATE}
            options={dateOptions}
            compact={true}
            maxDelayIndex={7}
            component={InputTiles}
          />
        </SheetLayoutContent>
        <FooterLayout submitting={props.submitting} />
      </SheetLayoutContentWrapper>
    </form>
  );
};

export default reduxForm({
  form: IkeaFlowForms.DATE,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmit: (details, dispatch) => dispatch(push(`${prefix}/delivery_details`)),
})(connect()(DateSelection));

import React from 'react';
import { Translate } from 'react-localize-redux';
import { getLocation } from 'connected-react-router';
import classNames from 'classnames';
import { QuoteNotGuaranteedReason } from '@brenger/api-client';

import { useTypedSelector } from '../../../../hooks';
import { CirclePerson2, Toast } from '../../../../brenger-shared-ui';
import { useNotification } from '../../../../hooks/useNotification';

interface INotGuaranteedValue {
  activePathnames: string[];
  isBlocked: boolean;
}

const reasonMap: { [key in QuoteNotGuaranteedReason]: INotGuaranteedValue } = {
  'area.outside_guaranteed': {
    activePathnames: ['items', 'auctions/bva'],
    isBlocked: false,
  },
  'area.directly_payable_not_guaranteed': {
    activePathnames: ['items'],
    isBlocked: false,
  },
  'item.over_200kg': {
    activePathnames: ['packages', 'weight'],
    isBlocked: true,
  },
  'item.too_many': {
    activePathnames: ['packages', 'weight'],
    isBlocked: true,
  },
  'item.too_large': {
    activePathnames: ['items', 'packages', 'weight'],
    isBlocked: true,
  },
  'extra_care.not_guaranteed': {
    activePathnames: ['items'],
    isBlocked: false,
  },
  'pickup.date.holiday': {
    activePathnames: ['contact', 'time', 'help'],
    isBlocked: false,
  },
};

/**
 * This notification responds directly to the non-guaranteed reasons returned by
 * the Pricing API.
 */
export const NotGuaranteedNotification: React.FC = () => {
  const notification = useNotification('not-guaranteed');
  const { pathname } = useTypedSelector(getLocation);
  const quote = useTypedSelector(state => state.generalTransport.quotes);
  const notGuaranteedReason = quote?.guaranteed?.reason;
  const activePathnames = notGuaranteedReason && reasonMap[notGuaranteedReason]?.activePathnames;
  const isBlocked = notGuaranteedReason && reasonMap[notGuaranteedReason]?.isBlocked;

  React.useEffect(() => {
    const isNotificationActive = activePathnames?.some(p => pathname.includes(p));
    if (isNotificationActive) {
      notification.add();
    } else {
      notification.remove();
    }
  }, [notGuaranteedReason, pathname]);

  // Handle differnece in heading text depending on whether it's a fully blocked non-guaranteed job
  let headingTransID = 'request_flow.not_guaranteed.heading';
  if (isBlocked) {
    headingTransID = headingTransID.concat('_blocked');
  }

  return (
    <div className={classNames('toast-container', { 'toast--visible': notification.isVisible })}>
      <Toast prefix={<CirclePerson2 />} handleClose={notification.remove}>
        <div className="pt-0-5" style={{ paddingRight: 15 }}>
          <b>
            <Translate id={headingTransID} />
          </b>
        </div>
        {notGuaranteedReason && <Translate id={`request_flow.not_guaranteed.${notGuaranteedReason}`} />}
      </Toast>
    </div>
  );
};

import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import classNames from 'classnames';

import { Button, Heading, IconCross, IconFilters, Input, InputDate, Select } from '../../../brenger-shared-ui';
import { RootState } from '../../../typings';
import { StatusPageForms } from '../interface';
import { FilterFields, PresentationFilter } from '../typings';
import { actions } from '../ducks';

import '../../../assets/status-page/filters.css';
import { hasBusinessFlow } from '../../Business/ducks';
import { Translate } from 'react-localize-redux';
import { translate } from '../../../utils/localization';

type ReduxProps = ReturnType<typeof mapStateToProps>;

interface FormProps {
  handleSubmit(): void;
}
type Props = ReduxProps & FormProps;

const Filters: React.FC<Props> = props => {
  const [isFiltersOpen, setFiltersOpen] = React.useState(false);
  return (
    <form className={'filter-form'} onSubmit={props.handleSubmit}>
      <div className="filter-form--inner">
        <Heading size={4} extraClasses="filter-form--header">
          <Translate id={'request_flow.filters.title'} />
        </Heading>
        <div className={'filter-form--search flex'}>
          <div className={'grow--1'}>
            <Field
              name={FilterFields.QUERY}
              label={translate(`request_flow.filters.types.search.label`)}
              component={Input}
            />
          </div>
          <Button
            extraClasses={'no-margin'}
            buttonStyle="primary"
            onClick={() => setFiltersOpen(!isFiltersOpen)}
            iconPos="left"
          >
            <IconFilters /> <Translate id={'request_flow.filters.title'} />
          </Button>
        </div>
        <div className={classNames('filter-form--pane', { 'filter-form--pane--open': isFiltersOpen })}>
          <div className={'filter-form--pane--header flex flex--vc flex--sb'}>
            <Heading size={4}>
              <Translate id={'request_flow.filters.title'} />
            </Heading>
            <div className={'trigger'} onClick={() => setFiltersOpen(!isFiltersOpen)}>
              <IconCross />
            </div>
          </div>
          <div className={'filter-form--pane--inner'}>
            <div className={'filter-form--pane--section'}>
              <Heading size={5}>
                <Translate id={'request_flow.filters.types.date.label'} />
              </Heading>
              <Field name={FilterFields.DATE} label={'Voer datum in'} component={InputDate} />
            </div>
            <div className={'filter-form--pane--section'}>
              <Heading size={5}>
                <Translate id={'request_flow.filters.types.status.title'} />
              </Heading>
              <Field
                name={FilterFields.PRESENTATION}
                label={'Status'}
                component={Select}
                options={[
                  {
                    label: translate(`request_flow.filters.types.status.options.${PresentationFilter.PUBLISHED}`),
                    value: PresentationFilter.PUBLISHED,
                  },
                  {
                    label: translate(
                      `request_flow.filters.types.status.options.${PresentationFilter.DRIVER_CONFIRMED}`
                    ),
                    value: PresentationFilter.DRIVER_CONFIRMED,
                  },
                  {
                    label: translate(`request_flow.filters.types.status.options.${PresentationFilter.DELIVERED}`),
                    value: PresentationFilter.DELIVERED,
                  },
                ]}
              />
            </div>
            {props.hasDedicatedBusinessFlow && (
              <div className={'filter-form--pane--section'}>
                <Heading size={5}>
                  <Translate id={'request_flow.filters.types.conditions.title'} />
                </Heading>
                <Field
                  name={FilterFields.ORDER_TYPE_NORMAL}
                  type={'checkbox'}
                  label={translate(`request_flow.filters.types.conditions.options.${FilterFields.ORDER_TYPE_NORMAL}`)}
                  component={Input}
                />
                <Field
                  name={FilterFields.ORDER_TYPE_RETURN}
                  type={'checkbox'}
                  label={translate(`request_flow.filters.types.conditions.options.${FilterFields.ORDER_TYPE_RETURN}`)}
                  component={Input}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state: RootState) => ({
  hasDedicatedBusinessFlow: hasBusinessFlow(state),
});

export default reduxForm({
  form: StatusPageForms.FILTERS,
  destroyOnUnmount: false,
  onSubmit: (details, dispatch) => dispatch(actions.submitFilterForm(details)),
})(connect(mapStateToProps)(Filters));

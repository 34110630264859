import { fork } from 'redux-saga/effects';
import { LocalizationSaga } from './Localization';
import { BvaSagas } from '../../modules/BvaFlow/sagas';
import { VavatoSagas } from '../../modules/VavatoFlow/sagas';
import { GeneralTransportSagas } from '../../modules/GeneralFlow/sagas';
import { StatusPageSagas } from '../../modules/StatusPage/sagas';
import { PaymentSagas } from '../../modules/Payment/sagas';
import { UserSagas } from '../../modules/User/sagas';
import { baseSagas } from './baseSagas';
import { ChatSagas } from '../../modules/Chat/sagas';
import { IkeaTransportSagas } from '../../modules/IkeaFlow/sagas';
import { BusinessSaga } from '../../modules/Business/sagas';
import { onInitSaga } from './runOnInit';

export function* rootSaga() {
  yield fork(baseSagas);
  yield fork(UserSagas);
  yield fork(LocalizationSaga);
  yield fork(onInitSaga);
  yield fork(BvaSagas);
  yield fork(VavatoSagas);
  yield fork(PaymentSagas);
  yield fork(ChatSagas);
  yield fork(GeneralTransportSagas);
  yield fork(IkeaTransportSagas);
  yield fork(StatusPageSagas);
  yield fork(BusinessSaga);
}

import React from 'react';
import { actions as flowActions } from '../ducks';

import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { CheckMarkList, IconCross, PriceDisplay } from '../../../brenger-shared-ui';
import TransportRequestSummary from './TransportRequestSummary';
import { translate } from '../../../utils/localization';
import { toEuroString } from '../../../utils/payment';
import { _INSURANCE_AMOUNT } from '../../../utils/global';

interface Props {
  transport: any;
  toggleSheet: any;
}

export class SidebarLayout extends React.Component<Props, any> {
  public render() {
    /* AB test */
    const showUsps = window.location.href.indexOf('/items') > -1;
    /* AB TEST: change when people see price */
    const showPrice = document.body.classList.contains('show-price-later')
      ? this.props.transport.layout.step > 3
      : true;
    return (
      <div>
        <div className={'sheet-layout--sidebar-shade-toggle'} onClick={this.props.toggleSheet} />
        <div className={'sheet-layout--sidebar-background' + (showUsps ? ' usps-present' : '')}>
          {/* AB TEST: change when people see price */}
          <div className={'summary' + (!showPrice ? ' summary--hide-prices' : '')}>
            <div className={'summary--close'} onClick={this.props.toggleSheet}>
              <IconCross />
            </div>
            <div className="summary--body">
              <div className={'summary-heading'}>
                <Translate id={'request_flow.headers.transport_request_title'} />
              </div>
              <TransportRequestSummary />
            </div>
            <div className={'summary--price hide--md-down'}>
              <PriceDisplay
                failedAttempts={this.props.transport.price.failedAttempts}
                loading={this.props.transport.price.isLoading}
                header={
                  <div>
                    <Translate id={'request_flow.price.total'} />
                  </div>
                }
                cents={this.props.transport.price.amount}
              />
            </div>
            {/* AB test */}
            {showUsps && (
              <div className={'hide--md-down'}>
                <div className={'brenger-usp-display pt-1'}>
                  <CheckMarkList
                    checkMarkStyle={'primary'}
                    list={[
                      translate('request_flow.summary.usp.one', { insurance_amount: toEuroString(_INSURANCE_AMOUNT) }),
                      translate('request_flow.summary.usp.two', { insurance_amount: toEuroString(_INSURANCE_AMOUNT) }),
                      translate('request_flow.summary.usp.three', {
                        insurance_amount: toEuroString(_INSURANCE_AMOUNT),
                      }),
                      translate('request_flow.summary.usp.four', { insurance_amount: toEuroString(_INSURANCE_AMOUNT) }),
                    ]}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  transport: state.generalTransport,
});

const mapDispatchToProps = dispatch => ({
  toggleSheet: () => dispatch(flowActions.toggleSheet()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarLayout);

declare global {
  interface Window {
    fcWidget: any;
    ga?: any;
  }
}

export * from './enums';
export * from './interfaces';
export * from './types';
export * from './utils';

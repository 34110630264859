import { Field, FieldArray, reduxForm } from 'redux-form';
import {
  Heading,
  Input,
  InputFileUploads,
  SheetLayoutContent,
  SheetLayoutContentWrapper,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { IkeaFlowFields, IkeaFlowForms, WarehouseOption } from '../interface';
import { actions, getIsInternalExternalOrBoth, getSelectedStoreData, getWarehouseChoice } from '../ducks';

import FooterLayout from './FooterLayout';
import ItemSets from '../../GeneralFlow/components/ItemSets';
import React from 'react';
import { RootState } from '../../../typings';
import { Translate } from 'react-localize-redux';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { parseInteger } from '../../GeneralFlow/schema';
import { pickupSchema } from '../schema';
import { translate } from '../../../utils/localization';

type ReduxProps = ReturnType<typeof mapStateToProps>;

interface FormProps {
  submitting: boolean;
  handleSubmit(): void;
  reset(): void;
}

type Props = ReduxProps & FormProps;

class PickupDetails extends React.Component<Props> {
  public render() {
    const warehouseChoice = this.props.wareHouseChoice;
    const [isInternalOrBoth, isExternalOrBoth] = this.props.isInternalExternalOrBoth;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <SheetLayoutContentWrapper>
          <SheetLayoutHeader>
            <Heading size={2}>
              <Translate id={'request_flow.IKEA.pickup_header'} />
            </Heading>
          </SheetLayoutHeader>
          <SheetLayoutContent>
            {isExternalOrBoth && (
              <>
                <div className={'pb-0-5'}>
                  <Heading size={5} extraClasses={'no-margin'}>
                    <Translate id={'request_flow.IKEA.external_header'} />
                  </Heading>
                </div>
                <InputFileUploads
                  fieldName={IkeaFlowFields.INVOICE}
                  formName={IkeaFlowForms.PICKUP_OPTIONS}
                  accept={'application/pdf,image/*'}
                  validate={pickupSchema.invoice}
                  multiple={true}
                  headerBold={false}
                  texts={{
                    button: {
                      default: String(translate('request_flow.IKEA.fields.upload_invoice.button.default')),
                      add: String(translate('request_flow.IKEA.fields.upload_invoice.button.add')),
                      delete: String(translate('request_flow.IKEA.fields.upload_invoice.button.delete')),
                      change: String(translate('request_flow.IKEA.fields.upload_invoice.button.change')),
                    },
                    header: String(translate('request_flow.IKEA.fields.upload_invoice.header')),
                  }}
                />
                <div className={'pb-0-5'}>
                  <Translate id={'request_flow.IKEA.invoice_extra_info_header'} />
                </div>
                <div className={'pb-0-5'}>
                  <div style={{ width: '66%', maxWidth: '250px' }}>
                    <Field
                      validate={pickupSchema.isell}
                      name={IkeaFlowFields.ISELL}
                      normalize={parseInteger}
                      type="number"
                      component={Input}
                      label={translate('request_flow.IKEA.fields.isell.label')}
                    />
                    <Field
                      validate={pickupSchema.weight}
                      name={IkeaFlowFields.WEIGHT}
                      normalize={parseInteger}
                      type="number"
                      component={Input}
                      min={1}
                      append={'kg'}
                      label={translate('request_flow.IKEA.fields.weight.label')}
                    />
                    <Field
                      validate={pickupSchema.collies}
                      name={IkeaFlowFields.COLLIES}
                      normalize={parseInteger}
                      type="number"
                      min={1}
                      component={Input}
                      label={translate('request_flow.IKEA.fields.collies.label')}
                    />
                  </div>
                </div>
              </>
            )}
            {isInternalOrBoth && (
              <>
                <div className={'pb-0-5'}>
                  <Heading size={5} extraClasses={'no-margin'}>
                    <Translate id={'request_flow.IKEA.internal_header'} />
                  </Heading>
                </div>
                <InputFileUploads
                  fieldName={IkeaFlowFields.IMAGE}
                  formName={IkeaFlowForms.PICKUP_OPTIONS}
                  accept={'image/*'}
                  validate={pickupSchema.image}
                  multiple={true}
                  headerBold={false}
                  texts={{
                    button: {
                      default: String(translate('request_flow.IKEA.fields.upload_image.button.default')),
                      add: String(translate('request_flow.IKEA.fields.upload_image.button.add')),
                      delete: String(translate('request_flow.IKEA.fields.upload_image.button.delete')),
                      change: String(translate('request_flow.IKEA.fields.upload_image.button.change')),
                    },
                    header: String(translate('request_flow.IKEA.fields.upload_image.header')),
                  }}
                />
                <div className={'pb-0-5'}>
                  <Translate
                    id={
                      'request_flow.IKEA.internal' +
                      (warehouseChoice === WarehouseOption.BOTH ? '_or_both' : '') +
                      '_extra_info_header'
                    }
                  />
                </div>
                {/* Per store we are able to set if we should render an item_set configurator like the General flow. 
                If false we redner a simple input for "item count" */}
                {this.props.ikeaStore?.config.hasItemSetConfigurator ? (
                  <div className="ikea-item-set-config">
                    <FieldArray
                      name={IkeaFlowFields.ITEM_SETS}
                      businessFlow={true}
                      formName={IkeaFlowForms.PICKUP_OPTIONS}
                      component={ItemSets}
                    />
                  </div>
                ) : (
                  <div>
                    <div style={{ width: '66%', maxWidth: '250px' }}>
                      <Field
                        validate={pickupSchema.count}
                        name={IkeaFlowFields.COUNT}
                        normalize={parseInteger}
                        type="number"
                        component={Input}
                        label={translate('request_flow.IKEA.fields.count.label')}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </SheetLayoutContent>
          <FooterLayout submitting={this.props.submitting} />
        </SheetLayoutContentWrapper>
      </form>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  wareHouseChoice: getWarehouseChoice(state),
  isInternalExternalOrBoth: getIsInternalExternalOrBoth(state),
  ikeaStore: getSelectedStoreData(state),
});

export default reduxForm({
  form: IkeaFlowForms.PICKUP_OPTIONS,
  destroyOnUnmount: false,
  forceUnregisterOnUmount: false,
  initialValues: {
    itemSets: [{ items: [{ count: 1 }] }],
  },
  onSubmit: (pickup, dispatch) => dispatch(actions.submitPickupOptions()),
})(connect(mapStateToProps)(PickupDetails));

import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import {
  Button,
  Col,
  Container,
  FlashMessage,
  Heading,
  IconArrow,
  Row,
  SheetLayoutContentWrapper,
  SheetLayoutFooter,
} from '../../../brenger-shared-ui';
import PaymentMethods from '../../Payment/components/PaymentMethods';
import { actions as paymentActions } from '../../Payment/ducks';
import { Translate } from 'react-localize-redux';
import { actions as flowActions } from '../../GeneralFlow/ducks';

type Props = InjectedFormProps<{}> & RouteComponentProps<{}>;
export const _FLOW_TYPE = 'general';
export const _NOT_GUARANTEED_PAYMENT = 'payment_not_guaranteed';

class NotPrePaidJobsPayment extends React.Component<Props> {
  public componentWillMount(): void {
    this.props.setProgress(_NOT_GUARANTEED_PAYMENT);
  }
  public render() {
    return (
      <form className={'not-prepaid-jobs-payment'} onSubmit={this.props.handleSubmit}>
        <SheetLayoutContentWrapper>
          <Container>
            <Row>
              <Col xs={12} md={8} extraClasses={'offset-md-2'}>
                <Heading size={2}>
                  <Translate id={'request_flow.headers.payment'} />
                </Heading>
                <PaymentMethods resetPaymentForm={this.props.reset} submitPaymentForm={this.props.handleSubmit} />
                <div className={'add-lot-general-errors-wrapper'}>
                  {this.props.generalErrors && this.props.generalErrors !== '' && (
                    <FlashMessage type={'error'} message={this.props.generalErrors} />
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </SheetLayoutContentWrapper>
        <SheetLayoutFooter>
          <Container>
            <Row>
              <Col xs={12} sm={6} extraClasses={'offset-lg-5'}>
                <Button
                  loading={this.props.submitting}
                  disabled={
                    this.props.isSubmitting || this.props.submitting || this.props.pristine || !this.props.valid
                  }
                  buttonStyle={'secondary'}
                  iconPos={'right'}
                  fullWidth={true}
                >
                  <Translate id={'request_flow.steps.next'} />
                  <IconArrow />
                </Button>
              </Col>
            </Row>
          </Container>
        </SheetLayoutFooter>
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  transport: state.generalTransport,
  price: state.generalTransport.request.price,
  generalErrors: state.generalTransport.errors,
  uuid: state.generalTransport.request.uuid,
  payment: state.payment,
});

const mapDispatchToProps = dispatch => ({
  setProgress: step => dispatch(flowActions.setProgress(step)),
});

export default reduxForm<{}, Props>({
  form: 'paymentForm',
  destroyOnUnmount: false,
  forceUnregisterOnUmount: false,
  onSubmit: (payment, dispatch) => dispatch(paymentActions.submitPayment({ ...payment, flow: _FLOW_TYPE })),
})(connect(mapStateToProps, mapDispatchToProps)(NotPrePaidJobsPayment));

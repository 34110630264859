import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { actions, getTransportAccountLink, types } from './ducks';
import { types as statusTypes } from '../StatusPage/ducks';
import { getLoggedInUser } from '../User/ducks';
import { http } from '../../utils/request';
import _get from 'lodash/get';
import * as appUtils from '../../utils/basics';

export function* ChatSagas() {
  yield fork(sagas);
}

function* sagas() {
  yield takeLatest(types.SEND_NEW_MESSAGE_START, sendMessage);
  yield takeLatest(statusTypes.SET_CHAT_OPEN, setDriverData);
  yield takeLatest(statusTypes.SET_CHAT_OPEN, loadAllMessages);
}

export function composeMessage(data) {
  return http()
    .post('/customer_messages', data, { withCredentials: true })
    .then(resp => resp);
}

export function* sendMessage(action) {
  if (action.payload.msg.length > 0) {
    try {
      const loggedInUser = yield select(getLoggedInUser);
      const accountLink = yield select(getTransportAccountLink);
      const user = _get(loggedInUser, 'id', null);
      const compose = {
        customer: action.payload.request.customer['@id'],
        author: user,
        transport_job_account_link: accountLink['@id'],
        item_set: action.payload.request.item_sets[0]['@id'],
        body: action.payload.msg,
        client_ip_address: '1.1.1.1',
      };
      // compose the message
      const response = yield call(composeMessage, compose);
      // put the created message in the store
      yield put(actions.sendMessageSuccess(response.data));
      const chatBody = document.querySelector('.chat-section--body');
      if (chatBody) {
        chatBody.scrollTop = chatBody.scrollHeight;
      }
    } catch (err) {
      appUtils.logger(err);
      yield put(actions.sendMessageFailed(err));
      const chatBody = document.querySelector('.chat-section--body');
      if (chatBody) {
        chatBody.scrollTop = chatBody.scrollHeight;
      }
    }
  }
}

export function* setDriverData(action) {
  yield put(actions.setDriver(action.payload.tjal));
}

export function* loadAllMessages(action) {
  try {
    const request = yield call(fetchAllMessages, action.payload.tjal['@id']);
    yield put(actions.successFetchAllMessages(request.data['hydra:member']));
  } catch (err) {
    appUtils.logger(err);
    yield put(actions.failedFetchAllMessages(err));
  }
}

export function fetchAllMessages(uuid) {
  return http()
    .get(`${uuid}/messages`, { withCredentials: true })
    .then(resp => resp);
}

import * as React from 'react';
import classnames from 'classnames';
import './pagination.css';

interface Props {
  currentPage: number;
  total: number;
  limit: number;
  onClick: any;
}

const Pagination: React.FC<Props> = props => {
  const { currentPage, total, limit, onClick } = props;
  if (total <= limit) {
    return null;
  }
  const pages: any = [];
  const totalPages = Math.ceil(total / limit);
  for (let i = 0; i < totalPages; i++) {
    pages.push(
      <button
        type={'button'}
        key={`page${i + 1}`}
        className={classnames('pagination--item', { 'pagination--item--active': currentPage === i + 1 })}
        onClick={() => onClick(i + 1)}
      >
        {i + 1}
      </button>
    );
  }
  return <div className={'pagination'}>{pages}</div>;
};

export default Pagination;

import React from 'react';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import {
  Col,
  FlashMessage,
  Heading,
  IconMarker,
  IconSmile,
  Input,
  InputFileUploads,
  InputHidden,
  InputTextToggle,
  Row,
  Select,
  SheetLayoutContent,
  SheetLayoutContentWrapper,
  SheetLayoutHeader,
  Textarea,
} from '../../../brenger-shared-ui';
import { RootState } from '../../../typings';
import { translate } from '../../../utils/localization';
import { normalizeFirstLetterCaps, normalizePostalCode } from '../../../utils/normalisation';
import { Address, User } from '../../GeneralFlow/interface';
import { AddressSchema, PersonSchema } from '../../GeneralFlow/schema';
import { actions, getBusinessFlowError, getBusinessName, getIsReturnTransport } from '../ducks';
import { BusinessFields, BusinessForms } from '../interface';
import FooterLayout from './FooterLayout';
import _get from 'lodash/get';
import classNames from 'classnames';

type ReduxProps = ReturnType<typeof mapStateToProps>;

interface FormProps {
  handleSubmit(): void;
  submit(): void;
}
type Props = ReduxProps & FormProps;

export const pickupPrefix = 'pickup';
export const deliveryPrefix = 'delivery';

class Destination extends React.Component<Props> {
  public render() {
    const { isReturnOrder } = this.props;
    const depots = this.props.depots.addresses;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <SheetLayoutContentWrapper>
          <SheetLayoutHeader>
            <Heading size={2}>
              <Translate id={'request_flow.business.step_1.title'} />
            </Heading>
          </SheetLayoutHeader>
          <SheetLayoutContent>
            <Row>
              <Col xs={12}>
                <Heading size={5} extraClasses={'no-margin pb-0-25'}>
                  <Translate id={'request_flow.business.order_header' + (!isReturnOrder ? '_normal' : '_return')} />
                </Heading>
                <Field
                  component={InputTextToggle}
                  label={String(
                    translate('request_flow.business.toggle_order_type' + (!isReturnOrder ? '_normal' : '_return'))
                  )}
                  name={BusinessFields.RETURN_ORDER}
                />
              </Col>
            </Row>
          </SheetLayoutContent>
          <SheetLayoutContent className={classNames('order-wrapper', { 'order-wrapper--is-return': isReturnOrder })}>
            <Row>
              <Col xs={12} extraClasses={'pt-0-5 pb-0-5'}>
                <b>
                  <Translate id={`request_flow.contact.${!isReturnOrder ? 'pickup' : 'delivery'}_address`} />
                </b>
              </Col>
              <Col xs={12}>
                {this.props.depots.loading ? (
                  <IconSmile spinning={true} />
                ) : (
                  <>
                    {depots.length === 0 && (
                      <FlashMessage type={'error'} message={translate('request_flow.business.errors.address')} />
                    )}
                    {depots.length === 1 && (
                      <FlashMessage type={'info-light'}>
                        <IconMarker /> <span style={{ paddingLeft: '10px' }} />
                        {depots[0].line1}, {depots[0].postal_code}, {depots[0].locality}
                      </FlashMessage>
                    )}
                    {depots.length > 1 && (
                      <Field
                        name={BusinessFields.DEPOT_SELECT}
                        component={Select}
                        emptyFirstOption={false}
                        options={depots.map((depot, index) => {
                          return {
                            value: index,
                            label: `${depot.line1}, ${depot.postal_code}, ${depot.locality}`,
                          };
                        })}
                        label={translate('request_flow.business.choose_from_different_depots')}
                      />
                    )}
                  </>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} extraClasses={'pt-0-5 pb-0-5'}>
                <b>
                  <Translate id={`request_flow.contact.${!isReturnOrder ? 'delivery' : 'pickup'}_address`} />
                </b>
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  validate={PersonSchema[User.first_name]}
                  name={BusinessFields.DELIVERY_FIRST_NAME}
                  type="text"
                  component={Input}
                  label={translate('form.fields.user.first_name.label')}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  validate={PersonSchema[User.last_name]}
                  name={BusinessFields.DELIVERY_LAST_NAME}
                  type="text"
                  component={Input}
                  label={translate('form.fields.user.last_name.label')}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  validate={PersonSchema[User.email]}
                  name={BusinessFields.DELIVERY_EMAIL}
                  type="email"
                  component={Input}
                  label={translate('form.fields.user.email.label')}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  validate={PersonSchema[User.phone]}
                  name={BusinessFields.DELIVERY_PHONE}
                  type="tel"
                  component={Input}
                  label={translate('form.fields.user.phone.label')}
                />
              </Col>
              <Col xs={12}>
                <Field
                  validate={AddressSchema[Address.line1]}
                  name={BusinessFields.DELIVERY_LINE_1}
                  type="text"
                  component={Input}
                  label={translate('form.fields.address.line1.label')}
                />
              </Col>
              <Col xs={12} sm={4}>
                <Field
                  validate={AddressSchema[Address.postal_code_business]}
                  name={BusinessFields.DELIVERY_POSTAL_CODE}
                  type="text"
                  component={Input}
                  normalize={normalizePostalCode}
                  label={translate('form.fields.address.postal_code.label')}
                />
              </Col>
              <Col xs={12} sm={8}>
                <Field
                  validate={AddressSchema[Address.locality]}
                  name={BusinessFields.DELIVERY_LOCALITY}
                  type="text"
                  component={Input}
                  normalize={normalizeFirstLetterCaps}
                  label={translate('form.fields.address.locality.label')}
                />
              </Col>
              <Col xs={12}>
                <Field
                  component={Textarea}
                  type="text"
                  name={BusinessFields.DELIVERY_INSTRUCTIONS}
                  label={translate('request_flow.business.fields.extra_information.description')}
                />
              </Col>
              <Field component={InputHidden} name={BusinessFields.DELIVERY_LATITUDE} />
              <Field component={InputHidden} name={BusinessFields.DELIVERY_LONGITUDE} />
              <Field component={InputHidden} name={BusinessFields.DELIVERY_MUNICIPALITY} />
              <Field component={InputHidden} name={BusinessFields.DELIVERY_ADMINISTRATIVE_AREA} />
              <Field component={InputHidden} name={BusinessFields.DELIVERY_COUNTRY} />
            </Row>
          </SheetLayoutContent>
          <SheetLayoutContent>
            <Row>
              <Col xs={12} extraClasses={'pb-0-5'}>
                <b>
                  <Translate id={`request_flow.business.optional_heading`} />
                </b>
              </Col>
              <Col xs={6}>
                <Field
                  component={Input}
                  type="text"
                  name={BusinessFields.CLIENT_REFERENCE}
                  label={translate('request_flow.business.fields.client_reference.label')}
                />
              </Col>
              <Col xs={6}>
                <InputFileUploads
                  fieldName={BusinessFields.INVOICE}
                  formName={BusinessForms.DESTINATION}
                  accept={'application/pdf,image/*'}
                  texts={{
                    button: {
                      default: String(translate('request_flow.pickup.invoice.button.default')),
                      add: String(translate('request_flow.pickup.invoice.button.add')),
                      delete: String(translate('request_flow.pickup.invoice.button.delete')),
                      change: String(translate('request_flow.pickup.invoice.button.change')),
                    },
                    header: String(translate('request_flow.business.invoice_header')),
                  }}
                  headerShowAfterUpload={true}
                />
              </Col>
            </Row>
          </SheetLayoutContent>
          <FooterLayout submitForm={this.props.submit} />
        </SheetLayoutContentWrapper>
      </form>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  businessFlowError: getBusinessFlowError(state),
  isReturnOrder: getIsReturnTransport(state),
  depots: state.business.depots,
  businessName: getBusinessName(state),
});

export default reduxForm<Props>({
  form: BusinessForms.DESTINATION,
  destroyOnUnmount: false,
  forceUnregisterOnUmount: false,
  initialValues: {
    [BusinessFields.DEPOT_SELECT]: 0,
  },
  onSubmit: (details, dispatch) => dispatch(actions.submitDestination(details)),
})(connect(mapStateToProps)(Destination));

import React from 'react';
import { AppLoader, Button, IconCircleBus } from '../../../brenger-shared-ui';
import '../../../assets/status-page/opening.css';
import { _ACTIVE_LANG } from '../../../configs/localization';
import TrBusinessCard from '../components/TrBusinessCard';
import TrCard from '../components/TrCard';
import { Translate } from 'react-localize-redux';
import { _FLOW_TYPE as BusinessFlowType } from '../../Business/containers/TransportRequest';

interface Props {
  list: {
    loading: boolean;
    results: any[];
  };
  openSingleView: any;
  startNewTr: any;
  showButton: boolean;
}

export const TransportRequestList: React.FC<Props> = props => {
  const { list, openSingleView, startNewTr, showButton } = props;
  if (list.loading) {
    return <AppLoader locale={_ACTIVE_LANG} />;
  }

  if (list.results.length > 0) {
    return (
      <>
        {list.results.map((item, index) => {
          if (item.source_flow === BusinessFlowType) {
            return <TrBusinessCard transportRequest={item} key={index} openSingleView={openSingleView} />;
          }
          return <TrCard tr={item} key={index} openSingleView={openSingleView} />;
        })}
      </>
    );
  }

  return (
    <div className={'pt-1 pb-1 text--center'}>
      <div className={'pb-1'}>
        <IconCircleBus />
      </div>
      <Translate id={'request_flow.status.zero_results'} />
      {showButton && (
        <div className={'pb-1'}>
          <Button buttonStyle={'secondary'} onClick={() => startNewTr()}>
            <Translate id={'request_flow.actions.create_first_request'} />
          </Button>
        </div>
      )}
    </div>
  );
};

import * as React from 'react';
import { logException } from '../../../../utils/basics';

import { ErrorPage } from './ErrorPage';

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<{}, State> {
  public static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // Log the error to Sentry.
    logException(error.message, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      // Rrender custom fallback UI
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

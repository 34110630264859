import React from 'react';
import { CirclePerson2, Toast } from '../../../../brenger-shared-ui';
import { Translate } from 'react-localize-redux';
import { exampleItems } from '../../mocks';
import { priceAsString } from '../../../../utils/price';
import { translate } from '../../../../utils/localization';

interface Props {
  examples: any;
  isOpen: boolean;
  close(): void;
}

const ExampleProductPrice = ({ examples, isOpen, close }: Props) => {
  return (
    <div className={'toast-container' + (isOpen ? ' toast--visible' : '')}>
      <Toast prefix={<CirclePerson2 />} handleClose={close}>
        <b style={{ paddingRight: '15px' }}>
          <Translate id={'request_flow.examples.heading'} />
        </b>
        <br />
        <div className={'toast--pricing-examples'}>
          {exampleItems.map((example, index) => {
            return (
              <div key={index} className={'toast--example'}>
                <div className={'toast--example-name'}>
                  <Translate id={'request_flow.examples.product' + (index + 1)} />
                </div>
                <div className={'toast--example-price'}>
                  {examples.prices[index]
                    ? priceAsString(examples.prices[index])
                    : translate('request_flow.price.unknown')}
                </div>
              </div>
            );
          })}
        </div>
      </Toast>
    </div>
  );
};

export default ExampleProductPrice;

import React from 'react';
import { CirclePerson2, Toast } from '../../../brenger-shared-ui';
import { Translate } from 'react-localize-redux';
import { _MAX_WEIGHT_IKEA } from '../../../utils/global';
import { NotGuaranteedState } from '../../../typings';
import classNames from 'classnames';

interface Props {
  notGuaranteed: NotGuaranteedState;
}

export const NotGuaranteedMessage: React.FC<Props> = ({ ...props }) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const reason = props.notGuaranteed.latestReason;

  // Toggle visiblility per change of reason
  React.useEffect(() => {
    setIsVisible(reason !== '');
  }, [reason]);

  return (
    <div className={classNames('toast-container', { 'toast--visible': isVisible && reason })}>
      <Toast prefix={<CirclePerson2 />} handleClose={() => setIsVisible(false)}>
        <div className={'pt-0-5'} style={{ paddingRight: '15px' }}>
          <b>
            <Translate id={'request_flow.IKEA.not_guaranteed.heading'} />
          </b>
        </div>
        <Translate id={'request_flow.IKEA.not_guaranteed.' + reason} data={{ amountkg: _MAX_WEIGHT_IKEA }} />
      </Toast>
    </div>
  );
};

import { renderToStaticMarkup } from 'react-dom/server';
import * as appUtils from '../utils/basics';
import moment from 'moment-timezone';

export const _LANGUAGES = [
  { name: 'Dutch', code: 'nl' },
  { name: 'English', code: 'en' },
];
export const _ACTIVE_LANG = appUtils.getActiveLanguage();

/**
 * report any missing translation as error to sentry
 * @param translationId
 * @param languageCode
 * @returns {string}
 */
export const onMissingTranslation = ({ translationId, languageCode }) => {
  // Handle frontend behaviour
  if (process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'staging') {
    return `(!) KEY:${translationId} LANG: ${languageCode}`;
  } else {
    return '';
  }
};

export const localization = {
  configure: provider => {
    // initialize the redux localize
    provider.initialize({
      languages: _LANGUAGES,
      options: {
        renderToStaticMarkup,
        onMissingTranslation,
      },
    });
    // 1. set a flag on the original moment to use local time
    moment().local();
    // 2. set moment localization to active language
    moment.locale(_ACTIVE_LANG);
    // 3. set active language in redux-localize
    provider.setActiveLanguage(_ACTIVE_LANG);
    // 4. fetch all translation from server
    localization.getTranslations(_ACTIVE_LANG, provider.addTranslationForLanguage);
  },

  /**
   * fetch and set all translation keys to state
   * @param lang: string
   * @param callback: function
   * @returns {void}
   */
  getTranslations: (lang, callback) => {
    if (!lang || lang === '') {
      lang = 'nl';
    }
    fetch(`${process.env.REACT_APP_TRANSLATIONS_URL}/i18n/translations/${lang}?target=js`)
      .then(resp => resp.json())
      .then(resp => {
        if (typeof resp.common !== 'undefined' && typeof resp.common.client !== 'undefined') {
          callback(resp.common.client, lang);
        }
        if (typeof resp.customer !== 'undefined') {
          callback(resp.customer, lang);
        }
        if (typeof resp.driver !== 'undefined') {
          callback(resp.driver, lang);
        }
      })
      .catch(err => appUtils.logger(err));
  },
};

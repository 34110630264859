import { _ACTIVE_LANG } from '../configs/localization';

export const floorAsString = (floorCount: number | string): string => {
  if (!floorCount || floorCount === 0) {
    return '';
  }
  let suffix = 'e';
  if (_ACTIVE_LANG === 'nl') {
    return floorCount + suffix;
  }
  // english
  suffix = 'th';
  if (floorCount === 1) {
    suffix = 'st';
  }
  if (floorCount === 2) {
    suffix = 'nd';
  }
  if (floorCount === 3) {
    suffix = 'rd';
  }
  return floorCount + suffix;
};

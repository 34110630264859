import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from '../../../brenger-shared-ui/components/layout/grid';
import { Button, Heading, InputPassword, SectionWrapper } from '../../../brenger-shared-ui';
import { RouteComponentProps } from 'react-router';
import { push } from 'connected-react-router';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { actions } from '../ducks';
import '../../../assets/user/user.css';
import { PersonSchema } from '../schema';
import { translate } from '../../../utils/localization';
import { FlashMessage } from '../../../brenger-shared-ui/';
import { Translate } from 'react-localize-redux';

type Props = InjectedFormProps<{}> & RouteComponentProps<{}>;

class PasswordNew extends React.Component<Props> {
  public render() {
    return (
      <SectionWrapper extraClasses={'login-wrapper'} sectionStyle={'white'} centerContent={true}>
        <form onSubmit={this.props.handleSubmit}>
          <Heading size={2}>
            <Translate id={'authentication.new.headline'} />
          </Heading>
          <Row>
            <Col xs={12} extraClasses={'pt-1'}>
              <Field
                validate={PersonSchema.new_password}
                name={'user.password'}
                type="password"
                component={InputPassword}
                label={translate('form.fields.user.new_password.label')}
              />
            </Col>
            {typeof this.props.error === 'string' && this.props.error !== '' && (
              <Col xs={12} sm={12} extraClasses={'login-errors'}>
                <FlashMessage type={'error'} message={this.props.error} />
              </Col>
            )}
            {this.props.success && (
              <Col xs={12} sm={12} extraClasses={'login-success'}>
                <FlashMessage type={'success'} message={String(translate('authentication.new.success'))} />
                <div className={'proceed-to-login'}>
                  <Translate
                    id={'authentication.new.login_as'}
                    data={{
                      customer: (
                        <a href="javascript:void(0);" onClick={() => this.props.openLogin()}>
                          <Translate id={'authentication.new.customer'} />
                        </a>
                      ),
                      driver: (
                        <a href="https://driver.brenger.nl/nl/driver/login">
                          <Translate id={'authentication.new.driver'} />
                        </a>
                      ),
                    }}
                  />
                </div>
              </Col>
            )}
            <Col xs={12} sm={12}>
              <Button
                fullWidth={true}
                loading={this.props.submitting}
                disabled={this.props.submitting || this.props.pristine || !this.props.valid}
                buttonStyle={'secondary'}
              >
                <Translate id={'authentication.new.button'} />
              </Button>
            </Col>
          </Row>
        </form>
      </SectionWrapper>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  submitting: state.user.password_new.loading,
  success: state.user.password_new.success,
  error: state.user.password_new.error,
});

const mapDispatchToProps = dispatch => ({
  openLogin: () => dispatch(push('/login')),
});

export default reduxForm<{}, Props>({
  form: 'passwordnew',
  destroyOnUnmount: false,
  forceUnregisterOnUmount: false,
  onSubmit: (details, dispatch) => dispatch(actions.newPass(details)),
})(connect(mapStateToProps, mapDispatchToProps)(PasswordNew));

import React from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { Button, SectionWrapper } from '../../../brenger-shared-ui';
import { RouteComponentProps } from 'react-router-dom';
import '../../../assets/user/user.css';
import { Invoice } from '../interface';
import _get from 'lodash/get';
import { priceAsString } from '../../../utils/price';
import moment from 'moment';
import { actions as userActions, getLoggedInUser } from '../ducks';
import { push } from 'connected-react-router';

export const prefix = '/invoices';

interface InvoicesProps {
  invoices: Invoice[];
  userDetails: any;
  fetchInvoices(): void;
  goToPath(path: string): void;
}

type Props = InvoicesProps & RouteComponentProps<{}>;

class Invoices extends React.Component<Props> {
  public componentDidMount(): void {
    if (this.props.userDetails.loggedIn) {
      this.props.fetchInvoices();
      return;
    }
    this.props.goToPath('/login');
  }
  public render() {
    return (
      <>
        <SectionWrapper extraClasses={'invoices-wrapper'} sectionStyle={'white'} centerContent={true}>
          <table>
            <thead>
              <tr>
                <td>
                  <Translate id={'bundled.essentials.factuur'} />
                </td>
                <td>ID</td>
                <td>
                  <Translate id={'transport_request.pickup'} />
                </td>
                <td>
                  <Translate id={'transport_request.delivery'} />
                </td>
                <td>
                  <Translate id={'transport_request.price'} />
                </td>
                <td>
                  <Translate id={'form.fields.datetime.date.label'} />
                </td>
                <td>
                  <Translate id={'transport_request.download_invoice'} />
                </td>
              </tr>
            </thead>
            <tbody>
              {this.props.invoices.length > 0 &&
                this.props.invoices.map((invoice: Invoice) => (
                  <tr>
                    <td>{invoice.invoice_number}</td>
                    <td>{_get(invoice, 'transport.short_id', '')}</td>
                    <td>{_get(invoice, 'transport.pickups[0].address.locality', '')}</td>
                    <td>{_get(invoice, 'transport.deliveries[0].address.locality', '')}</td>
                    <td>
                      <p style={{ width: '100px' }}>{priceAsString(_get(invoice, 'transport.price.amount', ''))}</p>
                    </td>
                    <td>
                      {moment(_get(invoice, 'transport.deliveries[0].available_datetime_periods[0].start')).format(
                        'YYYY-MM-DD'
                      )}
                    </td>
                    <td>
                      <Button
                        onClick={() => window.open(invoice.invoice_url, '_blank')}
                        size={'sm'}
                        buttonStyle={'secondary'}
                      >
                        <Translate id={'transport_request.download_invoice'} />
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </SectionWrapper>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  invoices: state.user.invoices,
  userDetails: getLoggedInUser(state),
});

const mapDispatchToProps = dispatch => ({
  fetchInvoices: () => dispatch(userActions.fetchInvoices()),
  goToPath: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);

import React from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { RootState } from '../../../typings/interfaces';
import { NotGuaranteedMessage } from '../components/NotGuaranteedMessage';
import { Container, SheetLayoutNotifications } from '../../../brenger-shared-ui';
import { getIsInternalExternalOrBoth } from '../ducks';
import { InternalWarehouseMessage } from '../components/InternalWarehouseMessage';
import { getLocation } from 'connected-react-router';
import { prefix } from '..';

type ReduxProps = ReturnType<typeof mapStateToProps>;

interface ComponentProps {
  submitting: boolean;
}

type Props = ReduxProps & ComponentProps;

const Notifications: React.FC<Props> = ({ ...props }) => {
  const [isInternalOrBoth] = props.isInternalExternalOrBoth;
  const isDeliveryStep1 = props.location.pathname === `${prefix}/delivery_details`;
  return (
    <SheetLayoutNotifications>
      <Container>
        <InternalWarehouseMessage showMessage={isInternalOrBoth && isDeliveryStep1} />
        <NotGuaranteedMessage notGuaranteed={props.notGuaranteed} />
      </Container>
    </SheetLayoutNotifications>
  );
};

const mapStateToProps = (state: RootState, ownProps) => ({
  notGuaranteed: state.ikeaTransport.request.not_guaranteed,
  isInternalExternalOrBoth: getIsInternalExternalOrBoth(state),
  location: getLocation(state),
});

export default connect(mapStateToProps)(Notifications);

import React from 'react';
import { Field } from 'redux-form';
import { Translate } from 'react-localize-redux';
import {
  Button,
  FlashMessage,
  Heading,
  IconSmile,
  Input,
  InputHidden,
  InputTiles,
  SheetLayoutContent,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { useTypedSelector } from '../../../hooks';
import { pickupDeliverySchema } from '../schema';
import { DateOption, SubStepFieldNamesDelivery, SubStepFieldNamesPickup } from '../interface';
import _get from 'lodash/get';
import { SMILE_DIRECTION } from '../../../brenger-shared-ui/components/basics/icons/IconSmile';
import { getIsInstantBookingAvailable } from '../ducks';
import { InstantBookingOption } from './InstantBookingOption';

interface OwnProps {
  isPriceLoading: boolean;
  title: string;
  errors: any;
  errorCode: string;
  dateOptions: DateOption[];
  isPickup: boolean;
  hasDifferentDeliveryOption?: boolean;
  // When picking "extra services package" display all dates starting from the beginning.
  // We determine where to start the date range based on the availablity of this action.
  // Economic packages nudge the user towards a less immediate, cheaper first option.
  showGetEarlierDatesButton: boolean;
  isAboveMaxDistance?: boolean;
  getMoreDates(): void;
  getEarlierDates(): void;
}

type AllProps = OwnProps;

const SubStepDate: React.FC<AllProps> = ({
  dateOptions = [],
  isPickup,
  errors,
  errorCode,
  getMoreDates,
  getEarlierDates,
  title,
  isPriceLoading,
  hasDifferentDeliveryOption = true,
  showGetEarlierDatesButton,
  isAboveMaxDistance,
}) => {
  // Set up component state
  const [displayEarlierDatesButton, setDisplayEarlierDatesButton] = React.useState(showGetEarlierDatesButton);
  const [isLoadingEarlierDates, setLoadingEarlierDates] = React.useState(false);
  const [isLoadingLaterDates, setLoadingLaterDates] = React.useState(false);
  const [isLoadingAllDates, setLoadingAllDates] = React.useState(true);

  // Set up dependency list - any changes to these values in this list will trigger the below effects to logic.
  const dependencyList = [isPriceLoading, dateOptions.length];

  // Determine whether to show earlier dates loader (in button)
  React.useEffect(() => {
    if (isLoadingEarlierDates && !isPriceLoading) {
      setLoadingEarlierDates(false);
      setDisplayEarlierDatesButton(false);
    }
  }, dependencyList);

  // Determine whether to show later dates loader (in button)
  React.useEffect(() => {
    if (isLoadingLaterDates && !isPriceLoading) {
      setLoadingLaterDates(false);
    }
  }, dependencyList);

  // Determine whether to show full page loader
  React.useEffect(() => {
    if (isLoadingAllDates && !isPriceLoading && dateOptions.length > 0) {
      setLoadingAllDates(false);
    }
  }, dependencyList);

  const fieldNames = isPickup ? SubStepFieldNamesPickup : SubStepFieldNamesDelivery;

  const hasInstantBookingOption = useTypedSelector(getIsInstantBookingAvailable) && isPickup;

  return (
    <>
      <SheetLayoutHeader>
        <Heading size={2}>
          <Translate id={title} />
        </Heading>
        {isAboveMaxDistance && (
          <p>
            <Translate id={'request_flow.date.sub_title_long_distance'} />
          </p>
        )}
      </SheetLayoutHeader>
      <SheetLayoutContent>
        <div className={'question-wrapper'}>
          {/* Warning message when in bva flow pickup date is not available */}
          {errorCode === 'no_available_auction_pickup_date' && (
            <div className={'no-available-auction-pickup-date'}>
              <Translate id={'request_flow.BVA.integration.no_pickup_date'} />
            </div>
          )}
          <div>
            {isLoadingAllDates && <IconSmile spinning={true} />}
            {!isLoadingAllDates && (
              <>
                {hasInstantBookingOption && (
                  <Field name={SubStepFieldNamesPickup.instant_booking} component={InstantBookingOption} />
                )}
                {isPickup && <Field name={SubStepFieldNamesDelivery.date} component={InputHidden} />}
                {displayEarlierDatesButton && (
                  <Button
                    fullWidth={true}
                    buttonStyle="outline"
                    type="button"
                    iconPos="left"
                    onClick={() => {
                      setLoadingEarlierDates(true);
                      getEarlierDates();
                    }}
                  >
                    <span className="icon-wrapper">
                      <IconSmile spinning={isLoadingEarlierDates} smileDirection={SMILE_DIRECTION.up} />
                    </span>
                    <Translate id={'request_flow.date.show_earlier_dates'} />
                  </Button>
                )}
                <Field
                  validate={pickupDeliverySchema.date}
                  name={fieldNames.date}
                  options={dateOptions}
                  compact={true}
                  maxDelayIndex={7}
                  component={InputTiles}
                />
                <Button
                  fullWidth={true}
                  type="button"
                  buttonStyle="outline"
                  iconPos="left"
                  onClick={() => {
                    setLoadingLaterDates(true);
                    getMoreDates();
                  }}
                >
                  <span className="icon-wrapper">
                    <IconSmile smileDirection={SMILE_DIRECTION.down} spinning={isLoadingLaterDates} />
                  </span>
                  <Translate id={'request_flow.date.show_more_dates'} />
                </Button>
                {isPickup && hasDifferentDeliveryOption && !isAboveMaxDistance && (
                  <Field
                    name={SubStepFieldNamesPickup.different_delivery_date}
                    type={'checkbox'}
                    label={<Translate id={'request_flow.date.select_different_delivery_date'} />}
                    component={Input}
                  />
                )}
              </>
            )}
          </div>
        </div>
        {errors && (
          <div className={'general-errors-wrapper'}>
            <FlashMessage type={'error'} message={errors} />
          </div>
        )}
      </SheetLayoutContent>
    </>
  );
};

export default SubStepDate;

import React from 'react';
import { reduxForm } from 'redux-form';
import { IkeaFlowForms } from '../interface';
import {
  Col,
  Heading,
  PrintDatetimePeriods,
  Row,
  SheetLayoutContent,
  SheetLayoutContentWrapper,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { connect } from 'react-redux';
import { actions as ikeaActions, getDeliveryDatetimePeriods } from '../ducks';
import { Translate } from 'react-localize-redux';
import '../../../assets/BVA/delivery-details.css';
import _get from 'lodash/get';
import moment from 'moment-timezone';
import { DatetimePeriod, RootState } from '../../../typings/interfaces';
import { Countries } from '../../../typings';
import { Route, Switch } from 'react-router';
import { prefix } from '..';
import { ContactFields } from '../components/ContactFields';
import { DeliveryFields } from '../components/DeliveryFields';
import FooterLayout from './FooterLayout';

type ReduxProps = ReturnType<typeof mapStateToProps>;

interface FormProps {
  submitting: boolean;
  handleSubmit(): void;
  reset(): void;
}

type Props = ReduxProps & FormProps;

class DeliveryForm extends React.Component<Props> {
  public render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <SheetLayoutContentWrapper>
          <SheetLayoutHeader>
            <Heading size={2}>
              <Translate id={'request_flow.headers.delivery'} />
            </Heading>
          </SheetLayoutHeader>
          <SheetLayoutContent>
            <Row>
              <Col xs={12}>
                <Heading size={5}>
                  <Translate id={'request_flow.delivery.date'} />
                </Heading>
                <div className="flex">
                  {PrintDatetimePeriods(this.props.deliveryDates)}
                  {this.props.deliveryDates.map((dateTimePeriod: DatetimePeriod, index) => {
                    return (
                      <div key={index} className={'text--primary'}>
                        &nbsp;
                        <Translate id={'request_flow.time.between'} /> {moment(dateTimePeriod.start).format('HH:mm')} -{' '}
                        {moment(dateTimePeriod.end).format('HH:mm')}
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </SheetLayoutContent>
          <Switch>
            <Route exact={true} path={`${prefix}/delivery_details`} component={ContactFields} />
            <Route exact={true} path={`${prefix}/delivery_details/address`} component={DeliveryFields} />
          </Switch>
          <FooterLayout submitting={this.props.submitting} />
        </SheetLayoutContentWrapper>
      </form>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  deliveryDates: getDeliveryDatetimePeriods(state),
});

export default reduxForm<Props>({
  form: IkeaFlowForms.DELIVERY,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  initialValues: {
    custom_delivery_date: false,
    address: {
      country_code: Countries.NL,
    },
  },
  onSubmit: (delivery, dispatch) => dispatch(ikeaActions.submitDeliveryDetails(delivery)),
})(connect(mapStateToProps)(DeliveryForm));

import React from 'react';
import { push } from 'connected-react-router';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { useTypedSelector } from '../../../../hooks';
import { useNotification } from '../../../../hooks/useNotification';
import { prefix } from '../../../GeneralFlow';
import { CirclePerson2, Toast } from '../../../../brenger-shared-ui';
import { GeneralFlowForms, Situations } from '../../interface';

export const MarktplaatsNotification: React.FC = () => {
  const notification = useNotification('marktplaats-situation');
  const dispatch = useDispatch();
  const progressStep = useTypedSelector(state => state.generalTransport.layout.step);
  const isMarktplaatsSituation = useTypedSelector(
    state => state.form[GeneralFlowForms.pickup]?.values?.pickup?.situation === Situations.MARKTPLAATS
  );

  React.useEffect(() => {
    if (progressStep === 3 && isMarktplaatsSituation) {
      notification.add();
    } else {
      notification.remove();
    }
  }, [progressStep, isMarktplaatsSituation]);

  return (
    <div className={classNames('toast-container', { 'toast--visible': notification.isVisible })}>
      <Toast prefix={<CirclePerson2 />} handleClose={notification.removeForManySessions}>
        <div className="pt-0-5" style={{ paddingRight: '15px' }}>
          <b>
            <Translate id="request_flow.marktplaats.notification.title" />
          </b>
        </div>
        <div className="trigger" onClick={() => dispatch(push(`${prefix}/pickup/marktplaats/form`))}>
          <Translate id="request_flow.marktplaats.notification.link" />
        </div>
      </Toast>
    </div>
  );
};

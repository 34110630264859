import React from 'react';
import { Toast } from '../../../../brenger-shared-ui';
import { Translate } from 'react-localize-redux';
import theNetherlands from '../../../../assets/images/the_netherlands@2x.png';

interface Props {
  visible: boolean;
  handleClose: any;
  toBelgium?: boolean;
}

export const BelgiumNotification = ({ toBelgium, visible, handleClose }: Props) => {
  const transKey = toBelgium ? 'to_belgium' : 'belgium';
  return (
    <div className={'toast-container ' + (visible ? 'toast--visible' : '')}>
      <Toast
        prefix={<img style={{ width: '80px', marginTop: '8px' }} src={theNetherlands} />}
        handleClose={handleClose}
      >
        <div className={'pt-0-5'} style={{ paddingRight: '15px' }}>
          <b>
            <Translate id={'request_flow.' + transKey + '.notification.title'} />
          </b>
        </div>
        <Translate id={'request_flow.' + transKey + '.notification.text'} data={{ discount_amount: '20%' }} />
      </Toast>
    </div>
  );
};

import React from 'react';
import { Field } from 'redux-form';
import { Translate } from 'react-localize-redux';
import {
  FlashMessage,
  Heading,
  IconDoor,
  IconElevator,
  InputTiles,
  SheetLayoutContent,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { pickupDeliverySchema } from '../schema';
import { HomeSituations, SubStepFieldNamesDelivery, SubStepFieldNamesPickup } from '../interface';
import { translate } from '../../../utils/localization';
import { priceAsString } from '../../../utils/price';
import { floorAsString } from '../../../utils/floors';

interface Props {
  hasHelp: boolean;
  isPickup: boolean;
  feeFloor: number;
  locality: string;
  transport: any;
}

const SubStepHomeFloorCombined = ({ hasHelp, isPickup, feeFloor, locality, transport }: Props) => {
  const fieldName = isPickup
    ? SubStepFieldNamesPickup.situation_home_floors_combined
    : SubStepFieldNamesDelivery.situation_home_floors_combined;
  const stopType = isPickup ? 'pickup' : 'delivery';

  const options: Array<{ value: string; title: string; desc: string; suffix: any; prefix: any }> = [
    {
      value: HomeSituations.ground_floor_and_ready,
      title: String(translate(`request_flow.${stopType}.situation_house.ground_floor`, { locality: { locality } })),
      desc: String(
        translate(`request_flow.${stopType}.situation_house.ready_at_front_door`, { locality: { locality } })
      ),
      suffix: <span className="text--primary"> + {priceAsString(0)}</span>,
      prefix: <IconDoor />,
    },
    {
      value: HomeSituations.floor_with_elevator,
      title: String(
        translate(`request_flow.${stopType}.situation_house.inside_with_elevator`, { locality: { locality } })
      ),
      desc: String(
        translate(`request_flow.${stopType}.situation_house.inside_with_elevator_subtitle`, { locality: { locality } })
      ),
      suffix: (
        <div>
          <span className="text--primary"> + {priceAsString(hasHelp ? 0 : feeFloor)}</span>
        </div>
      ),
      prefix: <IconElevator />,
    },
  ];

  for (let i = 1; i <= 10; i++) {
    let fee = i * feeFloor;
    if (hasHelp) {
      fee = i < 4 ? 0 : (i - 3) * feeFloor;
    }
    options.push({
      value: `${HomeSituations.floor_without_elevator}[${i}]`,
      title: String(translate(`request_flow.situation.x_floor`, { floor: floorAsString(i) })),
      desc: '',
      suffix: (
        <div>
          <span className="text--primary"> + {priceAsString(fee)}</span>
        </div>
      ),
      prefix: <span className="floor-indication custom">{i}</span>,
    });
  }

  return (
    <>
      <SheetLayoutHeader>
        <Heading size={2} extraClasses={'no-margin'}>
          <Translate
            id={`request_flow.${stopType}.situation_house.question`}
            data={{ locality: <span className={'text--primary'}>{locality}</span> }}
          />
        </Heading>
      </SheetLayoutHeader>
      <SheetLayoutContent>
        <div className={'question-wrapper'}>
          <p>
            <Translate id={'request_flow.insurance.description'} />
          </p>
          <Field
            name={fieldName}
            label={
              <Translate id={`request_flow.${stopType}.situation_house.question`} data={{ locality: { locality } }} />
            }
            type="select"
            validate={pickupDeliverySchema.situation}
            options={options}
            component={InputTiles}
          />
        </div>
        <div className={'general-errors-wrapper'}>
          {transport.errors && transport.errors !== '' && <FlashMessage type={'error'} message={transport.errors} />}
        </div>
      </SheetLayoutContent>
    </>
  );
};

export default SubStepHomeFloorCombined;

import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  Button,
  CardProfile,
  Col,
  Container,
  FlashMessage,
  Label,
  LocationDisplay,
  Modal,
  Row,
  SectionWrapper,
  StatusList,
} from '../../../brenger-shared-ui';
import '../../../assets/status-page/opening.css';
import { getTranslate, Translate } from 'react-localize-redux';
import {
  actions,
  getAccountLinks,
  getConfirmedAccountLink,
  getIsDriverFound,
  getStopTypeDetails,
  getTransportRequestIsDeliveredForCustomer,
  getTransportRequestState,
} from '../ducks';
import moment from 'moment-timezone';
import _get from 'lodash/get';
import DriverCard from '../components/DriverCard';
import { TJAL_STATE } from '../../../utils/global';
import { BusinessFields } from '../../Business/interface';
import { floorAsString } from '../../../utils/floors';
import { translate } from '../../../utils/localization';
import { truncateString } from '../../../utils/string';
import { RootState, StopType } from '../../../typings';
import { getDateFormatLocale } from '../../../utils/datetime';

interface State {
  deleteModalIsOpen: boolean;
}

type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export type Props = ReduxProps;

class TransportRequestBody extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      deleteModalIsOpen: false,
    };
  }

  public setDeleteModalOpen(isOpen: boolean = true) {
    this.setState({ deleteModalIsOpen: isOpen });
    const modalInner = document.querySelector('#delete-prompt');
    if (modalInner && isOpen) {
      modalInner.scrollIntoView(true);
    }
  }

  public getFloorLevelText(stopDataDetails) {
    const hasElevator = stopDataDetails.elevator;
    if (hasElevator) {
      return translate('request_flow.summary.elevator');
    }
    if (stopDataDetails.floor_level === 0) {
      return translate('request_flow.summary.ground_level');
    }
    return floorAsString(stopDataDetails.floor_level) + ' ' + translate('request_flow.summary.floor');
  }

  public render() {
    const pickup = this.props.pickup;
    const delivery = this.props.delivery;
    const bothPickupAndDelivery = pickup !== null && delivery !== null;
    const sourceFlow: string = _get(this.props.statusPage, 'source_flow', '');
    const showChat: boolean =
      _get(this.props.statusPage, 'transport_job_bundled') === false &&
      sourceFlow !== 'business' &&
      bothPickupAndDelivery;

    return (
      <>
        <Container fluid={true}>
          <SectionWrapper sectionStyle={'white'} extraClasses={'source-flow--' + sourceFlow.toLowerCase()}>
            {pickup && (
              <LocationDisplay
                showArrow={bothPickupAndDelivery}
                locationName={pickup.address.locality}
                isPickup={true}
                size={'big'}
                color={'black'}
              >
                <Row>
                  <Col xs={8}>
                    <div className={'loaction-address text--grey'}>
                      {pickup.address.line1}, <div className={'inline-block'}>{pickup.address.postal_code}</div>
                    </div>
                  </Col>
                  <Col xs={4}>
                    {_get(pickup, 'available_datetime_periods[0].start', false) && (
                      <div className={'location-display--datetime'}>
                        {pickup.available_datetime_periods.reverse().map((datetimePeriod, index) => {
                          return (
                            <div key={index} className={'location-date'}>
                              {moment(datetimePeriod.start).format(getDateFormatLocale())}
                            </div>
                          );
                        })}
                        <div className={'location-time text--grey'}>
                          {moment(pickup.available_datetime_periods[0].start).format('LT')} -{' '}
                          {moment(pickup.available_datetime_periods[0].end).format('LT')}
                        </div>
                      </div>
                    )}
                  </Col>
                  <Col xs={12}>
                    <div className={'location-labels'}>
                      <Label>
                        <Translate id={'request_flow.situation.' + pickup.details.situation} />
                      </Label>
                      {sourceFlow !== 'Business' && (
                        <Label>
                          <Translate id={'request_flow.summary.carrying_help.' + pickup.details.carrying_help} />
                        </Label>
                      )}
                      <Label>{this.getFloorLevelText(pickup.details)}</Label>
                    </div>
                    {pickup.details.instructions && pickup.details.instructions.length > 0 && (
                      <>
                        <div className={'text--grey'}>
                          <Translate id={'request_flow.summary.instructions'} />
                        </div>
                        <div className={'text--grey'}>{truncateString(pickup.details.instructions, 85)}</div>
                      </>
                    )}
                  </Col>
                </Row>
              </LocationDisplay>
            )}
            {delivery && (
              <div className={'pb-1'}>
                <LocationDisplay
                  showArrow={bothPickupAndDelivery}
                  locationName={delivery.address.locality}
                  isPickup={false}
                  size={'big'}
                  color={'black'}
                >
                  <Row>
                    <Col xs={8}>
                      <div className={'loaction-address text--grey'}>
                        {delivery.address.line1}, <div className={'inline-block'}>{delivery.address.postal_code}</div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      {_get(delivery, 'available_datetime_periods[0].start', false) && (
                        <div className={'location-display--datetime'}>
                          {delivery.available_datetime_periods.reverse().map((datetimePeriod, index) => {
                            return (
                              <div key={index} className={'location-date'}>
                                {moment(datetimePeriod.start).format(getDateFormatLocale())}
                              </div>
                            );
                          })}

                          <div className={'location-time text--grey'}>
                            {moment(delivery.available_datetime_periods[0].start).format('LT')} -{' '}
                            {moment(delivery.available_datetime_periods[0].end).format('LT')}
                          </div>
                        </div>
                      )}
                    </Col>
                    <Col xs={12}>
                      <div className={'location-labels'}>
                        <Label>
                          <Translate id={'request_flow.situation.' + delivery.details.situation} />
                        </Label>
                        {sourceFlow === 'Business' &&
                          delivery.details.carrying_help === BusinessFields.SERVICE_EXTRA_DRIVER && (
                            <Label>
                              <Translate id={'request_flow.business.fields.extra_driver.label'} />
                            </Label>
                          )}
                        {sourceFlow !== 'Business' && (
                          <Label>
                            <Translate id={'request_flow.summary.carrying_help.' + delivery.details.carrying_help} />
                          </Label>
                        )}
                        <Label>{this.getFloorLevelText(delivery.details)}</Label>
                      </div>
                      {delivery.details.instructions && delivery.details.instructions.length > 0 && (
                        <>
                          <div className={'text--grey'}>
                            <Translate id={'request_flow.summary.instructions'} />
                          </div>
                          <div className={'text--grey'}>{truncateString(delivery.details.instructions, 85)}</div>
                        </>
                      )}
                    </Col>
                  </Row>
                </LocationDisplay>
              </div>
            )}
            {this.props.confirmedAccountLink && (
              <div className={'pt-1'}>
                <p>
                  <Translate id={'request_flow.status.confirmed_driver'} />
                </p>
                <CardProfile
                  header={this.props.confirmedAccountLink.driver_user.first_name}
                  vehicle={_get(this.props.confirmedAccountLink, 'driver_account.vehicles[0].make', '')}
                  image={_get(this.props.confirmedAccountLink, 'driver_user.profile_image', '')}
                  rate={_get(this.props.confirmedAccountLink, 'driver_user.rating', '')}
                  phone={_get(this.props.confirmedAccountLink, 'driver_user.phone', '')}
                  email={this.props.confirmedAccountLink.driver_user.email}
                  footer={
                    showChat && (
                      <Button
                        loading={this.props.confirmedAccountLink['loading']}
                        disabled={this.props.confirmedAccountLink['loading']}
                        onClick={() => this.props.setChatOpen(this.props.confirmedAccountLink)}
                        fullWidth={false}
                        buttonStyle={'outline'}
                      >
                        <Translate id={'request_flow.actions.start_chat'} />
                      </Button>
                    )
                  }
                />
              </div>
            )}
            {bothPickupAndDelivery && (
              <>
                {!this.props.confirmedAccountLink && this.props.tjals.length > 0 && (
                  <div className={'driver-offer-list'}>
                    <div className={'pt-1 pb-1'}>
                      <b>
                        <Translate id={'request_flow.actions.make_choice'} />
                      </b>
                    </div>
                    {this.props.tjals.map((tjal, i) => {
                      if (tjal.pickup_commitments.length === 0 || tjal.delivery_commitments.length === 0) {
                        return <div key={`driver-${i}`} />;
                      }
                      const pickupDatetimes: any = {
                        start: moment(new Date(tjal.pickup_commitments[0].committed_datetime_period.start)).format(
                          'DD-MM-YY HH:mm'
                        ),
                        end: moment(new Date(tjal.pickup_commitments[0].committed_datetime_period.end)).format('HH:mm'),
                      };
                      const deliveryDatetimes: any = {
                        start: moment(new Date(tjal.delivery_commitments[0].committed_datetime_period.start)).format(
                          'DD-MM-YY HH:mm'
                        ),
                        end: moment(new Date(tjal.delivery_commitments[0].committed_datetime_period.end)).format(
                          'HH:mm'
                        ),
                      };
                      return _get(tjal, 'driver_user', false) &&
                        (tjal.state === TJAL_STATE.PENDING || tjal.state === TJAL_STATE.TEMPORARY_ACCEPTED) ? (
                        <DriverCard
                          tjal={tjal}
                          rejectAccountLink={this.props.rejectAccountLink}
                          confirmAccountLink={this.props.confirmAccountLink}
                          setChatOpen={sourceFlow !== 'Business' ? this.props.setChatOpen : false}
                          pickupDatetimes={pickupDatetimes}
                          deliveryDatetimes={deliveryDatetimes}
                          key={`driver-${i}`}
                        />
                      ) : (
                        <div key={`driver-${i}`} />
                      );
                    })}
                  </div>
                )}
                {this.props.tjals.length === 0 && typeof this.props.confirmedAccountLink === 'undefined' && (
                  <p>
                    <Translate id={'request_flow.status.no_driver_found'} />
                  </p>
                )}
                <div className={'pt-1 pb-1'}>
                  <b>
                    <Translate id={'request_flow.status.title'} />
                  </b>
                </div>
                <StatusList
                  items={[
                    {
                      label: <Translate id={'request_flow.status.status_1'} />,
                      value: true,
                    },
                    {
                      label: <Translate id={'request_flow.status.status_2'} />,
                      value: this.props.isDriverFound,
                    },
                    {
                      label: <Translate id={'request_flow.status.status_3'} />,
                      value: this.props.isDeliveredCustomer,
                    },
                  ]}
                />
                {this.props.statusPage.cancelable && (
                  <div className={'pt-1 pb-1 request-actions'}>
                    <Button buttonStyle={'tertiary'} onClick={() => this.setDeleteModalOpen()}>
                      <Translate id={'request_flow.actions.delete_transport_request'} />
                    </Button>
                  </div>
                )}
              </>
            )}
            {this.props.isDeliveredCustomer && (
              <div>
                <FlashMessage type={'success'} message={translate('request_flow.delivery.delivered_message')} />
              </div>
            )}
          </SectionWrapper>
        </Container>
        <Modal
          header={<Translate id={'request_flow.transport_request.delete_confirm_title'} />}
          open={this.state.deleteModalIsOpen}
          innerId={'delete-prompt'}
          onClick={() => this.setDeleteModalOpen(false)}
        >
          <p>
            <Translate id={'request_flow.transport_request.delete_confirm_body'} />
          </p>
          {this.props.cancelErrorText && <FlashMessage type={'error'} message={this.props.cancelErrorText} />}
          <div className={'btn-wrapper'}>
            <Button
              buttonStyle={'tertiary'}
              onClick={() => this.setDeleteModalOpen(false)}
              loading={this.props.isCancelLoading}
              disabled={this.props.isCancelLoading}
            >
              <Translate id={'default.no'} />
            </Button>
            <Button
              buttonStyle={'secondary'}
              onClick={() =>
                this.props.statusPage.id && this.props.cancelTransportRequestStart(this.props.statusPage.id)
              }
              loading={this.props.isCancelLoading}
              disabled={this.props.isCancelLoading}
            >
              <Translate id={'default.yes'} />
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  statusPage: state.statusPage,
  tjals: getAccountLinks(state),
  confirmedAccountLink: getConfirmedAccountLink(state),
  isDeliveredCustomer: getTransportRequestIsDeliveredForCustomer(state),
  isDriverFound: getIsDriverFound(state),
  transportRequestState: getTransportRequestState(state),
  translate: getTranslate(state.localize),
  isCancelLoading: state.statusPage.layout.isCancelLoading,
  cancelErrorText: state.statusPage.layout.cancelErrorText,
  pickup: getStopTypeDetails(state, StopType.pickup),
  delivery: getStopTypeDetails(state, StopType.delivery),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setChatOpen: (tjal: string) => dispatch(actions.setChatOpen(true, tjal)),
  confirmAccountLink: (id: string) => dispatch(actions.confirmAccountLink(id)),
  rejectAccountLink: (id: string) => dispatch(actions.rejectAccountLink(id)),
  cancelTransportRequestStart: (id: string) => dispatch(actions.cancelTransportRequestStart(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransportRequestBody);

import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import AuctionLotsDetails from './containers/AuctionLotsDetails';
import DeliveryDetails from './containers/DeliveryDetails';
import Payment from './containers/Payment';
import { connect } from 'react-redux';
import { StepInterface } from './interface';
import { ProgressHeader, SheetLayout } from '../../brenger-shared-ui';
import { SidebarLayout } from './components/SidebarLayout';
import { _VAVATO_LOGO } from '../../utils/global';
import { goBack } from 'connected-react-router';
import { translate } from '../../utils/localization';

export const prefix = '/partner/vavato/transport_request';
export const MODULE_PREFIX = '@@VavatoFlow';
export const _FLOW_TYPE = 'vavato';

class VavatoFlow extends React.Component<StepInterface> {
  public render() {
    window.document.title = String(translate('request_flow.Vavato.title'));
    return (
      <>
        <ProgressHeader
          goBack={this.props.goBack}
          goBackText={String(translate('nav.previous'))}
          currentStep={this.props.step}
          totalSteps={3}
          thirdPartyLogo={_VAVATO_LOGO}
        />
        <SheetLayout sidebar={<SidebarLayout />} navForwards={true} isNavigating={false}>
          <Switch>
            <Route path={`${prefix}/auction_lots_details`} component={AuctionLotsDetails} />
            <Route path={`${prefix}/delivery_details`} component={DeliveryDetails} />
            <Route path={`${prefix}/payment`} component={Payment} />
            <Redirect to={`${prefix}/auction_lots_details`} />
          </Switch>
        </SheetLayout>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  step: state.vavatoTransport.request.step,
});

const mapDispatchToProps = dispatch => ({
  goBack: () => dispatch(goBack()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VavatoFlow);

import React from 'react';
import { CirclePerson2, Toast } from '../../../brenger-shared-ui';
import { Translate } from 'react-localize-redux';
import { _MAX_WEIGHT } from '../../../utils/global';
import classNames from 'classnames';

interface Props {
  showMessage: boolean;
}

export const InternalWarehouseMessage: React.FC<Props> = ({ ...props }) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const [isSeen, setIsSeen] = React.useState(false);
  // Toggle visiblility
  React.useEffect(() => {
    setIsVisible(props.showMessage && !isSeen);
  }, [props.showMessage, isSeen]);

  return (
    <div className={classNames('toast-container', { 'toast--visible': isVisible })}>
      <Toast prefix={<CirclePerson2 />} handleClose={() => setIsSeen(true)}>
        <div className={'pt-0-5'} style={{ paddingRight: '15px' }}>
          <b>
            <Translate id={'request_flow.IKEA.internal_explanation_header'} />
          </b>
        </div>
        <Translate id={'request_flow.IKEA.internal_explanation'} />
      </Toast>
    </div>
  );
};

import React from 'react';
import {
  getAddressTextLabel,
  getBasePrice,
  getDeliveryAdditions,
  getGlobalAdditions,
  getItemSetTotalSizeAsString,
  getPickupAdditions,
  getRequestMoreInfo,
  getRequestTitle,
} from '../ducks';

import { connect } from 'react-redux';
import { Addition } from '../interface';
import { translate } from '../../../utils/localization';
import { Translate } from 'react-localize-redux';
import { priceAsString } from '../../../utils/price';
import { LocationDisplay } from '../../../brenger-shared-ui';
import AdditionList from '../components/AdditionList';

interface Props {
  title: string;
  description: string;
  itemsAsString: string;
  basePrice: any;
  pickupAsText: null | string;
  deliveryAsText: null | string;
  globalAdditions: Addition[];
  pickupAdditions: Addition[];
  deliveryAdditions: Addition[];
}

export class TransportRequestSummary extends React.Component<Props, any> {
  public render() {
    return (
      <>
        <div className={'summary-heading'}>{this.props.title}</div>
        {this.props.itemsAsString !== '' && (
          <>
            {this.props.itemsAsString}
            <br />
          </>
        )}
        {this.props.description && this.props.description}
        {this.props.basePrice > 0 && (
          <div className={'summary-line'}>
            <div>
              <Translate id={'request_flow.price.base'} />
            </div>
            <div className={'adds-price'}>{priceAsString(this.props.basePrice)}</div>
          </div>
        )}
        <AdditionList additions={this.props.globalAdditions} renderCheckMark={false} />
        <div className={'summary-route pt-0-5'}>
          {!this.props.pickupAsText && !this.props.deliveryAsText && (
            <i>
              <Translate id={'request_flow.location.no_location'} />
            </i>
          )}
          {(this.props.pickupAsText || this.props.deliveryAsText) && (
            <>
              <LocationDisplay
                isPickup={true}
                locationName={
                  this.props.pickupAsText ? this.props.pickupAsText : String(translate('request_flow.location.unknown'))
                }
              >
                <AdditionList additions={this.props.pickupAdditions} renderCheckMark={false} />
              </LocationDisplay>
              <LocationDisplay
                locationName={
                  this.props.deliveryAsText
                    ? this.props.deliveryAsText
                    : String(translate('request_flow.location.unknown'))
                }
              >
                <AdditionList additions={this.props.deliveryAdditions} renderCheckMark={false} />
              </LocationDisplay>
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  title: getRequestTitle(state, false),
  description: getRequestMoreInfo(state),
  itemsAsString: getItemSetTotalSizeAsString(state),
  basePrice: getBasePrice(state),
  pickupAsText: getAddressTextLabel(true, state),
  deliveryAsText: getAddressTextLabel(false, state),
  globalAdditions: getGlobalAdditions(state),
  pickupAdditions: getPickupAdditions(state, false),
  deliveryAdditions: getDeliveryAdditions(state, false),
});

export default connect(mapStateToProps)(TransportRequestSummary);

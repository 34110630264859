import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from '../../../brenger-shared-ui/components/layout/grid';
import {
  Button,
  Heading,
  InputSwitch,
  SectionWrapper,
  SheetLayoutContent,
  SheetLayoutContentWrapper,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { push } from 'connected-react-router';
import { RouteComponentProps } from 'react-router';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { actions } from '../ducks';
import '../../../assets/user/user.css';
import FlashMessage from '../../../brenger-shared-ui/components/notice/flashmessage/FlashMessage';
import { Translate } from 'react-localize-redux';
import SocialButtons from '../components/SocialButtons';
import CompanyDetails from '../components/CompanyDetails';
import PersonalDetails from '../components/PersonalDetails';
import _get from 'lodash/get';
import { getCountries } from '../../../state/ducks/baseReducer';
import { urlParser } from '../../../utils/basics';

type Props = InjectedFormProps<{}> & RouteComponentProps<{}>;

class Register extends React.Component<Props> {
  public render() {
    const URL = urlParser();
    const uuid = URL.get('transport_request');
    const loginURL = uuid && uuid !== '' ? `login?transport_request=${uuid}` : `login`;
    const isBusiness =
      _get(this.props.form, 'values.user.type', false) && this.props.form.values.user.type !== 'private';
    return (
      <>
        <SectionWrapper extraClasses={'register-wrapper'} sectionStyle={'white'} centerContent={true}>
          <form onSubmit={this.props.handleSubmit}>
            <SheetLayoutContentWrapper>
              <SheetLayoutHeader>
                <Heading size={2}>
                  <Translate id={'authentication.register.headline'} />
                </Heading>
              </SheetLayoutHeader>
              <SheetLayoutContent>
                <p>
                  <Translate id={'authentication.login.brief'} />
                  <a href="javascript:void(0);" onClick={() => this.props.openLogin(loginURL)}>
                    <Translate id={'authentication.login.button'} />
                  </a>
                </p>
                <SocialButtons socialAuth={this.props.socialAuth} />
                <br />
                <Field
                  type={'checkbox'}
                  name={'user.type'}
                  options={[
                    {
                      name: 'private',
                      value: 'private',
                      label: <Translate id={'request_flow.contact.is_private'} />,
                    },
                    {
                      name: 'business',
                      value: 'business',
                      label: <Translate id={'request_flow.contact.is_business'} />,
                    },
                  ]}
                  component={InputSwitch}
                />
                {isBusiness && <CompanyDetails countries={this.props.countries} />}
                <PersonalDetails />
                <Row>
                  <Col xs={12} sm={12}>
                    {typeof this.props.errors === 'string' && this.props.errors !== '' && (
                      <FlashMessage type={'error'} message={this.props.errors} />
                    )}
                    <Button
                      fullWidth={true}
                      loading={this.props.submitting}
                      disabled={this.props.submitting || this.props.pristine || !this.props.valid}
                      buttonStyle={'secondary'}
                    >
                      <Translate id={'authentication.register.button'} />
                    </Button>
                  </Col>
                </Row>
              </SheetLayoutContent>
            </SheetLayoutContentWrapper>
          </form>
        </SectionWrapper>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  submitting: state.user.loading,
  errors: state.user.errors,
  route: state.router,
  form: state.form.register,
  countries: getCountries(state, ['NL', 'BE']),
});

const mapDispatchToProps = dispatch => ({
  socialAuth: service => dispatch(actions.socialAuthStart(service, false)),
  openLogin: (url: string) => dispatch(push(url)),
});

export default reduxForm<{}, Props>({
  form: 'register',
  destroyOnUnmount: false,
  forceUnregisterOnUmount: false,
  initialValues: {
    user: {
      type: 'private',
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
    },
  },
  onSubmit: (details, dispatch) => dispatch(actions.register(details)),
})(connect(mapStateToProps, mapDispatchToProps)(Register));

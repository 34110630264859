import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { actions as flowActions, getFlowTitleByStepAndSituation } from '../ducks';
import { connect } from 'react-redux';
import {
  FlashMessage,
  Heading,
  InputRadio,
  SheetLayoutContent,
  SheetLayoutContentWrapper,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { Translate } from 'react-localize-redux';
import '../../../assets/general-flow/items.css';
import { GeneralFlowForms, WeightFields } from '../interface';
import { itemSchema } from '../schema';
import FooterLayout from './FooterLayout';
import _get from 'lodash/get';
import { _INSURANCE_AMOUNT, _MAX_WEIGHT } from '../../../utils/global';
import { translate } from '../../../utils/localization';

interface CustomProps {
  title: string;
  handleSubmit(): void;
}

type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type Props = CustomProps & ReduxProps;

class Weight extends React.Component<Props> {
  public render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <SheetLayoutContentWrapper>
          <SheetLayoutHeader>
            <Heading size={2}>
              <Translate id={this.props.title} />
            </Heading>
          </SheetLayoutHeader>
          <SheetLayoutContent>
            <div style={{ maxWidth: '500px' }}>
              <div className={'pb-0-5'}>
                <Translate id="request_flow.what.fields.weight.label_long" />
              </div>
              <div style={{ maxWidth: '200px' }}>
                <Field
                  validate={itemSchema.weigth}
                  name={WeightFields.WEIGHT}
                  defaultValue={0}
                  component={InputRadio}
                  options={[
                    {
                      label: String(translate('request_flow.weight.ranges.min', { range: '0-34kg' })),
                      value: 34,
                    },
                    {
                      label: String(translate('request_flow.weight.ranges.mid_1', { range: '35-79kg' })),
                      value: 79,
                    },
                    {
                      label: String(translate('request_flow.weight.ranges.mid_2', { range: `80-${_MAX_WEIGHT}kg` })),
                      value: _MAX_WEIGHT,
                    },
                    {
                      label: String(translate('request_flow.weight.ranges.max', { range: `>${_MAX_WEIGHT}kg` })),
                      value: _MAX_WEIGHT + 1,
                    },
                  ]}
                  label={translate('request_flow.what.fields.weight.label')}
                />
              </div>
              <p>
                <Translate id="request_flow.what.fields.weight.disclaimer" />
              </p>
            </div>
            <div className={'general-errors-wrapper'}>
              {this.props.errors && this.props.errors !== '' && (
                <FlashMessage type={'error'} message={this.props.errors} />
              )}
            </div>
          </SheetLayoutContent>
          <FooterLayout />
        </SheetLayoutContentWrapper>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  title: getFlowTitleByStepAndSituation(state, 'weight'),
  errors: state.generalTransport.request.errors,
});

const mapDispatchToProps = dispatch => ({
  setProgress: step => dispatch(flowActions.setProgress(step)),
});

export default reduxForm({
  form: GeneralFlowForms.weight,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmit: (formResults, dispatch) => dispatch(flowActions.submitWeight(formResults)),
})(connect(mapStateToProps, mapDispatchToProps)(Weight));

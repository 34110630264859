import * as React from 'react';
import './icons.css';

interface Props {
  checkMarkStyle?: any;
}

const IconCheckmark = ({ checkMarkStyle = 'secondary' }: Props) => {
  const style = checkMarkStyle === '' ? 'secondary' : checkMarkStyle;
  const classNames = 'IconCheckMark IconCheckMark--' + style;
  return (
    <svg className={classNames} width="11" height="9" viewBox="0 0 11 9">
      <path d="M1 5.438L4.115 8 10 1" />
    </svg>
  );
};

export default IconCheckmark;

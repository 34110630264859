import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from '../../../brenger-shared-ui/components/layout/grid';
import { Button, Heading, Input, InputPassword, SectionWrapper } from '../../../brenger-shared-ui';
import { RouteComponentProps } from 'react-router';
import { push } from 'connected-react-router';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { actions } from '../ducks';
import '../../../assets/user/user.css';
import { PersonSchema } from '../schema';
import { UserProperties } from '../interface';
import { translate } from '../../../utils/localization';
import { FlashMessage } from '../../../brenger-shared-ui/';
import SocialButtons from '../components/SocialButtons';
import { Translate } from 'react-localize-redux';
import { urlParser } from '../../../utils/basics';

type Props = InjectedFormProps<{}> & RouteComponentProps<{}>;

class Login extends React.Component<Props> {
  public render() {
    const URL = urlParser();
    const uuid = URL.get('transport_request');
    const registerURL = uuid && uuid !== '' ? `register?transport_request=${uuid}` : `register`;
    return (
      <>
        <SectionWrapper extraClasses={'login-wrapper'} sectionStyle={'white'} centerContent={true}>
          <form onSubmit={this.props.handleSubmit}>
            <Heading size={2}>
              <Translate id={'authentication.login.headline'} />
            </Heading>
            <p>
              <Translate id={'authentication.login.no_account'} />{' '}
              <a href="javascript:void(0);" onClick={() => this.props.openRegister(registerURL)}>
                <Translate id={'authentication.register.button'} />
              </a>
            </p>
            <div className={'pb-1'}>
              <SocialButtons socialAuth={this.props.socialAuth} />
            </div>
            <Row>
              <Col xs={12} extraClasses={'pt-1'}>
                <Field
                  validate={PersonSchema[UserProperties.email]}
                  name={'user.email'}
                  type="text"
                  component={Input}
                  label={translate('form.fields.user.email.label')}
                />
              </Col>
              <Col xs={12} sm={12}>
                <Field
                  validate={PersonSchema[UserProperties.password]}
                  name={'user.password'}
                  type="password"
                  component={InputPassword}
                  label={translate('form.fields.user.password.label')}
                />
              </Col>
              <Col xs={12} sm={12} extraClasses={'login-errors'}>
                {typeof this.props.errors === 'string' && this.props.errors !== '' && (
                  <FlashMessage type={'error'} message={this.props.errors} />
                )}
              </Col>
              <Col xs={12} sm={12}>
                <Button
                  fullWidth={true}
                  loading={this.props.submitting}
                  disabled={this.props.submitting || this.props.pristine || !this.props.valid}
                  buttonStyle={'secondary'}
                >
                  <Translate id={'authentication.login.button'} />
                </Button>
              </Col>
              <Col xs={12} extraClasses={'pt-0-5'}>
                <p>
                  <Translate id={'authentication.reset.lost_password'} />{' '}
                  <a href="javascript:void(0);" onClick={() => this.props.openReset()}>
                    <Translate id={'authentication.general.click_here'} />
                  </a>
                </p>
              </Col>
            </Row>
          </form>
        </SectionWrapper>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  submitting: state.user.loading,
  errors: state.user.errors,
  form: state.form.login,
});

const mapDispatchToProps = dispatch => ({
  socialAuth: service => dispatch(actions.socialAuthStart(service, true)),
  openRegister: (url: string) => dispatch(push(url)),
  openReset: () => dispatch(push('/password_reset')),
  login: user => dispatch(actions.login(user)),
});

export default reduxForm<{}, Props>({
  form: 'login',
  destroyOnUnmount: false,
  forceUnregisterOnUmount: false,
  onSubmit: (user, dispatch) => dispatch(actions.login(user)),
})(connect(mapStateToProps, mapDispatchToProps)(Login));

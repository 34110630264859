import * as React from 'react';
import './icons.css';

interface Props {
  left?: boolean;
  arrowStyle?: string;
}

const IconArrow = ({ left = false, arrowStyle = 'white' }: Props) => {
  return (
    <svg
      className={'IconArrow IconArrow--' + (left ? 'left' : 'right') + ' IconArrow--style-' + arrowStyle}
      viewBox="0 0 30 25"
      width="30"
      height="25"
    >
      <g>
        <path
          d=" M 29.69 11.738 L 19.04 0.368 C 18.61 -0.095 17.88 -0.125 17.41 0.299 C 16.94 0.723 16.91 1.442 17.34 1.905 L 26.53 11.709 L 1.15 11.709 C 0.51 11.709 0 12.221 0 12.842 C 0 13.472 0.52 13.975 1.15 13.975 L 25.88 13.975 L 17.34 23.089 C 16.91 23.552 16.94 24.271 17.41 24.695 C 17.63 24.901 17.91 25 18.19 25 C 18.5 25 18.81 24.882 19.04 24.635 L 29.69 13.265 C 30.1 12.842 30.1 12.172 29.69 11.738 Z "
          className="arrow-shape"
        />
      </g>
    </svg>
  );
};

export default IconArrow;

import React from 'react';
import { getLocation } from 'connected-react-router';
import classNames from 'classnames';
import { Translate } from 'react-localize-redux';
import { StopSituation } from '@brenger/api-client';

import { CirclePerson2, Toast } from '../../../../brenger-shared-ui';
import { useNotification } from '../../../../hooks/useNotification';
import { useTypedSelector } from '../../../../hooks';

// For more details re: this notification's raison d'etre, see: https://gitlab.com/brenger/frontend/transport-requests/-/issues/712
export const WeightNotification: React.FC = () => {
  const notification = useNotification('weight');
  const situation = useTypedSelector(state => state.form['@@GeneralFlow/pickup_order_form']?.values?.pickup?.situation);
  const weight = useTypedSelector(state => state.form['@@GeneralFlow/weight_form']?.values?.weight);
  const location = useTypedSelector(getLocation);

  const isAuction = (situation as StopSituation) === 'auction';
  const isWeightPageVisible = location.pathname.includes('/weight');
  const isBetween35And79 = weight && weight >= 35 && weight <= 79;
  const isBetween80And199 = weight && weight >= 80 && weight <= 199;
  const isNotificationVisible = isAuction && isWeightPageVisible && (isBetween35And79 || isBetween80And199);

  React.useEffect(() => {
    if (isNotificationVisible) {
      notification.add();
    } else {
      notification.remove();
    }
  }, [isNotificationVisible]);

  let titleTransId = '';
  let descriptionTransId = '';

  if (isBetween35And79) {
    titleTransId = 'request_flow.weight_notification_35_to_79_kg.title';
    descriptionTransId = 'request_flow.weight_notification_35_to_79_kg.description';
  }

  if (isBetween80And199) {
    titleTransId = 'request_flow.weight_notification_80_to_199_kg.title';
    descriptionTransId = 'request_flow.weight_notification_80_to_199_kg.description';
  }

  return (
    <div className={classNames('toast-container', { 'toast--visible': notification.isVisible })}>
      <Toast prefix={<CirclePerson2 />} handleClose={notification.remove}>
        <div className={'pt-0-5'} style={{ paddingRight: '15px' }}>
          <b>
            <Translate id={titleTransId} />
          </b>
        </div>
        <Translate id={descriptionTransId} />
      </Toast>
    </div>
  );
};

import React from 'react';
import { Field, FieldArray } from 'redux-form';
import { Translate } from 'react-localize-redux';
import { Heading, InputHidden, SheetLayoutContent, SheetLayoutHeader } from '../../../../brenger-shared-ui';
import RenderLotField from './RenderLotField';
import { auctionSchema } from '../../../BvaFlow/schema';
import '../../../../assets/auctions/auctions.css';

interface Props {
  errors: any;
  form: any;
  escapeBvaFlow(): void;
}

const SubStepAuctionTypes = ({ errors, form, escapeBvaFlow }: Props) => {
  return (
    <>
      <SheetLayoutHeader>
        <Heading size={2}>
          <Translate id={'request_flow.BVA.title'} />
        </Heading>
      </SheetLayoutHeader>
      <SheetLayoutContent>
        <React.Fragment>
          <FieldArray
            validate={auctionSchema.lots}
            name={'lots'}
            form={form}
            component={RenderLotField}
            generalErrors={errors}
          />
          <Field name={'pickup.errorCode'} component={InputHidden} />
          <p className={'link-text'} onClick={() => escapeBvaFlow()}>
            <Translate id={'request_flow.BVA.integration.escape_to_normal_flow'} />
          </p>
        </React.Fragment>
      </SheetLayoutContent>
    </>
  );
};

export default SubStepAuctionTypes;

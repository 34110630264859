// MOCKS
import moment from 'moment-timezone';
import { GeneralFlowForms, SubStepFieldNamesPickup } from './interface';
import { _LONG_DISTANCE_IN_KM_EXTRA_DATE, _MAX_WEIGHT } from '../../utils/global';
import { CarryingHelpChoices } from '../../typings';

const itemForm = {
  form: {
    [GeneralFlowForms.items]: {
      values: {
        itemSets: [
          {
            items: [
              {
                count: 1,
                width: 10,
                height: 20,
                length: 30,
                weight: 0,
              },
            ],
          },
        ],
      },
    },
  },
};

const itemFormHeavy = {
  form: {
    [GeneralFlowForms.items]: {
      values: {
        itemSets: [
          {
            items: [
              {
                count: 1,
                width: 10,
                height: 20,
                length: 30,
                weight: _MAX_WEIGHT + 50,
              },
            ],
          },
        ],
      },
    },
  },
};

export const mockedDates = [
  {
    date: moment()
      .startOf('day')
      .toISOString(),
    label: 'rush',
    extra_amount: 3445,
  },
  {
    date: moment()
      .add(1, 'days')
      .startOf('day')
      .toISOString(),
    label: '',
    extra_amount: 859,
  },
  {
    date: moment()
      .add(2, 'days')
      .startOf('day')
      .toISOString(),
    label: 'budget',
    extra_amount: 523,
  },
  {
    date: moment()
      .add(3, 'days')
      .startOf('day')
      .toISOString(),
    label: 'sustainable',
    extra_amount: 0,
  },
];

export const exampleItems = [
  {
    title: 'small',
    width: 10,
    height: 10,
    length: 10,
    weight: 10,
    count: 1,
  },
  {
    title: 'medium',
    width: 60,
    height: 60,
    length: 60,
    weight: 60,
    count: 1,
  },
  {
    title: 'large',
    width: 90,
    height: 110,
    length: 210,
    weight: 0,
    count: 1,
  },
];

export const mockAutoCompleteReadyState = {
  generalTransport: {
    request: {
      errors: null,
      price: 0,
      step: 1,
      pickup: null,
      delivery: null,
      submitting: false,
      uuid: null,
    },
    exampleItems: {
      selected: null,
      list: [],
    },
    layout: {
      sheet: false,
    },
    destination: {
      pickup: null,
      delivery: null,
    },
  },
};

export const mockAutoCompleteReadyState2 = {
  generalTransport: {
    request: {
      errors: null,
      price: 0,
      step: 1,
      pickup: null,
      delivery: null,
      submitting: false,
      uuid: null,
    },
    exampleItems: {
      selected: null,
      list: [],
    },
    destination: {
      pickup: {
        locality: 'Amsterdam',
        country_code: 'NL',
        country_name: 'Nederland',
        latitude: '52.37317',
        longitude: '4.89066',
      },
      delivery: {
        locality: 'Utrecht',
        country_code: 'NL',
        country_name: 'Nederland',
        latitude: '52.09126',
        longitude: '5.12275',
      },
    },
    layout: {
      sheet: false,
    },
  },
};

export const mockFloorDataWoElevator = {
  generalTransport: {
    quotes: null,
  },
  form: {
    '@@GeneralFlow/pickup_order_form': {
      values: {
        pickup: {
          situation: 'home',
          help_carrying: CarryingHelpChoices.NEEDED,
          home_floor_situation_combined: 'floor_without_elevator[7]',
        },
      },
      syncErrors: {
        pickup: {
          date: ' ',
        },
      },
    },
  },
};

export const mockAuctionTypes = [
  {
    state: {
      form: {
        '@@GeneralFlow/pickup_order_form': {
          values: {
            pickup: {
              situation: 'home',
            },
          },
        },
      },
    },
    return: false,
  },
  {
    state: {
      form: {
        '@@GeneralFlow/pickup_order_form': {
          values: {
            pickup: {
              situation: 'auction',
              auction_type: 'bva',
            },
          },
        },
      },
    },
    return: true,
  },
  {
    state: {
      form: {
        '@@GeneralFlow/pickup_order_form': {
          values: {
            pickup: {
              situation: 'auction',
              auction_type: 'vavato',
            },
          },
        },
      },
    },
    return: false,
  },
];

export const mockAuctionPickupDate = [
  {
    state: {
      form: {
        '@@GeneralFlow/pickup_order_form': {
          values: {
            pickup: {
              situation: 'auction',
              auction_type: 'bva',
            },
            lots: [
              {
                id: 14305059,
                number: '45743-1',
                auctionId: 45743,
                title: 'Rolls-Royce Dawn 6.6 V12 -Nieuwe auto- 571pk 2019',
                thumbnail: 'https://images.bva-auctions.com/static/auctions/45743/lots/112x112-crop/1.jpg',
                items: [
                  {
                    count: 1,
                    length: 11,
                    width: 22,
                    height: 33,
                  },
                ],
                collectionDaySummaries: [
                  {
                    id: 102874,
                    auctionId: 45743,
                    location: {
                      id: 79710,
                      name: {
                        nl: 'Boxmeer',
                      },
                      address: 'Graafseweg 23a',
                      address2: null,
                      auctionId: 45743,
                      city: 'Boxmeer',
                      countryId: 'nl',
                      houseNumber: null,
                      houseNumberAddition: null,
                      postalCode: '5831 AA',
                      defaultLocation: true,
                      stateName: null,
                      stateId: null,
                      latitude: '51.64731279999999',
                      longitude: '5.9339711',
                    },
                    startDate: '2020-02-17T12:00:00.000+0000',
                    endDate: '2020-02-17T14:00:00.000+0000',
                  },
                ],
              },
            ],
          },
        },
      },
    },
    return: { start: '2020-02-17T12:00:00.000+0000', end: '2020-02-17T14:00:00.000+0000' },
  },
  {
    state: {
      form: {
        '@@GeneralFlow/pickup_order_form': {
          values: {
            pickup: {
              situation: 'auction',
              auction_type: 'bva',
            },
            lots: [
              {
                id: 14305059,
                number: '45743-1',
                auctionId: 45743,
                title: 'Rolls-Royce Dawn 6.6 V12 -Nieuwe auto- 571pk 2019',
                thumbnail: 'https://images.bva-auctions.com/static/auctions/45743/lots/112x112-crop/1.jpg',
                items: [
                  {
                    count: 1,
                    length: 11,
                    width: 22,
                    height: 33,
                  },
                ],
                collectionDaySummaries: [],
              },
            ],
          },
        },
      },
    },
    return: { start: '', end: '' },
  },
];

export const mockDatesAvailable = [
  {
    state: {
      form: {
        '@@GeneralFlow/date_form': {
          values: {
            pickup: {
              date: '2020-02-17',
            },
            delivery: {
              date: '2020-02-17',
            },
          },
        },
      },
    },
    return: true,
  },
  {
    state: {
      form: {
        '@@GeneralFlow/date_form': {},
      },
    },
    return: false,
  },
];

export const mockFloorDataWElevator = {
  generalTransport: {
    quotes: null,
  },
  form: {
    '@@GeneralFlow/pickup_order_form': {
      values: {
        pickup: {
          situation: 'home',
          help_carrying: CarryingHelpChoices.NEEDED,
          home_floor_situation_combined: 'floor_with_elevator',
        },
      },
      syncErrors: {
        pickup: {
          date: ' ',
        },
      },
    },
  },
};

export const mockFloorDataNotReady = {
  form: {
    '@@GeneralFlow/pickup_order_form': {
      values: {
        pickup: {
          situation: 'home',
          help_carrying: CarryingHelpChoices.NEEDED,
          home_floor_situation_combined: 'ground_floor_not_ready',
        },
      },
      syncErrors: {
        pickup: {
          date: ' ',
        },
      },
    },
  },
};

export const mockChosenPackage = {
  generalTransport: {
    quotes: null,
  },
  form: {
    '@@GeneralFlow/packages_form': {
      values: {
        chosen_package: 'custom',
      },
    },
  },
};

export const Additions = {
  form: {
    '@@GeneralFlow/pickup_order_form': {
      values: {
        pickup: {
          situation: 'home',
          time: {
            start: '09:00',
            end: '18:00',
          },
          help_carrying: CarryingHelpChoices.NEEDED,
          home_floor_situation_combined: 'floor_without_elevator[2]',
        },
      },
    },
    '@@GeneralFlow/delivery_order_form': {
      values: {
        delivery: {
          time: {
            start: '09:00',
            end: '18:00',
          },
          pickupTime: {
            start: '09:00',
            end: '18:00',
          },
          pickupDate: '2019-10-30',
          date: '2019-10-30',
          help_carrying: CarryingHelpChoices.EXTRA_DRIVER,
          home_floor_situation_combined: 'floor_without_elevator[4]',
        },
      },
    },
    '@@GeneralFlow/date_form': {
      values: {
        pickup: {
          date: '2019-10-30',
        },
        delivery: {
          date: '2019-10-30',
        },
      },
    },
  },
  generalTransport: {
    priceStructure: {
      base: 3090,
      pickup: [
        {
          charge: 'date_price',
          amount: 1120,
        },
        {
          charge: 'floor',
          amount: 400,
          level: 2,
        },
      ],
      global: [
        {
          charge: 'carrying_help',
          amount: 2500,
          kind: 'extra_driver',
        },
      ],
      delivery: [
        {
          charge: 'floor',
          amount: 800,
          level: 4,
        },
      ],
    },
  },
};

export const mockFixedTimeOptionMock = [
  {
    form: {
      '@@GeneralFlow/pickup_order_form': {
        values: {
          pickup: {
            situation: 'store',
            time: {
              start: '16:00',
              end: '21:30',
            },
          },
        },
      },
      '@@GeneralFlow/delivery_order_form': {
        values: {
          delivery: {
            pickupTime: {
              start: '16:00',
              end: '21:30',
            },
            pickupDate: '2019-11-10',
            date: '2019-11-10',
          },
        },
      },
    },
    generalTransport: {},
  },
  {
    form: {
      '@@GeneralFlow/pickup_order_form': {
        values: {
          pickup: {
            situation: 'store',
            time: {
              start: '12:00',
              end: '21:30',
            },
          },
        },
      },
      '@@GeneralFlow/delivery_order_form': {
        values: {
          delivery: {
            pickupTime: {
              start: '12:00',
              end: '21:30',
            },
            pickupDate: '2019-11-10',
            date: '2019-11-10',
          },
        },
      },
    },
    generalTransport: {
      route: {
        distance: 200.579,
        points: [],
      },
    },
  },
  {
    form: {
      '@@GeneralFlow/pickup_order_form': {
        values: {
          pickup: {
            situation: 'store',
            time: {
              start: '16:00',
              end: '21:30',
            },
          },
        },
      },
      '@@GeneralFlow/delivery_order_form': {
        values: {
          delivery: {
            pickupTime: {
              start: '16:00',
              end: '21:30',
            },
            pickupDate: '2019-11-10',
            date: '2019-11-10',
          },
        },
      },
    },
    generalTransport: {
      route: {
        distance: 200.579,
        points: [],
      },
    },
  },
];

export const mockItemSets = {
  form: {
    '@@GeneralFlow/items_order_form': {
      values: {
        itemSets: [
          {
            items: [
              {
                count: 2,
                title: 'Yes',
                length: 10,
                width: 10,
                height: 15,
              },
              {
                count: 3,
                title: 'Yes',
                length: 50,
                width: 50,
                height: 50,
              },
            ],
          },
        ],
      },
    },
  },
};

export const mockCountries = [
  {
    '@id': '/countries/ae18889b-2f0c-481c-818e-c6138ea57057',
    '@type': 'Country',
    id: 'ae18889b-2f0c-481c-818e-c6138ea57057',
    code: 'NL',
    created_at: '2019-10-29T10:10:31+00:00',
    updated_at: '2019-10-29T10:10:31+00:00',
  },
  {
    '@id': '/countries/ae18889b-2f0c-481c-818e-c6138ea57058',
    '@type': 'Country',
    id: 'ae18889b-2f0c-481c-818e-c6138ea57058',
    code: 'BE',
    created_at: '2019-10-29T10:10:32+00:00',
    updated_at: '2019-10-29T10:10:32+00:00',
  },
];

export const mockContactForm = {
  '@@GeneralFlow/contact_order_form': {
    values: {
      customer: {
        type: 'business',
        business: {
          address: {
            line1: 'Brenger BV 3',
            locality: 'Amsterdam',
            postal_code: '1092AD',
            country: '/countries/ae18889b-2f0c-481c-818e-c6138ea57057',
            country_code: 'NL',
          },
          name: 'Brenger BV',
          coc_number: '628570783',
          vat_number: '319432588',
        },
        contact: {
          first_name: 'ik ben',
          last_name: 'ontwkkelaar',
          email: 'dev@brenger.nl',
          phone: '0858881934',
        },
      },
      pickup: {
        address: {
          locality: 'Amsterdam',
          municipality: 'Amsterdam',
          administrative_area: 'Noord-Holland',
          country_name: 'Nederland',
          country_code: 'NL',
          line1: 'kalkmarkt 9L',
          postal_code: '1011BD',
        },
        add_my_details: true,
        contact: {
          first_name: 'ik ben',
          last_name: 'ontwkkelaar',
          email: 'dev@brenger.nl',
          phone: '0858881934',
        },
      },
      delivery: {
        address: {
          locality: 'Antwerpen',
          municipality: 'Antwerpen',
          administrative_area: 'Vlaanderen',
          country_name: 'België',
          country_code: 'BE',
          line1: 'Mauritskade 55c',
          postal_code: '1092AD',
        },
        contact: {
          phone: '0858881934',
          first_name: 'ik ben',
          last_name: 'ontwkkelaar',
          email: 'dev@brenger.nl',
        },
        add_my_details: true,
      },
    },
    anyTouched: true,
    submitSucceeded: true,
  },
};

export const mockRouteDetailsDefault = {
  generalTransport: {
    route: null,
  },
};

export const mockRouteDetails = {
  generalTransport: {
    route: {
      distance: 46.265,
      points: [
        {
          latitude: 52.37312,
          longitude: 4.89063,
        },
      ],
      travel_time: 3172,
    },
  },
};

export const mockCustomTimePeriodOptionsWithDiscount = [
  {
    start: '9:00',
    end: '13:00',
    priceChange: 500,
  },
  {
    start: '10:00',
    end: '14:00',
    priceChange: 500,
  },
  {
    start: '11:00',
    end: '15:00',
    priceChange: 500,
  },
  {
    start: '12:00',
    end: '16:00',
    priceChange: 500,
  },
  {
    start: '13:00',
    end: '17:00',
    priceChange: 500,
  },
  {
    start: '14:00',
    end: '18:00',
    priceChange: 500,
  },
  {
    start: '15:00',
    end: '19:00',
    priceChange: 500,
  },
  {
    start: '16:00',
    end: '20:00',
    priceChange: 500,
  },
  {
    start: '17:00',
    end: '21:00',
    priceChange: 500,
  },
  {
    start: '18:00',
    end: '22:00',
    priceChange: 500,
  },
];

export const mockCustomTimePeriodOptionsWithoutDiscount = [
  {
    start: '9:00',
    end: '13:00',
    priceChange: 1500,
  },
  {
    start: '10:00',
    end: '14:00',
    priceChange: 1500,
  },
  {
    start: '11:00',
    end: '15:00',
    priceChange: 1500,
  },
  {
    start: '12:00',
    end: '16:00',
    priceChange: 1500,
  },
  {
    start: '13:00',
    end: '17:00',
    priceChange: 1000,
  },
  {
    start: '14:00',
    end: '18:00',
    priceChange: 1000,
  },
  {
    start: '15:00',
    end: '19:00',
    priceChange: 1000,
  },
  {
    start: '16:00',
    end: '20:00',
    priceChange: 1000,
  },
  {
    start: '17:00',
    end: '21:00',
    priceChange: 1000,
  },
  {
    start: '18:00',
    end: '22:00',
    priceChange: 1000,
  },
];

export const mockCustomTimePeriodOptionsTakingPickupIntoAccount = [
  {
    start: '16:00',
    end: '20:00',
    priceChange: 0,
  },
  {
    start: '17:00',
    end: '21:00',
    priceChange: 0,
  },
  {
    start: '18:00',
    end: '22:00',
    priceChange: 0,
  },
];

export const mockHistoricalAddresses = {
  generalTransport: {
    destination: {
      pickup: {
        locality: 'Williamsonstad',
      },
    },
    historicalAddresses: [
      {
        '@id': '/addresses/b49c3336-1dc9-4abd-9ce7-2cd20f3a80ff',
        '@type': 'Address',
        line1: '68588 Zboncak Pass Apt. 176',
        line2: null,
        postal_code: '19260-5761',
        locality: 'Williamsonstad',
        municipality: null,
        administrative_area: 'Norway',
        country: '/countries/5c13b696-1141-4eac-b793-00944a869f82',
        lat: null,
        lng: null,
        type: null,
        stop_type: 'pickup',
        value: '/addresses/b49c3336-1dc9-4abd-9ce7-2cd20f3a80ff',
        label: '68588 Zboncak Pass Apt. 176 Williamsonstad 19260-5761',
      },
      {
        '@id': '/addresses/c2bb67f3-4c9c-45dd-b44d-e63cb539aa73',
        '@type': 'Address',
        line1: '26829 Royal Terrace',
        line2: null,
        postal_code: '97181-1194',
        locality: 'Port Alyson',
        municipality: null,
        administrative_area: 'Gabon',
        country: '/countries/5c13b696-1141-4eac-b793-00944a869f82',
        lat: null,
        lng: null,
        type: null,
        stop_type: 'delivery',
        value: '/addresses/c2bb67f3-4c9c-45dd-b44d-e63cb539aa73',
        label: '26829 Royal Terrace Port Alyson 97181-1194',
      },
      {
        '@id': '/addresses/c2f0a2fb-df61-4d3d-96dc-0a4c581b1228',
        '@type': 'Address',
        line1: '6321 Lennie Road Suite 508',
        line2: null,
        postal_code: '59259',
        locality: 'North Robbhaven',
        municipality: null,
        administrative_area: 'French Guiana',
        country: '/countries/5c13b696-1141-4eac-b793-00944a869f82',
        lat: null,
        lng: null,
        type: null,
        stop_type: 'pickup',
        value: '/addresses/c2f0a2fb-df61-4d3d-96dc-0a4c581b1228',
        label: '6321 Lennie Road Suite 508 North Robbhaven 59259',
      },
      {
        '@id': '/addresses/cc468b51-3cf3-4a8d-b23b-05fc57d7f1bd',
        '@type': 'Address',
        line1: '324 Waelchi Throughway',
        line2: null,
        postal_code: '94871-1758',
        locality: 'Port Lloyd',
        municipality: null,
        administrative_area: 'Turks and Caicos Islands',
        country: '/countries/5c13b696-1141-4eac-b793-00944a869f82',
        lat: null,
        lng: null,
        type: null,
        stop_type: 'pickup',
        value: '/addresses/cc468b51-3cf3-4a8d-b23b-05fc57d7f1bd',
        label: '324 Waelchi Throughway Port Lloyd 94871-1758',
      },
      {
        '@id': '/addresses/d53f95cf-79f8-40b5-806e-21b35cd4d7f1',
        '@type': 'Address',
        line1: '90276 Cremin Views',
        line2: null,
        postal_code: '46772',
        locality: 'New Valliemouth',
        municipality: null,
        administrative_area: 'Botswana',
        country: '/countries/5c13b696-1141-4eac-b793-00944a869f82',
        lat: null,
        lng: null,
        type: null,
        stop_type: 'pickup',
        value: '/addresses/d53f95cf-79f8-40b5-806e-21b35cd4d7f1',
        label: '90276 Cremin Views New Valliemouth 46772',
      },
      {
        '@id': '/addresses/d91fd1f6-7860-4e70-839d-b7697de48027',
        '@type': 'Address',
        line1: '707 Aileen Well',
        line2: null,
        postal_code: '12779-8781',
        locality: 'Malikamouth',
        municipality: null,
        administrative_area: 'Canada',
        country: '/countries/5c13b696-1141-4eac-b793-00944a869f82',
        lat: null,
        lng: null,
        type: null,
        stop_type: 'delivery',
        value: '/addresses/d91fd1f6-7860-4e70-839d-b7697de48027',
        label: '707 Aileen Well Malikamouth 12779-8781',
      },
    ],
  },
};

export const mockGroupHistoricalAddresses = {
  pickup: {
    label: 'Missing translationId: request_flow.contact.pickup_address for language: ${ languageCode }',
    options: [
      {
        '@id': '/addresses/b49c3336-1dc9-4abd-9ce7-2cd20f3a80ff',
        '@type': 'Address',
        line1: '68588 Zboncak Pass Apt. 176',
        line2: null,
        postal_code: '19260-5761',
        locality: 'Williamsonstad',
        municipality: null,
        administrative_area: 'Norway',
        country: '/countries/5c13b696-1141-4eac-b793-00944a869f82',
        lat: null,
        lng: null,
        type: null,
        stop_type: 'pickup',
        value: '/addresses/b49c3336-1dc9-4abd-9ce7-2cd20f3a80ff',
        label: '68588 Zboncak Pass Apt. 176 Williamsonstad 19260-5761',
      },
    ],
  },
  delivery: {
    label: 'Missing translationId: request_flow.contact.delivery_address for language: ${ languageCode }',
    options: [],
  },
};

export const mockCustomTimeFrameExtraFree = [
  {
    form: {
      '@@GeneralFlow/pickup_order_form': {
        values: {
          pickup: {
            situation: 'home',
            time: {
              start: '12:00',
              end: '22:30',
            },
          },
        },
      },
    },
    generalTransport: {
      priceList: {
        'custom_timeslot.first': 1000,
        'custom_timeslot.second': 500,
      },
    },
  },
  {
    form: {
      '@@GeneralFlow/pickup_order_form': {
        values: {
          pickup: {
            situation: 'home',
            time: {
              start: '09:00',
              end: '18:00',
            },
          },
        },
      },
    },
    generalTransport: {
      priceList: {
        'custom_timeslot.first': 1000,
        'custom_timeslot.second': 500,
      },
    },
  },
  {
    form: {
      '@@GeneralFlow/date_form': {
        values: {
          pickup: {
            date: '2019-11-29',
          },
          delivery: {
            date: '2019-11-29',
          },
        },
      },
      '@@GeneralFlow/pickup_order_form': {
        values: {
          pickup: {
            situation: 'home',
            time: {
              start: '16:00',
              end: '18:00',
            },
          },
        },
      },
    },
    generalTransport: {
      priceList: {
        'custom_timeslot.first': 1000,
        'custom_timeslot.second': 500,
      },
    },
  },
  {
    form: {
      '@@GeneralFlow/date_form': {
        values: {
          pickup: {
            date: '2019-11-28',
          },
          delivery: {
            date: '2019-11-29',
          },
        },
      },
      '@@GeneralFlow/pickup_order_form': {
        values: {
          pickup: {
            situation: 'home',
            time: {
              start: '16:00',
              end: '18:00',
            },
          },
        },
      },
    },
    generalTransport: {
      priceList: {
        'custom_timeslot.first': 1000,
        'custom_timeslot.second': 500,
      },
    },
  },
];

export const mockGetStopHelpData = {
  generalTransport: {
    quotes: null,
  },
  form: {
    '@@GeneralFlow/pickup_order_form': {
      values: {
        pickup: {
          situation: 'home',
          time: {
            start: '09:00',
            end: '18:00',
          },
          help_carrying: CarryingHelpChoices.EXTRA_DRIVER,
          home_floor_situation_combined: 'floor_with_elevator',
        },
      },
    },
    '@@GeneralFlow/delivery_order_form': {
      values: {
        setPickupToDeliveryTime: false,
        delivery: {
          pickupTime: {
            start: '09:00',
            end: '18:00',
          },
          pickupDate: '2019-11-25',
          date: '2019-11-25',
          home_floor_situation_combined: 'floor_with_elevator',
        },
      },
    },
    '@@GeneralFlow/packages_form': {
      values: {
        chosen_package: 'custom',
      },
    },
  },
};

export const mockIsSameDates = {
  form: {
    '@@GeneralFlow/date_form': {
      values: {
        pickup: {
          date: '2019-11-29',
        },
        delivery: {
          date: '2019-11-29',
        },
      },
    },
  },
};

export const mockNotSameDates = {
  form: {
    '@@GeneralFlow/date_form': {
      values: {
        pickup: {
          date: '2019-11-28',
        },
        delivery: {
          date: '2019-11-29',
        },
      },
    },
  },
};

export const noDeliveryDate = {
  form: {
    '@@GeneralFlow/date_form': {
      values: {
        pickup: {
          date: '2019-11-29',
        },
      },
    },
  },
};

export const mockSpecialPrices = [
  {
    generalTransport: {
      price: {
        special: {
          be: false,
        },
      },
    },
  },
  {
    generalTransport: {
      price: {
        special: {
          be: true,
        },
      },
    },
  },
];

export const getBasePriceMock = [
  {
    form: {
      '@@GeneralFlow/items_order_form': {
        values: {
          itemSets: [
            {
              items: [
                {
                  count: 1,
                },
              ],
            },
          ],
        },
      },
    },
    generalTransport: {
      priceStructure: {
        base: null,
        global: [],
        pickup: [],
        delivery: [],
      },
    },
  },
  {
    form: {
      '@@GeneralFlow/items_order_form': {
        values: {
          itemSets: [
            {
              items: [
                {
                  count: 1,
                  width: 10,
                  height: 20,
                  length: 30,
                },
              ],
            },
          ],
        },
      },
    },
    generalTransport: {
      priceStructure: {
        base: 30,
        global: [],
        pickup: [],
        delivery: [],
      },
    },
  },
  {
    form: {
      '@@GeneralFlow/items_order_form': {
        values: {
          itemSets: [
            {
              items: [
                {
                  count: 1,
                },
              ],
            },
          ],
        },
      },
    },
    generalTransport: {
      priceStructure: {
        base: 30,
        global: [],
        pickup: [],
        delivery: [],
      },
    },
  },
];

export const getValidItemsMock = [
  {
    form: {
      '@@GeneralFlow/items_order_form': {
        values: {
          itemSets: [
            {
              items: [
                {
                  count: 1,
                  width: 10,
                },
              ],
            },
          ],
        },
      },
    },
  },
  {
    form: {
      '@@GeneralFlow/items_order_form': {
        values: {
          itemSets: [
            {
              items: [
                {
                  count: 1,
                  width: 10,
                  height: 20,
                  length: 30,
                },
              ],
            },
          ],
        },
      },
    },
  },
];

export const isSubmitDisabledMock = [
  {
    ...itemForm,
    generalTransport: {
      cacheUntil: 1574515285054,
      selectedPickupDateTime: '2019-11-23T00:00:00.271Z',
      request: {
        uuid: null,
        international: false,
        customTimePreference: 'no_preference',
        customTime: 'no_preference',
      },
      errors: null,
      route: [],
      price: {
        amount: 7680,
        isLoading: false,
        retryAttempt: 3,
        deliveryAvailableDates: null,
        failedAttempts: true,
      },
      priceStructure: {
        base: 7680,
        global: [
          {
            charge: 'carrying_help',
            amount: 0,
            kind: 'needed',
          },
        ],
      },
      notifications: {
        type: '',
        message: '',
        visible: false,
      },
      destination: {
        pickup: {
          country_code: 'NL',
        },
        delivery: {
          country_code: 'NL',
        },
      },
      layout: {
        sheet: false,
        step: 4,
        modal_time: false,
        modal_help: false,
        modal_help_type: 'default',
        more_info: false,
        uploaded_invoice_names: '',
      },
      referrer: 'http://localhost:3000/',
      isSubmitting: false,
    },
    payment: {
      paymentInfo: {
        isSubmitting: false,
      },
    },
    router: {
      location: {
        pathname: '/transport_request/somepath',
        search: '',
        hash: '',
        key: 'alt5ki',
      },
      action: 'POP',
    },
  },
  {
    ...itemForm,
    generalTransport: {
      cacheUntil: 1574515285054,
      selectedPickupDateTime: '2019-11-23T00:00:00.271Z',
      request: {
        uuid: null,
        international: false,
        customTimePreference: 'no_preference',
        customTime: 'no_preference',
      },
      errors: null,
      route: [],
      price: {
        amount: 7680,
        isLoading: false,
        retryAttempt: 0,
        deliveryAvailableDates: null,
        failedAttempts: false,
      },
      destination: {
        pickup: {
          country_code: 'NL',
        },
        delivery: {
          country_code: 'NL',
        },
      },
      priceStructure: {
        base: 7680,
        global: [
          {
            charge: 'carrying_help',
            amount: 0,
            kind: 'needed',
          },
        ],
      },
      notifications: {
        type: '',
        message: '',
        visible: false,
      },
      layout: {
        sheet: false,
        step: 4,
        modal_time: false,
        modal_help: false,
        modal_help_type: 'default',
        more_info: false,
        uploaded_invoice_names: '',
      },
      referrer: 'http://localhost:3000/',
      isSubmitting: false,
    },
    payment: {
      paymentInfo: {
        isSubmitting: true,
      },
    },
    router: {
      location: {
        pathname: '/transport_request/somepath',
        search: '',
        hash: '',
        key: 'alt5ki',
      },
      action: 'POP',
    },
  },
  {
    ...itemFormHeavy,
    generalTransport: {
      cacheUntil: 1574515285054,
      selectedPickupDateTime: '2019-11-23T00:00:00.271Z',
      request: {
        uuid: null,
        international: false,
        customTimePreference: 'no_preference',
        customTime: 'no_preference',
      },
      destination: {
        pickup: {
          country_code: 'NL',
        },
        delivery: {
          country_code: 'NL',
        },
      },
      errors: null,
      route: [],
      price: {
        amount: 7680,
        isLoading: false,
        retryAttempt: 0,
        deliveryAvailableDates: null,
        failedAttempts: false,
      },
      priceStructure: {
        base: 7680,
        global: [
          {
            charge: 'carrying_help',
            amount: 0,
            kind: 'needed',
          },
        ],
      },
      notifications: {
        type: '',
        message: '',
        visible: false,
      },
      layout: {
        sheet: false,
        step: 4,
        modal_time: false,
        modal_help: false,
        modal_help_type: 'default',
        more_info: false,
        uploaded_invoice_names: '',
      },
      referrer: 'http://localhost:3000/',
      isSubmitting: false,
    },
    payment: {
      paymentInfo: {
        isSubmitting: true,
      },
    },
    router: {
      location: {
        pathname: '/transport_request/terms',
        search: '',
        hash: '',
        key: 'alt5ki',
      },
      action: 'POP',
    },
  },
  {
    ...itemForm,
    generalTransport: {
      cacheUntil: 1574515285054,
      selectedPickupDateTime: '2019-11-23T00:00:00.271Z',
      request: {
        uuid: null,
        international: false,
        customTimePreference: 'no_preference',
        customTime: 'no_preference',
      },
      destination: {
        pickup: {
          country_code: 'NL',
        },
        delivery: {
          country_code: 'NL',
        },
      },
      errors: null,
      route: [],
      price: {
        amount: 7680,
        isLoading: false,
        retryAttempt: 0,
        deliveryAvailableDates: null,
        failedAttempts: false,
      },
      priceStructure: {
        base: 7680,
        global: [
          {
            charge: 'carrying_help',
            amount: 0,
            kind: 'needed',
          },
        ],
      },
      notifications: {
        type: '',
        message: '',
        visible: false,
      },
      layout: {
        sheet: false,
        step: 4,
        modal_time: false,
        modal_help: false,
        modal_help_type: 'default',
        more_info: false,
        uploaded_invoice_names: '',
      },
      referrer: 'http://localhost:3000/',
      isSubmitting: false,
    },
    payment: {
      paymentInfo: {
        isSubmitting: true,
      },
    },
    router: {
      location: {
        pathname: '/transport_request/terms',
        search: '',
        hash: '',
        key: 'alt5ki',
      },
      action: 'POP',
    },
  },
];

export const getStopDetailsMock = [
  {
    input: {
      generalTransport: {
        quotes: null,
      },
      form: {
        '@@GeneralFlow/pickup_order_form': {
          values: {
            pickup: {
              situation: 'home',
              time: {
                start: '09:00',
                end: '18:00',
              },
              help_carrying: CarryingHelpChoices.NEEDED,
              home_floor_situation_combined: 'floor_without_elevator[2]',
            },
          },
        },
      },
    },
    output: {
      carrying_help: CarryingHelpChoices.NEEDED,
      elevator: false,
      extras: [],
      floor_level: 2,
      situation: 'home',
    },
  },
  {
    input: {
      generalTransport: {
        quotes: null,
      },
      form: {
        '@@GeneralFlow/pickup_order_form': {
          values: {
            pickup: {
              situation: 'store',
              time: {
                start: '08:00',
                end: '16:00',
              },
              help_carrying: CarryingHelpChoices.EXTRA_DRIVER,
            },
          },
        },
      },
    },
    output: {
      carrying_help: CarryingHelpChoices.EXTRA_DRIVER,
      elevator: false,
      extras: [],
      floor_level: undefined,
      situation: 'store',
    },
  },
];

export const mockHasTransportRequestHelp = [
  {
    generalTransport: {
      quotes: null,
    },
    form: {
      '@@GeneralFlow/pickup_order_form': {
        values: {
          pickup: {
            help_carrying: CarryingHelpChoices.EXTRA_DRIVER,
          },
        },
      },
      '@@GeneralFlow/delivery_order_form': {
        values: {
          delivery: {
            help_carrying: CarryingHelpChoices.NOT_NEEDED,
          },
        },
      },
    },
  },
  {
    generalTransport: {
      quotes: null,
    },
    form: {
      '@@GeneralFlow/pickup_order_form': {
        values: {
          pickup: {
            help_carrying: CarryingHelpChoices.NOT_NEEDED,
          },
        },
      },
      '@@GeneralFlow/delivery_order_form': {
        values: {
          delivery: {
            help_carrying: CarryingHelpChoices.NOT_NEEDED,
          },
        },
      },
    },
  },
  {
    generalTransport: {
      quotes: null,
    },
    form: {},
  },
];

export const mockShouldHaveDifferentDeliveryDate = [
  {
    input: {
      form: {
        '@@GeneralFlow/date_form': {
          values: {
            pickup: {
              different_delivery_date: true,
            },
          },
        },
      },
    },
    expected: true,
  },
  {
    input: {
      form: {
        '@@GeneralFlow/date_form': {},
      },
    },
    expected: false,
  },
  {
    input: {
      generalTransport: {
        quotes: {
          distance: _LONG_DISTANCE_IN_KM_EXTRA_DATE + 50,
        },
      },
      form: {
        '@@GeneralFlow/date_form': {
          values: {
            pickup: {
              different_delivery_date: true,
            },
          },
        },
      },
    },
    expected: false,
  },
];

export const mockGetFlowTitleByStepAndSituation = [
  {
    state: {
      form: {
        '@@GeneralFlow/pickup_order_form': {
          values: {
            pickup: {
              situation: 'home',
            },
          },
        },
      },
    },
    stepName: 'itemSet',
    return: 'request_flow.headers.what',
  },
  {
    state: {
      form: {
        '@@GeneralFlow/pickup_order_form': {
          values: {
            pickup: {
              situation: 'store',
            },
          },
        },
      },
    },
    stepName: 'timePickup',
    return: 'request_flow.time.title_question_pickup_store',
  },
  {
    state: {
      form: {
        '@@GeneralFlow/pickup_order_form': {
          values: {
            pickup: {
              situation: 'auction',
            },
          },
        },
      },
    },
    stepName: 'contactDelivery',
    return: 'request_flow.headers.contact_delivery',
  },
];

export const mockDistanceDefault = {
  generalTransport: {
    quotes: null,
  },
};

export const mockDistance = {
  generalTransport: {
    quotes: {
      distance: 46.265,
    },
  },
};

export const mockIsDutchTransport = [
  {
    state: {
      generalTransport: {
        destination: {
          pickup: null,
          delivery: null,
        },
      },
    },
    return: false,
  },
  {
    state: {
      generalTransport: {
        destination: {
          pickup: {
            country_code: 'NL',
          },
          delivery: {
            country_code: 'NL',
          },
        },
      },
    },
    return: true,
  },
  {
    state: {
      generalTransport: {
        destination: {
          pickup: {
            country_code: 'NOOOOOPPPPEEEE',
          },
          delivery: {
            country_code: 'NL',
          },
        },
      },
    },
    return: false,
  },
];

export const mockGetIsTransportToFromSpecificCountry = [
  {
    state: {
      generalTransport: {
        destination: {
          pickup: null,
          delivery: null,
        },
      },
    },
    return: false,
  },
  {
    state: {
      generalTransport: {
        destination: {
          pickup: {
            country_code: 'NL',
          },
          delivery: {
            country_code: 'NL',
          },
        },
      },
    },
    return: false,
  },
  {
    state: {
      generalTransport: {
        destination: {
          pickup: {
            country_code: 'NOOOOOPPPPEEEE',
          },
          delivery: {
            country_code: 'BE',
          },
        },
      },
    },
    return: true,
  },
];

export const mockBlockedTransportRequestReasons = [
  {
    state: {
      ...itemFormHeavy,
      generalTransport: {
        destination: {
          pickup: null,
          delivery: null,
        },
      },
    },
    return: ['item.over_200kg'],
  },
  {
    state: {
      ...itemForm,
      generalTransport: {
        destination: {
          pickup: null,
          delivery: null,
        },
      },
    },
    return: [],
  },
  {
    state: {
      ...itemForm,
      generalTransport: {
        destination: {
          pickup: {
            country_code: 'NL',
          },
          delivery: {
            country_code: 'BE',
          },
        },
      },
    },
    return: [],
  },
  {
    state: {
      ...itemForm,
      generalTransport: {
        destination: {
          pickup: {
            country_code: 'BE',
          },
          delivery: {
            country_code: 'BE',
          },
        },
      },
    },
    return: [],
  },
];

export const mockIsDirectlyPayable = [
  {
    state: {
      user: {
        userData: {
          account: {
            paying_by_invoice: false,
          },
        },
      },
      generalTransport: {
        quotes: {
          guaranteed: {
            directly_payable: true,
          },
        },
      },
    },
    return: true,
  },
  {
    state: {
      user: {
        userData: {
          account: {
            paying_by_invoice: true,
          },
        },
      },
      generalTransport: {
        quotes: {
          guaranteed: {
            directly_payable: true,
          },
        },
      },
    },
    return: false,
  },
  {
    state: {
      user: {
        userData: {
          account: {
            paying_by_invoice: true,
          },
        },
      },
      generalTransport: {
        quotes: {
          guaranteed: {
            directly_payable: false,
          },
        },
      },
    },
    return: true,
  },
  {
    state: {
      generalTransport: {
        quotes: {
          guaranteed: {
            directly_payable: true,
          },
        },
      },
    },
    return: true,
  },
];

const instantBookingOption = {
  time_options: [
    {
      description: 'instant',
    },
  ],
};

export const mockIsInstantBookingAvailable = [
  {
    state: {
      generalTransport: {
        price: {
          pickupAvailableDates: {
            options: [instantBookingOption],
          },
        },
      },
    },
    return: instantBookingOption,
    returnIsAvailable: true,
  },
  {
    state: {},
    return: null,
    returnIsAvailable: false,
  },
];

export const mockIsInstantBookingSelected = [
  {
    state: {
      form: {
        [GeneralFlowForms.date]: {
          values: {
            [SubStepFieldNamesPickup.instant_booking]: true,
          },
        },
      },
    },
    return: true,
  },
  {
    state: {},
    return: false,
  },
];

export const mockPricingItems = [
  {
    state: {
      generalTransport: {
        quotes: {
          item_sets: [
            {
              items: [
                {
                  extra_care: {
                    match: 'requires_equipment',
                  },
                },
              ],
            },
          ],
        },
      },
    },
    return: true,
  },
  {
    state: {
      generalTransport: {
        quotes: {
          item_sets: [
            {
              items: [
                {
                  extra_care: {
                    match: 'scooter',
                  },
                },
              ],
            },
          ],
        },
      },
    },
    return: true,
  },
  {
    state: {
      generalTransport: {
        quotes: {
          item_sets: [
            {
              items: [
                {
                  extra_care: {
                    match: 'none',
                  },
                },
              ],
            },
          ],
        },
      },
    },
    return: false,
  },
  {
    state: {
      generalTransport: {
        quotes: null,
      },
    },
    return: false,
  },
];

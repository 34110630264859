import React from 'react';
import { SectionWrapper } from '../../../brenger-shared-ui';
import { RouteComponentProps } from 'react-router';
import { InjectedFormProps } from 'redux-form';
import { Translate } from 'react-localize-redux';

type Props = InjectedFormProps<{}> & RouteComponentProps<{}>;

class FailedSocialAuth extends React.Component<Props> {
  public render() {
    return (
      <SectionWrapper extraClasses={'social-login-failed'} sectionStyle={'white'} centerContent={true}>
        <h3>
          <Translate id={'authentication.login.failed_social_login_message'} />
        </h3>
        <p>
          <Translate id={'authentication.login.redirect_message'} />
        </p>
      </SectionWrapper>
    );
  }
}

export default FailedSocialAuth;

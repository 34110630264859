import * as React from 'react';
import InputWrapper from '../input/InputWrapper';
import InputFeedback from '../input/InputFeedback';
import { Flag } from '../../basics/flag';
import './inputPhoneNumber.css';
import _get from 'lodash/get';

export interface custom {
  type: string;
  successMessage: string;
  label?: string;
  description?: string;
  suffix?: string;
  disabled?: boolean;
  border?: boolean;
  classNames?: string;
  prepend?: string;
  append?: string;
  country?: string;
}

const supportedCountries = [
  {
    id: 'nl',
    code: '+31',
  },
  {
    id: 'be',
    code: '+32',
  },
  {
    id: 'de',
    code: '+49',
  },
  {
    id: 'at',
    code: '+43',
  },
  {
    id: 'ch',
    code: '+41',
  },
  {
    id: 'dk',
    code: '+45',
  },
  {
    id: 'es',
    code: '+34',
  },
  {
    id: 'fr',
    code: '+34',
  },
  {
    id: 'gb',
    code: '+33',
  },
  {
    id: 'ie',
    code: '+44',
  },
  {
    id: 'it',
    code: '+39',
  },
];

export type InputProps = custom & any;

interface Country {
  id: string;
  code: string;
}

const InputPhoneNumber: React.FC<InputProps & React.HTMLAttributes<InputProps>> = ({
  input,
  meta,
  border = false,
  disabled = false,
  label,
  description,
  suffix,
  type,
  successMessage,
  inputClasses,
  append,
}) => {
  const [country, setCountry] = React.useState(supportedCountries[0]);
  const [countryListVisible, setCountryListVisible] = React.useState(false);

  const selectCountry = React.useCallback(
    (value: Country) => {
      if (_get(country, 'id', false) && country.id !== value.id) {
        setCountry(value);
      }
    },
    [country]
  );

  const showCountries = React.useCallback(
    (isVisible: boolean) => {
      if (isVisible !== countryListVisible) {
        setCountryListVisible(isVisible);
      }
    },
    [countryListVisible]
  );

  if (country && input.value.indexOf(country.code) !== 0) {
    input.value = _get(country, 'code', '+31');
  }
  return (
    <div className={'input-phone-number'}>
      <InputWrapper label={label} input={...input} meta={...meta} type={type} border={border}>
        <div className={'input-el--inner'}>
          <div style={{ display: countryListVisible ? 'block' : 'none' }} className={'country-list'}>
            <ul>
              {supportedCountries.map((item: Country, idx: number) => (
                <li
                  key={idx}
                  onClick={() => {
                    showCountries(false);
                    selectCountry(item);
                  }}
                >
                  <Flag country={item.id} />
                  <span>{item.code}</span>
                </li>
              ))}
            </ul>
          </div>
          <span onClick={() => showCountries(true)} className={'input-el--prepend'}>
            {<Flag country={_get(country, 'id', 'nl')} />}
          </span>
          <div className={'input-el--tag'}>
            <input className={inputClasses} disabled={disabled} id={input.name} type={type} {...input} />
            {label && (
              <label
                className={
                  'input-el--label' +
                  (typeof input.required !== 'undefined' && input.required === true
                    ? ' input-el--label--required'
                    : '') +
                  (suffix ? ' input-el--label--with-suffix' : '')
                }
                htmlFor={input.name}
              >
                <span className={'input-el--label-text'}>{label}</span>
                {suffix && <div className={'input-el--suffix'}>{suffix}</div>}
                {description && <div className={'input-el--description'}>{description}</div>}
              </label>
            )}
          </div>
          {append && <span className={'input-el--append'}>{append}</span>}
        </div>

        <InputFeedback meta={...meta} error={meta.error} successMessage={successMessage} />
      </InputWrapper>
    </div>
  );
};

export default InputPhoneNumber;

import { Price, VavatoFlowForms } from './interface';
import _get from 'lodash/get';
import { getDateFormatLocale } from '../../utils/datetime';
import moment from 'moment-timezone';
import { MODULE_PREFIX } from './index';
import { getExternalPartyIri, getUploadedFileDataByFormAndFieldName } from '../../state/ducks/baseReducer';
import { AuctionTypes } from '../GeneralFlow/interface';

// init state
export const defaultState = {
  request: {
    errors: null,
    warnings: '',
    price: Price.AMOUNT,
    step: 1,
    submitting: false,
    uuid: null,
  },
};

// types
export const types = {
  CALCULATE_PRICE: `${MODULE_PREFIX}/CALCULATE_PRICE`,
  SET_PROGRESS_STEP: `${MODULE_PREFIX}/SET_PROGRESS_STEP`,
  SUBMIT_LOTS_DETAILS_START: `${MODULE_PREFIX}/SUBMIT_LOTS_DETAILS_START`,
  SUBMIT_DELIVERY_START: `${MODULE_PREFIX}/SUBMIT_DELIVERY_START`,
  SUBMIT_DELIVERY_STOP: `${MODULE_PREFIX}/SUBMIT_DELIVERY_STOP`,
  PUSH_NOTIFICATION: `${MODULE_PREFIX}/PUSH_NOTIFICATION`,
  PUSH_WARNING: `${MODULE_PREFIX}/PUSH_WARNING`,
  UPLOAD_INVOICE: `${MODULE_PREFIX}/UPLOAD_INVOICE`,
};

// actions
export const actions = {
  submitLotsDetails: lots => ({ type: types.SUBMIT_LOTS_DETAILS_START, payload: lots }),
  submitDeliveryDetails: delivery => ({ type: types.SUBMIT_DELIVERY_START, payload: delivery }),
  submitDeliveryStop: delivery => ({ type: types.SUBMIT_DELIVERY_STOP, payload: delivery }),
  pushNotification: message => ({ type: types.PUSH_NOTIFICATION, payload: message }),
  pushWarning: messages => ({ type: types.PUSH_WARNING, payload: messages }),
  setProgress: step => ({ type: types.SET_PROGRESS_STEP, payload: step }),
};

// selectors
export const getVavatoTransportRequest = state => {
  return state.vavatoTransport;
};

export const getTransportPrice = state => {
  return {
    amount: state.vavatoTransport.request.price,
    currency: Price.CURRENCY,
  };
};

export const getAuctionForm = state => {
  return state.form[VavatoFlowForms.auctionLotForm];
};

export const auctionFormReady = state => {
  return (
    typeof state.form[VavatoFlowForms.auctionLotForm] !== 'undefined' &&
    typeof state.form[VavatoFlowForms.auctionLotForm].values !== 'undefined'
  );
};

export const defaultDateTime = () => {
  return [
    {
      start: new Date().setHours(9),
      end: new Date().setHours(16),
    },
  ];
};

export const getAuctionPickupDatetimePeriod = state => {
  const form = getAuctionForm(state);
  const datetimePeriod = _get(form, 'values.lots[0].auction.datetimePeriod', null);
  if (datetimePeriod) {
    return { start: moment(datetimePeriod.start), end: moment(datetimePeriod.end) };
  }
  return { start: moment(), end: moment() };
};

export const getAuctionTransportOrderDeadline = state => {
  // transports for bva can be booked up to 12:00, 2 business days before pickup
  const pickupDatetimeDeadline = moment(getAuctionPickupDatetimePeriod(state).start).subtract(3, 'days');

  if (pickupDatetimeDeadline.day() === 0) {
    pickupDatetimeDeadline.day(-2);
  }
  if (pickupDatetimeDeadline.day() === 6) {
    pickupDatetimeDeadline.day(4);
  }

  return pickupDatetimeDeadline
    .hour(12)
    .minute(0)
    .second(0);
};

export const getDeliveryForm = state => {
  return state.form[VavatoFlowForms.deliveryForm];
};

export const deliveryFormReady = state => {
  return (
    typeof state.form[VavatoFlowForms.deliveryForm] !== 'undefined' &&
    typeof state.form[VavatoFlowForms.deliveryForm].values !== 'undefined'
  );
};

export const getContactDetails = state => {
  return {
    ...state.form[VavatoFlowForms.deliveryForm].values.contact,
    address: state.form[VavatoFlowForms.deliveryForm].values.address,
  };
};

export const getDeliveryDatetimePeriods = state => {
  const delivery = getDeliveryForm(state);
  let datetime = getAuctionDeliveryDatetimePeriods(state);

  // Check for custom delivery date
  const customDeliveryDate = _get(delivery, 'values.custom_delivery_date_value', false);

  if (customDeliveryDate) {
    datetime = [
      {
        start: moment(customDeliveryDate, getDateFormatLocale())
          .hour(10)
          .toDate(),
        end: moment(customDeliveryDate, getDateFormatLocale())
          .hour(17)
          .toDate(),
      },
    ];
  }
  return datetime;
};

export const getDeliveryDetails = state => {
  if (deliveryFormReady(state)) {
    const delivery = getDeliveryForm(state);
    const address = delivery.values.address;
    const contact = delivery.values.contact;
    const datetime = getDeliveryDatetimePeriods(state);

    return {
      address,
      contact,
      available_datetime_periods: datetime,
      details: {
        situation: 'home',
        extras: [],
        instructions: _get(delivery, 'values.address.instructions', null),
      },
    };
  }
  return null;
};

export const getPickupDetails = state => {
  if (auctionFormReady(state)) {
    const address = state.form[VavatoFlowForms.auctionLotForm].values.lots[0].auction.address;
    const contact = state.form[VavatoFlowForms.deliveryForm].values.contact;
    const datetime = getDeliveryDatetimePeriods(state);

    return {
      address,
      contact,
      available_datetime_periods: datetime,
      details: {
        situation: 'auction',
        extras: [],
        instructions: '',
      },
    };
  }
  return null;
};

export const getItemSets = state => {
  const auctions = getAuctionForm(state);
  const lots = auctions.values.lots;
  return lots.map((lot: any, index) => {
    const images = lot.job_image ? [lot.job_image] : [];
    return {
      external_party: getExternalPartyIri(state, 'vavato'),
      attributes: {
        external_lot_no: lot.number,
        external_lot_id: lot.id.toString(),
        auction_type: AuctionTypes.VAVATO,
      },
      external_invoices: getUploadedFileDataByFormAndFieldName(
        state,
        VavatoFlowForms.auctionLotForm,
        `lots.[${index}].external_invoice`,
        '@id'
      ),
      title: lot.title,
      description: lot.description,
      images,
    };
  });
};

export const getAuctionDeliveryDatetimePeriods = state => {
  const dateTime = getAuctionPickupDatetimePeriod(state);
  return dateTime
    ? [
        {
          start: moment(dateTime.start)
            .hour(10)
            .toDate(),
          end: moment(dateTime.start)
            .hour(17)
            .toDate(),
        },
      ]
    : defaultDateTime();
};

// reducers
export const vavatoTransport = (state = defaultState, action) => {
  switch (action.type) {
    case types.SUBMIT_LOTS_DETAILS_START:
      return {
        ...state,
        request: {
          ...state.request,
          errors: null,
        },
      };
    case types.CALCULATE_PRICE:
      return {
        ...state,
        request: {
          ...state.request,
          price: action.payload,
        },
      };
    case types.PUSH_NOTIFICATION:
      return {
        ...state,
        request: {
          ...state.request,
          errors: action.payload,
        },
      };
    case types.PUSH_WARNING:
      return {
        ...state,
        request: {
          ...state.request,
          warnings: action.payload,
        },
      };
    case types.SET_PROGRESS_STEP:
      return {
        ...state,
        request: {
          ...state.request,
          step: action.payload,
        },
      };
    case types.SUBMIT_DELIVERY_START:
      return {
        ...state,
        request: {
          ...state.request,
          submitting: true,
          errors: null,
        },
      };
    case types.SUBMIT_DELIVERY_STOP:
      const status = action.payload.status;
      const resp = action.payload.data;
      return {
        ...state,
        request: {
          ...state.request,
          uuid: status === 201 ? resp['@id'].split('transport_requests/')[1] : null,
          submitting: false,
        },
      };
    default:
      return state;
  }
};

export default vavatoTransport;

import React from 'react';
import { Button, Col, Heading, IconArrow, Row, SectionWrapper } from '../../../brenger-shared-ui';
import { Translate } from 'react-localize-redux';
import { _CUSTOMER_SUPPORT_PHONE_NUMBER } from '../../../utils/global';
import PaymentMethods from '../../Payment/components/PaymentMethods';
import { PaymentInfo, PaymentMethod } from '../typings';

interface Props {
  paymentInfo: PaymentInfo;
  paymentMethods: PaymentMethod[];
  submitting: boolean;
  // @TODO: use actual form prop types for this value.
  valid: boolean;
  // @TODO: use actual form prop types for this value.
  handleSubmit(): void;
  // @TODO: use actual form prop types for this value.
  reset(): void;
  // @TODO: use actual form prop types for this value.
}

export const PaymentRetry: React.FC<Props> = props => (
  <SectionWrapper sectionStyle={'white'} extraClasses={'profile-dashboard-wrapper'} centerContent={true}>
    <Row>
      <Col xs={12} md={8} extraClasses={'offset-md-2 pb-1'}>
        <Heading size={5}>
          <Translate id={'request_flow.thank_you.payment_failed_heading'} />
        </Heading>
        <p>
          <Translate
            id={'request_flow.thank_you.payment_failed_text'}
            data={{ phonenumber: _CUSTOMER_SUPPORT_PHONE_NUMBER }}
          />
        </p>
      </Col>
      <Col xs={12} md={8} extraClasses={'offset-md-2'}>
        <form onSubmit={props.handleSubmit}>
          <PaymentMethods resetPaymentForm={props.reset} submitPaymentForm={props.handleSubmit} />
          <Button
            loading={props.submitting || props.paymentInfo.isSubmitting}
            disabled={
              !props.paymentMethods.length || props.submitting || !props.valid || props.paymentInfo.isSubmitting
            }
            buttonStyle={'secondary'}
            iconPos={'right'}
            fullWidth={true}
          >
            <Translate id={'request_flow.thank_you.pay_again_button'} />
            <IconArrow />
          </Button>
        </form>
      </Col>
    </Row>
  </SectionWrapper>
);

import * as React from 'react';
import './collapse.css';
import { IconSmile } from '../../basics/icons';
import { Heading } from '../../../index';

interface Props {
  open: boolean;
  header: string;
  children: any;
}

class Collapse extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: this.props.open,
    };
  }

  public render() {
    return (
      <div className={'collapse collapse--is-' + (this.state.open ? 'open' : 'closed')}>
        <div className={'collapse--header trigger'} onClick={() => this.setState({ open: !this.state.open })}>
          <Heading size={5}>
            {this.props.header} <IconSmile />
          </Heading>
        </div>
        <div className={'collapse--content'}>{this.props.children}</div>
      </div>
    );
  }
}

export default Collapse;

import GeneralFlow from '../modules/GeneralFlow';
import { TransportRequestStatus } from '../modules/StatusPage';
import BvaFlow from '../modules/BvaFlow';
import VavatoFlow from '../modules/VavatoFlow';
import IkeaFlow from '../modules/IkeaFlow';
import ThankYou from '../modules/Payment/components/ThankYou';
import Register from '../modules/User/containers/Register';
import Login from '../modules/User/containers/Login';
import VerifySocialAuth from '../modules/User/components/VerifySocialAuth';
import FailedSocialAuth from '../modules/User/components/FailedSocialAuth';
import NotGuaranteedThankYou from '../modules/GeneralFlow/containers/NotGuaranteedThankYou';
import PasswordReset from '../modules/User/containers/PasswordReset';
import PasswordNew from '../modules/User/containers/PasswordNew';
import NotPrePaidJobsPayment from '../modules/StatusPage/containers/NotPrePaidJobsPayment';
import Business, { prefix } from '../modules/Business';
import Dashboard from '../modules/Business/containers/Dashboard';
import Invoices, { prefix as InvoicesPrefix } from '../modules/User/containers/Invoices';

const routes = [
  {
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    path: '/register',
    component: Register,
    exact: true,
  },
  {
    path: '/password_reset',
    component: PasswordReset,
    exact: true,
  },
  {
    path: '/password_reset/:token',
    component: PasswordNew,
    exact: true,
  },
  {
    path: '/login/check',
    component: VerifySocialAuth,
    exact: true,
  },
  {
    path: '/login/error',
    component: FailedSocialAuth,
    exact: true,
  },
  {
    path: '/partner/general/:id/thank_you',
    component: ThankYou,
    exact: true,
  },
  {
    path: '/not_prepaid_jobs/payment',
    component: NotPrePaidJobsPayment,
    exact: true,
  },
  {
    path: '/transport_request/thank_you_not_guaranteed',
    component: NotGuaranteedThankYou,
    exact: true,
  },
  {
    path: '/transport_request',
    component: GeneralFlow,
    exact: false,
  },
  {
    path: '/track_and_trace',
    component: TransportRequestStatus,
    exact: false,
  },
  {
    path: '/dashboard',
    component: Dashboard,
    exact: true,
  },
  {
    path: InvoicesPrefix,
    component: Invoices,
    exact: true,
  },
  {
    path: '/partner/ikea/transport_request',
    component: IkeaFlow,
    exact: false,
  },
  {
    path: '/partner/ikea/:id/thank_you',
    component: ThankYou,
    exact: false,
  },
  {
    path: '/partner/bva/:id/thank_you',
    component: ThankYou,
    exact: true,
  },
  {
    path: '/partner/bva/transport_request',
    component: BvaFlow,
    exact: false,
  },
  {
    path: '/partner/vavato/transport_request',
    component: VavatoFlow,
    exact: false,
  },
  {
    path: '/partner/vavato/:id/thank_you',
    component: ThankYou,
    exact: true,
  },
  {
    path: prefix,
    component: Business,
    exact: false,
  },
];

export default routes;

import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Route } from 'react-router';
import {
  actions as flowActions,
  getCustomTimePeriodOptions,
  getFeeAuction,
  getFeeExtraPerson,
  getFeeFloor,
  getFeeHome,
  getFeeStore,
  getFlowTitleByStepAndSituation,
  getIsTransportHeavyWeight,
  getOpeningHours,
  getPickupAvailableDate,
  getPickupDestinationDetails,
  getPickupFloorData,
  getPickupSituation,
  getPickupStep,
  getUploadedInvoiceNames,
  transportRequestHasHelp,
} from '../ducks';
import { connect } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { SheetLayoutContentWrapper } from '../../../brenger-shared-ui';
import { prefix } from '../index';
import { SubStepHelp } from '../components/SubStepHelp';
import SubStepSituation from '../components/SubStepSituation';
import SubStepAuctionTypes from '../components/SubStepAuctionTypes';
import SubStepTime from '../components/SubStepTime';
import FooterLayout from './FooterLayout';
import SubStepEquipment from '../components/SubStepEquipment';
import { GeneralFlowForms, PickupProps } from '../interface';
import { StopType } from '../../../typings';
import SubStepInvoice from '../components/SubStepInvoice';
import _get from 'lodash/get';
import SubStepHomeFloorCombined from '../components/SubStepHomeFloorCombined';
import Onboarding from '../components/Marktplaats/Onboarding';
import BuyerForm from '../components/Marktplaats/BuyerForm';
import SubStepBvaAuctions from '../components/BvaAuctions/SubStepBvaAuctions';
import { SubStepHelpHeavy } from '../components/SubStepHelpHeavy';

type Props = PickupProps & InjectedFormProps<{}> & RouteComponentProps<{}>;

class Pickup extends React.Component<Props, any> {
  public componentWillMount(): void {
    this.props.setProgress(this.props.step);
  }
  public render() {
    const locality = _get(this.props.autocompleteState, 'locality', '');
    return (
      <form onSubmit={this.props.handleSubmit}>
        <SheetLayoutContentWrapper>
          <Route
            exact={true}
            path={`${prefix}/pickup`}
            render={() => (
              <SubStepSituation
                transport={this.props.transport}
                form={this.props.form}
                changeField={this.props.change}
                isPickup={true}
                locality={locality}
                feeHome={this.props.feeHome}
                feeAuction={this.props.feeAuction}
                feeStore={this.props.feeStore}
              />
            )}
          />
          <Route exact={true} path={`${prefix}/pickup/marktplaats`} render={() => <Onboarding />} />
          <Route exact={true} path={`${prefix}/pickup/marktplaats/form`} render={() => <BuyerForm />} />
          <Route
            exact={true}
            path={`${prefix}/pickup/auctions`}
            render={() => <SubStepAuctionTypes transport={this.props.transport} />}
          />
          <Route
            exact={true}
            path={`${prefix}/pickup/auctions/bva`}
            render={() => (
              <SubStepBvaAuctions
                escapeBvaFlow={this.props.escapeBvaFlow}
                form={this.props.form}
                errors={this.props.transport.errors}
              />
            )}
          />
          <Route
            exact={true}
            path={`${prefix}/pickup/invoice`}
            render={() => (
              <SubStepInvoice errors={this.props.transport.errors} situation={this.props.pickupSituation} />
            )}
          />
          <Route
            exact={true}
            path={`${prefix}/pickup/help`}
            render={() => {
              if (!this.props.isHeavyTransport) {
                return (
                  <SubStepHelp
                    errors={this.props.transport.errors}
                    isPickup={true}
                    locality={locality}
                    title={this.props.title('helpPickup')}
                  />
                );
              }
              return (
                <SubStepHelpHeavy
                  errors={this.props.transport.errors}
                  isPickup={true}
                  locality={locality}
                  title={this.props.title('helpPickup')}
                />
              );
            }}
          />
          <Route
            exact={true}
            path={`${prefix}/pickup/help/equipment`}
            render={() => <SubStepEquipment transport={this.props.transport} isPickup={true} locality={locality} />}
          />
          <Route
            exact={true}
            path={`${prefix}/pickup/home_and_floor`}
            render={() => (
              <SubStepHomeFloorCombined
                hasHelp={this.props.hasHelp}
                transport={this.props.transport}
                feeFloor={this.props.feeFloor}
                isPickup={true}
                locality={locality}
              />
            )}
          />
          <Route
            exact={true}
            path={`${prefix}/pickup/time`}
            render={() => (
              <SubStepTime
                openingHours={this.props.openingHours}
                transport={this.props.transport}
                isPickup={true}
                isNoPreferenceTimeOptionVisible={true}
                date={this.props.date}
                timeOptions={this.props.customTimePeriodOptions}
                situation={this.props.pickupSituation}
                title={this.props.title('timePickup')}
                subTitle={this.props.title('timeCustomPickup')}
              />
            )}
          />
          <FooterLayout />
        </SheetLayoutContentWrapper>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  transport: state.generalTransport,
  form: state.form[GeneralFlowForms.pickup],
  openingHours: getOpeningHours(state, StopType.pickup),
  date: getPickupAvailableDate(state),
  autocompleteState: getPickupDestinationDetails(state),
  floorData: getPickupFloorData(state),
  uploadedInvoice: getUploadedInvoiceNames(state),
  hasHelp: transportRequestHasHelp(state),
  feeHome: getFeeHome(state),
  feeAuction: getFeeAuction(state),
  feeStore: getFeeStore(state),
  feeExtraPerson: getFeeExtraPerson(state),
  feeFloor: getFeeFloor(state),
  customTimePeriodOptions: getCustomTimePeriodOptions(state, StopType.pickup),
  step: getPickupStep(state),
  title: stepName => getFlowTitleByStepAndSituation(state, stepName),
  pickupSituation: getPickupSituation(state),
  isHeavyTransport: getIsTransportHeavyWeight(state),
});

const mapDispatchToProps = dispatch => ({
  setProgress: step => dispatch(flowActions.setProgress(step)),
  escapeBvaFlow: () => dispatch(flowActions.escapeBvaFlow()),
  removeInvoice: (index: number) => dispatch(flowActions.removeInvoice(index)),
});

export default reduxForm<{}, Props>({
  form: GeneralFlowForms.pickup,
  destroyOnUnmount: false,
  forceUnregisterOnUmount: true,
  onSubmit: (formResults, dispatch) => dispatch(flowActions.submitPickup(formResults)),
})(connect(mapStateToProps, mapDispatchToProps)(Pickup));

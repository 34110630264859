/*
/* BRENGER HELPER FUNCTIONS
/* ================================================== */

import _get from 'lodash/get';

/**
 * return duplicate keys of object
 * @param data {object}
 * @param key {string}
 * @returns {object}
 */
const duplicate = (data, key): any => {
  const report = data.reduce((a, e) => {
    if (_get(e, key, null)) {
      a[e[key]] = e[key] in a ? ++a[e[key]] : 0;
    }
    return a;
  }, {});
  return report;
};

/**
 * Turn enum into array
 * @param enumme {enum}
 * @returns {Array}
 */
function ToArray(enumme) {
  return Object.keys(enumme).map(key => ({ key: enumme[key], value: key.replace(/_/g, ' ') }));
}

export { duplicate, ToArray };

import React from 'react';
import { Field } from 'redux-form';
import { Translate } from 'react-localize-redux';
import {
  FlashMessage,
  Heading,
  IconAuction,
  IconHome,
  IconShop,
  InputTiles,
  SheetLayoutContent,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { pickupDeliverySchema } from '../schema';
import { Situations, SubStepFieldNamesDelivery, SubStepFieldNamesPickup } from '../interface';
import { translate } from '../../../utils/localization';
import IconMarktplaats from '../../../assets/marktplaats/IconMarktplaats';

interface Props {
  form: any;
  changeField: any;
  isPickup: boolean;
  locality: string;
  transport: any;
  feeHome: number | null;
  feeAuction: number | null;
  feeStore: number | null;
}

const SubStepSituation = ({ isPickup, locality, transport }: Props) => {
  const fieldName = isPickup ? SubStepFieldNamesPickup.situation : SubStepFieldNamesDelivery.situation;
  const situationOptions = [
    {
      value: Situations.HOME,
      title: String(translate('request_flow.situation.house')),
      desc: String(translate('request_flow.situation.house_explain')),
      prefix: <IconHome />,
    },
    {
      value: Situations.MARKTPLAATS,
      title: String(translate('request_flow.situation.marktplaats')),
      desc: String(translate('request_flow.situation.marktplaats_explain')),
      prefix: <IconMarktplaats />,
    },
    {
      value: Situations.STORE,
      title: String(translate('request_flow.situation.shop')),
      desc: String(translate('request_flow.situation.shop_explain')),
      prefix: <IconShop />,
    },
    {
      value: Situations.AUCTION,
      title: String(translate('request_flow.situation.auction')),
      desc: String(translate('request_flow.situation.auction_explain')),
      prefix: <IconAuction />,
    },
  ];
  if (!isPickup) {
    situationOptions.splice(1, 2);
  }
  return (
    <>
      <SheetLayoutHeader>
        <Heading size={2}>
          <Translate
            id={'request_flow.' + (isPickup ? 'pickup' : 'delivery') + '.situation_question'}
            data={{ locality: <span className={'text--primary'}>{locality}</span> }}
          />
        </Heading>
      </SheetLayoutHeader>
      <SheetLayoutContent>
        <div className={'question-wrapper'}>
          <Field
            name={fieldName}
            type="select"
            validate={pickupDeliverySchema.situation}
            options={situationOptions}
            component={InputTiles}
          />
        </div>
        <div className={'general-errors-wrapper'}>
          {transport.errors && transport.errors !== '' && <FlashMessage type={'error'} message={transport.errors} />}
        </div>
      </SheetLayoutContent>
    </>
  );
};

export default SubStepSituation;

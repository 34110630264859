import React from 'react';
import { Field, FieldArray, InjectedFormProps } from 'redux-form';
import { Address, GroupAddress, Situations, User } from '../interface';
import {
  Col,
  GroupSelect,
  Heading,
  Input,
  InputHidden,
  InputPhoneNumber,
  Row,
  SectionWrapper,
  SheetLayoutContent,
  Textarea,
} from '../../../brenger-shared-ui';
import { AddressSchema, PersonSchema } from '../schema';
import { translate } from '../../../utils/localization';
import CompanyBillingAddress from './CompanyBillingAddress';
import { Translate } from 'react-localize-redux';
import { normalizePostalCode } from '../../../utils/normalisation';

interface ContactAddressInterface {
  isBusiness: boolean;
  countries: any;
  groupHistoricalAddresses: GroupAddress;
  isPickup?: boolean;
  situation?: string;
}

type Props = InjectedFormProps<{}> & ContactAddressInterface;

class ContactFields extends React.Component<Props, any> {
  public render() {
    const formType = this.props.fields.name.split('.')[1];
    let groupHistoricalAddresses: GroupAddress = this.props.groupHistoricalAddresses;
    if (!this.props.isPickup && groupHistoricalAddresses) {
      groupHistoricalAddresses = {
        delivery: groupHistoricalAddresses.delivery,
        pickup: groupHistoricalAddresses.pickup,
      };
    }
    return (
      <>
        <SectionWrapper centerContent={false} sectionStyle={'primary-light'} dashed={true}>
          <SheetLayoutContent>
            <Row>
              {formType !== 'customer' && (
                <>
                  {(groupHistoricalAddresses.pickup.options.length > 0 ||
                    groupHistoricalAddresses.delivery.options.length > 0) && (
                    <Col xs={12}>
                      <Field
                        name={`${formType}.address.historical_addresses`}
                        type="text"
                        component={GroupSelect}
                        groups={groupHistoricalAddresses}
                        label={translate('form.fields.address.historical_addresses.label')}
                      />
                    </Col>
                  )}
                  <Col xs={12}>
                    <Field
                      validate={AddressSchema[Address.line1]}
                      name={`${formType}.address.line1`}
                      type="text"
                      component={Input}
                      label={translate('form.fields.address.line1.label')}
                    />
                  </Col>
                  <Col xs={12} sm={4}>
                    <Field
                      validate={AddressSchema[Address.postal_code]}
                      name={`${formType}.address.postal_code`}
                      type="text"
                      component={Input}
                      normalize={normalizePostalCode}
                      label={translate('form.fields.address.postal_code.label')}
                    />
                  </Col>
                  <Col xs={12} sm={8}>
                    <Field
                      validate={formType === 'invoice' ? AddressSchema[Address.locality] : undefined}
                      name={`${formType}.address.locality`}
                      type="text"
                      component={Input}
                      disabled={formType !== 'invoice'}
                      label={translate('form.fields.address.locality.label')}
                    />
                  </Col>
                  <Col xs={12}>
                    <Field
                      component={Textarea}
                      type="text"
                      name={`${formType}.address.instructions`}
                      label={translate('form.fields.address.instructions.label')}
                    />
                  </Col>
                  <Field component={InputHidden} name={`${formType}.address.lat`} />
                  <Field component={InputHidden} name={`${formType}.address.lng`} />
                  <Field component={InputHidden} name={`${formType}.address.municipality`} />
                  <Field component={InputHidden} name={`${formType}.address.administrative_area`} />
                  <Field component={InputHidden} name={`${formType}.address.country_name`} />
                  <Field component={InputHidden} name={`${formType}.address.country_code`} />
                  {this.props.situation !== Situations.AUCTION && (
                    <Col xs={12} extraClasses={'pt-1'}>
                      <Heading size={4}>
                        <Translate id={'request_flow.contact.who_details'} />
                      </Heading>
                      <Row>
                        <Col xs={12} md={6}>
                          <Field
                            component={Input}
                            type={'checkbox'}
                            label={String(translate('request_flow.contact.add_my_details_' + formType))}
                            name={`${formType}.add_my_details`}
                          />
                        </Col>
                      </Row>
                    </Col>
                  )}
                </>
              )}
              {this.props.situation !== Situations.AUCTION && (
                <>
                  <Col xs={12} sm={6}>
                    <Field
                      validate={PersonSchema[User.first_name]}
                      name={`${formType}.contact.first_name`}
                      type="text"
                      component={Input}
                      label={translate('form.fields.user.first_name.label')}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Field
                      validate={PersonSchema[User.last_name]}
                      name={`${formType}.contact.last_name`}
                      type="text"
                      component={Input}
                      label={translate('form.fields.user.last_name.label')}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Field
                      validate={PersonSchema[User.email]}
                      name={`${formType}.contact.email`}
                      type="email"
                      component={Input}
                      label={translate('form.fields.user.email.label')}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Field
                      validate={PersonSchema[User.phone]}
                      name={`${formType}.contact.phone`}
                      type="tel"
                      normalize={(val: string) => {
                        // Inspiration: https://redux-form.com/6.0.0-rc.1/examples/normalizing/
                        // Small tweak: added "+" to the normalizer regex to account for leading "+" symbol.
                        return val ? val.replace(/[^+\d]/g, '') : val;
                      }}
                      component={InputPhoneNumber}
                      label={translate('form.fields.user.phone.label')}
                    />
                  </Col>
                  <Col xs={12}>
                    <small>
                      <Translate id={'request_flow.contact.explain_' + formType + '_details'} />
                    </small>
                  </Col>
                </>
              )}
            </Row>
          </SheetLayoutContent>
        </SectionWrapper>
        <SheetLayoutContent>
          <Row>
            {this.props.isBusiness && (
              <Col xs={12}>
                <div className={'company-details-wrapper'}>
                  <Heading size={4}>
                    <Translate id={'request_flow.contact.business_details'} />
                  </Heading>
                  <FieldArray
                    name={'contact.business'}
                    countries={this.props.countries}
                    component={CompanyBillingAddress}
                  />
                </div>
              </Col>
            )}
          </Row>
        </SheetLayoutContent>
      </>
    );
  }
}

export default ContactFields;

import { CoreIRI, Nullable } from '../../typings';

export interface PriceInterface {
  price: number;
  currency: string;
}

// https://docs.mollie.com/payments/status-changes
export enum PaymentState {
  open = 'open',
  pending = 'pending',
  canceled = 'canceled',
  expired = 'expired',
  failed = 'failed',
  paid = 'paid',
}

export enum PaymentFormKey {
  method = 'method',
  issuer = 'issuer',
}

export enum PaymentEventNames {
  COMPLETE = 'Payment complete',
  FAILED = 'Payment failed',
  RETRY = 'Payment retry',
}

export const RETRY_PAYMENT_STATUS = [PaymentState.canceled, PaymentState.expired, PaymentState.failed];

interface PaymentMethodIssuer extends CoreIRI {
  mollie_id: string;
  name: string;
  icon: string;
  active: boolean;
}

export interface PaymentMethod extends CoreIRI {
  mollie_id: string;
  icon: string;
  name: string;
  active: boolean;
  issuers: PaymentMethodIssuer[];
}

export interface PaymentInfo {
  id: Nullable<string>;
  transport_request: Nullable<any>;
  amount: Nullable<number>;
  state: Nullable<PaymentState>;
  polling: boolean;
  error: Nullable<any>;
  isSubmitting: boolean;
}

export interface Payment {
  methods: PaymentMethod[];
  paymentInfo: PaymentInfo;
}

// @TODO: create or use generic form interface and mix-in our values into values key
export interface PaymentForm {
  syncErrors: any;
  values?: {
    // @TODO: add enums to these?
    [PaymentFormKey.method]?: string;
    [PaymentFormKey.issuer]?: string;
  };
}

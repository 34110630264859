import { all, call, fork, put, PutEffect, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { actionTypes, arrayRemove, blur, change, clearFields } from 'redux-form';
import {
  actions as bvaActions,
  getAuctionDeliveryDatetimePeriods,
  getAuctionForm,
  getAuctionTransportOrderDeadline,
  getContactDetails,
  getDeliveryDatetimePeriods,
  getDeliveryDetails,
  getDeliveryForm,
  getItemSets,
  getPickupDetails,
  getTransportPrice,
  types as bvaActionTypes,
} from './ducks';
import { _BASE_URL, _BVA_BASE_URL, _DIFFERENT_DELIVERY_DATE_AMOUNT } from '../../utils/global';
import { duplicate } from '../../utils/helpers';
import { BvaFlowForms, Price } from './interface';
import { http } from '../../utils/request';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { translate } from '../../utils/localization';
import _get from 'lodash/get';
import moment from 'moment-timezone';
import { getLotIdFromBVAUrl, getSingleSearchParam } from '../../utils/router';
import { matchPath } from 'react-router';
import { _FLOW_TYPE, prefix } from './';
import * as appUtils from '../../utils/basics';
import { getCountryIri } from '../../state/ducks/baseReducer';
import { uploadFile } from '../../state/sagas/uploadSaga';
import { GeneralFlowForms } from '../GeneralFlow/interface';
import { actions as generalFlowActions } from '../GeneralFlow/ducks';
import { searchGeoPoint } from '../../utils/geo';
import { GeoPoint } from '../../typings';
import { getSessionIds } from '../../utils/eventTracking';

export function* importLots(action) {
  const path = action.payload.location.pathname;
  if (!matchPath(path, { path: `${prefix}/auction_lots_details` })) {
    return;
  }

  const ids = getLotIdsFromQueryParam(document.location.href);
  // if we don't have id's in the url
  if (ids.length === 0) {
    // then try to get it from the referrer
    const lotId = getLotIdFromBVAUrl(document.referrer);
    if (!lotId) {
      return;
    }
    /**
     * we got a useful referrer, push it to url,
     * this triggers a location change and we reconsider this import logic.
     * Plus we have a nice url for the user to copy to share or save.
     */
    const search = new URLSearchParams(action.payload.location.search);
    search.set('lots', String(lotId));
    yield put(push(path + '?' + search.toString()));
    return;
  }

  try {
    for (const id of ids) {
      const blurAction = blur(BvaFlowForms.auctionLotForm, `lots[${ids.indexOf(id)}].id`, id);
      yield put(blurAction);
    }
  } catch (error) {
    yield put(bvaActions.pushNotification(translate('request_flow.auction.errors.fetch_error')));
  }
}

export const getLotIdsFromQueryParam = (url: string): number[] => {
  const getLotsFromQueryParams = getSingleSearchParam(url, 'lots');
  const ids: number[] = [];
  if (!getLotsFromQueryParams || getLotsFromQueryParams === '{lot_id}') {
    return ids;
  }
  // multiple?
  if (getLotsFromQueryParams.indexOf(',') > -1) {
    const paramsIds = getLotsFromQueryParams.split(',');
    paramsIds.map(item => {
      if (item.length >= 6) {
        ids.push(Number(item));
      }
    });
    return ids;
  }
  // single
  if (getLotsFromQueryParams.length >= 6) {
    ids.push(Number(getLotsFromQueryParams));
  }
  return ids;
};

const handleLocationsResets = action => {
  const url = action.payload.location.pathname;
  if (url.indexOf(_FLOW_TYPE) === -1) {
    return;
  }
  document.body.scrollTop = document.documentElement.scrollTop = 0;
};

export function* priceHandler(action) {
  const priceFieldsArray = ['custom_delivery_date', 'custom_delivery_date_value'];
  if (matchPath(window.location.pathname, { path: `${_BASE_URL}${prefix}/delivery_details` })) {
    if (priceFieldsArray.includes(action.meta.field)) {
      yield call(calculatePrice);
    }
  }
}

export function* scanDeliveryAddress() {
  const delivery = yield select(getDeliveryDetails);
  if (matchPath(window.location.pathname, { path: `${_BASE_URL}${prefix}/delivery_details` })) {
    if (
      typeof delivery.address !== 'undefined' &&
      typeof delivery.address.postal_code !== 'undefined' &&
      typeof delivery.address.line1 !== 'undefined' &&
      typeof delivery.address.locality !== 'undefined'
    ) {
      const point = yield call(
        searchGeoPoint,
        `${delivery.address.line1}, ${delivery.address.postal_code}, ${delivery.address.locality}`
      );
      if (typeof point.data[0] !== 'undefined') {
        const deliveryPoint: GeoPoint = { lat: point.data[0].address.latitude, lng: point.data[0].address.longitude };

        const countryIri = yield select(getCountryIri, point.data[0].address.country_code);
        yield all([
          put(change(BvaFlowForms.deliveryForm, 'address.country', countryIri)),
          put(change(BvaFlowForms.deliveryForm, 'address.lat', deliveryPoint.lat)),
          put(change(BvaFlowForms.deliveryForm, 'address.lng', deliveryPoint.lng)),
        ]);
      } else {
        yield all([
          put(bvaActions.pushWarning(translate('request_flow.auction.errors.invalid_address'))),
          put(clearFields(BvaFlowForms.deliveryForm, false, true, 'address.lat')),
          put(clearFields(BvaFlowForms.deliveryForm, false, true, 'address.lng')),
        ]);
      }
    }
  }
}

export function* removeLot(action) {
  if (action.meta.form === BvaFlowForms.auctionLotForm) {
    yield call(validateLots);
    yield call(calculatePrice);
  }
}

export function* calculatePrice() {
  const auctionForm = yield select(getAuctionForm);
  const selectedDates = yield select(getDeliveryDatetimePeriods);
  const auctionDates = yield select(getAuctionDeliveryDatetimePeriods);
  const delivery = yield select(getDeliveryForm);
  let rate: number = Price.AMOUNT;
  if (auctionForm && _get(auctionForm, 'values', null)) {
    auctionForm.values.lots.map((lot, index) => {
      if (_get(lot, 'id', null) && index !== 0) {
        rate += Price.EXTRA_RATE;
      }
    });

    if (delivery && !moment(selectedDates[0].start).isSame(moment(auctionDates[0].start), 'day')) {
      rate += _DIFFERENT_DELIVERY_DATE_AMOUNT;
    }
    yield put({ type: bvaActionTypes.CALCULATE_PRICE, payload: rate });
  }
}

export function* fetchLotDetails(action) {
  if (action.meta.field.match(/lots\[[0-9]\].id/g) && action.payload !== '') {
    return yield call(fetchLotDetailsById, action.payload);
  } else if (action.meta.field.match(/lots\[[0-9]\].number/g) && action.payload !== '') {
    try {
      let fullLotNumber: string = '';
      // Check if we deal with a full url input
      if (action.payload.indexOf('https://') === 0) {
        // construct url
        const url = new URL(action.payload);
        // use only the origin pathname, to ignore query paramaters
        const urlMatch: any = matchPath(url.origin + url.pathname, {
          path: `https://www.bva-auctions.com/:locale/auction/lot/:auctionId/:lotUrlId`,
        });
        if (!urlMatch) {
          throw new Error('The given url does not match with the BVA pattern we need');
        }
        // We got a match on url,
        // Get all lots from the particular auction
        const bvaRequest = yield call(fetch, `${_BVA_BASE_URL}/auction/${urlMatch.params.auctionId}/lots`);
        if (bvaRequest.status !== 200) {
          throw new Error('Failed to get lots from auction');
        }
        const auctionLots = yield bvaRequest.json();
        // Filter out the lot that we are dealing with
        const matchedLot = auctionLots.find(lot => lot.id === Number(urlMatch.params.lotUrlId));
        if (!matchedLot) {
          throw new Error('No matched lot found in this auction');
        }
        // Set full number
        fullLotNumber = matchedLot.fullNumber;
      } else {
        // We are not dealing with an link as input, so the input is the full number.
        fullLotNumber = action.payload;
      }
      // Deconstruct full number to auction id and lot number
      const [auctionId, lotNumber] = fullLotNumber.split('-');
      // Handle as normal BVA
      return yield call(fetchLotDetailsByNumber, auctionId, lotNumber);
    } catch (e) {
      appUtils.logger(e);
      // This saga is used in both auction (general) flow and the dedicated BVA flow
      // So we need to decide where to show the error
      if (_get(action, 'meta.form', '') === GeneralFlowForms.pickup) {
        yield put(generalFlowActions.pushNotification(translate('request_flow.auction.errors.fetch_error')));
        return;
      }
      yield put(bvaActions.pushNotification(translate('request_flow.auction.errors.fetch_error')));
    }
  }
}

export function* fetchLotDetailsById(lotId) {
  try {
    const response = yield call(fetch, `${_BVA_BASE_URL}/lot/${lotId}`);
    const json = yield response.json();
    return yield call(parseLotApiResponse, json);
  } catch (error) {
    yield put(bvaActions.pushNotification(translate('request_flow.auction.errors.fetch_error')));
  }
}

export function* fetchLotDetailsByNumber(auctionId, lotNumber) {
  try {
    const response = yield call(fetch, `${_BVA_BASE_URL}/lot/${auctionId}/${lotNumber}/lotbynumber`);
    const json = yield response.json();
    return parseLotApiResponse(json);
  } catch (error) {
    yield put(bvaActions.pushNotification(translate('request_flow.auction.errors.fetch_error')));
  }
}

export function parseLotApiResponse(json) {
  return {
    id: json.id,
    number: json.fullNumber,
    auctionId: json.auctionId,
    title: json.name.nl,
    description: json.description.nl,
    thumbnail: json.thumbnailUrl,
    image: json.imageUrl,
    address: {
      line1: json.location.address,
      line2: json.location.address2,
      postalCode: json.location.postalCode,
      countryCode: json.location.countryId,
      locality: json.location.city,
      latitude: json.location.latitude,
      longitude: json.location.longitude,
    },
  };
}

export function* fetchAuctionDetails(auctionId) {
  try {
    const response = yield call(fetch, `${_BVA_BASE_URL}/auction/${auctionId}/summary`);
    const json = yield response.json();
    if (json.collectionDaySummaries.length === 0) {
      yield all([
        put(arrayRemove(BvaFlowForms.auctionLotForm, 'lots', 0)),
        put(bvaActions.pushNotification('We could not load the auction details!')),
      ]);
      return null;
    }
    const collectionDay = json.collectionDaySummaries[0];
    const country = yield select(getCountryIri, collectionDay.location.countryId);
    const auctionDetails = {
      id: json.auctionId,
      address: {
        line1: collectionDay.location.address,
        line2: collectionDay.location.address2,
        postalCode: collectionDay.location.postalCode,
        locality: collectionDay.location.city,
        latitude: collectionDay.location.latitude,
        longitude: collectionDay.location.longitude,
        country,
      },
      datetimePeriod: {
        start: _get(collectionDay, 'startDate', null) ? collectionDay.startDate : null,
        end: _get(collectionDay, 'endDate', null) ? collectionDay.endDate : null,
      },
    };

    const addToFormActions = [] as PutEffect[];
    addToFormActions.push(put(change(BvaFlowForms.auctionLotForm, 'auction', auctionDetails)));

    yield all(addToFormActions);
  } catch (error) {}
}

export function postTransportRequest(customer, pickups, deliveries, price, items) {
  return http()
    .post('/transport_requests', {
      source_flow: 'BVA',
      item_sets: items,
      price,
      customer,
      pickups,
      deliveries,
      session_ids: getSessionIds(),
      frontend_url: window.location.href,
    })
    .then(resp => resp);
}

export function* handleResponse(e) {
  if (typeof e.response !== 'undefined' && typeof e.response.status !== 'undefined') {
    switch (e.response.status) {
      case 400:
        yield put(bvaActions.pushNotification(e.response.data['hydra:description']));
        break;
      default:
        yield put(bvaActions.pushNotification(translate('request_flow.auction.errors.handle_error')));
        break;
    }
  } else {
    if (e.toString().indexOf('Network Error') > 0) {
      yield put(bvaActions.pushNotification(translate('request_flow.auction.errors.handle_error')));
    }
  }
}

export function* submitDelivery() {
  if (matchPath(window.location.pathname, { path: `${_BASE_URL}${prefix}/delivery_details` })) {
    const auctionForm = yield select(getAuctionForm);
    if (!auctionForm) {
      yield put(push(`${prefix}/auction_lots_details`));
    }
    try {
      const customer = yield select(getContactDetails);
      const pickups = [yield select(getPickupDetails)];
      const deliveries = [yield select(getDeliveryDetails)];
      const price = yield select(getTransportPrice);
      const items = yield select(getItemSets);
      const response = yield call(postTransportRequest, customer, pickups, deliveries, price, items);
      yield all([put(bvaActions.submitDeliveryStop(response)), put(push('/partner/bva/transport_request/payment'))]);
    } catch (e) {
      yield all([put(bvaActions.submitDeliveryStop(e)), call(handleResponse, e)]);
    }
  }
}

export function* validateLots() {
  yield put(bvaActions.pushNotification(null));

  const auctionForm = yield select(getAuctionForm);
  const lots = auctionForm.values.lots;
  const currentLots = auctionForm.values.lots;
  const auctionTransportOrderDeadline = yield select(getAuctionTransportOrderDeadline);

  // check different auction id
  const duplicateAuctions = currentLots.length > 1 ? duplicate(currentLots, 'auctionId') : [];

  if (Object.keys(duplicateAuctions).length > 1) {
    yield put(bvaActions.pushNotification(translate('request_flow.auction.errors.different_auction')));
    return;
  }
  if (lots && lots.length > 0 && typeof lots[0] !== 'undefined' && typeof lots[0].number !== 'undefined') {
    for (const lot of lots) {
      if (_get(auctionForm, 'values.auction.datetimePeriod.start', null)) {
        if (!auctionForm.values.auction.datetimePeriod.start) {
          yield put(bvaActions.pushNotification(translate('request_flow.auction.errors.no_pickup_datetime')));
          return;
        }
        if (moment().diff(auctionTransportOrderDeadline) > 0) {
          yield put(
            bvaActions.pushNotification(translate('request_flow.auction.errors.auction_pickup_datetime_expired'))
          );
          return;
        }
      }

      // Check for list of BvaAuctions we support
      const SUPPORTED_AUCTION_IDS = process.env.REACT_APP_SUPPORTED_BVA_AUCTION_IDS || '0';
      if (SUPPORTED_AUCTION_IDS.indexOf(lot.auctionId) < 0) {
        yield put(bvaActions.pushNotification(translate('request_flow.auction.errors.unsupported_auction')));
        return;
      }

      const UNSUPPORTED_LOT_NUMBERS = process.env.REACT_APP_UNSUPPORTED_BVA_LOT_NUMBERS || '0';
      if (UNSUPPORTED_LOT_NUMBERS.indexOf(lot.number) > -1) {
        yield put(bvaActions.pushNotification(translate('request_flow.auction.errors.unsupported_lot')));
        return;
      }
    }
  }
}

export function* submitLot(action) {
  yield put(bvaActions.pushNotification(null));

  const redirectUrl = '/partner/bva/transport_request/delivery_details';
  const lots = action.payload.lots;
  if (lots && lots.length > 0 && typeof lots[0] !== 'undefined' && typeof lots[0].number !== 'undefined') {
    yield put(push(redirectUrl));
  } else {
    yield put(bvaActions.pushNotification(translate('request_flow.auction.errors.at_least_one')));
  }
}

export function* watchLotInput(action) {
  // watching lot input changes
  if (matchPath(window.location.pathname, { path: `${_BASE_URL}${prefix}/auction_lots_details` })) {
    if (
      action.meta.form === BvaFlowForms.auctionLotForm &&
      action.meta.field.match(/lots\[[0-9]\].[id|number]/g) !== null
    ) {
      yield call(fetchLot, action);
    }
  }
}

export function* watchDeliveryAddress(action) {
  // fetching delivery address
  if (matchPath(window.location.pathname, { path: `${_BASE_URL}${prefix}/delivery_details` })) {
    if (action.meta.form === BvaFlowForms.deliveryForm && action.meta.field.indexOf('address') >= 0) {
      yield call(scanDeliveryAddress);
    }
  }
}

export function* fetchLot(action) {
  // TODO: refactor and write test
  yield put(bvaActions.pushNotification(null));

  try {
    // fetch lot details
    const lotDetails = yield call(fetchLotDetails, action);
    if (lotDetails) {
      if (lotDetails.image) {
        const lotImage = yield call(uploadFile, lotDetails.image, '/job_images');
        if (lotImage.status === 201) {
          lotDetails['job_image'] = lotImage.data['@id'];
        }
      }

      yield put(change(BvaFlowForms.auctionLotForm, action.meta.field.split('.')[0], lotDetails));
      // fetch auction details
      if (action.meta.field === 'lots[0].id' || action.meta.field === 'lots[0].number') {
        yield call(fetchAuctionDetails, lotDetails.auctionId);
      }

      yield call(validateLots);
      // price calculation
      yield call(calculatePrice);
    }
  } catch (error) {
    // something went wrong with fetching
    const lotsForm = yield select(getAuctionForm);
    const currentLots = lotsForm.values.lots;
    // clean added failed lot from store
    yield put(arrayRemove(BvaFlowForms.auctionLotForm, 'lots', currentLots.length - 1));
    yield put(bvaActions.pushNotification(translate('request_flow.auction.errors.fetch_error')));
  }
}

function* handleProgressBar(action) {
  const url = action.payload.location.pathname;
  if (url.indexOf(_FLOW_TYPE) === -1) {
    return;
  }
  const event = {
    event: 'Flow progress',
    flow_type: _FLOW_TYPE,
  };

  if (matchPath(url, { path: `${prefix}/auction_lots_details` })) {
    event['progress_label'] = 'Step 1 - Lots';
    event['progress_value'] = 1;
    yield put(bvaActions.setProgress(1));
  }
  if (matchPath(url, { path: `${prefix}/delivery_details` })) {
    event['progress_label'] = 'Step 2 - Delivery';
    event['progress_value'] = 2;
    yield put(bvaActions.setProgress(2));
  }
  if (matchPath(url, { path: `${prefix}/payment` })) {
    event['progress_label'] = 'Step 3 - Payment';
    event['progress_value'] = 3;
    yield put(bvaActions.setProgress(3));
  }

  if (!_get(event, 'progress_label', false)) {
    return;
  }

  appUtils.pushToDataLayer(event);
}

function* lotSaga() {
  yield takeLatest(actionTypes.ARRAY_REMOVE, removeLot);

  yield takeLatest(LOCATION_CHANGE, importLots);
  yield takeLatest(LOCATION_CHANGE, handleLocationsResets);
  yield takeLatest(LOCATION_CHANGE, handleProgressBar);

  yield takeEvery(actionTypes.BLUR, watchLotInput);
  yield takeEvery(actionTypes.BLUR, watchDeliveryAddress);

  yield takeLatest(actionTypes.CHANGE, priceHandler);

  yield takeLatest(bvaActionTypes.SUBMIT_LOTS_DETAILS_START, submitLot);
  yield takeLatest(bvaActionTypes.SUBMIT_DELIVERY_START, submitDelivery);
}

export function* BvaSagas() {
  yield fork(lotSaga);
}

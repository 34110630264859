import React from 'react';
import _get from 'lodash/get';
import { Translate } from 'react-localize-redux';
import { Col, Heading, Row } from '../../../brenger-shared-ui';
import { Moment } from 'moment';

interface Props {
  form: any;
  auctionPickupDate: Moment;
}

export class RenderAuctionField extends React.Component<Props, any> {
  public render() {
    const addressData = _get(this.props.form, 'values.lots[0].address', false);
    if (!addressData) {
      return null;
    }
    return (
      <Row>
        <Col xs={12}>
          <Heading size={5}>
            <Translate id={'request_flow.location.pickup'} />
          </Heading>
        </Col>
        <Col xs={12} extraClasses={'pb-1'}>
          {addressData.line1 ? addressData.line1 + ', ' : ''}
          {addressData.postalCode ? addressData.postalCode + ', ' : ''}
          {addressData.locality ? addressData.locality : ''}
          <br />
          <Translate id={'request_flow.auction.pickup_date'} />:{' '}
          {this.props.auctionPickupDate.format('dddd DD MMMM YYYY')}
        </Col>
      </Row>
    );
  }
}

export default RenderAuctionField;

import * as React from 'react';
import './sheetlayout.css';
import classNames from 'classnames';

interface Props {
  className?: string;
}

export const SheetLayoutHeader: React.FC<Props> = ({ className, ...props }) => {
  return <div className={classNames('sheet-layout--main-header', className)} {...props} />;
};

export const SheetLayoutContentWrapper: React.FC<Props> = ({ className, ...props }) => {
  return <div className={classNames('sheet-layout--content-wrapper', className)} {...props} />;
};

export const SheetLayoutContent: React.FC<Props> = ({ className, ...props }) => {
  return <div className={classNames('sheet-layout--main-content', className)} {...props} />;
};

export const SheetLayoutSidebarContent: React.FC<Props> = ({ className, ...props }) => {
  return <div className={classNames('sheet-layout--sidebar-content', className)} {...props} />;
};

export const SheetLayoutFooter: React.FC<Props> = ({ className, ...props }) => {
  return <div className={classNames('sheet-layout--footer', className)} {...props} />;
};

export const SheetLayoutNotifications: React.FC<Props> = ({ className, ...props }) => {
  return <div className={classNames('sheet-layout--notifications', className)} {...props} />;
};

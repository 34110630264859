export const sentry = {
  configure: () => {
    return (function() {
      const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
      if (!sentryDsn) {
        return;
      }
      let script = document.createElement('script');
      script.setAttribute('src', 'https://browser.sentry-cdn.com/5.11.2/bundle.min.js');
      script.addEventListener('load', function() {
        // @ts-ignore
        window.Sentry.init({
          dsn: sentryDsn,
          ignoreErrors: sentry.getIgnoreErrors(),
          blacklistUrls: sentry.getIgnoreUrls(),
          environment: process.env.REACT_APP_ENV,
          maxValueLength: 5000,
        });
      });
      const body = document.querySelector('body');
      if (body) {
        body.appendChild(script);
      }
    })();
  },
  getIgnoreErrors: () => {
    return [
      'hj.hq is not a function',
      'Could not load "onion".',
      'jQuery is not defined',
      'Ongeldig argument.', // Error Occurs When Microsoft users Try to Import a Solution into Dynamics 365
      "/Cannot read property '_avast_submit' of undefined/g",
      'parentRef.postMessage is not a function',
      /hj.hq/,
      /fbq/,
      'QuotaExceededError: QuotaExceededError',
      "Argument 1 ('element') to Window.getComputedStyle must be an instance of Element",
      'QuotaExceededError: The quota has been exceeded.',
      /initFreshChat/,
      'geannuleerd',
      'Abgebrochen',
      'cancelled',
    ];
  },
  getIgnoreUrls: () => {
    return [
      /https:\/\/maps.googleapis.com\/maps\/api\/js(\?{1}.*)/,
      /https:\/\/wchat.freshchat.com\/js\/widget.js/,
      /https:\/\/cdn.jsdelivr.net\/npm\/jquery-validation@1.17.0\/dist\/additional-methods.min.js/,
      /https:\/\/www.brenger.nl\/nl\/hoe-het-werkt/,
      /www.brenger.nl\/sites\/default\//,
      /script.hotjar.com/,
      /maps.googleapis.com\/maps-api-v3\//,
      /\/themes\/brenger_rebranding_theme\//,
    ];
  },
};

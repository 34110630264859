import * as React from 'react';
import { BrengerLogo, IconSmile } from '../../basics/icons';
import { ProgressSteps } from '../../other/progresssteps';
import { ProgressBar } from '../../other/progressbar';
import './progressheader.css';

interface Props {
  currentStep: number;
  totalSteps: number;
  thirdPartyLogo?: string;
  thirdPartyText?: string;
  exitUrl?: any;
  logoUrl?: any;
  goBack: any;
  goBackText: string;
  goBackAlwaysAvailable?: boolean;
}

class ProgressHeader extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
  }
  public componentDidMount() {
    this.checkProcess();
    const body = document.querySelector('body');
    if (!body) {
      return;
    }
    body.classList.add('bd-progress-header-loaded');
  }
  public componentWillUnmount() {
    const body = document.querySelector('body');
    if (!body) {
      return;
    }
    body.classList.remove('bd-progress-header-loaded', 'bd-progress-header-finished', 'bd-hide-menu');
  }
  public componentDidUpdate() {
    this.checkProcess();
  }

  public checkProcess() {
    const body = document.querySelector('body');
    if (!body) {
      return;
    }
    if (this.props.currentStep > this.props.totalSteps) {
      body.classList.add('bd-progress-header-finished', 'bd-hide-menu');
      body.classList.remove('bd-progress-header-loaded');
    } else {
      body.classList.remove('bd-progress-header-finished', 'bd-hide-menu');
    }
  }

  public render() {
    const currentStep = this.props.currentStep;
    return (
      <header
        className={'progress-header' + (this.props.thirdPartyLogo ? ' progress-header--with-third-party-logo' : '')}
      >
        {(this.props.goBackAlwaysAvailable || (currentStep !== 1 && currentStep >= 2)) && (
          <span className={'go-back trigger'} title={this.props.goBackText} onClick={this.props.goBack}>
            <IconSmile />
            <span className={'go-back-text'}>{this.props.goBackText}</span>
          </span>
        )}
        {currentStep === 1 && <div className={'progress-header--placeholder'}>&nbsp;</div>}
        <div className={'progress-header--logo-wrapper'}>
          <a href={'https://www.brenger.nl'} onClick={this.props.logoUrl}>
            <BrengerLogo />
            {this.props.thirdPartyLogo && (
              <img
                src={this.props.thirdPartyLogo}
                alt={'third party brand'}
                className={'progress-header--third-party-logo'}
              />
            )}
          </a>
          {this.props.thirdPartyText && (
            <div className={'progress-header--third-party-text'}>{this.props.thirdPartyText}</div>
          )}
        </div>
        <ProgressSteps
          currentStep={this.props.currentStep}
          totalSteps={this.props.totalSteps}
          exitUrl={this.props.exitUrl}
        />
        <ProgressBar currentStep={this.props.currentStep} totalSteps={this.props.totalSteps} />
      </header>
    );
  }
}

export default ProgressHeader;

import { DatetimePeriod, PriceDataPart } from '../../typings';
import { AddressCreationParams } from '@brenger/api-client';

// Enums
export enum BusinessForms {
  PREVIEW = '@@BusinessFlow/Preview',
  DESTINATION = '@@BusinessFlow/Destination',
  ITEMS_AND_SERVICE = '@@BusinessFlow/ItemsAndServices',
}

export enum BusinessFields {
  RETURN_ORDER = 'return',
  CLIENT_REFERENCE = 'client_reference',
  INVOICE = 'invoice',
  DEPOT_SELECT = 'depot_select',
  DELIVERY_FIRST_NAME = 'delivery.contact.first_name',
  DELIVERY_LAST_NAME = 'delivery.contact.last_name',
  DELIVERY_EMAIL = 'delivery.contact.email',
  DELIVERY_PHONE = 'delivery.contact.phone',
  DELIVERY_LINE_1 = 'delivery.address.line1',
  DELIVERY_POSTAL_CODE = 'delivery.address.postal_code',
  DELIVERY_LOCALITY = 'delivery.address.locality',
  DELIVERY_LATITUDE = 'delivery.address.lat',
  DELIVERY_LONGITUDE = 'delivery.address.lng',
  DELIVERY_MUNICIPALITY = 'delivery.address.municipality',
  DELIVERY_ADMINISTRATIVE_AREA = 'delivery.address.administrative_area',
  DELIVERY_COUNTRY = 'delivery.address.country',
  DELIVERY_INSTRUCTIONS = 'delivery.details.instructions',
  ITEM_SETS = 'itemSets',
  DATE = 'date',
  SERVICE_EXTRA_DRIVER = 'extra_driver',
}

export enum BusinessAttributes {
  // NOTE: this atribute key gets ignored in the driver dashboard
  // When the ORDER_TYPE value changes, please be aware to adjust that in the driver dashboard too.
  ORDER_TYPE = 'business_order_type',
}

export enum BusinessAttributesValues {
  ORDER_TYPE_NORMAL = 'normal',
  ORDER_TYPE_RETURN = 'return',
}

// interfaces
export interface BusinessState {
  business_name: null;
  has_business_flow: boolean;
  depots: {
    loading: boolean;
    addresses: AddressCreationParams[];
  };
  transport_request: {
    uuid: string;
    price: {
      loading: boolean;
      vat: PriceDataPart;
      incl_vat: PriceDataPart;
      excl_vat: PriceDataPart;
    };
    date_time_periods: {
      options: DatetimePeriod[];
      index: number;
    };
    layout: {
      sheet: boolean;
      step: number;
    };
    error: null;
  };
}

export interface DateSelectOption {
  value: string;
  title: any;
}

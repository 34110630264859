import * as React from 'react';
import { Translate } from 'react-localize-redux';

import { _CLIENT_SERVICES_LINK } from '../../../../utils/global';
import { trackEvent } from '../../../../utils/eventTracking';
import { Card, CirclePerson2, Col, Heading, Row, SectionWrapper } from '../../../';

export const ErrorPage: React.FC = () => {
  return (
    <Col xs={12}>
      <SectionWrapper sectionStyle="primary" centerContent={true} fullScreen={true}>
        <Col xs={12}>
          <div className="pt-1 pb-1">
            <div className="dashboard-welcome">
              <div className="icon-wrapper">
                <div className="dashboard-welcome--logo">
                  <CirclePerson2 />
                </div>
              </div>
              <Heading size={2} extraClasses="text--center no-margin">
                <Translate id="error_boundary.title" />
              </Heading>
              <Heading size={3} extraClasses="text--center no-margin">
                <Translate id="error_boundary.sub_title" />
              </Heading>
            </div>
          </div>
          <Row>
            <Col xs={12} extraClasses="pb-1">
              <Card>
                <Heading size={5} extraClasses="no-margin">
                  <Translate id="error_boundary.description_header" />
                </Heading>
                <Translate id="error_boundary.description" />
              </Card>
            </Col>
            <Col xs={6} extraClasses="pb-1">
              <Card
                withBrengerSmile={true}
                onClick={() => {
                  // Push start over event to analytics
                  trackEvent({
                    eventCategory: 'Error page',
                    eventAction: 'Start over',
                    eventLabel: 'After fatal error',
                  });
                  // Remove all lingering state from local and session storage.
                  // This ensures that forms and notifications are not re-hydrated
                  // upon reloading the page again.
                  window.localStorage.clear();
                  window.sessionStorage.clear();
                  // Navigate back to origin.
                  window.location.assign(window.location.origin);
                }}
              >
                <div className="pb-0-5 text--bold">
                  <Translate id="error_boundary.ctas.start_over" />
                </div>
              </Card>
            </Col>
            <Col xs={6} extraClasses="pb-1">
              <Card
                withBrengerSmile={true}
                onClick={() => {
                  // Push client services redirect to analytics
                  trackEvent({
                    eventCategory: 'Error page',
                    eventAction: 'Client services',
                    eventLabel: 'After fatal error',
                  });
                  // Navigate to client services page on the static site.
                  window.location.assign(_CLIENT_SERVICES_LINK);
                }}
              >
                <div className="pb-0-5 text--bold">
                  <Translate id="error_boundary.ctas.contact_support" />
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
      </SectionWrapper>
    </Col>
  );
};

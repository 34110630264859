import * as React from 'react';
import classNames from 'classnames';

import './sectionwrapper.css';

interface Props {
  centerContent?: boolean;
  sectionStyle: string;
  extraClasses?: string;
  dashed?: boolean;
  fullScreen?: boolean;
}

export const SectionWrapper: React.FC<Props & React.HTMLProps<HTMLDivElement>> = ({
  centerContent = true,
  sectionStyle,
  extraClasses,
  dashed = false,
  fullScreen = false,
  children,
  style,
}) => {
  return (
    <section
      style={style}
      className={classNames(
        'section-wrapper',
        `section-wrapper--${sectionStyle}`,
        { 'section-wrapper--dashed': dashed, 'section-wrapper--full-screen': fullScreen },
        extraClasses
      )}
    >
      <div className={classNames('section-wrapper-content', { 'main-centered-content': centerContent })}>
        {children}
      </div>
    </section>
  );
};
export default SectionWrapper;

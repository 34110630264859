import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { actions as flowActions } from '../ducks';
import { connect } from 'react-redux';
import { InjectedFormProps } from 'redux-form';
import { push } from 'connected-react-router';
import {
  FlashMessage,
  Heading,
  SheetLayoutContent,
  SheetLayoutContentWrapper,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { Translate } from 'react-localize-redux';
import FooterLayout from './FooterLayout';
import { hotjar } from '../../../configs/hotjar';
import { translate } from '../../../utils/localization';

type Props = InjectedFormProps<{}> & RouteComponentProps<{}>;

interface State {
  expiredSession: boolean;
}

class Destination extends React.Component<Props, State> {
  public constructor(props) {
    super(props);
    this.state = {
      expiredSession: false,
    };
  }
  public componentWillMount(): void {
    this.props.setProgress(1);
  }

  public componentDidMount() {
    hotjar.addTag(['GENERAL_FLOW_KICKOFF']);
    if (window.location.href.indexOf('expired=true') > -1) {
      this.setState({ expiredSession: true });
    }
    /*
     * BRENGER_WIDGET_RENDER
     * Custom event that takes care of widget rendering
     * See event listener here: stand-alone/ts/widget.ts:12
     * */
    document.dispatchEvent(new CustomEvent('BRENGER_WIDGET_RENDER'));
    /*
     * BRENGER_WIDGET_SUBMIT
     * Custom event listener, event will pass a url that we dispatch here.
     * See event fire here: stand-alone/ts/autocomplete/autocompleteForm.ts:71
     * */
    document.addEventListener('BRENGER_WIDGET_SUBMIT', (event: CustomEvent) => {
      this.props.push(event.detail.url);
    });
    /*
     * BRENGER_AUTOCOMPLETE_SELECTED
     * Custom event listener, event will pass address details.
     * See event fire here: stand-alone/ts/autocomplete/autocompleteInput.ts:105
     * */
    document.addEventListener('BRENGER_AUTOCOMPLETE_SELECTED', (event: CustomEvent) => {
      const stopType = event.detail.name.indexOf('pickup') > -1 ? 'pickup' : 'delivery';
      this.props.setDestinationDetails(event.detail.addressDetails, stopType);
    });
  }

  public submitWidget(event) {
    // I used to trigger a submit event on the form, but somehow Firefox and Safari ignore default behaviour
    // By triggering a click on the button, we rely on default behaviour of the button, which does work and submit fields
    const button = document.querySelector('.internal-widget form button') as HTMLButtonElement;
    if (!button) {
      return;
    }
    button.click();
  }

  public resetFlow() {
    this.props.reset();
    setTimeout(() => {
      window.location.assign(window.location.href.split('?')[0]);
    }, 500);
  }

  public render() {
    const generalErrors = this.props.generalErrors;
    return (
      <>
        {process.env.REACT_APP_ENV === 'dev' && <button onClick={() => this.resetFlow()}>reset</button>}
        <SheetLayoutContentWrapper>
          <SheetLayoutHeader>
            <Heading size={2}>
              <Translate id={'request_flow.headers.from_to'} />
            </Heading>
            {this.state.expiredSession && (
              <FlashMessage type={'error'} message={String(translate('request_flow.errors.reset_form'))} />
            )}
          </SheetLayoutHeader>
          <SheetLayoutContent>
            <div className="internal-widget" data-brenger-tr-widget={true} />
            <div className={'item-errors'}>
              {generalErrors && generalErrors !== '' && <FlashMessage type={'error'} message={generalErrors} />}
            </div>
          </SheetLayoutContent>
        </SheetLayoutContentWrapper>
        <FooterLayout customSubmitFunction={this.submitWidget} />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  transport: state.generalTransport,
  generalErrors: state.generalTransport.request.errors,
});

const mapDispatchToProps = dispatch => ({
  setProgress: step => dispatch(flowActions.setProgress(step)),
  reset: () => dispatch(flowActions.resetForms()),
  push: path => dispatch(push(path)),
  setDestinationDetails: (details, stopType) => dispatch(flowActions.setDestinationDetails(details, stopType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Destination);

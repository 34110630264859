import React from 'react';
import { connect } from 'react-redux';
import { Button, Heading, IconQuestionBubble, Modal } from '../../../brenger-shared-ui';
import {
  actions as flowActions,
  getDeliveryAvailableDate,
  getPickupAvailableDate,
  isRequestAboveMaxDistance,
  isTimeModalOpen,
} from '../ducks';
import { Translate } from 'react-localize-redux';
import { translate } from '../../../utils/localization';
import '../../../configs/chatWidget';
import { customTimePreference } from '../interface';
import van from '../../../brenger-shared-ui/assets/images/van@2x.png';
import { printDatesAsString } from '../../../utils/datetime';

interface Props {
  setHelpOpen: any;
  closeTimeModal: any;
  transport?: any;
  pickupDate: string[];
  deliveryDate: string[];
  isTimeModalOpen: boolean;
  setCustomTimePreference: any;
  isRequestAboveMaxDistance: boolean;
  isRequestAboveMaxDateDistance: boolean;
}

interface State {
  transKey: any;
}

export class ModalLayout extends React.Component<Props, State> {
  public constructor(props) {
    super(props);
    this.state = {
      transKey: null,
    };
  }
  public toggleChat() {
    const body = document.querySelector('body');
    if (body) {
      const timing = body.classList.contains('chat--is-visible') ? 0 : 350;
      if (!body.classList.contains('chat--is-visible')) {
        body.classList.add('chat--is-visible');
        if (window.fcWidget && typeof window.fcWidget.open !== 'undefined') {
          setTimeout(() => {
            window.fcWidget.open();
          }, timing);
        }
      } else {
        body.classList.remove('chat--is-visible');
      }
    }
  }

  public render() {
    const transKey =
      this.props.transport.layout.modal_help_type === '' ? '' : '_' + this.props.transport.layout.modal_help_type;
    return (
      <>
        <Modal
          header={
            <div className={'help-header text--center'}>
              <IconQuestionBubble />
              <Translate id={'request_flow.help.title'} />
            </div>
          }
          onClick={this.props.setHelpOpen}
          modalStyle={'blue'}
          open={this.props.transport.layout.modal_help}
        >
          <div className={'help-content text--center'}>
            <Heading size={3}>
              <Translate id={'request_flow.help.big_text' + transKey} />
            </Heading>
            <div
              dangerouslySetInnerHTML={{
                __html: String(translate('request_flow.help.body_text' + transKey)),
              }}
            />
            <div className={'pt-1'}>
              <Button buttonStyle={'secondary'} extraClasses={'open-chat'} onClick={() => this.toggleChat()}>
                <Translate id={'request_flow.help.chat'} />
              </Button>
              <Button buttonStyle={'secondary'} extraClasses={'close-chat'} onClick={() => this.toggleChat()}>
                <Translate id={'request_flow.help.chat_close'} />
              </Button>
            </div>
          </div>
        </Modal>
        <Modal onClick={this.props.closeTimeModal} open={this.props.isTimeModalOpen} modalStyle={'whiteSmall'}>
          <div className={'time-pref-modal'}>
            <div className={'text--center pb-1'}>
              <div className={'time-pref-modal--smal-header text--primary text--bold'}>
                <Translate id={'request_flow.time.modal.title_top'} />
              </div>
              <img className={'van-assets-image'} src={van} />
            </div>
            <div className={'pb-1 text--bold'}>
              <Translate
                id={'request_flow.time.modal.we_let_you_know'}
                data={{
                  date: printDatesAsString(
                    [...this.props.pickupDate, ...this.props.deliveryDate],
                    this.props.isRequestAboveMaxDateDistance ? 'default.and_or' : 'default.and'
                  ),
                }}
              />
            </div>
            <div className={'pb-1'}>
              <Translate id={'request_flow.time.modal.explain_timeslot'} />
            </div>
            <Button
              buttonStyle={'secondary'}
              fullWidth={true}
              onClick={() => this.props.setCustomTimePreference(customTimePreference.no_preference)}
            >
              <Translate id={'request_flow.time.modal.ok'} />
            </Button>
            {!this.props.isRequestAboveMaxDistance && (
              <Button
                buttonStyle={'outline'}
                fullWidth={true}
                onClick={() => this.props.setCustomTimePreference(customTimePreference.custom_time)}
              >
                <Translate id={'request_flow.time.modal.pick_custom'} />
              </Button>
            )}
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  transport: state.generalTransport,
  isTimeModalOpen: isTimeModalOpen(state),
  isRequestAboveMaxDistance: isRequestAboveMaxDistance(state),
  isRequestAboveMaxDateDistance: isRequestAboveMaxDistance(state, true),
  pickupDate: getPickupAvailableDate(state),
  deliveryDate: getDeliveryAvailableDate(state),
});

const mapDispatchToProps = dispatch => ({
  setHelpOpen: () => dispatch(flowActions.setHelpOpen(false)),
  closeTimeModal: () => dispatch(flowActions.setTimeModalOpen(false)),
  setCustomTimePreference: preference => dispatch(flowActions.setCustomTimePreference(preference)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalLayout);

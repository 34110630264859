import React from 'react';
import { Route } from 'react-router';

import Overview from './containers/Overview';
import { Review } from './containers/Review';
import PageSingleView from './containers/Item';
import ChatModal from './containers/ChatModal';
import { ConfirmDelivery } from './containers/ConfirmDeliveryModal';
import { useTypedSelector } from '../../hooks';

export const prefix = '/track_and_trace';

export const TransportRequestStatus: React.FC = () => {
  const isConfirmDeliveryModalOpen = useTypedSelector(state => state.statusPage.layout.confirmOpen);
  const isLoggedIn = useTypedSelector(state => state.user.loggedIn);
  const isJobReady = useTypedSelector(state => Boolean(state.statusPage.pickup));

  return (
    // @TODO: 60px marginTop should be represented by a nav-related enum!
    <div style={{ marginTop: isLoggedIn ? '60px' : 0 }}>
      {isLoggedIn && <Route path={prefix} component={Overview} />}
      <Route exact={true} path={`${prefix}/:id`} component={PageSingleView} />
      <Route exact={true} path={`${prefix}/:id/review`} component={Review} />
      {isJobReady && <ChatModal />}
      {isConfirmDeliveryModalOpen && <ConfirmDelivery />}
    </div>
  );
};

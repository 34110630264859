import React from 'react';
import { Field } from 'redux-form';
import { Translate } from 'react-localize-redux';
import { translate } from '../../../utils/localization';
import { priceAsString } from '../../../utils/price';
import {
  FlashMessage,
  Heading,
  IconPersonOne,
  IconPersonTwo,
  IconVeryHeavy,
  InputTiles,
  SheetLayoutContent,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { SubStepFieldNamesDelivery, SubStepFieldNamesPickup } from '../interface';
import { pickupDeliverySchema } from '../schema';
import { CarryingHelpChoices } from '../../../typings';
import { useTypedSelector } from '../../../hooks';
import { getFeeExtraPerson, getFeeTailgate } from '../ducks';

interface Props {
  isPickup: boolean;
  locality: string;
  errors: any;
  title: string;
}

export const SubStepHelp: React.FC<Props> = ({ errors, isPickup, locality, title }) => {
  const fieldName = isPickup ? SubStepFieldNamesPickup.help_carrying : SubStepFieldNamesDelivery.help_carrying;
  const stopType = isPickup ? 'pickup' : 'delivery';
  const feeExtraPerson = useTypedSelector(getFeeExtraPerson);
  const feeMinEquipment = useTypedSelector(getFeeTailgate);
  const mostPopular = [
    {
      value: CarryingHelpChoices.NEEDED,
      title: String(translate(`request_flow.${stopType}.help_carrying.option_2`)),
      desc: <Translate id={`request_flow.${stopType}.help_carrying.option_2_explain`} />,
      suffix: <span className={'text--primary'}>+ {priceAsString(0)}</span>,
      prefix: <IconPersonOne />,
    },
  ];
  const otherOptions = [
    {
      value: CarryingHelpChoices.EXTRA_DRIVER,
      title: String(translate(`request_flow.${stopType}.help_carrying.option_3`)),
      desc: <Translate id={`request_flow.${stopType}.help_carrying.option_3_explain`} />,
      suffix: <span className={'text--primary'}>+ {priceAsString(feeExtraPerson)}</span>,
      prefix: <IconPersonTwo />,
    },
    {
      value: CarryingHelpChoices.NOT_NEEDED,
      title: String(translate(`request_flow.${stopType}.help_carrying.option_4`)),
      desc: <Translate id={`request_flow.${stopType}.help_carrying.option_4_explain`} />,
      suffix: (
        <div>
          <div className={'text--center text--normal'}>
            <Translate id={'request_flow.price.from'} />
          </div>
          <span className={'text--primary'}>{priceAsString(feeMinEquipment)}</span>
        </div>
      ),
      prefix: <IconVeryHeavy />,
    },
  ];

  return (
    <>
      <SheetLayoutHeader>
        <Heading size={2}>
          <Translate id={title} data={{ locality: <span className={'text--primary'}>{locality}</span> }} />
        </Heading>
      </SheetLayoutHeader>
      <SheetLayoutContent>
        <div className={'question-wrapper'}>
          <div className={'pb-0-5'}>
            <div className={'text--grey indent--tile pb-0-5'}>
              <b>
                <Translate id={'form.most_popular'} />
              </b>
            </div>
            <Field validate={pickupDeliverySchema.help} name={fieldName} options={mostPopular} component={InputTiles} />
          </div>
          <div className={'text--grey indent--tile pb-0-5'}>
            <b>
              <Translate id={'form.other_options'} />
            </b>
          </div>

          <Field validate={pickupDeliverySchema.help} name={fieldName} options={otherOptions} component={InputTiles} />
        </div>
        <div className={'general-errors-wrapper'}>{errors && <FlashMessage type={'error'} message={errors} />}</div>
      </SheetLayoutContent>
    </>
  );
};

import { all, call, debounce, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  actions as businessActions,
  defaultState,
  getBusinessSlug,
  getItemSetTotalSize,
  getNewTransportRequestLink,
  getPriceRequestParams,
  getTransportRequestPayload,
  types as businessTypes,
} from './ducks';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { matchPath } from 'react-router';
import { prefix } from './';
import _get from 'lodash/get';
import { actionTypes as reduxFormTypes, change, getFormValues, reset } from 'redux-form';
import { BusinessFields, BusinessForms } from './interface';
import { deliveryPrefix } from './containers/Destination';
import * as appUtils from '../../utils/basics';
import { _BUSINESS_MAX_ITEM_SET_SIZE, _PRICE_API_URL, _ROUTING_BASE_URL } from '../../utils/global';
import { priceCalculator } from '../GeneralFlow/sagas';
import { getLoggedInUser, types as userTypes } from '../User/ducks';
import { http } from '../../utils/request';

import { getCountryIri } from '../../state/ducks/baseReducer';

function* determineBusinessDomain(action) {
  const hasBusinessFlow = _get(action, 'payload.account.has_business_flow', false);
  yield put(businessActions.setHasBusinessFlow(hasBusinessFlow));
  if (hasBusinessFlow) {
    yield put(businessActions.setBusinessDomainName(action.payload.account.name));
    return;
  }
  yield put(businessActions.setBusinessDomainName(defaultState.business_name));
}

function* fetchAddresses() {
  try {
    const loggedInUser = yield select(getLoggedInUser);
    const addresses = _get(loggedInUser, 'userData.account.addresses', null);
    if (addresses === null || !addresses.length) {
      throw new Error('No addresses found');
    }
    const addressResponses = yield all(
      addresses.map(address => call(() => http().get(address, { withCredentials: true })))
    );
    const depotAddressesResponses = addressResponses.filter(
      address => address.status === 200 && address.data.type === 'depot'
    );
    if (depotAddressesResponses.length === 0) {
      throw new Error('Either all api calls failed or this account has no depot address');
    }
    // filter out the usefull fields, reverse the array to have the oldest addresses on top.
    yield put(
      businessActions.setDepotAddresses(
        depotAddressesResponses
          .map(address => {
            return {
              line1: address.data.line1,
              line2: address.data.line2 || undefined,
              postal_code: address.data.postal_code,
              locality: address.data.locality,
              municipality: address.data.municipality || undefined,
              administrative_area: address.data.administrative_area || undefined,
              country: address.data.country,
              lat: address.data.lat,
              lng: address.data.lng,
            };
          })
          .reverse()
      )
    );
  } catch (e) {
    // Lawd have mercy
    appUtils.logger(e);
    yield put(businessActions.setDepotAddresses([]));
  }
}

function* fillHiddenDeliveryInfo(action) {
  const fieldListeners = [
    deliveryPrefix + '.address.line1',
    deliveryPrefix + '.address.postal_code',
    deliveryPrefix + '.address.locality',
  ];

  if (!fieldListeners.includes(action.meta.field) || action.meta.form !== BusinessForms.DESTINATION) {
    return;
  }

  const values = yield select(getFormValues(BusinessForms.DESTINATION));
  if (
    !(
      _get(values, deliveryPrefix + '.address.line1', false) &&
      _get(values, deliveryPrefix + '.address.postal_code', false) &&
      _get(values, deliveryPrefix + '.address.locality', false)
    )
  ) {
    return;
  }
  const query = `${values[deliveryPrefix].address.line1},${values[deliveryPrefix].address.postal_code},${values[deliveryPrefix].address.locality}`;
  const response = yield call(fetch, `${_ROUTING_BASE_URL}/geo/autocomplete?q=${query}`);
  if (response.status !== 200) {
    return;
  }
  const json = yield response.json();
  const result = json[0];
  const fields = ['municipality', 'administrative_area'];
  yield all(
    fields.map(field =>
      put(change(BusinessForms.DESTINATION, `${deliveryPrefix}.address.${field}`, result['address'][field]))
    )
  );
  const country = yield select(getCountryIri, result.address.country_code);
  yield all([
    put(change(BusinessForms.DESTINATION, `${deliveryPrefix}.address.lat`, result.address.latitude)),
    put(change(BusinessForms.DESTINATION, `${deliveryPrefix}.address.lng`, result.address.longitude)),
    put(change(BusinessForms.DESTINATION, `${deliveryPrefix}.address.country`, country)),
  ]);
}

function* priceHandler() {
  const priceParams = yield select(getPriceRequestParams);
  if (priceParams === null) {
    yield put(businessActions.setPriceLoading(false));
    return;
  }
  yield put(businessActions.setPriceLoading(true));
  try {
    const priceResponse: any = yield call(priceCalculator, priceParams);
    const price = _get(priceResponse, 'data.price', false);
    if (price) {
      yield put(businessActions.setPrice(price));
    }
  } catch (e) {
    appUtils.logger(e);
  }
  yield put(businessActions.setPriceLoading(false));
}

function* createTransportRequest() {
  yield put(businessActions.setPriceLoading(true));

  try {
    const transportRequestPayload: object = yield select(getTransportRequestPayload);
    if (transportRequestPayload === null) {
      yield put(businessActions.setPriceLoading(false));
      return;
    }
    const transportRequestResponse = yield call(() =>
      http()
        .post('/transport_requests', transportRequestPayload, { withCredentials: true })
        .then(resp => resp)
    );
    // save uuid and navigate user to payment
    if (transportRequestResponse) {
      const uuid = _get(transportRequestResponse, "data['@id']", '').replace('/transport_requests/', '');
      yield put(businessActions.setTransportRequestId(uuid));
      yield put(businessActions.setPriceLoading(false));
      const businessPrefix = yield select(getBusinessSlug);
      yield put(push(`${businessPrefix}/thank_you`));
    } else {
      yield put(businessActions.setPriceLoading(false));
    }
  } catch (err) {
    console.log(err);
    yield put(businessActions.setPriceLoading(false));
    appUtils.logger(err);
  }
}

export function* onFieldChange(action) {
  const triggerPriceFields = [BusinessFields.DEPOT_SELECT, BusinessFields.SERVICE_EXTRA_DRIVER];
  const triggerPriceForms = [BusinessForms.DESTINATION, BusinessForms.ITEMS_AND_SERVICE];
  if (
    triggerPriceForms.includes(action.meta.form) &&
    (triggerPriceFields.includes(action.meta.field) || action.meta.field.includes(BusinessFields.ITEM_SETS))
  ) {
    yield put(businessActions.getPrice());
  }
  if (action.meta.form !== BusinessForms.ITEMS_AND_SERVICE) {
    return;
  }
  const itemsTotalSize = yield select(getItemSetTotalSize);
  if (itemsTotalSize > _BUSINESS_MAX_ITEM_SET_SIZE) {
    yield put(businessActions.setBusinessFlowError('MAX_TOTAL_ITEM_SIZE'));
  } else {
    yield put(businessActions.setBusinessFlowError(null));
  }
}

export function* itemOnRemove(action) {
  if (action.meta.form !== BusinessForms.ITEMS_AND_SERVICE) {
    return;
  }
  if (action.meta.field.indexOf('itemSets') > -1) {
    yield put(businessActions.getPrice());
  }
}

function* setProgress(action) {
  const path = action.payload.location.pathname;
  const pathData = matchPath(path, { path: `${prefix}`, exact: false, strict: false });
  if (!pathData) {
    return;
  }
  const pathSegments = path.split('/');
  let progressStep = 1;
  switch (pathSegments[pathSegments.length - 1]) {
    case 'items':
      progressStep = 2;
      break;
    case 'preview':
      progressStep = 3;
      break;
    case 'thank_you':
      progressStep = 4;
      break;
    default:
      progressStep = 1;
  }
  yield put(businessActions.setProgressStep(progressStep));
}

function* handleDestinationSubmit() {
  const businessPrefix = yield select(getBusinessSlug);
  yield put(push(`${businessPrefix}/transport_request/items`));
}

function* handleItemsSubmit() {
  // push to preview view
  const businessPrefix = yield select(getBusinessSlug);
  yield put(push(`${businessPrefix}/transport_request/preview`));
}

function* resetBusinessFlow() {
  yield all([
    put(businessActions.setTransportRequestId(defaultState.transport_request.uuid)),
    put(businessActions.setPrice(defaultState.transport_request.price)),
    put(businessActions.setDateTimePeriodIndex(defaultState.transport_request.date_time_periods.index)),
    put(reset(BusinessForms.DESTINATION)),
    put(reset(BusinessForms.ITEMS_AND_SERVICE)),
  ]);
}

function* startNewTransportRequest() {
  const newTransportRequestLink = yield select(getNewTransportRequestLink);
  yield put(push(`${newTransportRequestLink}`));
}

function* getDates() {
  const userDetails = yield select(getLoggedInUser);
  try {
    const dateResponse = yield call(
      http().get,
      `${_PRICE_API_URL()}/business/date-list/${userDetails.userData.account.id}`
    );
    if (dateResponse.data?.error) {
      throw new Error(dateResponse.data.error);
    }
    const dates = _get(dateResponse, 'data', defaultState.transport_request.date_time_periods.options);
    if (dates.length === 0) {
      throw new Error('No dates available');
    }
    yield put(businessActions.setDateTimePeriodOptions(dates));
  } catch (e) {
    // If something went wrong we should fall back to default state
    // In this case the client can not select a date, but still can proceed with the order
    appUtils.logger(e);
    yield put(businessActions.setDateTimePeriodOptions(defaultState.transport_request.date_time_periods.options));
  }
}

function* businessTransportRequestSaga() {
  yield takeLatest(userTypes.SET_USER_DETAILS, determineBusinessDomain);
  yield takeLatest(businessTypes.FETCH_ADDRESSES, fetchAddresses);
  yield takeLatest(LOCATION_CHANGE, setProgress);
  yield debounce(250, reduxFormTypes.CHANGE, fillHiddenDeliveryInfo);
  yield takeLatest(reduxFormTypes.ARRAY_REMOVE, itemOnRemove);
  yield debounce(1000, reduxFormTypes.CHANGE, onFieldChange);
  yield takeLatest(businessTypes.SUBMIT_DESTINATION, handleDestinationSubmit);
  yield takeLatest(businessTypes.SUBMIT_ITEMS, handleItemsSubmit);
  yield takeLatest(businessTypes.RESET_TR_BUSINESS_FLOW, resetBusinessFlow);
  yield takeLatest(businessTypes.START_NEW_TR, startNewTransportRequest);
  yield takeLatest(businessTypes.CREATE_TR, createTransportRequest);
  yield takeLatest(businessTypes.GET_PRICE, priceHandler);
  yield takeLatest(businessTypes.GET_DATE_TIME_PERIODS_OPTIONS, getDates);
}

export function* BusinessSaga() {
  yield fork(businessTransportRequestSaga);
}

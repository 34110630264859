export const getBooleansForRenderingStars = (reviewScore: number): boolean[] => {
  const fullStars = Array(reviewScore)
    .fill('')
    .map((el, i) => true);

  const emptyStars = Array(5 - reviewScore)
    .fill('')
    .map((el, i) => false);

  return fullStars.concat(emptyStars);
};

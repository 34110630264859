import * as React from 'react';
import _isEqual from 'lodash/isEqual';
import classNames from 'classnames';

import InputWrapper from '../input/InputWrapper';
import InputFeedback from '../input/InputFeedback';
import { InputProps } from '../input/Input';

import '../input/input.css';
import './input-tiles.css';

interface custom {
  compact?: boolean;
  options: Array<{ value: any; title: string; desc?: any; prefix?: any; suffix?: string | object }>;
  maxDelayIndex: number;
}

interface InputOption {
  title: string;
  value: string;
  desc?: string;
  prefix?: string | React.ReactNode;
  suffix?: string | React.ReactNode;
  selected?: boolean;
}

export type InputOptionsProps = custom & InputProps;

const InputTiles: React.FC<InputOptionsProps> = ({
  input,
  meta,
  successMessage,
  compact,
  options,
  maxDelayIndex = -1,
}) => {
  return (
    <InputWrapper
      input={...input}
      meta={...meta}
      type={'tile'}
      extraClasses={classNames({ 'input-el--tile-compact': compact })}
      // override any input tile container bottom margin styling
      style={{ marginBottom: 0 }}
    >
      <div>
        <input {...input} type={'hidden'} />
        {options.map((option: InputOption, index: number) => {
          let selected = option.selected || input.value === option.value;
          if (typeof option === 'object') {
            selected = _isEqual(input.value, option.value);
          }
          const delayClass = maxDelayIndex === -1 ? index : index % maxDelayIndex;
          return (
            <div
              className={classNames('trigger', 'input-el--tile--option', `delay-index-${delayClass}`, {
                'is-selected': selected,
              })}
              key={index}
              onClick={() => input.onChange(option.value)}
            >
              {option.prefix && <div className={'input-el--tile--prefix'}>{option.prefix}</div>}
              <div className={'input-el--tile--copy'}>
                <div className={'input-el--tile--title'} dangerouslySetInnerHTML={{ __html: option.title }} />
                {option.desc && <div className={'input-el--tile--desc'}>{option.desc}</div>}
              </div>
              {option.suffix && typeof option.suffix !== 'string' && (
                <div className={'input-el--tile--suffix'}>{option.suffix}</div>
              )}
              {option.suffix && typeof option.suffix === 'string' && (
                <div className={'input-el--tile--suffix'} dangerouslySetInnerHTML={{ __html: option.suffix }} />
              )}
            </div>
          );
        })}
      </div>
      <InputFeedback meta={...meta} error={meta.error} successMessage={successMessage} />
    </InputWrapper>
  );
};

export default InputTiles;

import * as React from 'react';
import { InputProps } from '../input/Input';
import _get from 'lodash/get';
import { GenericInputFileMethods } from '../../../../typings/enums';
import { IconSmile } from '../../basics/icons';
import classNames from 'classnames';

interface CustomProps {
  label: string;
  accept?: string;
  isUploading: boolean;
  multiple?: boolean;
  showButton?: boolean;
}

type Props = InputProps & CustomProps;

const InputFile = ({ input, meta, label, accept, multiple, isUploading, showButton }: Props): any => {
  const buttonStyle = classNames({
    outline: !isUploading,
    ['is-disabled']: isUploading,
    'btn--loading': isUploading,
  });

  return (
    <>
      <div className={'input-el input-el--file'}>
        <input
          type="file"
          id={input.name}
          multiple={multiple ? true : undefined}
          accept={accept}
          onChange={event =>
            input.onChange({
              input_file_method: multiple ? GenericInputFileMethods.ADD : GenericInputFileMethods.CHANGE,
              files: event.target.files,
            })
          }
          disabled={isUploading}
        />
        {showButton && (
          <label className={'btn btn--' + buttonStyle} htmlFor={input.name}>
            {label}
            {isUploading && <IconSmile spinning={true} />}
          </label>
        )}
      </div>
      {meta.touched && meta.error && (
        <div style={{ marginLeft: 0 }} className={'input-el--feedback'}>
          <div className={'input-el--feedback--error'}>{meta.error}</div>
        </div>
      )}
    </>
  );
};

export default InputFile;

import * as React from 'react';
import './icons.css';

interface Props {
  full: boolean;
}

const IconStar = ({ full }: Props) => {
  if (!full) {
    return (
      <svg className={'IconStar IconStarEmpty'} width="20" height="20" viewBox="0 0 20 20">
        <polygon fill={'#e2eaf2'} className="a" points="10 0 7 7 0 8 5 13 4 20 10 17 16 20 15 13 20 8 13 7 10 0" />
      </svg>
    );
  }
  return (
    <svg className={'IconStar IconStarFull'} width="20" height="20" viewBox="0 0 20 20">
      <polygon fill={'#f1c442'} points="10 0 7 7 0 8 5 13 4 20 10 17 16 20 15 13 20 8 13 7 10 0" />
    </svg>
  );
};

export default IconStar;

import * as React from 'react';
import InputWrapper from '../input/InputWrapper';
import InputFeedback from '../input/InputFeedback';
import './select.css';
import { IconSmile } from '../../basics/icons';

interface Props {
  input: any;
  meta: any;
  label?: string;
  description?: string;
  border?: boolean;
  successMessage: string;
  options: any[];
  emptyFirstOption?: boolean;
}

const Select = ({ input, meta, label, border, successMessage, options, emptyFirstOption = true }: Props): any => (
  <InputWrapper label={label} input={...input} meta={...meta} type={'select'} border={border}>
    {label && (
      <label
        className={
          'input-el--label' +
          (typeof input.required !== 'undefined' && input.required === true ? ' input-el--label--required' : '')
        }
        htmlFor={input.name}
      >
        {label}
      </label>
    )}
    <select {...input} id={input.name} value={input.value}>
      {emptyFirstOption && <option />}
      {options.map((item, index) => (
        <option key={index} value={item.value}>
          {item.label}
        </option>
      ))}
    </select>
    <label htmlFor={input.name}>
      <IconSmile />
    </label>
    <InputFeedback meta={...meta} error={meta.error} successMessage={successMessage} />
  </InputWrapper>
);

export default Select;

import { setCookie, urlParser } from '../utils/basics';

export const cookie = {
  fetchMarktplaatsUTM: () => {
    const URL = urlParser();
    const utmKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content'];
    for (const key of utmKeys) {
      if (URL.get(key)) {
        setCookie(key, URL.get(key), 30);
      }
    }
  },
};
